/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ExportExcelParam } from '../models/export-excel-param';
import { ImportModel } from '../models/import-model';
import { EmployeeListItemModelPaginationResult } from '../models/employee-list-item-model-pagination-result';
import { CreateEmployeeModel } from '../models/create-employee-model';
import { EmployeeModel } from '../models/employee-model';
import { EmployeePvdModel } from '../models/employee-pvd-model';
import { EmployeeGeneralInfoModel } from '../models/employee-general-info-model';
import { EmployeeAddressInfoModel } from '../models/employee-address-info-model';
import { EmployeeRelativeModel } from '../models/employee-relative-model';
import { EmployeeEducationModel } from '../models/employee-education-model';
import { EmployeeCertificateModel } from '../models/employee-certificate-model';
import { EmployeeTrainingListItemModel } from '../models/employee-training-list-item-model';
import { EmployeeInsigniaListItemModel } from '../models/employee-insignia-list-item-model';
import { EmployeeInterestModel } from '../models/employee-interest-model';
import { EmployeePersonalInfoModel } from '../models/employee-personal-info-model';
import { EmployeeCardModel } from '../models/employee-card-model';
import { EmployeeNameModel } from '../models/employee-name-model';
import { EmployeeWorkPermitModel } from '../models/employee-work-permit-model';
import { EmployeePastEmploymentModel } from '../models/employee-past-employment-model';
import { EmployeeInPositionModel } from '../models/employee-in-position-model';
import { EmployeeContactInfoModel } from '../models/employee-contact-info-model';
import { EmployeeParentInfoModel } from '../models/employee-parent-info-model';
import { EmployeeSpouseInfoModel } from '../models/employee-spouse-info-model';
import { EmployeeChildrenInfoModel } from '../models/employee-children-info-model';
import { EmployeeEmergencyContactModel } from '../models/employee-emergency-contact-model';
import { EmployeePhysicalMeasurementModel } from '../models/employee-physical-measurement-model';
import { EmployeeChronicDiseaseModel } from '../models/employee-chronic-disease-model';
import { EmployeeDrugAllergyModel } from '../models/employee-drug-allergy-model';
import { EmployeeSurgeryModel } from '../models/employee-surgery-model';
import { EmployeeHealthCheckModel } from '../models/employee-health-check-model';
import { EmployeePunishmentModel } from '../models/employee-punishment-model';
import { EmployeeSalaryModel } from '../models/employee-salary-model';
import { EmployeeBankAccountModel } from '../models/employee-bank-account-model';
import { EmployeeOtherInfoModel } from '../models/employee-other-info-model';
import { EmployeeTaxBeforeHireInfoModel } from '../models/employee-tax-before-hire-info-model';
import { EmployeeTaxInfoModel } from '../models/employee-tax-info-model';
import { EmployeeFixPayElementModel } from '../models/employee-fix-pay-element-model';
import { EmployeeAdditionalFieldPaginationResult } from '../models/employee-additional-field-pagination-result';
import { EmployeeBranchModel } from '../models/employee-branch-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeService extends __BaseService {
  static readonly ExportExcelPath = '/api/Employee/run/exportexcel';
  static readonly ExportDirectBossExcelPath = '/api/Employee/run/directboss/exportexcel';
  static readonly ImportExcelStep1Path = '/api/Employee/run/importexcel/step1';
  static readonly ImportExcelStep2Path = '/api/Employee/run/importexcel/step2';
  static readonly ImportExcelStep2AzyncPath = '/api/Employee/run/importexcel/step2async';
  static readonly GetEmployeesPath = '/api/Employee';
  static readonly CreateEmployeePath = '/api/Employee';
  static readonly GetEmployeeCreateInfoByIdPath = '/api/Employee/{employeeId}/creationinfo';
  static readonly GetSubordinateOfEmployeePath = '/api/Employee/{employeeId}/subordinate';
  static readonly GetEmployeeInfoPath = '/api/Employee/{employeeId}';
  static readonly TerminateEmployeePath = '/api/Employee/{employeeId}';
  static readonly DeleteEmployeePath = '/api/Employee/{employeeId}';
  static readonly GetEmployeeInfoWithPermissionPath = '/api/Employee/{employeeId}/getwithoutpermission';
  static readonly GetMyEmployeeInfoPath = '/api/Employee/myemployeeinfo';
  static readonly GetPvdInfoPath = '/api/Employee/{employeeId}/pvd';
  static readonly UpdatePvdInfoPath = '/api/Employee/{employeeId}/pvd';
  static readonly GetGeneralInfoPath = '/api/Employee/{employeeId}/generalinfo';
  static readonly GetAddressInfoPath = '/api/Employee/{employeeId}/addressinfo';
  static readonly GetFamilyPath = '/api/Employee/{employeeId}/family';
  static readonly GetEducationsPath = '/api/Employee/{employeeId}/educations';
  static readonly GetCertificatesPath = '/api/Employee/{employeeId}/certificates';
  static readonly GetTrainingsPath = '/api/Employee/{employeeId}/trainings';
  static readonly GetInsigniasPath = '/api/Employee/{employeeId}/insignias';
  static readonly GetInterestsPath = '/api/Employee/{employeeId}/interests';
  static readonly GetPersonalInfoPath = '/api/Employee/{employeeId}/personalinfo';
  static readonly UpdatePersonalInfoPath = '/api/Employee/{employeeId}/personalinfo';
  static readonly GetCardsPath = '/api/Employee/{employeeId}/cards';
  static readonly GetNamesPath = '/api/Employee/{employeeId}/names';
  static readonly GetWorkPermitsPath = '/api/Employee/{employeeId}/workpermits';
  static readonly GetPastEmploymentsPath = '/api/Employee/{employeeId}/pastemployment';
  static readonly GetInPositionsPath = '/api/Employee/{employeeId}/inposition';
  static readonly GetContactInfoPath = '/api/Employee/{employeeId}/contact';
  static readonly UpdateEmployeeContactPath = '/api/Employee/{employeeId}/contact';
  static readonly GetParentInfoPath = '/api/Employee/{employeeId}/parent';
  static readonly UpdateParentPath = '/api/Employee/{employeeId}/parent';
  static readonly GetSpouseInfoPath = '/api/Employee/{employeeId}/spouse';
  static readonly GetChildrenInfoPath = '/api/Employee/{employeeId}/children';
  static readonly GetEmergencyContactInfoPath = '/api/Employee/{employeeId}/emergencycontact';
  static readonly GetPhysicalMeasurementPath = '/api/Employee/{employeeId}/physicalmeasurement';
  static readonly GetChronicDiseasesPath = '/api/Employee/{employeeId}/chronicdiseases';
  static readonly GetDrugAllergiesPath = '/api/Employee/{employeeId}/drugallergies';
  static readonly GetSurgeriesPath = '/api/Employee/{employeeId}/surgeries';
  static readonly GetEmployeeHealthChecksPath = '/api/Employee/{employeeId}/healthchecks';
  static readonly GetPunishmentsPath = '/api/Employee/{employeeId}/punishment';
  static readonly GetSalariesPath = '/api/Employee/{employeeId}/salary';
  static readonly GetBankAccountsPath = '/api/Employee/{employeeId}/bankaccount';
  static readonly GetOtherInfoPath = '/api/Employee/{employeeId}/otherinfo';
  static readonly UpdateOtherInfoPath = '/api/Employee/{employeeId}/otherinfo';
  static readonly GetEmployeeImagePath = '/api/Employee/{userId}/image';
  static readonly UpdateEmployeeImagePath = '/api/Employee/{employeeId}/image';
  static readonly CancelTerminateEmployeePath = '/api/Employee/{employeeId}/cancelterminate';
  static readonly GetTaxBeforeHireInfoPath = '/api/Employee/{employeeId}/tax-before-hire-info';
  static readonly UpdateTaxBeforeHireInfoPath = '/api/Employee/{employeeId}/tax-before-hire-info';
  static readonly GetTaxInfoPath = '/api/Employee/{employeeId}/tax-info';
  static readonly UpdateTaxInfoPath = '/api/Employee/{employeeId}/tax-info';
  static readonly GetFixPayElementPath = '/api/Employee/{employeeId}/fix-pay-elements';
  static readonly UpdateFixPayElementPath = '/api/Employee/{employeeId}/fix-pay-elements';
  static readonly IsAccessibleEmployeePath = '/api/Employee/{employeeId}/isaccessible';
  static readonly GetAdditionalPath = '/api/Employee/{employeeId}/additional';
  static readonly GetBranchPath = '/api/Employee/{employeeId}/branch';
  static readonly UpdateBranchPath = '/api/Employee/{employeeId}/branch';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  ExportExcelResponse(body?: ExportExcelParam): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employee/run/exportexcel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ExportExcel(body?: ExportExcelParam): __Observable<Blob> {
    return this.ExportExcelResponse(body).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ExportDirectBossExcelResponse(body?: ExportExcelParam): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employee/run/directboss/exportexcel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ExportDirectBossExcel(body?: ExportExcelParam): __Observable<Blob> {
    return this.ExportDirectBossExcelResponse(body).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  ImportExcelStep1Response(file?: Blob): __Observable<__StrictHttpResponse<ImportModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employee/run/importexcel/step1`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImportModel>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  ImportExcelStep1(file?: Blob): __Observable<ImportModel> {
    return this.ImportExcelStep1Response(file).pipe(
      __map(_r => _r.body as ImportModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep2Response(body?: ImportModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employee/run/importexcel/step2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep2(body?: ImportModel): __Observable<string> {
    return this.ImportExcelStep2Response(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep2AzyncResponse(body?: ImportModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employee/run/importexcel/step2async`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep2Azync(body?: ImportModel): __Observable<number> {
    return this.ImportExcelStep2AzyncResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `EmployeeService.GetEmployeesParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetEmployeesResponse(params: EmployeeService.GetEmployeesParams): __Observable<__StrictHttpResponse<EmployeeListItemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeListItemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.GetEmployeesParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetEmployees(params: EmployeeService.GetEmployeesParams): __Observable<EmployeeListItemModelPaginationResult> {
    return this.GetEmployeesResponse(params).pipe(
      __map(_r => _r.body as EmployeeListItemModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeResponse(body?: CreateEmployeeModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployee(body?: CreateEmployeeModel): __Observable<number> {
    return this.CreateEmployeeResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeCreateInfoByIdResponse(employeeId: number): __Observable<__StrictHttpResponse<CreateEmployeeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/creationinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateEmployeeModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeCreateInfoById(employeeId: number): __Observable<CreateEmployeeModel> {
    return this.GetEmployeeCreateInfoByIdResponse(employeeId).pipe(
      __map(_r => _r.body as CreateEmployeeModel)
    );
  }

  /**
   * @param params The `EmployeeService.GetSubordinateOfEmployeeParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * @return Success
   */
  GetSubordinateOfEmployeeResponse(params: EmployeeService.GetSubordinateOfEmployeeParams): __Observable<__StrictHttpResponse<EmployeeListItemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/subordinate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeListItemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.GetSubordinateOfEmployeeParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * @return Success
   */
  GetSubordinateOfEmployee(params: EmployeeService.GetSubordinateOfEmployeeParams): __Observable<EmployeeListItemModelPaginationResult> {
    return this.GetSubordinateOfEmployeeResponse(params).pipe(
      __map(_r => _r.body as EmployeeListItemModelPaginationResult)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeInfo(employeeId: number): __Observable<EmployeeModel> {
    return this.GetEmployeeInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeModel)
    );
  }

  /**
   * @param params The `EmployeeService.TerminateEmployeeParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  TerminateEmployeeResponse(params: EmployeeService.TerminateEmployeeParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.TerminateEmployeeParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  TerminateEmployee(params: EmployeeService.TerminateEmployeeParams): __Observable<string> {
    return this.TerminateEmployeeResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  DeleteEmployeeResponse(employeeId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  DeleteEmployee(employeeId: number): __Observable<string> {
    return this.DeleteEmployeeResponse(employeeId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeInfoWithPermissionResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/getwithoutpermission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeInfoWithPermission(employeeId: number): __Observable<EmployeeModel> {
    return this.GetEmployeeInfoWithPermissionResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeModel)
    );
  }

  /**
   * @return Success
   */
  GetMyEmployeeInfoResponse(): __Observable<__StrictHttpResponse<EmployeeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/myemployeeinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeModel>;
      })
    );
  }
  /**
   * @return Success
   */
  GetMyEmployeeInfo(): __Observable<EmployeeModel> {
    return this.GetMyEmployeeInfoResponse().pipe(
      __map(_r => _r.body as EmployeeModel)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetPvdInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeePvdModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/pvd`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePvdModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetPvdInfo(employeeId: number): __Observable<EmployeePvdModel> {
    return this.GetPvdInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeePvdModel)
    );
  }

  /**
   * @param params The `EmployeeService.UpdatePvdInfoParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdatePvdInfoResponse(params: EmployeeService.UpdatePvdInfoParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/pvd`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.UpdatePvdInfoParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdatePvdInfo(params: EmployeeService.UpdatePvdInfoParams): __Observable<string> {
    return this.UpdatePvdInfoResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetGeneralInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeGeneralInfoModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/generalinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeGeneralInfoModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetGeneralInfo(employeeId: number): __Observable<EmployeeGeneralInfoModel> {
    return this.GetGeneralInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeGeneralInfoModel)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetAddressInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeAddressInfoModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/addressinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeAddressInfoModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetAddressInfo(employeeId: number): __Observable<EmployeeAddressInfoModel> {
    return this.GetAddressInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeAddressInfoModel)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetFamilyResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeRelativeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/family`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeRelativeModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetFamily(employeeId: number): __Observable<Array<EmployeeRelativeModel>> {
    return this.GetFamilyResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeRelativeModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEducationsResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeEducationModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/educations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeEducationModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEducations(employeeId: number): __Observable<Array<EmployeeEducationModel>> {
    return this.GetEducationsResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeEducationModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetCertificatesResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeCertificateModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/certificates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeCertificateModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetCertificates(employeeId: number): __Observable<Array<EmployeeCertificateModel>> {
    return this.GetCertificatesResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeCertificateModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetTrainingsResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeTrainingListItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/trainings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeTrainingListItemModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetTrainings(employeeId: number): __Observable<Array<EmployeeTrainingListItemModel>> {
    return this.GetTrainingsResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeTrainingListItemModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetInsigniasResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeInsigniaListItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/insignias`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeInsigniaListItemModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetInsignias(employeeId: number): __Observable<Array<EmployeeInsigniaListItemModel>> {
    return this.GetInsigniasResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeInsigniaListItemModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetInterestsResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeInterestModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/interests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeInterestModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetInterests(employeeId: number): __Observable<Array<EmployeeInterestModel>> {
    return this.GetInterestsResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeInterestModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetPersonalInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeePersonalInfoModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/personalinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePersonalInfoModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetPersonalInfo(employeeId: number): __Observable<EmployeePersonalInfoModel> {
    return this.GetPersonalInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeePersonalInfoModel)
    );
  }

  /**
   * @param params The `EmployeeService.UpdatePersonalInfoParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdatePersonalInfoResponse(params: EmployeeService.UpdatePersonalInfoParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/personalinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.UpdatePersonalInfoParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdatePersonalInfo(params: EmployeeService.UpdatePersonalInfoParams): __Observable<string> {
    return this.UpdatePersonalInfoResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetCardsResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeCardModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/cards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeCardModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetCards(employeeId: number): __Observable<Array<EmployeeCardModel>> {
    return this.GetCardsResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeCardModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetNamesResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeNameModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/names`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeNameModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetNames(employeeId: number): __Observable<Array<EmployeeNameModel>> {
    return this.GetNamesResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeNameModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetWorkPermitsResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeWorkPermitModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/workpermits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeWorkPermitModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetWorkPermits(employeeId: number): __Observable<Array<EmployeeWorkPermitModel>> {
    return this.GetWorkPermitsResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeWorkPermitModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetPastEmploymentsResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeePastEmploymentModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/pastemployment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeePastEmploymentModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetPastEmployments(employeeId: number): __Observable<Array<EmployeePastEmploymentModel>> {
    return this.GetPastEmploymentsResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeePastEmploymentModel>)
    );
  }

  /**
   * @param params The `EmployeeService.GetInPositionsParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `isOrderedByDescending`:
   *
   * @return Success
   */
  GetInPositionsResponse(params: EmployeeService.GetInPositionsParams): __Observable<__StrictHttpResponse<Array<EmployeeInPositionModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.isOrderedByDescending != null) __params = __params.set('isOrderedByDescending', params.isOrderedByDescending.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/inposition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeInPositionModel>>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.GetInPositionsParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `isOrderedByDescending`:
   *
   * @return Success
   */
  GetInPositions(params: EmployeeService.GetInPositionsParams): __Observable<Array<EmployeeInPositionModel>> {
    return this.GetInPositionsResponse(params).pipe(
      __map(_r => _r.body as Array<EmployeeInPositionModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetContactInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeContactInfoModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/contact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeContactInfoModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetContactInfo(employeeId: number): __Observable<EmployeeContactInfoModel> {
    return this.GetContactInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeContactInfoModel)
    );
  }

  /**
   * @param params The `EmployeeService.UpdateEmployeeContactParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeContactResponse(params: EmployeeService.UpdateEmployeeContactParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/contact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.UpdateEmployeeContactParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeContact(params: EmployeeService.UpdateEmployeeContactParams): __Observable<string> {
    return this.UpdateEmployeeContactResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetParentInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeParentInfoModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/parent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeParentInfoModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetParentInfo(employeeId: number): __Observable<EmployeeParentInfoModel> {
    return this.GetParentInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeParentInfoModel)
    );
  }

  /**
   * @param params The `EmployeeService.UpdateParentParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateParentResponse(params: EmployeeService.UpdateParentParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/parent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.UpdateParentParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateParent(params: EmployeeService.UpdateParentParams): __Observable<string> {
    return this.UpdateParentResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetSpouseInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeSpouseInfoModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/spouse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeSpouseInfoModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetSpouseInfo(employeeId: number): __Observable<EmployeeSpouseInfoModel> {
    return this.GetSpouseInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeSpouseInfoModel)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetChildrenInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeChildrenInfoModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeChildrenInfoModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetChildrenInfo(employeeId: number): __Observable<EmployeeChildrenInfoModel> {
    return this.GetChildrenInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeChildrenInfoModel)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmergencyContactInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeEmergencyContactModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/emergencycontact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeEmergencyContactModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmergencyContactInfo(employeeId: number): __Observable<EmployeeEmergencyContactModel> {
    return this.GetEmergencyContactInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeEmergencyContactModel)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetPhysicalMeasurementResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeePhysicalMeasurementModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/physicalmeasurement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePhysicalMeasurementModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetPhysicalMeasurement(employeeId: number): __Observable<EmployeePhysicalMeasurementModel> {
    return this.GetPhysicalMeasurementResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeePhysicalMeasurementModel)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetChronicDiseasesResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeChronicDiseaseModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/chronicdiseases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeChronicDiseaseModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetChronicDiseases(employeeId: number): __Observable<Array<EmployeeChronicDiseaseModel>> {
    return this.GetChronicDiseasesResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeChronicDiseaseModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetDrugAllergiesResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeDrugAllergyModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/drugallergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeDrugAllergyModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetDrugAllergies(employeeId: number): __Observable<Array<EmployeeDrugAllergyModel>> {
    return this.GetDrugAllergiesResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeDrugAllergyModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetSurgeriesResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeSurgeryModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/surgeries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeSurgeryModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetSurgeries(employeeId: number): __Observable<Array<EmployeeSurgeryModel>> {
    return this.GetSurgeriesResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeSurgeryModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeHealthChecksResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeHealthCheckModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/healthchecks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeHealthCheckModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeHealthChecks(employeeId: number): __Observable<Array<EmployeeHealthCheckModel>> {
    return this.GetEmployeeHealthChecksResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeHealthCheckModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetPunishmentsResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeePunishmentModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/punishment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeePunishmentModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetPunishments(employeeId: number): __Observable<Array<EmployeePunishmentModel>> {
    return this.GetPunishmentsResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeePunishmentModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetSalariesResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeSalaryModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/salary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeSalaryModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetSalaries(employeeId: number): __Observable<Array<EmployeeSalaryModel>> {
    return this.GetSalariesResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeSalaryModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetBankAccountsResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeBankAccountModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/bankaccount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeBankAccountModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetBankAccounts(employeeId: number): __Observable<Array<EmployeeBankAccountModel>> {
    return this.GetBankAccountsResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeBankAccountModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetOtherInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeOtherInfoModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/otherinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeOtherInfoModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetOtherInfo(employeeId: number): __Observable<EmployeeOtherInfoModel> {
    return this.GetOtherInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeOtherInfoModel)
    );
  }

  /**
   * @param params The `EmployeeService.UpdateOtherInfoParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateOtherInfoResponse(params: EmployeeService.UpdateOtherInfoParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/otherinfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.UpdateOtherInfoParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateOtherInfo(params: EmployeeService.UpdateOtherInfoParams): __Observable<string> {
    return this.UpdateOtherInfoResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param userId undefined
   */
  GetEmployeeImageResponse(userId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(userId))}/image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param userId undefined
   */
  GetEmployeeImage(userId: number): __Observable<null> {
    return this.GetEmployeeImageResponse(userId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeeService.UpdateEmployeeImageParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `imageDocumentId`:
   *
   * @return Success
   */
  UpdateEmployeeImageResponse(params: EmployeeService.UpdateEmployeeImageParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.imageDocumentId != null) __params = __params.set('imageDocumentId', params.imageDocumentId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.UpdateEmployeeImageParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `imageDocumentId`:
   *
   * @return Success
   */
  UpdateEmployeeImage(params: EmployeeService.UpdateEmployeeImageParams): __Observable<string> {
    return this.UpdateEmployeeImageResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  CancelTerminateEmployeeResponse(employeeId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/cancelterminate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  CancelTerminateEmployee(employeeId: number): __Observable<string> {
    return this.CancelTerminateEmployeeResponse(employeeId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetTaxBeforeHireInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeTaxBeforeHireInfoModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/tax-before-hire-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeTaxBeforeHireInfoModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetTaxBeforeHireInfo(employeeId: number): __Observable<EmployeeTaxBeforeHireInfoModel> {
    return this.GetTaxBeforeHireInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeTaxBeforeHireInfoModel)
    );
  }

  /**
   * @param params The `EmployeeService.UpdateTaxBeforeHireInfoParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateTaxBeforeHireInfoResponse(params: EmployeeService.UpdateTaxBeforeHireInfoParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/tax-before-hire-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.UpdateTaxBeforeHireInfoParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateTaxBeforeHireInfo(params: EmployeeService.UpdateTaxBeforeHireInfoParams): __Observable<string> {
    return this.UpdateTaxBeforeHireInfoResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetTaxInfoResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeTaxInfoModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/tax-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeTaxInfoModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetTaxInfo(employeeId: number): __Observable<EmployeeTaxInfoModel> {
    return this.GetTaxInfoResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeTaxInfoModel)
    );
  }

  /**
   * @param params The `EmployeeService.UpdateTaxInfoParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateTaxInfoResponse(params: EmployeeService.UpdateTaxInfoParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/tax-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.UpdateTaxInfoParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateTaxInfo(params: EmployeeService.UpdateTaxInfoParams): __Observable<string> {
    return this.UpdateTaxInfoResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetFixPayElementResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeFixPayElementModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/fix-pay-elements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeFixPayElementModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetFixPayElement(employeeId: number): __Observable<EmployeeFixPayElementModel> {
    return this.GetFixPayElementResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeFixPayElementModel)
    );
  }

  /**
   * @param params The `EmployeeService.UpdateFixPayElementParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateFixPayElementResponse(params: EmployeeService.UpdateFixPayElementParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/fix-pay-elements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.UpdateFixPayElementParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateFixPayElement(params: EmployeeService.UpdateFixPayElementParams): __Observable<string> {
    return this.UpdateFixPayElementResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  IsAccessibleEmployeeResponse(employeeId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/isaccessible`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  IsAccessibleEmployee(employeeId: number): __Observable<boolean> {
    return this.IsAccessibleEmployeeResponse(employeeId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetAdditionalResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeAdditionalFieldPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/additional`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeAdditionalFieldPaginationResult>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetAdditional(employeeId: number): __Observable<EmployeeAdditionalFieldPaginationResult> {
    return this.GetAdditionalResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeAdditionalFieldPaginationResult)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetBranchResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeBranchModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(employeeId))}/branch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeBranchModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetBranch(employeeId: number): __Observable<EmployeeBranchModel> {
    return this.GetBranchResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeBranchModel)
    );
  }

  /**
   * @param params The `EmployeeService.UpdateBranchParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `branchId`:
   *
   * @return Success
   */
  UpdateBranchResponse(params: EmployeeService.UpdateBranchParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.branchId != null) __params = __params.set('branchId', params.branchId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/branch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeService.UpdateBranchParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `branchId`:
   *
   * @return Success
   */
  UpdateBranch(params: EmployeeService.UpdateBranchParams): __Observable<string> {
    return this.UpdateBranchResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeService {

  /**
   * Parameters for GetEmployees
   */
  export interface GetEmployeesParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsAllEmployee?: boolean;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    CompanyId?: number;
    BranchId?: number;
  }

  /**
   * Parameters for GetSubordinateOfEmployee
   */
  export interface GetSubordinateOfEmployeeParams {
    employeeId: number;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    EmployeeName?: string;
    EmployeeCode?: string;
  }

  /**
   * Parameters for TerminateEmployee
   */
  export interface TerminateEmployeeParams {
    employeeId: number;
    body?: EmployeeModel;
  }

  /**
   * Parameters for UpdatePvdInfo
   */
  export interface UpdatePvdInfoParams {
    employeeId: number;
    body?: EmployeePvdModel;
  }

  /**
   * Parameters for UpdatePersonalInfo
   */
  export interface UpdatePersonalInfoParams {
    employeeId: number;
    body?: EmployeePersonalInfoModel;
  }

  /**
   * Parameters for GetInPositions
   */
  export interface GetInPositionsParams {
    employeeId: number;
    isOrderedByDescending?: boolean;
  }

  /**
   * Parameters for UpdateEmployeeContact
   */
  export interface UpdateEmployeeContactParams {
    employeeId: number;
    body?: EmployeeContactInfoModel;
  }

  /**
   * Parameters for UpdateParent
   */
  export interface UpdateParentParams {
    employeeId: number;
    body?: EmployeeParentInfoModel;
  }

  /**
   * Parameters for UpdateOtherInfo
   */
  export interface UpdateOtherInfoParams {
    employeeId: number;
    body?: EmployeeOtherInfoModel;
  }

  /**
   * Parameters for UpdateEmployeeImage
   */
  export interface UpdateEmployeeImageParams {
    employeeId: number;
    imageDocumentId?: number;
  }

  /**
   * Parameters for UpdateTaxBeforeHireInfo
   */
  export interface UpdateTaxBeforeHireInfoParams {
    employeeId: number;
    body?: EmployeeTaxBeforeHireInfoModel;
  }

  /**
   * Parameters for UpdateTaxInfo
   */
  export interface UpdateTaxInfoParams {
    employeeId: number;
    body?: EmployeeTaxInfoModel;
  }

  /**
   * Parameters for UpdateFixPayElement
   */
  export interface UpdateFixPayElementParams {
    employeeId: number;
    body?: EmployeeFixPayElementModel;
  }

  /**
   * Parameters for UpdateBranch
   */
  export interface UpdateBranchParams {
    employeeId: number;
    branchId?: number;
  }
}

export { EmployeeService }
