/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeSalaryModel } from '../models/employee-salary-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeSalaryService extends __BaseService {
  static readonly GetEmployeeSalariesListPath = '/api/EmployeeSalary';
  static readonly CreateEmployeeSalaryPath = '/api/EmployeeSalary';
  static readonly GetEmployeeSalaryPath = '/api/EmployeeSalary/{EmployeeSalaryId}';
  static readonly UpdateEmployeeSalaryPath = '/api/EmployeeSalary/{EmployeeSalaryId}';
  static readonly DeleteEmployeeSalaryPath = '/api/EmployeeSalary/{EmployeeSalaryId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeSalariesListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeSalaryModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeSalary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeSalaryModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeSalariesList(): __Observable<Array<EmployeeSalaryModel>> {
    return this.GetEmployeeSalariesListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeSalaryModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeSalaryResponse(body?: EmployeeSalaryModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeSalary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeSalary(body?: EmployeeSalaryModel): __Observable<string> {
    return this.CreateEmployeeSalaryResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeSalaryId undefined
   * @return Success
   */
  GetEmployeeSalaryResponse(EmployeeSalaryId: number): __Observable<__StrictHttpResponse<EmployeeSalaryModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeSalary/${encodeURIComponent(String(EmployeeSalaryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeSalaryModel>;
      })
    );
  }
  /**
   * @param EmployeeSalaryId undefined
   * @return Success
   */
  GetEmployeeSalary(EmployeeSalaryId: number): __Observable<EmployeeSalaryModel> {
    return this.GetEmployeeSalaryResponse(EmployeeSalaryId).pipe(
      __map(_r => _r.body as EmployeeSalaryModel)
    );
  }

  /**
   * @param params The `EmployeeSalaryService.UpdateEmployeeSalaryParams` containing the following parameters:
   *
   * - `EmployeeSalaryId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeSalaryResponse(params: EmployeeSalaryService.UpdateEmployeeSalaryParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeSalary/${encodeURIComponent(String(params.EmployeeSalaryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeSalaryService.UpdateEmployeeSalaryParams` containing the following parameters:
   *
   * - `EmployeeSalaryId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeSalary(params: EmployeeSalaryService.UpdateEmployeeSalaryParams): __Observable<string> {
    return this.UpdateEmployeeSalaryResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeSalaryId undefined
   * @return Success
   */
  DeleteEmployeeSalaryResponse(EmployeeSalaryId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeSalary/${encodeURIComponent(String(EmployeeSalaryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeSalaryId undefined
   * @return Success
   */
  DeleteEmployeeSalary(EmployeeSalaryId: number): __Observable<string> {
    return this.DeleteEmployeeSalaryResponse(EmployeeSalaryId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeSalaryService {

  /**
   * Parameters for UpdateEmployeeSalary
   */
  export interface UpdateEmployeeSalaryParams {
    EmployeeSalaryId: number;
    body?: EmployeeSalaryModel;
  }
}

export { EmployeeSalaryService }
