/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeCardModel } from '../models/employee-card-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeCardService extends __BaseService {
  static readonly GetEmployeeCardsListPath = '/api/EmployeeCard';
  static readonly CreateEmployeeCardPath = '/api/EmployeeCard';
  static readonly UpdateEmployeeCardPath = '/api/EmployeeCard';
  static readonly GetEmployeeCardByIdPath = '/api/EmployeeCard/{EmployeeCardId}';
  static readonly DeleteEmployeeCardPath = '/api/EmployeeCard/{EmployeeCardId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeCardsListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeCardModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeCard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeCardModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeCardsList(): __Observable<Array<EmployeeCardModel>> {
    return this.GetEmployeeCardsListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeCardModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeCardResponse(body?: EmployeeCardModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeCard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeCard(body?: EmployeeCardModel): __Observable<string> {
    return this.CreateEmployeeCardResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeCardResponse(body?: EmployeeCardModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeCard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeCard(body?: EmployeeCardModel): __Observable<string> {
    return this.UpdateEmployeeCardResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeCardId undefined
   * @return Success
   */
  GetEmployeeCardByIdResponse(EmployeeCardId: number): __Observable<__StrictHttpResponse<EmployeeCardModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeCard/${encodeURIComponent(String(EmployeeCardId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeCardModel>;
      })
    );
  }
  /**
   * @param EmployeeCardId undefined
   * @return Success
   */
  GetEmployeeCardById(EmployeeCardId: number): __Observable<EmployeeCardModel> {
    return this.GetEmployeeCardByIdResponse(EmployeeCardId).pipe(
      __map(_r => _r.body as EmployeeCardModel)
    );
  }

  /**
   * @param EmployeeCardId undefined
   * @return Success
   */
  DeleteEmployeeCardResponse(EmployeeCardId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeCard/${encodeURIComponent(String(EmployeeCardId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeCardId undefined
   * @return Success
   */
  DeleteEmployeeCard(EmployeeCardId: number): __Observable<string> {
    return this.DeleteEmployeeCardResponse(EmployeeCardId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeCardService {
}

export { EmployeeCardService }
