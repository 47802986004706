import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CompanyService, EmployeeService, LocationService, RoleService } from 'app/api/services';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { toOptionList } from 'utils/formly-utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { FilterSettingComponent } from 'components/foundation/filter-setting/filter-setting.component';
import { EWagePayType } from 'app/api/models';
import * as moment from 'moment';
import { getYearOptionList } from '../../../shared/defaultValues';

export enum FileType {
  NotSpecified,
  Excel,
  Pdf,
}

export enum ReportItemField {
  EmployeeCode,
  EmployeeName,
  Company,
  BeginDate,
  EndDate,
  AsOfDate,
  AsOfMonth,
  PersonnelArea,
  Location,
  Unit,
  Position,
  Year,
  Month,
  EmployeeTypeId,
  WagePayType,
  ExportPayElementFields,
  FileType,
  Role,
}

export interface FilterFormModel {
  roleid?: number;
  companyId?: number;
  employeeName?: string;
  employeeCode?: string;
  unitId?: number;
  year?: number;
  positionId?: number;
  locationId?: number;
  beginDate?: string;
  endDate?: string;
  asOfDate?: string;
  asOfMonth?: string;
  employeeTypeId?: string[];
  exportPayElementFields?: string[];
  wagePayType?: EWagePayType;
  reportType?: string;
  fileType?: FileType;
}

interface FilterFieldConfig extends FormlyFieldConfig {
  key?: keyof FilterFormModel;
  fieldGroup?: FilterFieldConfig[];
}

@Component({
  selector: 'app-report-config-modal-module',
  templateUrl: './report-config-modal-module.component.html',
  styleUrls: ['./report-config-modal-module.component.css'],
})
export class ReportConfigModalModuleComponent implements OnInit {
  @ViewChild('filterComponent') filterSettingComponent: FilterSettingComponent;
  companyIdChange = new EventEmitter<any>();
  filterFields: ReportItemField[] = this.dialogConfig.data.filterFields;

  form = new FormGroup({});
  filterModel: FilterFormModel = {
    fileType: FileType.NotSpecified
  };
  options = {};
  fields: FilterFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'beginDate',
          type: 'calendar',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            label: 'ช่วงวันที่',
            labelClass: 'col-4',
            inputClass: 'col-8',
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.BeginDate) === -1
          || this.dialogConfig.data?.code == "T20",
        },
        {
          key: 'endDate',
          type: 'calendar',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            label: 'ถึงวันที่',
            labelClass: 'col-4',
            inputClass: 'col-8',
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.EndDate) === -1
          || this.dialogConfig.data?.code == "T20",
        },
        {
          key: 'year',
          type: 'filter-dropdown',
          defaultValue: new Date().getFullYear(),
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'year',
            placeholder: '@blank',
            labelClass: 'col-4',
            inputClass: 'col-8',
            options: getYearOptionList()
          },
          hideExpression: () => this.dialogConfig.data?.code != "T20",
        },
        {
          key: 'asOfDate',
          type: 'calendar',
          wrappers: ['inline-label'],
          className: 'col-6',
          defaultValue: new Date().toISOString(),
          templateOptions: {
            label: 'ณ วันที่',
            labelClass: 'col-4',
            inputClass: 'col-8',
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.AsOfDate) === -1,
        },
        {
          key: 'asOfMonth',
          type: 'month-picker',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            label: 'ณ เดือนที่',
            labelClass: 'col-4',
            inputClass: 'col-8',
            required: true,
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.AsOfMonth) === -1,
        },
        {
          key: 'companyId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            label: 'บริษัท:',
            placeholder: 'บริษัท',
            labelClass: 'col-4',
            inputClass: 'col-8',
            filter: true,
            options: this.companyService
              .GetCompaniesList({})
              .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'companyId', 'name')))),
            change: (_, value) => {
              debugger;
              this.onCompanyIdChange(value);
            },
          },
          hooks: {
            afterViewInit: field => {
              // query company list
              // this.companyService
              //   .GetCompaniesList({})
              //   .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'companyId', 'name'))))
              //   .subscribe(items => {
              //     field.templateOptions.options = items;
              //   });
              // field.formControl.valueChanges.pipe(debounceTime(300)).subscribe(companyId => {
              //   this.onCompanyIdChange(companyId);
              // });
            },
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.Company) === -1,
        },
        {
          key: 'employeeName',
          type: 'auto-complete',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            label: 'ชื่อพนักงาน:',
            placeholder: 'ชื่อพนักงาน',
            labelClass: 'col-4',
            inputClass: 'col-8',
            suggestions: [],
            completeMethod: (field: FormlyFieldConfig, event) => {
              this.employeeService
                .GetEmployees({
                  Page: 1,
                  ItemPerPage: 10,
                  EmployeeName: event.query,
                  CompanyId: this.filterModel.companyId,
                  UnitId: this.filterModel.unitId,
                })
                .subscribe(pageResult => {
                  field.templateOptions.suggestions = pageResult.results.map(emp => {
                    return (emp.employeeName?.firstnameTh || '') + ' ' + (emp.employeeName?.lastnameTh || '');
                  });
                });
            },
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.EmployeeName) === -1,
        },
        {
          key: 'employeeCode',
          type: 'auto-complete',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            label: 'รหัสพนักงาน:',
            placeholder: 'รหัสพนักงาน',
            labelClass: 'col-4',
            inputClass: 'col-8',
            suggestions: [],
            completeMethod: (field: FormlyFieldConfig, event) => {
              this.employeeService
                .GetEmployees({
                  Page: 1,
                  ItemPerPage: 10,
                  EmployeeCode: event.query,
                  CompanyId: this.filterModel.companyId,
                  UnitId: this.filterModel.unitId,
                })
                .subscribe(pageResult => {
                  field.templateOptions.suggestions = pageResult.results.map(emp => {
                    return emp.employeeCode;
                  });
                });
            },
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.EmployeeCode) === -1,
        },
        {
          key: 'fileType',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          defaultValue: FileType.Pdf,
          templateOptions: {
            label: 'ประเภทไฟล์:',
            placeholder: '.pdf',
            labelClass: 'col-4',
            inputClass: 'col-8',
            suggestions: [],
            options: [
              { label: '.pdf', value: FileType.Pdf },
              { label: '.xlsx', value: FileType.Excel },
            ],
            required: true,
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.FileType) === -1
          || this.dialogConfig.data?.code == "T20",
        },
        {
          key: 'unitId',
          type: 'select-unit',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            label: 'หน่วยงาน:',
            labelClass: 'col-4',
            inputClass: 'col-8',
            companyChange: this.companyIdChange,
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.Unit) === -1,
        },
        {
          key: 'positionId',
          type: 'select-position-2',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            label: 'ตำแหน่ง:',
            placeholder: 'ตำแหน่ง',
            labelClass: 'col-4',
            inputClass: 'col-8',
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.Position) === -1,
        },
        {
          key: 'locationId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            label: 'working_area',
            translate: true,
            placeholder: '@blank',
            labelClass: 'col-4',
            inputClass: 'col-8',
            filter: true,
            options: this.locationService.GetLocationsList({}).pipe(
              map(pageResult => {
                return pageResult.results.map(location => {
                  return {
                    label: location.name,
                    value: location.locationId,
                  };
                });
              }),
            ),
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.Location) === -1,
        },
        {
          type: 'multi-select',
          key: 'employeeTypeId',
          wrappers: ['inline-label'],
          className: 'col-6',
          hideExpression: () => this.filterFields.indexOf(ReportItemField.EmployeeTypeId) === -1,
          templateOptions: {
            label: 'ประเภทการทำงาน:',
            placeholder: 'เลือกประเภทการทำงาน',
            labelClass: 'col-4',
            inputClass: 'col-8',
            options: this.masterDataRepo.getEmployeeTypeOptionList(),
          },
        },
        {
          type: 'filter-dropdown',
          key: 'wagePayType',
          wrappers: ['inline-label'],
          className: 'col-6',
          hideExpression: () => this.filterFields.indexOf(ReportItemField.WagePayType) === -1,
          templateOptions: {
            label: 'รอบการจ่าย:',
            placeholder: 'เลือกรอบการจ่าย',
            labelClass: 'col-4',
            inputClass: 'col-8',
            options: this.masterDataRepo.getWagePayTypesOptionList(),
          },
        },
        {
          key: 'exportPayElementFields',
          type: 'multi-select',
          wrappers: ['inline-label'],
          className: 'col-12',
          hideExpression: () => this.filterFields.indexOf(ReportItemField.ExportPayElementFields) === -1,
          templateOptions: {
            label: 'คอลัมน์ที่จะส่งออก',
            placeholder: 'เลือกคอลัมน์',
            labelClass: 'col-2',
            inputClass: 'col-10',
            maxSelectedLabels: 8,
            options: this.optionListRepo.getPayElementExportFieldList(),
          },
        },
        {
          key: 'roleid',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'role',
            placeholder: 'เลือก Role',
            labelClass: 'col-4',
            inputClass: 'col-8',
            filter: true,
            options: this.optionListRepo.getRoleOptionList(),
          },
          hideExpression: () => this.filterFields.indexOf(ReportItemField.Role) === -1,
        },
        // {
        //   key: 'roleid',
        //   type: 'filter-dropdown',
        //   wrappers: ['inline-label'],
        //   className: 'col-6',
        //   templateOptions: {
        //     translate: true,
        //     label: 'role',
        //     placeholder: 'เลือก Role',
        //     labelClass: 'col-4',
        //     inputClass: 'col-8',
        //     options: this.optionListRepo.getRoleOptionList(),
        //   },
        // },
      ],
    },
  ];

  constructor(
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private optionListRepo: OptionListRepo,
    private masterDataRepo: MasterDataRepo,
    private locationService: LocationService,
    private roleService: RoleService,
  ) {}

  ngOnInit(): void {
    this.filterModel = {
      beginDate: moment().format('YYYY-MM-01'),
      endDate: moment().format('YYYY-MM-DD'),
    };
  }

  onCompanyIdChange(companyId: number) {
    if (this.form.controls.unitId) {
      this.form.controls.unitId.reset();
    }
    this.companyIdChange.emit(companyId);
  }

  download(): void {
    this.filterSettingComponent.upsertFilterSetting().subscribe();
    this.dialogRef.close(this.filterModel);
  }

  close(): void {
    this.dialogRef.close();
  }

  getSettingStr() {
    return JSON.stringify({ ...this.filterModel });
  }

  getExtraUrl() {
    return '/' + this.dialogConfig.data?.code;
  }

  onClearFilterIconClick() {
    this.filterModel = {};
    this.companyIdChange.emit();
  }

  applyFilter(fullFilter: FilterFormModel) {
    this.filterModel = {
      ...fullFilter,
      beginDate: fullFilter.beginDate,
      endDate: fullFilter.endDate,
    };
    console.log({ ...fullFilter });
    this.companyIdChange.emit(fullFilter?.companyId);
  }
}
