import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'shared/shared.module';
import { ComponentsModule } from 'components/components.module';
import { PipeModule } from 'pipes/pipe.module';
import { PrivacyPolicyComponent } from './privacy-policy.component';

@NgModule({
  declarations: [PrivacyPolicyComponent],
  imports: [CommonModule, SharedModule, ComponentsModule, PipeModule],
  exports: [PrivacyPolicyComponent],
})
export class PrivacyPolicyModule {}
