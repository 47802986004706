/* tslint:disable */
type EAttendanceCertifyRequestStatus =
  'Draft' |
  'Editing' |
  'Awaiting' |
  'Approved' |
  'Cancel' |
  'Reject';
module EAttendanceCertifyRequestStatus {
  export const DRAFT: EAttendanceCertifyRequestStatus = 'Draft';
  export const EDITING: EAttendanceCertifyRequestStatus = 'Editing';
  export const AWAITING: EAttendanceCertifyRequestStatus = 'Awaiting';
  export const APPROVED: EAttendanceCertifyRequestStatus = 'Approved';
  export const CANCEL: EAttendanceCertifyRequestStatus = 'Cancel';
  export const REJECT: EAttendanceCertifyRequestStatus = 'Reject';
  export function values(): EAttendanceCertifyRequestStatus[] {
    return [
      DRAFT,
      EDITING,
      AWAITING,
      APPROVED,
      CANCEL,
      REJECT
    ];
  }
}

export { EAttendanceCertifyRequestStatus }