import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Menu } from 'primeng/menu';
import { MenuItem, SelectItem } from 'primeng/api';
import { EventPageChange } from '../../../interfaces/EventPageChange';
import { PaginationParams } from '../../../interfaces/PaginationParams';
import { EmployeeDisplayModel } from '../../../models/employee-display-model';

@Component({
  selector: 'app-employee-list-card',
  templateUrl: './employee-list-card.component.html',
  styleUrls: ['./employee-list-card.component.css'],
})
export class EmployeeListCardComponent implements OnInit, OnChanges {
  @Input() employeeList: EmployeeDisplayModel[];
  @Input() paginationParams: PaginationParams;
  @Input() loading: boolean;
  @Input() employeeMenuItems: MenuItem[];
  @Output() pageChangeEvent = new EventEmitter<EventPageChange>();
  @Output() selectedEmployeeEvent = new EventEmitter<EmployeeDisplayModel>();
  isFirtInit = true;
  pageSelectList: SelectItem[] = [];

  dateLateUpdate = new Date();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paginationParams && changes.paginationParams.currentValue && this.isFirtInit) {
      this.isFirtInit = false;
      this.pageSelectList = ((changes.paginationParams
        .currentValue as any) as PaginationParams).rowPerPageSelectList.map(v => {
        return {
          value: v,
          label: v.toString(),
        };
      });
    }
  }

  getLinkProfileImage(employeeId): string {
    return '/api/employee/' + employeeId + '/image?' + this.dateLateUpdate;
  }

  onPageChange(rowPerPage: number) {
    this.pageChangeEvent.next({
      rows: rowPerPage,
    });
  }

  onPrePageClick() {
    this.pageChangeEvent.next({
      rows: this.paginationParams.rowPerPage,
      first: this.paginationParams.firstRowIdx - this.paginationParams.rowPerPage,
    });
  }

  onNextPageClick() {
    this.pageChangeEvent.next({
      rows: this.paginationParams.rowPerPage,
      first: this.paginationParams.firstRowIdx + this.paginationParams.rowPerPage,
    });
  }

  onEllipsisIconClick(e, menu: Menu, employee: EmployeeDisplayModel) {
    this.selectedEmployeeEvent.emit(employee);

    menu.toggle(e);
  }
}
