<div #blockTarget class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <form [formGroup]="form">
        <formly-form [form]="form" [fields]="fields" [model]="filterModel"></formly-form>
      </form>
    </div>
  </div>
  <div class="grid" style="margin-top: 2%">
    <div class="col">
    </div>
    <div class="col-fixed">
      <app-filter-setting
        #filterComponent
        [getSettingStr]="getSettingStr.bind(this)"
        [getExraUrl]="getExtraUrl.bind(this)"
        (filterChange)="applyFilter($event)"
      ></app-filter-setting>
    </div>
    <div class="col-fixed" style="padding-top: 0%">
      <div class="col-fixed">
        <button
          pButton
          type="button"
          icon="pi pi-replay"
          iconPos="left"
          label="{{ 'clear_filter' | translate }}"
          (click)="onClearFilterIconClick()"
        ></button>
      </div>
    </div>
  </div>
</div>
<div class="p-dialog-footer">
  <button
    type="button"
    pButton
    class="p-button-primary"
    label="{{ 'download' | translate }}"
    (click)="download()"
  ></button>
  <button
    type="button"
    pButton
    class="p-button-secondary"
    label="{{ 'cancel' | translate }}"
    (click)="close()"
  ></button>
</div>
