import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { JobFamilyService } from 'app/api/services';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { JobFamilyModel } from 'app/api/models';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'app-modal-job-families',
  templateUrl: './modal-job-families.component.html',
  styleUrls: ['./modal-job-families.component.css'],
})
export class ModalJobFamiliesComponent implements OnInit, OnDestroy {
  model: JobFamilyModel = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'code',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'code_jf',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4',
        maxLength: 8,
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'name_jf',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'description',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'description',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$: Subject<any> = new Subject();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private jobfamilyService: JobFamilyService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const jobFamilyId = this.getJobFamilyId();

    if (jobFamilyId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {};
      return;
    }

    this.model = await this.jobfamilyService.GetJobFamily_1(this.getJobFamilyId()).toPromise();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createJobFamily()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateJobFamily()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createJobFamily() {
    return this.jobfamilyService.CreateJobFamily({
      code: this.model.code,
      name: this.model.name,
      description: this.model.description,
    });
  }

  private updateJobFamily() {
    return this.jobfamilyService.UpdateJobFamily({
      jobFamilyId: this.model.jobFamilyId,
      body: {
        jobFamilyId: this.model.jobFamilyId,
        code: this.model.code,
        name: this.model.name,
        description: this.model.description,
        createdBy: this.model.createdBy,
        createdDate: this.model.createdDate,
        status: this.model.status,
      },
    });
  }

  getJobFamilyId() {
    return this.config.data?.id;
  }
}
