/* tslint:disable */
type EBPlusSyncType =
  'CreateEmployee' |
  'UpdateEmployee' |
  'Promote' |
  'Resign' |
  'CreatePayroll';
module EBPlusSyncType {
  export const CREATE_EMPLOYEE: EBPlusSyncType = 'CreateEmployee';
  export const UPDATE_EMPLOYEE: EBPlusSyncType = 'UpdateEmployee';
  export const PROMOTE: EBPlusSyncType = 'Promote';
  export const RESIGN: EBPlusSyncType = 'Resign';
  export const CREATE_PAYROLL: EBPlusSyncType = 'CreatePayroll';
  export function values(): EBPlusSyncType[] {
    return [
      CREATE_EMPLOYEE,
      UPDATE_EMPLOYEE,
      PROMOTE,
      RESIGN,
      CREATE_PAYROLL
    ];
  }
}

export { EBPlusSyncType }