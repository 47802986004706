/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OTModelPaginationResult } from '../models/otmodel-pagination-result';
import { OTModel } from '../models/otmodel';
import { CalculateRequest } from '../models/calculate-request';
@Injectable({
  providedIn: 'root',
})
class OTService extends __BaseService {
  static readonly GetOTsListPath = '/api/OT';
  static readonly GetOTByIdPath = '/api/OT/{OTId}';
  static readonly GetEmployeeOTPath = '/api/Employee/{employeeId}/ot';
  static readonly CalculateOTPath = '/api/CalculateOT';
  static readonly CalculateOtherOTPath = '/api/CalculateOtherOT';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OTService.GetOTsListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetOTsListResponse(params: OTService.GetOTsListParams): __Observable<__StrictHttpResponse<OTModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OT`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `OTService.GetOTsListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetOTsList(params: OTService.GetOTsListParams): __Observable<OTModelPaginationResult> {
    return this.GetOTsListResponse(params).pipe(
      __map(_r => _r.body as OTModelPaginationResult)
    );
  }

  /**
   * @param OTId undefined
   * @return Success
   */
  GetOTByIdResponse(OTId: number): __Observable<__StrictHttpResponse<OTModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OT/${encodeURIComponent(String(OTId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTModel>;
      })
    );
  }
  /**
   * @param OTId undefined
   * @return Success
   */
  GetOTById(OTId: number): __Observable<OTModel> {
    return this.GetOTByIdResponse(OTId).pipe(
      __map(_r => _r.body as OTModel)
    );
  }

  /**
   * @param params The `OTService.GetEmployeeOTParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetEmployeeOTResponse(params: OTService.GetEmployeeOTParams): __Observable<__StrictHttpResponse<OTModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/ot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `OTService.GetEmployeeOTParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetEmployeeOT(params: OTService.GetEmployeeOTParams): __Observable<OTModelPaginationResult> {
    return this.GetEmployeeOTResponse(params).pipe(
      __map(_r => _r.body as OTModelPaginationResult)
    );
  }

  /**
   * @param params The `OTService.CalculateOTParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `EmployeeId`:
   *
   * @return Success
   */
  CalculateOTResponse(params: OTService.CalculateOTParams): __Observable<__StrictHttpResponse<OTModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CalculateOT`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `OTService.CalculateOTParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `EmployeeId`:
   *
   * @return Success
   */
  CalculateOT(params: OTService.CalculateOTParams): __Observable<OTModelPaginationResult> {
    return this.CalculateOTResponse(params).pipe(
      __map(_r => _r.body as OTModelPaginationResult)
    );
  }

  /**
   * @param params The `OTService.CalculateOtherOTParams` containing the following parameters:
   *
   * - `date`:
   *
   * - `body`:
   *
   * @return Success
   */
  CalculateOtherOTResponse(params: OTService.CalculateOtherOTParams): __Observable<__StrictHttpResponse<OTModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.date != null) __params = __params.set('date', params.date.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CalculateOtherOT`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `OTService.CalculateOtherOTParams` containing the following parameters:
   *
   * - `date`:
   *
   * - `body`:
   *
   * @return Success
   */
  CalculateOtherOT(params: OTService.CalculateOtherOTParams): __Observable<OTModelPaginationResult> {
    return this.CalculateOtherOTResponse(params).pipe(
      __map(_r => _r.body as OTModelPaginationResult)
    );
  }
}

module OTService {

  /**
   * Parameters for GetOTsList
   */
  export interface GetOTsListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for GetEmployeeOT
   */
  export interface GetEmployeeOTParams {
    employeeId: number;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for CalculateOT
   */
  export interface CalculateOTParams {
    body?: CalculateRequest;
    EmployeeId?: number;
  }

  /**
   * Parameters for CalculateOtherOT
   */
  export interface CalculateOtherOTParams {
    date?: string;
    body?: CalculateRequest;
  }
}

export { OTService }
