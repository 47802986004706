/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GenderChartModel } from '../models/gender-chart-model';
import { DashboardFilterModel } from '../models/dashboard-filter-model';
import { LocationChartModel } from '../models/location-chart-model';
import { EducationChartModel } from '../models/education-chart-model';
import { YearsOfServiceChartModel } from '../models/years-of-service-chart-model';
import { RetireChartModel } from '../models/retire-chart-model';
import { YearOfJobGradeModel } from '../models/year-of-job-grade-model';
import { OtPerTotalWorkingHourModel } from '../models/ot-per-total-working-hour-model';
import { LabourCostModel } from '../models/labour-cost-model';
import { MMLogisticExpenseModel } from '../models/mmlogistic-expense-model';
@Injectable({
  providedIn: 'root',
})
class DemographicService extends __BaseService {
  static readonly GetGenderChartDataPath = '/api/Demographic/genderChart';
  static readonly GetLocationChartDataPath = '/api/Demographic/locationChart';
  static readonly GetEducationChartDataPath = '/api/Demographic/educationChart';
  static readonly GetYearsOfServiceChartDataPath = '/api/Demographic/yearofServiceChart';
  static readonly GetRetireChartDataPath = '/api/Demographic/retireChart';
  static readonly GetYearOfJobGradePath = '/api/Demographic/yearsOfJobGrade';
  static readonly GetOTPerTotalWorkingHourPath = '/api/Demographic/otPerTotalWorkingHour';
  static readonly GetLabourCostPerEmployeeDataPath = '/api/Demographic/labourCostPerEmployee';
  static readonly GetMMLogisticDataPath = '/api/Demographic/mmLogistic';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetGenderChartDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<Array<GenderChartModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Demographic/genderChart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GenderChartModel>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetGenderChartData(body?: DashboardFilterModel): __Observable<Array<GenderChartModel>> {
    return this.GetGenderChartDataResponse(body).pipe(
      __map(_r => _r.body as Array<GenderChartModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetLocationChartDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<Array<LocationChartModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Demographic/locationChart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LocationChartModel>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetLocationChartData(body?: DashboardFilterModel): __Observable<Array<LocationChartModel>> {
    return this.GetLocationChartDataResponse(body).pipe(
      __map(_r => _r.body as Array<LocationChartModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetEducationChartDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<EducationChartModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Demographic/educationChart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EducationChartModel>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetEducationChartData(body?: DashboardFilterModel): __Observable<EducationChartModel> {
    return this.GetEducationChartDataResponse(body).pipe(
      __map(_r => _r.body as EducationChartModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetYearsOfServiceChartDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<YearsOfServiceChartModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Demographic/yearofServiceChart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<YearsOfServiceChartModel>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetYearsOfServiceChartData(body?: DashboardFilterModel): __Observable<YearsOfServiceChartModel> {
    return this.GetYearsOfServiceChartDataResponse(body).pipe(
      __map(_r => _r.body as YearsOfServiceChartModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetRetireChartDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<Array<RetireChartModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Demographic/retireChart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RetireChartModel>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetRetireChartData(body?: DashboardFilterModel): __Observable<Array<RetireChartModel>> {
    return this.GetRetireChartDataResponse(body).pipe(
      __map(_r => _r.body as Array<RetireChartModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetYearOfJobGradeResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<Array<YearOfJobGradeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Demographic/yearsOfJobGrade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<YearOfJobGradeModel>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetYearOfJobGrade(body?: DashboardFilterModel): __Observable<Array<YearOfJobGradeModel>> {
    return this.GetYearOfJobGradeResponse(body).pipe(
      __map(_r => _r.body as Array<YearOfJobGradeModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetOTPerTotalWorkingHourResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<OtPerTotalWorkingHourModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Demographic/otPerTotalWorkingHour`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OtPerTotalWorkingHourModel>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetOTPerTotalWorkingHour(body?: DashboardFilterModel): __Observable<OtPerTotalWorkingHourModel> {
    return this.GetOTPerTotalWorkingHourResponse(body).pipe(
      __map(_r => _r.body as OtPerTotalWorkingHourModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetLabourCostPerEmployeeDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<LabourCostModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Demographic/labourCostPerEmployee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LabourCostModel>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetLabourCostPerEmployeeData(body?: DashboardFilterModel): __Observable<LabourCostModel> {
    return this.GetLabourCostPerEmployeeDataResponse(body).pipe(
      __map(_r => _r.body as LabourCostModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetMMLogisticDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<MMLogisticExpenseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Demographic/mmLogistic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MMLogisticExpenseModel>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetMMLogisticData(body?: DashboardFilterModel): __Observable<MMLogisticExpenseModel> {
    return this.GetMMLogisticDataResponse(body).pipe(
      __map(_r => _r.body as MMLogisticExpenseModel)
    );
  }
}

module DemographicService {
}

export { DemographicService }
