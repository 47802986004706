import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeNameModel } from 'app/api/models';
import { EmployeeNameService } from 'app/api/services';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';

import { MasterDataRepo } from 'app/repo/master-data.repo';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeNameModel;
}

@Component({
  selector: 'app-modal-employee-name',
  templateUrl: './modal-employee-name.component.html',
  styleUrls: ['./modal-employee-name.component.css'],
})
export class ModalEmployeeNameComponent implements OnInit, OnDestroy {
  model: EmployeeNameModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'titleTh',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'prefix_th',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        // options: this.masterDataRepo.getTitleThOptionList(),
      },
    },
    {
      key: 'firstnameTh',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'name_th',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'middlenameTh',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'middle_name_th',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'lastnameTh',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        required: true,
        label: 'surname_th',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'titleEn',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        required: true,
        label: 'prefix_en',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
        // options: this.masterDataRepo.getTitleEnOptionList(),
      },
    },
    {
      key: 'firstnameEn',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        required: true,
        label: 'name_en',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'middlenameEn',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'middle_name_en',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'lastnameEn',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        required: true,
        label: 'surname_en',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'effective_date',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeNameService: EmployeeNameService,
    private masterDataRepo: MasterDataRepo,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const employeeNameId = this.getEmployeeNameId();

    if (employeeNameId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'titleTh').templateOptions.options = await this.masterDataRepo
      .getTitleThOptionList()
      .toPromise();
    this.fields.find(a => a.key === 'titleEn').templateOptions.options = await this.masterDataRepo
      .getTitleEnOptionList()
      .toPromise();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    const res = await this.employeeNameService.GetEmployeeNameById(this.getEmployeeNameId()).toPromise();

    this.model = {
      ...res,
      effectiveDate: (res.effectiveDate),
    };
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createEmployeeName()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateEmployeeName()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createEmployeeName() {
    return this.employeeNameService.CreateEmployeeName({
      employeeId: this.model.employeeId,
      titleTh: this.model.titleTh,
      firstnameTh: this.model.firstnameTh,
      middlenameTh: this.model.middlenameTh,
      lastnameTh: this.model.lastnameTh,
      titleEn: this.model.titleEn,
      firstnameEn: this.model.firstnameEn,
      middlenameEn: this.model.middlenameEn,
      lastnameEn: this.model.lastnameEn,
      effectiveDate: this.model.effectiveDate,
    });
  }

  private updateEmployeeName() {
    return this.employeeNameService.UpdateEmployeeName({
      employeeId: this.model.employeeId,
      employeeNameId: this.model.employeeNameId,
      titleTh: this.model.titleTh,
      firstnameTh: this.model.firstnameTh,
      middlenameTh: this.model.middlenameTh,
      lastnameTh: this.model.lastnameTh,
      titleEn: this.model.titleEn,
      firstnameEn: this.model.firstnameEn,
      middlenameEn: this.model.middlenameEn,
      lastnameEn: this.model.lastnameEn,
      effectiveDate: this.model.effectiveDate,
      changeReason: this.model.changeReason,
      status: this.model.status,
    });
  }

  getEmployeeNameId() {
    return this.config.data?.id;
  }
}
