/* tslint:disable */
type EPositionTakingType =
  'Main' |
  'ActFor';
module EPositionTakingType {
  export const MAIN: EPositionTakingType = 'Main';
  export const ACT_FOR: EPositionTakingType = 'ActFor';
  export function values(): EPositionTakingType[] {
    return [
      MAIN,
      ACT_FOR
    ];
  }
}

export { EPositionTakingType }