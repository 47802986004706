import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppResetComponent } from './reset-password/app.reset.component';
import { AppLoginComponent } from './login/app.login.component';
import { ComponentsModule } from '../../../components/components.module';
import { SharedModule } from '../../../shared/shared.module';
import { AppForgetComponent } from './forget-password/app.forget.component';



@NgModule({
  declarations: [
    AppForgetComponent,
    AppLoginComponent,
    AppResetComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
  ]
})
export class AccountModule { }
