/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApprovalCenterModelPaginationResult } from '../models/approval-center-model-pagination-result';
@Injectable({
  providedIn: 'root',
})
class ApprovalCenterService extends __BaseService {
  static readonly GetApprovalListPath = '/api/ApprovalCenter';
  static readonly DeleteNewEmployeePath = '/api/ApprovalCenter/{RequestId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ApprovalCenterService.GetApprovalListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestTypeId`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsDisplayResignedRequester`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetApprovalListResponse(params: ApprovalCenterService.GetApprovalListParams): __Observable<__StrictHttpResponse<ApprovalCenterModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RequestTypeId != null) __params = __params.set('RequestTypeId', params.RequestTypeId.toString());
    if (params.PersonnelAreaId != null) __params = __params.set('PersonnelAreaId', params.PersonnelAreaId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsDisplayResignedRequester != null) __params = __params.set('IsDisplayResignedRequester', params.IsDisplayResignedRequester.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ApprovalCenter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApprovalCenterModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `ApprovalCenterService.GetApprovalListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestTypeId`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsDisplayResignedRequester`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetApprovalList(params: ApprovalCenterService.GetApprovalListParams): __Observable<ApprovalCenterModelPaginationResult> {
    return this.GetApprovalListResponse(params).pipe(
      __map(_r => _r.body as ApprovalCenterModelPaginationResult)
    );
  }

  /**
   * @param RequestId undefined
   * @return Success
   */
  DeleteNewEmployeeResponse(RequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ApprovalCenter/${encodeURIComponent(String(RequestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param RequestId undefined
   * @return Success
   */
  DeleteNewEmployee(RequestId: number): __Observable<string> {
    return this.DeleteNewEmployeeResponse(RequestId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module ApprovalCenterService {

  /**
   * Parameters for GetApprovalList
   */
  export interface GetApprovalListParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    RequestTypeId?: any;
    PersonnelAreaId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsDisplayResignedRequester?: boolean;
    CompanyId?: number;
  }
}

export { ApprovalCenterService }
