/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HolidayModelPaginationResult } from '../models/holiday-model-pagination-result';
import { HolidayModel } from '../models/holiday-model';
@Injectable({
  providedIn: 'root',
})
class HolidayService extends __BaseService {
  static readonly GetHolidaysListPath = '/api/Holiday';
  static readonly CreateHolidayPath = '/api/Holiday';
  static readonly UpdateHolidayPath = '/api/Holiday';
  static readonly GetHolidayByIdPath = '/api/Holiday/{holidayId}';
  static readonly DeleteHolidayPath = '/api/Holiday/{holidayId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `HolidayService.GetHolidaysListParams` containing the following parameters:
   *
   * - `Year`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetHolidaysListResponse(params: HolidayService.GetHolidaysListParams): __Observable<__StrictHttpResponse<HolidayModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Year != null) __params = __params.set('Year', params.Year.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Holiday`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HolidayModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `HolidayService.GetHolidaysListParams` containing the following parameters:
   *
   * - `Year`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetHolidaysList(params: HolidayService.GetHolidaysListParams): __Observable<HolidayModelPaginationResult> {
    return this.GetHolidaysListResponse(params).pipe(
      __map(_r => _r.body as HolidayModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateHolidayResponse(body?: HolidayModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Holiday`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateHoliday(body?: HolidayModel): __Observable<string> {
    return this.CreateHolidayResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateHolidayResponse(body?: HolidayModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Holiday`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateHoliday(body?: HolidayModel): __Observable<string> {
    return this.UpdateHolidayResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param holidayId undefined
   * @return Success
   */
  GetHolidayByIdResponse(holidayId: number): __Observable<__StrictHttpResponse<HolidayModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Holiday/${encodeURIComponent(String(holidayId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HolidayModel>;
      })
    );
  }
  /**
   * @param holidayId undefined
   * @return Success
   */
  GetHolidayById(holidayId: number): __Observable<HolidayModel> {
    return this.GetHolidayByIdResponse(holidayId).pipe(
      __map(_r => _r.body as HolidayModel)
    );
  }

  /**
   * @param holidayId undefined
   * @return Success
   */
  DeleteHolidayResponse(holidayId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Holiday/${encodeURIComponent(String(holidayId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param holidayId undefined
   * @return Success
   */
  DeleteHoliday(holidayId: number): __Observable<string> {
    return this.DeleteHolidayResponse(holidayId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module HolidayService {

  /**
   * Parameters for GetHolidaysList
   */
  export interface GetHolidaysListParams {
    Year?: number;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    CompanyId?: number;
  }
}

export { HolidayService }
