import { Component, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  EmployeeChronicDiseaseModel,
  EmployeeDrugAllergyModel,
  EmployeeHealthCheckModel,
  EmployeePhysicalMeasurementModel,
  EmployeeSurgeryModel,
} from 'app/api/models';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmployeeHealthCheckService, EmployeeSurgeryService, EmployeeService, EmployeePhysicalMeasurementService, EmployeeChronicDiseaseService, EmployeeDrugAllergyService } from '../../../api/services';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { ModalEmployeeChronicDiseaseComponent } from '../../../modals/core/modal-employee-chronic-disease/modal-employee-chronic-disease.component';
import { ModalEmployeeDrugAllergyComponent } from '../../../modals/core/modal-employee-drug-allergy/modal-employee-drug-allergy.component';
import { ModalEmployeeHealthCheckComponent } from '../../../modals/core/modal-employee-health-check/modal-employee-health-check.component';
import { ModalEmployeeSurgeryComponent } from '../../../modals/core/modal-employee-surgery/modal-employee-surgery.component';


interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeePhysicalMeasurementModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-health-info',
  templateUrl: './health-info.component.html',
  styleUrls: ['./health-info.component.css'],
  providers: [DialogService],
})
export class HealthInfoComponent implements OnInit {
  isEditable = false;
  physicalMeasurementModel: EmployeePhysicalMeasurementModel;
  diseaseList: EmployeeChronicDiseaseModel[];
  drugAllergyList: EmployeeDrugAllergyModel[];
  surgeryList: EmployeeSurgeryModel[];
  healthFileList: EmployeeHealthCheckModel[];
  fields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'weight',
          type: 'primeng-input',
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'weight',
            placeholder: '@blank',
            type: 'number',
          },
        },
        {
          key: 'height',
          type: 'primeng-input',
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'height',
            placeholder: '@blank',
            type: 'number',
          },
        },
      ],
    },
  ];
  form = new FormGroup({});
  ref: DynamicDialogRef;

  constructor(
    private layout: LayoutDefaultComponent,
    private router: Router,
    private notificationService: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeHealthCheckService: EmployeeHealthCheckService,
    private employeeSurgeryService: EmployeeSurgeryService,
    private employeeService: EmployeeService,
    private employeePhysicalMeasurementService: EmployeePhysicalMeasurementService,
    private employeeChronicDiseaseService: EmployeeChronicDiseaseService,
    private employeeDrugAllergyService: EmployeeDrugAllergyService,
  ) {}

  ngOnInit(): void {
    this.setEditablePermission();
    this.setPhysicalMeasurement();
    this.setDiseaseList();
    this.setDrugAllergyList();
    this.setSurgeryList();
    this.setHealthFileList();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  editHealthCheck(id: number) {
    this.showDialog({ id }, 'แก้ไขใบตรวจสุขภาพ', ModalEmployeeHealthCheckComponent, false);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setHealthFileList();
      }
    });
  }

  removeHealthCheck(id: number) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeeHealthCheckService.DeleteEmployeeHealthCheck(id).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.setHealthFileList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  setPhysicalMeasurement() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetPhysicalMeasurement(employeeId).subscribe(phys => {
      this.form.reset();
      this.physicalMeasurementModel = phys;
    });
  }

  setDiseaseList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetChronicDiseases(employeeId).subscribe(chronic => {
      this.diseaseList = chronic;
    });
  }

  setDrugAllergyList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetDrugAllergies(employeeId).subscribe(drugs => {
      this.drugAllergyList = drugs;
    });
  }

  setSurgeryList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetSurgeries(employeeId).subscribe(surgeries => {
      this.surgeryList = surgeries;
    });
  }

  setHealthFileList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetEmployeeHealthChecks(employeeId).subscribe(healthChecks => {
      this.healthFileList = healthChecks;
    });
  }

  onUpdateHealthInfoBtnClick() {
    this.notificationService.confirm('บันทึก', 'ยืนยันการบันทึกข้อมูล').then(value => {
      if (value.isConfirmed) {
        this.employeePhysicalMeasurementService
          .CreateEmployeePhysicalMeasurement(this.physicalMeasurementModel)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.setPhysicalMeasurement();
            },
            err => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
      }
    });
  }

  showCreateHealthCheckDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่มใบตรวจสุขภาพ', ModalEmployeeHealthCheckComponent, false);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setHealthFileList();
      }
    });
  }

  showCreateDiseaseDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่มโรคประจำตัว', ModalEmployeeChronicDiseaseComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setDiseaseList();
      }
    });
  }

  showUpdateDiseaseDialog(diseaseId: number) {
    this.showDialog({ id: diseaseId }, 'แก้ไขโรคประจำตัว', ModalEmployeeChronicDiseaseComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setDiseaseList();
      }
    });
  }

  showDeleteDiseaseDialog(diseaseId: number) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeeChronicDiseaseService.DeleteEmployeeChronicDisease(diseaseId).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.setDiseaseList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  showCreateDrugAllergyDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่มประวัติการแพ้ยา', ModalEmployeeDrugAllergyComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setDrugAllergyList();
      }
    });
  }

  showUpdateDrugAllergyDialog(diseaseId: number) {
    this.showDialog({ id: diseaseId }, 'แก้ไขประวัติการแพ้ยา', ModalEmployeeDrugAllergyComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setDrugAllergyList();
      }
    });
  }

  showDeleteDrugAllergyDialog(diseaseId: number) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeeDrugAllergyService.DeleteEmployeeDrugAllergy(diseaseId).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.setDrugAllergyList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  showCreateSurgeryDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่มประวัติการผ่าตัด', ModalEmployeeSurgeryComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setSurgeryList();
      }
    });
  }

  showUpdateSurgeryDialog(surgeryId: number) {
    this.showDialog({ id: surgeryId }, 'แก้ไขประวัติการผ่าตัด', ModalEmployeeSurgeryComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setSurgeryList();
      }
    });
  }

  showDeleteSurgeryDialog(surgeryId: number) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeeSurgeryService.DeleteEmployeeSurgery(surgeryId).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.setSurgeryList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  private showDialog(data: any, header: string, dialogComponent: any, dismissableMask = true) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }
}
