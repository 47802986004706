<button
  *ngIf="to.required"
  [disabled]="to.disabled"
  type="text"
  pButton
  type="button"
  [label]="label"
  (click)="showPositionSearchModal()"
></button>
<p-splitButton
  *ngIf="!to.required"
  [disabled]="to.disabled"
  (onClick)="showPositionSearchModal()"
  [label]="label"
  [model]="items"
  [style]="{'width':'100%'}"
></p-splitButton>
