import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { EmployeeDisplayModel } from '../../../models/employee-display-model';
import { PaginationParams } from '../../../interfaces/PaginationParams';
import { EventPageChange } from '../../../interfaces/EventPageChange';

@Component({
  selector: 'app-employee-list-table',
  templateUrl: './employee-list-table.component.html',
  styleUrls: ['./employee-list-table.component.css'],
})
export class EmployeeListTableComponent implements OnInit {
  @Input() employeeList: EmployeeDisplayModel[];
  @Input() paginationParams: PaginationParams;
  @Input() loading: boolean;
  @Input() employeeMenuItems: MenuItem[];
  @Output() pageChangeEvent = new EventEmitter<EventPageChange>();
  @Output() selectedEmployeeEvent = new EventEmitter<EmployeeDisplayModel>();

  constructor() {}

  ngOnInit() {}

  onPageChange(event: EventPageChange) {
    this.pageChangeEvent.next(event);
  }

  onEllipsisIconClick(e, menu: Menu, employee: EmployeeDisplayModel) {
    this.selectedEmployeeEvent.emit(employee);

    menu.toggle(e);
  }
}
