import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, AfterViewChecked } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { ResignSurveyItemModel, ResignSurveyModel, ResignSurveyTopicModel } from 'app/api/models';
import { EmployeeService, ResignSurveyService } from 'app/api/services';
import { AppBreadcrumbService } from 'app/layout/layout-default/app-breadcrumb/app-breadcrumb.service';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';


interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof ResignSurveyModel | keyof ResignSurveyItemModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

type ComponentMode = 'CREATE' | 'UPDATE';

@Component({
  selector: 'app-resignation-form',
  templateUrl: './resignation-form.component.html',
  styleUrls: ['./resignation-form.component.css'],
})
export class ResignationFormComponent implements OnInit, AfterViewChecked {
  form = new FormGroup({});
  model: ResignSurveyModel = {};
  options: FormlyFormOptions = {};
  fields: CustomFormlyFieldConfig[] = [
    // {
    //   fieldGroupClassName: 'grid',
    //   fieldGroup: [
    //     ...this.getStartFormSection(),
    //     // ...this.getRadioList(),
    //     ...this.getLastFormSection(),
    //     // {
    //     //   key: 'xxx5',
    //     //   type: 'primeng-input',
    //     //   wrappers: ['inline-label'],
    //     //   className: 'col-12',
    //     //   templateOptions: {
    //     //     label: 'สาเหตุใดที่จะสามารถเปลี่ยนใจทำให้ท่านไม่ออกจากงานได้',
    //     //     labelClass: 'col-4',
    //     //     inputClass: 'col',
    //     //   },
    //     // },
    //   ],
    // },
  ];
  componentMode: ComponentMode;

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    private route: ActivatedRoute,
    private router: Router,
    private resignSurveyService: ResignSurveyService,
    private notificationService: AppNotificationServiceService,
    private employeeService: EmployeeService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.initBreadcrumb();
    this.initData();
  }

  ngAfterViewChecked() {
    // your code to update the model
    this.cdRef.detectChanges();
  }
  initBreadcrumb() {
    const url = this.router.url;

    if (url.includes('admin')) {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/admin' },
        { label: 'ส่วนหลัก', routerLink: '/admin/core' },
        { label: 'รายชื่อพนักงานทั้งหมด', routerLink: '/admin/core/employee/list' },
        { label: 'ข้อมูลพนักงาน', routerLink: `/admin/core/employee/list/${this.getEmployeeId()}/other-info` },
        { label: 'แบบสัมภาษณ์พนักงานลาออก' },
      ]);
    } else if (url.includes('employee') && url.includes('info')) {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/employee' },
        { label: 'ข้อมูลส่วนตัว', routerLink: '/employee/info' },
        { label: 'ข้อมูลพนักงาน', routerLink: `/employee/info/${this.getEmployeeId()}/other-info` },
        { label: 'แบบสัมภาษณ์พนักงานลาออก' },
      ]);
    } else {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/employee' },
        { label: 'ส่วนหลัก', routerLink: '/employee/core' },
        { label: 'พนักงานใต้บังคับบัญชาทั้งหมด', routerLink: '/employee/core/team' },
        { label: 'ข้อมูลพนักงาน', routerLink: `/employee/core/team/${this.getEmployeeId()}/other-info` },
        { label: 'แบบสัมภาษณ์พนักงานลาออก' },
      ]);
    }
  }

  initData() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetOtherInfo(employeeId).subscribe(({ resignSurvey }) => {
      if (resignSurvey) {
        this.componentMode = 'UPDATE';
        this.model = {
          ...resignSurvey,
          // resignDate: (resignSurvey.resignDate),
        };
        this.setFormWithTemplate();
      } else {
        this.componentMode = 'CREATE';
        this.model = {
          employeeId,
        };

        this.resignSurveyService.GetResignSurveyTemplateDefault().subscribe(resign => {
          this.model.resignSurveyTemplateId = resign.resignSurveyTemplateId;
          this.model.resignSurveyItems = resign.resignSurveyTemplateItems.map(item => {
            return {
              ...item,
              createdBy: undefined,
              createdDate: undefined,
              updatedDate: undefined,
              updatedBy: undefined,
            };
          });
          this.setFormWithTemplate();
        });
      }
    });
  }

  onSaveBtnClick() {
    console.log(this.model);

    this.notificationService.confirm('บันทึก', 'กรุณากด "ยืนยัน" หากต้องการบันทึกแบบสัมภาษณ์').then(res => {
      if (res.isConfirmed) {
        this.save();
      }
    });
  }

  save() {
    switch (this.componentMode) {
      case 'CREATE':
        this.resignSurveyService.CreateResignSurvey(this.model).subscribe(
          () => {
            this.notificationService.saveCompleted().then(v => {
              this.router.navigate(['..'], { relativeTo: this.route });
            });
          },
          (err: HttpErrorResponse) => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
          },
        );
        break;
      case 'UPDATE':
        this.resignSurveyService.UpdateResignSurvey(this.model).subscribe(
          () => {
            this.notificationService.saveCompleted().then(v => {
              this.router.navigate(['..'], { relativeTo: this.route });
            });
          },
          (err: HttpErrorResponse) => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
          },
        );
        break;
      default:
        break;
    }
  }

  setFormWithTemplate(): void {
    const radioList: CustomFormlyFieldConfig[] = [];

    this.model.resignSurveyItems.forEach((templateItem, i) => {
      const topic = templateItem?.resignSurveyTopic;
      if (!topic) {
        return;
      }

      radioList.push(
        {
          key: 'resignSurveyTopicId',
          defaultValue: topic.resignSurveyTopicId,
        },
        {
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            label: topic.topicName,
            labelClass: 'col-12',
          },
        },
        {
          key: i.toString() as any,
          fieldGroup: [
            {
              key: 'rating',
              type: 'radio',
              className: 'col-6',
              defaultValue: topic.minRating,
              templateOptions: {
                required: true,
                options: this.buildOptionList(topic.minRating, topic.maxRating),
              },
            },
          ],
        },
      );
    });

    this.fields = [
      {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          ...this.getStartFormSection(),
          {
            className: 'col-12',
            fieldGroupClassName: 'grid',
            key: 'resignSurveyItems',
            fieldGroup: radioList,
          },
          ...this.getLastFormSection(),
        ],
      },
    ];
  }

  getEmployeeId() {
    return +this.route.snapshot.paramMap.get('employeeId');
  }

  getStartFormSection(): CustomFormlyFieldConfig[] {
    return [
      // {
      //   key: 'resignDate',
      //   type: 'calendar',
      //   wrappers: ['inline-label'],
      //   className: 'col-12',
      //   templateOptions: {
      //     label: 'วันที่ลาออก',
      //     placeholder: 'วันที่ลาออก',
      //     required: true,
      //     labelClass: 'col-4',
      //     inputClass: 'col-4',
      //   },
      // },
      {
        key: 'resignReason',
        type: 'primeng-input',
        wrappers: ['inline-label'],
        className: 'col-12',
        templateOptions: {
          translate: true,
          label: 'briefly_state_resignation',
          placeholder: '@blank',
          required: true,
          labelClass: 'col-4',
          inputClass: 'col-8',
          maxLength: 2048,
        },
      },
      {
        key: 'managementOpinion',
        type: 'primeng-input',
        wrappers: ['inline-label'],
        className: 'col-12',
        templateOptions: {
          translate: true,
          label: 'your_thoughts_company',
          placeholder: '@blank',
          required: true,
          labelClass: 'col-4',
          inputClass: 'col-8',
          maxLength: 2048,
        },
      },
      {
        wrappers: ['inline-label'],
        className: 'col-4',
        templateOptions: {
          translate: true,
          label: 'please_leave_your_comments',
          placeholder: '@blank',
          labelClass: 'col-12',
        },
      },
      {
        wrappers: ['inline-label'],
        className: 'col-8',
        templateOptions: {
          translate: true,
          label: 'by_choosing_from',
          placeholder: '@blank',
          labelClass: 'col-fixed',
        },
      },
    ];
  }

  getLastFormSection(): CustomFormlyFieldConfig[] {
    return [
      {
        key: 'generalOpinion',
        type: 'primeng-input',
        wrappers: ['inline-label'],
        className: 'col-12',
        templateOptions: {
          translate: true,
          label: 'opinions_that_do_not_match_the_Company',
          placeholder: '@blank',
          labelClass: 'col-4',
          inputClass: 'col-8',
          maxLength: 2048,
        },
      },
      {
        key: 'improvementRecommendations',
        type: 'primeng-input',
        wrappers: ['inline-label'],
        className: 'col-12',
        templateOptions: {
          translate: true,
          label: 'suggestions_do_you_have',
          placeholder: '@blank',
          labelClass: 'col-4',
          inputClass: 'col-8',
          maxLength: 2048,
        },
      },
      {
        key: 'engagementRecommendations',
        type: 'primeng-input',
        wrappers: ['inline-label'],
        className: 'col-12',
        templateOptions: {
          translate: true,
          label: 'suggestions_for_the_company',
          placeholder: '@blank',
          labelClass: 'col-4',
          inputClass: 'col-8',
          maxLength: 2048,
        },
      },
      {
        key: 'otherRecommendations',
        type: 'primeng-input',
        wrappers: ['inline-label'],
        className: 'col-12',
        templateOptions: {
          translate: true,
          label: 'additional_comments',
          placeholder: '@blank',
          labelClass: 'col-4',
          inputClass: 'col-8',
          maxLength: 2048,
        },
      },
    ];
  }

  buildOptionList(min: number = 0, max: number = 0): { value: any; label: string }[] {
    const ar: { value: any; label: string }[] = [];

    ar.push({ value: null, label: 'N/A' });

    for (let i = min; i <= max; i++) {
      ar.push({ value: i, label: i.toString() });
    }

    return ar;
  }

  disbleSaveBtn(): boolean {
    return this.form.invalid;
  }
}
