import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppBreadcrumbService } from 'app/layout/layout-default/app-breadcrumb/app-breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpErrorResponse } from '@angular/common/http';
import { FileUpload } from 'primeng/fileupload';
import { interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentService, EmployeeService } from 'app/api/services';
import { finalize, flatMap } from 'rxjs/operators';
import { AuthenticationService } from 'services/authentication.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppMenuService } from 'services/app-menu.service';
import { AppNotificationServiceService } from '../../../shared/app-notification-service.service';
import { ModalEmployeeResignsComponent } from 'modals/core/modal-employee-resigns/modal-employee-resigns/modal-employee-resigns.component';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { GeneralInfoComponent } from '../general-info/general-info.component';
import { ModalTransferInCompanyComponent } from 'modals/core/modal-transfer-in-company/modal-transfer-in-company.component';
import { ModalTranferCompanyComponent } from 'modals/core/modal-tranfer-company/modal-tranfer-company.component';

export interface HasEffectToEmployeeView {
  employeeUpdatedEvent: EventEmitter<any>;
}

interface EmployeeDisplayModel {
  employeeId?: number;
  employeeCode?: string;
  fullnameTh?: string;
  nicknameTh?: string;
  unitName?: string;
  companyName?: string;
  positionName?: string;
  locationName?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  terminatedDate?: string;
  employeeTypeId?: string;
}

interface PositionMappingType {
  name: string;
  unitId: number;
}

interface UnitMappingType {
  name: string;
  locationName: string;
}

interface UplaodFileParam {
  files: File[];
}

@UntilDestroy()
@Component({
  selector: 'app-employee-view',
  templateUrl: './employee-view.component.html',
  styleUrls: ['./employee-view.component.css'],
})
export class EmployeeViewComponent implements OnInit {
  @ViewChild('profileImg') profileImg: FileUpload;
  file: File;

  isEditable = false;
  isVisibleByPermission = false;
  isOrWillBeResignedEmployee = false;
  employee: EmployeeDisplayModel = {};
  items: MenuItem[];
  mapPosition: { [id: number]: PositionMappingType } = {};
  mapUnit: { [id: number]: UnitMappingType } = {};
  lastUpdatedProfile = Date.now();
  ref: DynamicDialogRef;

  constructor(
    private layout: LayoutDefaultComponent,
    private dialogService: DialogService,
    private breadcrumbService: AppBreadcrumbService,
    private router: Router,
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private employeeService: EmployeeService,
    private authenticationService: AuthenticationService,
    private ngxPermissionsService: NgxPermissionsService,
    private notificationService: AppNotificationServiceService
  ) { }

  ngOnInit() {
    this.setEditablePermission();
    this.initBreadcrumb();
    this.initMenuItems();
    this.setEmployeeData();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
    this.isVisibleByPermission = this.router.url.includes('admin', 1);
  }

  onUploadHandler(param: UplaodFileParam) {
    this.profileImg.uploading = true;

    const progressInterval = interval(500)
      .pipe(untilDestroyed(this))
      .subscribe(c => {
        this.profileImg.progress += 5;

        if (this.profileImg.progress >= 100) {
          this.profileImg.progress = 0;
          progressInterval.unsubscribe();
        }
      });

    this.documentService
      .CreateDocument({
        description: 'Employee Profile',
        file: param.files[0],
      })
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.profileImg.uploading = false;
          this.profileImg.progress = 0;

          this.profileImg.files = [];
          this.profileImg.uploadedFileCount = 0;
          progressInterval.unsubscribe();
        }),
        flatMap(imageDocument => {
          return this.employeeService.UpdateEmployeeImage({
            employeeId: this.employee.employeeId,
            imageDocumentId: imageDocument.documentId,
          });
        }),
      )
      .subscribe(() => {
        this.lastUpdatedProfile = Date.now();
      });
  }

  onActivate(componentRef) {
    // console.log(componentRef);

    if (componentRef.hasOwnProperty('employeeUpdatedEvent')) {
      //console.log('sub', componentRef);
      (componentRef as GeneralInfoComponent).employeeUpdatedEvent.subscribe(() => {
        console.log('GeneralInfoComponent updated.');
        this.setEmployeeData();
      });
    }
  }

  initBreadcrumb() {
    const url = this.router.url;

    if (url.includes('admin')) {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/admin' },
        { label: 'ส่วนหลัก', routerLink: '/admin/core' },
        { label: 'รายชื่อพนักงานทั้งหมด', routerLink: '/admin/core/employee/list' },
        { label: 'ข้อมูลพนักงาน' },
      ]);
    } else if (url.includes('employee') && url.includes('info')) {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/employee' },
        { label: 'ข้อมูลส่วนตัว', routerLink: '/employee/info' },
        { label: 'ข้อมูลพนักงาน' },
      ]);
    } else {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/employee' },
        { label: 'ส่วนหลัก', routerLink: '/employee/core' },
        { label: 'พนักงานใต้บังคับบัญชาทั้งหมด', routerLink: '/employee/core/team' },
        { label: 'ข้อมูลพนักงาน' },
      ]);
    }
  }

  initMenuItems() {
    const items = [
      {
        icon: 'fas fa-id-card',
        routerLink: 'important-info',
        title: 'ข้อมูลสำคัญ',
        permission: 'submenu.shared.employee-info.important-info',
      },
      {
        icon: 'fas fa-user-alt',
        routerLink: 'general-info',
        title: 'ข้อมูลทั่วไป',
        permission: 'submenu.shared.employee-info.general-info',
      },
      {
        icon: 'fas fa-briefcase',
        routerLink: 'past-employment',
        title: 'ประวัติการทำงาน',
        permission: 'submenu.shared.employee-info.past-employment',
      },
      /* todo: ทำหน้านี้ให้เรียบร้อย
      { icon: 'fas fa-sitemap', routerLink: 'chain-of-command', title: 'สายการบังคับบัญชา' },
      */
      {
        icon: 'fas fa-address-book',
        routerLink: 'contact-info',
        title: 'ข้อมูลการติดต่อ',
        permission: 'submenu.shared.employee-info.contact-info',
      },
      {
        icon: 'fas fa-home',
        routerLink: 'family-info',
        title: 'ข้อมูลครอบครัว',
        permission: 'submenu.shared.employee-info.family-info',
      },
      {
        icon: 'fas fa-graduation-cap',
        routerLink: 'educations',
        title: 'การศึกษา',
        permission: 'submenu.shared.employee-info.educations',
      },
      {
        icon: 'far fa-clock',
        routerLink: 'ta-settings',
        title: 'ตั้งค่า Time Attendance',
        permission: 'submenu.shared.employee-info.general-info',
      },
      {
        icon: 'fa-solid fa-medal',
        routerLink: 'insignia',
        title: 'เครื่องราชอิสริยาภรณ์',
        permission: 'submenu.shared.employee-info.educations',
      },
      {
        icon: 'fas fa-star',
        routerLink: 'competence',
        title: 'ความสามารถ',
        permission: 'submenu.shared.employee-info.competence',
      },
      {
        icon: 'fas fa-heart',
        routerLink: 'health-info',
        title: 'ข้อมูลสุขภาพเบื้องต้น',
        permission: 'submenu.shared.employee-info.health-info',
      },
      {
        icon: 'fas fa-file-signature',
        routerLink: 'certificate',
        title: 'Certificate',
        permission: 'submenu.shared.employee-info.certificate',
      },
      {
        icon: 'fa-solid fa-universal-access',
        routerLink: 'training',
        title: 'Training',
        permission: 'submenu.shared.employee-info.certificate',
      },
      {
        icon: 'far fa-file-alt',
        routerLink: 'performance',
        title: 'ผลประเมินประสิทธิภาพการทำงาน',
        permission: 'submenu.shared.employee-info.performance',
      },
      {
        icon: 'fa fa-exclamation-circle',
        routerLink: 'punishment',
        title: 'โทษทางวินัย',
        permission: 'submenu.shared.employee-info.punishment',
      },
      {
        icon: 'fas fa-money-bill-1-wave',
        routerLink: 'salary',
        title: 'เงินเดือน',
        permission: this.getIsMyData()
          ? 'submenu.shared.employee-info.salary'
          : 'submenu.shared.employee-info.salary-for-supervisor',
      },
      {
        icon: 'fas fa-money-check-dollar',
        routerLink: 'tax-info',
        title: 'ข้อมูลด้านภาษี',
        permission: this.getIsMyData()
          ? 'submenu.shared.employee-info.salary'
          : 'submenu.shared.employee-info.salary-for-supervisor',
      },
      {
        icon: 'fa fa-credit-card',
        routerLink: 'pvd',
        title: 'PVD',
        permission: this.getIsMyData()
          ? 'submenu.shared.employee-info.salary'
          : 'submenu.shared.employee-info.salary-for-supervisor',
      },
      {
        icon: 'fas fa-folder-open',
        routerLink: 'file',
        title: 'ไฟล์แนบ',
        permission: this.getIsMyData()
          ? 'submenu.shared.employee-info.file'
          : 'submenu.shared.employee-info.file-for-supervisor',
      },
      {
        icon: 'far fa-calendar-alt',
        routerLink: 'work-schedule',
        title: 'ตารางการทำงาน',
        permission: 'submenu.shared.employee-info.work-schedule',
      },
      /* todo: ทำหน้านี้ให้เรียบร้อย
      { icon: 'far fa-calendar-times', routerLink: 'timeline', title: 'Timeline' },
      */
      {
        icon: 'fas fa-tasks',
        routerLink: 'other-info',
        title: 'รายการอื่นๆ',
        permission: 'submenu.shared.employee-info.other-info',
      },
      {
        icon: 'fas fa-info-circle',
        routerLink: 'additional',
        title: 'ข้อมูลเพิ่มเติม',
        permission: 'submenu.shared.employee-info.additional-info',
      },
    ];

    let myPermission: string[] = null;
    if (this.authenticationService.isSubrogating())
      myPermission = this.authenticationService.subrogatePermission().get();
    else myPermission = Object.keys(this.ngxPermissionsService.getPermissions());

    const employeeId = +this.route.snapshot.paramMap.get('employeeId');
    const user = this.authenticationService.getUserInfo();
    const isSuperAdmin = user.roles.map(a => a.toLowerCase()).includes('superadmin');
    const isMe = user.employeeId === employeeId;
    let items_filter = items.filter(a => myPermission.includes(a.permission));
    this.items = items_filter;
    if (
      this.employee.employeeTypeId != 'EQ' &&
      this.employee.employeeTypeId != 'TR' &&
      this.employee.employeeTypeId != 'WH'
    ) {
      this.items = items_filter.filter(a => a.routerLink != 'additional');
    }
  }

  EmployeeResignsButtonItems = [
    {
      label: 'สิ้นสุดการจ้าง',
      command: () => {
        this.showDialog({ id: this.employee.employeeId }, 'การสิ้นสุดการเป็นพนักงาน');

        this.ref.onClose.subscribe(data => {
          if (data) {
            this.setEditablePermission();
            this.setEmployeeData();
          }
        });
      },
    },
    {
      label: 'ย้ายพนักงานภายในบริษัท',
      command: () => {
        const header: string = "ย้ายพนักงานภายในบริษัท";
        this.ref = this.dialogService.open(ModalTransferInCompanyComponent, {
          header,
          width: this.layout.isMobile() ? '100%' : '700px',
          contentStyle: { overflow: 'auto' },
          data: { employeeId: this.employee.employeeId },
        })

        this.ref.onClose.subscribe(data => {
          if (data) {
            this.setEditablePermission();
            this.setEmployeeData();
          }
        });
      }
    },
    {
      label: 'ย้ายพนักงานข้ามบริษัท',
      command: () => {
        const header: string = "ย้ายพนักงานข้ามบริษัท";
        this.ref = this.dialogService.open(ModalTranferCompanyComponent, {
          header,
          width: this.layout.isMobile() ? '100%' : '700px',
          contentStyle: { overflow: 'auto' },
          data: { employeeId: this.employee.employeeId },
        })

        this.ref.onClose.subscribe(data => {
          if (data) {
            this.setEditablePermission();
            this.setEmployeeData();
          }
        });
      }
    },
  ];

  private showDialog(data: any, header: string) {
    this.ref = this.dialogService.open(ModalEmployeeResignsComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '700px',
      contentStyle: { overflow: 'auto' },
      data,
    });
  }

  check_first = false;
  setEmployeeData() {
    const urls = this.router.url.split('/');
    const employeeId = +urls[urls.length - 2];

    this.employee = {};

    this.employeeService.GetEmployeeInfo(employeeId).subscribe(
      employee => {
        this.employee = {
          employeeId: employee.employeeId,
          employeeCode: employee.employeeCode,
          fullnameTh: employee.currentName?.fullnameTh ?? 'N/A',
          nicknameTh: employee.nickNameTh,
          unitName: employee.currentUnitDef?.name ?? 'N/A',
          locationName: employee.currentUnitDef?.locationName ?? 'N/A',
          positionName: employee.currentPosition?.name ?? 'N/A',
          companyName: employee.companyName ?? 'N/A',
          email: employee.email ?? 'N/A', // working email
          phone: employee.employeeContacts.filter(cont => cont.contactTypeId === 'WT')[0]?.value ?? 'N/A',
          mobile: employee.employeeContacts.filter(cont => cont.contactTypeId === 'M')[0]?.value ?? 'N/A',
          terminatedDate: employee.terminatedDate,
          employeeTypeId: employee.currentEmployeeDef?.employeeTypeId ?? 'N/A',
        };

        if (!this.check_first) {
          this.initMenuItems();
          this.check_first = true;
        }

        this.isOrWillBeResignedEmployee = new Date(this.employee.terminatedDate).getFullYear() < 9999 && !null;
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.router.navigate(['..'], { relativeTo: this.route });
      },
    );
  }

  onSaveEmployeeBtnClick(event?: any) {
    console.log(event);
  }

  getLinkProfileImage(): string {
    return `/api/employee/${this.employee.employeeId}/image?${this.lastUpdatedProfile}`;
  }

  getIsMyData() {
    // by pass if this is admin
    if (this.authenticationService.getCurrentUser().loginType == 'Admin') {
      return true;
    }

    if (this.authenticationService.isSubrogating()) {
      return false;
    }

    const myEmployeeId = this.authenticationService.getUserInfo()?.employeeId;
    const targetEmployeeId = +this.route.snapshot.paramMap.get('employeeId');
    return myEmployeeId == targetEmployeeId;
  }

  CancelTerminate() {
    this.notificationService.confirm('ยืนยัน เพื่อยกเลิกการลาออก', '').then((result: any) => {
      if (result.value) {
        this.employeeService.CancelTerminateEmployee(
          this.employee.employeeId,
        ).subscribe(
          () => {
            this.notificationService.success('ยกเลิกการลาออกสำเร็จ', 'การยกเลิกการลาออกสำเร็จแล้ว');
            this.setEmployeeData();
          }),
          (err: HttpErrorResponse) => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถเพิ่มข้อมูลได้', 'กรุณาตรวจสอบอีกครั้ง - ' + errMsg);
          }
      }
    });
  }

}
