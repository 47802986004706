/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeEducationModel } from '../models/employee-education-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeEducationService extends __BaseService {
  static readonly GetEmployeeEducationsListPath = '/api/EmployeeEducation';
  static readonly CreateEmployeeEducationPath = '/api/EmployeeEducation';
  static readonly UpdateEmployeeEducationPath = '/api/EmployeeEducation';
  static readonly GetEmployeeEducationByIdPath = '/api/EmployeeEducation/{EmployeeEducationId}';
  static readonly DeleteEmployeeEducationPath = '/api/EmployeeEducation/{EmployeeEducationId}';
  static readonly ImportEmployeeEducationPath = '/api/EmployeeEducation/Excel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeEducationsListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeEducationModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeEducation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeEducationModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeEducationsList(): __Observable<Array<EmployeeEducationModel>> {
    return this.GetEmployeeEducationsListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeEducationModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeEducationResponse(body?: EmployeeEducationModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeEducation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeEducation(body?: EmployeeEducationModel): __Observable<string> {
    return this.CreateEmployeeEducationResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeEducationResponse(body?: EmployeeEducationModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeEducation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeEducation(body?: EmployeeEducationModel): __Observable<string> {
    return this.UpdateEmployeeEducationResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeEducationId undefined
   * @return Success
   */
  GetEmployeeEducationByIdResponse(EmployeeEducationId: number): __Observable<__StrictHttpResponse<EmployeeEducationModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeEducation/${encodeURIComponent(String(EmployeeEducationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeEducationModel>;
      })
    );
  }
  /**
   * @param EmployeeEducationId undefined
   * @return Success
   */
  GetEmployeeEducationById(EmployeeEducationId: number): __Observable<EmployeeEducationModel> {
    return this.GetEmployeeEducationByIdResponse(EmployeeEducationId).pipe(
      __map(_r => _r.body as EmployeeEducationModel)
    );
  }

  /**
   * @param EmployeeEducationId undefined
   * @return Success
   */
  DeleteEmployeeEducationResponse(EmployeeEducationId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeEducation/${encodeURIComponent(String(EmployeeEducationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeEducationId undefined
   * @return Success
   */
  DeleteEmployeeEducation(EmployeeEducationId: number): __Observable<string> {
    return this.DeleteEmployeeEducationResponse(EmployeeEducationId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  ImportEmployeeEducationResponse(file?: Blob): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeEducation/Excel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  ImportEmployeeEducation(file?: Blob): __Observable<string> {
    return this.ImportEmployeeEducationResponse(file).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeEducationService {
}

export { EmployeeEducationService }
