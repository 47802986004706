<div *ngFor="let afield of field.fieldGroup; let i = index" class="grid">
  <div class="col-fixed">รายละเอียดคำขอลา {{ i + 1 }}</div>
  <div class="col-fixed" *ngIf="to?.isShowRemoveBtn">
    <i class="fa fa-trash text-remove" (click)="remove(i)"
      ><u>{{ to?.deleteTitle }}</u></i
    >
  </div>
  <formly-field class="col-12" [field]="afield"></formly-field>
  <div class="col-12">
    <hr />
  </div>
</div>
<div class="grid" *ngIf="to?.isShowAddBtn">
  <div class="col-fixed">รายละเอียดคำขอลา {{ field.fieldGroup.length + 1 }}</div>
  <div class="col-fixed">
    <button pButton type="button" (click)="add()" label="{{ to?.addText }}" class="p-button-secondary"></button>
  </div>
</div>
