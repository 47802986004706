/* tslint:disable */
type EEmployeePromoteRequestStatus =
  'Draft' |
  'Editing' |
  'Awaiting' |
  'Approved' |
  'Cancel' |
  'Reject';
module EEmployeePromoteRequestStatus {
  export const DRAFT: EEmployeePromoteRequestStatus = 'Draft';
  export const EDITING: EEmployeePromoteRequestStatus = 'Editing';
  export const AWAITING: EEmployeePromoteRequestStatus = 'Awaiting';
  export const APPROVED: EEmployeePromoteRequestStatus = 'Approved';
  export const CANCEL: EEmployeePromoteRequestStatus = 'Cancel';
  export const REJECT: EEmployeePromoteRequestStatus = 'Reject';
  export function values(): EEmployeePromoteRequestStatus[] {
    return [
      DRAFT,
      EDITING,
      AWAITING,
      APPROVED,
      CANCEL,
      REJECT
    ];
  }
}

export { EEmployeePromoteRequestStatus }