import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from 'app/api/services';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { AppBreadcrumbService } from 'app/layout/layout-default/app-breadcrumb/app-breadcrumb.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';
import { CreateEmployeeModel } from '../../../api/models';

interface SurveyFormModel {
  type: string;
  id?: number;
  routerLink: FormLink;
  disabled: boolean;
  createdDate: string;
  updatedDate: string;
}

type FormLink = '' | 'resignation-form' | 'asset-form';

@Component({
  selector: 'app-other-info',
  templateUrl: './other-info.component.html',
  styleUrls: ['./other-info.component.css'],
})
export class OtherInfoComponent implements OnInit {
  isEditable = false;
  questionnaireList: SurveyFormModel[];
  otherDocumentList: SurveyFormModel[];

  isShowErr = true;
  employeeUpdatedEvent = new EventEmitter();

  form = new FormGroup({});
  model: CreateEmployeeModel = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isDisabled',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'question1',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
            options: [
              {
                label: 'มี',
                value: true,
              },
              {
                label: 'ไม่มี',
                value: false,
              },
            ],
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        {
          key: 'isDisabledDescription',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'specify',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isDisabled',
        },
        // Line 2
        {
          key: 'everSeriousIllness',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'question2',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
            options: [
              {
                label: 'เคย',
                value: true,
              },
              {
                label: 'ไม่เคย',
                value: false,
              },
            ],
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        {
          key: 'everSeriousIllnessDescription',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'specify',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everSeriousIllness',
        },
        // Line 3
        {
          key: 'generalHealhCondition',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'question3',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
            options: [
              {
                label: 'ดีเลิศ',
                value: 'ดีเลิศ',
              },
              {
                label: 'ดี',
                value: 'ดี',
              },
              {
                label: 'พอใช้',
                value: 'พอใช้',
              },
              {
                label: 'ไม่ดี',
                value: 'ไม่ดี',
              },
            ],
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        // Line 4
        {
          key: 'everPenalized',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'question4',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
            options: [
              {
                label: 'เคย',
                value: true,
              },
              {
                label: 'ไม่เคย',
                value: false,
              },
            ],
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        {
          key: 'everPenalizedDescription',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'fault_base',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everPenalized',
        },
        // Line 4 part 2
        {
          key: 'everPenalizedFinished',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'specify',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
            options: [
              {
                label: 'ถึงที่สุด',
                value: true,
              },
              {
                label: 'ยังไม่ถึงที่สุด',
                value: false,
              },
            ],
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
          hideExpression: '!model.everPenalized',
        },
        {
          key: 'everPenalizedJailYear',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12 ',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'imprisonment',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
            type: 'number',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everPenalizedFinished || !model.everPenalized',
        },
        {
          key: 'everPenalizedJailMonth',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 108%',
            },
            label: 'not',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
            type: 'number',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everPenalizedFinished || !model.everPenalized',
        },
        {
          key: 'everPenalizedExcapeYear',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'past_punishment',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
            type: 'number',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everPenalizedFinished || !model.everPenalized',
        },
        {
          key: 'everPenalizedExcapeMonth',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 108%',
            },
            label: 'not',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
            type: 'number',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everPenalizedFinished || !model.everPenalized',
        },

        // Line 5
        {
          key: 'everGotOut',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'question5',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12  text-left',
            inputClass: 'col-12',
            options: [
              {
                label: 'เคย',
                value: true,
              },
              {
                label: 'ไม่เคย',
                value: false,
              },
            ],
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        {
          key: 'everGotOutDescription',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'specify',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everGotOut',
        },
        // Line 6
        {
          key: 'haveRelativeWorkingHere',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'question6',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12  text-left',
            inputClass: 'col-12',
            options: [
              {
                label: 'มี',
                value: true,
              },
              {
                label: 'ไม่มี',
                value: false,
              },
            ],
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        {
          key: 'haveRelativeWorkingHereDescription',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'specify',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-12 text-left',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.haveRelativeWorkingHere',
        },
      ],
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employeeService: EmployeeService,
    private notification: AppNotificationServiceService,
  ) {}

  ngOnInit() {
    this.setEditablePermission();
    this.initData();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  initData() {
    this.questionnaireList = [
      {
        type: 'แบบสัมภาษณ์พนักงานลาออก',
        routerLink: 'resignation-form',
        disabled: false,
        createdDate: '',
        updatedDate: '',
      },
    ];
    this.otherDocumentList = [
      {
        type: 'แบบฟอร์มตรวจรับทรัพย์สิน (เข้า-ออก)',
        routerLink: 'asset-form',
        disabled: true,
        createdDate: '',
        updatedDate: '',
      },
    ];

    this.employeeService.GetOtherInfo(this.getEmployeeId()).subscribe(other => {
      const { resignSurvey, employeeAssetSurvey } = other;

      if (resignSurvey) {
        this.questionnaireList[0].createdDate = resignSurvey.createdDate;
        this.questionnaireList[0].id = resignSurvey.resignSurveyId;
        this.questionnaireList[0].disabled = true;
      }

      if (employeeAssetSurvey) {
        this.otherDocumentList[0].updatedDate = employeeAssetSurvey.updatedDate;
        this.otherDocumentList[0].id = employeeAssetSurvey.employeeAssetSurveyId;
        this.otherDocumentList[0].disabled = true;
      }

      this.form.reset();
      this.model = {
        isDisabled: other.isDisabled,
        isDisabledDescription: other.isDisabled ? other.isDisabledDescription : null,
        everSeriousIllness: other.everSeriousIllness,
        everSeriousIllnessDescription: other.everSeriousIllness ? other.everSeriousIllnessDescription : null,
        generalHealhCondition: other.generalHealhCondition,
        everPenalized: other.everPenalized,
        everPenalizedDescription: other.everPenalized ? other.everPenalizedDescription : null,
        everPenalizedFinished: other.everPenalizedFinished,
        everPenalizedJailYear: other.everPenalized && other.everPenalizedFinished ? other.everPenalizedJailYear : null,
        everPenalizedJailMonth:
          other.everPenalized && other.everPenalizedFinished ? other.everPenalizedJailMonth : null,
        everPenalizedExcapeYear:
          other.everPenalized && other.everPenalizedFinished ? other.everPenalizedExcapeYear : null,
        everPenalizedExcapeMonth:
          other.everPenalized && other.everPenalizedFinished ? other.everPenalizedExcapeMonth : null,
        everGotOut: other.everGotOut,
        everGotOutDescription: other.everGotOut ? other.everGotOutDescription : null,
        haveRelativeWorkingHere: other.haveRelativeWorkingHere,
        haveRelativeWorkingHereDescription: other.haveRelativeWorkingHere
          ? other.haveRelativeWorkingHereDescription
          : null,
      };
    });
  }

  updateData() {
    this.employeeService
      .UpdateOtherInfo({
        employeeId: this.getEmployeeId(),
        body: this.model,
      })
      .subscribe(
        () => {
          this.notification.saveCompleted();
          this.initData();
          this.employeeUpdatedEvent.next(null);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notification.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }
}
