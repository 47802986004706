import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PipeTransform, Pipe, Injectable, Inject, LOCALE_ID } from '@angular/core';
import { DecimalPipe, formatNumber } from '@angular/common';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'safeNumber' })
export class SafeNumber implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private _locale: string
  ) { }
  transform(value: string, info?, locale?): string {
    if (value == null || value == undefined ) return 'N/A';
    locale = locale || this._locale;
    const num = strToNumber(value);
    return formatNumber(num, locale, info);
  }
}
/**
 * Transforms a string into a number (if needed).
 */
function strToNumber(value: number | string): number {
  // Convert strings to numbers
  if (typeof value === 'string' && !isNaN(Number(value) - parseFloat(value))) {
    return Number(value);
  }
  if (typeof value !== 'number') {
    throw new Error(`${value} is not a number`);
  }
  return value;
}
