/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LeaveItemModelPaginationResult } from '../models/leave-item-model-pagination-result';
import { LeaveItemModel } from '../models/leave-item-model';
@Injectable({
  providedIn: 'root',
})
class LeaveService extends __BaseService {
  static readonly GetLeavesListPath = '/api/Leave';
  static readonly GetLeaveByIdPath = '/api/Leave/{leaveId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `LeaveService.GetLeavesListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `LeaveTypeId`:
   *
   * - `LeaveStatusId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CreatedDate`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetLeavesListResponse(params: LeaveService.GetLeavesListParams): __Observable<__StrictHttpResponse<LeaveItemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PersonnelAreaId != null) __params = __params.set('PersonnelAreaId', params.PersonnelAreaId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.LeaveTypeId != null) __params = __params.set('LeaveTypeId', params.LeaveTypeId.toString());
    if (params.LeaveStatusId != null) __params = __params.set('LeaveStatusId', params.LeaveStatusId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.CreatedDate != null) __params = __params.set('CreatedDate', params.CreatedDate.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Leave`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LeaveItemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `LeaveService.GetLeavesListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `LeaveTypeId`:
   *
   * - `LeaveStatusId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CreatedDate`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetLeavesList(params: LeaveService.GetLeavesListParams): __Observable<LeaveItemModelPaginationResult> {
    return this.GetLeavesListResponse(params).pipe(
      __map(_r => _r.body as LeaveItemModelPaginationResult)
    );
  }

  /**
   * @param leaveId undefined
   * @return Success
   */
  GetLeaveByIdResponse(leaveId: number): __Observable<__StrictHttpResponse<LeaveItemModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Leave/${encodeURIComponent(String(leaveId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LeaveItemModel>;
      })
    );
  }
  /**
   * @param leaveId undefined
   * @return Success
   */
  GetLeaveById(leaveId: number): __Observable<LeaveItemModel> {
    return this.GetLeaveByIdResponse(leaveId).pipe(
      __map(_r => _r.body as LeaveItemModel)
    );
  }
}

module LeaveService {

  /**
   * Parameters for GetLeavesList
   */
  export interface GetLeavesListParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    PersonnelAreaId?: number;
    Page?: number;
    LocationId?: number;
    LeaveTypeId?: number;
    LeaveStatusId?: any;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    CreatedDate?: string;
    CompanyId?: number;
  }
}

export { LeaveService }
