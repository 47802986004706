import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeInPositionModel, PositionModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map, takeUntil, tap, flatMap, finalize } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeInPositionService, EmployeeService } from 'app/api/services';
import { HttpErrorResponse } from '@angular/common/http';

import * as moment from 'moment';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { MasterDataRepo } from 'app/repo/master-data.repo';

interface EmployeeInpositionFormModel extends EmployeeInPositionModel {
  isNotSpecifyTerminateDate?: boolean;
}

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface ModelFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeInpositionFormModel;
  fieldGroup?: ModelFormlyFieldConfig[];
}

@Component({
  selector: 'app-modal-employee-in-position',
  templateUrl: './modal-employee-in-position.component.html',
  styleUrls: ['./modal-employee-in-position.component.css'],
})
export class ModalEmployeeInPositionComponent implements OnInit {
  model: EmployeeInpositionFormModel = {};
  form = new FormGroup({});
  fields: ModelFormlyFieldConfig[] = [
    {
      key: 'positionId',
      type: 'select-position-2',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'position',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        isEmptyEmployee: true,
      },
    },
    {
      key: 'positionTakingType',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'type_tenure',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: [],
      },
    },
    {
      key: 'probationDay',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      defaultValue: 90,
      templateOptions: {
        translate: true,
        label: 'probation_period',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        type: 'number',
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      defaultValue: moment().format('YYYY-MM-DD'),
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'start_date_s',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4'
      },
    },
    // {
    //   key: 'terminateDate',
    //   type: 'primeng-input',
    //   wrappers: ['inline-label'],
    //   templateOptions: {
    //     attributes: {
    //       style: 'width: 100%'
    //     },
    //     label: 'วันที่สิ้นสุด',
    //     placeholder: 'วันที่สิ้นสุด',
    //     required: true,
    //     labelClass: 'col-4',
    //     inputClass: 'col-4'
    //   }
    // },
    {
      fieldGroupClassName: 'grid align-center',
      fieldGroup: [
        {
          key: 'terminateDate',
          type: 'calendar',
          className: 'col-8',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'end_use_date',
            placeholder: '@blank',
            labelClass: 'col-6',
            inputClass: 'col',
            required: true,
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.isNotSpecifyTerminateDate',
          },
        },
        {
          key: 'isNotSpecifyTerminateDate',
          type: 'checkbox',
          className: 'col-4',
          wrappers: ['inline-label'],
          defaultValue: true,
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'no_date_specified',
            translate: true,
            placeholder: '@blank',
            labelClass: 'col',
          },
        },
      ],
    },
    {
      key: 'remark',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'remark',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
  ];
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeInPositionService: EmployeeInPositionService,
    private optionListRepo: OptionListRepo,
    private employeeService: EmployeeService,
    private masterDataRepo: MasterDataRepo,
  ) {}

  ngOnInit(): void {
    this.initComponentMode();

    this.employeeService
      .GetEmployeeInfo(this.getEmployeeId())
      .pipe(
        tap(employee => {
          if (this.componentMode === ComponentMode.UPDATE) {
            this.initData();
          } else {
            this.model = {
              employeeId: employee.employeeId,
            };
          }
        }),
        flatMap(employee => {
          return forkJoin([
            this.optionListRepo.getPositionItemListOptionList({ CompanyId: employee.currentEmployeeDef?.companyId }),
            this.masterDataRepo.getPositionTakingTypesOptionList(),
          ]);
        }),
      )
      .subscribe(([positionList, positionTakingTypesList]) => {
        console.log(positionTakingTypesList);
        this.fields.find(a => a.key === 'positionId').templateOptions.options = positionList;
        this.fields.find(a => a.key === 'positionTakingType').templateOptions.options = positionTakingTypesList;
      });
  }

  initData() {
    this.employeeInPositionService
      .GetEmployeeInPositionById(this.getEmployeeInPositionId())
      .subscribe(res => {
        this.model = {
          ...res,
          effectiveDate: (res.effectiveDate),
          terminateDate: (res.terminateDate),
          isNotSpecifyTerminateDate: (res.terminateDate) === '9999-12-31',
        };
        // this.form.controls.positionId.disable();
      });
  }

  initComponentMode() {
    const employeeInPositionId = this.getEmployeeInPositionId();

    if (employeeInPositionId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.employeeInPositionService
          .CreateEmployeeInPosition({
            ...this.model,
            terminateDate: this.model.isNotSpecifyTerminateDate ? '9999-12-31' : this.model.terminateDate,
          })
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.employeeInPositionService
          .UpdateEmployeeInPosition({
            ...this.model,
            terminateDate: this.model.isNotSpecifyTerminateDate ? '9999-12-31' : this.model.terminateDate,
          })
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getPositionId() {
    return this.config.data?.positionId;
  }

  getEmployeeId() {
    return this.config.data?.employeeId;
  }

  getEmployeeInPositionId() {
    return this.config.data?.employeeInPositionId;
  }
}
