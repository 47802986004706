import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-repeat-benefit-policy-row',
  templateUrl: './repeat-benefit-policy-row.component.html',
  styleUrls: ['./repeat-benefit-policy-row.component.css'],
})
export class RepeatBenefitPolicyRowComponent extends FieldArrayType {
  addRow() {
    this.add();

    this.to.afterAddRow();
  }
}
