/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeAddressModel } from '../models/employee-address-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeAddressService extends __BaseService {
  static readonly GetEmployeeAddressByIdPath = '/api/EmployeeAddress/{employeeAddressId}';
  static readonly DeleteEmployeeAddressPath = '/api/EmployeeAddress/{employeeAddressId}';
  static readonly CreateEmployeeAddressPath = '/api/EmployeeAddress';
  static readonly UpdateEmployeeAddressPath = '/api/EmployeeAddress';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param employeeAddressId undefined
   * @return Success
   */
  GetEmployeeAddressByIdResponse(employeeAddressId: number): __Observable<__StrictHttpResponse<EmployeeAddressModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeAddress/${encodeURIComponent(String(employeeAddressId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeAddressModel>;
      })
    );
  }
  /**
   * @param employeeAddressId undefined
   * @return Success
   */
  GetEmployeeAddressById(employeeAddressId: number): __Observable<EmployeeAddressModel> {
    return this.GetEmployeeAddressByIdResponse(employeeAddressId).pipe(
      __map(_r => _r.body as EmployeeAddressModel)
    );
  }

  /**
   * @param employeeAddressId undefined
   * @return Success
   */
  DeleteEmployeeAddressResponse(employeeAddressId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeAddress/${encodeURIComponent(String(employeeAddressId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeAddressId undefined
   * @return Success
   */
  DeleteEmployeeAddress(employeeAddressId: number): __Observable<string> {
    return this.DeleteEmployeeAddressResponse(employeeAddressId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeAddressResponse(body?: EmployeeAddressModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeAddress(body?: EmployeeAddressModel): __Observable<string> {
    return this.CreateEmployeeAddressResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeAddressResponse(body?: EmployeeAddressModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeAddress(body?: EmployeeAddressModel): __Observable<string> {
    return this.UpdateEmployeeAddressResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeAddressService {
}

export { EmployeeAddressService }
