/* tslint:disable */
type EFileProcessingType =
  'Import' |
  'Export';
module EFileProcessingType {
  export const IMPORT: EFileProcessingType = 'Import';
  export const EXPORT: EFileProcessingType = 'Export';
  export function values(): EFileProcessingType[] {
    return [
      IMPORT,
      EXPORT
    ];
  }
}

export { EFileProcessingType }