import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { EmployeeCompetenceModel } from 'app/api/models';
import { EmployeeCompetenceService } from 'app/api/services/employee-competence.service';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';

@Component({
  selector: 'app-competence',
  templateUrl: './competence.component.html',
  styleUrls: ['./competence.component.css'],
  providers: [DialogService],
})
export class CompetenceComponent implements OnInit {
  isEditable = false;
  ref: DynamicDialogRef;

  employeeUpdatedEvent = new EventEmitter();

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private notification: AppNotificationServiceService,
    private employeeCompetenceService: EmployeeCompetenceService,
    private masterDataRepo: MasterDataRepo, // private employeeCompetenceService: EmployeeCompetenceService,
  ) { }

  isShowErr = true;

  form = new FormGroup({});
  model: EmployeeCompetenceModel = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      template: `<hr style="margin-top: 20px; margin-bottom: 20px;"><h3>ความสามารถทางภาษา</h3>`,
    },
    {
      key: 'employeeLanguages',
      type: 'repeat',
      templateOptions: {
        addText: '+ เพิ่มความสามารถทางภาษา',
        bigTitle: 'ความสามารถทางภาษา ',
        littleTitle: 'ความสามารถทางภาษา ',
        deleteTitle: 'ลบข้อมูลความสามารถทางภาษา',
      },
      fieldArray: {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            type: 'filter-dropdown',
            key: 'languageId',
            wrappers: ['inline-label'],
            className: 'col-12',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%;',
              },
              placeholder: '@blank',
              label: 'language',
              required: true,
              labelClass: 'col-2',
              inputClass: 'col-3',
              options: this.masterDataRepo.getLanguageOptionList(),
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'speakingProficiency',
            type: 'radio',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'speaking',
              placeholder: '@blank',
              required: true,
              labelClass: 'col-4',
              inputClass: 'col-6',
              options: [
                { value: 1, label: 'ดีมาก' },
                { value: 2, label: 'ดี' },
                { value: 3, label: 'พอใช้' },
              ],
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'listeningProficiency',
            type: 'radio',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'listening',
              placeholder: '@blank',
              required: true,
              labelClass: 'col-4',
              inputClass: 'col-6',
              options: [
                { value: 1, label: 'ดีมาก' },
                { value: 2, label: 'ดี' },
                { value: 3, label: 'พอใช้' },
              ],
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'readingProficiency',
            type: 'radio',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'read',
              placeholder: '@blank',
              required: true,
              labelClass: 'col-4',
              inputClass: 'col-6',
              options: [
                { value: 1, label: 'ดีมาก' },
                { value: 2, label: 'ดี' },
                { value: 3, label: 'พอใช้' },
              ],
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'writingProficiency',
            type: 'radio',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 't_write',
              placeholder: '@blank',
              required: true,
              labelClass: 'col-4',
              inputClass: 'col-6',
              options: [
                { value: 1, label: 'ดีมาก' },
                { value: 2, label: 'ดี' },
                { value: 3, label: 'พอใช้' },
              ],
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'toeicScore',
            type: 'primeng-input',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'toeic',
              placeholder: '@blank',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'tofelScore',
            type: 'primeng-input',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'toefl',
              placeholder: '@blank',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'ieltsScore',
            type: 'primeng-input',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'itlst',
              placeholder: '@blank',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'otherScore',
            type: 'primeng-input',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'other',
              placeholder: '@blank',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
            },
            validation: {
              show: this.isShowErr,
            },
          },
        ],
      },
    },
    {
      template: `<hr style="margin-top: 20px; margin-bottom: 20px;"><h3>ความสามารถพิเศษอื่นๆ</h3>`,
    },
    {
      fieldGroupClassName: 'grid',
      key: 'typingSkill',
      fieldGroup: [
        {
          key: 'isHasTypingSkill',
          type: 'checkbox',
          className: 'col-2',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'typewriting',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            change: () => { },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'primeng-input',
          key: 'th',
          wrappers: ['inline-label'],
          className: 'col-4',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            label: 'thai_language',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasTypingSkill',
        },
        {
          type: 'primeng-input',
          key: 'eng',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            label: 'english_language',
            placeholder: '@blank',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasTypingSkill',
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      key: 'driveCarSkill',
      fieldGroup: [
        {
          key: 'isHasDrivingSkill',
          type: 'checkbox',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'driving_car',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'isHasPersonalCar',
          type: 'radio',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            attributes: {
              style: 'width: 100%;',
            },
            label: 'มีรถยนต์ส่วนตัวหรือไม่',
            labelClass: 'col-3',
            inputClass: 'col-2',
            options: [
              { value: true, label: 'มี' },
              { value: false, label: 'ไม่มี' },
            ],
          },
          defaultValue: false,
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasDrivingSkill',
        },
        {
          className: 'col-6',
          type: 'primeng-input',
          key: 'licenseNumber',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,

            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'driver_license_number',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasDrivingSkill',
        },
        {
          className: 'col-6',
          type: 'calendar',
          key: 'expireDate',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'expiration_date',

            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasDrivingSkill',
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      key: 'driveMotorcySkill',
      fieldGroup: [
        {
          key: 'isHasMortorCyclingSkill',
          type: 'checkbox',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            attributes: {
              style: 'width: 100%;',
            },
            label: 'driving_motorcycle',
            labelClass: 'col',
            inputClass: 'col',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'isHasMotorcycle',
          type: 'radio',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            attributes: {
              style: 'width: 100%;',
            },
            label: 'มีจักรยานยนต์ส่วนตัวหรือไม่',
            labelClass: 'col-3',
            inputClass: 'col-2',
            options: [
              { value: true, label: 'มี' },
              { value: false, label: 'ไม่มี' },
            ],
          },
          defaultValue: false,
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasMortorCyclingSkill',
        },
        {
          className: 'col-6',
          type: 'primeng-input',
          key: 'motorcycleLicenseNumber',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'driver_license_number',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasMortorCyclingSkill',
        },
        {
          className: 'col-6',
          type: 'calendar',
          key: 'motorcycleExpireDate',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'expiration_date',

            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasMortorCyclingSkill',
        },
      ],
    },
  ];

  ngOnInit() {
    this.setEditablePermission();
    this.setCompetence();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setCompetence() {
    const employeeId = this.getEmployeeId();
    this.employeeCompetenceService.GetEmployeeCompetenceByEmployeeId(employeeId).subscribe(competences => {
      this.model = {
        ...competences,
      };
    });
  }

  updateComptence() {
    const employeeId = this.getEmployeeId();

    const competences = {
      ...this.model,
      driveCarSkill: {
        isHasDrivingSkill: this.model.driveCarSkill.isHasDrivingSkill,
        isHasPersonalCar: this.model.driveCarSkill.isHasPersonalCar,
        isDontHasPersonalCar: !this.model.driveCarSkill.isHasPersonalCar,
        licenseNumber: this.model.driveCarSkill.isHasPersonalCar ? this.model.driveCarSkill.licenseNumber : null,
        expireDate: this.model.driveCarSkill.isHasPersonalCar ? this.model.driveCarSkill.expireDate : null,
      },
      driveMotorcySkill: {
        isHasMortorCyclingSkill: this.model.driveMotorcySkill.isHasMortorCyclingSkill,
        isHasMotorcycle: this.model.driveMotorcySkill.isHasMotorcycle,
        isDontHasMotorcycle: !this.model.driveMotorcySkill.isHasMotorcycle,
        motorcycleLicenseNumber: this.model.driveMotorcySkill.isHasMotorcycle
          ? this.model.driveMotorcySkill.motorcycleLicenseNumber
          : null,
        motorcycleExpireDate: this.model.driveMotorcySkill.isHasMotorcycle
          ? this.model.driveMotorcySkill.motorcycleExpireDate
          : null,
      },
    };

    this.employeeCompetenceService.UpdateEmployeeCompetence({ employeeId: employeeId, body: competences }).subscribe(
      () => {
        this.notification.saveCompleted();
        this.setCompetence();
        this.employeeUpdatedEvent.next(null);
      },
      (err: HttpErrorResponse) => {
        const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
        this.notification.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
      },
    );
  }

  onUpdateComptenceBtnClick() {
    this.updateComptence();
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: '75%',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }
}
