import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LayoutDefaultComponent } from '../layout-default.component';
import { AppMenuService } from './app-menu.service';
import { AppMenuItem, AppMenuService as MenuService2 } from '../../../services/app-menu.service';
import { ApplicationSettingsService } from '../../../services/application-settings.service';
import { ViewUtilsService } from '../../../services/view-utils.service';
import { ApplicationSettingsExtended } from '../../../api/models';

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-menuitem]',
  /* tslint:enable:component-selector */
  template: `
    <!-- <ng-container *ngxPermissionsOnly="item.permissions">
      <ng-container *ngxPermissionsOnly="item.permissions2"> -->
    <a
      [attr.href]="item.url"
      (click)="itemClick($event)"
      *ngIf="!item.routerLink || item.items"
      (mouseenter)="onMouseEnter()"
      (keydown.enter)="itemClick($event)"
      [attr.target]="item.target"
      [attr.tabindex]="0"
    >
      <span class="menuitem-text">{{ item.label }}</span>
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
      <i [ngClass]="item.icon"></i>
    </a>
    <a
      (click)="itemClick($event)"
      (mouseenter)="onMouseEnter()"
      *ngIf="item.routerLink && !item.items"
      [routerLink]="item.routerLink"
      routerLinkActive="active-menuitem-routerlink"
      [attr.target]="item.target"
      [attr.tabindex]="0"
    >
      <span class="menuitem-text">{{ item.label }}</span>
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
      <i [ngClass]="item.icon"></i>
    </a>
    <ul
      *ngIf="item.items"
      [@children]="
        layout.isHorizontal() && root ? (active ? 'visible' : 'hidden') : active ? 'visibleAnimated' : 'hiddenAnimated'
      "
    >
      <ng-template ngFor let-child let-i="index" [ngForOf]="getMenuItems() ">
        <!-- <ng-container *ngxPermissionsOnly="child.permissions">
              <ng-container *ngxPermissionsOnly="child.permissions2">
                </ng-container>
              </ng-container> -->
        <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="" (notifyActive)="onNotifyActive($event)"></li>
      </ng-template>
    </ul>
    <!-- </ng-container>
    </ng-container> -->
  `,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class.active-menuitem]': 'active',
  },
  animations: [
    trigger('children', [
      state(
        'void',
        style({
          height: '0px',
        }),
      ),
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        }),
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        }),
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100,
        }),
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*',
        }),
      ),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('void => visibleAnimated, visibleAnimated => void', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class AppMenuItemComponent implements OnInit, OnDestroy {
  appSettings: ApplicationSettingsExtended;
  constructor(
    public layout: LayoutDefaultComponent,
    public router: Router,
    public globalSettingsService: ApplicationSettingsService,
    private menuService: AppMenuService,
    public viewUtils: ViewUtilsService,
    private menuService2: MenuService2,
    private appSettingsService: ApplicationSettingsService
  ) {
    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.updateActiveStateFromRoute();
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(params => {
      this.updateActiveStateFromRoute();
    });
  }
  @Input() item: AppMenuItem;
  @Input() index: number;
  @Input() root: boolean;
  @Input() parentKey: string;
  @Output() notifyActive: EventEmitter<string> = new EventEmitter<string>();

  active = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key: string;

  getMenuItems() {
    return this.item.items
      .filter(a => this.menuService2.checkMenuVisible(a, this.appSettings));
  }

  async ngOnInit() {
    this.appSettings = await this.appSettingsService.getApplicationSettings().toPromise();

    if (!this.layout.isHorizontal() && this.item.routerLink) {
      this.updateActiveStateFromRoute();
    }

    this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
  }
  onNotifyActive(v) {
    this.active = true;
    this.notifyActive.emit(v);
  }
  updateActiveStateFromRoute() {
    this.active = this.router.isActive(this.item.routerLink[0], { paths: 'exact', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' });
    // notitfy active to parent
    if (this.active) {
      console.log(this.item.routerLink[0]);
      this.notifyActive.emit('active');
    }
  }

  itemClick(event: Event) {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return true;
    }

    // navigate with hover in horizontal mode
    if (this.root) {
      this.layout.menuHoverActive = !this.layout.menuHoverActive;
    }

    // notify other items
    this.menuService.onMenuStateChange(this.key);

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    this.active = !this.active;
  }

  onMouseEnter() {
    // activate item on hover
    if (this.root && this.layout.menuHoverActive && this.layout.isHorizontal() && this.layout.isDesktop()) {
      this.menuService.onMenuStateChange(this.key);
      this.active = true;
    }
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
