/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ImportLeaveRequestModel } from '../models/import-leave-request-model';
import { LeaveRequestModelPaginationResult } from '../models/leave-request-model-pagination-result';
import { LeaveRequestModel } from '../models/leave-request-model';
@Injectable({
  providedIn: 'root',
})
class LeaveRequestService extends __BaseService {
  static readonly ImportExcelStep1Path = '/api/LeaveRequest/run/import-request/step1';
  static readonly ImportExcelStep2Path = '/api/LeaveRequest/run/import-request/step2';
  static readonly GetLeaveRequestsListPath = '/api/LeaveRequest';
  static readonly CreateLeaveRequestPath = '/api/LeaveRequest';
  static readonly UpdateLeaveRequestPath = '/api/LeaveRequest';
  static readonly GetLeaveRequestByIdPath = '/api/LeaveRequest/{leaveRequestId}';
  static readonly DeleteLeaveRequestPath = '/api/LeaveRequest/{leaveRequestId}';
  static readonly RecalulateLeaveRequestPath = '/api/run/recalculate-leave-request';
  static readonly GetMyLeaveRequestsListPath = '/api/LeaveRequest/MyLeaveRequests';
  static readonly CancelLeaveRequestByLeaveItemPath = '/api/LeaveRequestItem/{leaveRequestItemId}';
  static readonly CancelLeaveRequestPath = '/api/LeaveRequest/{leaveRequestId}/Cancel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param file undefined
   * @return Success
   */
  ImportExcelStep1Response(file?: Blob): __Observable<__StrictHttpResponse<ImportLeaveRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/LeaveRequest/run/import-request/step1`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImportLeaveRequestModel>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  ImportExcelStep1(file?: Blob): __Observable<ImportLeaveRequestModel> {
    return this.ImportExcelStep1Response(file).pipe(
      __map(_r => _r.body as ImportLeaveRequestModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep2Response(body?: ImportLeaveRequestModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/LeaveRequest/run/import-request/step2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep2(body?: ImportLeaveRequestModel): __Observable<string> {
    return this.ImportExcelStep2Response(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `LeaveRequestService.GetLeaveRequestsListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestDate`:
   *
   * - `PositionId`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `LeaveTypeId`:
   *
   * - `LeaveStatusId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetLeaveRequestsListResponse(params: LeaveRequestService.GetLeaveRequestsListParams): __Observable<__StrictHttpResponse<LeaveRequestModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RequestDate != null) __params = __params.set('RequestDate', params.RequestDate.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.PersonnelAreaId != null) __params = __params.set('PersonnelAreaId', params.PersonnelAreaId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.LeaveTypeId != null) __params = __params.set('LeaveTypeId', params.LeaveTypeId.toString());
    if (params.LeaveStatusId != null) __params = __params.set('LeaveStatusId', params.LeaveStatusId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LeaveRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LeaveRequestModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `LeaveRequestService.GetLeaveRequestsListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestDate`:
   *
   * - `PositionId`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `LeaveTypeId`:
   *
   * - `LeaveStatusId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetLeaveRequestsList(params: LeaveRequestService.GetLeaveRequestsListParams): __Observable<LeaveRequestModelPaginationResult> {
    return this.GetLeaveRequestsListResponse(params).pipe(
      __map(_r => _r.body as LeaveRequestModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateLeaveRequestResponse(body?: LeaveRequestModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/LeaveRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateLeaveRequest(body?: LeaveRequestModel): __Observable<number> {
    return this.CreateLeaveRequestResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateLeaveRequestResponse(body?: LeaveRequestModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/LeaveRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateLeaveRequest(body?: LeaveRequestModel): __Observable<number> {
    return this.UpdateLeaveRequestResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param leaveRequestId undefined
   * @return Success
   */
  GetLeaveRequestByIdResponse(leaveRequestId: number): __Observable<__StrictHttpResponse<LeaveRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LeaveRequest/${encodeURIComponent(String(leaveRequestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LeaveRequestModel>;
      })
    );
  }
  /**
   * @param leaveRequestId undefined
   * @return Success
   */
  GetLeaveRequestById(leaveRequestId: number): __Observable<LeaveRequestModel> {
    return this.GetLeaveRequestByIdResponse(leaveRequestId).pipe(
      __map(_r => _r.body as LeaveRequestModel)
    );
  }

  /**
   * @param leaveRequestId undefined
   * @return Success
   */
  DeleteLeaveRequestResponse(leaveRequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/LeaveRequest/${encodeURIComponent(String(leaveRequestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param leaveRequestId undefined
   * @return Success
   */
  DeleteLeaveRequest(leaveRequestId: number): __Observable<string> {
    return this.DeleteLeaveRequestResponse(leaveRequestId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `LeaveRequestService.RecalulateLeaveRequestParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `end`:
   *
   * @return Success
   */
  RecalulateLeaveRequestResponse(params: LeaveRequestService.RecalulateLeaveRequestParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.start != null) __params = __params.set('start', params.start.toString());
    if (params.end != null) __params = __params.set('end', params.end.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/run/recalculate-leave-request`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `LeaveRequestService.RecalulateLeaveRequestParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `end`:
   *
   * @return Success
   */
  RecalulateLeaveRequest(params: LeaveRequestService.RecalulateLeaveRequestParams): __Observable<string> {
    return this.RecalulateLeaveRequestResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetMyLeaveRequestsListResponse(): __Observable<__StrictHttpResponse<Array<LeaveRequestModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LeaveRequest/MyLeaveRequests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LeaveRequestModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetMyLeaveRequestsList(): __Observable<Array<LeaveRequestModel>> {
    return this.GetMyLeaveRequestsListResponse().pipe(
      __map(_r => _r.body as Array<LeaveRequestModel>)
    );
  }

  /**
   * @param leaveRequestItemId undefined
   * @return Success
   */
  CancelLeaveRequestByLeaveItemResponse(leaveRequestItemId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/LeaveRequestItem/${encodeURIComponent(String(leaveRequestItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param leaveRequestItemId undefined
   * @return Success
   */
  CancelLeaveRequestByLeaveItem(leaveRequestItemId: number): __Observable<string> {
    return this.CancelLeaveRequestByLeaveItemResponse(leaveRequestItemId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param leaveRequestId undefined
   * @return Success
   */
  CancelLeaveRequestResponse(leaveRequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/LeaveRequest/${encodeURIComponent(String(leaveRequestId))}/Cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param leaveRequestId undefined
   * @return Success
   */
  CancelLeaveRequest(leaveRequestId: number): __Observable<string> {
    return this.CancelLeaveRequestResponse(leaveRequestId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module LeaveRequestService {

  /**
   * Parameters for GetLeaveRequestsList
   */
  export interface GetLeaveRequestsListParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    RequestDate?: string;
    PositionId?: number;
    PersonnelAreaId?: number;
    Page?: number;
    LocationId?: number;
    LeaveTypeId?: number;
    LeaveStatusId?: any;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsAllEmployee?: boolean;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    CompanyId?: number;
    BranchId?: number;
  }

  /**
   * Parameters for RecalulateLeaveRequest
   */
  export interface RecalulateLeaveRequestParams {
    start?: string;
    end?: string;
  }
}

export { LeaveRequestService }
