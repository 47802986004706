<div *ngIf="isMultiSelectMode">
  <p-chips [(ngModel)]="selectedUnitIds" [disabled]="true" ></p-chips>
</div>
<button
  *ngIf="to.required"
  type="text"
  [disabled]="to.disabled"
  pButton
  type="button"
  [label]="label"
  (click)="showUnitSearchModal()"
></button>
<p-splitButton
  *ngIf="!to.required"
  [disabled]="to.disabled"
  (onClick)="showUnitSearchModal()"
  [label]="label"
  [model]="items"
  [style]="{'width':'100%'}"
></p-splitButton>
