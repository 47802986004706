/* tslint:disable */
type ELeaveTypesInAllowanceType =
  'Include' |
  'Exclude';
module ELeaveTypesInAllowanceType {
  export const INCLUDE: ELeaveTypesInAllowanceType = 'Include';
  export const EXCLUDE: ELeaveTypesInAllowanceType = 'Exclude';
  export function values(): ELeaveTypesInAllowanceType[] {
    return [
      INCLUDE,
      EXCLUDE
    ];
  }
}

export { ELeaveTypesInAllowanceType }