<div class="grid align-start block">
    <div
      *ngIf="to.label && to.hideLabel !== true"
      [class]="to.labelClass"
      [ngStyle]="to.labelStyle"
    >
      <label>
        {{ to.label }}
        <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
      </label>
    </div>
    <div [class]="to.inputClass">
      <ng-container #fieldComponent></ng-container>
      <div>
        {{ field.templateOptions.helpMessage }}
      </div>
      <div class="p-message ui-widget ui-corner-all p-message-error p-messages-error" *ngIf="showError">
        <formly-validation-message class="p-message-text" [field]="field"></formly-validation-message>
      </div>
    </div>
    <div *ngIf="to.btnClick && !to.disabled" [class]="to.btnClass">
      <button
        pButton
        type="button"
        [label]="to.btnLabel"
        [disabled]="to.btnDisabled"
        (click)="to.btnClick(this, field)"
      ></button>
    </div>
  </div>
  