<div *ngFor="let afield of field.fieldGroup; let i = index" class="grid align-center">
  <div class="text-label" [class]="to.labelClass">
    <label *ngIf="i === 0">{{ to?.label }}</label>
  </div>
  <formly-field class="repeated-form" [class]="to.inputClass" [field]="afield"></formly-field>
  <div class="col-fixed">
    <i class="fa fa-trash" (click)="remove(i)"></i>
  </div>
</div>
<div class="grid align-center">
  <div class="text-label" [class]="to.labelClass">
    <label *ngIf="field.fieldGroup.length === 0">{{ to?.label }}</label>
  </div>
  <div class="col-fixed">
    <button
      pButton
      type="button"
      [pTooltip]="to?.tooltip || undefined"
      (click)="showPositionSearchModal()"
      label="{{ to?.addSectionLabel }}"
    ></button>
  </div>
</div>
