import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { CountryService } from 'app/api/services';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CountryModel } from 'app/api/models';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof CountryModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-modal-countries',
  templateUrl: './modal-countries.component.html',
  styleUrls: ['./modal-countries.component.css'],
})
export class ModalCountriesComponent implements OnInit, OnDestroy {
  model: CountryModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'countryCode',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'code',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
      hooks: {
        afterContentInit: (field?: FormlyFieldConfig) => {
          if (this.componentMode === ComponentMode.UPDATE) {
            field.formControl.disable({ emitEvent: true });
          }
        },
      },
    },
    {
      key: 'nameTH',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'name_Th',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-7',
      },
    },
    {
      key: 'nameEN',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'name_En',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-7',
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private countryService: CountryService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const countryCode = this.getCountryCode();

    if (countryCode) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {};
      return;
    }

    this.model = await this.countryService.GetCountryById(this.getCountryCode()).toPromise();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createCountry()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateCountry()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createCountry() {
    return this.countryService.CreateCountry({
      countryCode: this.model.countryCode,
      nameTH: this.model.nameTH,
      nameEN: this.model.nameEN,
      terminateDate: '9999-09-09T00:00:00', // FIXME: set at backend?
    });
  }

  private updateCountry() {
    return this.countryService.UpdateCountry({
      CountryId: this.model.countryCode,
      body: {
        countryCode: this.model.countryCode,
        nameTH: this.model.nameTH,
        nameEN: this.model.nameEN,
        createdBy: this.model.createdBy,
        createdDate: this.model.createdDate,
        effectiveDate: this.model.effectiveDate,
        terminateDate: this.model.terminateDate,
      },
    });
  }

  getCountryCode() {
    return this.config.data?.id;
  }
}
