import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { EmployeeTransferModel } from 'app/api/models/employee-transfer-model';
import { EmployeeTransferService } from 'app/api/services/employee-transfer.service';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import * as moment from 'moment';
//import { EmployeeTransferModel } from 'app/api/models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeTransferModel;
}

@Component({
  selector: 'app-modal-transfer-in-company',
  templateUrl: './modal-transfer-in-company.component.html',
  styleUrls: ['./modal-transfer-in-company.component.scss']
})
export class ModalTransferInCompanyComponent implements OnInit {

  form = new FormGroup({});
  model: any = {};
  fields: CustomFormlyFieldConfig[] = []
  isEditable = true;

  constructor(private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private masterDataRepo: MasterDataRepo,
    private employeetransferService: EmployeeTransferService,) {
  }

  ngOnInit(): void {
    this.generateForm();
  }

  generateForm() {
    this.fields = [
      {
        key: 'positionId',
        type: 'select-position-2',
        wrappers: ['inline-label'],
        templateOptions: {
          required: true,
          translate: true,
          label: 'position',
          labelClass: 'col-4',
          inputClass: 'col-6',
          placeholder: '@blank',
        }
      },
      {
        key: 'positionTakingType',
        type: 'select',
        wrappers: ['inline-label'],
        templateOptions: {
          required: true,
          translate: true,
          label: 'type_tenure',
          labelClass: 'col-4',
          inputClass: 'col-6',
          placeholder: '@blank',
          options: this.masterDataRepo.getPositionTakingTypesOptionList()
        }
      },
      {
        type: 'filter-dropdown',
        key: 'employeeTypeId',
        wrappers: ['inline-label'],
        expressionProperties: {
          'templateOptions.disabled': () => !this.isEditable,
        },
        templateOptions: {
          translate: true,
          attributes: {
            style: 'width: 100%;',
          },
          placeholder: '@blank',
          label: 'working_type',
          required: true,
          labelClass: 'col-4',
          inputClass: 'col-6',
          options: this.masterDataRepo.getEmployeeTypeOptionList(),
        },
      },
      {
        type: 'filter-dropdown',
        key: 'employeeType2Id',
        wrappers: ['inline-label'],

        expressionProperties: {
          'templateOptions.disabled': () => !this.isEditable,
        },
        templateOptions: {
          translate: true,
          attributes: {
            style: 'width: 100%;',
          },
          placeholder: '@blank',
          label: 'employee_manpower',
          required: true,
          labelClass: 'col-4',
          inputClass: 'col-6',
          options: this.masterDataRepo.getEmployeeType2OptionList(),
        },
      },
      {
        type: 'filter-dropdown',
        key: 'payType',
        wrappers: ['inline-label'],

        expressionProperties: {
          'templateOptions.disabled': () => !this.isEditable,
        },
        templateOptions: {
          translate: true,
          placeholder: '@blank',
          label: 'type_payment',
          required: true,
          labelClass: 'col-4',
          inputClass: 'col-6',
          options: this.masterDataRepo.getPayTypeOptionList(),
        },
      },
      {
        key: 'salary',
        type: 'primeng-input',
        defaultValue: 0,
        wrappers: ['inline-label'],
        templateOptions: {
          translate: true,
          label: 'salary',
          placeholder: '@blank',
          labelClass: 'col-4',
          inputClass: 'col-6',
          required: true,
          type: 'number',
        },
      },
      {
        key: 'effectiveDate',
        type: 'calendar',
        defaultValue: moment().format('YYYY-MM-DD'),
        wrappers: ['inline-label'],
        templateOptions: {
          translate: true,
          label: 'start_date_s',
          placeholder: '@blank',
          required: true,
          labelClass: 'col-4',
          inputClass: 'col-4',
        },
      },
      {
        fieldGroupClassName: 'grid align-center',
        fieldGroup: [
          {
            key: 'terminateDate',
            type: 'calendar',
            className: 'col-8',
            wrappers: ['inline-label'],
            templateOptions: {
              translate: true,
              label: 'end_use_date',
              placeholder: '@blank',
              labelClass: 'col-6',
              inputClass: 'col',
              required: true,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.isNotSpecifyTerminateDate',
            },
          },
          {
            key: 'isNotSpecifyTerminateDate',
            type: 'checkbox',
            className: 'col-4',
            wrappers: ['inline-label'],
            defaultValue: true,
            templateOptions: {
              attributes: {
                style: 'width: 100%;',
              },
              label: 'no_date_specified',
              translate: true,
              placeholder: '@blank',
              labelClass: 'col',
            },
          },
        ],
      },
      {
        key: 'remark',
        type: 'primeng-input',
        wrappers: ['inline-label'],
        templateOptions: {
          translate: true,
          label: 'remark',
          placeholder: '@blank',
          labelClass: 'col-4',
          inputClass: 'col-4',
        },
      },
    ];
  }

  submit() {
    this.employeetransferService.TransferPosition({ employeeId: this.getEmployeeId(), body: this.model }).subscribe(res => {
      this.ref.close(res);
    });
    console.log(this.model);
  }

  close() {
    this.ref.close();
  }

  getEmployeeId() {
    return this.config.data?.employeeId;
  }
}
