<div class="tw-flex tw-flex-row tw-flex-wrap tw-gap-8 tw-justify-center">
  <ng-container *ngFor="let menu of menuItems">
    <ng-container *ngxPermissionsOnly="menu.permissions">
      <ng-container *ngxPermissionsOnly="menu.permissions2">
        <ng-container *ngIf="appMenuService.checkMenuVisible(menu, appSettings)">
          <div class="tw-flex tw-flex-col tw-items-center" style="width: 130px">
            <div class="item-badge tw-drop-shadow" (mouseover)="itemHover = getText(menu)" (mouseout)="itemHover = ''"
              (click)="onIconClick(menu)" [ngClass]="{
                      'shadow-2': itemHover === getText(menu)
                    }">
              <i class="{{ menu.icon }}" style="font-size:35px"></i>
            </div>
            <div class="tw-drop-shadow tw-items-center tw-text-center tw-pt-4">
              <b>{{ getText(menu) }}</b>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
