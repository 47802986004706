import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmployeeInsigniaListItemModel } from 'app/api/models';
import { EmployeeInsigniaService, EmployeeService } from 'app/api/services';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { ModalEmployeeInsigniaComponent } from 'modals/core/modal-employee-insignia/modal-employee-insignia.component';

@Component({
  selector: 'app-insignia',
  templateUrl: './insignia.component.html',
  styleUrls: ['./insignia.component.scss']
})
export class InsigniaComponent implements OnInit {
  isEditable = false;
  insigniaList: EmployeeInsigniaListItemModel[];
  ref: DynamicDialogRef;

  constructor(
    private layout: LayoutDefaultComponent,
    private router: Router,
    private notificationService: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeInsigniaService: EmployeeInsigniaService,
    private employeeService: EmployeeService,
  ) {}

  ngOnInit() {
    this.setEditablePermission();
    this.setInsigniaList();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setInsigniaList() {
    const employeeId = this.getEmployeeId();
    this.employeeService.GetInsignias(employeeId).subscribe(training => {
      this.insigniaList = training;
    });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');
    return +urls[urls.length - 2];
  }

  showCreateInsigniaDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่ม เครื่องราชอิสริยาภรณ์', ModalEmployeeInsigniaComponent);
    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setInsigniaList();
      }
    });
  }

  UpdateInsigniaDialog(trainingId: number) {
    this.showDialog({ id: trainingId }, 'แก้ไข เครื่องราชอิสริยาภรณ์', ModalEmployeeInsigniaComponent);
    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setInsigniaList();
      }
    });
  }

  DeleteInsigniaDialog(trainingId: number) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeeInsigniaService.DeleteEmployeeInsignia(trainingId).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.setInsigniaList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }

}
