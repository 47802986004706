<div class="grid">
  <div class="col-fixed">
    <h1>{{ 'employee_profile' | translate }}</h1>
  </div>
  <div class="col-fixed" *ngIf="isShowBtn">
    <button pButton type="button" label="{{ 'select_employees' | translate }}"
      (click)="showEmployeeSearchModal()"></button>
  </div>
</div>
<div class="grid justify-content-center" *ngIf="employee">
  <div class="grid col-12 md:col-2 align-start justify-content-center text-sm-center">
    <img src="/api/employee/{{ employee.employeeId }}/image" width="100" />
  </div>
  <div class="grid col-12 md:col-9 lg:col-9 align-center">
    <div class="grid text-sm-center">
      <div class="col-6 md:col-6 lg:col-4">
        <div>
          <label>{{ 'name_surname' | translate }}</label>
        </div>
        <div>
          <b>{{ employee.employeeName }}</b>
        </div>
      </div>
      <div class="col-6 md:col-6 lg:col-4">
        <div>
          <label>{{ 'employee_id' | translate }}</label>
        </div>
        <div>
          <b>{{ employee.employeeCode }}</b>
        </div>
      </div>
      <div class="col-6 md:col-6 lg:col-4">
        <div>
          <label>{{ 'start_date_work' | translate }}</label>
        </div>
        <div>
          <b>{{ employee.employeeDate | date: 'dd/MM/yyyy' }}</b>
        </div>
      </div>
      <div class="col-6 md:col-6 lg:col-4">
        <div>
          <label>{{ 'position' | translate }}</label>
        </div>
        <div>
          <b>{{ employee.positionName }}</b>
        </div>
      </div>
      <div class="col-6 md:col-6 lg:col-4">
        <div>
          <label>แผนก</label>
        </div>
        <div>
          <b>{{ employee.deptName || 'N/A' }}</b>
        </div>
      </div>
      <div class="col-6 md:col-6 lg:col-4">
        <div>
          <label>{{ 'division' | translate }}</label>
        </div>
        <div>
          <b>{{ employee.divisionName || 'N/A' }}</b>
        </div>
      </div>
    </div>
  </div>
</div>