import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-time-dropdown',
  templateUrl: './formly-time-dropdown.component.html',
  styleUrls: ['./formly-time-dropdown.component.scss']
})
export class FormlyTimeDropdownComponent extends FieldWrapper implements OnInit {
  timeOptions = [];

  defaultOptions = {
    templateOptions: { minutesOffset: 30 },
  };

  fixValue(){
    if (this.formControl.value && typeof this.formControl.value === 'string') {
      console.log(this.formControl.value);
      let valueSplit = this.formControl.value.split(':');
      let newValue = valueSplit[0].padStart(2, '0') + ':' + valueSplit[1].padStart(2, '0');
      if (newValue != this.formControl.value) {
        this.formControl.setValue(newValue);
      }
    }
  }

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }
  ngOnInit() {
    this.fixValue();
    // this.timeOptions.push({
    //   label: '\xa0',
    //   value: null
    // });
    // populate time picker
    for (let h = 0 ; h <= 23; h++)
    {
      for (let m = 0; m <= 59; m += this.to.minutesOffset)
      {
        this.timeOptions.push({
          label: `${this.pad(h, 2)}:${this.pad(m, 2)}`,
          value: `${this.pad(h, 2)}:${this.pad(m, 2)}`
        });
      }
    }
  }
}
