/* tslint:disable */
type EDayType =
  'WorkDay' |
  'WeekEnd' |
  'Holiday' |
  'WorkDayWithQuota';
module EDayType {
  export const WORK_DAY: EDayType = 'WorkDay';
  export const WEEK_END: EDayType = 'WeekEnd';
  export const HOLIDAY: EDayType = 'Holiday';
  export const WORK_DAY_WITH_QUOTA: EDayType = 'WorkDayWithQuota';
  export function values(): EDayType[] {
    return [
      WORK_DAY,
      WEEK_END,
      HOLIDAY,
      WORK_DAY_WITH_QUOTA
    ];
  }
}

export { EDayType }