import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface UnitTreeNode extends TreeNode {
  data?: {
    id: number;
    name: string;
    unitLevel: string;
    fullName: string;
  };
  children?: UnitTreeNode[];
}

@Component({
  selector: 'app-modal-unit-search',
  templateUrl: './modal-unit-search.component.html',
  styleUrls: ['./modal-unit-search.component.css'],
})
export class ModalUnitSearchComponent implements OnInit {
  units: UnitTreeNode[];
  cols = [
    { field: 'name', header: 'ชื่อหน่วยงาน' },
    { field: 'unitLevel', header: 'ระดับหน่วยงาน' },
    { field: 'company', header: 'บริษัท' },
  ];
  selectedUnitIds: number[] = [];
  isMultiSelectMode = false;

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.isMultiSelectMode = !!this.config.data?.isMultiSelectMode;
    this.selectedUnitIds = this.config.data?.selectedUnitIds || [];
    this.units = this.config.data?.units || [];

  }

  select(event, selectNode: UnitTreeNode) {
    if (this.isMultiSelectMode) {
      this.addToUnitIds(event, selectNode);
    } else {
      this.ref.close(selectNode);
    }
  }

  addToUnitIds(event, selectNode: UnitTreeNode) {
    if (!this.selectedUnitIds.includes(selectNode.data.id)) {
      this.selectedUnitIds.push(selectNode.data.id);
    }
  }

  submitMultiSelectUnit() {
    this.ref.close(this.selectedUnitIds);
  }

  onChipAddedManual(event) {
    const { value } = event;

    console.log('no permission add ', value);
    // tslint:disable-next-line: triple-equals
    this.selectedUnitIds = this.selectedUnitIds.filter(a => a != value);
  }
}
