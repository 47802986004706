import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],
})
export class UploadFileComponent extends FieldWrapper implements OnInit {
  @ViewChild('fileUpload') fileUpload;
  file: any = {};
  displayedImage = "";
  defaultOptions = {
    templateOptions: { mode: 'basic' }, // (advanced, basic)
  };

  ngOnInit(): void {
    debugger;
    this.displayedImage=this.to.value?'/api/document/'+this.to.value+'/download':"";
    this.formControl.valueChanges.subscribe(documentId => {
    debugger;
     this.displayedImage=""
      this.file = {
        documentId:documentId,
      };
    });
  }
  clear() {
    this.formControl.markAsDirty();
    this.fileUpload.clear();
    this.displayedImage=""
    this.file.documentId = null;
    this.model.documentOfAttendanceCertifyId = 0;
    this.model.DocumentOfAttendanceCertify= null
  }
}
