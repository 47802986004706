/* tslint:disable */
type EUserStatus =
  'InUse' |
  'Disabled' |
  'Terminated';
module EUserStatus {
  export const IN_USE: EUserStatus = 'InUse';
  export const DISABLED: EUserStatus = 'Disabled';
  export const TERMINATED: EUserStatus = 'Terminated';
  export function values(): EUserStatus[] {
    return [
      IN_USE,
      DISABLED,
      TERMINATED
    ];
  }
}

export { EUserStatus }