import { Component, OnInit, OnDestroy } from '@angular/core';
import { UnitLevelOfSystemModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UnitLevelOfSystemService } from 'app/api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';


interface UnitLevelOfSystemFormModel extends UnitLevelOfSystemModel {
  isNotSpecifyTerminateDate?: boolean;
}

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'app-modal-unit-level-of-system',
  templateUrl: './modal-unit-level-of-system.component.html',
  styleUrls: ['./modal-unit-level-of-system.component.css'],
})
export class ModalUnitLevelOfSystemComponent implements OnInit, OnDestroy {
  model: UnitLevelOfSystemFormModel = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'level',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'department_level',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        type: 'number',
        required: true,
        helpMessage: '* เลขน้อยคือหน่วยงานระดับสูง'
      },
    },
    {
      key: 'description',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'description',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: true,
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'start_date_user',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
        required: true,
      },
    },
    {
      fieldGroupClassName: 'grid align-center',
      fieldGroup: [
        {
          key: 'terminateDate',
          type: 'calendar',
          className: 'col-8',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'end_use_date',
            placeholder: '@blank',
            labelClass: 'col-6',
            inputClass: 'col',
            required: true,
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.isNotSpecifyTerminateDate',
          },
        },
        {
          key: 'isNotSpecifyTerminateDate',
          type: 'checkbox',
          className: 'col-4',
          wrappers: ['inline-label'],
          defaultValue: true,
          templateOptions: {
            translate: true,
            label: 'no_date_specified',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            attributes: {
              style: 'width: 100%;',
            },
          },
          //
        },
      ],
    },
    // {
    //   key: 'terminateDate',
    //   type: 'primeng-input',
    //   wrappers: ['inline-label'],
    //   templateOptions: {
    //     attributes: {
    //       style: 'width: 100%',
    //     },
    //     label: 'วันที่สิ่นสุดใช้งาน',
    //     placeholder: 'วันที่สิ่นสุดใช้งาน',
    //     labelClass: 'col-4',
    //     inputClass: 'col-3',
    //     type: 'date',
    //   },
    // },
  ];
  unsubscribe$ = new Subject<any>();
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private unitLevelOfSystemService: UnitLevelOfSystemService,
    private noti: AppNotificationServiceService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const unitLevelOfSystemId = this.getUnitLevelOfSystemId();

    if (unitLevelOfSystemId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {};
      return;
    }

    const unit = await this.unitLevelOfSystemService
      .GetUnitLevelOfSystemById(this.getUnitLevelOfSystemId())
      .toPromise();

    this.model = {
      ...unit,
      effectiveDate: (unit.effectiveDate),
      terminateDate: (unit.terminateDate),
      isNotSpecifyTerminateDate: (unit.terminateDate) === '9999-12-31',
    };
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    if (this.componentMode === ComponentMode.CREATE) {
      this.createUnitLevelOfSystem()
        .subscribe(
          () => {
            this.noti.saveCompleted();
            this.ref.close(this.model);
          },
          (err: HttpErrorResponse) => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
          },
        );
    } else {
      this.updateUnitLevelOfSystem()
        .subscribe(
          () => {
            this.noti.saveCompleted();
            this.ref.close(this.model);
          },
          (err: HttpErrorResponse) => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
          },
        );
    }
  }

  close() {
    this.ref.close();
    this.unsubscribe$.unsubscribe();
  }

  private createUnitLevelOfSystem() {
    return this.unitLevelOfSystemService.CreateUnitLevelOfSystem({
      ...this.model,
      terminateDate: this.model.isNotSpecifyTerminateDate ? '9999-12-31' : this.model.terminateDate,
    });
  }

  private updateUnitLevelOfSystem() {
    return this.unitLevelOfSystemService.UpdateUnitLevelOfSystem({
      ...this.model,
      terminateDate: this.model.isNotSpecifyTerminateDate ? '9999-12-31' : this.model.terminateDate,
    });
  }

  getUnitLevelOfSystemId() {
    return this.config.data?.id;
  }
}
