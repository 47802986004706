import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LayoutDefaultComponent } from '../layout-default.component';
import { AppMenuService, AppMenuItem } from '../../../services/app-menu.service';
import { ApplicationSettingsService } from '../../../services/application-settings.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ViewUtilsService } from '../../../services/view-utils.service';
import { ApplicationSettingsExtended } from '../../../api/models';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.css']
})
export class AppMenuComponent implements OnInit {
  model: AppMenuItem[];
  appSettings: ApplicationSettingsExtended;

  constructor(
    public app: LayoutDefaultComponent,
    private menuService: AppMenuService,
    public applicationSettingService: ApplicationSettingsService,
    public viewUtils: ViewUtilsService,
  ) { }

  async ngOnInit() {
    this.appSettings = await this.applicationSettingService.getApplicationSettings().toPromise();
    this.model = this.menuService.getMenu();
  }

  onMenuClick() {
    this.app.menuClick = true;
  }

  getMenuItems() {
    let menus = this.model
      .filter(a => this.menuService.checkMenuVisible(a, this.appSettings));
    return menus;
  }
}
