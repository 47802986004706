/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { StiNetCoreViewer } from '../models/sti-net-core-viewer';
import { PayrollByOrganizationReportModelParam } from '../models/payroll-by-organization-report-model-param';
import { ReportQueryParamModel } from '../models/report-query-param-model';
@Injectable({
  providedIn: 'root',
})
class ReportService extends __BaseService {
  static readonly InitViewerPath = '/api/Report/InitViewer/{ReportName}';
  static readonly ViewerEventPath = '/api/Report/ViewerEvent/salary_summary_split_by_income_and_deduction';
  static readonly GetAccessReportPath = '/api/Report/AccessReport';
  static readonly GetNewEmployeeListReportPath = '/api/Report/NewEmployeeListReport';
  static readonly GetQuittedEmployeeListReportPath = '/api/Report/QuittedEmployeeListReport';
  static readonly GetEmployeeListReportPath = '/api/Report/EmployeeListReport';
  static readonly GetReportForPMRPath = '/api/Report/ReportForPMR';
  static readonly GetPrivilegeReportPath = '/api/Report/PrivilegeReport';
  static readonly GetPayrollReportSalaryStructurePath = '/api/Report/PayrollReportSalaryStructure';
  static readonly GetPayrollByOrganizationReportPath = '/api/Report/PayrollByOrganizationReport';
  static readonly GetPayrollByOrganizationReportExcelPath = '/api/Report/PayrollByOrganizationReportExcel';
  static readonly GetPayrollReportPath = '/api/Report/PayrollReport';
  static readonly GetPayrollPrivilegeReportPath = '/api/Report/PayrollPrivilegeReport';
  static readonly GetSalaryIncreaseReportPath = '/api/Report/SalaryIncreaseReport';
  static readonly GetSalaryIncreaseApprovalReportPath = '/api/Report/SalaryIncreaseApprovalReport';
  static readonly GetPayrollSlipReportPath = '/api/Report/PayrollSlipReport';
  static readonly GetPayrollAccoutingReportPath = '/api/Report/PayrollAccoutingReport';
  static readonly GetPayElementReportPath = '/api/Report/PayElementReport';
  static readonly GetTavi50ReportPath = '/api/Report/Tavi50Report';
  static readonly GetPnd1KorReportPath = '/api/Report/Pnd1KorReport';
  static readonly GetPnd3ReportPath = '/api/Report/Pnd3Report';
  static readonly GetPnd1ReportPath = '/api/Report/Pnd1Report';
  static readonly GetPnd1ForOnlineReportPath = '/api/Report/Pnd1ForOnlineReport';
  static readonly GetTaxReductFormPath = '/api/Report/TaxReductForm';
  static readonly GetReportSlipsPath = '/api/Report/Report_Slips_payroll_report';
  static readonly GetReportPaySlipPath = '/api/Report/Report_Slip/{payslipId}';
  static readonly GetOverTimeReportPath = '/api/Report/OverTimeReport';
  static readonly GeOverTimeReportSummaryPath = '/api/Report/OverTimeReportSummary';
  static readonly GetTimeAttendanceReportPath = '/api/Report/TimeAttendanceReport';
  static readonly GetTimeAttendanceReportSummaryPath = '/api/Report/TimeAttendanceReportSummary';
  static readonly GetReportForEmployeeSumBenefitPath = '/api/Report/ReportForEmpSumBenefit';
  static readonly ExportBPlusExcelPath = '/api/Report/bplus_report';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param ReportName undefined
   */
  InitViewerResponse(ReportName: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Report/InitViewer/${encodeURIComponent(String(ReportName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param ReportName undefined
   */
  InitViewer(ReportName: string): __Observable<null> {
    return this.InitViewerResponse(ReportName).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  ViewerEventResponse(): __Observable<__StrictHttpResponse<StiNetCoreViewer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Report/ViewerEvent/salary_summary_split_by_income_and_deduction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StiNetCoreViewer>;
      })
    );
  }
  /**
   * @return Success
   */
  ViewerEvent(): __Observable<StiNetCoreViewer> {
    return this.ViewerEventResponse().pipe(
      __map(_r => _r.body as StiNetCoreViewer)
    );
  }
  GetAccessReportResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/AccessReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  GetAccessReport(): __Observable<null> {
    return this.GetAccessReportResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ReportService.GetNewEmployeeListReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetNewEmployeeListReportResponse(params: ReportService.GetNewEmployeeListReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.AsOfDate != null) __params = __params.set('AsOfDate', params.AsOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/NewEmployeeListReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetNewEmployeeListReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetNewEmployeeListReport(params: ReportService.GetNewEmployeeListReportParams): __Observable<Blob> {
    return this.GetNewEmployeeListReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetQuittedEmployeeListReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetQuittedEmployeeListReportResponse(params: ReportService.GetQuittedEmployeeListReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.AsOfDate != null) __params = __params.set('AsOfDate', params.AsOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/QuittedEmployeeListReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetQuittedEmployeeListReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetQuittedEmployeeListReport(params: ReportService.GetQuittedEmployeeListReportParams): __Observable<Blob> {
    return this.GetQuittedEmployeeListReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetEmployeeListReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetEmployeeListReportResponse(params: ReportService.GetEmployeeListReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.AsOfDate != null) __params = __params.set('AsOfDate', params.AsOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/EmployeeListReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetEmployeeListReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetEmployeeListReport(params: ReportService.GetEmployeeListReportParams): __Observable<Blob> {
    return this.GetEmployeeListReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetReportForPMRParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetReportForPMRResponse(params: ReportService.GetReportForPMRParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.AsOfDate != null) __params = __params.set('AsOfDate', params.AsOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/ReportForPMR`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetReportForPMRParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetReportForPMR(params: ReportService.GetReportForPMRParams): __Observable<Blob> {
    return this.GetReportForPMRResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPrivilegeReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Roleid`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetPrivilegeReportResponse(params: ReportService.GetPrivilegeReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Roleid != null) __params = __params.set('Roleid', params.Roleid.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.AsOfDate != null) __params = __params.set('AsOfDate', params.AsOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/PrivilegeReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPrivilegeReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Roleid`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetPrivilegeReport(params: ReportService.GetPrivilegeReportParams): __Observable<Blob> {
    return this.GetPrivilegeReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPayrollReportSalaryStructureParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPayrollReportSalaryStructureResponse(params: ReportService.GetPayrollReportSalaryStructureParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WagePayType != null) __params = __params.set('WagePayType', params.WagePayType.toString());
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/PayrollReportSalaryStructure`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPayrollReportSalaryStructureParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPayrollReportSalaryStructure(params: ReportService.GetPayrollReportSalaryStructureParams): __Observable<Blob> {
    return this.GetPayrollReportSalaryStructureResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPayrollByOrganizationReportParams` containing the following parameters:
   *
   * - `ReturnFileType`:
   *
   * - `EndDate`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPayrollByOrganizationReportResponse(params: ReportService.GetPayrollByOrganizationReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/PayrollByOrganizationReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPayrollByOrganizationReportParams` containing the following parameters:
   *
   * - `ReturnFileType`:
   *
   * - `EndDate`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPayrollByOrganizationReport(params: ReportService.GetPayrollByOrganizationReportParams): __Observable<Blob> {
    return this.GetPayrollByOrganizationReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetPayrollByOrganizationReportExcelResponse(body?: PayrollByOrganizationReportModelParam): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Report/PayrollByOrganizationReportExcel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetPayrollByOrganizationReportExcel(body?: PayrollByOrganizationReportModelParam): __Observable<Blob> {
    return this.GetPayrollByOrganizationReportExcelResponse(body).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPayrollReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ReturnFileType`:
   *
   * - `IssueMonth`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetPayrollReportResponse(params: ReportService.GetPayrollReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.IssueMonth != null) __params = __params.set('IssueMonth', params.IssueMonth.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/PayrollReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPayrollReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ReturnFileType`:
   *
   * - `IssueMonth`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetPayrollReport(params: ReportService.GetPayrollReportParams): __Observable<Blob> {
    return this.GetPayrollReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPayrollPrivilegeReportParams` containing the following parameters:
   *
   * - `Roleid`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetPayrollPrivilegeReportResponse(params: ReportService.GetPayrollPrivilegeReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Roleid != null) __params = __params.set('Roleid', params.Roleid.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.AsOfDate != null) __params = __params.set('AsOfDate', params.AsOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/PayrollPrivilegeReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPayrollPrivilegeReportParams` containing the following parameters:
   *
   * - `Roleid`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetPayrollPrivilegeReport(params: ReportService.GetPayrollPrivilegeReportParams): __Observable<Blob> {
    return this.GetPayrollPrivilegeReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetSalaryIncreaseReportParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetSalaryIncreaseReportResponse(params: ReportService.GetSalaryIncreaseReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WagePayType != null) __params = __params.set('WagePayType', params.WagePayType.toString());
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/SalaryIncreaseReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetSalaryIncreaseReportParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetSalaryIncreaseReport(params: ReportService.GetSalaryIncreaseReportParams): __Observable<Blob> {
    return this.GetSalaryIncreaseReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetSalaryIncreaseApprovalReportParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetSalaryIncreaseApprovalReportResponse(params: ReportService.GetSalaryIncreaseApprovalReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WagePayType != null) __params = __params.set('WagePayType', params.WagePayType.toString());
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/SalaryIncreaseApprovalReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetSalaryIncreaseApprovalReportParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetSalaryIncreaseApprovalReport(params: ReportService.GetSalaryIncreaseApprovalReportParams): __Observable<Blob> {
    return this.GetSalaryIncreaseApprovalReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPayrollSlipReportParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPayrollSlipReportResponse(params: ReportService.GetPayrollSlipReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WagePayType != null) __params = __params.set('WagePayType', params.WagePayType.toString());
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/PayrollSlipReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPayrollSlipReportParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPayrollSlipReport(params: ReportService.GetPayrollSlipReportParams): __Observable<Blob> {
    return this.GetPayrollSlipReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPayrollAccoutingReportParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPayrollAccoutingReportResponse(params: ReportService.GetPayrollAccoutingReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WagePayType != null) __params = __params.set('WagePayType', params.WagePayType.toString());
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/PayrollAccoutingReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPayrollAccoutingReportParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPayrollAccoutingReport(params: ReportService.GetPayrollAccoutingReportParams): __Observable<Blob> {
    return this.GetPayrollAccoutingReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPayElementReportParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPayElementReportResponse(params: ReportService.GetPayElementReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WagePayType != null) __params = __params.set('WagePayType', params.WagePayType.toString());
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/PayElementReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPayElementReportParams` containing the following parameters:
   *
   * - `WagePayType`:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPayElementReport(params: ReportService.GetPayElementReportParams): __Observable<Blob> {
    return this.GetPayElementReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetTavi50ReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetTavi50ReportResponse(params: ReportService.GetTavi50ReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/Tavi50Report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetTavi50ReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetTavi50Report(params: ReportService.GetTavi50ReportParams): __Observable<Blob> {
    return this.GetTavi50ReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPnd1KorReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPnd1KorReportResponse(params: ReportService.GetPnd1KorReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/Pnd1KorReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPnd1KorReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPnd1KorReport(params: ReportService.GetPnd1KorReportParams): __Observable<Blob> {
    return this.GetPnd1KorReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPnd3ReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPnd3ReportResponse(params: ReportService.GetPnd3ReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/Pnd3Report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPnd3ReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPnd3Report(params: ReportService.GetPnd3ReportParams): __Observable<Blob> {
    return this.GetPnd3ReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPnd1ReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPnd1ReportResponse(params: ReportService.GetPnd1ReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/Pnd1Report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPnd1ReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPnd1Report(params: ReportService.GetPnd1ReportParams): __Observable<Blob> {
    return this.GetPnd1ReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetPnd1ForOnlineReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPnd1ForOnlineReportResponse(params: ReportService.GetPnd1ForOnlineReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/Pnd1ForOnlineReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetPnd1ForOnlineReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetPnd1ForOnlineReport(params: ReportService.GetPnd1ForOnlineReportParams): __Observable<Blob> {
    return this.GetPnd1ForOnlineReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetTaxReductFormParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetTaxReductFormResponse(params: ReportService.GetTaxReductFormParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.EmployeeTypeId || []).forEach(val => {if (val != null) __params = __params.append('EmployeeTypeId', val.toString())});
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BeginDate != null) __params = __params.set('BeginDate', params.BeginDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/TaxReductForm`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetTaxReductFormParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `SearchText`:
   *
   * - `ReturnFileType`:
   *
   * - `PositionId`:
   *
   * - `LocationId`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `EndDate`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BeginDate`:
   *
   * @return Success
   */
  GetTaxReductForm(params: ReportService.GetTaxReductFormParams): __Observable<Blob> {
    return this.GetTaxReductFormResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetReportSlipsParams` containing the following parameters:
   *
   * - `Year`:
   *
   * - `ReturnFileType`:
   *
   * - `PayrollPeriodId`:
   *
   * - `Month`:
   *
   * - `EmployeeCode`:
   *
   * @return Success
   */
  GetReportSlipsResponse(params: ReportService.GetReportSlipsParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Year != null) __params = __params.set('Year', params.Year.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PayrollPeriodId != null) __params = __params.set('PayrollPeriodId', params.PayrollPeriodId.toString());
    if (params.Month != null) __params = __params.set('Month', params.Month.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/Report_Slips_payroll_report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetReportSlipsParams` containing the following parameters:
   *
   * - `Year`:
   *
   * - `ReturnFileType`:
   *
   * - `PayrollPeriodId`:
   *
   * - `Month`:
   *
   * - `EmployeeCode`:
   *
   * @return Success
   */
  GetReportSlips(params: ReportService.GetReportSlipsParams): __Observable<Blob> {
    return this.GetReportSlipsResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param payslipId undefined
   * @return Success
   */
  GetReportPaySlipResponse(payslipId: number): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/Report_Slip/${encodeURIComponent(String(payslipId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param payslipId undefined
   * @return Success
   */
  GetReportPaySlip(payslipId: number): __Observable<Blob> {
    return this.GetReportPaySlipResponse(payslipId).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetOverTimeReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `IsAllEmployee`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetOverTimeReportResponse(params: ReportService.GetOverTimeReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.AsOfDate != null) __params = __params.set('AsOfDate', params.AsOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/OverTimeReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetOverTimeReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `IsAllEmployee`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetOverTimeReport(params: ReportService.GetOverTimeReportParams): __Observable<Blob> {
    return this.GetOverTimeReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GeOverTimeReportSummaryParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `IsAllEmployee`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GeOverTimeReportSummaryResponse(params: ReportService.GeOverTimeReportSummaryParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.AsOfDate != null) __params = __params.set('AsOfDate', params.AsOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/OverTimeReportSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GeOverTimeReportSummaryParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `IsAllEmployee`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GeOverTimeReportSummary(params: ReportService.GeOverTimeReportSummaryParams): __Observable<Blob> {
    return this.GeOverTimeReportSummaryResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetTimeAttendanceReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `IsAllEmployee`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetTimeAttendanceReportResponse(params: ReportService.GetTimeAttendanceReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/TimeAttendanceReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetTimeAttendanceReportParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `IsAllEmployee`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetTimeAttendanceReport(params: ReportService.GetTimeAttendanceReportParams): __Observable<Blob> {
    return this.GetTimeAttendanceReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetTimeAttendanceReportSummaryParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `IsAllEmployee`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetTimeAttendanceReportSummaryResponse(params: ReportService.GetTimeAttendanceReportSummaryParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/TimeAttendanceReportSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetTimeAttendanceReportSummaryParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `IsAllEmployee`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetTimeAttendanceReportSummary(params: ReportService.GetTimeAttendanceReportSummaryParams): __Observable<Blob> {
    return this.GetTimeAttendanceReportSummaryResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `ReportService.GetReportForEmployeeSumBenefitParams` containing the following parameters:
   *
   * - `Year`:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetReportForEmployeeSumBenefitResponse(params: ReportService.GetReportForEmployeeSumBenefitParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Year != null) __params = __params.set('Year', params.Year.toString());
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.ToDate != null) __params = __params.set('ToDate', params.ToDate.toString());
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.FromDate != null) __params = __params.set('FromDate', params.FromDate.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.AsOfDate != null) __params = __params.set('AsOfDate', params.AsOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Report/ReportForEmpSumBenefit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `ReportService.GetReportForEmployeeSumBenefitParams` containing the following parameters:
   *
   * - `Year`:
   *
   * - `UnitId`:
   *
   * - `ToDate`:
   *
   * - `ReturnFileType`:
   *
   * - `LocationId`:
   *
   * - `FromDate`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `AsOfDate`:
   *
   * @return Success
   */
  GetReportForEmployeeSumBenefit(params: ReportService.GetReportForEmployeeSumBenefitParams): __Observable<Blob> {
    return this.GetReportForEmployeeSumBenefitResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ExportBPlusExcelResponse(body?: ReportQueryParamModel): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Report/bplus_report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ExportBPlusExcel(body?: ReportQueryParamModel): __Observable<Blob> {
    return this.ExportBPlusExcelResponse(body).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module ReportService {

  /**
   * Parameters for GetNewEmployeeListReport
   */
  export interface GetNewEmployeeListReportParams {
    UnitId?: number;
    ToDate?: string;
    ReturnFileType?: any;
    LocationId?: number;
    FromDate?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    AsOfDate?: string;
  }

  /**
   * Parameters for GetQuittedEmployeeListReport
   */
  export interface GetQuittedEmployeeListReportParams {
    UnitId?: number;
    ToDate?: string;
    ReturnFileType?: any;
    LocationId?: number;
    FromDate?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    AsOfDate?: string;
  }

  /**
   * Parameters for GetEmployeeListReport
   */
  export interface GetEmployeeListReportParams {
    UnitId?: number;
    ToDate?: string;
    ReturnFileType?: any;
    LocationId?: number;
    FromDate?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    AsOfDate?: string;
  }

  /**
   * Parameters for GetReportForPMR
   */
  export interface GetReportForPMRParams {
    UnitId?: number;
    ToDate?: string;
    ReturnFileType?: any;
    LocationId?: number;
    FromDate?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    AsOfDate?: string;
  }

  /**
   * Parameters for GetPrivilegeReport
   */
  export interface GetPrivilegeReportParams {
    UnitId?: number;
    Roleid?: number;
    ReturnFileType?: any;
    LocationId?: number;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    AsOfDate?: string;
  }

  /**
   * Parameters for GetPayrollReportSalaryStructure
   */
  export interface GetPayrollReportSalaryStructureParams {
    WagePayType?: any;
    UnitId?: number;
    SearchText?: string;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetPayrollByOrganizationReport
   */
  export interface GetPayrollByOrganizationReportParams {
    ReturnFileType?: any;
    EndDate?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetPayrollReport
   */
  export interface GetPayrollReportParams {
    UnitId?: number;
    ReturnFileType?: any;
    IssueMonth?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for GetPayrollPrivilegeReport
   */
  export interface GetPayrollPrivilegeReportParams {
    Roleid?: number;
    ReturnFileType?: any;
    LocationId?: number;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    AsOfDate?: string;
  }

  /**
   * Parameters for GetSalaryIncreaseReport
   */
  export interface GetSalaryIncreaseReportParams {
    WagePayType?: any;
    UnitId?: number;
    SearchText?: string;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetSalaryIncreaseApprovalReport
   */
  export interface GetSalaryIncreaseApprovalReportParams {
    WagePayType?: any;
    UnitId?: number;
    SearchText?: string;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetPayrollSlipReport
   */
  export interface GetPayrollSlipReportParams {
    WagePayType?: any;
    UnitId?: number;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetPayrollAccoutingReport
   */
  export interface GetPayrollAccoutingReportParams {
    WagePayType?: any;
    UnitId?: number;
    SearchText?: string;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetPayElementReport
   */
  export interface GetPayElementReportParams {
    WagePayType?: any;
    UnitId?: number;
    SearchText?: string;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetTavi50Report
   */
  export interface GetTavi50ReportParams {
    UnitId?: number;
    SearchText?: string;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetPnd1KorReport
   */
  export interface GetPnd1KorReportParams {
    UnitId?: number;
    SearchText?: string;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetPnd3Report
   */
  export interface GetPnd3ReportParams {
    UnitId?: number;
    SearchText?: string;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetPnd1Report
   */
  export interface GetPnd1ReportParams {
    UnitId?: number;
    SearchText?: string;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetPnd1ForOnlineReport
   */
  export interface GetPnd1ForOnlineReportParams {
    UnitId?: number;
    SearchText?: string;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetTaxReductForm
   */
  export interface GetTaxReductFormParams {
    UnitId?: number;
    SearchText?: string;
    ReturnFileType?: any;
    PositionId?: number;
    LocationId?: number;
    IsOnlySubOrdinate?: boolean;
    EndDate?: string;
    EmployeeTypeId?: Array<string>;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    BeginDate?: string;
  }

  /**
   * Parameters for GetReportSlips
   */
  export interface GetReportSlipsParams {
    Year?: number;
    ReturnFileType?: any;
    PayrollPeriodId?: number;
    Month?: number;
    EmployeeCode?: string;
  }

  /**
   * Parameters for GetOverTimeReport
   */
  export interface GetOverTimeReportParams {
    UnitId?: number;
    ToDate?: string;
    ReturnFileType?: any;
    LocationId?: number;
    IsAllEmployee?: boolean;
    FromDate?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    AsOfDate?: string;
  }

  /**
   * Parameters for GeOverTimeReportSummary
   */
  export interface GeOverTimeReportSummaryParams {
    UnitId?: number;
    ToDate?: string;
    ReturnFileType?: any;
    LocationId?: number;
    IsAllEmployee?: boolean;
    FromDate?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    AsOfDate?: string;
  }

  /**
   * Parameters for GetTimeAttendanceReport
   */
  export interface GetTimeAttendanceReportParams {
    UnitId?: number;
    ToDate?: string;
    ReturnFileType?: any;
    LocationId?: number;
    IsAllEmployee?: boolean;
    FromDate?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for GetTimeAttendanceReportSummary
   */
  export interface GetTimeAttendanceReportSummaryParams {
    UnitId?: number;
    ToDate?: string;
    ReturnFileType?: any;
    LocationId?: number;
    IsAllEmployee?: boolean;
    FromDate?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for GetReportForEmployeeSumBenefit
   */
  export interface GetReportForEmployeeSumBenefitParams {
    Year?: number;
    UnitId?: number;
    ToDate?: string;
    ReturnFileType?: any;
    LocationId?: number;
    FromDate?: string;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
    AsOfDate?: string;
  }
}

export { ReportService }
