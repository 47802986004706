<div class="grid align-start">
  <div
    *ngIf="to.label && to.hideLabel !== true"
    [class]="to.labelClass"
    [ngStyle]="to.labelStyle || { 'text-align': 'left', 'padding-top': '14px', 'margin-bottom': '40px' }"
  >
    <label>
      {{ to.label }}
      <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
    </label>
  </div>
</div>

<div [class]="to.inputClass">
  <ng-container #fieldComponent></ng-container>

  <div class="p-message ui-widget ui-corner-all p-message-error p-messages-error" *ngIf="showError">
    <formly-validation-message class="p-message-text" [field]="field"></formly-validation-message>
  </div>
</div>
