import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { map, startWith } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
export interface AddressCreateModel {
  addressTypeId?: string;
  address?: string;
  district?: string;
  subDistrict?: string;
  province?: string;
  zipcode?: string;
}

export interface EmployeePhoneCreateModel {
  homePhone?: string;
  mobilePhone?: string;
  facebookAccount?: string;
  twitterAccount?: string;
  instragramAccount?: string;
  lineID?: string;
  personalEmail?: string;
}

export interface EmployeeAddressCreateModel {
  currentAddressIsTheSameAsHomeAddress?: boolean;
  employeeRegisteredAddress: AddressCreateModel;
  employeeCurrentAddress: AddressCreateModel;
  employeePhone: EmployeePhoneCreateModel;
}

@Component({
  selector: 'app-employee-create-form-address-info',
  templateUrl: './employee-create-form-address-info.component.html',
  styleUrls: ['./employee-create-form-address-info.component.css'],
})
export class EmployeeCreateFormAddressInfoComponent implements OnInit {
  @Input('defaultModel')
  set defaultModel(val) {
    if (val) {
      Object.assign(this.model, val);
      // Initiate District/Sub-District for new employee request
      if (this.model.employeeRegisteredAddress.province) {
        const provinceId = this.masterDataRepo.getProvinceIdByName(this.model.employeeRegisteredAddress.province);
        this.fields[0].fieldGroup[2].templateOptions.options = this.masterDataRepo.getAmphoeOptionList(provinceId);

        const amphoeId = this.masterDataRepo.getAmphoeIdByName(this.model.employeeRegisteredAddress.district);
        this.fields[0].fieldGroup[3].templateOptions.options = this.masterDataRepo.getTambonOptionList(amphoeId);
      }

      if (this.model.employeeCurrentAddress.province) {
        const provinceId = this.masterDataRepo.getProvinceIdByName(this.model.employeeCurrentAddress.province);
        this.fields[3].fieldGroup[3].templateOptions.options = this.masterDataRepo.getAmphoeOptionList(provinceId);

        const amphoeId = this.masterDataRepo.getAmphoeIdByName(this.model.employeeCurrentAddress.district);
        this.fields[3].fieldGroup[4].templateOptions.options = this.masterDataRepo.getTambonOptionList(amphoeId);
      }
    }
  }

  isShowErr = true;

  form = new FormGroup({});
  model: EmployeeAddressCreateModel = {
    employeeRegisteredAddress: {},
    employeeCurrentAddress: {},
    employeePhone: {},
    currentAddressIsTheSameAsHomeAddress: true,
  };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    // {
    //   template: `<h3>{{'address_details_house' | translate}}</h3>`,
    // },
    {
      key: 'employeeRegisteredAddress',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        // {
        //   key: 'addressTypeId',
        //   type: 'filter-dropdown',
        //   className: 'col-6',
        //   wrappers: ['inline-label'],
        //   templateOptions: {
        //     attributes: {
        //       style: 'width: 100%;',
        //     },
        //     label: 'ประเภทที่อยู่อาศัย',
        //     placeholder: 'ประเภทที่อยู่อาศัย',
        //     options: this.masterDataRepo.getAddressTypeOptionList(),
        //     labelClass: 'col-3',
        //     inputClass: 'col-6',
        //   },
        // },
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-9',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'address',
            placeholder: '@blank',
            labelClass: 'col-2',
            inputClass: 'col',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'province',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'province',
            placeholder: '@blank',
            options: this.masterDataRepo.getProvinceOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',

            change: (_: FormlyFieldConfig, { value }) => {
              const provinceId = this.masterDataRepo.getProvinceIdByName(value);

              this.fields[0].fieldGroup[2].templateOptions.options =
                this.masterDataRepo.getAmphoeOptionList(provinceId);
              (this.form.controls.employeeRegisteredAddress as FormGroup).controls.district.reset();
              (this.form.controls.employeeRegisteredAddress as FormGroup).controls.subDistrict.reset();
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'district',
          type: 'filter-dropdown',
          className: 'col-6',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': '!model.province',
          },
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'district',
            placeholder: '@blank',

            labelClass: 'col-3',
            inputClass: 'col-6',
            change: (_: FormlyFieldConfig, { value }) => {
              const amphoeId = this.masterDataRepo.getAmphoeIdByName(value);
              console.log(value, amphoeId);

              this.fields[0].fieldGroup[3].templateOptions.options = this.masterDataRepo.getTambonOptionList(amphoeId);

              (this.form.controls.employeeRegisteredAddress as FormGroup).controls.subDistrict.reset();
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'subDistrict',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': '!model.district',
          },
          templateOptions: {
            translate: true,
            label: 'subdistrict',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'zipcode',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'postal_code',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            maxLength: 5,
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
    {
      template: `<h3>รายละเอียดที่อยู่ปัจจุบัน</h3>`,
    },
    {
      key: 'currentAddressIsTheSameAsHomeAddress',
      type: 'checkbox',
      className: 'col',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%;',
        },
        label: 'address_house_registration',
        placeholder: '@blank',
        labelClass: 'col',
        inputClass: 'col',
        // change: () => {
        //   if (this.form.controls.currentAddressIsTheSameAsHomeAddress.value) {
        //     this.form.controls.employeeCurrentAddress.reset();
        //     this.form.controls.employeeCurrentAddress.disable();
        //   } else {
        //     this.form.controls.employeeCurrentAddress.reset();
        //     this.form.controls.employeeCurrentAddress.enable();
        //   }
        // },
      },
      hooks: {
        afterContentInit: field => {
          field.formControl.valueChanges.subscribe(isChecked => {
            if (isChecked) {
              this.form.controls.employeeCurrentAddress.reset();
              this.form.controls.employeeCurrentAddress.disable();
            } else {
              this.form.controls.employeeCurrentAddress.enable();
            }
          });

          field.formControl.updateValueAndValidity();
        },
      },
      validation: {
        show: this.isShowErr,
      },
    },
    {
      key: 'employeeCurrentAddress',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'addressTypeId',
          type: 'filter-dropdown',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'housing_type',
            placeholder: '@blank',
            options: this.masterDataRepo
              .getAddressTypeOptionList()
              .pipe(map(a => a.filter(b => b.label !== 'ที่อยู่ตามทะเบียนบ้าน'))),
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-9',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'address',
            placeholder: '@blank',
            labelClass: 'col-2',
            inputClass: 'col',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'province',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'province',
            placeholder: '@blank',
            options: this.masterDataRepo.getProvinceOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',

            change: (_: FormlyFieldConfig, { value }) => {
              const provinceId = this.masterDataRepo.getProvinceIdByName(value);
              console.log(value, provinceId);

              this.fields[3].fieldGroup[3].templateOptions.options =
                this.masterDataRepo.getAmphoeOptionList(provinceId);
              (this.form.controls.employeeCurrentAddress as FormGroup).controls.district.reset();
              (this.form.controls.employeeCurrentAddress as FormGroup).controls.subDistrict.reset();
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'district',
          type: 'filter-dropdown',
          className: 'col-6',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': '!model.province',
          },
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'district',
            placeholder: '@blank',

            labelClass: 'col-3',
            inputClass: 'col-6',
            change: (_: FormlyFieldConfig, { value }) => {
              const amphoeId = this.masterDataRepo.getAmphoeIdByName(value);
              console.log(value, amphoeId);

              this.fields[3].fieldGroup[4].templateOptions.options = this.masterDataRepo.getTambonOptionList(amphoeId);
              (this.form.controls.employeeCurrentAddress as FormGroup).controls.subDistrict.reset();
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'subDistrict',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': '!model.district',
          },
          templateOptions: {
            translate: true,
            label: 'subdistrict',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'zipcode',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'postal_code',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            maxLength: 5,
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
  ];
  fields2: FormlyFieldConfig[] = [
    // {
    //   template: `<h3>รายละเอียดข้อมูลเบอร์โทรศัพท์</h3>`,
    // },
    {
      fieldGroupClassName: 'grid',
      key: 'employeePhone',
      fieldGroup: [
        {
          key: 'homePhone',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'home_phone',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'mobilePhone',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'phone_number',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'personalEmail',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'private_email',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'facebookAccount',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'facebook',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'twitterAccount',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'twitter',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'instragramAccount',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'instragram',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'lineID',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'line',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
  ];

  constructor(private masterDataRepo: MasterDataRepo, public translate: TranslateService) {}

  ngOnInit(): void {}
}
