import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AllowanceTypeModel, AllowanceTypeShiftModel, AllowanceTypeWithoutLeaveTypeModel } from 'app/api/models';
import { AllowanceTypeService } from 'app/api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { MasterDataRepo } from 'app/repo/master-data.repo';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof AllowanceTypeModel | keyof AllowanceTypeWithoutLeaveTypeModel;
  fieldGroup?: CustomFormlyFieldConfig[];
  fieldArray?: CustomFormlyFieldConfig;
}

@Component({
  selector: 'app-modal-allowance-type',
  templateUrl: './modal-allowance-type.component.html',
  styleUrls: ['./modal-allowance-type.component.css'],
})
export class ModalAllowanceTypeComponent implements OnInit, OnDestroy {
  model: AllowanceTypeModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'allowanceTypeId',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'code',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-4',
        maxLength: 10,
      },
      hooks: {
        afterContentInit: field => {
          field.templateOptions.disabled = this.componentMode === ComponentMode.UPDATE;
        },
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,

        label: 'item_name',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 256,
      },
    },
    {
      key: 'description',
      type: 'textarea',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,

        label: 'description',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 2048,
      },
    },
    {
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'conditions',
        labelClass: 'col-4',
      },
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isNotAbsent',
          type: 'checkbox',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'not_absent_from',
            labelClass: 'col-6',
            inputClass: 'col-6 col-offset-8',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isNotLate',
          type: 'checkbox',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'not_late',
            labelClass: 'col-6',
            inputClass: 'col-6 col-offset-8',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isNotLeftEarly',
          type: 'checkbox',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'not_out_first',
            labelClass: 'col-6',
            inputClass: 'col-6 col-offset-8',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isUseWorkingHours',
          type: 'checkbox',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'hours_than_equal',
            labelClass: 'col-6',
            inputClass: 'col-8 col-offset-8',
          },
        },
        {
          key: 'workingHoursGreaterthanOrEqual',
          type: 'primeng-input',
          className: 'col-4',
          wrappers: ['inline-label'],
          templateOptions: {
            // translate: true,
            // required: true,

            inputClass: 'col-6 col-offset-5',
            type: 'number',
            min: 0,
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isUseShift',
          type: 'checkbox',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'workshift',
            labelClass: 'col-8',
            inputClass: 'col-8 col-offset-8',
          },
        },
        {
          key: 'shiftsInAllowanceType',
          type: 'radio',
          className: 'col-6',
          wrappers: ['inline-label'],
          hideExpression: (model: AllowanceTypeModel) => !model.isUseShift,
          templateOptions: {
            label: '',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-8',
            options: this.masterDataRepo.getShiftsInAllowanceTypesOptionList(),
          },
        },
        {
          key: 'allowanceTypeShifts',
          type: 'repeat-input',
          className: 'col-12',
          hideExpression: () => !this.model.isUseShift,
          templateOptions: {
            addText: '+ เพิ่มกะการทำงาน',
            inputClass: 'col-11',
          },
          fieldArray: {
            type: 'select-shift',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isUseLeaveType',
          type: 'checkbox',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            label: 'ประเภทการลา',
            labelClass: 'col-8',
            inputClass: 'col-8 col-offset-8',
          },
        },
        {
          key: 'leaveTypesInAllowanceType',
          type: 'radio',
          className: 'col-6',
          wrappers: ['inline-label'],
          hideExpression: (model: AllowanceTypeModel) => !model.isUseLeaveType,
          templateOptions: {
            label: '',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-8',
            options: this.masterDataRepo.getLeaveTypesInAllowanceTypesOptionList(),
          },
        },
        {
          key: 'allowanceTypeWithoutLeaveTypes',
          type: 'repeat-input',
          className: 'col-12',
          hideExpression: () => !this.model.isUseLeaveType,
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            addText: '+ เพิ่มประเภทการลา',
            label: 'not',
            inputClass: 'col-11',
          },
          fieldArray: {
            fieldGroup: [
              {
                key: 'benefitElementId',
                type: 'filter-dropdown',
                templateOptions: {
                  translate: true,

                  label: 'not',
                  placeholder: '@blank',
                  required: true,
                  options: this.masterDataRepo.getLeaveTypeOptionList(),
                },
              },
            ],
          },
        },
      ],
    },
  ];
  unsubscripe$ = new Subject<any>();
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private allowanceTypeService: AllowanceTypeService,
    private masterDataRepo: MasterDataRepo,
  ) {}

  ngOnInit() {
    this.initComponentMode();
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {
        isNotAbsent: false,
        isNotLate: false,
        isNotLeftEarly: false,
        isUseWorkingHours: false,
        workingHoursGreaterthanOrEqual: 0,
        isUseShift: false,
        shiftsInAllowanceType: 'Include',
        leaveTypesInAllowanceType: 'Include',
        allowanceTypeShifts: [],
        allowanceTypeWithoutLeaveTypes: [],
      };
    } else {
      this.allowanceTypeService
        .GetAllowanceTypeById(this.getAllowanceTypeId())
        .subscribe(allowanceType => {
          this.model = allowanceType;
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscripe$.unsubscribe();
  }

  initComponentMode() {
    const allowanceTypeId = this.getAllowanceTypeId();

    if (allowanceTypeId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  submit() {
    console.log(this.model);

    const mapShift = {};
    const uniqueAllowanceTypeShifts: AllowanceTypeShiftModel[] = [];
    this.model.allowanceTypeShifts
      .filter(a => a.shiftId != null)
      .forEach(a => {
        const key = `${a.shiftId}@${a.subShiftNumber ?? 0}`;
        if (mapShift[key]) {
          return;
        }
        mapShift[key] = true;
        uniqueAllowanceTypeShifts.push({ shiftId: a.shiftId, subShiftNumber: a.subShiftNumber ?? 0 });
      });

    const mapLeaveType = {};
    const uniqueLeaveTypes: AllowanceTypeWithoutLeaveTypeModel[] = [];
    this.model.allowanceTypeWithoutLeaveTypes.forEach(a => {
      const key = a.benefitElementId;
      if (mapLeaveType[key]) {
        return;
      }
      mapLeaveType[key] = true;
      uniqueLeaveTypes.push({ benefitElementId: a.benefitElementId });
    });

    const model: AllowanceTypeModel = {
      ...this.model,
      workingHoursGreaterthanOrEqual: this.model.workingHoursGreaterthanOrEqual ?? 0,
      allowanceTypeShifts: this.model.isUseShift ? uniqueAllowanceTypeShifts : [],
      allowanceTypeWithoutLeaveTypes: this.model.isUseLeaveType ? uniqueLeaveTypes : [],
    };
    console.log({ ...model });

    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.allowanceTypeService
          .CreateAllowanceType(model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.allowanceTypeService
          .UpdateAllowanceType(model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getAllowanceTypeId() {
    return this.config.data?.id;
  }
}
