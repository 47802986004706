/* tslint:disable */
type EEmployeeUpdateInfoRequestStep =
  'Draft' |
  'AwaitingApproval' |
  'Finish';
module EEmployeeUpdateInfoRequestStep {
  export const DRAFT: EEmployeeUpdateInfoRequestStep = 'Draft';
  export const AWAITING_APPROVAL: EEmployeeUpdateInfoRequestStep = 'AwaitingApproval';
  export const FINISH: EEmployeeUpdateInfoRequestStep = 'Finish';
  export function values(): EEmployeeUpdateInfoRequestStep[] {
    return [
      DRAFT,
      AWAITING_APPROVAL,
      FINISH
    ];
  }
}

export { EEmployeeUpdateInfoRequestStep }