/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WorkflowModelPaginationResult } from '../models/workflow-model-pagination-result';
import { WorkflowInputModel } from '../models/workflow-input-model';
import { WorkflowModel } from '../models/workflow-model';
import { RequestStepItemModel } from '../models/request-step-item-model';
import { RequestStatusItemModel } from '../models/request-status-item-model';
@Injectable({
  providedIn: 'root',
})
class WorkflowService extends __BaseService {
  static readonly CreateWorkflowPath = '/api/Workflow';
  static readonly GetWorkflowsPath = '/api/Workflow';
  static readonly GetWorkflowInputPath = '/api/Workflow/{workflowId}/input';
  static readonly GetWorkflowPath = '/api/Workflow/{workflowId}';
  static readonly DeleteWorkflowPath = '/api/Workflow/{workflowId}';
  static readonly GetWorkflowStepsPath = '/api/Workflow/steps/{workflowTypeId}';
  static readonly GetWorkflowStatusPath = '/api/Workflow/status/{workflowTypeId}';
  static readonly CreateWorkflowFromUIPath = '/api/Workflow/fromui';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateWorkflowResponse(body?: any): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Workflow`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateWorkflow(body?: any): __Observable<string> {
    return this.CreateWorkflowResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `WorkflowService.GetWorkflowsParams` containing the following parameters:
   *
   * - `WorkflowTypeId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetWorkflowsResponse(params: WorkflowService.GetWorkflowsParams): __Observable<__StrictHttpResponse<WorkflowModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WorkflowTypeId != null) __params = __params.set('WorkflowTypeId', params.WorkflowTypeId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Workflow`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `WorkflowService.GetWorkflowsParams` containing the following parameters:
   *
   * - `WorkflowTypeId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetWorkflows(params: WorkflowService.GetWorkflowsParams): __Observable<WorkflowModelPaginationResult> {
    return this.GetWorkflowsResponse(params).pipe(
      __map(_r => _r.body as WorkflowModelPaginationResult)
    );
  }

  /**
   * @param workflowId undefined
   * @return Success
   */
  GetWorkflowInputResponse(workflowId: number): __Observable<__StrictHttpResponse<WorkflowInputModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Workflow/${encodeURIComponent(String(workflowId))}/input`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowInputModel>;
      })
    );
  }
  /**
   * @param workflowId undefined
   * @return Success
   */
  GetWorkflowInput(workflowId: number): __Observable<WorkflowInputModel> {
    return this.GetWorkflowInputResponse(workflowId).pipe(
      __map(_r => _r.body as WorkflowInputModel)
    );
  }

  /**
   * @param workflowId undefined
   * @return Success
   */
  GetWorkflowResponse(workflowId: number): __Observable<__StrictHttpResponse<WorkflowModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Workflow/${encodeURIComponent(String(workflowId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowModel>;
      })
    );
  }
  /**
   * @param workflowId undefined
   * @return Success
   */
  GetWorkflow(workflowId: number): __Observable<WorkflowModel> {
    return this.GetWorkflowResponse(workflowId).pipe(
      __map(_r => _r.body as WorkflowModel)
    );
  }

  /**
   * @param workflowId undefined
   * @return Success
   */
  DeleteWorkflowResponse(workflowId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Workflow/${encodeURIComponent(String(workflowId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param workflowId undefined
   * @return Success
   */
  DeleteWorkflow(workflowId: number): __Observable<string> {
    return this.DeleteWorkflowResponse(workflowId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param workflowTypeId undefined
   * @return Success
   */
  GetWorkflowStepsResponse(workflowTypeId: string): __Observable<__StrictHttpResponse<Array<RequestStepItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Workflow/steps/${encodeURIComponent(String(workflowTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RequestStepItemModel>>;
      })
    );
  }
  /**
   * @param workflowTypeId undefined
   * @return Success
   */
  GetWorkflowSteps(workflowTypeId: string): __Observable<Array<RequestStepItemModel>> {
    return this.GetWorkflowStepsResponse(workflowTypeId).pipe(
      __map(_r => _r.body as Array<RequestStepItemModel>)
    );
  }

  /**
   * @param workflowTypeId undefined
   * @return Success
   */
  GetWorkflowStatusResponse(workflowTypeId: string): __Observable<__StrictHttpResponse<Array<RequestStatusItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Workflow/status/${encodeURIComponent(String(workflowTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RequestStatusItemModel>>;
      })
    );
  }
  /**
   * @param workflowTypeId undefined
   * @return Success
   */
  GetWorkflowStatus(workflowTypeId: string): __Observable<Array<RequestStatusItemModel>> {
    return this.GetWorkflowStatusResponse(workflowTypeId).pipe(
      __map(_r => _r.body as Array<RequestStatusItemModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateWorkflowFromUIResponse(body?: WorkflowInputModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Workflow/fromui`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateWorkflowFromUI(body?: WorkflowInputModel): __Observable<string> {
    return this.CreateWorkflowFromUIResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module WorkflowService {

  /**
   * Parameters for GetWorkflows
   */
  export interface GetWorkflowsParams {
    WorkflowTypeId?: string;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { WorkflowService }
