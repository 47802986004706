import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ObjectPropertyInfo } from 'app/api/models';
import { MasterDataEditorService } from 'app/api/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'app-modal-edit-masterdata',
  templateUrl: './modal-edit-masterdata.component.html',
  styleUrls: ['./modal-edit-masterdata.component.scss']
})
export class ModalEditMasterdataComponent implements OnInit, OnDestroy {

  componentMode: ComponentMode;
  propertyModal: ObjectPropertyInfo[] = [];
  formDataModel: any = {};
  inputTypes = ["Int32", "String", "DateTime"]
  disabledFiled = ["Status", "CreatedDate", "CreatedBy", "UpdatedDate", "UpdatedBy"]

  model: any = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: '',
      type: '',
      defaultValue: '',
      templateOptions: {
        label: '',
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
  ];
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private masterDataEditorService: MasterDataEditorService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.initComponentMode();
  }

  ngOnDestroy() {
    this.unsubscribe$.unsubscribe();
  }

  createForm() {
    this.propertyModal = this.config.data?.propertyData;
    this.fields = this.propertyModal.filter(s => {
      if (this.inputTypes.includes(s.typeName) && !this.disabledFiled.includes(s.name)) {
        return s;
      }
    }).map((field) => {
      if (field.typeName === 'Int32' || field.typeName === 'Int64' || field.typeName === 'Int16') {
        return {
          key: this.camelize(field.name),
          type: 'primeng-input',
          wrappers: ['inline-label'],
          templateOptions: {
            label: field.name,
            type: 'number',
            labelClass: 'col-4',
            inputClass: 'col-4',
          },
        };
      } else if (field.typeName === 'String') {
        return {
          key: this.camelize(field.name),
          type: 'primeng-input',
          wrappers: ['inline-label'],
          templateOptions: {
            label: field.name,
            type: 'text',
            labelClass: 'col-4',
            inputClass: 'col-4',
          },
        };
      }
      else if (field.typeName === 'DateTime') {
        return {
          key: this.camelize(field.name),
          type: 'calendar',
          wrappers: ['inline-label'],
          defaultValue: new Date(),
          templateOptions: {
            label: field.name,
            labelClass: 'col-4',
            inputClass: 'col-4',
          },
        };
      }
    });
  }

  initComponentMode() {
    const formData = this.getFormData();
    if (formData) {
      this.componentMode = ComponentMode.UPDATE;
      this.model = formData;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  getFormData() {
    return this.config.data?.formData;
  }

  getTableName() {
    return this.config.data?.tableName || "";
  }

  close() {
    this.ref.close();
  }

  submit() {
    if (this.componentMode === ComponentMode.CREATE) {
      const jsonData = this.createJsonBody();
      let result = Object.assign(jsonData, this.model);
      result.CreatedBy = "ayodia";
      result.UpdatedBy = "ayodia";
      console.log(`result value: ${JSON.stringify(result)}`);
      this.masterDataEditorService.CreateObject({ dbSetName: (this.getTableName() as string), jsonValue: JSON.stringify(result) }).
        subscribe(res => {
          this.notificationService.saveCompleted();
          this.ref.close(this.model);
        }),
        (err) => {
          const errMsg = err.error?.message || err.message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        }
    }
    else if (this.componentMode === ComponentMode.UPDATE) {
      var result = Object.assign(this.getFormData(), this.model);
      result.UpdatedDate = new Date();
      result.UpdatedBy = "ayodia";
      console.log(`result value: ${JSON.stringify(result)}`);
      this.masterDataEditorService.UpdateObject({ dbSetName: (this.getTableName() as string), key: Object.values(this.model)[0] as string, jsonValue: JSON.stringify(result) }).
        subscribe(res => {
          this.notificationService.saveCompleted();
          this.ref.close(this.model);
        }),
        (err) => {
          const errMsg = err.error?.message || err.message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        }
    }

  }

  createJsonBody() {
    let jsonData = {};
    this.propertyModal.forEach((column) => {
      var columnName = this.camelize(column.name);
      switch (column.typeName) {
        case "Int32":
          jsonData[columnName] = 0;
          break;
        case "String":
          jsonData[columnName] = "";
          break;
        case "DateTime":
          jsonData[columnName] = new Date();
          break;
        default:
          jsonData[columnName] = null;
      }
    });
    return jsonData;
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }


}
