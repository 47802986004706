/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class SettingsService extends __BaseService {
  static readonly SetWebLogoLargeImagePath = '/api/Settings/web-logo-large-image';
  static readonly SetWebLogoSmallImagePath = '/api/Settings/web-logo-small-image';
  static readonly SetWebBackgroundLargeImage1Path = '/api/Settings/web-background-large-image1';
  static readonly SetWebLoginLogoImagePath = '/api/Settings/web-login-logo-image';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param file undefined
   * @return Success
   */
  SetWebLogoLargeImageResponse(file?: Blob): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Settings/web-logo-large-image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  SetWebLogoLargeImage(file?: Blob): __Observable<string> {
    return this.SetWebLogoLargeImageResponse(file).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  SetWebLogoSmallImageResponse(file?: Blob): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Settings/web-logo-small-image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  SetWebLogoSmallImage(file?: Blob): __Observable<string> {
    return this.SetWebLogoSmallImageResponse(file).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  SetWebBackgroundLargeImage1Response(file?: Blob): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Settings/web-background-large-image1`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  SetWebBackgroundLargeImage1(file?: Blob): __Observable<string> {
    return this.SetWebBackgroundLargeImage1Response(file).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  SetWebLoginLogoImageResponse(file?: Blob): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Settings/web-login-logo-image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  SetWebLoginLogoImage(file?: Blob): __Observable<string> {
    return this.SetWebLoginLogoImageResponse(file).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module SettingsService {
}

export { SettingsService }
