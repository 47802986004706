import { Component } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-formly-ag-grid-cell',
  templateUrl: './formly-ag-grid-cell.component.html',
  styleUrls: ['./formly-ag-grid-cell.component.css']
})
export class FormlyAgGridCellComponent implements ICellRendererAngularComp {
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  getField(): FormlyFieldConfig {
    const rowIndex = this.params.rowIndex;
    const prop = this.params.colDef.field;
    const fg =  this.params.context.parentField.fieldGroup;

    return fg[rowIndex].fieldGroup.find(f => f.key === prop);
  }
}
