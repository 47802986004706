import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeInterestModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeInterestService, MasterDataService } from 'app/api/services';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeInterestModel;
}

@Component({
  selector: 'app-modal-employee-interest',
  templateUrl: './modal-employee-interest.component.html',
  styleUrls: ['./modal-employee-interest.component.css'],
})
export class ModalEmployeeInterestComponent implements OnInit, OnDestroy {
  model: EmployeeInterestModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'customTypeName',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'type',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 128,
      },
    },
    // {
    //   key: 'frequency',
    //   type: 'primeng-input',
    //   wrappers: ['inline-label'],
    //   templateOptions: {
    //     attributes: {
    //       style: 'width: 100%',
    //     },
    //     label: 'ความถี่',
    //     placeholder: 'ความถี่',
    //     required: true,
    //     labelClass: 'col-4',
    //     inputClass: 'col-3',
    //     type: 'number',
    //   },
    // },
    {
      key: 'proficiency',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'aptitude_level',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: [
          { value: 1, label: 'ทำได้เล็กน้อย' },
          { value: 2, label: 'ทำได้แต่ยังไม่คล่อง' },
          { value: 3, label: 'ทำได้ดี' },
          { value: 4, label: 'ทำได้ดีมาก' },
          // { value: 5, label: 'ทำได้ดีเหนือกว่าคนทั่วไป' },
        ],
      },
    },
    {
      key: 'comment',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'node',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeInterestService: EmployeeInterestService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const employeeInterestId = this.getEmployeeInterestId();

    if (employeeInterestId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    this.model = await this.employeeInterestService.GetEmployeeInterestById(this.getEmployeeInterestId()).toPromise();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.employeeInterestService
          .CreateEmployeeInterest(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.employeeInterestService
          .UpdateEmployeeInterest(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getEmployeeInterestId() {
    return this.config.data?.id;
  }
}
