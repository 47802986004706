/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { JobGradeModelPaginationResult } from '../models/job-grade-model-pagination-result';
import { JobGradeModel } from '../models/job-grade-model';
@Injectable({
  providedIn: 'root',
})
class JobGradeService extends __BaseService {
  static readonly GetJobGradePath = '/api/JobGrade';
  static readonly CreateJobGradePath = '/api/JobGrade';
  static readonly GetJobGradeIdPath = '/api/JobGrade/{jobGradeId}';
  static readonly UpdateJobGradePath = '/api/JobGrade/{jobGradeId}';
  static readonly DeleteJobGradePath = '/api/JobGrade/{jobGradeId}';
  static readonly CopyJobGradeOfSystemToJobGradePath = '/api/JobGrade/{companyid}/CopyJobGradeOfSystemToJobGrade';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `JobGradeService.GetJobGradeParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetJobGradeResponse(params: JobGradeService.GetJobGradeParams): __Observable<__StrictHttpResponse<JobGradeModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/JobGrade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobGradeModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `JobGradeService.GetJobGradeParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetJobGrade(params: JobGradeService.GetJobGradeParams): __Observable<JobGradeModelPaginationResult> {
    return this.GetJobGradeResponse(params).pipe(
      __map(_r => _r.body as JobGradeModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateJobGradeResponse(body?: JobGradeModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/JobGrade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateJobGrade(body?: JobGradeModel): __Observable<number> {
    return this.CreateJobGradeResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param jobGradeId undefined
   * @return Success
   */
  GetJobGradeIdResponse(jobGradeId: number): __Observable<__StrictHttpResponse<JobGradeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/JobGrade/${encodeURIComponent(String(jobGradeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobGradeModel>;
      })
    );
  }
  /**
   * @param jobGradeId undefined
   * @return Success
   */
  GetJobGradeId(jobGradeId: number): __Observable<JobGradeModel> {
    return this.GetJobGradeIdResponse(jobGradeId).pipe(
      __map(_r => _r.body as JobGradeModel)
    );
  }

  /**
   * @param params The `JobGradeService.UpdateJobGradeParams` containing the following parameters:
   *
   * - `jobGradeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateJobGradeResponse(params: JobGradeService.UpdateJobGradeParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/JobGrade/${encodeURIComponent(String(params.jobGradeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `JobGradeService.UpdateJobGradeParams` containing the following parameters:
   *
   * - `jobGradeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateJobGrade(params: JobGradeService.UpdateJobGradeParams): __Observable<string> {
    return this.UpdateJobGradeResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param jobGradeId undefined
   * @return Success
   */
  DeleteJobGradeResponse(jobGradeId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/JobGrade/${encodeURIComponent(String(jobGradeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param jobGradeId undefined
   * @return Success
   */
  DeleteJobGrade(jobGradeId: number): __Observable<string> {
    return this.DeleteJobGradeResponse(jobGradeId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param companyid undefined
   * @return Success
   */
  CopyJobGradeOfSystemToJobGradeResponse(companyid: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/JobGrade/${encodeURIComponent(String(companyid))}/CopyJobGradeOfSystemToJobGrade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param companyid undefined
   * @return Success
   */
  CopyJobGradeOfSystemToJobGrade(companyid: number): __Observable<string> {
    return this.CopyJobGradeOfSystemToJobGradeResponse(companyid).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module JobGradeService {

  /**
   * Parameters for GetJobGrade
   */
  export interface GetJobGradeParams {
    companyId?: number;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for UpdateJobGrade
   */
  export interface UpdateJobGradeParams {
    jobGradeId: number;
    body?: JobGradeModel;
  }
}

export { JobGradeService }
