/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RequestModelPaginationResult } from '../models/request-model-pagination-result';
import { RequestModel } from '../models/request-model';
import { WorkflowStepActionInfo } from '../models/workflow-step-action-info';
import { LeaveRequestModel } from '../models/leave-request-model';
import { OTRequestModel } from '../models/otrequest-model';
import { AttendanceCertifyRequestModel } from '../models/attendance-certify-request-model';
import { EmployeeWarningLetterModel } from '../models/employee-warning-letter-model';
import { EmployeePromoteRequestModel } from '../models/employee-promote-request-model';
@Injectable({
  providedIn: 'root',
})
class RequestService extends __BaseService {
  static readonly GetMyRequestListPath = '/api/Request/myrequest';
  static readonly GetRequestByIdPath = '/api/Request/{requestId}';
  static readonly GetStepActionPath = '/api/Request/{requestId}/step-actions';
  static readonly GetLeaveRequestPath = '/api/Request/{requestId}/leave';
  static readonly GetOTRequestPath = '/api/Request/{requestId}/ot';
  static readonly GetAttendanceCertifyRequestPath = '/api/Request/{requestId}/attendancecertify';
  static readonly GetWarningLetterRequestPath = '/api/Request/{requestId}/warningletter';
  static readonly GetPromoteRequestPath = '/api/Request/{requestId}/promoterequest';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RequestService.GetMyRequestListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestTypeId`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetMyRequestListResponse(params: RequestService.GetMyRequestListParams): __Observable<__StrictHttpResponse<RequestModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RequestTypeId != null) __params = __params.set('RequestTypeId', params.RequestTypeId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Request/myrequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RequestModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `RequestService.GetMyRequestListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestTypeId`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetMyRequestList(params: RequestService.GetMyRequestListParams): __Observable<RequestModelPaginationResult> {
    return this.GetMyRequestListResponse(params).pipe(
      __map(_r => _r.body as RequestModelPaginationResult)
    );
  }

  /**
   * @param requestId undefined
   * @return Success
   */
  GetRequestByIdResponse(requestId: number): __Observable<__StrictHttpResponse<RequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Request/${encodeURIComponent(String(requestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RequestModel>;
      })
    );
  }
  /**
   * @param requestId undefined
   * @return Success
   */
  GetRequestById(requestId: number): __Observable<RequestModel> {
    return this.GetRequestByIdResponse(requestId).pipe(
      __map(_r => _r.body as RequestModel)
    );
  }

  /**
   * @param requestId undefined
   * @return Success
   */
  GetStepActionResponse(requestId: number): __Observable<__StrictHttpResponse<Array<WorkflowStepActionInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Request/${encodeURIComponent(String(requestId))}/step-actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkflowStepActionInfo>>;
      })
    );
  }
  /**
   * @param requestId undefined
   * @return Success
   */
  GetStepAction(requestId: number): __Observable<Array<WorkflowStepActionInfo>> {
    return this.GetStepActionResponse(requestId).pipe(
      __map(_r => _r.body as Array<WorkflowStepActionInfo>)
    );
  }

  /**
   * @param requestId undefined
   * @return Success
   */
  GetLeaveRequestResponse(requestId: number): __Observable<__StrictHttpResponse<LeaveRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Request/${encodeURIComponent(String(requestId))}/leave`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LeaveRequestModel>;
      })
    );
  }
  /**
   * @param requestId undefined
   * @return Success
   */
  GetLeaveRequest(requestId: number): __Observable<LeaveRequestModel> {
    return this.GetLeaveRequestResponse(requestId).pipe(
      __map(_r => _r.body as LeaveRequestModel)
    );
  }

  /**
   * @param requestId undefined
   * @return Success
   */
  GetOTRequestResponse(requestId: number): __Observable<__StrictHttpResponse<OTRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Request/${encodeURIComponent(String(requestId))}/ot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTRequestModel>;
      })
    );
  }
  /**
   * @param requestId undefined
   * @return Success
   */
  GetOTRequest(requestId: number): __Observable<OTRequestModel> {
    return this.GetOTRequestResponse(requestId).pipe(
      __map(_r => _r.body as OTRequestModel)
    );
  }

  /**
   * @param requestId undefined
   * @return Success
   */
  GetAttendanceCertifyRequestResponse(requestId: number): __Observable<__StrictHttpResponse<AttendanceCertifyRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Request/${encodeURIComponent(String(requestId))}/attendancecertify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttendanceCertifyRequestModel>;
      })
    );
  }
  /**
   * @param requestId undefined
   * @return Success
   */
  GetAttendanceCertifyRequest(requestId: number): __Observable<AttendanceCertifyRequestModel> {
    return this.GetAttendanceCertifyRequestResponse(requestId).pipe(
      __map(_r => _r.body as AttendanceCertifyRequestModel)
    );
  }

  /**
   * @param requestId undefined
   * @return Success
   */
  GetWarningLetterRequestResponse(requestId: number): __Observable<__StrictHttpResponse<EmployeeWarningLetterModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Request/${encodeURIComponent(String(requestId))}/warningletter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeWarningLetterModel>;
      })
    );
  }
  /**
   * @param requestId undefined
   * @return Success
   */
  GetWarningLetterRequest(requestId: number): __Observable<EmployeeWarningLetterModel> {
    return this.GetWarningLetterRequestResponse(requestId).pipe(
      __map(_r => _r.body as EmployeeWarningLetterModel)
    );
  }

  /**
   * @param requestId undefined
   * @return Success
   */
  GetPromoteRequestResponse(requestId: number): __Observable<__StrictHttpResponse<EmployeePromoteRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Request/${encodeURIComponent(String(requestId))}/promoterequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePromoteRequestModel>;
      })
    );
  }
  /**
   * @param requestId undefined
   * @return Success
   */
  GetPromoteRequest(requestId: number): __Observable<EmployeePromoteRequestModel> {
    return this.GetPromoteRequestResponse(requestId).pipe(
      __map(_r => _r.body as EmployeePromoteRequestModel)
    );
  }
}

module RequestService {

  /**
   * Parameters for GetMyRequestList
   */
  export interface GetMyRequestListParams {
    Sorting?: string;
    SearchText?: string;
    RequestTypeId?: any;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { RequestService }
