/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class BackgroundJobService extends __BaseService {
  static readonly CalculateETLTablePath = '/api/BackgroundJob/run/CalculateETLTable';
  static readonly CalculateETLUnitTablePath = '/api/BackgroundJob/run/CalculateETLUnitTable';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BackgroundJobService.CalculateETLTableParams` containing the following parameters:
   *
   * - `toDate`:
   *
   * - `fromDate`:
   *
   * @return Success
   */
  CalculateETLTableResponse(params: BackgroundJobService.CalculateETLTableParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toDate != null) __params = __params.set('toDate', params.toDate.toString());
    if (params.fromDate != null) __params = __params.set('fromDate', params.fromDate.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BackgroundJob/run/CalculateETLTable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `BackgroundJobService.CalculateETLTableParams` containing the following parameters:
   *
   * - `toDate`:
   *
   * - `fromDate`:
   *
   * @return Success
   */
  CalculateETLTable(params: BackgroundJobService.CalculateETLTableParams): __Observable<string> {
    return this.CalculateETLTableResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `BackgroundJobService.CalculateETLUnitTableParams` containing the following parameters:
   *
   * - `isFullMonth`:
   *
   * - `date`:
   *
   * @return Success
   */
  CalculateETLUnitTableResponse(params: BackgroundJobService.CalculateETLUnitTableParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isFullMonth != null) __params = __params.set('isFullMonth', params.isFullMonth.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BackgroundJob/run/CalculateETLUnitTable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `BackgroundJobService.CalculateETLUnitTableParams` containing the following parameters:
   *
   * - `isFullMonth`:
   *
   * - `date`:
   *
   * @return Success
   */
  CalculateETLUnitTable(params: BackgroundJobService.CalculateETLUnitTableParams): __Observable<string> {
    return this.CalculateETLUnitTableResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module BackgroundJobService {

  /**
   * Parameters for CalculateETLTable
   */
  export interface CalculateETLTableParams {
    toDate?: string;
    fromDate?: string;
  }

  /**
   * Parameters for CalculateETLUnitTable
   */
  export interface CalculateETLUnitTableParams {
    isFullMonth?: boolean;
    date?: string;
  }
}

export { BackgroundJobService }
