<div class="layout-breadcrumb no-print non-desktop-hide">
  <div class="breadcrumb m-0">
    <div class="route-bar-breadcrumb">
      <ng-template ngFor let-item let-last="last" [ngForOf]="items">
        <li>
          <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{ item.label }}</a>
          <ng-container *ngIf="!item.routerLink">{{ item.label }}</ng-container>
        </li>
        <li *ngIf="!last"><i class="pi pi-angle-right"></i></li>
      </ng-template>
    </div>
  </div>

  <!-- <div class="notification">👋 Bonjour Olivia, there is no notification today for you</div> -->
</div>
