<div #blockTarget class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <form [formGroup]="form" *ngIf="form">
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      </form>
    </div>
  </div>
</div>
<div class="p-dialog-footer">
  <button type="button" pButton class="ui-button-danger" label="ยกเลิกคำขอลา" (click)="onCancelClicked()"></button>
  <button type="button" pButton class="ui-button-secondary" label="ปิด" (click)="close(false)"></button>
</div>
