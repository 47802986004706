import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmployeeTrainingListItemModel } from 'app/api/models';
import { EmployeeTrainingService, EmployeeService } from 'app/api/services';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { ModalEmployeeTrainingComponent } from 'modals/core/modal-employee-training/modal-employee-training.component';


@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  isEditable = false;
  trainingList: EmployeeTrainingListItemModel[];
  ref: DynamicDialogRef;

  constructor(
    private layout: LayoutDefaultComponent,
    private router: Router,
    private notificationService: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeTrainingService: EmployeeTrainingService,
    private employeeService: EmployeeService,
  ) {}

  ngOnInit() {
    this.setEditablePermission();
    this.settrainingList();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  settrainingList() {
    const employeeId = this.getEmployeeId();
    console.log(`call training list for employee id: ${employeeId}`);
    this.employeeService.GetTrainings(employeeId).subscribe(training => {
      this.trainingList = training;
    });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');
    return +urls[urls.length - 2];
  }

  showCreateTrainingDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่ม training', ModalEmployeeTrainingComponent);
    this.ref.onClose.subscribe(data => {
      if (data) {
        this.settrainingList();
      }
    });
  }

  showUpdateTrainingDialog(trainingId: number) {
    this.showDialog({ id: trainingId }, 'แก้ไข training', ModalEmployeeTrainingComponent);
    this.ref.onClose.subscribe(data => {
      if (data) {
        this.settrainingList();
      }
    });
  }

  showDeleteTrainingDialog(trainingId: number) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeeTrainingService.DeleteEmployeeTraining(trainingId).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.settrainingList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  getYearFromDateISOString(ISODate: string) {
    return new Date(ISODate).getFullYear();
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }

}
