/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeSurgeryModel } from '../models/employee-surgery-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeSurgeryService extends __BaseService {
  static readonly GetEmployeeSurgeryListPath = '/api/EmployeeSurgery';
  static readonly CreateEmployeeSurgeryPath = '/api/EmployeeSurgery';
  static readonly GetEmployeeSurgeryPath = '/api/EmployeeSurgery/{EmployeeSurgeryId}';
  static readonly UpdateEmployeeSurgeryPath = '/api/EmployeeSurgery/{EmployeeSurgeryId}';
  static readonly DeleteEmployeeSurgeryPath = '/api/EmployeeSurgery/{EmployeeSurgeryId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeSurgeryListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeSurgeryModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeSurgery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeSurgeryModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeSurgeryList(): __Observable<Array<EmployeeSurgeryModel>> {
    return this.GetEmployeeSurgeryListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeSurgeryModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeSurgeryResponse(body?: EmployeeSurgeryModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeSurgery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeSurgery(body?: EmployeeSurgeryModel): __Observable<string> {
    return this.CreateEmployeeSurgeryResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeSurgeryId undefined
   * @return Success
   */
  GetEmployeeSurgeryResponse(EmployeeSurgeryId: number): __Observable<__StrictHttpResponse<EmployeeSurgeryModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeSurgery/${encodeURIComponent(String(EmployeeSurgeryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeSurgeryModel>;
      })
    );
  }
  /**
   * @param EmployeeSurgeryId undefined
   * @return Success
   */
  GetEmployeeSurgery(EmployeeSurgeryId: number): __Observable<EmployeeSurgeryModel> {
    return this.GetEmployeeSurgeryResponse(EmployeeSurgeryId).pipe(
      __map(_r => _r.body as EmployeeSurgeryModel)
    );
  }

  /**
   * @param params The `EmployeeSurgeryService.UpdateEmployeeSurgeryParams` containing the following parameters:
   *
   * - `EmployeeSurgeryId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeSurgeryResponse(params: EmployeeSurgeryService.UpdateEmployeeSurgeryParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeSurgery/${encodeURIComponent(String(params.EmployeeSurgeryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeSurgeryService.UpdateEmployeeSurgeryParams` containing the following parameters:
   *
   * - `EmployeeSurgeryId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeSurgery(params: EmployeeSurgeryService.UpdateEmployeeSurgeryParams): __Observable<string> {
    return this.UpdateEmployeeSurgeryResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeSurgeryId undefined
   * @return Success
   */
  DeleteEmployeeSurgeryResponse(EmployeeSurgeryId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeSurgery/${encodeURIComponent(String(EmployeeSurgeryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeSurgeryId undefined
   * @return Success
   */
  DeleteEmployeeSurgery(EmployeeSurgeryId: number): __Observable<string> {
    return this.DeleteEmployeeSurgeryResponse(EmployeeSurgeryId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeSurgeryService {

  /**
   * Parameters for UpdateEmployeeSurgery
   */
  export interface UpdateEmployeeSurgeryParams {
    EmployeeSurgeryId: number;
    body?: EmployeeSurgeryModel;
  }
}

export { EmployeeSurgeryService }
