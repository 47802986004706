<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>ไฟล์แนบ</h1>
        <hr />
        <div class="card">
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>ไฟล์แนบ</h1>
            </div>
            <div class="col"></div>
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                label="เพิ่มข้อมูล"
                (click)="showCreateFileDialog()"
              ></button>
            </div>
          </div>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="fileList">
            <ng-template pTemplate="header">
              <tr>
                <th>ไฟล์เอกสาร</th>
                <th>ไฟล์แนบ</th>
                <th>รายละเอียด</th>
                <th *ngIf="isEditable" style="width: 100px">Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.name }}</td>
                <td>
                  <span *ngIf="!rowData.documentId">N/A</span>
                  <a *ngIf="rowData.documentId" (click)="downloadFile(rowData.documentId, rowData.name)">download</a>
                </td>
                <td>{{ rowData.description }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i class="fa fa-pencil-alt" (click)="editFile(rowData.documentId)"></i>
                    </div>
                    <div class="col-fixed">
                      <i class="fa fa-trash" (click)="removeFile(rowData.documentId)"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="4" class="emptymessage">ไม่พบข้อมูล</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
