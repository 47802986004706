/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeDeptResponseModel } from '../models/employee-dept-response-model';
import { CashAdvanceModelPaginationResult } from '../models/cash-advance-model-pagination-result';
import { TimesheetsResponseModel } from '../models/timesheets-response-model';
@Injectable({
  providedIn: 'root',
})
class StarkMigrationService extends __BaseService {
  static readonly ImportOrganizationPath = '/api/StarkMigration/importorg';
  static readonly ImportEmployeePath = '/api/StarkMigration/importemployee';
  static readonly ClearUnusedUnitPath = '/api/StarkMigration/clearunusedunit';
  static readonly ImportAdminUserPath = '/api/StarkMigration/importadminuser';
  static readonly PrepareTestSystemPath = '/api/StarkMigration/preparetestsystem';
  static readonly GetEmployeeDebtPath = '/api/StarkMigration/GetEmployeeDebt';
  static readonly GetCashAdvancesPath = '/api/StarkMigration/CashAdvances';
  static readonly GetResPlanningTimeSheetPath = '/api/StarkMigration/PlanningTimeSheets/{workNo}';
  static readonly ExportOrganizationStructurePath = '/api/StarkMigration/orgfile';
  static readonly ExportEmployeeUpdatePath = '/api/StarkMigration/employeeupdatefile';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `StarkMigrationService.ImportOrganizationParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `file`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  ImportOrganizationResponse(params: StarkMigrationService.ImportOrganizationParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.secret != null) __params = __params.set('secret', params.secret.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkMigration/importorg`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `StarkMigrationService.ImportOrganizationParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `file`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  ImportOrganization(params: StarkMigrationService.ImportOrganizationParams): __Observable<string> {
    return this.ImportOrganizationResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `StarkMigrationService.ImportEmployeeParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `file`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  ImportEmployeeResponse(params: StarkMigrationService.ImportEmployeeParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.secret != null) __params = __params.set('secret', params.secret.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkMigration/importemployee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `StarkMigrationService.ImportEmployeeParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `file`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  ImportEmployee(params: StarkMigrationService.ImportEmployeeParams): __Observable<string> {
    return this.ImportEmployeeResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `StarkMigrationService.ClearUnusedUnitParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  ClearUnusedUnitResponse(params: StarkMigrationService.ClearUnusedUnitParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.secret != null) __params = __params.set('secret', params.secret.toString());
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkMigration/clearunusedunit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `StarkMigrationService.ClearUnusedUnitParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  ClearUnusedUnit(params: StarkMigrationService.ClearUnusedUnitParams): __Observable<string> {
    return this.ClearUnusedUnitResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `StarkMigrationService.ImportAdminUserParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `file`:
   *
   * @return Success
   */
  ImportAdminUserResponse(params: StarkMigrationService.ImportAdminUserParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.secret != null) __params = __params.set('secret', params.secret.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkMigration/importadminuser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `StarkMigrationService.ImportAdminUserParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `file`:
   *
   * @return Success
   */
  ImportAdminUser(params: StarkMigrationService.ImportAdminUserParams): __Observable<string> {
    return this.ImportAdminUserResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `StarkMigrationService.PrepareTestSystemParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `mode`:
   *
   * @return Success
   */
  PrepareTestSystemResponse(params: StarkMigrationService.PrepareTestSystemParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.secret != null) __params = __params.set('secret', params.secret.toString());
    if (params.mode != null) __params = __params.set('mode', params.mode.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkMigration/preparetestsystem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `StarkMigrationService.PrepareTestSystemParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `mode`:
   *
   * @return Success
   */
  PrepareTestSystem(params: StarkMigrationService.PrepareTestSystemParams): __Observable<string> {
    return this.PrepareTestSystemResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeDebtResponse(employeeId?: string): __Observable<__StrictHttpResponse<EmployeeDeptResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (employeeId != null) __params = __params.set('employeeId', employeeId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkMigration/GetEmployeeDebt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeDeptResponseModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeDebt(employeeId?: string): __Observable<EmployeeDeptResponseModel> {
    return this.GetEmployeeDebtResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeDeptResponseModel)
    );
  }

  /**
   * @param params The `StarkMigrationService.GetCashAdvancesParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeId`:
   *
   * @return Success
   */
  GetCashAdvancesResponse(params: StarkMigrationService.GetCashAdvancesParams): __Observable<__StrictHttpResponse<CashAdvanceModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/StarkMigration/CashAdvances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CashAdvanceModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `StarkMigrationService.GetCashAdvancesParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeId`:
   *
   * @return Success
   */
  GetCashAdvances(params: StarkMigrationService.GetCashAdvancesParams): __Observable<CashAdvanceModelPaginationResult> {
    return this.GetCashAdvancesResponse(params).pipe(
      __map(_r => _r.body as CashAdvanceModelPaginationResult)
    );
  }

  /**
   * @param workNo undefined
   * @return Success
   */
  GetResPlanningTimeSheetResponse(workNo: string): __Observable<__StrictHttpResponse<TimesheetsResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/StarkMigration/PlanningTimeSheets/${encodeURIComponent(String(workNo))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TimesheetsResponseModel>;
      })
    );
  }
  /**
   * @param workNo undefined
   * @return Success
   */
  GetResPlanningTimeSheet(workNo: string): __Observable<TimesheetsResponseModel> {
    return this.GetResPlanningTimeSheetResponse(workNo).pipe(
      __map(_r => _r.body as TimesheetsResponseModel)
    );
  }

  /**
   * @return Success
   */
  ExportOrganizationStructureResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkMigration/orgfile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  ExportOrganizationStructure(): __Observable<string> {
    return this.ExportOrganizationStructureResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  ExportEmployeeUpdateResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkMigration/employeeupdatefile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  ExportEmployeeUpdate(): __Observable<string> {
    return this.ExportEmployeeUpdateResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module StarkMigrationService {

  /**
   * Parameters for ImportOrganization
   */
  export interface ImportOrganizationParams {
    secret?: string;
    file?: Blob;
    companyId?: number;
  }

  /**
   * Parameters for ImportEmployee
   */
  export interface ImportEmployeeParams {
    secret?: string;
    file?: Blob;
    companyId?: number;
  }

  /**
   * Parameters for ClearUnusedUnit
   */
  export interface ClearUnusedUnitParams {
    secret?: string;
    companyId?: number;
  }

  /**
   * Parameters for ImportAdminUser
   */
  export interface ImportAdminUserParams {
    secret?: string;
    file?: Blob;
  }

  /**
   * Parameters for PrepareTestSystem
   */
  export interface PrepareTestSystemParams {
    secret?: string;
    mode?: number;
  }

  /**
   * Parameters for GetCashAdvances
   */
  export interface GetCashAdvancesParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    EmployeeId?: number;
  }
}

export { StarkMigrationService }
