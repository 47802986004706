import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DialogService } from 'primeng/dynamicdialog';
import { EmployeePvdModel } from '../../../api/models';
import { EmployeeService, EmployeePunishmentService } from '../../../api/services';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { MasterDataRepo } from '../../../repo/master-data.repo';
import { AppNotificationServiceService } from '../../../shared/app-notification-service.service';
import { CustomFormlyFieldConfig } from '../../../utils/formly-utils';

@Component({
  selector: 'app-pvd',
  templateUrl: './pvd.component.html',
  styleUrls: ['./pvd.component.scss']
})
export class PvdComponent implements OnInit {
  isEditable = false;
  model: EmployeePvdModel = null;
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig<keyof EmployeePvdModel>[] = [
    {
      key: 'pvdRegisterDate',
      type: 'calendar',
      className: 'col-6',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'วันที่เข้ากองทุน (วันที่-เดือน-ปี)',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      }
    },
    {
      key: 'pvdSavingPercent',
      type: 'primeng-input',
      className: 'col-6',
      defaultValue: 5,
      wrappers: ['inline-label'],
      templateOptions: {
        label: '% เงินสะสม',
        type: 'number',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-8',
      }
    }
  ];
  constructor(
    private router: Router,
    private notificationService: AppNotificationServiceService,
    private employeeService: EmployeeService,
  ) { }

  async ngOnInit() {
    this.isEditable = this.router.url.includes('admin', 1);
    this.model = await this.employeeService.GetPvdInfo(this.getEmployeeId()).toPromise();
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  async save() {
    await this.employeeService.UpdatePvdInfo({
      employeeId: this.getEmployeeId(),
      body: this.model
    }).toPromise();

    this.notificationService.saveCompleted();
  }

}
