/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EBenefitQuotaSubTypeEnumItemModel } from '../models/ebenefit-quota-sub-type-enum-item-model';
import { BenefitQuotaTypeModel } from '../models/benefit-quota-type-model';
import { BenefitElementModel } from '../models/benefit-element-model';
import { BenefitConditionTypeModel } from '../models/benefit-condition-type-model';
import { BenefitUseTypeModel } from '../models/benefit-use-type-model';
import { ActivityTypeModel } from '../models/activity-type-model';
import { ETaxCalculationTypeEnumItemModel } from '../models/etax-calculation-type-enum-item-model';
import { BusinessTypeModel } from '../models/business-type-model';
import { PayChannelModel } from '../models/pay-channel-model';
import { TitleModel } from '../models/title-model';
import { AddressTypeModel } from '../models/address-type-model';
import { BloodGroupModel } from '../models/blood-group-model';
import { CertificateTypeModel } from '../models/certificate-type-model';
import { ContactTypeModel } from '../models/contact-type-model';
import { DirectBossTypeModel } from '../models/direct-boss-type-model';
import { ESpouseIncomeTypeEnumItemModel } from '../models/espouse-income-type-enum-item-model';
import { EducationLevelModel } from '../models/education-level-model';
import { EmployeeCardTypeModel } from '../models/employee-card-type-model';
import { EmployeeTypeModel } from '../models/employee-type-model';
import { HealthMeasurementModel } from '../models/health-measurement-model';
import { InterestTypeModel } from '../models/interest-type-model';
import { MaritalStatusModel } from '../models/marital-status-model';
import { NationalityModel } from '../models/nationality-model';
import { PunishmentTypeModel } from '../models/punishment-type-model';
import { RaceModel } from '../models/race-model';
import { ReligionModel } from '../models/religion-model';
import { SkillTypeModel } from '../models/skill-type-model';
import { WagePayTypeModel } from '../models/wage-pay-type-model';
import { BankModel } from '../models/bank-model';
import { HospitalModel } from '../models/hospital-model';
import { LanguageModel } from '../models/language-model';
import { RelativeTypeModel } from '../models/relative-type-model';
import { MilitaryStatusModel } from '../models/military-status-model';
import { EmployeeWarningLetterStatusModel } from '../models/employee-warning-letter-status-model';
import { EmployeePromoteRequestStatusModel } from '../models/employee-promote-request-status-model';
import { PayTypeModel } from '../models/pay-type-model';
import { PenaltyTypeModel } from '../models/penalty-type-model';
import { RelativeInfoStatusModel } from '../models/relative-info-status-model';
import { BossPositionTypeModel } from '../models/boss-position-type-model';
import { ProbationEvaluationStatusModel } from '../models/probation-evaluation-status-model';
import { PositionTakingTypeModel } from '../models/position-taking-type-model';
import { EducationFieldListItemModel } from '../models/education-field-list-item-model';
import { WorkTypeItemListModel } from '../models/work-type-item-list-model';
import { AmphoeModel } from '../models/amphoe-model';
import { GenderModel } from '../models/gender-model';
import { ProvinceModel } from '../models/province-model';
import { TambonModel } from '../models/tambon-model';
import { RequestTypeModel } from '../models/request-type-model';
import { ListItemModel } from '../models/list-item-model';
import { WorkflowTypeModel } from '../models/workflow-type-model';
import { WorkflowStepActorTypeModel } from '../models/workflow-step-actor-type-model';
import { CompanyMasterListModel } from '../models/company-master-list-model';
import { CostCenterMasterListModel } from '../models/cost-center-master-list-model';
import { ListRelativeTypeModel } from '../models/list-relative-type-model';
import { CountryMasterListModel } from '../models/country-master-list-model';
import { PayElementTypeModel } from '../models/pay-element-type-model';
import { PayElementSubTypeModel } from '../models/pay-element-sub-type-model';
import { EEmployeePayrollProcessingStatusEnumItemModel } from '../models/eemployee-payroll-processing-status-enum-item-model';
import { EPayrollValidationStatusEnumItemModel } from '../models/epayroll-validation-status-enum-item-model';
import { PayAllowanceRoundModel } from '../models/pay-allowance-round-model';
import { PayAllowanceTypeModel } from '../models/pay-allowance-type-model';
import { ShiftsInAllowanceTypeModel } from '../models/shifts-in-allowance-type-model';
import { LeaveTypesInAllowanceTypeModel } from '../models/leave-types-in-allowance-type-model';
import { AttendanceCertifyStatusModel } from '../models/attendance-certify-status-model';
import { EnumItemModel } from '../models/enum-item-model';
import { AttendanceCertifyTypeModel } from '../models/attendance-certify-type-model';
import { LeaveStatusModel } from '../models/leave-status-model';
import { OTTypeModel } from '../models/ottype-model';
import { OTStatusModel } from '../models/otstatus-model';
import { WorkDayTypeModel } from '../models/work-day-type-model';
import { TimeAttendanceStatusModel } from '../models/time-attendance-status-model';
import { HolidayTypeModel } from '../models/holiday-type-model';
import { ShiftDefinitionTypeModel } from '../models/shift-definition-type-model';
import { ShiftsInHolidayTypeModel } from '../models/shifts-in-holiday-type-model';
@Injectable({
  providedIn: 'root',
})
class MasterDataService extends __BaseService {
  static readonly GetBenefitQuotaSubTypesPath = '/api/MasterData/benefit-quota-sub-types';
  static readonly GetBenefitQuotaTypesPath = '/api/MasterData/benefit-quota-types';
  static readonly GetLeaveTypesPath = '/api/MasterData/leave-types';
  static readonly GetMyLeaveTypesPath = '/api/MasterData/my-leave-types';
  static readonly GetBenefitConditionTypesPath = '/api/MasterData/benefit-condition-types';
  static readonly GetBenefitUseTypesPath = '/api/MasterData/benefit-use-types';
  static readonly GetActivityTypesPath = '/api/MasterData/activitytypes';
  static readonly GetTaxCalculationTypesPath = '/api/MasterData/tax-calculation-types';
  static readonly GetBusinesstypesPath = '/api/MasterData/businesstypes';
  static readonly GetPayChannelsPath = '/api/MasterData/paychannel';
  static readonly GetTitlesPath = '/api/MasterData/titles';
  static readonly GetAddressTypesPath = '/api/MasterData/addresstypes';
  static readonly GetBloodGroupsPath = '/api/MasterData/bloodgroups';
  static readonly GetCertificateTypesPath = '/api/MasterData/certificatetypes';
  static readonly GetContactTypesPath = '/api/MasterData/contacttypes';
  static readonly GetDirectBossTypesPath = '/api/MasterData/directbosstypes';
  static readonly GetSpouseIncomeTypesPath = '/api/MasterData/spouse-income-types';
  static readonly GetEducationLevelsPath = '/api/MasterData/educationlevels';
  static readonly GetEmployeeCardTypesPath = '/api/MasterData/employeecardtypes';
  static readonly GetEmployeeTypesPath = '/api/MasterData/employeetypes';
  static readonly GetEmployeeTypes2Path = '/api/MasterData/employeetypes2';
  static readonly GetHealthMeasurementsPath = '/api/MasterData/healthmeasurements';
  static readonly GetInterestTypesPath = '/api/MasterData/intersettypes';
  static readonly GetMaritalStatusesPath = '/api/MasterData/maritalstatuses';
  static readonly GetNationalitiesPath = '/api/MasterData/nationalities';
  static readonly GetPunishmentTypesPath = '/api/MasterData/punishmenttypes';
  static readonly GetRacesPath = '/api/MasterData/races';
  static readonly GetReligionsPath = '/api/MasterData/religions';
  static readonly GetSkillTypesPath = '/api/MasterData/skilltypes';
  static readonly GetWagePayTypesPath = '/api/MasterData/wagePayType';
  static readonly GetBanksPath = '/api/MasterData/banks';
  static readonly GetHospitalsPath = '/api/MasterData/hospital';
  static readonly GetLanguagesPath = '/api/MasterData/languages';
  static readonly GetRelativeTypesPath = '/api/MasterData/relativetypes';
  static readonly GetMilitaryStatusesPath = '/api/MasterData/militarystatus';
  static readonly GetEmployeeWarningLetterStatusesPath = '/api/MasterData/warningletterstatuses';
  static readonly GetEmployeePromoteRequestStatusesPath = '/api/MasterData/promoterequeststatuses';
  static readonly GetPayTypesPath = '/api/MasterData/payType';
  static readonly GetPenaltyTypesPath = '/api/MasterData/penaltyType';
  static readonly GetRelativeInfoStatusesPath = '/api/MasterData/relativeinfostatuses';
  static readonly GetBossPositionTypesPath = '/api/MasterData/bossPositionTypes';
  static readonly GetProbationEvaluationStatusesPath = '/api/MasterData/probationEvaluationStatuses';
  static readonly GetPositionTakingTypesPath = '/api/MasterData/positionTakingTypes';
  static readonly GetEducationFieldsPath = '/api/MasterData/educationfields';
  static readonly GetWorkTypesPath = '/api/MasterData/worktypes';
  static readonly GetAmphoesPath = '/api/MasterData/amphoes';
  static readonly GetGendersPath = '/api/MasterData/genders';
  static readonly GetProvincePath = '/api/MasterData/provinces';
  static readonly GetTambonsPath = '/api/MasterData/tambons';
  static readonly GetRequestTypesPath = '/api/MasterData/requesttypes';
  static readonly GetJobGradeLevelsPath = '/api/MasterData/jobgradelevels';
  static readonly GetAccessLogEventTypesPath = '/api/MasterData/accesslogeventtypes';
  static readonly GetWorkflowTypesPath = '/api/MasterData/workflowtypes';
  static readonly GetWorkflowStepActorTypesPath = '/api/MasterData/workflowstepactortypes';
  static readonly GetCompanysPath = '/api/MasterData/companies';
  static readonly GetCostCentersPath = '/api/MasterData/costcenters';
  static readonly GetListRelativeTypesPath = '/api/MasterData/listRelativeTypes';
  static readonly GetCountryPath = '/api/MasterData/countrys';
  static readonly GetPayElementTypesPath = '/api/MasterData/pay-element-types';
  static readonly GetPayElementSubTypesPath = '/api/MasterData/pay-element-sub-types';
  static readonly GetEmployeePayrollProcessingStatusesPath = '/api/MasterData/employee-payroll-processing-statuses';
  static readonly GetPayrollValidationStatusesPath = '/api/MasterData/payroll-validation-statuses';
  static readonly GetPayAllowanceRoundsPath = '/api/MasterData/pay-allowance-rounds';
  static readonly GetPayAllowanceTypesPath = '/api/MasterData/pay-allowance-types';
  static readonly GetShiftsInAllowanceTypesPath = '/api/MasterData/shifts-in-allowance-types';
  static readonly GetLeaveTypesInAllowanceTypesPath = '/api/MasterData/leave-type-in-allowance-types';
  static readonly GetAttendanceCertifyStatusesListPath = '/api/MasterData/attendanceCertifyStatuses';
  static readonly CreateAttendanceCertifyStatusPath = '/api/MasterData/attendanceCertifyStatuses';
  static readonly UpdateAttendanceCertifyStatusPath = '/api/MasterData/attendanceCertifyStatuses';
  static readonly GetLeaveTimeTypesPath = '/api/MasterData/leave-time-types';
  static readonly GetAttendanceCertifyStatusByIdPath = '/api/MasterData/attendanceCertifyStatuses/{attendanceCertifyStatusId}';
  static readonly DeleteAttendanceCertifyStatusPath = '/api/MasterData/attendanceCertifyStatuses/{attendanceCertifyStatusId}';
  static readonly GetAttendanceCertifyTypesListPath = '/api/MasterData/attendanceCertifyTypes';
  static readonly CreateAttendanceCertifyTypePath = '/api/MasterData/attendanceCertifyTypes';
  static readonly UpdateAttendanceCertifyTypePath = '/api/MasterData/attendanceCertifyTypes';
  static readonly GetAttendanceCertifyTypeByIdPath = '/api/MasterData/attendanceCertifyTypes/{attendanceCertifyTypeId}';
  static readonly DeleteAttendanceCertifyTypePath = '/api/MasterData/attendanceCertifyTypes/{attendanceCertifyTypeId}';
  static readonly GetLeaveStatusesListPath = '/api/MasterData/leaveStatuses';
  static readonly CreateLeaveStatusPath = '/api/MasterData/leaveStatuses';
  static readonly UpdateLeaveStatusPath = '/api/MasterData/leaveStatuses';
  static readonly GetLeaveStatusByIdPath = '/api/MasterData/leaveStatuses/{leaveStatusId}';
  static readonly DeleteLeaveStatusPath = '/api/MasterData/leaveStatuses/{leaveStatusId}';
  static readonly GetOTTypesListPath = '/api/MasterData/otTypes';
  static readonly CreateOTTypePath = '/api/MasterData/otTypes';
  static readonly UpdateOTTypePath = '/api/MasterData/otTypes';
  static readonly GetOTTypeByIdPath = '/api/MasterData/otTypes/{otTypeId}';
  static readonly DeleteOTTypePath = '/api/MasterData/otTypes/{otTypeId}';
  static readonly GetOTStatusesListPath = '/api/MasterData/otStatuses';
  static readonly CreateOTStatusPath = '/api/MasterData/otStatuses';
  static readonly UpdateOTStatusPath = '/api/MasterData/otStatuses';
  static readonly GetOTStatusByIdPath = '/api/MasterData/otStatuses/{otStatusId}';
  static readonly DeleteOTStatusPath = '/api/MasterData/otStatuses/{otStatusId}';
  static readonly GetWorkDayTypesPath = '/api/MasterData/workdaytypes';
  static readonly GetTimeAttendanceStatusPath = '/api/MasterData/timeattendancestatus';
  static readonly GetHolidayTypesPath = '/api/MasterData/holidaytypes';
  static readonly GetShiftDefinitionTypesPath = '/api/MasterData/ShiftDefinitionTypes';
  static readonly GetShiftsInHolidayTypesPath = '/api/MasterData/shiftsInHolidayTypes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param filterByQuota undefined
   * @return Success
   */
  GetBenefitQuotaSubTypesResponse(filterByQuota?: any): __Observable<__StrictHttpResponse<Array<EBenefitQuotaSubTypeEnumItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (filterByQuota != null) __params = __params.set('filterByQuota', filterByQuota.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/benefit-quota-sub-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EBenefitQuotaSubTypeEnumItemModel>>;
      })
    );
  }
  /**
   * @param filterByQuota undefined
   * @return Success
   */
  GetBenefitQuotaSubTypes(filterByQuota?: any): __Observable<Array<EBenefitQuotaSubTypeEnumItemModel>> {
    return this.GetBenefitQuotaSubTypesResponse(filterByQuota).pipe(
      __map(_r => _r.body as Array<EBenefitQuotaSubTypeEnumItemModel>)
    );
  }

  /**
   * @return Success
   */
  GetBenefitQuotaTypesResponse(): __Observable<__StrictHttpResponse<Array<BenefitQuotaTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/benefit-quota-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BenefitQuotaTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetBenefitQuotaTypes(): __Observable<Array<BenefitQuotaTypeModel>> {
    return this.GetBenefitQuotaTypesResponse().pipe(
      __map(_r => _r.body as Array<BenefitQuotaTypeModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetLeaveTypesResponse(employeeId?: number): __Observable<__StrictHttpResponse<Array<BenefitElementModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (employeeId != null) __params = __params.set('employeeId', employeeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/leave-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BenefitElementModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetLeaveTypes(employeeId?: number): __Observable<Array<BenefitElementModel>> {
    return this.GetLeaveTypesResponse(employeeId).pipe(
      __map(_r => _r.body as Array<BenefitElementModel>)
    );
  }

  /**
   * @return Success
   */
  GetMyLeaveTypesResponse(): __Observable<__StrictHttpResponse<Array<BenefitElementModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/my-leave-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BenefitElementModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetMyLeaveTypes(): __Observable<Array<BenefitElementModel>> {
    return this.GetMyLeaveTypesResponse().pipe(
      __map(_r => _r.body as Array<BenefitElementModel>)
    );
  }

  /**
   * @return Success
   */
  GetBenefitConditionTypesResponse(): __Observable<__StrictHttpResponse<Array<BenefitConditionTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/benefit-condition-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BenefitConditionTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetBenefitConditionTypes(): __Observable<Array<BenefitConditionTypeModel>> {
    return this.GetBenefitConditionTypesResponse().pipe(
      __map(_r => _r.body as Array<BenefitConditionTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetBenefitUseTypesResponse(): __Observable<__StrictHttpResponse<Array<BenefitUseTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/benefit-use-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BenefitUseTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetBenefitUseTypes(): __Observable<Array<BenefitUseTypeModel>> {
    return this.GetBenefitUseTypesResponse().pipe(
      __map(_r => _r.body as Array<BenefitUseTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetActivityTypesResponse(): __Observable<__StrictHttpResponse<Array<ActivityTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/activitytypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ActivityTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetActivityTypes(): __Observable<Array<ActivityTypeModel>> {
    return this.GetActivityTypesResponse().pipe(
      __map(_r => _r.body as Array<ActivityTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetTaxCalculationTypesResponse(): __Observable<__StrictHttpResponse<Array<ETaxCalculationTypeEnumItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/tax-calculation-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ETaxCalculationTypeEnumItemModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTaxCalculationTypes(): __Observable<Array<ETaxCalculationTypeEnumItemModel>> {
    return this.GetTaxCalculationTypesResponse().pipe(
      __map(_r => _r.body as Array<ETaxCalculationTypeEnumItemModel>)
    );
  }

  /**
   * @return Success
   */
  GetBusinesstypesResponse(): __Observable<__StrictHttpResponse<Array<BusinessTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/businesstypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BusinessTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetBusinesstypes(): __Observable<Array<BusinessTypeModel>> {
    return this.GetBusinesstypesResponse().pipe(
      __map(_r => _r.body as Array<BusinessTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetPayChannelsResponse(): __Observable<__StrictHttpResponse<Array<PayChannelModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/paychannel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PayChannelModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPayChannels(): __Observable<Array<PayChannelModel>> {
    return this.GetPayChannelsResponse().pipe(
      __map(_r => _r.body as Array<PayChannelModel>)
    );
  }

  /**
   * @return Success
   */
  GetTitlesResponse(): __Observable<__StrictHttpResponse<Array<TitleModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/titles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TitleModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTitles(): __Observable<Array<TitleModel>> {
    return this.GetTitlesResponse().pipe(
      __map(_r => _r.body as Array<TitleModel>)
    );
  }

  /**
   * @return Success
   */
  GetAddressTypesResponse(): __Observable<__StrictHttpResponse<Array<AddressTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/addresstypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AddressTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAddressTypes(): __Observable<Array<AddressTypeModel>> {
    return this.GetAddressTypesResponse().pipe(
      __map(_r => _r.body as Array<AddressTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetBloodGroupsResponse(): __Observable<__StrictHttpResponse<Array<BloodGroupModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/bloodgroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BloodGroupModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetBloodGroups(): __Observable<Array<BloodGroupModel>> {
    return this.GetBloodGroupsResponse().pipe(
      __map(_r => _r.body as Array<BloodGroupModel>)
    );
  }

  /**
   * @return Success
   */
  GetCertificateTypesResponse(): __Observable<__StrictHttpResponse<Array<CertificateTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/certificatetypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CertificateTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCertificateTypes(): __Observable<Array<CertificateTypeModel>> {
    return this.GetCertificateTypesResponse().pipe(
      __map(_r => _r.body as Array<CertificateTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetContactTypesResponse(): __Observable<__StrictHttpResponse<Array<ContactTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/contacttypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ContactTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetContactTypes(): __Observable<Array<ContactTypeModel>> {
    return this.GetContactTypesResponse().pipe(
      __map(_r => _r.body as Array<ContactTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetDirectBossTypesResponse(): __Observable<__StrictHttpResponse<Array<DirectBossTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/directbosstypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DirectBossTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetDirectBossTypes(): __Observable<Array<DirectBossTypeModel>> {
    return this.GetDirectBossTypesResponse().pipe(
      __map(_r => _r.body as Array<DirectBossTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetSpouseIncomeTypesResponse(): __Observable<__StrictHttpResponse<Array<ESpouseIncomeTypeEnumItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/spouse-income-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ESpouseIncomeTypeEnumItemModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetSpouseIncomeTypes(): __Observable<Array<ESpouseIncomeTypeEnumItemModel>> {
    return this.GetSpouseIncomeTypesResponse().pipe(
      __map(_r => _r.body as Array<ESpouseIncomeTypeEnumItemModel>)
    );
  }

  /**
   * @return Success
   */
  GetEducationLevelsResponse(): __Observable<__StrictHttpResponse<Array<EducationLevelModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/educationlevels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EducationLevelModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEducationLevels(): __Observable<Array<EducationLevelModel>> {
    return this.GetEducationLevelsResponse().pipe(
      __map(_r => _r.body as Array<EducationLevelModel>)
    );
  }

  /**
   * @return Success
   */
  GetEmployeeCardTypesResponse(): __Observable<__StrictHttpResponse<Array<EmployeeCardTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/employeecardtypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeCardTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeCardTypes(): __Observable<Array<EmployeeCardTypeModel>> {
    return this.GetEmployeeCardTypesResponse().pipe(
      __map(_r => _r.body as Array<EmployeeCardTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetEmployeeTypesResponse(): __Observable<__StrictHttpResponse<Array<EmployeeTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/employeetypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeTypes(): __Observable<Array<EmployeeTypeModel>> {
    return this.GetEmployeeTypesResponse().pipe(
      __map(_r => _r.body as Array<EmployeeTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetEmployeeTypes2Response(): __Observable<__StrictHttpResponse<Array<EmployeeTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/employeetypes2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeTypes2(): __Observable<Array<EmployeeTypeModel>> {
    return this.GetEmployeeTypes2Response().pipe(
      __map(_r => _r.body as Array<EmployeeTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetHealthMeasurementsResponse(): __Observable<__StrictHttpResponse<Array<HealthMeasurementModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/healthmeasurements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HealthMeasurementModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetHealthMeasurements(): __Observable<Array<HealthMeasurementModel>> {
    return this.GetHealthMeasurementsResponse().pipe(
      __map(_r => _r.body as Array<HealthMeasurementModel>)
    );
  }

  /**
   * @return Success
   */
  GetInterestTypesResponse(): __Observable<__StrictHttpResponse<Array<InterestTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/intersettypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InterestTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetInterestTypes(): __Observable<Array<InterestTypeModel>> {
    return this.GetInterestTypesResponse().pipe(
      __map(_r => _r.body as Array<InterestTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetMaritalStatusesResponse(): __Observable<__StrictHttpResponse<Array<MaritalStatusModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/maritalstatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MaritalStatusModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetMaritalStatuses(): __Observable<Array<MaritalStatusModel>> {
    return this.GetMaritalStatusesResponse().pipe(
      __map(_r => _r.body as Array<MaritalStatusModel>)
    );
  }

  /**
   * @return Success
   */
  GetNationalitiesResponse(): __Observable<__StrictHttpResponse<Array<NationalityModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/nationalities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NationalityModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetNationalities(): __Observable<Array<NationalityModel>> {
    return this.GetNationalitiesResponse().pipe(
      __map(_r => _r.body as Array<NationalityModel>)
    );
  }

  /**
   * @return Success
   */
  GetPunishmentTypesResponse(): __Observable<__StrictHttpResponse<Array<PunishmentTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/punishmenttypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PunishmentTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPunishmentTypes(): __Observable<Array<PunishmentTypeModel>> {
    return this.GetPunishmentTypesResponse().pipe(
      __map(_r => _r.body as Array<PunishmentTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetRacesResponse(): __Observable<__StrictHttpResponse<Array<RaceModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/races`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RaceModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRaces(): __Observable<Array<RaceModel>> {
    return this.GetRacesResponse().pipe(
      __map(_r => _r.body as Array<RaceModel>)
    );
  }

  /**
   * @return Success
   */
  GetReligionsResponse(): __Observable<__StrictHttpResponse<Array<ReligionModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/religions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReligionModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetReligions(): __Observable<Array<ReligionModel>> {
    return this.GetReligionsResponse().pipe(
      __map(_r => _r.body as Array<ReligionModel>)
    );
  }

  /**
   * @return Success
   */
  GetSkillTypesResponse(): __Observable<__StrictHttpResponse<Array<SkillTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/skilltypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SkillTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetSkillTypes(): __Observable<Array<SkillTypeModel>> {
    return this.GetSkillTypesResponse().pipe(
      __map(_r => _r.body as Array<SkillTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetWagePayTypesResponse(): __Observable<__StrictHttpResponse<Array<WagePayTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/wagePayType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WagePayTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetWagePayTypes(): __Observable<Array<WagePayTypeModel>> {
    return this.GetWagePayTypesResponse().pipe(
      __map(_r => _r.body as Array<WagePayTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetBanksResponse(): __Observable<__StrictHttpResponse<Array<BankModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/banks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BankModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetBanks(): __Observable<Array<BankModel>> {
    return this.GetBanksResponse().pipe(
      __map(_r => _r.body as Array<BankModel>)
    );
  }

  /**
   * @return Success
   */
  GetHospitalsResponse(): __Observable<__StrictHttpResponse<Array<HospitalModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/hospital`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HospitalModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetHospitals(): __Observable<Array<HospitalModel>> {
    return this.GetHospitalsResponse().pipe(
      __map(_r => _r.body as Array<HospitalModel>)
    );
  }

  /**
   * @return Success
   */
  GetLanguagesResponse(): __Observable<__StrictHttpResponse<Array<LanguageModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/languages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LanguageModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetLanguages(): __Observable<Array<LanguageModel>> {
    return this.GetLanguagesResponse().pipe(
      __map(_r => _r.body as Array<LanguageModel>)
    );
  }

  /**
   * @return Success
   */
  GetRelativeTypesResponse(): __Observable<__StrictHttpResponse<Array<RelativeTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/relativetypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RelativeTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRelativeTypes(): __Observable<Array<RelativeTypeModel>> {
    return this.GetRelativeTypesResponse().pipe(
      __map(_r => _r.body as Array<RelativeTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetMilitaryStatusesResponse(): __Observable<__StrictHttpResponse<Array<MilitaryStatusModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/militarystatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MilitaryStatusModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetMilitaryStatuses(): __Observable<Array<MilitaryStatusModel>> {
    return this.GetMilitaryStatusesResponse().pipe(
      __map(_r => _r.body as Array<MilitaryStatusModel>)
    );
  }

  /**
   * @return Success
   */
  GetEmployeeWarningLetterStatusesResponse(): __Observable<__StrictHttpResponse<Array<EmployeeWarningLetterStatusModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/warningletterstatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeWarningLetterStatusModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeWarningLetterStatuses(): __Observable<Array<EmployeeWarningLetterStatusModel>> {
    return this.GetEmployeeWarningLetterStatusesResponse().pipe(
      __map(_r => _r.body as Array<EmployeeWarningLetterStatusModel>)
    );
  }

  /**
   * @return Success
   */
  GetEmployeePromoteRequestStatusesResponse(): __Observable<__StrictHttpResponse<Array<EmployeePromoteRequestStatusModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/promoterequeststatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeePromoteRequestStatusModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeePromoteRequestStatuses(): __Observable<Array<EmployeePromoteRequestStatusModel>> {
    return this.GetEmployeePromoteRequestStatusesResponse().pipe(
      __map(_r => _r.body as Array<EmployeePromoteRequestStatusModel>)
    );
  }

  /**
   * @return Success
   */
  GetPayTypesResponse(): __Observable<__StrictHttpResponse<Array<PayTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/payType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PayTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPayTypes(): __Observable<Array<PayTypeModel>> {
    return this.GetPayTypesResponse().pipe(
      __map(_r => _r.body as Array<PayTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetPenaltyTypesResponse(): __Observable<__StrictHttpResponse<Array<PenaltyTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/penaltyType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PenaltyTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPenaltyTypes(): __Observable<Array<PenaltyTypeModel>> {
    return this.GetPenaltyTypesResponse().pipe(
      __map(_r => _r.body as Array<PenaltyTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetRelativeInfoStatusesResponse(): __Observable<__StrictHttpResponse<Array<RelativeInfoStatusModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/relativeinfostatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RelativeInfoStatusModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRelativeInfoStatuses(): __Observable<Array<RelativeInfoStatusModel>> {
    return this.GetRelativeInfoStatusesResponse().pipe(
      __map(_r => _r.body as Array<RelativeInfoStatusModel>)
    );
  }

  /**
   * @return Success
   */
  GetBossPositionTypesResponse(): __Observable<__StrictHttpResponse<Array<BossPositionTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/bossPositionTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BossPositionTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetBossPositionTypes(): __Observable<Array<BossPositionTypeModel>> {
    return this.GetBossPositionTypesResponse().pipe(
      __map(_r => _r.body as Array<BossPositionTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetProbationEvaluationStatusesResponse(): __Observable<__StrictHttpResponse<Array<ProbationEvaluationStatusModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/probationEvaluationStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProbationEvaluationStatusModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetProbationEvaluationStatuses(): __Observable<Array<ProbationEvaluationStatusModel>> {
    return this.GetProbationEvaluationStatusesResponse().pipe(
      __map(_r => _r.body as Array<ProbationEvaluationStatusModel>)
    );
  }

  /**
   * @return Success
   */
  GetPositionTakingTypesResponse(): __Observable<__StrictHttpResponse<Array<PositionTakingTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/positionTakingTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PositionTakingTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPositionTakingTypes(): __Observable<Array<PositionTakingTypeModel>> {
    return this.GetPositionTakingTypesResponse().pipe(
      __map(_r => _r.body as Array<PositionTakingTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetEducationFieldsResponse(): __Observable<__StrictHttpResponse<Array<EducationFieldListItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/educationfields`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EducationFieldListItemModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEducationFields(): __Observable<Array<EducationFieldListItemModel>> {
    return this.GetEducationFieldsResponse().pipe(
      __map(_r => _r.body as Array<EducationFieldListItemModel>)
    );
  }

  /**
   * @return Success
   */
  GetWorkTypesResponse(): __Observable<__StrictHttpResponse<Array<WorkTypeItemListModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/worktypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkTypeItemListModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetWorkTypes(): __Observable<Array<WorkTypeItemListModel>> {
    return this.GetWorkTypesResponse().pipe(
      __map(_r => _r.body as Array<WorkTypeItemListModel>)
    );
  }

  /**
   * @param provinceId undefined
   * @return Success
   */
  GetAmphoesResponse(provinceId?: number): __Observable<__StrictHttpResponse<Array<AmphoeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (provinceId != null) __params = __params.set('provinceId', provinceId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/amphoes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AmphoeModel>>;
      })
    );
  }
  /**
   * @param provinceId undefined
   * @return Success
   */
  GetAmphoes(provinceId?: number): __Observable<Array<AmphoeModel>> {
    return this.GetAmphoesResponse(provinceId).pipe(
      __map(_r => _r.body as Array<AmphoeModel>)
    );
  }

  /**
   * @return Success
   */
  GetGendersResponse(): __Observable<__StrictHttpResponse<Array<GenderModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/genders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GenderModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetGenders(): __Observable<Array<GenderModel>> {
    return this.GetGendersResponse().pipe(
      __map(_r => _r.body as Array<GenderModel>)
    );
  }

  /**
   * @return Success
   */
  GetProvinceResponse(): __Observable<__StrictHttpResponse<Array<ProvinceModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/provinces`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProvinceModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetProvince(): __Observable<Array<ProvinceModel>> {
    return this.GetProvinceResponse().pipe(
      __map(_r => _r.body as Array<ProvinceModel>)
    );
  }

  /**
   * @param amphoeId undefined
   * @return Success
   */
  GetTambonsResponse(amphoeId?: number): __Observable<__StrictHttpResponse<Array<TambonModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (amphoeId != null) __params = __params.set('amphoeId', amphoeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/tambons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TambonModel>>;
      })
    );
  }
  /**
   * @param amphoeId undefined
   * @return Success
   */
  GetTambons(amphoeId?: number): __Observable<Array<TambonModel>> {
    return this.GetTambonsResponse(amphoeId).pipe(
      __map(_r => _r.body as Array<TambonModel>)
    );
  }

  /**
   * @return Success
   */
  GetRequestTypesResponse(): __Observable<__StrictHttpResponse<Array<RequestTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/requesttypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RequestTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRequestTypes(): __Observable<Array<RequestTypeModel>> {
    return this.GetRequestTypesResponse().pipe(
      __map(_r => _r.body as Array<RequestTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetJobGradeLevelsResponse(): __Observable<__StrictHttpResponse<Array<ListItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/jobgradelevels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ListItemModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetJobGradeLevels(): __Observable<Array<ListItemModel>> {
    return this.GetJobGradeLevelsResponse().pipe(
      __map(_r => _r.body as Array<ListItemModel>)
    );
  }

  /**
   * @return Success
   */
  GetAccessLogEventTypesResponse(): __Observable<__StrictHttpResponse<Array<ListItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/accesslogeventtypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ListItemModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAccessLogEventTypes(): __Observable<Array<ListItemModel>> {
    return this.GetAccessLogEventTypesResponse().pipe(
      __map(_r => _r.body as Array<ListItemModel>)
    );
  }

  /**
   * @return Success
   */
  GetWorkflowTypesResponse(): __Observable<__StrictHttpResponse<Array<WorkflowTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/workflowtypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkflowTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetWorkflowTypes(): __Observable<Array<WorkflowTypeModel>> {
    return this.GetWorkflowTypesResponse().pipe(
      __map(_r => _r.body as Array<WorkflowTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetWorkflowStepActorTypesResponse(): __Observable<__StrictHttpResponse<Array<WorkflowStepActorTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/workflowstepactortypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkflowStepActorTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetWorkflowStepActorTypes(): __Observable<Array<WorkflowStepActorTypeModel>> {
    return this.GetWorkflowStepActorTypesResponse().pipe(
      __map(_r => _r.body as Array<WorkflowStepActorTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetCompanysResponse(): __Observable<__StrictHttpResponse<Array<CompanyMasterListModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyMasterListModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCompanys(): __Observable<Array<CompanyMasterListModel>> {
    return this.GetCompanysResponse().pipe(
      __map(_r => _r.body as Array<CompanyMasterListModel>)
    );
  }

  /**
   * @param companyId undefined
   * @return Success
   */
  GetCostCentersResponse(companyId?: number): __Observable<__StrictHttpResponse<Array<CostCenterMasterListModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (companyId != null) __params = __params.set('companyId', companyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/costcenters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CostCenterMasterListModel>>;
      })
    );
  }
  /**
   * @param companyId undefined
   * @return Success
   */
  GetCostCenters(companyId?: number): __Observable<Array<CostCenterMasterListModel>> {
    return this.GetCostCentersResponse(companyId).pipe(
      __map(_r => _r.body as Array<CostCenterMasterListModel>)
    );
  }

  /**
   * @return Success
   */
  GetListRelativeTypesResponse(): __Observable<__StrictHttpResponse<Array<ListRelativeTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/listRelativeTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ListRelativeTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetListRelativeTypes(): __Observable<Array<ListRelativeTypeModel>> {
    return this.GetListRelativeTypesResponse().pipe(
      __map(_r => _r.body as Array<ListRelativeTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetCountryResponse(): __Observable<__StrictHttpResponse<Array<CountryMasterListModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/countrys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CountryMasterListModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCountry(): __Observable<Array<CountryMasterListModel>> {
    return this.GetCountryResponse().pipe(
      __map(_r => _r.body as Array<CountryMasterListModel>)
    );
  }

  /**
   * @return Success
   */
  GetPayElementTypesResponse(): __Observable<__StrictHttpResponse<Array<PayElementTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/pay-element-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PayElementTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPayElementTypes(): __Observable<Array<PayElementTypeModel>> {
    return this.GetPayElementTypesResponse().pipe(
      __map(_r => _r.body as Array<PayElementTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetPayElementSubTypesResponse(): __Observable<__StrictHttpResponse<Array<PayElementSubTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/pay-element-sub-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PayElementSubTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPayElementSubTypes(): __Observable<Array<PayElementSubTypeModel>> {
    return this.GetPayElementSubTypesResponse().pipe(
      __map(_r => _r.body as Array<PayElementSubTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetEmployeePayrollProcessingStatusesResponse(): __Observable<__StrictHttpResponse<Array<EEmployeePayrollProcessingStatusEnumItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/employee-payroll-processing-statuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EEmployeePayrollProcessingStatusEnumItemModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeePayrollProcessingStatuses(): __Observable<Array<EEmployeePayrollProcessingStatusEnumItemModel>> {
    return this.GetEmployeePayrollProcessingStatusesResponse().pipe(
      __map(_r => _r.body as Array<EEmployeePayrollProcessingStatusEnumItemModel>)
    );
  }

  /**
   * @return Success
   */
  GetPayrollValidationStatusesResponse(): __Observable<__StrictHttpResponse<Array<EPayrollValidationStatusEnumItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/payroll-validation-statuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EPayrollValidationStatusEnumItemModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPayrollValidationStatuses(): __Observable<Array<EPayrollValidationStatusEnumItemModel>> {
    return this.GetPayrollValidationStatusesResponse().pipe(
      __map(_r => _r.body as Array<EPayrollValidationStatusEnumItemModel>)
    );
  }

  /**
   * @return Success
   */
  GetPayAllowanceRoundsResponse(): __Observable<__StrictHttpResponse<Array<PayAllowanceRoundModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/pay-allowance-rounds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PayAllowanceRoundModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPayAllowanceRounds(): __Observable<Array<PayAllowanceRoundModel>> {
    return this.GetPayAllowanceRoundsResponse().pipe(
      __map(_r => _r.body as Array<PayAllowanceRoundModel>)
    );
  }

  /**
   * @return Success
   */
  GetPayAllowanceTypesResponse(): __Observable<__StrictHttpResponse<Array<PayAllowanceTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/pay-allowance-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PayAllowanceTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPayAllowanceTypes(): __Observable<Array<PayAllowanceTypeModel>> {
    return this.GetPayAllowanceTypesResponse().pipe(
      __map(_r => _r.body as Array<PayAllowanceTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetShiftsInAllowanceTypesResponse(): __Observable<__StrictHttpResponse<Array<ShiftsInAllowanceTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/shifts-in-allowance-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShiftsInAllowanceTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetShiftsInAllowanceTypes(): __Observable<Array<ShiftsInAllowanceTypeModel>> {
    return this.GetShiftsInAllowanceTypesResponse().pipe(
      __map(_r => _r.body as Array<ShiftsInAllowanceTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetLeaveTypesInAllowanceTypesResponse(): __Observable<__StrictHttpResponse<Array<LeaveTypesInAllowanceTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/leave-type-in-allowance-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LeaveTypesInAllowanceTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetLeaveTypesInAllowanceTypes(): __Observable<Array<LeaveTypesInAllowanceTypeModel>> {
    return this.GetLeaveTypesInAllowanceTypesResponse().pipe(
      __map(_r => _r.body as Array<LeaveTypesInAllowanceTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetAttendanceCertifyStatusesListResponse(): __Observable<__StrictHttpResponse<Array<AttendanceCertifyStatusModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/attendanceCertifyStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AttendanceCertifyStatusModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAttendanceCertifyStatusesList(): __Observable<Array<AttendanceCertifyStatusModel>> {
    return this.GetAttendanceCertifyStatusesListResponse().pipe(
      __map(_r => _r.body as Array<AttendanceCertifyStatusModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateAttendanceCertifyStatusResponse(body?: AttendanceCertifyStatusModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MasterData/attendanceCertifyStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateAttendanceCertifyStatus(body?: AttendanceCertifyStatusModel): __Observable<string> {
    return this.CreateAttendanceCertifyStatusResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateAttendanceCertifyStatusResponse(body?: AttendanceCertifyStatusModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/MasterData/attendanceCertifyStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateAttendanceCertifyStatus(body?: AttendanceCertifyStatusModel): __Observable<string> {
    return this.UpdateAttendanceCertifyStatusResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetLeaveTimeTypesResponse(): __Observable<__StrictHttpResponse<Array<EnumItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/leave-time-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EnumItemModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetLeaveTimeTypes(): __Observable<Array<EnumItemModel>> {
    return this.GetLeaveTimeTypesResponse().pipe(
      __map(_r => _r.body as Array<EnumItemModel>)
    );
  }

  /**
   * @param attendanceCertifyStatusId undefined
   * @return Success
   */
  GetAttendanceCertifyStatusByIdResponse(attendanceCertifyStatusId: string): __Observable<__StrictHttpResponse<AttendanceCertifyStatusModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/attendanceCertifyStatuses/${encodeURIComponent(String(attendanceCertifyStatusId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttendanceCertifyStatusModel>;
      })
    );
  }
  /**
   * @param attendanceCertifyStatusId undefined
   * @return Success
   */
  GetAttendanceCertifyStatusById(attendanceCertifyStatusId: string): __Observable<AttendanceCertifyStatusModel> {
    return this.GetAttendanceCertifyStatusByIdResponse(attendanceCertifyStatusId).pipe(
      __map(_r => _r.body as AttendanceCertifyStatusModel)
    );
  }

  /**
   * @param attendanceCertifyStatusId undefined
   * @return Success
   */
  DeleteAttendanceCertifyStatusResponse(attendanceCertifyStatusId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/MasterData/attendanceCertifyStatuses/${encodeURIComponent(String(attendanceCertifyStatusId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param attendanceCertifyStatusId undefined
   * @return Success
   */
  DeleteAttendanceCertifyStatus(attendanceCertifyStatusId: string): __Observable<string> {
    return this.DeleteAttendanceCertifyStatusResponse(attendanceCertifyStatusId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetAttendanceCertifyTypesListResponse(): __Observable<__StrictHttpResponse<Array<AttendanceCertifyTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/attendanceCertifyTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AttendanceCertifyTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAttendanceCertifyTypesList(): __Observable<Array<AttendanceCertifyTypeModel>> {
    return this.GetAttendanceCertifyTypesListResponse().pipe(
      __map(_r => _r.body as Array<AttendanceCertifyTypeModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateAttendanceCertifyTypeResponse(body?: AttendanceCertifyTypeModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MasterData/attendanceCertifyTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateAttendanceCertifyType(body?: AttendanceCertifyTypeModel): __Observable<string> {
    return this.CreateAttendanceCertifyTypeResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateAttendanceCertifyTypeResponse(body?: AttendanceCertifyTypeModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/MasterData/attendanceCertifyTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateAttendanceCertifyType(body?: AttendanceCertifyTypeModel): __Observable<string> {
    return this.UpdateAttendanceCertifyTypeResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param attendanceCertifyTypeId undefined
   * @return Success
   */
  GetAttendanceCertifyTypeByIdResponse(attendanceCertifyTypeId: string): __Observable<__StrictHttpResponse<AttendanceCertifyTypeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/attendanceCertifyTypes/${encodeURIComponent(String(attendanceCertifyTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttendanceCertifyTypeModel>;
      })
    );
  }
  /**
   * @param attendanceCertifyTypeId undefined
   * @return Success
   */
  GetAttendanceCertifyTypeById(attendanceCertifyTypeId: string): __Observable<AttendanceCertifyTypeModel> {
    return this.GetAttendanceCertifyTypeByIdResponse(attendanceCertifyTypeId).pipe(
      __map(_r => _r.body as AttendanceCertifyTypeModel)
    );
  }

  /**
   * @param attendanceCertifyTypeId undefined
   * @return Success
   */
  DeleteAttendanceCertifyTypeResponse(attendanceCertifyTypeId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/MasterData/attendanceCertifyTypes/${encodeURIComponent(String(attendanceCertifyTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param attendanceCertifyTypeId undefined
   * @return Success
   */
  DeleteAttendanceCertifyType(attendanceCertifyTypeId: string): __Observable<string> {
    return this.DeleteAttendanceCertifyTypeResponse(attendanceCertifyTypeId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetLeaveStatusesListResponse(): __Observable<__StrictHttpResponse<Array<LeaveStatusModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/leaveStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LeaveStatusModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetLeaveStatusesList(): __Observable<Array<LeaveStatusModel>> {
    return this.GetLeaveStatusesListResponse().pipe(
      __map(_r => _r.body as Array<LeaveStatusModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateLeaveStatusResponse(body?: LeaveStatusModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MasterData/leaveStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateLeaveStatus(body?: LeaveStatusModel): __Observable<string> {
    return this.CreateLeaveStatusResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateLeaveStatusResponse(body?: LeaveStatusModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/MasterData/leaveStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateLeaveStatus(body?: LeaveStatusModel): __Observable<string> {
    return this.UpdateLeaveStatusResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param leaveStatusId undefined
   * @return Success
   */
  GetLeaveStatusByIdResponse(leaveStatusId: string): __Observable<__StrictHttpResponse<LeaveStatusModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/leaveStatuses/${encodeURIComponent(String(leaveStatusId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LeaveStatusModel>;
      })
    );
  }
  /**
   * @param leaveStatusId undefined
   * @return Success
   */
  GetLeaveStatusById(leaveStatusId: string): __Observable<LeaveStatusModel> {
    return this.GetLeaveStatusByIdResponse(leaveStatusId).pipe(
      __map(_r => _r.body as LeaveStatusModel)
    );
  }

  /**
   * @param leaveStatusId undefined
   * @return Success
   */
  DeleteLeaveStatusResponse(leaveStatusId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/MasterData/leaveStatuses/${encodeURIComponent(String(leaveStatusId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param leaveStatusId undefined
   * @return Success
   */
  DeleteLeaveStatus(leaveStatusId: string): __Observable<string> {
    return this.DeleteLeaveStatusResponse(leaveStatusId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetOTTypesListResponse(): __Observable<__StrictHttpResponse<Array<OTTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/otTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OTTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetOTTypesList(): __Observable<Array<OTTypeModel>> {
    return this.GetOTTypesListResponse().pipe(
      __map(_r => _r.body as Array<OTTypeModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateOTTypeResponse(body?: OTTypeModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MasterData/otTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateOTType(body?: OTTypeModel): __Observable<string> {
    return this.CreateOTTypeResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateOTTypeResponse(body?: OTTypeModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/MasterData/otTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateOTType(body?: OTTypeModel): __Observable<string> {
    return this.UpdateOTTypeResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param otTypeId undefined
   * @return Success
   */
  GetOTTypeByIdResponse(otTypeId: string): __Observable<__StrictHttpResponse<OTTypeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/otTypes/${encodeURIComponent(String(otTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTTypeModel>;
      })
    );
  }
  /**
   * @param otTypeId undefined
   * @return Success
   */
  GetOTTypeById(otTypeId: string): __Observable<OTTypeModel> {
    return this.GetOTTypeByIdResponse(otTypeId).pipe(
      __map(_r => _r.body as OTTypeModel)
    );
  }

  /**
   * @param otTypeId undefined
   * @return Success
   */
  DeleteOTTypeResponse(otTypeId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/MasterData/otTypes/${encodeURIComponent(String(otTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param otTypeId undefined
   * @return Success
   */
  DeleteOTType(otTypeId: string): __Observable<string> {
    return this.DeleteOTTypeResponse(otTypeId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetOTStatusesListResponse(): __Observable<__StrictHttpResponse<Array<OTStatusModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/otStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OTStatusModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetOTStatusesList(): __Observable<Array<OTStatusModel>> {
    return this.GetOTStatusesListResponse().pipe(
      __map(_r => _r.body as Array<OTStatusModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateOTStatusResponse(body?: OTStatusModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MasterData/otStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateOTStatus(body?: OTStatusModel): __Observable<string> {
    return this.CreateOTStatusResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateOTStatusResponse(body?: OTStatusModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/MasterData/otStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateOTStatus(body?: OTStatusModel): __Observable<string> {
    return this.UpdateOTStatusResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param otStatusId undefined
   * @return Success
   */
  GetOTStatusByIdResponse(otStatusId: string): __Observable<__StrictHttpResponse<OTStatusModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/otStatuses/${encodeURIComponent(String(otStatusId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTStatusModel>;
      })
    );
  }
  /**
   * @param otStatusId undefined
   * @return Success
   */
  GetOTStatusById(otStatusId: string): __Observable<OTStatusModel> {
    return this.GetOTStatusByIdResponse(otStatusId).pipe(
      __map(_r => _r.body as OTStatusModel)
    );
  }

  /**
   * @param otStatusId undefined
   * @return Success
   */
  DeleteOTStatusResponse(otStatusId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/MasterData/otStatuses/${encodeURIComponent(String(otStatusId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param otStatusId undefined
   * @return Success
   */
  DeleteOTStatus(otStatusId: string): __Observable<string> {
    return this.DeleteOTStatusResponse(otStatusId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetWorkDayTypesResponse(): __Observable<__StrictHttpResponse<Array<WorkDayTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/workdaytypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkDayTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetWorkDayTypes(): __Observable<Array<WorkDayTypeModel>> {
    return this.GetWorkDayTypesResponse().pipe(
      __map(_r => _r.body as Array<WorkDayTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetTimeAttendanceStatusResponse(): __Observable<__StrictHttpResponse<Array<TimeAttendanceStatusModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/timeattendancestatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TimeAttendanceStatusModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTimeAttendanceStatus(): __Observable<Array<TimeAttendanceStatusModel>> {
    return this.GetTimeAttendanceStatusResponse().pipe(
      __map(_r => _r.body as Array<TimeAttendanceStatusModel>)
    );
  }

  /**
   * @return Success
   */
  GetHolidayTypesResponse(): __Observable<__StrictHttpResponse<Array<HolidayTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/holidaytypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HolidayTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetHolidayTypes(): __Observable<Array<HolidayTypeModel>> {
    return this.GetHolidayTypesResponse().pipe(
      __map(_r => _r.body as Array<HolidayTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetShiftDefinitionTypesResponse(): __Observable<__StrictHttpResponse<Array<ShiftDefinitionTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/ShiftDefinitionTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShiftDefinitionTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetShiftDefinitionTypes(): __Observable<Array<ShiftDefinitionTypeModel>> {
    return this.GetShiftDefinitionTypesResponse().pipe(
      __map(_r => _r.body as Array<ShiftDefinitionTypeModel>)
    );
  }

  /**
   * @return Success
   */
  GetShiftsInHolidayTypesResponse(): __Observable<__StrictHttpResponse<Array<ShiftsInHolidayTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterData/shiftsInHolidayTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShiftsInHolidayTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetShiftsInHolidayTypes(): __Observable<Array<ShiftsInHolidayTypeModel>> {
    return this.GetShiftsInHolidayTypesResponse().pipe(
      __map(_r => _r.body as Array<ShiftsInHolidayTypeModel>)
    );
  }
}

module MasterDataService {
}

export { MasterDataService }
