<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <div class="grid">
          <div class="col-fixed">
            <h1>{{'employee_resignation' | translate}}</h1>
          </div>
          <div class="col"></div>
          <div class="col-fixed">
            <button
              pButton
              type="button"
              label="บันทึก"
              (click)="onSaveBtnClick()"
              [disabled]="disbleSaveBtn()"
            ></button>
          </div>
        </div>
        <hr />
        <div class="card">
          <form [formGroup]="form">
            <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
