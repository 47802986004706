import { FormlyFieldConfig } from '@ngx-formly/core';

export function minlengthValidationMessages(err, field: FormlyFieldConfig) {
  return `ต้องกรอกอย่างน้อย ${field.templateOptions.minLength} ตัวอักษร`;
}

export function minValidationMessages(err, field: FormlyFieldConfig) {
  return `ต้องกรอกอย่างน้อย ${field.templateOptions.min}`;
}

export function maxValidationMessages(err, field: FormlyFieldConfig) {
  return `ต้องกรอกไม่เกิน ${field.templateOptions.max}`;
}
