import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { DocumentService } from '../../../../../../api/services';

interface EmployeeFile {
  type: string;
  fileName: string;
  desc: string;
  uploadDate: string;
  documentId?: number;
  buttonLabel: string;
}

interface SelectFileParam {
  currentFiles: File[];
}

@Component({
  selector: 'app-employee-create-form-attach-file',
  templateUrl: './employee-create-form-attach-file.component.html',
  styleUrls: ['./employee-create-form-attach-file.component.css'],
})
export class EmployeeCreateFormAttachFileComponent implements OnInit {
  importanceFileList: EmployeeFile[];
  isApprovalCenterPage = false;

  @Input('defaultModel')
  set defaultModel(val) {
    if (val) {
      this.importanceFileList = val;
      console.log(val);
    }
  }

  constructor(private documentService: DocumentService, private router: Router) {}

  onRemoveFile(selectFileParam: SelectFileParam, rowData: EmployeeFile) {
    rowData.documentId = null;
    rowData.buttonLabel = 'อัพโหลดไฟล์';
    rowData.fileName = null;
  }

  onSelectFile(selectFileParam: SelectFileParam, rowData: EmployeeFile) {
    this.documentService
      .CreateDocument({
        description: rowData.type,
        file: selectFileParam.currentFiles[0],
      })
      .subscribe(result => {
        rowData.documentId = result.documentId;
        rowData.buttonLabel = 'อัพโหลดไฟล์ (1)';
        rowData.fileName = result.name;
      });
  }

  ngOnInit() {
    if (this.router.url.includes('approval-center')) {
      this.isApprovalCenterPage = true;
      console.log(this.importanceFileList);
    }
    if (this.importanceFileList == null) {
      this.importanceFileList = [
        {
          type: 'สำเนาบัตรประชาชน',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
        {
          type: 'สำเนาใบขับขี่',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
        {
          type: 'สำเนาสมุดบัญชีเงินฝาก',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
        {
          type: 'สำเนาทะเบียนบ้าน',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
        {
          type: 'สำเนาเปลี่ยนชื่อ-สกุล',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
        {
          type: 'สำเนาหลักฐานการเกณฑ์ทหาร/การพ้นภาระทางทหาร',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
        {
          type: 'สำเนาทะเบียนสมรส',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
        {
          type: 'สำเนาใบอนุญาตทำงาน',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
        {
          type: 'สำเนา passport',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
        {
          type: 'สำเนาสูติบัตรบุตรพนักงาน',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
        {
          type: 'สำเนาวุฒิการศึกษา',
          fileName: '',
          desc: '',
          uploadDate: '',
          documentId: null,
          buttonLabel: 'อัพโหลดไฟล์',
        },
      ];
    }
  }

  downloadFile(id: number, fileName: string) {
    console.log(id);
    console.log(fileName);

    this.documentService.Download(id).subscribe(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      if (fileName.endsWith('.pdf')) {
        a.target = '_blank';
      } else {
        a.download = fileName;
      }

      a.click();
    });
  }
}
