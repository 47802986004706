import { Component, OnInit } from '@angular/core';
import { AppBreadcrumbService } from 'app/layout/layout-default/app-breadcrumb/app-breadcrumb.service';
import { MenuIconItem } from 'app/components/menu-icon/menu-icon.component';
import { Router } from '@angular/router';
import { AppMenuService } from '../../../services/app-menu.service';
import { ViewUtilsService } from '../../../services/view-utils.service';
import { IosRedeemedCodeService } from 'app/api/services';
import { IosRedeemedCodeModel } from 'app/api/models';
import { HttpErrorResponse } from '@angular/common/http';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';

@Component({
  selector: 'app-ios-redeemed-code',
  templateUrl: './ios-redeemed-code.component.html',
})
export class IosRedeemedCodeComponent implements OnInit {
  menuItems: MenuIconItem[];
  isLoading: boolean;
  iosRedeemedCode: IosRedeemedCodeModel;

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    public router: Router,
    private appMenuService: AppMenuService,
    public viewUtils: ViewUtilsService,
    public iosRedeemedCodeService: IosRedeemedCodeService,
    public notificationService: AppNotificationServiceService,
  ) {}

  ngOnInit(): void {
    this.initBreadcrumb();
    this.initMenuItems();
    this.onGetLinkAndRedeemedCode();
  }

  initBreadcrumb() {
    this.breadcrumbService.setItems([
      { label: 'หน้าแรก', routerLink: '/employee' },
      { label: 'ส่วนหลัก' },
      { label: 'ดาวน์โหลดแอปสำหรับ iOS' },
    ]);
  }

  initMenuItems() {
    const prefixLink = 'employee/core/';

    this.menuItems = this.appMenuService.getMenu().find(a => a.routerLink[0] == '/employee/core').items;
  }

  onGetLinkAndRedeemedCode() {
    this.iosRedeemedCodeService.GetIosRedeemedCode().subscribe(
      result => {
        this.iosRedeemedCode = result;
      },
      (err: HttpErrorResponse) => {
        const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
        this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
      },
    );
  }

  onCopyRedeemedCode() {
    const copyRedeemedCode = document.getElementById('redeemedCode');

    copyRedeemedCode.select();
    copyRedeemedCode.setSelectionRange(0, 99999); /* For mobile devices */

    navigator.clipboard.writeText(copyRedeemedCode.value);

    alert('คัดลอก redeemed code: ' + copyRedeemedCode.value);
  }
}
