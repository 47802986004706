import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { filter, map } from 'rxjs/operators';
import { EmployeeListItemModel } from 'app/api/models';
import { EmployeeService } from 'app/api/services';
import { ModalEmployeeSearchComponent } from 'app/modals/core/modal-employee-search/modal-employee-search.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-formly-select-employee',
  templateUrl: './formly-select-employee.component.html',
  styleUrls: ['./formly-select-employee.component.css'],
  providers: [DialogService],
})
export class FormlySelectEmployeeComponent extends FieldType implements OnInit {
  ref: DynamicDialogRef;
  btnLabel = 'เลือกพนักงาน';
  isInitValue = true;
  isOnlySubOrdinate: boolean = false;
  mode: formlySelectEmployeeDisplayMode = formlySelectEmployeeDisplayMode.button;
  disabled: boolean = false;

  public get formlySelectEmployeeDisplayMode(): typeof formlySelectEmployeeDisplayMode {
    return formlySelectEmployeeDisplayMode;
  }

  constructor(private dialogService: DialogService, private employeeService: EmployeeService) {
    super();
  }

  ngOnInit(): void {
    this.isOnlySubOrdinate = this.to.isOnlySubOrdinate || this.isOnlySubOrdinate;
    this.mode = this.to.mode || this.mode;
    this.disabled = this.to.disabled || this.disabled;

    this.formControl.valueChanges.pipe(filter(() => this.isInitValue)).subscribe(employeeId => {
      if (employeeId) {
        this.employeeService.GetEmployeeInfo(employeeId).subscribe(info => {
          this.btnLabel = info.currentName.fullnameTh ?? 'N/A';
          if (typeof this.to.successInitEmployee === 'function') {
            this.to.successInitEmployee(info);
          }
        });
      }
    });
  }

  showEmployeeSearchModal() {
    this.ref = this.dialogService.open(ModalEmployeeSearchComponent, {
      header: 'ค้นหาพนักงาน',
      width: '90%',
      contentStyle: { overflow: 'auto' },
      data: {
        isOnlySubOrdinate: this.isOnlySubOrdinate
      },
    });

    this.ref.onClose.pipe(map(data => data as EmployeeListItemModel)).subscribe(data => {
      if (data) {
        this.isInitValue = false;
        this.btnLabel = data.employeeName?.fullnameTh ?? 'N/A';
        this.formControl.setValue(data.employeeId);
      }
    });
  }

}

export enum formlySelectEmployeeDisplayMode {
  button,
  text,
}
