<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>ข้อมูลเพิ่มเติม</h1>
        <hr />
        <div class="card">
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="additionalList">
            <ng-template pTemplate="header">
              <tr>
                <th>Field</th>
                <th>Value</th>
                <!-- <th style="width: 100px">Action</th> -->
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.columnName }}</td>
                <td>{{ rowData.value }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="2" class="emptymessage">ไม่พบข้อมูล</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
