import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DialogService } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { EmployeeTaxInfoModel, EmployeeTaxBeforeHireInfoModel, TaxReductionModel } from '../../../api/models';
import { EmployeeService, MasterDataService } from '../../../api/services';
import { AppNotificationServiceService } from '../../../shared/app-notification-service.service';
import { CustomFormlyFieldConfig } from '../../../utils/formly-utils';
@Component({
  selector: 'app-TaxInfo',
  templateUrl: './TaxInfo.component.html',
  styleUrls: ['./TaxInfo.component.scss']
})
export class TaxInfoComponent implements OnInit {
  employeeId = null;
  isEditable = false;
  isVisibleEmployee = true;
  model: EmployeeTaxInfoModel;
  modelBeforeHire: EmployeeTaxBeforeHireInfoModel;
  taxCalculationForm = new FormGroup({});
  taxCalculationFields: CustomFormlyFieldConfig<
    keyof EmployeeTaxInfoModel
  >[] = [
    {
      key: 'taxCalculationType',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ประเภทการคำนวณภาษี',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
        options: this.masterDataService.GetTaxCalculationTypes()
          .pipe(map(a => a.map(b => ({ label: b.name, value: b.key })))),
      },
    },
    {
      key: 'manualTaxValue',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      hideExpression: 'model.taxCalculationType != "Manual"',
      templateOptions: {
        attributes: {
          style: 'width: 100%;',
        },
        labelClass: 'col-4',
        inputClass: 'col-8',
        label: 'กำหนดค่าภาษีที่หัก',
        type: 'number',
      },
    },
    {
      key: 'spouseIncomeType',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'สถานะการยื่นแบบของคู่สมรส',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
        options: this.masterDataService.GetSpouseIncomeTypes()
          .pipe(map(a => a.map(b => ({ label: b.name, value: b.key })))),
      },

    }
  ];
  taxBeforeHireForm = new FormGroup({});
  taxBeforeHireFields: CustomFormlyFieldConfig<
    keyof EmployeeTaxBeforeHireInfoModel
  >[] = [
    {
      key: 'accRegularIncomeBeforeHire',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินรายได้ประจำสะสมก่อนเป็นพนักงาน',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'accNonRegularIncomeBeforeHire',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินรายได้ไม่ประจำสะสมก่อนเป็นพนักงาน',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'accGpfBeforeHire',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินรายได้กบข.สะสมก่อนเป็นพนักงาน',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'accPvdBeforeHire',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินกองทุนสำรองเลี้ยงชีพสะสมก่อนเป็นพนักงาน',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'accTeachWelfareFundBeforeHire',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินกองทุนสงเคราะห์ครูโรงเรียนเอกชนสะสมก่อนเป็นพนักงาน',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'accBeforeHire',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินที่ได้รับการยกเว้นคนพิการ หรืออายุตั้งแต่ 65 ปีขึ้นไปสะสมก่อนเป็นพนักงาน',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'accSeveranceBeforeHire',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินค่าชดเชยที่ได้รับตามกฎหมายแรงงานสะสมก่อนเป็นพนักงาน',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'accSsoBeforeHire',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินกองทุนประกันสังคมสะสมก่อนเป็นพนักงาน',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'accTaxBeforeHire',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินภาษีหัก ณ ที่จ่ายสะสมก่อนเป็นพนักงาน',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    }
  ];

  taxDeductionForm = new FormGroup({});
  taxDeductionFields: CustomFormlyFieldConfig<
    keyof EmployeeTaxInfoModel | keyof TaxReductionModel
  >[] = [
    {
      key: 'isSupportFather',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'อุปการะบิดา',
        labelClass: 'col-4',
        required: true
      },
    },
    {
      key: 'isSupportMother',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'อุปการะมารดา',
        labelClass: 'col-4',
        required: true
      },
    },
    {
      key: 'isSpouseSupportFather',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'อุปการะบิดาของคู่สมรส',
        labelClass: 'col-4',
        required: true
      },
    },
    {
      key: 'isSpouseSupportMother',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'อุปการะมารดาดของคู่สมรส',
        labelClass: 'col-4',
        required: true
      },
    },
    {
      key: 'parentsHealthInsurance',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เบี้ยประกันสุขภาพของบิดามารดาตนเอง',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'spouseParentsHealthInsurance',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เบี้ยประกันสุขภาพของบิดารมารดาคู่สมรส ',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'ownLifeInsurance',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เบี้ยประกันชีวิตของตนเอง',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'ownHealthInsurance',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เบี้ยประกันสุขภาพของตนเอง',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'pensionLifeInsurance',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เบี้ยประกันชีวิตแบบบำนาญของตนเอง',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'nationalSavingFund',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินสะสมกองทุนการออมแห่งชาติ',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'rmfFund',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ RMF',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'ssfFund',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม SSF',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'loanInterestForBuildings',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ดอกเบี้ยเงินกู้ยืมเพื่อซื้อ เช่าซื้อ หรือสร้างอาคารอยู่อาศัย ',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'maternityExpenses',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ค่าฝากครรภ์ และค่าคลอดบุตร',
        type: 'number',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      template: '<hr>'
    },
    {
      key: 'taxReductions',
      type: 'repeat',
      templateOptions: {
        addText: '+ เพิ่มรายการหักลดหย่อน',
        bigTitle: 'รายละเอียดค่าลดหย่อนอื่นๆ:',
        littleTitle: 'ค่าลดหย่อนที่ ',
        deleteTitle: 'ลบ',
      },
      fieldArray: {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            key: 'taxReduction',
            type: 'primeng-input',
            className: 'col-12',
            templateOptions: {
              label: 'ชื่อค่าลดหย่อน',
              maxLength: 256,
              required: true
            },
          },
          {
            key: 'amountPerYear',
            type: 'primeng-input',
            className: 'col-6',
            templateOptions: {
              label: 'ค่าลดหย่อนต่อปี',
              type: 'number',
              required: true
            },
          },
          {
            key: 'effectiveYear',
            type: 'primeng-input',
            className: 'col-3',
            templateOptions: {
              label: 'ปีที่เริ่มใช้ (ค.ศ.)',
              type: 'number',
              required: true
            },
          },
          {
            key: 'terminateYear',
            type: 'primeng-input',
            className: 'col-3',
            templateOptions: {
              label: 'ปีที่สิ้นสุด (ค.ศ.)',
              type: 'number',
              required: true
            },
          },
        ]
      }
    }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notification: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeService: EmployeeService,
    private masterDataService: MasterDataService,
  ) {
  }

  async ngOnInit() {
    this.setEditablePermission();

    const urls = this.router.url.split('/');
    const employeeId = this.route.snapshot.paramMap.get('employeeId');
    const namepart = urls[3];

    // ส่ง route มาจาก EmployeeModule ส่วนของพนักงาน
    if(namepart == "taxInfo-edit-employee"){
      this.employeeId = employeeId;
      this.model = await this.employeeService.GetTaxInfo(this.employeeId).toPromise();
      this.modelBeforeHire = await this.employeeService.GetTaxBeforeHireInfo(this.employeeId).toPromise();
      this.isEditable = !this.isEditable;
      this.isVisibleEmployee = false;
    }else{
      this.route.parent.params.subscribe(async p => {
        this.employeeId = p.employeeId;
        this.model = await this.employeeService.GetTaxInfo(this.employeeId).toPromise();
        this.modelBeforeHire = await this.employeeService.GetTaxBeforeHireInfo(this.employeeId).toPromise();
      })
    }
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }
  async save() {
    await this.employeeService.UpdateTaxInfo({
      employeeId: this.employeeId,
      body: this.model
    }).toPromise();
    this.notification.saveCompleted();
  }
  async saveBeforeHire() {
    await this.employeeService.UpdateTaxBeforeHireInfo({
      employeeId: this.employeeId,
      body: this.modelBeforeHire
    }).toPromise();
    this.notification.saveCompleted();
  }
}
