import { Component, getPlatform, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Chart } from 'chart.js';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from './services/authentication.service';
import { ApplicationSettingsService } from './services/application-settings.service';
import { Router } from '@angular/router';

// import OrgChart from '@balkangraph/orgchart.js';
import { GoogleAuthService } from './services/google-auth.service';
import { LoadingService } from './services/LoadingService';
import { PrimeNGConfig } from 'primeng/api';

interface googleAuthParams {
  googleId?: string;
  email?: string;
  type?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    public permissionService: NgxPermissionsService,
    public authenticationService: AuthenticationService,
    public loadingService: LoadingService,
    private router: Router,
  ) {
    this.authenticationService.bootstrap();
  }
  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.initChartJs();
    this.initOrgChart();

    // for mobile app
    window.addEventListener('message', event => {
      if (event.data === 'Request back page') {
        const path = window.location.hash;

        if (path === '#/employee/info' || path === '#/admin/home' || path === '#/account/login') {
          window.parent.postMessage('Exit', '*');
        } else {
          window.parent.postMessage('Back', '*');
        }
      }
    });
  }

  initOrgChart() {
    OrgChart.templates.milaBig = {
      defs: '',
      size: [350, 120],
      linkAdjuster: { fromX: 0, fromY: 0, toX: 0, toY: 0 },
      ripple: { radius: 0, color: '#e6e6e6', rect: null },
      expandCollapseSize: 30,
      svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  style="display:block;" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>',
      link: '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />',
      assistanseLink:
        '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="2px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} {xd},{yd} L{xe},{ye}"/>',
      pointer:
        '<g data-pointer="pointer" transform="matrix(0,0,0,0,100,100)"><radialGradient id="pointerGradient"><stop stop-color="#ffffff" offset="0" /><stop stop-color="#C1C1C1" offset="1" /></radialGradient><circle cx="16" cy="16" r="16" stroke-width="1" stroke="#acacac" fill="url(#pointerGradient)"></circle></g>',
      node: '<rect x="0" y="0" height="{h}" width="{w}" fill="#039BE5" stroke-width="1" stroke="#aeaeae" rx="5" ry="5"></rect>',
      plus: '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle><line x1="4" y1="15" x2="26" y2="15" stroke-width="1" stroke="#aeaeae"></line><line x1="15" y1="4" x2="15" y2="26" stroke-width="1" stroke="#aeaeae"></line>',
      minus:
        '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle><line x1="4" y1="15" x2="26" y2="15" stroke-width="1" stroke="#aeaeae"></line>',
      nodeMenuButton:
        '<g style="cursor:pointer;" transform="matrix(1,0,0,1,325,105)" control-node-menu-id="{id}"><rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect><circle cx="0" cy="0" r="2" fill="#ffffff"></circle><circle cx="7" cy="0" r="2" fill="#ffffff"></circle><circle cx="14" cy="0" r="2" fill="#ffffff"></circle></g>',
      exportMenuButton:
        '<div style="position:absolute;right:{p}px;top:{p}px; width:40px;height:50px;cursor:pointer;" control-export-menu=""><hr style="background-color: #7A7A7A; height: 3px; border: none;"><hr style="background-color: #7A7A7A; height: 3px; border: none;"><hr style="background-color: #7A7A7A; height: 3px; border: none;"></div>',
      img_0:
        '<clipPath id="{randId}"><circle cx="50" cy="30" r="40"></circle></clipPath><image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="10" y="-10"  width="80" height="80"></image>',
      link_field_0:
        '<text text-anchor="middle" fill="#aeaeae" width="290" x="0" y="0" style="font-size:10px;">{val}</text>',
      field_0:
        '<text width="230" style="font-size: 18px;" fill="#ffffff" x="175" y="95" text-anchor="middle">{val}</text>',
      field_1:
        '<text width="130" text-overflow="multiline" style="font-size: 14px;" fill="#ffffff" x="330" y="30" text-anchor="end">{val}</text>',
      padding: [50, 20, 35, 20],
    };

    OrgChart.templates.chainOfCommand1 = {
      ...OrgChart.templates.milaBig,
      node: '<rect x="0" y="0" height="120" width="350" fill="#039BE5" stroke-width="1" stroke="#aeaeae"></rect><rect x="-5" y="70" height="30" width="360" fill="#ffffff" stroke-width="1" stroke="#039BE5"></rect><line x1="-5" x2="0" y1="100" y2="105" stroke-width="1" stroke="#039BE5"/><line x1="355" x2="350" y1="100" y2="105" stroke-width="1" stroke="#039BE5"/>',
      img_0:
        '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" x="20" y="5"  width="64" height="64"></image>',
      field_0:
        '<text width="340" style="font-size: 18px;" fill="#039BE5" x="175" y="92" text-anchor="middle">{val}</text>',
      nodeMenuButton:
        '<g style="cursor:pointer;" transform="matrix(1,0,0,1,325,110)" control-node-menu-id="{id}"><rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect><circle cx="0" cy="0" r="2" fill="#ffffff"></circle><circle cx="7" cy="0" r="2" fill="#ffffff"></circle><circle cx="14" cy="0" r="2" fill="#ffffff"></circle></g>',
    };
  }
  initChartJs() {
    /* ไม่รู้ใช้ทำอะไร ใช้ไม่ได้
    Chart.register({
      id: 'config_global_draw',
      beforeDraw(chart, args, options) {
        if (chart.config.options.elements.center) {
          const element = chart.config.options.elements.center;
          // const data = chart.data.datasets[0].data;
          // const sum = data.reduce((a, b) => {
          //   return a + b;
          // }, 0);

          const ctx = chart.ctx;
          ctx.restore();

          ctx.font = element.font;
          ctx.fillStyle = element.textColor;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          const text = element.text;
          const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.fillText(text, centerX, centerY);
          ctx.save();
        }
      },
    });
    */
  }
}
