import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-attendance-card',
  templateUrl: './attendance-card.component.html',
  styleUrls: ['./attendance-card.component.css'],
})
export class AttendanceCardComponent implements OnInit {
  @Input() cardName: any = null;
  @Input() times: number;
  @Input() amountOfTime: string;
  @Input() unitOfAmount: string;

  @Input() loading: boolean;

  constructor() {}

  ngOnInit() {}
}
