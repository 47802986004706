/* tslint:disable */
type EProbationEvaluationFormStatus =
  'AwaitEval1' |
  'Eval1' |
  'AwaitEval2' |
  'Eval2' |
  'Finish';
module EProbationEvaluationFormStatus {
  export const AWAIT_EVAL_1: EProbationEvaluationFormStatus = 'AwaitEval1';
  export const EVAL_1: EProbationEvaluationFormStatus = 'Eval1';
  export const AWAIT_EVAL_2: EProbationEvaluationFormStatus = 'AwaitEval2';
  export const EVAL_2: EProbationEvaluationFormStatus = 'Eval2';
  export const FINISH: EProbationEvaluationFormStatus = 'Finish';
  export function values(): EProbationEvaluationFormStatus[] {
    return [
      AWAIT_EVAL_1,
      EVAL_1,
      AWAIT_EVAL_2,
      EVAL_2,
      FINISH
    ];
  }
}

export { EProbationEvaluationFormStatus }