/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TaStatSummary } from '../models/ta-stat-summary';
import { ChartStatByYear } from '../models/chart-stat-by-year';
import { EmployeePaginationParams } from '../models/employee-pagination-params';
import { TaSubordinatesStatPaginationResult } from '../models/ta-subordinates-stat-pagination-result';
import { OtStatSummary } from '../models/ot-stat-summary';
import { OtSubordinatesStatByCategoryPaginationResult } from '../models/ot-subordinates-stat-by-category-pagination-result';
@Injectable({
  providedIn: 'root',
})
class DashboardService extends __BaseService {
  static readonly PersonalTimeAttendanceStatPath = '/api/Dashboard/PersonalTimeAttendanceStat';
  static readonly PersonalTimeAttendanceHistoryPath = '/api/Dashboard/PersonalTimeAttendanceHistory';
  static readonly SubordinatesTimeAttendanceStatPath = '/api/Dashboard/SubordinatesTimeAttendanceStat';
  static readonly SubordinatesTimeAttendanceLatePath = '/api/Dashboard/SubordinatesTimeAttendanceLate';
  static readonly SubordinatesTimeAttendanceLeftEarlyPath = '/api/Dashboard/SubordinatesTimeAttendanceLeftEarly';
  static readonly SubordinatesTimeAttendanceAbsentPath = '/api/Dashboard/SubordinatesTimeAttendanceAbsent';
  static readonly PersonalOtStatPath = '/api/Dashboard/PersonalOtStat';
  static readonly SubordinatesOtStatPath = '/api/Dashboard/SubordinatesOtStat';
  static readonly SubordinatesOtStatPerUnitPath = '/api/Dashboard/SubordinatesOtStatPerUnit';
  static readonly SubordinatesOtStatPerPersonPath = '/api/Dashboard/SubordinatesOtStatPerPerson';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DashboardService.PersonalTimeAttendanceStatParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  PersonalTimeAttendanceStatResponse(params: DashboardService.PersonalTimeAttendanceStatParams): __Observable<__StrictHttpResponse<TaStatSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dashboard/PersonalTimeAttendanceStat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TaStatSummary>;
      })
    );
  }
  /**
   * @param params The `DashboardService.PersonalTimeAttendanceStatParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  PersonalTimeAttendanceStat(params: DashboardService.PersonalTimeAttendanceStatParams): __Observable<TaStatSummary> {
    return this.PersonalTimeAttendanceStatResponse(params).pipe(
      __map(_r => _r.body as TaStatSummary)
    );
  }

  /**
   * @return Success
   */
  PersonalTimeAttendanceHistoryResponse(): __Observable<__StrictHttpResponse<Array<ChartStatByYear>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dashboard/PersonalTimeAttendanceHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ChartStatByYear>>;
      })
    );
  }
  /**
   * @return Success
   */
  PersonalTimeAttendanceHistory(): __Observable<Array<ChartStatByYear>> {
    return this.PersonalTimeAttendanceHistoryResponse().pipe(
      __map(_r => _r.body as Array<ChartStatByYear>)
    );
  }

  /**
   * @param params The `DashboardService.SubordinatesTimeAttendanceStatParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesTimeAttendanceStatResponse(params: DashboardService.SubordinatesTimeAttendanceStatParams): __Observable<__StrictHttpResponse<TaStatSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dashboard/SubordinatesTimeAttendanceStat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TaStatSummary>;
      })
    );
  }
  /**
   * @param params The `DashboardService.SubordinatesTimeAttendanceStatParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesTimeAttendanceStat(params: DashboardService.SubordinatesTimeAttendanceStatParams): __Observable<TaStatSummary> {
    return this.SubordinatesTimeAttendanceStatResponse(params).pipe(
      __map(_r => _r.body as TaStatSummary)
    );
  }

  /**
   * @param params The `DashboardService.SubordinatesTimeAttendanceLateParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesTimeAttendanceLateResponse(params: DashboardService.SubordinatesTimeAttendanceLateParams): __Observable<__StrictHttpResponse<TaSubordinatesStatPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dashboard/SubordinatesTimeAttendanceLate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TaSubordinatesStatPaginationResult>;
      })
    );
  }
  /**
   * @param params The `DashboardService.SubordinatesTimeAttendanceLateParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesTimeAttendanceLate(params: DashboardService.SubordinatesTimeAttendanceLateParams): __Observable<TaSubordinatesStatPaginationResult> {
    return this.SubordinatesTimeAttendanceLateResponse(params).pipe(
      __map(_r => _r.body as TaSubordinatesStatPaginationResult)
    );
  }

  /**
   * @param params The `DashboardService.SubordinatesTimeAttendanceLeftEarlyParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesTimeAttendanceLeftEarlyResponse(params: DashboardService.SubordinatesTimeAttendanceLeftEarlyParams): __Observable<__StrictHttpResponse<TaSubordinatesStatPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dashboard/SubordinatesTimeAttendanceLeftEarly`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TaSubordinatesStatPaginationResult>;
      })
    );
  }
  /**
   * @param params The `DashboardService.SubordinatesTimeAttendanceLeftEarlyParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesTimeAttendanceLeftEarly(params: DashboardService.SubordinatesTimeAttendanceLeftEarlyParams): __Observable<TaSubordinatesStatPaginationResult> {
    return this.SubordinatesTimeAttendanceLeftEarlyResponse(params).pipe(
      __map(_r => _r.body as TaSubordinatesStatPaginationResult)
    );
  }

  /**
   * @param params The `DashboardService.SubordinatesTimeAttendanceAbsentParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesTimeAttendanceAbsentResponse(params: DashboardService.SubordinatesTimeAttendanceAbsentParams): __Observable<__StrictHttpResponse<TaSubordinatesStatPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dashboard/SubordinatesTimeAttendanceAbsent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TaSubordinatesStatPaginationResult>;
      })
    );
  }
  /**
   * @param params The `DashboardService.SubordinatesTimeAttendanceAbsentParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesTimeAttendanceAbsent(params: DashboardService.SubordinatesTimeAttendanceAbsentParams): __Observable<TaSubordinatesStatPaginationResult> {
    return this.SubordinatesTimeAttendanceAbsentResponse(params).pipe(
      __map(_r => _r.body as TaSubordinatesStatPaginationResult)
    );
  }

  /**
   * @param params The `DashboardService.PersonalOtStatParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  PersonalOtStatResponse(params: DashboardService.PersonalOtStatParams): __Observable<__StrictHttpResponse<OtStatSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Dashboard/PersonalOtStat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OtStatSummary>;
      })
    );
  }
  /**
   * @param params The `DashboardService.PersonalOtStatParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  PersonalOtStat(params: DashboardService.PersonalOtStatParams): __Observable<OtStatSummary> {
    return this.PersonalOtStatResponse(params).pipe(
      __map(_r => _r.body as OtStatSummary)
    );
  }

  /**
   * @param params The `DashboardService.SubordinatesOtStatParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesOtStatResponse(params: DashboardService.SubordinatesOtStatParams): __Observable<__StrictHttpResponse<OtStatSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dashboard/SubordinatesOtStat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OtStatSummary>;
      })
    );
  }
  /**
   * @param params The `DashboardService.SubordinatesOtStatParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesOtStat(params: DashboardService.SubordinatesOtStatParams): __Observable<OtStatSummary> {
    return this.SubordinatesOtStatResponse(params).pipe(
      __map(_r => _r.body as OtStatSummary)
    );
  }

  /**
   * @param params The `DashboardService.SubordinatesOtStatPerUnitParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesOtStatPerUnitResponse(params: DashboardService.SubordinatesOtStatPerUnitParams): __Observable<__StrictHttpResponse<OtSubordinatesStatByCategoryPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dashboard/SubordinatesOtStatPerUnit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OtSubordinatesStatByCategoryPaginationResult>;
      })
    );
  }
  /**
   * @param params The `DashboardService.SubordinatesOtStatPerUnitParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesOtStatPerUnit(params: DashboardService.SubordinatesOtStatPerUnitParams): __Observable<OtSubordinatesStatByCategoryPaginationResult> {
    return this.SubordinatesOtStatPerUnitResponse(params).pipe(
      __map(_r => _r.body as OtSubordinatesStatByCategoryPaginationResult)
    );
  }

  /**
   * @param params The `DashboardService.SubordinatesOtStatPerPersonParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesOtStatPerPersonResponse(params: DashboardService.SubordinatesOtStatPerPersonParams): __Observable<__StrictHttpResponse<OtSubordinatesStatByCategoryPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Dashboard/SubordinatesOtStatPerPerson`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OtSubordinatesStatByCategoryPaginationResult>;
      })
    );
  }
  /**
   * @param params The `DashboardService.SubordinatesOtStatPerPersonParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * - `body`:
   *
   * @return Success
   */
  SubordinatesOtStatPerPerson(params: DashboardService.SubordinatesOtStatPerPersonParams): __Observable<OtSubordinatesStatByCategoryPaginationResult> {
    return this.SubordinatesOtStatPerPersonResponse(params).pipe(
      __map(_r => _r.body as OtSubordinatesStatByCategoryPaginationResult)
    );
  }
}

module DashboardService {

  /**
   * Parameters for PersonalTimeAttendanceStat
   */
  export interface PersonalTimeAttendanceStatParams {
    startDate?: string;
    endDate?: string;
  }

  /**
   * Parameters for SubordinatesTimeAttendanceStat
   */
  export interface SubordinatesTimeAttendanceStatParams {
    startDate?: string;
    endDate?: string;
    body?: EmployeePaginationParams;
  }

  /**
   * Parameters for SubordinatesTimeAttendanceLate
   */
  export interface SubordinatesTimeAttendanceLateParams {
    startDate?: string;
    endDate?: string;
    body?: EmployeePaginationParams;
  }

  /**
   * Parameters for SubordinatesTimeAttendanceLeftEarly
   */
  export interface SubordinatesTimeAttendanceLeftEarlyParams {
    startDate?: string;
    endDate?: string;
    body?: EmployeePaginationParams;
  }

  /**
   * Parameters for SubordinatesTimeAttendanceAbsent
   */
  export interface SubordinatesTimeAttendanceAbsentParams {
    startDate?: string;
    endDate?: string;
    body?: EmployeePaginationParams;
  }

  /**
   * Parameters for PersonalOtStat
   */
  export interface PersonalOtStatParams {
    startDate?: string;
    endDate?: string;
  }

  /**
   * Parameters for SubordinatesOtStat
   */
  export interface SubordinatesOtStatParams {
    startDate?: string;
    endDate?: string;
    body?: EmployeePaginationParams;
  }

  /**
   * Parameters for SubordinatesOtStatPerUnit
   */
  export interface SubordinatesOtStatPerUnitParams {
    startDate?: string;
    endDate?: string;
    body?: EmployeePaginationParams;
  }

  /**
   * Parameters for SubordinatesOtStatPerPerson
   */
  export interface SubordinatesOtStatPerPersonParams {
    startDate?: string;
    endDate?: string;
    body?: EmployeePaginationParams;
  }
}

export { DashboardService }
