<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <form [formGroup]="form">
        <formly-form [form]="form" [fields]="fields" [model]="filterModel"></formly-form>
      </form>
      <p-table [value]="workflowList" [loading]="loading" [autoLayout]="true"  [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0">
        <ng-template pTemplate="header">
          <tr>
            <th>{{'no' | translate}}.</th>
            <th>{{'workflow_name' | translate}}</th>
            <th>{{'description' | translate}}</th>
            <th>{{'last_modified_date' | translate}}</th>
            <th>{{'status' | translate}}</th>
            <th style="width: 50px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td>{{ rowData.seq }}</td>
            <td>{{ rowData.name }}</td>
            <td>{{ rowData.description }}</td>
            <td>{{ rowData.updatedDate | date: 'dd/MM/yyyy' }}</td>
            <td>
              <div class="grid justify-content-around">
                <div
                  class="col-fixed status-badge"
                  [ngClass]="{
                    'status-active': rowData.status === 'Active',
                    'status-inactive': rowData.status === 'InActive'
                  }"
                >
                  {{ rowData.statusName }}
                </div>
              </div>
            </td>
            <td>
              <div class="grid justify-content-around">
                <div class="col-fixed">
                  <button pButton icon="fa fa-plus" (click)="selectWorkflow(rowData)"></button>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6" class="emptymessage">{{'no_data' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator
        [rows]="paginationParams.rowPerPage"
        [first]="paginationParams.firstRowIdx"
        [totalRecords]="paginationParams.totalRecords"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="paginationParams.rowPerPageSelectList"
        currentPageReportTemplate="{first} - {last} of {totalRecords} Results"
        (onPageChange)="onPageChange($event)"
      >
      </p-paginator>
    </div>
  </div>
</div>
