/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeNameModel } from '../models/employee-name-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeNameService extends __BaseService {
  static readonly GetEmployeeNamesListPath = '/api/EmployeeName';
  static readonly CreateEmployeeNamePath = '/api/EmployeeName';
  static readonly UpdateEmployeeNamePath = '/api/EmployeeName';
  static readonly GetEmployeeNameByIdPath = '/api/EmployeeName/{EmployeeNameId}';
  static readonly DeleteEmployeeNamePath = '/api/EmployeeName/{EmployeeNameId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeNamesListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeNameModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeNameModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeNamesList(): __Observable<Array<EmployeeNameModel>> {
    return this.GetEmployeeNamesListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeNameModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeNameResponse(body?: EmployeeNameModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeName(body?: EmployeeNameModel): __Observable<string> {
    return this.CreateEmployeeNameResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeNameResponse(body?: EmployeeNameModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeName(body?: EmployeeNameModel): __Observable<string> {
    return this.UpdateEmployeeNameResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeNameId undefined
   * @return Success
   */
  GetEmployeeNameByIdResponse(EmployeeNameId: number): __Observable<__StrictHttpResponse<EmployeeNameModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeName/${encodeURIComponent(String(EmployeeNameId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeNameModel>;
      })
    );
  }
  /**
   * @param EmployeeNameId undefined
   * @return Success
   */
  GetEmployeeNameById(EmployeeNameId: number): __Observable<EmployeeNameModel> {
    return this.GetEmployeeNameByIdResponse(EmployeeNameId).pipe(
      __map(_r => _r.body as EmployeeNameModel)
    );
  }

  /**
   * @param EmployeeNameId undefined
   * @return Success
   */
  DeleteEmployeeNameResponse(EmployeeNameId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeName/${encodeURIComponent(String(EmployeeNameId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeNameId undefined
   * @return Success
   */
  DeleteEmployeeName(EmployeeNameId: number): __Observable<string> {
    return this.DeleteEmployeeNameResponse(EmployeeNameId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeNameService {
}

export { EmployeeNameService }
