<div class="grid">
  <div class="col-12">
    <div class="card card-w-title">
      <div class="grid justify-content-between">
        <div class="col"></div>
        <div class="col-fixed" *ngIf="isVisibleByPermission">
          <p-splitButton #EmployeeResignsBtn label="จัดการข้อมูลพนักงาน"
            (onClick)="EmployeeResignsBtn.onDropdownButtonClick($event)" [model]="EmployeeResignsButtonItems">
          </p-splitButton>
      </div>
      <hr style="margin-top: 5px; margin-bottom: 20px" />
      <div class="grid">
        <div class="col-3">
          <div class="card" style="text-align: center">
            <img
              *ngIf="employee.employeeId"
              class="tw-mx-auto"
              [src]="getLinkProfileImage()"
              alt="mirage-layout"
              width="100%"
              style="max-width: 200px"
            />
            <div>
              <b>{{ employee.fullnameTh }} ({{ employee.nicknameTh }})</b>
            </div>
            <div>
              <b>{{ employee.employeeCode }}</b>
            </div>
            <div *ngIf="isOrWillBeResignedEmployee">
              <b>วันที่ลาออก(หรือจะลาออก): {{ employee.terminatedDate | date: 'dd/MM/yyyy' }}</b>
            </div>
            <div class="col-fixed" *ngIf="isOrWillBeResignedEmployee">
              <button pButton type="button" style="background-color: #eb4d4b;border: red;" label="ยกเลิกการลาออก" (click)="CancelTerminate()"></button>
            </div>
          </div>
          <div class="card mt-2" *ngIf="isEditable">
            <p-fileUpload
              #profileImg
              mode="advanced"
              [multiple]="false"
              [maxFileSize]="26214400"
              [showCancelButton]="false"
              [showCancelButton]="false"
              [fileLimit]="1"
              chooseLabel="เลือกรูป"
              uploadLabel="อัพโหลด"
              accept="image/*"
              [customUpload]="true"
              (uploadHandler)="onUploadHandler($event)"
            >
              <ng-template pTemplate="file">
                <div class="p-fileupload-row" *ngFor="let file of profileImg.files">
                  <div>
                    <img [src]="file['objectURL']" width="140px" />
                  </div>
                  <div>
                    <button
                      pButton
                      type="button"
                      icon="pi pi-times"
                      (click)="profileImg.remove($event, 0)"
                      [disabled]="profileImg.uploading"
                    ></button>
                  </div>
                </div>
              </ng-template>
            </p-fileUpload>
          </div>
          <div class="card company-info mt-2">
            <div class="grid">
              <div class="col-12">
                <div class="card shadow-1">
                  <div><i class="fas fa-map-marker-alt"></i></div>
                  <b>{{ employee.locationName }}</b>
                </div>
              </div>
              <div class="col-12">
                <div class="card shadow-1">
                  <div><i class="far fa-building"></i></div>
                  <b>{{ employee.companyName }}</b>
                </div>
              </div>
            </div>
            <div class="grid">
              <div class="col-12">
                <div class="card shadow-1">
                  <div><i class="fas fa-suitcase"></i></div>
                  <b>{{ employee.unitName }}</b>
                </div>
              </div>
            </div>
            <div class="grid">
              <div class="col-12">
                <div class="card shadow-1">
                  <div><i class="fas fa-folder"></i></div>
                  <b>{{ employee.positionName }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-2">
            <h1>ข้อมูลติดต่อ</h1>
            <div class="col-12">
              <i class="fas fa-envelope" style="margin-right: 0.5vw"></i>
              <b>{{ 'working_email' | translate }}</b>
              <div>
                <span>{{ employee.email || 'N/A' }}</span>
              </div>
            </div>
            <div class="col-12">
              <i class="fas fa-phone-alt" style="margin-right: 0.5vw"></i>
              <b>{{ 'internal_phone_number' | translate }}</b>
              <div>
                <span>{{ employee.phone || 'N/A' }}</span>
              </div>
            </div>
            <div class="col-12">
              <i class="fas fa-mobile" style="margin-right: 0.5vw"></i>
              <b>{{ 'personal_phone_number' | translate }}</b>
              <div>
                <span>{{ employee.mobile || 'N/A' }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9 employee-tab">
          <div *ngFor="let item of items" class="d-in-block">
            <p-button [icon]="item.icon" styleClass="tab-button" [routerLink]="item.routerLink" [pTooltip]="item.title"></p-button>
          </div>
          <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
