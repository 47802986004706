import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmployeeEducationModel } from 'app/api/models';
import { EmployeeService, EmployeeEducationService } from 'app/api/services';
import { ModalEmployeeEducationComponent } from 'modals/core/modal-employee-education/modal-employee-education.component';

@Component({
  selector: 'app-educations',
  templateUrl: './educations.component.html',
  styleUrls: ['./educations.component.css'],
  providers: [DialogService],
})
export class EducationsComponent implements OnInit {
  isEditable = false;
  educationList: EmployeeEducationModel[];
  ref: DynamicDialogRef;

  constructor(
    private router: Router,
    private notificationService: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeService: EmployeeService,
    private employeeEducationService: EmployeeEducationService
  ) { }

  ngOnInit() {
    this.setEditablePermission();
    this.setEducationList();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setEducationList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetEducations(employeeId).subscribe(educationList => {
      this.educationList = educationList;
    });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  showCreateEmployeeEducationDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่มข้อมูลการศึกษา', ModalEmployeeEducationComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setEducationList();
      }
    });
  }

  showUpdateEmployeeEducationDialog(row: EmployeeEducationModel) {
    this.showDialog({ id: row.employeeEducationId }, 'แก้ไขข้อมูลการศึกษา', ModalEmployeeEducationComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setEducationList();
      }
    });
  }

  showDeleteEmployeeEducationDialog(row: EmployeeEducationModel) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeeEducationService.DeleteEmployeeEducation(row.employeeEducationId).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.setEducationList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: '75%',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }
}
