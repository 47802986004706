/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';

@Injectable()
export class LoadingService {
  private isLoading$$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading$$.asObservable().pipe(debounceTime(500));

  setLoading(isLoading: boolean) {
    if (isLoading) {
      this.isLoading$$.next(isLoading);
    } else {
      this.isLoading$$.next(isLoading);
    }
  }
}
