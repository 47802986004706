/* tslint:disable */
type EJobGradeLevel =
  'Labor' |
  'Operation' |
  'Supervisor' |
  'Management' |
  'Executive' |
  'Shareholder';
module EJobGradeLevel {
  export const LABOR: EJobGradeLevel = 'Labor';
  export const OPERATION: EJobGradeLevel = 'Operation';
  export const SUPERVISOR: EJobGradeLevel = 'Supervisor';
  export const MANAGEMENT: EJobGradeLevel = 'Management';
  export const EXECUTIVE: EJobGradeLevel = 'Executive';
  export const SHAREHOLDER: EJobGradeLevel = 'Shareholder';
  export function values(): EJobGradeLevel[] {
    return [
      LABOR,
      OPERATION,
      SUPERVISOR,
      MANAGEMENT,
      EXECUTIVE,
      SHAREHOLDER
    ];
  }
}

export { EJobGradeLevel }