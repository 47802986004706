import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeInsigniaModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeInsigniaService } from 'app/api/services';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';


enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeInsigniaModel;
}

@Component({
  selector: 'app-modal-employee-insignia',
  templateUrl: './modal-employee-insignia.component.html',
  styleUrls: ['./modal-employee-insignia.component.scss']
})
export class ModalEmployeeInsigniaComponent implements OnInit {

  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeInsigniaService: EmployeeInsigniaService,
  ) {}

  model: EmployeeInsigniaModel = {};
  form = new FormGroup({});

  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'insigniaName',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'ชื่อเครื่องราชอิสริยาภรณ์',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 300,
      },
    },
    {
      key: 'year',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ปีที่ได้รับ',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        type: 'number',
        min:0,
      },
    },
    {
      key: 'remark',
      type: 'textarea',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'หมายเหตุ:',
        labelClass: 'col-4',
        inputClass: 'col-6',
        placeholder: '@blank'
      },
    },
  ];

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
  }

  initComponentMode() {
    const employeeRegaliaId = this.getEmployeeRegaliaId();

    if (employeeRegaliaId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };
      return;
    }

    const res = await this.employeeInsigniaService
      .GetEmployeeInsigniaById(this.getEmployeeRegaliaId())
      .toPromise();

    this.model = {
      ...res
    };
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.employeeInsigniaService
          .CreateEmployeeInsignia(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.employeeInsigniaService
          .UpdateEmployeeInsignia({
            EmployeeInsigniaId: this.model.employeeInsigniaId,
            body: this.model,
          })
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getEmployeeRegaliaId() {
    return this.config.data?.id;
  }

}
