import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RoleModel, PermissionModel } from 'app/api/models';
import { RolePermissionModel } from 'app/api/models/role-permission-model';
import { PermissionService, RoleService } from 'app/api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { MasterDataRepo } from 'app/repo/master-data.repo';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  READ = 'READ',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof RoleModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

interface PermissionOptionModel extends PermissionModel {
  isPermitted: boolean;
}

@Component({
  selector: 'app-modal-role',
  templateUrl: './modal-role.component.html',
  styleUrls: ['./modal-role.component.css'],
})
export class ModalRoleComponent implements OnInit {
  model: RoleModel;
  form = new FormGroup({});
  options: FormlyFormOptions = {};
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      expressionProperties: {
        'templateOptions.disabled': 'formState.disabled',
      },
      templateOptions: {
        translate: true,
        required: true,

        label: 'license_name',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 128,
      },
    },
    {
      key: 'roleType',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      expressionProperties: {
        'templateOptions.disabled': 'formState.disabled',
      },
      templateOptions: {
        translate: true,
        required: true,

        label: 'type_of_r',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',

        options: [
          { label: 'Employee', value: 'Employee' },
          { label: 'Admin', value: 'Admin' },
        ],
      },
    },
    {
      key: 'description',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      expressionProperties: {
        'templateOptions.disabled': 'formState.disabled',
      },
      templateOptions: {
        translate: true,
        label: 'description',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 2048,
      },
    },
  ];
  componentMode: ComponentMode;
  permissionList: PermissionOptionModel[] = [];

  constructor(
    private config: DynamicDialogConfig,
    private roleService: RoleService,
    private notificationService: AppNotificationServiceService,
    private ref: DynamicDialogRef,
    private permissionService: PermissionService,
    private masterDataRepo: MasterDataRepo,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initPermissionList();
    await this.initData();
    this.disableForm();
  }

  initComponentMode() {
    const id = this.config.data?.id;
    const mode = this.config.data?.mode;

    if (id) {
      if (mode === 'READ') {
        this.componentMode = ComponentMode.READ;
      } else {
        this.componentMode = ComponentMode.UPDATE;
      }
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {};
      return;
    }

    const role = await this.roleService.GetRole(this.config.data?.id).toPromise();
    this.model = role;

    this.permissionList = this.permissionList.map(p => {
      return {
        ...p,
        isPermitted: role.rolePermissions.map(a => a.permission).includes(p.name),
      };
    });

    this.model.rolePermissions = this.permissionList.map(p => {
      return {
        overrideValue: true,
        permission: p.name,
        roleId: role.roleId
      };
    });

  }

  async initPermissionList() {
    this.permissionList = (await this.permissionService.GetAllPermissions().toPromise()).map(p => {
      return {
        ...p,
        isPermitted: false,
      };
    });
  }

  disableForm() {
    if (this.componentMode === ComponentMode.READ) {
      this.options = {
        formState: {
          disabled: true,
        },
      };
    }
  }

  submit() {
    const data = this.buildSaveData();

    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.roleService
          .CreateRole(data)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(data);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.roleService
          .UpdateRole(data)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(data);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  buildSaveData(): RoleModel {
    return {
      ...this.model,
      rolePermissions: this.permissionList
        .filter(p => p.isPermitted)
        .map<RolePermissionModel>(p => {
          return {
            roleId: this.model.roleId,
            permission: p.name,
            overrideValue:true
          };
        }),
    };
  }

  close() {
    this.ref.close();
  }
}
