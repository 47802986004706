<p-table [value]="employeeSumBenefitList" [loading]="loading" [autoLayout]="true"  [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0">
  <ng-template pTemplate="header">
    <tr>
      <th>ประเภทการลา</th>
      <th>วันที่มีผล</th>
      <th>โควตาทั้งหมด</th>
      <th>โควตาใช้แล้ว</th>
      <th>โควตาคงเหลือ</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowdata>
    <tr>
      <td>{{ rowdata.benefitElementName }}</td>
      <td class="text-center">{{ rowdata.effectiveDate }}</td>
      <td>{{ rowdata.totalQuota | dayHourFormat }}</td>
      <td>{{ rowdata.quotaUsed | dayHourFormat }}</td>
      <td>{{ rowdata.quotaBal | dayHourFormat }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="9" class="emptymessage">{{"no_data" | translate}}</td>
    </tr>
  </ng-template>
</p-table>

<div class="p-dialog-footer">
  <button type="button" pButton class="p-button-secondary" label="ปิดหน้าต่าง" (click)="close()"></button>
</div>
