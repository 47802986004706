/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserListItemModelPaginationResult } from '../models/user-list-item-model-pagination-result';
import { UserModel } from '../models/user-model';
import { ResetPasswordModel } from '../models/reset-password-model';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly GetUsersPath = '/api/User';
  static readonly AddUserPath = '/api/User';
  static readonly UpdateUserPath = '/api/User';
  static readonly GetUserPath = '/api/User/{userId}';
  static readonly DeleteUserPath = '/api/User/{userId}';
  static readonly GetUserIdByGoogleIdPath = '/api/User/googleId/{googleId}';
  static readonly ResetPasswordPath = '/api/User/resetpassword';
  static readonly ResetPasswordToBirthdayPath = '/api/User/resetpassword/{employeeId}';
  static readonly LinkGooglePath = '/api/User/link-google';
  static readonly GetUserImagePath = '/api/User/{userId}/image';
  static readonly GetCurrentUserPath = '/api/User/current';
  static readonly GetCurrentUserPermissionsPath = '/api/User/current/permissions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UserService.GetUsersParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RoleId`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetUsersResponse(params: UserService.GetUsersParams): __Observable<__StrictHttpResponse<UserListItemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RoleId != null) __params = __params.set('RoleId', params.RoleId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserListItemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `UserService.GetUsersParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RoleId`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetUsers(params: UserService.GetUsersParams): __Observable<UserListItemModelPaginationResult> {
    return this.GetUsersResponse(params).pipe(
      __map(_r => _r.body as UserListItemModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  AddUserResponse(body?: UserModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  AddUser(body?: UserModel): __Observable<number> {
    return this.AddUserResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateUserResponse(body?: UserModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateUser(body?: UserModel): __Observable<string> {
    return this.UpdateUserResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param userId undefined
   * @return Success
   */
  GetUserResponse(userId: number): __Observable<__StrictHttpResponse<UserModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/${encodeURIComponent(String(userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserModel>;
      })
    );
  }
  /**
   * @param userId undefined
   * @return Success
   */
  GetUser(userId: number): __Observable<UserModel> {
    return this.GetUserResponse(userId).pipe(
      __map(_r => _r.body as UserModel)
    );
  }

  /**
   * @param userId undefined
   * @return Success
   */
  DeleteUserResponse(userId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/User/${encodeURIComponent(String(userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param userId undefined
   * @return Success
   */
  DeleteUser(userId: number): __Observable<string> {
    return this.DeleteUserResponse(userId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param googleId undefined
   * @return Success
   */
  GetUserIdByGoogleIdResponse(googleId: string): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/googleId/${encodeURIComponent(String(googleId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param googleId undefined
   * @return Success
   */
  GetUserIdByGoogleId(googleId: string): __Observable<number> {
    return this.GetUserIdByGoogleIdResponse(googleId).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ResetPasswordResponse(body?: ResetPasswordModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/resetpassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ResetPassword(body?: ResetPasswordModel): __Observable<string> {
    return this.ResetPasswordResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  ResetPasswordToBirthdayResponse(employeeId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/resetpassword/${encodeURIComponent(String(employeeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  ResetPasswordToBirthday(employeeId: number): __Observable<string> {
    return this.ResetPasswordToBirthdayResponse(employeeId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param googleId undefined
   * @return Success
   */
  LinkGoogleResponse(googleId?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (googleId != null) __params = __params.set('googleId', googleId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/link-google`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param googleId undefined
   * @return Success
   */
  LinkGoogle(googleId?: string): __Observable<string> {
    return this.LinkGoogleResponse(googleId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param userId undefined
   */
  GetUserImageResponse(userId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/${encodeURIComponent(String(userId))}/image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param userId undefined
   */
  GetUserImage(userId: number): __Observable<null> {
    return this.GetUserImageResponse(userId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  GetCurrentUserResponse(): __Observable<__StrictHttpResponse<UserModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/current`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserModel>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCurrentUser(): __Observable<UserModel> {
    return this.GetCurrentUserResponse().pipe(
      __map(_r => _r.body as UserModel)
    );
  }

  /**
   * @param loginType undefined
   * @return Success
   */
  GetCurrentUserPermissionsResponse(loginType?: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (loginType != null) __params = __params.set('loginType', loginType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/current/permissions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param loginType undefined
   * @return Success
   */
  GetCurrentUserPermissions(loginType?: string): __Observable<Array<string>> {
    return this.GetCurrentUserPermissionsResponse(loginType).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }
}

module UserService {

  /**
   * Parameters for GetUsers
   */
  export interface GetUsersParams {
    Sorting?: string;
    SearchText?: string;
    RoleId?: number;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { UserService }
