import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize, map } from 'rxjs/operators';
import { BenefitElementModel } from 'app/api/models';
import { BenefitElementService, DocumentService, MasterDataService } from 'app/api/services';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface BenefitElementFormModel extends BenefitElementModel {
  isActive?: boolean;
  benefitElementDocumentId?: number;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof BenefitElementFormModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

interface SelectFileParam {
  currentFiles: File[];
}

@Component({
  selector: 'app-modal-benefit-element',
  templateUrl: './modal-benefit-element.component.html',
  styleUrls: ['./modal-benefit-element.component.css'],
})
export class ModalBenefitElementComponent implements OnInit {
  componentMode: ComponentMode;
  model: BenefitElementFormModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'code',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'code',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-4',
        maxLength: 32,
      },
    },
    {
      key: 'benefitQuotaTypeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        change: async (field, event) => {
          var options = await this.masterDataService.GetBenefitQuotaSubTypes(this.model.benefitQuotaTypeId)
            .pipe(map(a => a.map(b => ({ label: b.name, value: b.key }))))
            .toPromise();

          this.fields.find(a => a.key === 'benefitQuotaSubTypeId').templateOptions.options = options;
        },
        translate: true,
        label: 'quota_type',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: true,
      },
    },
    {
      key: 'benefitQuotaSubTypeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ประเภทโควตาย่อย',
        placeholder: 'เว้นว่างถ้าไม่ต้องการระบุ',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: false,
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,

        label: 'item_name',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: true,
        maxLength: 128,
      },
    },
    {
      key: 'isYearly',
      type: 'switch-input',
      defaultValue: false,
      templateOptions: {
        translate: true,
        required: false,
        label: 'give_one_time',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        trueMessage: 'Yes',
        falseMessage: 'No',
        tooltip: 'Yes: คำนวณรายปี\nNo: ให้ครั้งเดียว',
      },
    },
    {
      key: 'isIncludeHoliday',
      type: 'switch-input',
      defaultValue: false,
      templateOptions: {
        translate: true,
        required: false,
        placeholder: '@blank',
        label: 'holiday_included',
        labelClass: 'col-4',
        inputClass: 'col-6',
        trueMessage: 'Yes',
        falseMessage: 'No',
        tooltip: 'วันลาคลอด 90 วันจะรวมวันหยุดด้วย',
      },
    },
    {
      key: 'isPay',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        // translate: true,
        // required: true,
        label: 'จ่ายค่าจ้างในวันลาหรือไม่',
        labelClass: 'col-4',
        inputClass: 'col-6',
        trueMessage: 'จ่าย',
        falseMessage: 'ไม่จ่าย',
      },
    },
    {
      key: 'description',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: false,
        label: 'description',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 2048,
      },
    },
    {
      key: 'benefitElementDocumentId',
      type: 'upload-file',
      templateOptions: {
        translate: true,
        required: false,
        label: 'attach_file',
        chooseLabel: 'อัพโหลด',
        previewWidth: 150,
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
        onSelect: (selectFileParam: SelectFileParam) => {
          this.documentService
            .CreateDocument({
              description: 'Benefit Element',
              file: selectFileParam.currentFiles[0],
            })
            .subscribe(result => {
              this.model.benefitElementDocument = {
                documentId: result.documentId,
              };
            });
        },
        onRemove: (_, model: BenefitElementModel) => {
          model.benefitElementDocument = undefined;
        },
      },
    },
    {
      key: 'isActive',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        translate: true,
        required: false,
        placeholder: '@blank',
        label: 'status',
        labelClass: 'col-4',
        inputClass: 'col-6',
        trueMessage: 'กำลังใช้งาน',
        falseMessage: 'ระงับใช้งาน',
      },
    },
  ];

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private benefitElementService: BenefitElementService,
    private documentService: DocumentService,
    private masterDataRepo: MasterDataRepo,
    private masterDataService: MasterDataService
  ) { }

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  initComponentMode() {
    const benefitElementId = this.getBenefitElementId();

    if (benefitElementId) {
      this.componentMode = ComponentMode.UPDATE;
      //this.fields.find(a => a.key === 'isYearly').templateOptions.disabled = true;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {};
      return;
    }

    const benefitElement = await this.benefitElementService
      .GetBenefitElementById(this.getBenefitElementId())
      .toPromise();
    this.model = {
      ...benefitElement,
      benefitElementDocumentId: benefitElement.activeBenefitElementDocument?.documentId,
      isActive: benefitElement.status === 'Active',
    };
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'benefitQuotaTypeId').templateOptions.options = await this.masterDataRepo
      .getBenefitQuotaTypesOptionList()
      .toPromise();
  }

  submit() {
    console.log(this.model);

    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createBenefitElement();
        break;
      case ComponentMode.UPDATE:
        this.updateBenefitElement();
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createBenefitElement() {
    this.benefitElementService
      .CreateBenefitElement({
        ...this.model,
        status: this.model.isActive ? 'Active' : 'InActive',
      })
      .subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.ref.close(this.model);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  private updateBenefitElement() {
    this.benefitElementService
      .UpdateBenefitElement({
        ...this.model,
        status: this.model.isActive ? 'Active' : 'InActive',
      })
      .subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.ref.close(this.model);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  getBenefitElementId() {
    return +this.config.data?.id;
  }
}
