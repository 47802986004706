/* tslint:disable */
type UnitType =
  'Pixel' |
  'Point' |
  'Pica' |
  'Inch' |
  'Mm' |
  'Cm' |
  'Percentage' |
  'Em' |
  'Ex';
module UnitType {
  export const PIXEL: UnitType = 'Pixel';
  export const POINT: UnitType = 'Point';
  export const PICA: UnitType = 'Pica';
  export const INCH: UnitType = 'Inch';
  export const MM: UnitType = 'Mm';
  export const CM: UnitType = 'Cm';
  export const PERCENTAGE: UnitType = 'Percentage';
  export const EM: UnitType = 'Em';
  export const EX: UnitType = 'Ex';
  export function values(): UnitType[] {
    return [
      PIXEL,
      POINT,
      PICA,
      INCH,
      MM,
      CM,
      PERCENTAGE,
      EM,
      EX
    ];
  }
}

export { UnitType }