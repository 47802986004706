import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { SharedModule } from '../../shared/shared.module';
import { ModalCompanyComponent } from './modal-company/modal-company.component';
import { ModalCostCentersComponent } from './modal-cost-centers/modal-cost-centers.component';
import { ModalCountriesComponent } from './modal-countries/modal-countries.component';
import { ModalEmployeeAttachmentComponent } from './modal-employee-attachment/modal-employee-attachment.component';
import { ModalEmployeeBankAccountComponent } from './modal-employee-bank-account/modal-employee-bank-account.component';
import { ModalEmployeeCardComponent } from './modal-employee-card/modal-employee-card.component';
import { ModalEmployeeCertificateComponent } from './modal-employee-certificate/modal-employee-certificate.component';
import { ModalEmployeeChronicDiseaseComponent } from './modal-employee-chronic-disease/modal-employee-chronic-disease.component';
import { ModalEmployeeDrugAllergyComponent } from './modal-employee-drug-allergy/modal-employee-drug-allergy.component';
import { ModalEmployeeEducationComponent } from './modal-employee-education/modal-employee-education.component';
import { ModalEmployeeHealthCheckComponent } from './modal-employee-health-check/modal-employee-health-check.component';
import { ModalEmployeeInPositionComponent } from './modal-employee-in-position/modal-employee-in-position.component';
import { ModalEmployeeInShiftComponent } from './modal-employee-in-shift/modal-employee-in-shift.component';
import { ModalEmployeeInterestComponent } from './modal-employee-interest/modal-employee-interest.component';
import { ModalEmployeeNameComponent } from './modal-employee-name/modal-employee-name.component';
import { ModalEmployeePastEmploymentComponent } from './modal-employee-past-employment/modal-employee-past-employment.component';
import { ModalEmployeePunishmentComponent } from './modal-employee-punishment/modal-employee-punishment.component';
import { ModalEmployeeSalaryComponent } from './modal-employee-salary/modal-employee-salary.component';
import { ModalEmployeeSearchComponent } from './modal-employee-search/modal-employee-search.component';
import { ModalEmployeeSurgeryComponent } from './modal-employee-surgery/modal-employee-surgery.component';
import { ModalEmployeeWorkPermitComponent } from './modal-employee-work-permit/modal-employee-work-permit.component';
import { ModalJobFamiliesComponent } from './modal-job-families/modal-job-families.component';
import { ModalJobGradeOfSystemComponent } from './modal-job-grade-of-system/modal-job-grade-of-system.component';
import { ModalJobGradesComponent } from './modal-job-grades/modal-job-grades.component';
import { ModalLocationsComponent } from './modal-locations/modal-locations.component';
import { ModalPersonnelAreaComponent } from './modal-personnel-area/modal-personnel-area.component';
import { ModalPositionSearchComponent } from './modal-position-search/modal-position-search.component';
import { ModalPositionComponent } from './modal-position/modal-position.component';
import { ModalUnitLevelOfSystemComponent } from './modal-unit-level-of-system/modal-unit-level-of-system.component';
import { ModalUnitLevelsComponent } from './modal-unit-levels/modal-unit-levels.component';
import { ModalUnitsComponent } from './modal-units/modal-units.component';
import { ModalEmployeeImportComponent } from './modal-employee-import/modal-employee-import.component';
import { ModalWorkflowSearchComponent } from './modal-workflow-search/modal-workflow-search.component';
import { ModalUnitSearchComponent } from './modal-unit-search/modal-unit-search.component';
import { ModalPersonnelAreaSearchComponent } from './modal-personnel-area-search/modal-personnel-area-search.component';
import { ModalTagComponent } from './modal-tag/modal-tag.component';
import { ModalWorkflowConditionComponent } from './modal-workflow-condition/modal-workflow-condition.component';
import { ModalEmployeeSignUpComponent } from './modal-employee-sign-up/modal-employee-sign-up.component';

import { QRCodeModule } from 'angularx-qrcode';
import { ModalEmployeeResignsComponent } from './modal-employee-resigns/modal-employee-resigns/modal-employee-resigns.component';
import { ModalBranchComponent } from './modal-branch/modal-branch.component';
import { ModalGlCodeComponent } from './modal-gl-code/modal-gl-code.component';
import { EmployeeBaseComponentsModule } from '../../components/core/employee-base-component.module';
import { ModalTransferInCompanyComponent } from './modal-transfer-in-company/modal-transfer-in-company.component';
import { ModalViewShiftdetailComponent } from './modal-view-shiftdetail/modal-view-shiftdetail.component';
import { ModalTranferCompanyComponent } from './modal-tranfer-company/modal-tranfer-company.component';
import { ModalEmployeeTrainingComponent } from './modal-employee-training/modal-employee-training.component';
import { ModalEmployeeInsigniaComponent } from './modal-employee-insignia/modal-employee-insignia.component';

const allModals = [
  ModalLocationsComponent,
  ModalCostCentersComponent,
  ModalCountriesComponent,
  ModalJobGradesComponent,
  ModalJobFamiliesComponent,
  ModalUnitLevelsComponent,
  ModalUnitsComponent,
  ModalJobGradeOfSystemComponent,
  ModalUnitLevelOfSystemComponent,
  ModalPositionComponent,
  ModalEmployeeSearchComponent,
  ModalPersonnelAreaComponent,
  ModalCompanyComponent,
  ModalEmployeeCardComponent,
  ModalEmployeeNameComponent,
  ModalEmployeeWorkPermitComponent,
  ModalEmployeeBankAccountComponent,
  ModalEmployeeInPositionComponent,
  ModalEmployeePastEmploymentComponent,
  ModalEmployeeSalaryComponent,
  ModalEmployeeChronicDiseaseComponent,
  ModalEmployeeDrugAllergyComponent,
  ModalEmployeeCertificateComponent,
  ModalEmployeePunishmentComponent,
  ModalEmployeeEducationComponent,
  ModalEmployeeInterestComponent,
  ModalPositionSearchComponent,
  ModalEmployeeSurgeryComponent,
  ModalEmployeeAttachmentComponent,
  ModalEmployeeHealthCheckComponent,
  ModalEmployeeInShiftComponent,
  ModalEmployeeImportComponent,
  ModalWorkflowSearchComponent,
  ModalUnitSearchComponent,
  ModalPersonnelAreaSearchComponent,
  ModalTagComponent,
  ModalWorkflowConditionComponent,
  ModalEmployeeSignUpComponent,
  ModalGlCodeComponent,
  ModalEmployeeResignsComponent,
  ModalBranchComponent,
  ModalTransferInCompanyComponent,
  ModalViewShiftdetailComponent,
  ModalEmployeeTrainingComponent
];

@NgModule({
  declarations: [...allModals, ModalTranferCompanyComponent, ModalEmployeeInsigniaComponent],
  imports: [CommonModule, DynamicDialogModule, SharedModule, QRCodeModule, EmployeeBaseComponentsModule],
  exports: [...allModals]
})
export class CoreModule { }
