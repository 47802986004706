import { Injectable, ChangeDetectorRef } from "@angular/core";
import { SystemService } from "../api/services";
import { first, shareReplay, tap } from "rxjs/operators";
import { Observable, of } from 'rxjs';
import { ApplicationSettingsExtended } from "../api/models";

@Injectable({
  providedIn: "root",
})
export class ApplicationSettingsService {
  getApplicationSettingsCache$: Observable<ApplicationSettingsExtended> = null;
  settings: ApplicationSettingsExtended = null;

  constructor(
    private systemService: SystemService
  ) {
  }

  getApplicationSettings(): Observable<ApplicationSettingsExtended> {
    if(this.settings){
      return of(this.settings);
    } else {
      if (!this.getApplicationSettingsCache$) {
        this.getApplicationSettingsCache$ = this.systemService
          .GetApplicationSettingsExtended()
          .pipe(
            tap(a => {this.settings = a;}),
            shareReplay(1)
          );
      }
      return this.getApplicationSettingsCache$;
    }
  }
}
