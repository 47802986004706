import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { OTModel } from 'app/api/models';
import { OTRequestService, OTService } from 'app/api/services';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'services/authentication.service';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

enum Message {
  CONFIRM_CANCEL = 'ยืนยันการยกเลิกคำขอค่าล่วงเวลา',
  CANCEL_SUCCESS = 'ยกเลิกคำขอค่าล่วงเวลาสำเร็จ',
  CANCEL_FAILED = 'ยกเลิกคำขอค่าล่วงเวลาล้มเหลว',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof OTModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-modal-ot-request',
  templateUrl: './modal-ot-request.component.html',
  styleUrls: ['./modal-ot-request.component.css'],
})
export class ModalOtRequestComponent implements OnInit {
  model: OTModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'beginDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'วันที่',
        labelClass: 'col-3',
        inputClass: 'col-3',
        disabled: true,
      },
    },
    {
      key: 'beginTime',
      type: 'time-picker',
      defaultValue: '00:00',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เวลาเริ่มต้น',
        labelClass: 'col-3',
        inputClass: 'col-3',
        disabled: true,
      },
    },
    {
      key: 'endTime',
      type: 'time-picker',
      defaultValue: '00:00',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เวลาสิ้นสุด',
        labelClass: 'col-3',
        inputClass: 'col-3',
        disabled: true,
      },
    },
    {
      key: 'otTypeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ระบุต้นทุน',
        labelClass: 'col-3',
        inputClass: 'col-3',
        disabled: true,
        options: this.masterDataRepo.geOTTypeOptionList(),
      },
    },
    {
      key: 'soNumber',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เลข SO',
        labelClass: 'col-3',
        inputClass: 'col-4',
        disabled: true,
      },
    },
    {
      key: 'remark',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เหตุผลที่ขอโอที',
        labelClass: 'col-3',
        inputClass: 'col-6',
        disabled: true,
        required: true
      },
    },
  ];
  employeeId: number;
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private masterDataRepo: MasterDataRepo,
    private otService: OTService,
    private otRequestService: OTRequestService,
    private notification: AppNotificationServiceService,
    private authService: AuthenticationService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.initComponentMode();
    await this.setOTData();
  }

  initComponentMode() {
    this.componentMode = ComponentMode.UPDATE;
  }

  async setOTData() {
    const itemId = this.getOTItemId();
    this.model = await this.otService.GetOTById(itemId).toPromise();
  }

  getOTItemId() {
    return +this.config.data?.id;
  }

  get isCanCancel(): boolean {
    const currentUserEmployeeId = this.authService.getUserInfo()?.employeeId;
    const isCurrentUserCanCancel = this.model.directBossEmployeeIds.includes(currentUserEmployeeId);
    return this.model.isCanCanceled && isCurrentUserCanCancel;
  }

  onCancelClicked() {
    this.notification.confirm(Message.CONFIRM_CANCEL, "").then((result: any) => {
      if (result.value) {
        this.otRequestService.CancelOTRequest(this.model.otRequestId).subscribe(
          () => {
            this.notification.success(Message.CANCEL_SUCCESS, "");
            this.close(true);
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notification.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  close(isValueChanged: boolean) {
    this.ref.close(isValueChanged);
  }
}
