<div *ngFor="let afield of field.fieldGroup; let i = index" class="grid">
  <div class="col-11">
    <formly-field [field]="afield"></formly-field>
  </div>
  <div class="col-1" *ngIf="to?.canRemove && !formState.disabled">
    <i class="fa fa-trash text-remove" (click)="remove(i)"></i>
  </div>
</div>
<!-- ซ่อนไว้ก่อนยังไม่เรียบร้อย
<div class="grid justify-content-end">
  <div class="col-fixed">
    รวมชั่วโมงทำงานทั้งหมด
  </div>
  <div class="col-1 cal-hours">
    {{ to?.sumWorkingTime | number: "1.2-2" }}
  </div>
  <div class="col-fixed">
    ชั่วโมง
  </div>
  <div class="col-fixed">
    รวมชั่วโมงโอทีทั้งหมด
  </div>
  <div class="col-1 cal-hours">
    {{ to?.sumOtTime | number: "1.2-2" }}
  </div>
  <div class="col-fixed">
    ชั่วโมง
  </div>
  <div class="col-1"></div>
</div>
-->
<div class="grid justify-content-end" *ngIf="to?.isDisplayAddBtn && !formState.disabled">
  <div class="col-fixed">
    <button pButton type="button" (click)="add()" label="{{ to?.addText }}"></button>
  </div>
</div>
