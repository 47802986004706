import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, Observable, of } from 'rxjs';
import { UnitLevelService, UnitLevelOfSystemService, BranchService } from 'app/api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { takeUntil, map, finalize, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UnitLevelModel } from 'app/api/models';

import * as moment from 'moment';

interface UnitLevelFormModel extends UnitLevelModel {
  isNotSpecifyTerminateDate?: boolean;
}

interface OptionModel {
  label: string;
  value: number;
}

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'app-unit-level-create',
  templateUrl: './modal-unit-levels.component.html',
  styleUrls: ['./modal-unit-levels.component.css'],
})
export class ModalUnitLevelsComponent implements OnInit, OnDestroy {
  model: UnitLevelFormModel = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'level',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'department_level',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        type: 'number',
        helpMessage: '* เลขน้อยคือหน่วยงานระดับสูง'
      },
    },
    {
      key: 'unitLevelOfSystemId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'central_level',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4',
        // options: this.getUnitLevelOfSystemOption(),
      },
    },

    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'department_level_name',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      defaultValue: moment().format('YYYY-MM-DD'),
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'start_date_user',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
        required: true,
      },
    },
    {
      fieldGroupClassName: 'grid align-center',
      fieldGroup: [
        {
          key: 'terminateDate',
          type: 'calendar',
          className: 'col-8',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'end_use_date',
            placeholder: '@blank',
            labelClass: 'col-6',
            inputClass: 'col',
            required: true,
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.isNotSpecifyTerminateDate',
          },
        },
        {
          key: 'isNotSpecifyTerminateDate',
          type: 'checkbox',
          className: 'col-4',
          wrappers: ['inline-label'],
          defaultValue: true,
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'no_date_specified',
            placeholder: '@blank',
            labelClass: 'col',
          },
        },
      ],
    },
  ];
  unsubscribe$ = new Subject<any>();
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private unitLevelService: UnitLevelService,
    private unitLevelOfSystemService: UnitLevelOfSystemService,
    private notificationService: AppNotificationServiceService,
    private branchService: BranchService,
  ) { }

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const unitLevelId = this.getUnitLevelId();

    if (unitLevelId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'unitLevelOfSystemId').templateOptions.options =
      await this.getUnitLevelOfSystemOption().toPromise();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const companyId = this.config.data?.companyId;
      if (!companyId) {
        this.close(); // FIXME: handle this
      }
      this.model = { companyId };

      return;
    }

    const res = await this.unitLevelService.GetUnitLevelById(this.getUnitLevelId()).toPromise();

    this.model = {
      ...res,
      effectiveDate: (res.effectiveDate),
      terminateDate: (res.terminateDate),
      isNotSpecifyTerminateDate: (res.terminateDate) === '9999-12-31',
    };
  }
  submit() {
    if (this.form.invalid) {
      return;
    }

    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createUnitLevel();
        break;
      case ComponentMode.UPDATE:
        this.updateUnitLevel();
        break;
      default:
        break;
    }
  }


  close() {
    this.ref.close();
  }

  private createUnitLevel() {
    this.unitLevelService
      .CreateUnitLevel({
        companyId: this.model.companyId,
        level: this.model.level,
        unitLevelOfSystemId: this.model.unitLevelOfSystemId,
        name: this.model.name,
        effectiveDate: this.model.effectiveDate,
        terminateDate: this.model.isNotSpecifyTerminateDate ? '9999-12-31' : this.model.terminateDate,
      })
      .subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.ref.close(this.model);

        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }



  private updateUnitLevel() {
    this.unitLevelService
      .UpdateUnitLevel({
        unitLevelId: this.model.unitLevelId,
        companyId: this.model.companyId,
        level: this.model.level,
        unitLevelOfSystemId: this.model.unitLevelOfSystemId,
        name: this.model.name,
        effectiveDate: this.model.effectiveDate,
        terminateDate: this.model.isNotSpecifyTerminateDate ? '9999-12-31' : this.model.terminateDate,
        createdDate: this.model.createdDate,
        createdBy: this.model.createdBy,
        description: this.model.description,
        status: this.model.status,
      })
      .subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.ref.close(this.model);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  private getUnitLevelOfSystemOption(): Observable<OptionModel[]> {
    return this.unitLevelOfSystemService.GetUnitLevelOfSystemsList({}).pipe(
      map(pageResult => {
        return pageResult.results.map(unitLvl => {
          return {
            label: unitLvl.level + ' ' + unitLvl.description,
            value: unitLvl.unitLevelOfSystemId,
          };
        });
      }),
    );
  }

  getUnitLevelId() {
    return this.config.data?.id;
  }

}
