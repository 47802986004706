import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { EmployeeModel, EmployeeListItemModel } from 'app/api/models';
import { EmployeeService } from 'app/api/services';
import { ModalEmployeeSearchComponent } from '../../../modals/core/modal-employee-search/modal-employee-search.component';

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.css'],
})
export class EmployeeCardComponent implements OnInit {
  @Input() employee: EmployeeModel;
  @Input() isShowBtn: boolean;
  @Input() isOnlySubOrdinate = false;
  @Output() selectedEmployeeIdEvent = new EventEmitter<number>();

  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  ref: DynamicDialogRef;

  constructor(private dialogService: DialogService, private employeeService: EmployeeService) { }

  ngOnInit() {
    this.setDefaultEmployee();
  }

  showEmployeeSearchModal() {
    this.ref = this.dialogService.open(ModalEmployeeSearchComponent, {
      header: 'ค้นหาพนักงาน',
      width: '90%',
      contentStyle: { overflow: 'auto' },
      data: {
        isOnlySubOrdinate: this.isOnlySubOrdinate,
      },
    });

    this.ref.onClose.pipe(map(data => data as EmployeeListItemModel)).subscribe(data => {
      if (data) {
        this.selectedEmployeeIdEvent.emit(data.employeeId);
      }
    });
  }

  setDefaultEmployee() {
    if (!this.employee && this.userInfo.enableLoginAsEmployee && !this.userInfo.enableLoginAsAdmin) {
      this.selectedEmployeeIdEvent.emit(this.userInfo.employeeId);
      if (!this.currentUser.isManager) {
        this.isShowBtn = false;
      }
    }
  }
}
