
<label *ngIf="to.label && !field.wrappers.length" style="margin: 0%">
  {{ to.label }}
  <span *ngIf="to.required">*</span>
</label>

<p-calendar
  [formControl]="$any(formControl)"
  view="date"
  dateFormat="yy-mm-dd"
  [minDate]="to?.minDate || defaultMinDate"
  [maxDate]="to?.maxDate || defaultMaxDate"
  [showIcon]="true"
  [readonlyInput]="to.readonlyInput == null ? true : to.readonlyInput"
  (onSelect)="to.onSelect != undefined ? to.onSelect(field, model) : undefined"
  (ngModelChange)="to.change != undefined ? to.change(field, model) : undefined"
  [dataType]="'string'"
  [required]="to.required"
  [showButtonBar]="true"
  appendTo="body"
></p-calendar>
