/* tslint:disable */
type ELeaveTimeType =
  'SpecificTime' |
  'HalfDay_First' |
  'HalfDay_Second' |
  'FullDay';
module ELeaveTimeType {
  export const SPECIFIC_TIME: ELeaveTimeType = 'SpecificTime';
  export const HALF_DAY_FIRST: ELeaveTimeType = 'HalfDay_First';
  export const HALF_DAY_SECOND: ELeaveTimeType = 'HalfDay_Second';
  export const FULL_DAY: ELeaveTimeType = 'FullDay';
  export function values(): ELeaveTimeType[] {
    return [
      SPECIFIC_TIME,
      HALF_DAY_FIRST,
      HALF_DAY_SECOND,
      FULL_DAY
    ];
  }
}

export { ELeaveTimeType }