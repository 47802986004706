/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeInPositionModel } from '../models/employee-in-position-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeInPositionService extends __BaseService {
  static readonly GetEmployeeInPositionsListByEmployeeIdPath = '/api/EmployeeInPosition/employee/{employeeId}';
  static readonly GetEmployeeInPositionByIdPath = '/api/EmployeeInPosition/{employeeInPositionId}';
  static readonly DeleteEmployeeInPositionPath = '/api/EmployeeInPosition/{employeeInPositionId}';
  static readonly CreateEmployeeInPositionPath = '/api/EmployeeInPosition';
  static readonly UpdateEmployeeInPositionPath = '/api/EmployeeInPosition';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeInPositionsListByEmployeeIdResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeInPositionModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeInPosition/employee/${encodeURIComponent(String(employeeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeInPositionModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeInPositionsListByEmployeeId(employeeId: number): __Observable<Array<EmployeeInPositionModel>> {
    return this.GetEmployeeInPositionsListByEmployeeIdResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeInPositionModel>)
    );
  }

  /**
   * @param employeeInPositionId undefined
   * @return Success
   */
  GetEmployeeInPositionByIdResponse(employeeInPositionId: number): __Observable<__StrictHttpResponse<EmployeeInPositionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeInPosition/${encodeURIComponent(String(employeeInPositionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeInPositionModel>;
      })
    );
  }
  /**
   * @param employeeInPositionId undefined
   * @return Success
   */
  GetEmployeeInPositionById(employeeInPositionId: number): __Observable<EmployeeInPositionModel> {
    return this.GetEmployeeInPositionByIdResponse(employeeInPositionId).pipe(
      __map(_r => _r.body as EmployeeInPositionModel)
    );
  }

  /**
   * @param employeeInPositionId undefined
   * @return Success
   */
  DeleteEmployeeInPositionResponse(employeeInPositionId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeInPosition/${encodeURIComponent(String(employeeInPositionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeInPositionId undefined
   * @return Success
   */
  DeleteEmployeeInPosition(employeeInPositionId: number): __Observable<string> {
    return this.DeleteEmployeeInPositionResponse(employeeInPositionId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeInPositionResponse(body?: EmployeeInPositionModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeInPosition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeInPosition(body?: EmployeeInPositionModel): __Observable<string> {
    return this.CreateEmployeeInPositionResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeInPositionResponse(body?: EmployeeInPositionModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeInPosition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeInPosition(body?: EmployeeInPositionModel): __Observable<string> {
    return this.UpdateEmployeeInPositionResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeInPositionService {
}

export { EmployeeInPositionService }
