<p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="employeeList" [loading]="loading">
  <ng-template pTemplate="header">
    <tr>
      <th>{{"code" | translate}}</th>
      <th>{{"employee_namesurname" | translate}}</th>
      <th>{{"company" | translate}}</th>
      <th>{{"agency" | translate}}</th>
      <th>{{"position" | translate}}</th>
      <th>{{"email" | translate}}</th>
      <th>{{"contact_number" | translate}}</th>
      <th>{{"birthday" | translate}}</th>
      <th style="width: 100px">{{"action" | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-employee>
    <tr>
      <td>{{ employee.employeeCode }}</td>
      <td>{{ employee.fullnameTh }}</td>
      <td>{{ employee.companyName }}</td>
      <td>{{ employee.unitName }}</td>
      <td>{{ employee.positionName }}</td>
      <td>{{ employee.c_Email }}</td>
      <td>{{ employee.mphone }}</td>
      <td>{{ employee.birthday | date: 'dd/MM/yyyy' }}</td>
      <td>
        <div class="grid justify-content-around icon-action">
          <div class="col-fixed">
            <button pButton icon="fa fa-eye" [routerLink]="[employee.id]"></button>
          </div>
          <div class="col-fixed">
            <button pButton  icon="pi pi-ellipsis-v" (click)="onEllipsisIconClick($event, menu, employee)"></button>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="9" class="emptymessage">{{"no_data" | translate}}</td>
    </tr>
  </ng-template>
</p-table>
<p-paginator
  [rows]="paginationParams.rowPerPage"
  [first]="paginationParams.firstRowIdx"
  [totalRecords]="paginationParams.totalRecords"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="paginationParams.rowPerPageSelectList"
  currentPageReportTemplate="{first} - {last} of {totalRecords} Results"
  (onPageChange)="onPageChange($event)"
>
</p-paginator>
<p-menu #menu [model]="employeeMenuItems" [popup]="true"></p-menu>
