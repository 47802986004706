/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeWarningLetterModelPaginationResult } from '../models/employee-warning-letter-model-pagination-result';
import { EmployeeWarningLetterModel } from '../models/employee-warning-letter-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeWarningLetterService extends __BaseService {
  static readonly GetEmployeeWarningLetterListPath = '/api/EmployeeWarningLetter';
  static readonly CreateEmployeeWarningLetterPath = '/api/EmployeeWarningLetter';
  static readonly UpdateEmployeeWarningLetterPath = '/api/EmployeeWarningLetter';
  static readonly GetEmployeeWarningLetterByIdPath = '/api/EmployeeWarningLetter/{employeeWarningLetterId}';
  static readonly DeleteEmployeeWarningLetterPath = '/api/EmployeeWarningLetter/{employeeWarningLetterId}';
  static readonly CancelEmployeeWarningLetterPath = '/api/EmployeeWarningLetter/{employeeWarningLetterId}/cancel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EmployeeWarningLetterService.GetEmployeeWarningLetterListParams` containing the following parameters:
   *
   * - `WarningLetterStatusId`:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IssueDate`:
   *
   * - `IsWarningByVerbal`:
   *
   * - `IsWarningByLetterAndPunnishment`:
   *
   * - `IsWarningByLetter`:
   *
   * - `IsTerminate`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetEmployeeWarningLetterListResponse(params: EmployeeWarningLetterService.GetEmployeeWarningLetterListParams): __Observable<__StrictHttpResponse<EmployeeWarningLetterModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.WarningLetterStatusId != null) __params = __params.set('WarningLetterStatusId', params.WarningLetterStatusId.toString());
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IssueDate != null) __params = __params.set('IssueDate', params.IssueDate.toString());
    if (params.IsWarningByVerbal != null) __params = __params.set('IsWarningByVerbal', params.IsWarningByVerbal.toString());
    if (params.IsWarningByLetterAndPunnishment != null) __params = __params.set('IsWarningByLetterAndPunnishment', params.IsWarningByLetterAndPunnishment.toString());
    if (params.IsWarningByLetter != null) __params = __params.set('IsWarningByLetter', params.IsWarningByLetter.toString());
    if (params.IsTerminate != null) __params = __params.set('IsTerminate', params.IsTerminate.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeWarningLetter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeWarningLetterModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeeWarningLetterService.GetEmployeeWarningLetterListParams` containing the following parameters:
   *
   * - `WarningLetterStatusId`:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IssueDate`:
   *
   * - `IsWarningByVerbal`:
   *
   * - `IsWarningByLetterAndPunnishment`:
   *
   * - `IsWarningByLetter`:
   *
   * - `IsTerminate`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetEmployeeWarningLetterList(params: EmployeeWarningLetterService.GetEmployeeWarningLetterListParams): __Observable<EmployeeWarningLetterModelPaginationResult> {
    return this.GetEmployeeWarningLetterListResponse(params).pipe(
      __map(_r => _r.body as EmployeeWarningLetterModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeWarningLetterResponse(body?: EmployeeWarningLetterModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeWarningLetter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeWarningLetter(body?: EmployeeWarningLetterModel): __Observable<number> {
    return this.CreateEmployeeWarningLetterResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeWarningLetterResponse(body?: EmployeeWarningLetterModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeWarningLetter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeWarningLetter(body?: EmployeeWarningLetterModel): __Observable<string> {
    return this.UpdateEmployeeWarningLetterResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeWarningLetterId undefined
   * @return Success
   */
  GetEmployeeWarningLetterByIdResponse(employeeWarningLetterId: number): __Observable<__StrictHttpResponse<EmployeeWarningLetterModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeWarningLetter/${encodeURIComponent(String(employeeWarningLetterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeWarningLetterModel>;
      })
    );
  }
  /**
   * @param employeeWarningLetterId undefined
   * @return Success
   */
  GetEmployeeWarningLetterById(employeeWarningLetterId: number): __Observable<EmployeeWarningLetterModel> {
    return this.GetEmployeeWarningLetterByIdResponse(employeeWarningLetterId).pipe(
      __map(_r => _r.body as EmployeeWarningLetterModel)
    );
  }

  /**
   * @param employeeWarningLetterId undefined
   * @return Success
   */
  DeleteEmployeeWarningLetterResponse(employeeWarningLetterId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeWarningLetter/${encodeURIComponent(String(employeeWarningLetterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeWarningLetterId undefined
   * @return Success
   */
  DeleteEmployeeWarningLetter(employeeWarningLetterId: number): __Observable<string> {
    return this.DeleteEmployeeWarningLetterResponse(employeeWarningLetterId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeWarningLetterId undefined
   * @return Success
   */
  CancelEmployeeWarningLetterResponse(employeeWarningLetterId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeWarningLetter/${encodeURIComponent(String(employeeWarningLetterId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeWarningLetterId undefined
   * @return Success
   */
  CancelEmployeeWarningLetter(employeeWarningLetterId: number): __Observable<string> {
    return this.CancelEmployeeWarningLetterResponse(employeeWarningLetterId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeWarningLetterService {

  /**
   * Parameters for GetEmployeeWarningLetterList
   */
  export interface GetEmployeeWarningLetterListParams {
    WarningLetterStatusId?: any;
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IssueDate?: string;
    IsWarningByVerbal?: boolean;
    IsWarningByLetterAndPunnishment?: boolean;
    IsWarningByLetter?: boolean;
    IsTerminate?: boolean;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsAllEmployee?: boolean;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    CompanyId?: number;
    BranchId?: number;
  }
}

export { EmployeeWarningLetterService }
