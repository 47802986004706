import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import {
  EmployeeAddressModel,
  EmployeeRelativeModel,
  EmployeeEmergencyContactModel,
  EmployeeParentInfoModel,
  EmployeeSpouseInfoModel,
  EmployeeChildrenInfoModel,
  ERelativeInfoStatus,
  EmployeeAddressInfoModel,
} from 'app/api/models';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomFormlyFieldConfig } from 'utils/formly-utils';
import { Subject, ReplaySubject, forkJoin } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { EmployeeEmergencyContactService, EmployeeService, EmployeeRelativeService } from '../../../api/services';
import { EmployeeMotherModel } from '../../../pages/admin/core/employee/employee-create/employee-create-form-family-info/employee-create-form-family-info.component';

interface FatherInfoModel extends EmployeeRelativeModel {
  fatherAddressIsTheSameAsHomeAddress?: boolean;
}

interface MotherInfoModel extends EmployeeRelativeModel {
  motherAddressIsTheSameAsHomeAddress?: boolean;
}

interface SpouseInfoDisplayModel extends EmployeeRelativeModel {
  spouseAddressIsTheSameAsHomeAddress?: boolean;
}

interface SpouseInfoModel extends EmployeeSpouseInfoModel {
  employeeSpouse: SpouseInfoDisplayModel;
}

interface ParentInfoDisplayModel extends EmployeeParentInfoModel {
  employeeFather?: FatherInfoModel;
  employeeMother?: MotherInfoModel;
}

interface ParentStatus {
  isAlive: boolean;
  isNoData: boolean;
}

@Component({
  selector: 'app-family-info',
  templateUrl: './family-info.component.html',
  styleUrls: ['./family-info.component.css'],
})
export class FamilyInfoComponent implements OnInit {
  editableEventSource = new ReplaySubject<boolean>(1);
  isEditable = false;
  form = new FormGroup({});
  parentForm = new FormGroup({});
  spouseForm = new FormGroup({});
  childrenForm = new FormGroup({});
  emergencyForm = new FormGroup({});
  parentInfoFields: CustomFormlyFieldConfig<
    | keyof FatherInfoModel
    | keyof MotherInfoModel
    | keyof EmployeeRelativeModel
    | keyof EmployeeParentInfoModel
    | keyof ParentStatus
  >[] = [
    // {
    //   template: `<h3>ข้อมูลครอบครัว</h3>`,
    // },
    // {
    //   fieldGroupClassName: 'grid',
    //   fieldGroup: [
    //     {
    //       key: 'maritalStatusId',
    //       type: 'radio',
    //       className: 'col-12',
    //       wrappers: ['inline-label'],
    //       expressionProperties: {
    //         'templateOptions.disabled': () => !this.isEditable,
    //       },
    //       templateOptions: {
    //         translate: true,
    //         label: 'marital_status',
    //         placeholder: '@blank',

    //         options: this.masterDataRepo.getMaritalStatusOptionList(),
    //         labelClass: 'col-fixed',
    //         inputClass: 'col',
    //       },
    //     },
    //   ],
    // },
    {
      template: `<h4>รายละเอียดบิดา</h4>`,
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isFatherAlive',
          type: 'radio',
          className: 'col-12',
          wrappers: ['above-label'],
          defaultValue: 'HaveInfo' as ERelativeInfoStatus,
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !this.isEditable;
            },
          },
          templateOptions: {
            translate: true,
            label: 'father_status',
            placeholder: '@blank',
            labelStyle: { 'text-align': 'right' },
            labelClass: 'col-fixed',
            inputClass: 'col-10',
            options: this.masterDataRepo.getRelativeInfoStatusesOptionList(),
          },
        },
      ],
    },
    {
      key: 'employeeFather',
      fieldGroupClassName: 'grid align-center',
      fieldGroup: [
        {
          key: 'titleTh',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'prefix_n',
            placeholder: '@blank',

            options: this.masterDataRepo.getTitleThOptionList(),
          },
        },
        {
          key: 'firstnameTh',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'name_th',
            placeholder: '@blank',

            maxLength: 128,
          },
        },
        {
          key: 'lastnameTh',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'surname_th',
            placeholder: '@blank',

            maxLength: 128,
          },
        },
        {
          key: 'birthday',
          type: 'calendar',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'birthday',
            placeholder: 'd/m/y',
          },
        },
        {
          key: 'religionId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'religion',
            placeholder: '@blank',
            options: this.masterDataRepo.getReligionOptionList(),
          },
        },
        {
          key: 'nationalityId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'nationality',
            placeholder: '@blank',
            translate: true,
            options: this.masterDataRepo.getNationalityOptionList(),
          },
        },
        {
          key: 'raceId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'race',
            placeholder: '@blank',
            translate: true,
            options: this.masterDataRepo.getRaceOptionList(),
          },
        },
        {
          key: 'job',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'occupation_position',
            placeholder: '@blank',

            maxLength: 128,
          },
        },
        {
          key: 'phone',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'phone_number',
            placeholder: '@blank',

            maxLength: 32,
          },
        },
        {
          key: 'taxId',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'card_number',
            placeholder: '@blank',

            maxLength: 32,
          },
        },
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-9',
          expressionProperties: {
            'templateOptions.disabled': (model: FatherInfoModel) => {
              return this.disabledFatherForm() || model.fatherAddressIsTheSameAsHomeAddress;
            },
          },
          templateOptions: {
            translate: true,
            label: 'address',
            placeholder: '@blank',
            maxLength: 512,
          },
        },
        {
          key: 'fatherAddressIsTheSameAsHomeAddress',
          type: 'checkbox',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'address_house_registration',
            placeholder: '@blank',
          },
        },
        {
          key: 'province',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: FatherInfoModel) => {
              return this.disabledFatherForm() || model.fatherAddressIsTheSameAsHomeAddress;
            },
          },
          templateOptions: {
            translate: true,
            label: 'province',
            placeholder: '@blank',
            options: this.masterDataRepo.getProvinceOptionList(),

            change: (_: FormlyFieldConfig, { value }) => {
              const provinceId = this.masterDataRepo.getProvinceIdByName(value);
              console.log(value, provinceId);

              this.parentInfoFields
                .find(a => a.key === 'employeeFather')
                .fieldGroup.find(a => a.key === 'district').templateOptions.options =
                this.masterDataRepo.getAmphoeOptionList(provinceId);
              (this.parentForm.controls.employeeFather as FormGroup).controls.district.reset();
              (this.parentForm.controls.employeeFather as FormGroup).controls.subDistrict.reset();
            },
            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'district',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: FatherInfoModel) => {
              return this.disabledFatherForm() || model.fatherAddressIsTheSameAsHomeAddress;
            },
          },
          templateOptions: {
            translate: true,
            label: 'district',
            placeholder: '@blank',

            change: (_: FormlyFieldConfig, { value }) => {
              const amphoeId = this.masterDataRepo.getAmphoeIdByName(value);
              console.log(value, amphoeId);

              this.parentInfoFields
                .find(a => a.key === 'employeeFather')
                .fieldGroup.find(a => a.key === 'subDistrict').templateOptions.options =
                this.masterDataRepo.getTambonOptionList(amphoeId);
              (this.parentForm.controls.employeeFather as FormGroup).controls.subDistrict.reset();
            },
            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'subDistrict',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: FatherInfoModel) => {
              return this.disabledFatherForm() || model.fatherAddressIsTheSameAsHomeAddress;
            },
          },
          templateOptions: {
            translate: true,
            label: 'subdistrict',
            placeholder: '@blank',

            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'zipcode',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: FatherInfoModel) => {
              return this.disabledFatherForm() || model.fatherAddressIsTheSameAsHomeAddress;
            },
          },
          templateOptions: {
            translate: true,
            label: 'postal_code',
            placeholder: '@blank',

            maxLength: 5,
          },
        },
      ],
    },
    {
      template: `<hr/><h4>รายละเอียดมารดา</h4>`,
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isMotherAlive',
          type: 'radio',
          className: 'col-12',
          wrappers: ['above-label'],
          defaultValue: 'HaveInfo' as ERelativeInfoStatus,
          expressionProperties: {
            'templateOptions.disabled': () => {
              return !this.isEditable;
            },
          },
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'mom_status',
            labelStyle: { 'text-align': 'right' },
            labelClass: 'col-fixed',
            inputClass: 'col-10',
            options: this.masterDataRepo.getRelativeInfoStatusesOptionList(),
          },
        },
      ],
    },
    {
      key: 'employeeMother',
      fieldGroupClassName: 'grid align-center',
      fieldGroup: [
        {
          key: 'titleTh',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'prefix_n',
            placeholder: '@blank',

            options: this.masterDataRepo.getTitleThOptionList(),
          },
        },
        {
          key: 'firstnameTh',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'name_th',
            placeholder: '@blank',

            maxLength: 128,
          },
        },
        {
          key: 'lastnameTh',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'surname_th',
            placeholder: '@blank',

            maxLength: 128,
          },
        },
        {
          key: 'birthday',
          type: 'calendar',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'birthday',
            placeholder: 'd/m/y',
          },
        },
        {
          key: 'religionId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'religion',
            placeholder: '@blank',
            options: this.masterDataRepo.getReligionOptionList(),
          },
        },
        {
          key: 'nationalityId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'nationality',
            placeholder: '@blank',
            translate: true,
            options: this.masterDataRepo.getNationalityOptionList(),
          },
        },
        {
          key: 'raceId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'race',
            placeholder: '@blank',
            translate: true,
            options: this.masterDataRepo.getRaceOptionList(),
          },
        },
        {
          key: 'job',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'occupation_position',
            placeholder: '@blank',

            maxLength: 128,
          },
        },
        {
          key: 'phone',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'phone_number',
            placeholder: '@blank',

            maxLength: 32,
          },
        },
        {
          key: 'taxId',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'card_number',
            placeholder: '@blank',

            maxLength: 32,
          },
        },
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-9',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => {
              return this.disabledMotherForm() || model.motherAddressIsTheSameAsHomeAddress;
            },
          },
          templateOptions: {
            translate: true,
            label: 'address',
            placeholder: '@blank',
            maxLength: 512,
          },
        },
        {
          key: 'motherAddressIsTheSameAsHomeAddress',
          type: 'checkbox',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'address_house_registration',
            placeholder: '@blank',
            // change: this.setEnableAddressControl.bind(this, ['mother']),
          },
        },
        {
          key: 'province',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => {
              return this.disabledMotherForm() || model.motherAddressIsTheSameAsHomeAddress;
            },
          },
          templateOptions: {
            translate: true,
            label: 'province',
            placeholder: '@blank',
            options: this.masterDataRepo.getProvinceOptionList(),

            change: (_: FormlyFieldConfig, { value }) => {
              const provinceId = this.masterDataRepo.getProvinceIdByName(value);
              console.log(value, provinceId);

              this.parentInfoFields
                .find(a => a.key === 'employeeMother')
                .fieldGroup.find(a => a.key === 'district').templateOptions.options =
                this.masterDataRepo.getAmphoeOptionList(provinceId);
              (this.parentForm.controls.employeeMother as FormGroup).controls.district.reset();
              (this.parentForm.controls.employeeMother as FormGroup).controls.subDistrict.reset();
            },
            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'district',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => {
              return this.disabledMotherForm() || model.motherAddressIsTheSameAsHomeAddress;
            },
          },
          templateOptions: {
            translate: true,
            label: 'district',
            placeholder: '@blank',

            change: (_: FormlyFieldConfig, { value }) => {
              const amphoeId = this.masterDataRepo.getAmphoeIdByName(value);
              console.log(value, amphoeId);

              this.parentInfoFields
                .find(a => a.key === 'employeeMother')
                .fieldGroup.find(a => a.key === 'subDistrict').templateOptions.options =
                this.masterDataRepo.getTambonOptionList(amphoeId);
              (this.parentForm.controls.employeeMother as FormGroup).controls.subDistrict.reset();
            },
            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'subDistrict',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => {
              return this.disabledMotherForm() || model.motherAddressIsTheSameAsHomeAddress;
            },
          },
          templateOptions: {
            translate: true,
            label: 'subdistrict',
            placeholder: '@blank',

            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'zipcode',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => {
              return this.disabledMotherForm() || model.motherAddressIsTheSameAsHomeAddress;
            },
          },
          templateOptions: {
            translate: true,
            label: 'postal_code',
            placeholder: '@blank',

            maxLength: 5,
          },
        },
      ],
    },
    {
      template: `<hr/><h4>ข้อมูลครอบครัวอื่นๆ</h4>`,
    },
    {
      // key: 'otherParent',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'siblingCount',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'sibling_number',
            placeholder: '@blank',
            tooltip: 'หากเป็นลูกคนเดียว ให้ระบุ 1',
            type: 'number',
          },
        },
        {
          key: 'siblingIndex',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'you_order',
            placeholder: '@blank',
            tooltip: 'หากเป็นลูกคนเดียว ให้ระบุ 1',
            type: 'number',
          },
        },
      ],
    },
  ];
  spouseInfoFields: CustomFormlyFieldConfig<
    keyof SpouseInfoModel | keyof EmployeeRelativeModel | keyof SpouseInfoDisplayModel
  >[] = [
    {
      template: `<hr/><h4>รายละเอียดข้อมูลสามี/ภรรยา</h4>`,
    },
    {
      key: 'employeeSpouse',
      fieldGroupClassName: 'grid  align-center',
      fieldGroup: [
        {
          key: 'titleTh',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'prefix_n',
            placeholder: '@blank',

            options: this.masterDataRepo.getTitleThOptionList(),
          },
        },
        {
          key: 'firstnameTh',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'name_th',
            placeholder: '@blank',

            maxLength: 128,
          },
        },
        {
          key: 'lastnameTh',
          type: 'primeng-input',
          className: 'col-3',
          // defaultValue:
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'surname_th',
            placeholder: '@blank',

            maxLength: 128,
          },
        },
        {
          key: 'birthday',
          type: 'calendar',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'birthday',
            placeholder: 'd/m/y',
          },
        },
        {
          key: 'religionId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'religion',
            placeholder: '@blank',
            options: this.masterDataRepo.getReligionOptionList(),
          },
        },
        {
          key: 'nationalityId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'nationality',
            placeholder: '@blank',
            translate: true,
            options: this.masterDataRepo.getNationalityOptionList(),
          },
        },
        {
          key: 'raceId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'race',
            placeholder: '@blank',
            translate: true,
            options: this.masterDataRepo.getRaceOptionList(),
          },
        },
        {
          key: 'job',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'occupation_position',
            placeholder: '@blank',

            maxLength: 128,
          },
        },
        {
          key: 'phone',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'phone_number',
            placeholder: '@blank',

            maxLength: 32,
          },
        },
        {
          key: 'taxId',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'card_number',
            placeholder: '@blank',

            maxLength: 32,
          },
        },
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-9',
          expressionProperties: {
            'templateOptions.disabled': (model: SpouseInfoDisplayModel) =>
              !this.isEditable || model.spouseAddressIsTheSameAsHomeAddress,
          },
          templateOptions: {
            translate: true,
            label: 'address',
            placeholder: '@blank',
            maxLength: 512,
          },
        },
        {
          key: 'spouseAddressIsTheSameAsHomeAddress',
          type: 'checkbox',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: SpouseInfoDisplayModel) =>
              !this.isEditable || model.spouseAddressIsTheSameAsHomeAddress,
          },
          templateOptions: {
            translate: true,
            label: 'address_house_registration',
            placeholder: '@blank',
            // change: this.setEnableAddressControl.bind(this, ['spouse']),
          },
        },
        {
          key: 'province',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: SpouseInfoDisplayModel) =>
              !this.isEditable || model.spouseAddressIsTheSameAsHomeAddress,
          },
          templateOptions: {
            translate: true,
            label: 'province',
            placeholder: '@blank',
            options: this.masterDataRepo.getProvinceOptionList(),

            change: (_: FormlyFieldConfig, { value }) => {
              const provinceId = this.masterDataRepo.getProvinceIdByName(value);
              console.log(value, provinceId);

              this.spouseInfoFields
                .find(a => a.key === 'employeeSpouse')
                .fieldGroup.find(a => a.key === 'district').templateOptions.options =
                this.masterDataRepo.getAmphoeOptionList(provinceId);
              (this.spouseForm.controls.employeeSpouse as FormGroup).controls.district.reset();
              (this.spouseForm.controls.employeeSpouse as FormGroup).controls.subDistrict.reset();
            },
            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'district',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: SpouseInfoDisplayModel) =>
              !this.isEditable || model.spouseAddressIsTheSameAsHomeAddress,
          },
          templateOptions: {
            translate: true,
            label: 'district',
            placeholder: '@blank',

            change: (_: FormlyFieldConfig, { value }) => {
              const amphoeId = this.masterDataRepo.getAmphoeIdByName(value);
              console.log(value, amphoeId);

              this.spouseInfoFields
                .find(a => a.key === 'employeeSpouse')
                .fieldGroup.find(a => a.key === 'subDistrict').templateOptions.options =
                this.masterDataRepo.getTambonOptionList(amphoeId);
              (this.spouseForm.controls.employeeSpouse as FormGroup).controls.subDistrict.reset();
            },
            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'subDistrict',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: SpouseInfoDisplayModel) =>
              !this.isEditable || model.spouseAddressIsTheSameAsHomeAddress,
          },
          templateOptions: {
            translate: true,
            label: 'subdistrict',
            placeholder: '@blank',

            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'zipcode',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: SpouseInfoDisplayModel) =>
              !this.isEditable || model.spouseAddressIsTheSameAsHomeAddress,
          },
          templateOptions: {
            translate: true,
            label: 'postal_code',
            placeholder: '@blank',

            maxLength: 5,
          },
        },
      ],
    },
  ];
  childrenInfoFields: CustomFormlyFieldConfig<keyof EmployeeChildrenInfoModel | keyof EmployeeRelativeModel>[] = [
    {
      template: '<hr/>'
    },
    {
      key: 'employeeChildren',
      type: 'repeat',
      expressionProperties: {
        'templateOptions.disabled': this.editableEventSource.asObservable().pipe(map(editable => !editable)),
      },
      templateOptions: {
        addText: '+ เพิ่มบุตร',
        bigTitle: 'รายละเอียดข้อมูลบุตร:',
        littleTitle: 'รายละเอียดบุตรคนที่ ',
        deleteTitle: 'ลบข้อมูลบุตร',
      },
      fieldArray: {
        key: 'employeeChildren',
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            className: 'col-3',
            type: 'filter-dropdown',
            key: 'titleTh',
            templateOptions: {
              translate: true,
              placeholder: '@blank',
              label: 'prefix_n',

              options: this.masterDataRepo.getTitleThOptionList(),
            },
          },
          {
            type: 'primeng-input',
            key: 'firstnameTh',
            className: 'col-3',
            templateOptions: {
              translate: true,
              placeholder: '@blank',
              label: 'name_th',

              maxLength: 128,
            },
          },
          {
            type: 'primeng-input',
            key: 'lastnameTh',
            className: 'col-3',
            templateOptions: {
              translate: true,
              placeholder: '@blank',
              label: 'surname_th',

              maxLength: 128,
            },
          },
          {
            type: 'calendar',
            key: 'birthday',
            className: 'col-3',
            templateOptions: {
              translate: true,
              label: 'birthday',

              placeholder: 'd/m/y',
            },
          },
          {
            type: 'primeng-input',
            key: 'taxId',
            className: 'col-3',
            templateOptions: {
              translate: true,
              placeholder: '@blank',
              label: 'card_number',
              maxLength: 32,
            },
          },
          {
            type: 'radio',
            key: 'isStudent',
            className: 'col-3',
            templateOptions: {
              translate: true,
              placeholder: '@blank',
              label: 'studying',

              options: [
                {
                  label: 'ใช่',
                  value: true,
                },
                {
                  label: 'ไม่ใช่',
                  value: false,
                },
              ],
            },
          },
        ],
      },
    },
  ];
  emergencyContactInfoFields: CustomFormlyFieldConfig<keyof EmployeeEmergencyContactModel>[] = [
    {
      template: `<hr/><h3>รายละเอียดผู้ติดต่อในกรณีฉุกเฉิน</h3>`,
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'titleTh',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'prefix_n',
            placeholder: '@blank',
            required: true,
            options: this.masterDataRepo.getTitleThOptionList(),
          },
        },
        {
          key: 'firstnameTh',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'name_th',
            placeholder: '@blank',
            required: true,
            maxLength: 128,
          },
        },
        {
          key: 'lastnameTh',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'surname_th',
            placeholder: '@blank',
            required: true,
            labelClass: 'col',
            inputClass: 'col-12',
            maxLength: 128,
          },
        },
        {
          key: 'birthday',
          type: 'calendar',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'birthday',
            placeholder: '@blank',
          },
        },
        {
          key: 'emergencyContactRelation',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'relationship',
            placeholder: '@blank',
            options: this.masterDataRepo.getRelativeTypeOptionList(),
            required: true,
          },
        },
        {
          key: 'phone',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'phone_number',
            placeholder: '@blank',
            required: true,
            maxLength: 32,
          },
        },
      ],
    },
  ];
  parentModel: ParentInfoDisplayModel = {};
  spouseModel: SpouseInfoModel = null;
  childrenModel: EmployeeChildrenInfoModel;
  oldChildrenModel: EmployeeChildrenInfoModel;
  emergencyModel: EmployeeEmergencyContactModel;

  constructor(
    private masterDataRepo: MasterDataRepo,
    private router: Router,
    private notificationService: AppNotificationServiceService,
    private employeeEmergencyContactService: EmployeeEmergencyContactService,
    private employeeService: EmployeeService,
    private employeeRelativeService: EmployeeRelativeService,
  ) {}

  ngOnInit(): void {
    this.setEditablePermission();
    this.employeeService.GetAddressInfo(this.getEmployeeId()).subscribe(addressInfo => {
      this.setParentInfo(addressInfo);
      this.setSpouseInfo(addressInfo);
    });
    this.setChildrenInfo();
    this.setEmergencyContactInfo();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
    this.editableEventSource.next(this.isEditable);
  }

  setParentInfo(addressInfo: EmployeeAddressInfoModel) {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetParentInfo(employeeId).subscribe(
      parentInfo => {
        let isFatherSameHomeAddress = false;
        if (parentInfo.employeeFather) {
          isFatherSameHomeAddress = this.isSameAddress(
            parentInfo.employeeFather,
            addressInfo.employeeRegisteredAddress,
          );
          (this.parentForm.controls.employeeFather as FormGroup).controls.fatherAddressIsTheSameAsHomeAddress.setValue(
            isFatherSameHomeAddress,
          );
          // this.setEnableAddressControl(['father']);
        }

        let isMotherSameHomeAddress = false;
        if (parentInfo.employeeMother) {
          isMotherSameHomeAddress = this.isSameAddress(
            parentInfo.employeeMother,
            addressInfo.employeeRegisteredAddress,
          );
          (this.parentForm.controls.employeeMother as FormGroup).controls.motherAddressIsTheSameAsHomeAddress.setValue(
            isMotherSameHomeAddress,
          );
          // this.setEnableAddressControl(['mother']);
        }

        this.parentForm.reset();

        this.parentModel = {
          ...parentInfo,
          employeeFather: {
            ...parentInfo.employeeFather,
            fatherAddressIsTheSameAsHomeAddress: isFatherSameHomeAddress,
            birthday: parentInfo.employeeFather?.birthday
              ? parentInfo.employeeFather?.birthday
              : undefined,
          },
          employeeMother: {
            ...parentInfo.employeeMother,
            motherAddressIsTheSameAsHomeAddress: isMotherSameHomeAddress,
            birthday: parentInfo.employeeMother?.birthday
              ? parentInfo.employeeMother?.birthday
              : undefined,
          },
        };
        this.setParentAddressOptions();
      },
      (err: HttpErrorResponse) => {
        console.log(err);
      },
    );
  }

  setSpouseInfo(addressInfo: EmployeeAddressInfoModel) {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetSpouseInfo(employeeId).subscribe(spouseInfo => {
      console.log('setSpouseInfo', spouseInfo);
      let isSpouseSameHomeAddress = false;
      if (spouseInfo.employeeSpouse) {
        isSpouseSameHomeAddress = this.isSameAddress(spouseInfo.employeeSpouse, addressInfo.employeeRegisteredAddress);
        (this.spouseForm.controls.employeeSpouse as FormGroup).controls.spouseAddressIsTheSameAsHomeAddress.setValue(
          isSpouseSameHomeAddress,
        );
        // this.setEnableAddressControl(['spouse']);
      }

      this.spouseForm.reset();
      this.spouseModel = {
        employeeSpouse: {
          ...spouseInfo.employeeSpouse,
          spouseAddressIsTheSameAsHomeAddress: isSpouseSameHomeAddress,
          birthday: spouseInfo.employeeSpouse?.birthday
            ? spouseInfo.employeeSpouse?.birthday
            : undefined,
        },
      };
      this.setSpouseAddressOptions();
    });
  }

  setChildrenInfo() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetChildrenInfo(employeeId).subscribe(children => {
      this.childrenForm.reset();
      this.childrenModel = {
        employeeChildren: children.employeeChildren.map(child => {
          return {
            ...child,
            birthday: child.birthday ? (child.birthday) : undefined,
          };
        }),
      };
      this.oldChildrenModel = {
        employeeChildren: children.employeeChildren,
      };
    });
  }

  setEmergencyContactInfo() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetEmergencyContactInfo(employeeId).subscribe(emergency => {
      this.emergencyForm.reset();

      if (emergency) {
        this.emergencyModel = {
          ...emergency,
          birthday: emergency.birthday ? (emergency.birthday) : undefined,
        };
      } else {
        this.emergencyModel = {
          employeeId,
        };
      }
    });
  }

  onUpdateParentInfoBtnClick() {
    console.log(this.parentModel);

    this.notificationService.confirm('บันทึก', 'ยืนยันการบันทึกข้อมูล').then(v => {
      if (v.isConfirmed) {
        this.updateParentInfo();
      }
    });
  }

  onUpdateSpouseInfoBtnClick() {
    console.log(this.spouseModel);

    this.notificationService.confirm('บันทึก', 'ยืนยันการบันทึกข้อมูล').then(v => {
      if (v.isConfirmed) {
        this.updateSpouseInfo();
      }
    });
  }

  onUpdateChildrenInfoBtnClick() {
    console.log(this.childrenModel);

    this.notificationService.confirm('บันทึก', 'ยืนยันการบันทึกข้อมูล').then(v => {
      if (v.isConfirmed) {
        this.updateChildrenInfo();
      }
    });
  }

  onUpdateEmergencyContactInfoBtnClick() {
    console.log(this.emergencyModel);

    this.notificationService.confirm('บันทึก', 'ยืนยันการบันทึกข้อมูล').then(v => {
      if (v.isConfirmed) {
        this.updateEmergencyContactInfo();
      }
    });
  }

  async updateParentInfo() {
    const employeeId = this.getEmployeeId();

    const { employeeRegisteredAddress } = await this.employeeService.GetAddressInfo(employeeId).toPromise();

    const { employeeFather, employeeMother, siblingCount, siblingIndex, isMotherAlive, isFatherAlive } =
      this.parentModel;
    const isFatherSameAddress = this.parentModel.employeeFather.fatherAddressIsTheSameAsHomeAddress;
    const isMotherSameAddress = this.parentModel.employeeMother.motherAddressIsTheSameAsHomeAddress;

    this.employeeService
      .UpdateParent({
        employeeId,
        body: {
          parentMaritalStatusId: this.parentModel.parentMaritalStatusId,
          employeeFather: {
            ...employeeFather,
            titleTh: employeeFather.titleTh,
            firstnameTh: employeeFather.firstnameTh,
            lastnameTh: employeeFather.lastnameTh,
            job: employeeFather.job,
            phone: employeeFather.phone,
            taxId: employeeFather.taxId,
            address: isFatherSameAddress ? employeeRegisteredAddress.address : employeeFather.address,
            province: isFatherSameAddress ? employeeRegisteredAddress.province : employeeFather.province,
            district: isFatherSameAddress ? employeeRegisteredAddress.district : employeeFather.district,
            subDistrict: isFatherSameAddress ? employeeRegisteredAddress.subDistrict : employeeFather.subDistrict,
            zipcode: isFatherSameAddress ? employeeRegisteredAddress.zipcode : employeeFather.zipcode,
          },
          employeeMother: {
            ...employeeMother,
            titleTh: employeeMother.titleTh,
            firstnameTh: employeeMother.firstnameTh,
            lastnameTh: employeeMother.lastnameTh,
            job: employeeMother.job,
            phone: employeeMother.phone,
            taxId: employeeMother.taxId,
            address: isMotherSameAddress ? employeeRegisteredAddress.address : employeeMother.address,
            province: isMotherSameAddress ? employeeRegisteredAddress.province : employeeMother.province,
            district: isMotherSameAddress ? employeeRegisteredAddress.district : employeeMother.district,
            subDistrict: isMotherSameAddress ? employeeRegisteredAddress.subDistrict : employeeMother.subDistrict,
            zipcode: isMotherSameAddress ? employeeRegisteredAddress.zipcode : employeeMother.zipcode,
          },
          siblingCount,
          siblingIndex,
          isMotherAlive,
          isFatherAlive,
        },
      })
      .subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.employeeService.GetAddressInfo(this.getEmployeeId()).subscribe(addressInfo => {
            this.setParentInfo(addressInfo);
          });
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  async updateSpouseInfo() {
    const employeeId = this.getEmployeeId();

    const { employeeRegisteredAddress } = await this.employeeService.GetAddressInfo(employeeId).toPromise();

    const employeeSpouse = this.spouseModel.employeeSpouse;
    const isSameAddress = this.spouseModel.employeeSpouse.spouseAddressIsTheSameAsHomeAddress;
    if (employeeSpouse.employeeRelativeId) {
      this.employeeRelativeService
        .UpdateEmployeeRelative({
          employeeRelativeId: employeeSpouse.employeeRelativeId,
          body: {
            ...employeeSpouse,
            relativeTypeId: 'Spouse',
            employeeId,
            address: isSameAddress ? employeeRegisteredAddress.address : employeeSpouse.address,
            province: isSameAddress ? employeeRegisteredAddress.province : employeeSpouse.province,
            district: isSameAddress ? employeeRegisteredAddress.district : employeeSpouse.district,
            subDistrict: isSameAddress ? employeeRegisteredAddress.subDistrict : employeeSpouse.subDistrict,
            zipcode: isSameAddress ? employeeRegisteredAddress.zipcode : employeeSpouse.zipcode,
          },
        })
        .subscribe(
          () => {
            this.notificationService.saveCompleted();
            this.employeeService.GetAddressInfo(this.getEmployeeId()).subscribe(addressInfo => {
              this.setSpouseInfo(addressInfo);
            });
          },
          (err: HttpErrorResponse) => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
          },
        );
    } else {
      this.employeeRelativeService
        .CreateEmployeeRelative({
          ...employeeSpouse,
          relativeTypeId: 'Spouse',
          employeeId,
          address: isSameAddress ? employeeRegisteredAddress.address : employeeSpouse.address,
          province: isSameAddress ? employeeRegisteredAddress.province : employeeSpouse.province,
          district: isSameAddress ? employeeRegisteredAddress.district : employeeSpouse.district,
          subDistrict: isSameAddress ? employeeRegisteredAddress.subDistrict : employeeSpouse.subDistrict,
          zipcode: isSameAddress ? employeeRegisteredAddress.zipcode : employeeSpouse.zipcode,
        })
        .subscribe(
          () => {
            this.notificationService.saveCompleted();
            this.employeeService.GetAddressInfo(this.getEmployeeId()).subscribe(addressInfo => {
              this.setSpouseInfo(addressInfo);
            });
          },
          (err: HttpErrorResponse) => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
          },
        );
    }
  }

  updateChildrenInfo() {
    const employeeId = this.getEmployeeId();
    const oldChildren = this.oldChildrenModel.employeeChildren;
    const newChildren = this.childrenModel.employeeChildren;
    const isHaveNewChild = newChildren.length > oldChildren.length;
    let mergeChildren: EmployeeRelativeModel[] = [];

    if (isHaveNewChild) {
      mergeChildren = newChildren.map((newChild, idx) => {
        return {
          ...oldChildren[idx],
          employeeId,
          relativeTypeId: 'Child',
          titleTh: newChild.titleTh,
          firstnameTh: newChild.firstnameTh,
          lastnameTh: newChild.lastnameTh,
          birthday: newChild.birthday,
          taxId: newChild.taxId,
          isStudent: newChild.isStudent,
        };
      });
    } else {
      mergeChildren = oldChildren.map((oldChild, idx) => {
        const isDeletedChild = !newChildren[idx];
        if (isDeletedChild) {
          return {
            ...oldChild,
            status: 'InActive',
          };
        }
        return {
          ...oldChild,
          employeeId,
          relativeTypeId: 'Child',
          titleTh: newChildren[idx].titleTh,
          firstnameTh: newChildren[idx].firstnameTh,
          lastnameTh: newChildren[idx].lastnameTh,
          birthday: newChildren[idx].birthday,
          taxId: newChildren[idx].taxId,
          isStudent: newChildren[idx].isStudent,
        };
      });
    }
    this.employeeRelativeService
      .MultiUpsertEmployeeRelative(
        mergeChildren.map(child => {
          return {
            ...child,
            employeeId,
            relativeTypeId: child.relativeTypeId,
            titleTh: child.titleTh,
            firstnameTh: child.firstnameTh,
            lastnameTh: child.lastnameTh,
            birthday: child.birthday,
          };
        }),
      )
      .subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.setChildrenInfo();
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  updateEmergencyContactInfo() {
    const emergencyContact = this.emergencyModel;

    if (emergencyContact.employeeEmergencyContactId) {
      this.employeeEmergencyContactService.UpdateEmployeeEmergencyContact(this.emergencyModel).subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.setEmergencyContactInfo();
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
    } else {
      this.employeeEmergencyContactService.CreateEmployeeEmergencyContact(this.emergencyModel).subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.setEmergencyContactInfo();
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
    }
  }

  isSameAddress(address1: EmployeeAddressModel, address2: EmployeeAddressModel): boolean {
    if (!address1 || !address2) {
      return false;
    } else {
      if (address1.address !== address2.address) {
        return false;
      }
      if (address1.district !== address2.district) {
        return false;
      }
      if (address1.subDistrict !== address2.subDistrict) {
        return false;
      }
      if (address1.province !== address2.province) {
        return false;
      }
      if (address1.zipcode !== address2.zipcode) {
        return false;
      }
    }
    return true;
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  setParentAddressOptions() {
    this.parentInfoFields
      .find(a => a.key === 'employeeFather')
      .fieldGroup.find(a => a.key === 'district').templateOptions.options = this.masterDataRepo.getAmphoeOptionList(
      this.masterDataRepo.getProvinceIdByName(this.parentModel.employeeFather.province),
    );
    this.parentInfoFields
      .find(a => a.key === 'employeeFather')
      .fieldGroup.find(a => a.key === 'subDistrict').templateOptions.options = this.masterDataRepo.getTambonOptionList(
      this.masterDataRepo.getAmphoeIdByName(this.parentModel.employeeFather.district),
    );

    this.parentInfoFields
      .find(a => a.key === 'employeeMother')
      .fieldGroup.find(a => a.key === 'district').templateOptions.options = this.masterDataRepo.getAmphoeOptionList(
      this.masterDataRepo.getProvinceIdByName(this.parentModel.employeeMother.province),
    );
    this.parentInfoFields
      .find(a => a.key === 'employeeMother')
      .fieldGroup.find(a => a.key === 'subDistrict').templateOptions.options = this.masterDataRepo.getTambonOptionList(
      this.masterDataRepo.getAmphoeIdByName(this.parentModel.employeeMother.district),
    );
  }

  setSpouseAddressOptions() {
    this.spouseInfoFields
      .find(a => a.key === 'employeeSpouse')
      .fieldGroup.find(a => a.key === 'district').templateOptions.options = this.masterDataRepo.getAmphoeOptionList(
      this.masterDataRepo.getProvinceIdByName(this.spouseModel.employeeSpouse.province),
    );
    this.spouseInfoFields
      .find(a => a.key === 'employeeSpouse')
      .fieldGroup.find(a => a.key === 'subDistrict').templateOptions.options = this.masterDataRepo.getTambonOptionList(
      this.masterDataRepo.getAmphoeIdByName(this.spouseModel.employeeSpouse.district),
    );
  }

  disabledFatherForm() {
    return !this.isEditable || this.parentModel.isFatherAlive === 'Unknown';
  }

  disabledMotherForm() {
    return !this.isEditable || this.parentModel.isMotherAlive === 'Unknown';
  }
}
