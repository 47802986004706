/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class StarkReportService extends __BaseService {
  static readonly GetReportSlipsPath = '/api/StarkReport/Report_Slips_payroll_report';
  static readonly GetReportSlipEmpPath = '/api/StarkReport/Report_Slips_payroll_report_emp';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `StarkReportService.GetReportSlipsParams` containing the following parameters:
   *
   * - `ReturnFileType`:
   *
   * - `PayrollPeriodId`:
   *
   * - `PRP_YEAR`:
   *
   * - `PRP_MONTH`:
   *
   * - `EmployeeCode`:
   *
   * @return Success
   */
  GetReportSlipsResponse(params: StarkReportService.GetReportSlipsParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PayrollPeriodId != null) __params = __params.set('PayrollPeriodId', params.PayrollPeriodId.toString());
    if (params.PRPYEAR != null) __params = __params.set('PRP_YEAR', params.PRPYEAR.toString());
    if (params.PRPMONTH != null) __params = __params.set('PRP_MONTH', params.PRPMONTH.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/StarkReport/Report_Slips_payroll_report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `StarkReportService.GetReportSlipsParams` containing the following parameters:
   *
   * - `ReturnFileType`:
   *
   * - `PayrollPeriodId`:
   *
   * - `PRP_YEAR`:
   *
   * - `PRP_MONTH`:
   *
   * - `EmployeeCode`:
   *
   * @return Success
   */
  GetReportSlips(params: StarkReportService.GetReportSlipsParams): __Observable<Blob> {
    return this.GetReportSlipsResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `StarkReportService.GetReportSlipEmpParams` containing the following parameters:
   *
   * - `ReturnFileType`:
   *
   * - `PayrollPeriodId`:
   *
   * - `PRP_YEAR`:
   *
   * - `PRP_MONTH`:
   *
   * - `EmployeeCode`:
   *
   * @return Success
   */
  GetReportSlipEmpResponse(params: StarkReportService.GetReportSlipEmpParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ReturnFileType != null) __params = __params.set('ReturnFileType', params.ReturnFileType.toString());
    if (params.PayrollPeriodId != null) __params = __params.set('PayrollPeriodId', params.PayrollPeriodId.toString());
    if (params.PRPYEAR != null) __params = __params.set('PRP_YEAR', params.PRPYEAR.toString());
    if (params.PRPMONTH != null) __params = __params.set('PRP_MONTH', params.PRPMONTH.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/StarkReport/Report_Slips_payroll_report_emp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `StarkReportService.GetReportSlipEmpParams` containing the following parameters:
   *
   * - `ReturnFileType`:
   *
   * - `PayrollPeriodId`:
   *
   * - `PRP_YEAR`:
   *
   * - `PRP_MONTH`:
   *
   * - `EmployeeCode`:
   *
   * @return Success
   */
  GetReportSlipEmp(params: StarkReportService.GetReportSlipEmpParams): __Observable<Blob> {
    return this.GetReportSlipEmpResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module StarkReportService {

  /**
   * Parameters for GetReportSlips
   */
  export interface GetReportSlipsParams {
    ReturnFileType?: any;
    PayrollPeriodId?: number;
    PRPYEAR?: number;
    PRPMONTH?: number;
    EmployeeCode?: string;
  }

  /**
   * Parameters for GetReportSlipEmp
   */
  export interface GetReportSlipEmpParams {
    ReturnFileType?: any;
    PayrollPeriodId?: number;
    PRPYEAR?: number;
    PRPMONTH?: number;
    EmployeeCode?: string;
  }
}

export { StarkReportService }
