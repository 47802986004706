/* tslint:disable */
type EBenefitUseType =
  'Prorate' |
  'Full';
module EBenefitUseType {
  export const PRORATE: EBenefitUseType = 'Prorate';
  export const FULL: EBenefitUseType = 'Full';
  export function values(): EBenefitUseType[] {
    return [
      PRORATE,
      FULL
    ];
  }
}

export { EBenefitUseType }