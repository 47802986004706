import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-time-picker',
  templateUrl: './formly-time-picker.component.html',
  styleUrls: ['./formly-time-picker.component.css']
})
export class FormlyTimePickerComponent extends FieldWrapper implements OnInit {

  fixValue() {
    if (this.formControl.value && typeof this.formControl.value === 'string') {
      let valueSplit = this.formControl.value.split(':');
      let newValue = valueSplit[0].padStart(2, '0') + ':' + valueSplit[1].padStart(2, '0');
      if (newValue != this.formControl.value) {
        this.formControl.setValue(newValue);
      }
    }
  }

  ngOnInit() {
    // แก้ไขค่าทันที
    this.fixValue();

    // subscribe เพื่อแก้ไขค่าเมื่ออัพเดท
    this.formControl.valueChanges.subscribe((value) => {
      this.fixValue();
    });
  }

}
