<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>{{ 'other_items' | translate }}</h1>
        <hr />
        <div class="card">
          <h1>{{ 'questionnaire' | translate }}</h1>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="questionnaireList">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'document_type' | translate }}</th>
                <th>{{ 'survey_date' | translate }}</th>
                <th></th>
                <th style="width: 50px">{{ 'action' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.type }}</td>
                <td>{{ rowData.createdDate | date: 'dd/MM/yyyy' }}</td>
                <td>
                  <button
                    pButton
                    type="button"
                    label="{{ 'survey' | translate }}"
                    [disabled]="rowData.disabled"
                    [routerLink]="[rowData.routerLink]"
                  ></button>
                </td>
                <td>
                  <div *ngIf="rowData.id" class="grid justify-content-center icon-action">
                    <div class="col-fixed">
                      <i class="fa fa-pencil-alt" [routerLink]="[rowData.routerLink]"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="4" class="emptymessage">{{ 'no_data' | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
          <h1>{{ 'various_forms' | translate }}</h1>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="otherDocumentList">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'document_type' | translate }}</th>
                <th>{{ 'last_modified_date' | translate }}</th>
                <th *ngIf="isEditable" style="width: 50px">{{ 'action' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.type }}</td>
                <td>{{ rowData.updatedDate | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i class="fa fa-pencil-alt" [routerLink]="[rowData.routerLink]"></i>
                    </div>
                    <!-- <div class="col-fixed">
                      <i class="fa fa-trash"></i>
                    </div> -->
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="3" class="emptymessage">{{ 'no_data' | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="card" style="margin-top: 2%">
          <h1>{{ 'other' | translate }}</h1>
          <div class="col-12" style="margin-top: 3%">
            <form [formGroup]="form">
              <formly-form [model]="model" [fields]="fields" [form]="form"> </formly-form>
            </form>
          </div>
          <!-- TODO: Fix this -->
          <div class="grid justify-content-end">
            <div class="col-fixed">
              <button *ngIf="isEditable" pButton type="button" label="{{ 'record' | translate }}" (click)="updateData()"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
