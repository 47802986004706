<div class="grid align-center" style="padding:0px">
  <div class="col-fixed" style="padding-top: 14px">
    <label >{{ 'filter_s' | translate }}</label>
  </div>
  <div class="col">
    <p-dropdown
      [options]="filterList"
      [(ngModel)]="selectFilter"
      placeholder="{{ 'filter_c' | translate }}"
      optionLabel="label"
      (onChange)="onFilterDropdownChange()"
      [style]="{'width':'100%'}"
    ></p-dropdown>
  </div>
  <div class="col-fixed">
    <button
      pButton
      type="button"
      class="p-button-secondary"
      label="{{ 'record' | translate }}"
      (click)="onUpsertFilterSettingClick()"
    ></button>
  </div>
  <div class="col-fixed">
    <button
      pButton
      type="button"
      class="p-button-danger"
      label="{{ 'delete' | translate }}"
      [disabled]="!selectFilter || selectFilter.label == 'Last'"
      (click)="onDeleteFilterSetting()"
    ></button>
  </div>
</div>
