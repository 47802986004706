import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { LocationService } from 'app/api/services';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocationModel } from 'app/api/models';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'app-modal-locations',
  templateUrl: './modal-locations.component.html',
  styleUrls: ['./modal-locations.component.css'],
})
export class ModalLocationsComponent implements OnInit, OnDestroy {
  form = new FormGroup({});
  model: LocationModel = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'code',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'code',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'ชื่อ',
        translate: true,
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    // {
    //   key: 'bpCode',
    //   type: 'primeng-input',
    //   wrappers: ['inline-label'],
    //   templateOptions: {
    //     translate: true,
    //     label: 'bp_code_location',
    //     placeholder: '@blank',
    //     labelClass: 'col-4',
    //     inputClass: 'col-4',
    //   },
    // },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private locationService: LocationService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const locationId = this.getLocationId();

    if (locationId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {};
      return;
    }

    this.model = await this.locationService.GetLocationById(this.getLocationId()).toPromise();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createLocation()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateLocation()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createLocation() {
    return this.locationService.CreateLocation({
      code: this.model.code,
      name: this.model.name,
      bpCode: this.model.bpCode,
      terminateDate: '9999-09-09T00:00:00', // FIXME: set in backend?
    });
  }

  private updateLocation() {
    return this.locationService.UpdateLocation({
      LocationId: this.model.locationId,
      body: {
        locationId: this.model.locationId,
        code: this.model.code,
        name: this.model.name,
        effectiveDate: this.model.effectiveDate,
        terminateDate: this.model.terminateDate,
        createdBy: this.model.createdBy,
        createdDate: this.model.createdDate,
        bpCode: this.model.bpCode
      },
    });
  }

  getLocationId() {
    return this.config.data?.id;
  }
}
