import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { saveAs } from 'file-saver';
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal-employee-sign-up',
  templateUrl: './modal-employee-sign-up.component.html',
  styleUrls: ['./modal-employee-sign-up.component.css'],
})
export class ModalEmployeeSignUpComponent implements OnInit {
  constructor(private ref: DynamicDialogRef,private platformLocation: PlatformLocation,private router: Router,) {
    this.baseUrl = (platformLocation as any).location.origin + "/#/signup/new-employee";
  }

  qrCodeDownloadLink: string = "";
  baseUrl: string;
  ngOnInit(): void {}

  saveAsImage(parent) {
    // fetches base 64 date from image
    const parentElement = parent.el.nativeElement.querySelector("img").src;

    // converts base 64 encoded image to blobData
    let blobData = this.convertBase64ToBlob(parentElement);


      const blob = new Blob([blobData], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Qrcode';
      link.click();
    

  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }

  downloadQRCode() {
    saveAs('../../../../assets/layout/images/employee/new-employee-sign-up.png', 'new-employee-sign-up.png');
  }

  close() {
    this.ref.close();
  }

  ToSignupEmployee() {
    this.ref.close();
 }

}
