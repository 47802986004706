/* tslint:disable */
type EAuthenticationType =
  'Password' |
  'LDAP' |
  'GoogleAccount';
module EAuthenticationType {
  export const PASSWORD: EAuthenticationType = 'Password';
  export const LDAP: EAuthenticationType = 'LDAP';
  export const GOOGLE_ACCOUNT: EAuthenticationType = 'GoogleAccount';
  export function values(): EAuthenticationType[] {
    return [
      PASSWORD,
      LDAP,
      GOOGLE_ACCOUNT
    ];
  }
}

export { EAuthenticationType }