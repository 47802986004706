<p-autoComplete
  [formControl]="$any(formControl)"
  [suggestions]="to.suggestions"
  (completeMethod)="to.completeMethod(field, $event)"
  [size]="to.size || 30"
  [placeholder]="to.placeholder || ''"
  [minLength]="1"
  [dropdown]="to.dropdown || false"
  [delay]="to.delay || 300"
></p-autoComplete>
