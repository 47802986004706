import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeAdditionalFieldPaginationResult } from 'app/api/models';
import { EmployeeInShiftService, EmployeeService } from 'app/api/services';

@Component({
  selector: 'app-additional',
  templateUrl: './additional.component.html',
  styleUrls: ['./additional.component.css']
})
export class AdditionalComponent implements OnInit {

  employeeId: number;
  additionalList: any[];

  constructor(
    private employeeService: EmployeeService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.setEmployeeData();
    this.setWorkScheduleList();
  }

  setEmployeeData() {
    this.employeeId = this.getEmployeeId();
  }

  setWorkScheduleList() {
    this.employeeService
      .GetAdditional(this.employeeId)
      .subscribe((pageResult: EmployeeAdditionalFieldPaginationResult) => {
        this.additionalList = pageResult.results;
      });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }
}

