/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PayElementModelPaginationResult } from '../models/pay-element-model-pagination-result';
import { PayElementModel } from '../models/pay-element-model';
import { PayElementExportListModel } from '../models/pay-element-export-list-model';
@Injectable({
  providedIn: 'root',
})
class PayElementService extends __BaseService {
  static readonly GetPayElementListPath = '/api/PayElement';
  static readonly CreatePayElementPath = '/api/PayElement';
  static readonly UpdatePayElementPath = '/api/PayElement';
  static readonly GetPayElementExportFieldListPath = '/api/PayElement/exportfieldlist';
  static readonly GetPayElementByIdPath = '/api/PayElement/{payElementId}';
  static readonly DeletePayElementPath = '/api/PayElement/{payElementId}';
  static readonly GetPayElementIdListPath = '/api/PayElement/payElementIdList';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PayElementService.GetPayElementListParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PayElementType`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetPayElementListResponse(params: PayElementService.GetPayElementListParams): __Observable<__StrictHttpResponse<PayElementModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PayElementType != null) __params = __params.set('PayElementType', params.PayElementType.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PayElement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PayElementModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `PayElementService.GetPayElementListParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PayElementType`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetPayElementList(params: PayElementService.GetPayElementListParams): __Observable<PayElementModelPaginationResult> {
    return this.GetPayElementListResponse(params).pipe(
      __map(_r => _r.body as PayElementModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreatePayElementResponse(body?: PayElementModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PayElement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreatePayElement(body?: PayElementModel): __Observable<string> {
    return this.CreatePayElementResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdatePayElementResponse(body?: PayElementModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/PayElement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdatePayElement(body?: PayElementModel): __Observable<string> {
    return this.UpdatePayElementResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetPayElementExportFieldListResponse(): __Observable<__StrictHttpResponse<Array<PayElementExportListModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PayElement/exportfieldlist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PayElementExportListModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPayElementExportFieldList(): __Observable<Array<PayElementExportListModel>> {
    return this.GetPayElementExportFieldListResponse().pipe(
      __map(_r => _r.body as Array<PayElementExportListModel>)
    );
  }

  /**
   * @param payElementId undefined
   * @return Success
   */
  GetPayElementByIdResponse(payElementId: string): __Observable<__StrictHttpResponse<PayElementModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PayElement/${encodeURIComponent(String(payElementId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PayElementModel>;
      })
    );
  }
  /**
   * @param payElementId undefined
   * @return Success
   */
  GetPayElementById(payElementId: string): __Observable<PayElementModel> {
    return this.GetPayElementByIdResponse(payElementId).pipe(
      __map(_r => _r.body as PayElementModel)
    );
  }

  /**
   * @param payElementId undefined
   * @return Success
   */
  DeletePayElementResponse(payElementId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PayElement/${encodeURIComponent(String(payElementId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param payElementId undefined
   * @return Success
   */
  DeletePayElement(payElementId: string): __Observable<string> {
    return this.DeletePayElementResponse(payElementId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetPayElementIdListResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PayElement/payElementIdList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetPayElementIdList(): __Observable<Array<string>> {
    return this.GetPayElementIdListResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }
}

module PayElementService {

  /**
   * Parameters for GetPayElementList
   */
  export interface GetPayElementListParams {
    Status?: any;
    Sorting?: string;
    SearchText?: string;
    PayElementType?: any;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { PayElementService }
