import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as moment from 'moment';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { EmployeeInShiftModel } from 'app/api/models';
import { EmployeeInShiftService, ShiftService, EmployeeService } from 'app/api/services';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';


enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeInShiftModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-modal-employee-in-shift',
  templateUrl: './modal-employee-in-shift.component.html',
  styleUrls: ['./modal-employee-in-shift.component.css'],
  providers: [DialogService],
})
export class ModalEmployeeInShiftComponent implements OnInit, OnDestroy {
  model: EmployeeInShiftModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'shiftId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'work_schedule',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        // options: this.optionListRepo.getActiveShiftOptionList({}),
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      defaultValue: moment().format('YYYY-MM-DD'),
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'effective_date',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4'
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();
  dataId: number;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeInShiftService: EmployeeInShiftService,
    private ShiftService: ShiftService,
    private employeeService: EmployeeService,
    private optionListRepo: OptionListRepo,
  ) { }

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const employeeInShiftId = this.getEmployeeInShiftId();

    if (employeeInShiftId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    if (this.config.data?.employeeId) {
      await this.employeeService.GetEmployeeInfo(this.config.data?.employeeId).subscribe(s => {
        this.fields.find(
          a => a.key === 'shiftId',
        ).templateOptions.options = this.GetActiveShiftsListByCompanyId(s.companyId);
      })
    }
  }

  GetActiveShiftsListByCompanyId(companyId: number) {
    return this.ShiftService.GetActiveShiftsList({ CompanyId: companyId, Page: 1, ItemPerPage: 1000 }).pipe(
      map(a => a.results.map(a =>
        ({ label: `${a.code} -> ชื่อกะ : ${a.name}`, value: a.shiftId })
      )))
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    const empInShift = await this.employeeInShiftService
      .GetEmployeeInShiftById(this.getEmployeeInShiftId())
      .toPromise();

    console.log(empInShift);
    this.model = {
      ...empInShift,
      effectiveDate: (empInShift.effectiveDate),
    };

    if (this.componentMode === ComponentMode.UPDATE) {
      if (this.config.data?.employeeId == undefined) {
        await this.employeeService.GetEmployeeInfo(empInShift.employeeId).subscribe(s => {
          this.fields.find(
            a => a.key === 'shiftId',
          ).templateOptions.options = this.GetActiveShiftsListByCompanyId(s.companyId);
        })
      }
    }

  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createEmployeeInShift()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              console.log(err);
              this.notificationService.error(err?.error?.message || 'บันทึกล้มเหลว', '');
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateEmployeeInShift()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              console.log(err);
              this.notificationService.error(err?.error?.message || 'บันทึกล้มเหลว', '');
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createEmployeeInShift() {
    return this.employeeInShiftService.CreateEmployeeInShift({
      shiftId: this.model.shiftId,
      employeeId: this.model.employeeId,
      effectiveDate: this.model.effectiveDate,
    });
  }

  private updateEmployeeInShift() {
    return this.employeeInShiftService.UpdateEmployeeInShift({
      employeeInShiftId: this.model.employeeInShiftId,
      shiftId: this.model.shiftId,
      employeeId: this.model.employeeId,
      effectiveDate: this.model.effectiveDate,
      createdBy: this.model.createdBy,
      createdDate: this.model.createdDate,
    });
  }

  getEmployeeInShiftId() {
    return this.config.data?.id;
  }
}
