/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateUserForTestingParam } from '../models/create-user-for-testing-param';
import { InitializeNewDatabaseConfig } from '../models/initialize-new-database-config';
import { ApplicationSettingsExtended } from '../models/application-settings-extended';
@Injectable({
  providedIn: 'root',
})
class SystemService extends __BaseService {
  static readonly RunHouseKeepingTaskPath = '/api/System/run/housekeeping';
  static readonly UpdateOrganizationStatePath = '/api/System/run/updateorganizationstate';
  static readonly CreateUserForTestingPath = '/api/System/run/create-user-for-testing';
  static readonly InitializeNewDatabasePath = '/api/System/run/initialize-new-database';
  static readonly CreateUsersFromEmployeesPath = '/api/System/run/create-users-from-employees';
  static readonly MigrationPath = '/api/System/run/migrate-database';
  static readonly SeedMasterDataPath = '/api/System/run/seed-master-data';
  static readonly UploadFileFingerscanPath = '/api/System/run/uploadfile/fingerscan';
  static readonly CheckAndRepairDataPath = '/api/System/run/check-and-repair-data';
  static readonly AddRandomAvatarToEmployeeForDemoPath = '/api/System/run/AddRandomAvatarToEmployeeForDemo';
  static readonly ApplyEmployeeDataMaskingToAllEmployeeForDemoPath = '/api/System/run/ApplyEmployeeDataMaskingToAllEmployeeForDemo';
  static readonly ApplyUnitDataMaskingToAllForDemoPath = '/api/System/run/ApplyUnitDataMaskingToAllForDemo';
  static readonly TerminateNotCleanEmployeeForDemoPath = '/api/System/run/TerminateNotCleanEmployeeForDemo';
  static readonly ImportUnitPath = '/api/System/run/importunit';
  static readonly UpdateUserInCompanyPath = '/api/System/run/UpdateUserInCompany';
  static readonly GetApplicationSettingsExtendedPath = '/api/System/application-settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SystemService.RunHouseKeepingTaskParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `retensionDays`:
   *
   * @return Success
   */
  RunHouseKeepingTaskResponse(params: SystemService.RunHouseKeepingTaskParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.secret != null) __params = __params.set('secret', params.secret.toString());
    if (params.retensionDays != null) __params = __params.set('retensionDays', params.retensionDays.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/housekeeping`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `SystemService.RunHouseKeepingTaskParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `retensionDays`:
   *
   * @return Success
   */
  RunHouseKeepingTask(params: SystemService.RunHouseKeepingTaskParams): __Observable<string> {
    return this.RunHouseKeepingTaskResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param secret undefined
   * @return Success
   */
  UpdateOrganizationStateResponse(secret?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (secret != null) __params = __params.set('secret', secret.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/updateorganizationstate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param secret undefined
   * @return Success
   */
  UpdateOrganizationState(secret?: string): __Observable<string> {
    return this.UpdateOrganizationStateResponse(secret).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `SystemService.CreateUserForTestingParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `body`:
   *
   * @return Success
   */
  CreateUserForTestingResponse(params: SystemService.CreateUserForTestingParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.secret != null) __params = __params.set('secret', params.secret.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/create-user-for-testing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `SystemService.CreateUserForTestingParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `body`:
   *
   * @return Success
   */
  CreateUserForTesting(params: SystemService.CreateUserForTestingParams): __Observable<string> {
    return this.CreateUserForTestingResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `SystemService.InitializeNewDatabaseParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `body`:
   *
   * @return Success
   */
  InitializeNewDatabaseResponse(params: SystemService.InitializeNewDatabaseParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.secret != null) __params = __params.set('secret', params.secret.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/initialize-new-database`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param params The `SystemService.InitializeNewDatabaseParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `body`:
   *
   * @return Success
   */
  InitializeNewDatabase(params: SystemService.InitializeNewDatabaseParams): __Observable<Array<string>> {
    return this.InitializeNewDatabaseResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param params The `SystemService.CreateUsersFromEmployeesParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `isUseBirthDateAsPassword`:
   *
   * @return Success
   */
  CreateUsersFromEmployeesResponse(params: SystemService.CreateUsersFromEmployeesParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.secret != null) __params = __params.set('secret', params.secret.toString());
    if (params.isUseBirthDateAsPassword != null) __params = __params.set('isUseBirthDateAsPassword', params.isUseBirthDateAsPassword.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/create-users-from-employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `SystemService.CreateUsersFromEmployeesParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `isUseBirthDateAsPassword`:
   *
   * @return Success
   */
  CreateUsersFromEmployees(params: SystemService.CreateUsersFromEmployeesParams): __Observable<string> {
    return this.CreateUsersFromEmployeesResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param secret undefined
   * @return Success
   */
  MigrationResponse(secret?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (secret != null) __params = __params.set('secret', secret.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/migrate-database`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param secret undefined
   * @return Success
   */
  Migration(secret?: string): __Observable<string> {
    return this.MigrationResponse(secret).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param secret undefined
   * @return Success
   */
  SeedMasterDataResponse(secret?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (secret != null) __params = __params.set('secret', secret.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/seed-master-data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param secret undefined
   * @return Success
   */
  SeedMasterData(secret?: string): __Observable<string> {
    return this.SeedMasterDataResponse(secret).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `SystemService.UploadFileFingerscanParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `file`:
   *
   * @return Success
   */
  UploadFileFingerscanResponse(params: SystemService.UploadFileFingerscanParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.secret != null) __params = __params.set('secret', params.secret.toString());
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/uploadfile/fingerscan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `SystemService.UploadFileFingerscanParams` containing the following parameters:
   *
   * - `secret`:
   *
   * - `file`:
   *
   * @return Success
   */
  UploadFileFingerscan(params: SystemService.UploadFileFingerscanParams): __Observable<string> {
    return this.UploadFileFingerscanResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param secret undefined
   * @return Success
   */
  CheckAndRepairDataResponse(secret?: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (secret != null) __params = __params.set('secret', secret.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/check-and-repair-data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param secret undefined
   * @return Success
   */
  CheckAndRepairData(secret?: string): __Observable<Array<string>> {
    return this.CheckAndRepairDataResponse(secret).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param secret undefined
   * @return Success
   */
  AddRandomAvatarToEmployeeForDemoResponse(secret?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (secret != null) __params = __params.set('secret', secret.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/AddRandomAvatarToEmployeeForDemo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param secret undefined
   * @return Success
   */
  AddRandomAvatarToEmployeeForDemo(secret?: string): __Observable<string> {
    return this.AddRandomAvatarToEmployeeForDemoResponse(secret).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param secret undefined
   * @return Success
   */
  ApplyEmployeeDataMaskingToAllEmployeeForDemoResponse(secret?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (secret != null) __params = __params.set('secret', secret.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/ApplyEmployeeDataMaskingToAllEmployeeForDemo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param secret undefined
   * @return Success
   */
  ApplyEmployeeDataMaskingToAllEmployeeForDemo(secret?: string): __Observable<string> {
    return this.ApplyEmployeeDataMaskingToAllEmployeeForDemoResponse(secret).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param secret undefined
   * @return Success
   */
  ApplyUnitDataMaskingToAllForDemoResponse(secret?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (secret != null) __params = __params.set('secret', secret.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/ApplyUnitDataMaskingToAllForDemo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param secret undefined
   * @return Success
   */
  ApplyUnitDataMaskingToAllForDemo(secret?: string): __Observable<string> {
    return this.ApplyUnitDataMaskingToAllForDemoResponse(secret).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param secret undefined
   * @return Success
   */
  TerminateNotCleanEmployeeForDemoResponse(secret?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (secret != null) __params = __params.set('secret', secret.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/TerminateNotCleanEmployeeForDemo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param secret undefined
   * @return Success
   */
  TerminateNotCleanEmployeeForDemo(secret?: string): __Observable<string> {
    return this.TerminateNotCleanEmployeeForDemoResponse(secret).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  ImportUnitResponse(file?: Blob): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/run/importunit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  ImportUnit(file?: Blob): __Observable<string> {
    return this.ImportUnitResponse(file).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  UpdateUserInCompanyResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/System/run/UpdateUserInCompany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  UpdateUserInCompany(): __Observable<string> {
    return this.UpdateUserInCompanyResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetApplicationSettingsExtendedResponse(): __Observable<__StrictHttpResponse<ApplicationSettingsExtended>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/System/application-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApplicationSettingsExtended>;
      })
    );
  }
  /**
   * @return Success
   */
  GetApplicationSettingsExtended(): __Observable<ApplicationSettingsExtended> {
    return this.GetApplicationSettingsExtendedResponse().pipe(
      __map(_r => _r.body as ApplicationSettingsExtended)
    );
  }
}

module SystemService {

  /**
   * Parameters for RunHouseKeepingTask
   */
  export interface RunHouseKeepingTaskParams {
    secret?: string;
    retensionDays?: number;
  }

  /**
   * Parameters for CreateUserForTesting
   */
  export interface CreateUserForTestingParams {
    secret?: string;
    body?: CreateUserForTestingParam;
  }

  /**
   * Parameters for InitializeNewDatabase
   */
  export interface InitializeNewDatabaseParams {
    secret?: string;
    body?: InitializeNewDatabaseConfig;
  }

  /**
   * Parameters for CreateUsersFromEmployees
   */
  export interface CreateUsersFromEmployeesParams {
    secret?: string;
    isUseBirthDateAsPassword?: boolean;
  }

  /**
   * Parameters for UploadFileFingerscan
   */
  export interface UploadFileFingerscanParams {
    secret?: string;
    file?: Blob;
  }
}

export { SystemService }
