import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmployeeCertificateModel } from 'app/api/models';
import { EmployeeCertificateService, EmployeeService } from 'app/api/services';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { ModalEmployeeCertificateComponent } from '../../../modals/core/modal-employee-certificate/modal-employee-certificate.component';

@Component({
  selector: 'app-my-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css'],
  providers: [DialogService],
})
export class CoreCertificateComponent implements OnInit {
  isEditable = false;
  certificateList: EmployeeCertificateModel[];
  ref: DynamicDialogRef;

  constructor(
    private layout: LayoutDefaultComponent,
    private router: Router,
    private notificationService: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeCertificateService: EmployeeCertificateService,
    private employeeService: EmployeeService,
  ) {}

  ngOnInit() {
    console.log(`core certificate component: ${this.router.url}`);
    this.setEditablePermission();
    this.setCertificateList();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setCertificateList() {
    const employeeId = this.getEmployeeId();
    console.log(`call certificate list for employee id: ${employeeId}`);
    this.employeeService.GetCertificates(employeeId).subscribe(cefrtificates => {
      this.certificateList = cefrtificates;
    });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  showCreateCertificateDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่ม Certificate', ModalEmployeeCertificateComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setCertificateList();
      }
    });
  }

  showUpdateCertificateDialog(certificateId: number) {
    this.showDialog({ id: certificateId }, 'แก้ไข Certificate', ModalEmployeeCertificateComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setCertificateList();
      }
    });
  }

  showDeleteCertificateDialog(certificateId: number) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeeCertificateService.DeleteEmployeeCertificate(certificateId).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.setCertificateList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  getYearFromDateISOString(ISODate: string) {
    return new Date(ISODate).getFullYear();
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }
}
