import { NgModule } from '@angular/core';
import { DayHourFormatPipe } from './day-hour-format.pipe';
import { MapValueByKeyPipe } from './map-value-by-key.pipe';
import { NaCheckPipe } from './na-check.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeNumber } from './safe-number.pipe';
import { TimeFormatPipe } from './time-format';

const pipes = [SafeHtmlPipe, TimeFormatPipe, DayHourFormatPipe, MapValueByKeyPipe, NaCheckPipe, SafeNumber];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class PipeModule {}
