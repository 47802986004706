<div *ngFor="let afield of field.fieldGroup; let i = index" class="grid">
  <div class="col-6">
    <formly-field [field]="afield"></formly-field>
  </div>
  <div class="col-fixed" *ngIf="i !== 0">
    <i class="fa fa-trash text-remove" (click)="remove(i)"></i>
  </div>
  <div class="col-fixed" *ngIf="field.fieldGroup.length === i + 1">
    <button pButton type="button" (click)="add()" label="{{ to?.addText }}"></button>
  </div>
</div>
