import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { IntegerValidator } from 'app/formly-template/integer.validator';
import { MasterDataRepo } from 'app/repo/master-data.repo';

export interface EmployeeEducationCreateModel {
  employeeEducations: {
    educationLevelId?: string;
    location?: string;
    institute?: string;
    educationalBackground?: string;
    fieldName?: string;
    startYear?: number;
    endYear?: number;
    gpa?: string;
  }[];
  employeeLanguages: {
    languageId?: string;
    speakingProficiency?: number;
    listeningProficiency?: number;
    readingProficiency?: number;
    writingProficiency?: number;
    toeicScore?: number;
    tofelScore?: number;
    ieltsScore?: number;
    otherScore?: number;
  }[];
  typingSkill: {
    isHasTypingSkill: boolean;
    th?: string;
    eng?: string;
  };
  drivingSkill: {
    isHasDrivingSkill: boolean;
    isHasPersonalCar?: boolean;
    isDontHasPersonalCar?: boolean;
    licenseNumber?: string;
    expireDate?: string;
  };
  mortorCyclingSkill: {
    isHasMortorCyclingSkill: boolean;
    isHasMotorcycle?: boolean;
    isDontHasMotorcycle?: boolean;
    motorcycleLicenseNumber?: string;
    motorcycleExpireDate?: string;
  };
  // otherSkill: {
  //   isHasOtherSkill: boolean
  //   otherSkillList: {
  //     skillType?: string
  //     skillValue?: string
  //   }[]
  // };
}

@Component({
  selector: 'app-employee-create-form-education',
  templateUrl: './employee-create-form-education.component.html',
  styleUrls: ['./employee-create-form-education.component.css'],
})
export class EmployeeCreateFormEducationComponent implements OnInit {
  @Input('defaultModel')
  set defaultModel(val) {
    if (val) {
      Object.assign(this.model, val);
    }
  }

  isShowErr = true;

  form = new FormGroup({});
  model: EmployeeEducationCreateModel = {
    employeeEducations: [],
    employeeLanguages: [],
    typingSkill: { isHasTypingSkill: false },
    drivingSkill: { isHasDrivingSkill: false },
    mortorCyclingSkill: { isHasMortorCyclingSkill: false },
    // otherSkill: { isHasOtherSkill: false, otherSkillList: [] },
  };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'employeeEducations',
      type: 'repeat',
      templateOptions: {
        addText: '+ เพิ่มประวัติการศึกษา',
        bigTitle: 'ประวัติการศึกษา ',
        littleTitle: 'ลำดับที่ ',
        deleteTitle: 'ลบข้อมูลประวัติการศึกษา',
      },
      fieldArray: {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            type: 'filter-dropdown',
            key: 'educationLevelId',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%;',
              },
              placeholder: '@blank',
              label: 'education_level',
              required: true,
              labelClass: 'col-4',
              inputClass: 'col-6',
              options: this.masterDataRepo.getEducationLevelOptionList(),
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'location',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 't_location',
              labelClass: 'col-4',
              inputClass: 'col-6',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'institute',
            wrappers: ['inline-label'],
            className: 'col-12',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'educational_name',
              required: true,
              labelClass: 'col-2',
              inputClass: 'col-9',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'educationalBackground',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'qualifications_earned',
              labelClass: 'col-4',
              inputClass: 'col-6',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'fieldName',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'branch',
              labelClass: 'col-4',
              inputClass: 'col-6',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'startYear',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'since_year',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
              min: 0,
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'endYear',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'to_year',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
              min: 0,
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'gpa',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'average_score',
              labelClass: 'col-4',
              inputClass: 'col-6',
            },
            validation: {
              show: this.isShowErr,
            },
          },
        ],
      },
    },
    // {
    //   template: `<hr style="margin-top: 20px; margin-bottom: 20px;"><h3>ความสามารถทางภาษา</h3>`,
    // },
    {
      key: 'employeeLanguages',
      type: 'repeat',
      templateOptions: {
        addText: '+ เพิ่มความสามารถทางภาษา',
        bigTitle: 'ความสามารถทางภาษา ',
        littleTitle: 'ลำดับที่ ',
        deleteTitle: 'ลบข้อมูลความสามารถทางภาษา',
      },
      fieldArray: {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            type: 'filter-dropdown',
            key: 'languageId',
            wrappers: ['inline-label'],
            className: 'col-12',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%;',
              },
              placeholder: '@blank',
              label: 'language',
              required: true,
              labelClass: 'col-2',
              inputClass: 'col-3',
              options: this.masterDataRepo.getLanguageOptionList(),
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'speakingProficiency',
            type: 'radio',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'speaking',
              placeholder: '@blank',
              required: true,
              labelClass: 'col-4',
              inputClass: 'col-6',
              options: [
                { value: 1, label: 'ดีมาก' },
                { value: 2, label: 'ดี' },
                { value: 3, label: 'พอใช้' },
              ],
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'listeningProficiency',
            type: 'radio',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'listening',
              placeholder: '@blank',
              required: true,
              labelClass: 'col-4',
              inputClass: 'col-6',
              options: [
                { value: 1, label: 'ดีมาก' },
                { value: 2, label: 'ดี' },
                { value: 3, label: 'พอใช้' },
              ],
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'readingProficiency',
            type: 'radio',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'read',
              placeholder: '@blank',
              required: true,
              labelClass: 'col-4',
              inputClass: 'col-6',
              options: [
                { value: 1, label: 'ดีมาก' },
                { value: 2, label: 'ดี' },
                { value: 3, label: 'พอใช้' },
              ],
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'writingProficiency',
            type: 'radio',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 't_write',
              placeholder: '@blank',
              required: true,
              labelClass: 'col-4',
              inputClass: 'col-6',
              options: [
                { value: 1, label: 'ดีมาก' },
                { value: 2, label: 'ดี' },
                { value: 3, label: 'พอใช้' },
              ],
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'toeicScore',
            type: 'primeng-input',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'toeic',
              placeholder: '@blank',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
            },
            validators: {
              validation: [IntegerValidator]
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'tofelScore',
            type: 'primeng-input',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'toefl',
              placeholder: '@blank',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
            },
            validators: {
              validation: [IntegerValidator]
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'ieltsScore',
            type: 'primeng-input',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'itlst',
              placeholder: '@blank',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
            },
            validators: {
              validation: [IntegerValidator]
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            key: 'otherScore',
            type: 'primeng-input',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'other',
              placeholder: '@blank',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
            },
            validators: {
              validation: [IntegerValidator]
            },
            validation: {
              show: this.isShowErr,
            },
          },
        ],
      },
    },
    {
      template: `<hr style="margin-top: 20px; margin-bottom: 20px;"><h3>ความสามารถพิเศษอื่นๆ</h3>`,
    },
    {
      fieldGroupClassName: 'grid',
      key: 'typingSkill',
      fieldGroup: [
        {
          key: 'isHasTypingSkill',
          type: 'checkbox',
          className: 'col-2',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'typewriting',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            change: () => {},
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'primeng-input',
          key: 'th',
          wrappers: ['inline-label'],
          className: 'col-4',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            label: 'thai_language',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasTypingSkill',
        },
        {
          type: 'primeng-input',
          key: 'eng',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            label: 'english_language',
            placeholder: '@blank',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasTypingSkill',
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      key: 'drivingSkill',
      fieldGroup: [
        {
          key: 'isHasDrivingSkill',
          type: 'checkbox',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'driving_car',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            change: () => {
              // this.form.controls.isDontHasPersonalCar.setValue(false);
              // this.form.controls.isHasPersonalCar.setValue(false);
              // this.form.controls.licenseNumber.setValue(null);
              // this.form.controls.expireDate.setValue(null);
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        // {
        //   key: 'label1',
        //   type: 'primeng-input',
        //   wrappers: ['inline-label'],
        //   className: 'col-2',
        //   templateOptions: {
        //     translate: true,
        //     placeholder: '@blank',
        //     label: 'have_own_car',
        //     type: 'hidden',
        //     labelClass: 'col-12',
        //     inputClass: 'col',
        //   },
        //   validation: {
        //     show: this.isShowErr,
        //   },
        //   hideExpression: '!model.isHasDrivingSkill',
        // },
        {
          key: 'isHasPersonalCar',
          type: 'radio',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            attributes: {
              style: 'width: 100%;',
            },
            label: 'มีรถยนต์ส่วนตัวหรือไม่',
            labelClass: 'col-2',
            inputClass: 'col-2',
            options: [
              { value: true, label: 'มี' },
              { value: false, label: 'ไม่มี' },
            ],
          },
          defaultValue: false,
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasDrivingSkill',
        },
        // {
        //   key: 'isHasPersonalCar',
        //   type: 'checkbox',
        //   className: 'col-1',
        //   wrappers: ['inline-label'],
        //   templateOptions: {
        //     translate: true,
        //     placeholder: '@blank',
        //     attributes: {
        //       style: 'width: 100%;',
        //     },
        //     label: 'answer1',
        //     labelClass: 'col',
        //     inputClass: 'col',
        //     change: () => {
        //       if ((this.form.controls.drivingSkill as FormGroup).controls.isHasPersonalCar) {
        //         (this.form.controls.drivingSkill as FormGroup).controls.isDontHasPersonalCar.setValue(false);
        //       }
        //     },
        //   },
        //   validation: {
        //     show: this.isShowErr,
        //   },
        //   hideExpression: '!model.isHasDrivingSkill',
        // },
        // {
        //   key: 'isDontHasPersonalCar',
        //   type: 'checkbox',
        //   className: 'col-9',
        //   wrappers: ['inline-label'],
        //   templateOptions: {
        //     translate: true,
        //     placeholder: '@blank',
        //     attributes: {
        //       style: 'width: 100%;',
        //     },
        //     label: 'answer2',
        //     labelClass: 'col-1',
        //     inputClass: 'col',
        //     change: () => {
        //       if ((this.form.controls.drivingSkill as FormGroup).controls.isDontHasPersonalCar) {
        //         (this.form.controls.drivingSkill as FormGroup).controls.isHasPersonalCar.setValue(false);
        //         // this.form.controls.licenseNumber.setValue(null);
        //         // this.form.controls.expireDate.setValue(null);
        //       }
        //     },
        //   },
        //   validation: {
        //     show: this.isShowErr,
        //   },
        //   hideExpression: '!model.isHasDrivingSkill',
        // },
        {
          className: 'col-6',
          type: 'primeng-input',
          key: 'licenseNumber',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,

            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'driver_license_number',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasDrivingSkill',
        },
        {
          className: 'col-6',
          type: 'calendar',
          key: 'expireDate',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'expiration_date',

            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasDrivingSkill',
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      key: 'mortorCyclingSkill',
      fieldGroup: [
        {
          key: 'isHasMortorCyclingSkill',
          type: 'checkbox',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            attributes: {
              style: 'width: 100%;',
            },
            label: 'driving_motorcycle',
            labelClass: 'col',
            inputClass: 'col',
            change: () => {
              // this.form.controls.isHasMotorcycle.setValue(false);
              // this.form.controls.isDontHasMotorcycle.setValue(false);
              // this.form.controls.motorcycleLicenseNumber.setValue(null);
              // this.form.controls.motorcycleExpireDate.setValue(null);
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        // {
        //   key: 'label1',
        //   type: 'primeng-input',
        //   wrappers: ['inline-label'],
        //   className: 'col-2',
        //   templateOptions: {
        //     translate: true,
        //     placeholder: '@blank',
        //     label: 'have_own_motorcycle',
        //     type: 'hidden',
        //     labelClass: 'col-12',
        //     inputClass: 'col',
        //   },
        //   validation: {
        //     show: this.isShowErr,
        //   },
        //   hideExpression: '!model.isHasMortorCyclingSkill',
        // },
        {
          key: 'isHasMotorcycle',
          type: 'radio',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            attributes: {
              style: 'width: 100%;',
            },
            label: 'มีจักรยานยนต์ส่วนตัวหรือไม่',
            labelClass: 'col-2',
            inputClass: 'col-2',
            options: [
              { value: true, label: 'มี' },
              { value: false, label: 'ไม่มี' },
            ],
          },
          defaultValue: false,
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasMortorCyclingSkill',
        },
        // {
        //   key: 'isHasMotorcycle',
        //   type: 'checkbox',
        //   className: 'col-1',
        //   wrappers: ['inline-label'],
        //   templateOptions: {
        //     translate: true,
        //     placeholder: '@blank',
        //     attributes: {
        //       style: 'width: 100%;',
        //     },
        //     label: 'answer1',
        //     labelClass: 'col',
        //     inputClass: 'col',
        //     change: () => {
        //       if ((this.form.controls.mortorCyclingSkill as FormGroup).controls.isHasMotorcycle) {
        //         (this.form.controls.mortorCyclingSkill as FormGroup).controls.isDontHasMotorcycle.setValue(false);
        //       }
        //     },
        //   },
        //   validation: {
        //     show: this.isShowErr,
        //   },
        //   hideExpression: '!model.isHasMortorCyclingSkill',
        // },
        // {
        //   key: 'isDontHasMotorcycle',
        //   type: 'checkbox',
        //   className: 'col-9',
        //   wrappers: ['inline-label'],
        //   templateOptions: {
        //     translate: true,
        //     placeholder: '@blank',
        //     attributes: {
        //       style: 'width: 100%;',
        //     },
        //     label: 'answer2',
        //     labelClass: 'col-1',
        //     inputClass: 'col',
        //     change: () => {
        //       if ((this.form.controls.mortorCyclingSkill as FormGroup).controls.isDontHasMotorcycle) {
        //         (this.form.controls.mortorCyclingSkill as FormGroup).controls.isHasMotorcycle.setValue(false);
        //         // this.form.controls.motorcycleLicenseNumber.setValue(null);
        //         // this.form.controls.motorcycleExpireDate.setValue(null);
        //       }
        //     },
        //   },
        //   validation: {
        //     show: this.isShowErr,
        //   },
        //   hideExpression: '!model.isHasMortorCyclingSkill',
        // },
        {
          className: 'col-6',
          type: 'primeng-input',
          key: 'motorcycleLicenseNumber',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'driver_license_number',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasMortorCyclingSkill',
        },
        {
          className: 'col-6',
          type: 'calendar',
          key: 'motorcycleExpireDate',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'expiration_date',

            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isHasMortorCyclingSkill',
        },
      ],
    },
    // {
    //   fieldGroupClassName: 'grid',
    //   key: 'otherSkill',
    //   fieldGroup: [
    //     {
    //       key: 'isHasOtherSkill',
    //       type: 'checkbox',
    //       className: 'col-12',
    //       wrappers: ['inline-label'],
    //       templateOptions: {
    //         attributes: {
    //           style: 'width: 100%;',
    //         },
    //         label: 'ความสามารถอื่นๆ',
    //         labelClass: 'col',
    //         inputClass: 'col',
    //         change: () => {

    //         }
    //       }
    //     },
    //     {
    //       key: 'otherSkillList',
    //       type: 'repeat-education',
    //       className: 'col-12',
    //       templateOptions: {
    //         addText: '+ เพิ่มความสามารถ',
    //         deleteTitle: 'ลบ',
    //       },
    //       fieldArray: {
    //         fieldGroupClassName: 'grid',
    //         fieldGroup: [
    //           {
    //             type: 'primeng-input',
    //             key: 'skillType',
    //             wrappers: ['inline-label'],
    //             className: 'col-6',
    //             templateOptions: {
    //               attributes: {
    //                 style: 'margin-left: -3%;'
    //               },
    //               placeholder: 'ประเภทความสามารถ',
    //               label: 'ประเภทความสามารถ:',
    //               required: true,
    //               labelClass: 'col-5',
    //               inputClass: 'col-7',
    //               // options: this.masterDataRepo.geSkillTypeOptionList(),
    //             },
    //           },
    //           {
    //             type: 'primeng-input',
    //             key: 'skillValue',
    //             wrappers: ['inline-label'],
    //             className: 'col-6',
    //             templateOptions: {
    //               attributes: {
    //                 style: 'width: 110%; margin-left: -3%;'
    //               },
    //               required: true,
    //               labelClass: 'col',
    //               inputClass: 'col-12',
    //             },
    //           },
    //         ],
    //       },
    //     },
    //   ]
    // },
  ];

  constructor(private masterDataRepo: MasterDataRepo) {}

  ngOnInit(): void {}

  submit() {
    console.log(this.model);
  }
}
