<div class="login-content" [hidden]="!appSettings">
  <div class=" p-fluid" style="width: 99%">
    <div class="grid justify-content-center">
      <div class="col-12 lg:col-8 md:col-12" style="background-color: white; margin-top: 20vh">
        <div class="grid justify-content-center">
          <div class="col-fixed">
            <h2><b>ตั้งค่ารหัสผ่าน</b></h2>
          </div>
          <div class="col-12">
            <div class="grid justify-content-center">
              <div class="col-fixed">
                <div class="logo">
                  <div class="menu-logo"
                  [ngStyle]="{'background-image': 'url(/api/document/' + appSettings.webLogoLargeImageId + '/download)'}"
                    alt="mirage-layout"></div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin: 1%"></div>
          <div class="col-12">
            <div #blockTarget class="p-fluid">
              <div class="grid">
                <div class="col-12">
                  <form [formGroup]="form">
                    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-fixed">
            <button type="button" pButton class="p-button-secondary" label="บันทึก" [disabled]="form.invalid"
              (click)="submit()"></button>
          </div>
        </div>
      </div>

      <!-- <div class="desert"></div> -->
    </div>
  </div>
</div>
