/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AttendanceCertifyRequestModelPaginationResult } from '../models/attendance-certify-request-model-pagination-result';
import { AttendanceCertifyRequestModel } from '../models/attendance-certify-request-model';
@Injectable({
  providedIn: 'root',
})
class AttendanceCertifyRequestService extends __BaseService {
  static readonly GetAttendanceCertifyRequestsListPath = '/api/AttendanceCertifyRequest';
  static readonly CreateAttendanceCertifyRequestPath = '/api/AttendanceCertifyRequest';
  static readonly UpdateAttendanceCertifyRequestPath = '/api/AttendanceCertifyRequest';
  static readonly GetMyAttendanceCertifyRequestsListPath = '/api/AttendanceCertifyRequest/MyAttendanceCertifyRequest';
  static readonly GetAttendanceCertifyRequestByIdPath = '/api/AttendanceCertifyRequest/{attendanceCertifyRequestId}';
  static readonly DeleteAttendanceCertifyRequestPath = '/api/AttendanceCertifyRequest/{attendanceCertifyRequestId}';
  static readonly CancelAttendanceCertifyRequestPath = '/api/AttendanceCertifyRequest/{attendanceCertifyRequestId}/cancel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AttendanceCertifyRequestService.GetAttendanceCertifyRequestsListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestDate`:
   *
   * - `PositionId`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * - `AttendanceCertifyTypeId`:
   *
   * - `AttendanceCertifyStatusId`:
   *
   * - `AttendanceCertifyDate`:
   *
   * @return Success
   */
  GetAttendanceCertifyRequestsListResponse(params: AttendanceCertifyRequestService.GetAttendanceCertifyRequestsListParams): __Observable<__StrictHttpResponse<AttendanceCertifyRequestModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RequestDate != null) __params = __params.set('RequestDate', params.RequestDate.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.PersonnelAreaId != null) __params = __params.set('PersonnelAreaId', params.PersonnelAreaId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    if (params.AttendanceCertifyTypeId != null) __params = __params.set('AttendanceCertifyTypeId', params.AttendanceCertifyTypeId.toString());
    if (params.AttendanceCertifyStatusId != null) __params = __params.set('AttendanceCertifyStatusId', params.AttendanceCertifyStatusId.toString());
    if (params.AttendanceCertifyDate != null) __params = __params.set('AttendanceCertifyDate', params.AttendanceCertifyDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AttendanceCertifyRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttendanceCertifyRequestModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `AttendanceCertifyRequestService.GetAttendanceCertifyRequestsListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestDate`:
   *
   * - `PositionId`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * - `AttendanceCertifyTypeId`:
   *
   * - `AttendanceCertifyStatusId`:
   *
   * - `AttendanceCertifyDate`:
   *
   * @return Success
   */
  GetAttendanceCertifyRequestsList(params: AttendanceCertifyRequestService.GetAttendanceCertifyRequestsListParams): __Observable<AttendanceCertifyRequestModelPaginationResult> {
    return this.GetAttendanceCertifyRequestsListResponse(params).pipe(
      __map(_r => _r.body as AttendanceCertifyRequestModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateAttendanceCertifyRequestResponse(body?: AttendanceCertifyRequestModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AttendanceCertifyRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateAttendanceCertifyRequest(body?: AttendanceCertifyRequestModel): __Observable<number> {
    return this.CreateAttendanceCertifyRequestResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateAttendanceCertifyRequestResponse(body?: AttendanceCertifyRequestModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/AttendanceCertifyRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateAttendanceCertifyRequest(body?: AttendanceCertifyRequestModel): __Observable<string> {
    return this.UpdateAttendanceCertifyRequestResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `AttendanceCertifyRequestService.GetMyAttendanceCertifyRequestsListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestDate`:
   *
   * - `PositionId`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * - `AttendanceCertifyTypeId`:
   *
   * - `AttendanceCertifyStatusId`:
   *
   * - `AttendanceCertifyDate`:
   *
   * @return Success
   */
  GetMyAttendanceCertifyRequestsListResponse(params: AttendanceCertifyRequestService.GetMyAttendanceCertifyRequestsListParams): __Observable<__StrictHttpResponse<AttendanceCertifyRequestModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RequestDate != null) __params = __params.set('RequestDate', params.RequestDate.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.PersonnelAreaId != null) __params = __params.set('PersonnelAreaId', params.PersonnelAreaId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    if (params.AttendanceCertifyTypeId != null) __params = __params.set('AttendanceCertifyTypeId', params.AttendanceCertifyTypeId.toString());
    if (params.AttendanceCertifyStatusId != null) __params = __params.set('AttendanceCertifyStatusId', params.AttendanceCertifyStatusId.toString());
    if (params.AttendanceCertifyDate != null) __params = __params.set('AttendanceCertifyDate', params.AttendanceCertifyDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AttendanceCertifyRequest/MyAttendanceCertifyRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttendanceCertifyRequestModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `AttendanceCertifyRequestService.GetMyAttendanceCertifyRequestsListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestDate`:
   *
   * - `PositionId`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * - `AttendanceCertifyTypeId`:
   *
   * - `AttendanceCertifyStatusId`:
   *
   * - `AttendanceCertifyDate`:
   *
   * @return Success
   */
  GetMyAttendanceCertifyRequestsList(params: AttendanceCertifyRequestService.GetMyAttendanceCertifyRequestsListParams): __Observable<AttendanceCertifyRequestModelPaginationResult> {
    return this.GetMyAttendanceCertifyRequestsListResponse(params).pipe(
      __map(_r => _r.body as AttendanceCertifyRequestModelPaginationResult)
    );
  }

  /**
   * @param attendanceCertifyRequestId undefined
   * @return Success
   */
  GetAttendanceCertifyRequestByIdResponse(attendanceCertifyRequestId: number): __Observable<__StrictHttpResponse<AttendanceCertifyRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AttendanceCertifyRequest/${encodeURIComponent(String(attendanceCertifyRequestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttendanceCertifyRequestModel>;
      })
    );
  }
  /**
   * @param attendanceCertifyRequestId undefined
   * @return Success
   */
  GetAttendanceCertifyRequestById(attendanceCertifyRequestId: number): __Observable<AttendanceCertifyRequestModel> {
    return this.GetAttendanceCertifyRequestByIdResponse(attendanceCertifyRequestId).pipe(
      __map(_r => _r.body as AttendanceCertifyRequestModel)
    );
  }

  /**
   * @param attendanceCertifyRequestId undefined
   * @return Success
   */
  DeleteAttendanceCertifyRequestResponse(attendanceCertifyRequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/AttendanceCertifyRequest/${encodeURIComponent(String(attendanceCertifyRequestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param attendanceCertifyRequestId undefined
   * @return Success
   */
  DeleteAttendanceCertifyRequest(attendanceCertifyRequestId: number): __Observable<string> {
    return this.DeleteAttendanceCertifyRequestResponse(attendanceCertifyRequestId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param attendanceCertifyRequestId undefined
   * @return Success
   */
  CancelAttendanceCertifyRequestResponse(attendanceCertifyRequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/AttendanceCertifyRequest/${encodeURIComponent(String(attendanceCertifyRequestId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param attendanceCertifyRequestId undefined
   * @return Success
   */
  CancelAttendanceCertifyRequest(attendanceCertifyRequestId: number): __Observable<string> {
    return this.CancelAttendanceCertifyRequestResponse(attendanceCertifyRequestId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module AttendanceCertifyRequestService {

  /**
   * Parameters for GetAttendanceCertifyRequestsList
   */
  export interface GetAttendanceCertifyRequestsListParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    RequestDate?: string;
    PositionId?: number;
    PersonnelAreaId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsAllEmployee?: boolean;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    CompanyId?: number;
    BranchId?: number;
    AttendanceCertifyTypeId?: string;
    AttendanceCertifyStatusId?: any;
    AttendanceCertifyDate?: string;
  }

  /**
   * Parameters for GetMyAttendanceCertifyRequestsList
   */
  export interface GetMyAttendanceCertifyRequestsListParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    RequestDate?: string;
    PositionId?: number;
    PersonnelAreaId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsAllEmployee?: boolean;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    CompanyId?: number;
    BranchId?: number;
    AttendanceCertifyTypeId?: string;
    AttendanceCertifyStatusId?: any;
    AttendanceCertifyDate?: string;
  }
}

export { AttendanceCertifyRequestService }
