import { Component, OnInit, EventEmitter } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ReplaySubject, Subject } from 'rxjs';
import { PositionService } from 'app/api/services';
import { PositionModel } from 'app/api/models';
import { ModalPositionSearchComponent } from 'modals/core/modal-position-search/modal-position-search.component';
import { finalize } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-formly-select-position',
  templateUrl: './formly-select-position.component.html',
  styleUrls: ['./formly-select-position.component.css'],
  providers: [DialogService],
})
export class FormlySelectPositionComponent extends FieldType implements OnInit {
  readonly mainTextLabel = 'เลือกตำแหน่งงาน';
  label = this.mainTextLabel;
  items: MenuItem[];
  ref: DynamicDialogRef;
  companyId: number;
  unitId: number;

  constructor(private dialogService: DialogService, private positionService: PositionService) {
    super();
  }

  ngOnInit(): void {
    this.items = [
      {
        label: 'ล้าง',
        icon: 'pi pi-refresh',
        command: () => {
          this.formControl.reset();
        },
      },
    ];
    this.formControl.valueChanges.subscribe(positionId => {
      this.setPosition(positionId);
    });

    if (this.to.companyIdChange instanceof EventEmitter || this.to?.companyIdChange instanceof Subject) {
      this.to.companyIdChange.subscribe(companyId => {
        this.companyId = companyId
        console.log('companyId', companyId);
      });
    }

    if (this.to.unitIdChange instanceof Subject || this.to?.unitIdChange instanceof EventEmitter) {
      this.to.unitIdChange.subscribe(unitId => (this.unitId = unitId));
    }
  }

  setPosition(positionId: number) {
    if (positionId) {
      this.positionService
        .GetPosition(positionId)
        .subscribe(position => {
          this.label = this.toTextLabel(position);
        });
    } else {
      this.label = this.mainTextLabel;
    }
  }

  showPositionSearchModal() {
    this.ref = this.dialogService.open(ModalPositionSearchComponent, {
      header: 'ค้นหาตำแหน่งงาน',
      width: '70%',
      dismissableMask: false,
      contentStyle: { overflow: 'auto' },
      data: {
        isEmptyEmployee: this.to.isEmptyEmployee ?? false,
        disabledSelectCompany: this.to.disabledSelectCompany ?? false,
        companyId: this.companyId,
        unitId: this.unitId,
      },
    });

    this.ref.onClose.subscribe((position: PositionModel) => {
      if (position) {
        this.formControl.setValue(position.positionId);
        if (typeof this.to?.change === 'function') {
          this.to.change(this, { event, value: position.positionId });
        }
      }
    });
  }

  toTextLabel(model: PositionModel) {
    return `${model.name}: ${model.currentEmployeeInPosition?.employeeName ?? 'N/A'}`;
  }
}
