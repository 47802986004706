import { EventEmitter, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../api/services';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  auth2: gapi.auth2.GoogleAuth;
  googleAuth: gapi.auth2.GoogleAuth;
  initGapiEvent = new ReplaySubject(1);

  constructor(private userService: UserService) {}

  loadAuth2(clientId: string) {
    setTimeout(() => {
      gapi.load('auth2', () => {
        /* Ready. Make a call to gapi.auth2.init or some other API */
        this.googleAuth = gapi.auth2.init({
          client_id: clientId,
        });

        // this.googleAuth.isSignedIn.listen(signedIn => {
        //   console.log('signedIn', signedIn);
        // });

        // this.googleAuth.currentUser.listen(user => {
        //   console.log('user', user);
        // });
        this.initGapiEvent.next('');
      });
    }, 5000);
  }

  logMe() {
    const user = this.googleAuth.currentUser.get();
    const isSignedIn = this.googleAuth.isSignedIn.get();

    console.log('user', user);
    console.log('isSignedIn', isSignedIn);
  }

  async linkGoogleAccount() {
    const user = await this.signIn();

    return this.userService
      .LinkGoogle(user.getId())
      .pipe(map(() => user))
      .toPromise();
  }

  signIn() {
    return this.googleAuth.signIn({
      prompt: 'select_account',
    });
  }

  // signOut() {
  //   this.googleAuth.signOut();
  // }
}
