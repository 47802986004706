import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';

import { calcAge } from 'app/utils/calc-age';
import { HttpErrorResponse } from '@angular/common/http';
import { EmployeePersonalInfoModel, EmployeeNameModel, EmployeeContactModel, EmployeeCardModel, EmployeeWorkPermitModel } from '../../../api/models';
import { EmployeeService, EmployeeNameService, EmployeeCardService, EmployeeWorkPermitService } from '../../../api/services';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { ModalEmployeeCardComponent } from '../../../modals/core/modal-employee-card/modal-employee-card.component';
import { ModalEmployeeNameComponent } from '../../../modals/core/modal-employee-name/modal-employee-name.component';
import { ModalEmployeeWorkPermitComponent } from '../../../modals/core/modal-employee-work-permit/modal-employee-work-permit.component';
import { AuthenticationService } from '../../../services/authentication.service';


interface EmployeePersonalInfoDisplayModel extends EmployeePersonalInfoModel {
  age: string;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeePersonalInfoDisplayModel | keyof EmployeeNameModel | keyof EmployeeContactModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.css'],
  providers: [DialogService],
})
export class GeneralInfoComponent implements OnInit {

  isEditable = false;
  employeeUpdatedEvent = new EventEmitter();
  personnalCardList: EmployeeCardModel[];
  nameHistoryList: EmployeeNameModel[];
  workPermitList: EmployeeWorkPermitModel[];
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  personalInfoModel: EmployeePersonalInfoDisplayModel;
  personalFields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'employeeCode',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'employee_id',
            placeholder: '@blank',
            translate: true,
            required: true,
          },
        },
        {
          key: 'genderId',
          type: 'filter-dropdown',
          className: 'col-2',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'sex',
            placeholder: '@blank',
            translate: true,
            required: true,
            options: this.masterDataRepo.getGenderOptionList(),
          },
        },
        {
          key: 'birthday',
          type: 'calendar',
          className: 'col-2',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            label: 'birthday',
            placeholder: '@blank',
            translate: true,
            required: true,
            change: field => {
              if (field.formControl.valid) {
                var ageControl =  field.parent.fieldGroup.find(a => a.key == "age");
                ageControl.formControl.setValue(this.toAgeString(field.formControl.value));
              }
            },
          },
        },
        {
          key: 'age',
          type: 'primeng-input',
          className: 'col-2',
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'age',
            placeholder: '@blank',
            required: false,
            readonly: true,
            translate: true,
          },
        },
        {
          key: 'religionId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'religion',
            placeholder: '@blank',
            options: this.masterDataRepo.getReligionOptionList(),
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'employeeName',
          className: 'col-10',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'titleTh',
              type: 'filter-dropdown',
              className: 'col-2',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'prefix_n',
                placeholder: '@blank',
                translate: true,
                required: true,
                options: this.masterDataRepo.getTitleThOptionList(),
              },
            },
            {
              key: 'firstnameTh',
              type: 'primeng-input',
              className: 'col-3',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'name_th',
                placeholder: '@blank',
                translate: true,
                required: true,
              },
            },
            {
              key: 'middlenameTh',
              type: 'primeng-input',
              className: 'col-3',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'middle_name_th',
                placeholder: '@blank',
                translate: true,
              },
            },
            {
              key: 'lastnameTh',
              type: 'primeng-input',
              className: 'col-4',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'surname_th',
                placeholder: '@blank',
                translate: true,
                required: true,
              },
            },
            {
              key: 'titleEn',
              type: 'filter-dropdown',
              className: 'col-2',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'prefix_n',
                placeholder: '@blank',
                translate: true,
                required: true,
                options: this.masterDataRepo.getTitleEnOptionList(),
              },
            },
            {
              key: 'firstnameEn',
              type: 'primeng-input',
              className: 'col-3',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'name_en',
                placeholder: '@blank',
                translate: true,
                required: true,
              },
            },
            {
              key: 'middlenameEn',
              type: 'primeng-input',
              className: 'col-3',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'middle_name_en',
                placeholder: '@blank',
                translate: true,
              },
            },
            {
              key: 'lastnameEn',
              type: 'primeng-input',
              className: 'col-4',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'surname_en',
                placeholder: '@blank',
                translate: true,
                required: true,
              },
            },
          ],
        },
        {
          className: 'col-2',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'nickNameTh',
              type: 'primeng-input',
              className: 'col-12',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'nickname_th',
                placeholder: '@blank',
                translate: true,
                required: true
              },
            },
            {
              key: 'nickNameEn',
              type: 'primeng-input',
              className: 'col-12',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'nickname_en',
                placeholder: '@blank',
                translate: true,
                required: false
              },
            },
          ],
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'nationalityId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'nationality',
            placeholder: '@blank',
            translate: true,
            options: this.masterDataRepo.getNationalityOptionList(),
          },
        },
        {
          key: 'raceId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'race',
            placeholder: '@blank',
            translate: true,
            options: this.masterDataRepo.getRaceOptionList(),
          },
        },
        {
          key: 'maritalStatusId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'marital_status',
            placeholder: '@blank',
            translate: true,
            options: this.masterDataRepo.getMaritalStatusOptionList(),
          },
        },
        {
          key: 'bloodGroupId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'blood_type',
            placeholder: '@blank',
            translate: true,
            options: this.masterDataRepo.getBloodGroupOptionList(),
          },
        },
        {
          key: 'internalPhone',
          className: 'col-3',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'value',
              type: 'primeng-input',
              className: 'col-12',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'internal_phone_number',
                placeholder: '@blank',
                translate: true,
              },
            },
          ],
        },
        {
          key: 'personalPhone',
          className: 'col-3',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'value',
              type: 'primeng-input',
              className: 'col-12',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                label: 'personal_phone_number',
                placeholder: '@blank',
                translate: true,
              },
            },
          ],
        },
        {
          key: 'taxId',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            label: 'tax_id',
            placeholder: '@blank',
            translate: true,
            required: true,
            maxLength: 13
          },
        },
        {
          key: 'militaryStatusId',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            // 'templateOptions.required': `model.genderId == 'Male'`,
          },
          templateOptions: {
            translate: true,
            label: 'military_status',
            placeholder: '@blank',
            options: this.masterDataRepo.getMilitaryStatusOptionList(),
          },
        },
      ],
    },
  ];
  ref: DynamicDialogRef;

  constructor(
    private layout: LayoutDefaultComponent,
    private authenticationService: AuthenticationService,
    private employeeService: EmployeeService,
    private employeeNameService: EmployeeNameService,
    private employeeCardService: EmployeeCardService,
    private router: Router,
    private masterDataRepo: MasterDataRepo,
    private notification: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeWorkPermitService: EmployeeWorkPermitService,
  ) { }

  ngOnInit(): void {
    this.setEditablePermission();
    this.setPersonalInfo();
    this.setPersonalCardList();
    this.setEmployeeNameList();
    this.setWorkPermitList();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setPersonalInfo() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetPersonalInfo(employeeId).subscribe(personalInfo => {
      this.form.reset();
      this.personalInfoModel = {
        ...personalInfo,
        internalPhone: personalInfo.internalPhone
          ? personalInfo.internalPhone
          : {
            contactTypeId: 'WT',
          },
        personalPhone: personalInfo.personalPhone
          ? personalInfo.personalPhone
          : {
            contactTypeId: 'M',
          },
        birthday: (personalInfo.birthday),
        age: this.toAgeString(personalInfo.birthday),
      };
    });
  }

  setPersonalCardList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetCards(employeeId).subscribe(cardList => {
      this.personnalCardList = cardList;
    });
  }

  setEmployeeNameList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetNames(employeeId).subscribe(employeeNameList => {
      this.nameHistoryList = employeeNameList;
    });
  }

  setWorkPermitList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetWorkPermits(employeeId).subscribe(workPermitList => {
      this.workPermitList = workPermitList;
    });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  showCreateEmployeeCardDialog() {
    this.showDialog(
      { employeeId: this.personalInfoModel.employeeId },
      '+ เพิ่มบัตรประจำตัว',
      ModalEmployeeCardComponent,
    );

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setPersonalCardList();
      }
    });
  }

  showUpdateEmployeeCardDialog(employeeCardId: number) {
    this.showDialog({ id: employeeCardId }, 'แก้ไขบัตรประจำตัว', ModalEmployeeCardComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setPersonalCardList();
      }
    });
  }

  showDeleteEmployeeCardDialog(employeeCardId: number) {
    this.notification.confirm('ยืนยันการลบข้อมูล', '').then((result: any) => {
      if (result.value) {
        this.employeeCardService.DeleteEmployeeCard(employeeCardId).subscribe(
          () => {
            this.notification.deleteCompleted();
            this.setPersonalCardList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notification.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  showCreateEmployeeNameDialog() {
    this.showDialog(
      { employeeId: this.personalInfoModel.employeeId },
      '+ เพิ่มการเปลี่ยนชื่อ',
      ModalEmployeeNameComponent,
    );

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setPersonalInfo();
        this.setEmployeeNameList();
        this.employeeUpdatedEvent.next(null);
      }
    });
  }

  showUpdateEmployeeNameDialog(rowData: EmployeeNameModel) {
    this.showDialog({ id: rowData.employeeNameId }, 'แก้ไขการเปลี่ยนชื่อ', ModalEmployeeNameComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setPersonalInfo();
        this.setEmployeeNameList();
        this.employeeUpdatedEvent.next(null);
      }
    });
  }

  showDeleteEmployeeNameDialog(rowData: EmployeeNameModel) {
    this.notification.confirm('ยืนยันการลบข้อมูล', '').then((result: any) => {
      if (result.value) {
        this.employeeNameService.DeleteEmployeeName(rowData.employeeNameId).subscribe(
          () => {
            this.notification.deleteCompleted();
            this.setPersonalInfo();
            this.setEmployeeNameList();
            this.employeeUpdatedEvent.next(null);
          },
          (err: HttpErrorResponse) => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notification.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  onUpdatePersonalInfoBtnClick() {
    console.log(this.personalInfoModel);

    this.notification.confirm('บันทึก', 'ยืนยันการบันทึกข้อมูล').then(v => {
      if (v.isConfirmed) {
        this.updatePersonalInfo();
      }
    });
  }

  updatePersonalInfo() {
    this.employeeService
      .UpdatePersonalInfo({
        employeeId: this.personalInfoModel.employeeId,
        body: this.personalInfoModel,
      })
      .subscribe(
        () => {
          this.notification.saveCompleted();
          this.setPersonalInfo();
          this.employeeUpdatedEvent.next(null);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notification.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  showCreateEmployeeWorkPermitDialog() {
    this.showDialog(
      { employeeId: this.personalInfoModel.employeeId },
      '+ เพิ่มใบอนุญาตทำงาน',
      ModalEmployeeWorkPermitComponent,
    );

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setWorkPermitList();
      }
    });
  }

  showUpdateEmployeeWorkPermitDialog(employeeWorkPermitId: number) {
    this.showDialog({ id: employeeWorkPermitId }, 'แก้ไขใบอนุญาตทำงาน', ModalEmployeeWorkPermitComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setWorkPermitList();
      }
    });
  }

  showDeleteEmployeeWorkPermitDialog(employeeWorkPermitId: number) {
    this.notification.confirm('ยืนยันการลบข้อมูล', '').then((result: any) => {
      if (result.value) {
        this.employeeWorkPermitService.DeleteEmployeeWorkPermit(employeeWorkPermitId).subscribe(
          () => {
            this.notification.deleteCompleted();
            this.setWorkPermitList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notification.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }

  private toAgeString(birthday: string): string {
    const { year, month, day } = calcAge(birthday);

    return `${year} ปี ${month} เดือน ${day} วัน`;
  }
}
