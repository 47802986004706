import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeModel, EmployeePunishmentModel } from 'app/api/models';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeePunishmentService, EmployeeService } from 'app/api/services';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';

interface Performance {
  year: number;
  point: number;
  grade: string;
  remark: string;
}

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.css'],
  providers: [DialogService],
})
export class PerformanceComponent implements OnInit {
  isEditable = false;
  performanceList: Performance[];
  employee: EmployeeModel;
  ref: DynamicDialogRef;

  constructor(
    private layout: LayoutDefaultComponent,
    private router: Router,
    private notificationService: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeService: EmployeeService,
    private employeePunishmentService: EmployeePunishmentService,
  ) {}

  ngOnInit(): void {
    this.setEditablePermission();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
    });
  }
}
