/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PaySlipModelPaginationResult } from '../models/pay-slip-model-pagination-result';
import { PaySlipModel } from '../models/pay-slip-model';
@Injectable({
  providedIn: 'root',
})
class PaySlipService extends __BaseService {
  static readonly GetPaySlipListPath = '/api/PaySlip';
  static readonly CreatePaySlipPath = '/api/PaySlip';
  static readonly UpdatePaySlipPath = '/api/PaySlip';
  static readonly GetPaySlipByIdPath = '/api/PaySlip/{paySlipId}';
  static readonly DeletePaySlipPath = '/api/PaySlip/{paySlipId}';
  static readonly GetEmployeePaySlipByMonthPath = '/api/employee/{employeeId}/payslip/{month}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PaySlipService.GetPaySlipListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `PayDate`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetPaySlipListResponse(params: PaySlipService.GetPaySlipListParams): __Observable<__StrictHttpResponse<PaySlipModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.PayDate != null) __params = __params.set('PayDate', params.PayDate.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PaySlip`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaySlipModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `PaySlipService.GetPaySlipListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `PayDate`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetPaySlipList(params: PaySlipService.GetPaySlipListParams): __Observable<PaySlipModelPaginationResult> {
    return this.GetPaySlipListResponse(params).pipe(
      __map(_r => _r.body as PaySlipModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreatePaySlipResponse(body?: PaySlipModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PaySlip`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreatePaySlip(body?: PaySlipModel): __Observable<string> {
    return this.CreatePaySlipResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdatePaySlipResponse(body?: PaySlipModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/PaySlip`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdatePaySlip(body?: PaySlipModel): __Observable<string> {
    return this.UpdatePaySlipResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param paySlipId undefined
   * @return Success
   */
  GetPaySlipByIdResponse(paySlipId: number): __Observable<__StrictHttpResponse<PaySlipModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PaySlip/${encodeURIComponent(String(paySlipId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaySlipModel>;
      })
    );
  }
  /**
   * @param paySlipId undefined
   * @return Success
   */
  GetPaySlipById(paySlipId: number): __Observable<PaySlipModel> {
    return this.GetPaySlipByIdResponse(paySlipId).pipe(
      __map(_r => _r.body as PaySlipModel)
    );
  }

  /**
   * @param paySlipId undefined
   * @return Success
   */
  DeletePaySlipResponse(paySlipId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PaySlip/${encodeURIComponent(String(paySlipId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param paySlipId undefined
   * @return Success
   */
  DeletePaySlip(paySlipId: number): __Observable<string> {
    return this.DeletePaySlipResponse(paySlipId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `PaySlipService.GetEmployeePaySlipByMonthParams` containing the following parameters:
   *
   * - `month`:
   *
   * - `employeeId`:
   *
   * @return Success
   */
  GetEmployeePaySlipByMonthResponse(params: PaySlipService.GetEmployeePaySlipByMonthParams): __Observable<__StrictHttpResponse<PaySlipModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/employee/${encodeURIComponent(String(params.employeeId))}/payslip/${encodeURIComponent(String(params.month))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaySlipModel>;
      })
    );
  }
  /**
   * @param params The `PaySlipService.GetEmployeePaySlipByMonthParams` containing the following parameters:
   *
   * - `month`:
   *
   * - `employeeId`:
   *
   * @return Success
   */
  GetEmployeePaySlipByMonth(params: PaySlipService.GetEmployeePaySlipByMonthParams): __Observable<PaySlipModel> {
    return this.GetEmployeePaySlipByMonthResponse(params).pipe(
      __map(_r => _r.body as PaySlipModel)
    );
  }
}

module PaySlipService {

  /**
   * Parameters for GetPaySlipList
   */
  export interface GetPaySlipListParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    PayDate?: string;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsAllEmployee?: boolean;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    CompanyId?: number;
    BranchId?: number;
  }

  /**
   * Parameters for GetEmployeePaySlipByMonth
   */
  export interface GetEmployeePaySlipByMonthParams {
    month: string;
    employeeId: number;
  }
}

export { PaySlipService }
