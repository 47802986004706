<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>{{'contact_information' | translate}}</h1>
        <hr />
        <div class="card">
          <div class="grid card-section">
            <div class="col-12">
              <h1>{{'address_details_house' | translate}}</h1>
            </div>
            <div class="col-12">
              <form [formGroup]="form">
                <formly-form [form]="form" [fields]="registeredAddressFields" [model]="model"> </formly-form>
              </form>
            </div>
          </div>
          <div class="grid card-section">
            <div class="col-fixed">
              <h1>{{'current_address_details' | translate}}</h1>
            </div>
            <div class="col-fixed">
              <p-checkbox
                [disabled]="!isEditable"
                [(ngModel)]="isSameAddress"
                [binary]="true"
                label="{{'address_house_registration' | translate}}"
                (onChange)="onIsSameAddressChange()"
              ></p-checkbox>
            </div>
            <div class="col-12">
              <form [formGroup]="form">
                <formly-form [form]="form" [fields]="currentAddressFields" [model]="model"></formly-form>
              </form>
            </div>
          </div>
          <div class="grid card-section">
            <h1>{{'contact_details' | translate}}</h1>
            <div class="col-12">
              <form [formGroup]="form">
                <formly-form [form]="form" [fields]="contactInfoFields" [model]="model"></formly-form>
              </form>
            </div>
          </div>
          <div class="grid justify-content-end">
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                label="{{'record' | translate}}"
                [disabled]="form.invalid || !form.dirty"
                (click)="onSaveBtnClick()"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
