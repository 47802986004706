/* tslint:disable */
type EShiftsInAllowanceType =
  'Include' |
  'Exclude';
module EShiftsInAllowanceType {
  export const INCLUDE: EShiftsInAllowanceType = 'Include';
  export const EXCLUDE: EShiftsInAllowanceType = 'Exclude';
  export function values(): EShiftsInAllowanceType[] {
    return [
      INCLUDE,
      EXCLUDE
    ];
  }
}

export { EShiftsInAllowanceType }