/* tslint:disable */
type EPayrollPeriodStatus =
  'Open' |
  'Validated' |
  'Processed' |
  'Closed' |
  'Cancel';
module EPayrollPeriodStatus {
  export const OPEN: EPayrollPeriodStatus = 'Open';
  export const VALIDATED: EPayrollPeriodStatus = 'Validated';
  export const PROCESSED: EPayrollPeriodStatus = 'Processed';
  export const CLOSED: EPayrollPeriodStatus = 'Closed';
  export const CANCEL: EPayrollPeriodStatus = 'Cancel';
  export function values(): EPayrollPeriodStatus[] {
    return [
      OPEN,
      VALIDATED,
      PROCESSED,
      CLOSED,
      CANCEL
    ];
  }
}

export { EPayrollPeriodStatus }