<div class="login-body" [ngClass]="{'layout-dark': dark, 'layout-light': !dark}">

  <div class="login-content" [hidden]="!appSettings">
      <div class="night-switch">
          <p-button styleClass="dark-switch"  icon="pi pi-circle-on" (click)="dark = true"></p-button>
          <p-button styleClass="light-switch"  icon="pi pi-circle-off" (click)="dark = false"></p-button>
      </div>
      <div class="login-panel" style="width: 40%; height: auto; margin-top: 7.5%;">
        <div class="login-panel-content" style="height: 70vh; width: 100%; background-color:white; padding: 5%;" >
              <h2><b>ลืมรหัสผ่าน</b></h2>
              <div class="logo" style="height: 20vh;">
                <div class="menu-logo" [ngStyle]="{'background-image': 'url(/api/document/' + appSettings.webLogoLargeImageId + '/download)'}"  alt="mirage-layout"></div>
              </div>
              <div class="col">
                <div class="p-inputgroup" style="margin-left: 20%;">
                  <span class="p-inputgroup-addon"><i class="fas fa-user" style="line-height: 1.25;"></i></span>
                  <input type="text" style="width: 70%;" pInputText placeholder="Email">
                </div>
              </div>
              <div class="col" style="margin-top: 7.5%;">
                <div class="grid justify-content-even">
                  <button type="button" style="width: 40%;" pButton class="p-button-secondary"label="Reset Password" [disabled]="" (click)="submit()"></button>
                </div>
              </div>
      </div>

      <div class="desert"></div>
  </div>
</div>
