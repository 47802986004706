import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PipeTransform, Pipe, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'mapValueByKey' })
export class MapValueByKeyPipe implements PipeTransform {
  constructor() { }
  transform(value: any, items: any): string {
    let filteredItems =  items.filter(a => a.key == value);
    return filteredItems.length > 0 ? filteredItems[0].name : 'N/A';
  }
}
