import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  CompanyService,
  JobFamilyService,
  JobGradeOfSystemService,
  JobGradeService,
  LocationService,
  PayElementService,
  PersonnelAreaService,
  PositionService,
  RoleService,
  ShiftService,
  UnitService,
} from '../api/services';
import { toOptionList } from '../utils/formly-utils';

enum UnitLevel {
  Plant = 1,
  Division = 2,
  Department = 3,
  Section = 4,
}

@Injectable()
export class OptionListRepo {
  private cachedPositionOptionList$: Observable<any>;
  private cachedJobGradeOfSystems$: Observable<any>;
  private cachedPayElementExportField$: Observable<any>;
  private cachedRoles$: Observable<any>;
  private cachedCompanies$: Observable<any>;
  private cachedActiveShifts$: Observable<any>;
  private cachedLocations$: Observable<any>;
  private cachedUnits$: Observable<any>;

  constructor(
    private unitService: UnitService,
    private positionService: PositionService,
    private personnelAreaService: PersonnelAreaService,
    private companyService: CompanyService,
    private locationService: LocationService,
    private jobGradeService: JobGradeService,
    private jobFamilyService: JobFamilyService,
    private shiftService: ShiftService,
    private roleService: RoleService,
    private jobGradeOfSystemService: JobGradeOfSystemService,
    private payElementService: PayElementService,
  ) {
    this.cachedPositionOptionList$ = this.positionService.GetPositions({}).pipe(
      map(pageResult => {
        return pageResult.results.map(position => {
          return {
            value: position.positionId.toString(),
            label: `${position.name}: ${position.currentEmployeeInPosition?.employeeName ?? 'N/A'}`,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedJobGradeOfSystems$ = this.jobGradeOfSystemService.GetJobGradeOfSystemsList({}).pipe(
      map(pageResult => {
        return pageResult.results.map(job => {
          return {
            label: job.order + ' : ' + job.description,
            value: job.jobGradeOfSystemId,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedPayElementExportField$ = this.payElementService.GetPayElementExportFieldList().pipe(
      map(exportFieldList => {
        return exportFieldList.map(a => {
          return {
            value: a.payElementId,
            label: a.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedRoles$ = this.roleService.GetRoles({}).pipe(
      map(pageResult => pageResult.results.map(u => toOptionList(u, 'roleId', 'name'))),
      shareReplay(1),
    );

    this.cachedCompanies$ = this.companyService.GetCompaniesList({}).pipe(
      map(pageResult => pageResult.results.map(u => toOptionList(u, 'companyId', 'name'))),
      shareReplay(1),
    );

    this.cachedActiveShifts$ = this.shiftService
      .GetActiveShiftsList({ ItemPerPage: 999 })
      .pipe(
        map(pageResult => pageResult.results.map(u => toOptionList(u, 'shiftId', 'name'))),
        shareReplay(1));

    this.cachedLocations$ = this.locationService.GetLocationsList({}).pipe(
      map(pageResult => pageResult.results.map(u => toOptionList(u, 'locationId', 'name'))),
      shareReplay(1),
    );

    this.cachedUnits$ = this.unitService.GetUnits({}).pipe(
      map(pageResult => pageResult.results.map(u => toOptionList(u, 'unitId', 'name'))),
      shareReplay(1),
    );
  }

  getUnitOptionList(params: UnitService.GetUnitsParams) {
    return this.unitService
      .GetUnits(params)
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'unitId', 'name'))));
  }

  getPositionItemListOptionList(params: PositionService.GetPositionItemListParams) {
    return this.positionService
      .GetPositionItemList(params)
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'positionId', 'name'))));
  }

  getCachedPositionOptionList() {
    return this.cachedPositionOptionList$;
  }

  getPersonnelAreaOptionList(params: PersonnelAreaService.GetPersonnelAreasListParams) {
    return this.personnelAreaService
      .GetPersonnelAreasList(params)
      .pipe(map(personnelList => personnelList.map(u => toOptionList(u, 'personnelAreaId', 'name'))));
  }

  getCompanyOptionList(params: CompanyService.GetCompaniesListParams) {
    return this.companyService
      .GetCompaniesList(params)
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'companyId', 'name'))));
  }

  getLocationOptionList(params: LocationService.GetLocationsListParams) {
    return this.locationService
      .GetLocationsList(params)
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'locationId', 'name'))));
  }

  getJobGradeOptionList(params: JobGradeService.GetJobGradeParams) {
    return this.jobGradeService
      .GetJobGrade(params)
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'jobGradeId', 'name'))));
  }

  getJobGradeOfSystemOptionList() {
    return this.cachedJobGradeOfSystems$;
  }

  getJobFamilyOptionList(params: JobFamilyService.GetJobFamilyParams) {
    return this.jobFamilyService
      .GetJobFamily(params)
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'jobFamilyId', 'name'))));
  }

  getActiveShiftOptionList(params: ShiftService.GetActiveShiftsListParams) {
    return this.shiftService
      .GetActiveShiftsList(params)
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'shiftId', 'name'))));
  }

  getPlantOptionList(params: UnitService.GetUnitsParams) {
    return this.unitService
      .GetUnits({ ...params, UnitLevel: UnitLevel.Plant })
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'unitId', 'name'))));
  }

  getDivisionOptionList(params: UnitService.GetUnitsParams) {
    return this.unitService
      .GetUnits({ ...params, UnitLevel: UnitLevel.Division })
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'unitId', 'name'))));
  }

  getDepartmentOptionList(params: UnitService.GetUnitsParams) {
    return this.unitService
      .GetUnits({ ...params, UnitLevel: UnitLevel.Department })
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'unitId', 'name'))));
  }

  getSectionOptionList(params: UnitService.GetUnitsParams) {
    return this.unitService
      .GetUnits({ ...params, UnitLevel: UnitLevel.Section })
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'unitId', 'name'))));
  }

  getRoleOptionList(params: RoleService.GetRolesParams = {}) {
    return this.roleService
      .GetRoles(params)
      .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'roleId', 'name'))));
  }

  getPayElementExportFieldList() {
    return this.cachedPayElementExportField$;
  }

  getCachedRoleOptionList() {
    return this.cachedRoles$;
  }

  getCachedCompanyOptionList() {
    return this.cachedCompanies$;
  }

  getCachedActiveShiftOptionList() {
    return this.cachedActiveShifts$;
  }

  getCachedLocations() {
    return this.cachedLocations$;
  }

  getCachedUnits() {
    return this.cachedUnits$;
  }
}
