import { Injectable, HostListener } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppPlatform } from 'shared/globalUtils';
import { ApplicationSettingsExtended } from '../api/models';
import { RouteHelpers } from '../helpers/RouteHelpers';
import { ViewUtilsService } from './view-utils.service';

export interface AppMenuItem {
  label: string;
  icon?: string;
  routerLink?: string[];
  items?: AppMenuItem[];
  clientCode?: string;
  stage?: Stage;
  disabled?: boolean;
  showOnNativeApp?: boolean;
  command?: any;
  url?: string;
  target?: string;
  permissions?: Permission[];
  permissions2?: Permission[];
  permissionSubrogate?: Permission[];
}

export type Permission = 'role.admin' | 'role.employee' | 'isManager' | string;

type Stage = 'publish' | 'dev';

@Injectable({
  providedIn: 'root',
})
export class AppMenuService {
  adminPages: AppMenuItem[] = [];
  employeePages: AppMenuItem[] = [];
  home = '{{ "HELLO"| translate}}';

  screenWidth = window.innerWidth;

  constructor(
    private authenticationService: AuthenticationService,
    private ngxPermissionsService: NgxPermissionsService,
    private viewUtils: ViewUtilsService,
    private routeHelpers: RouteHelpers
  ) {
    const adminPages: AppMenuItem[] = [
      {
        label: 'หน้าแรก (Home)',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/admin/home'],
        stage: 'publish',
        permissions: ['Admin'],
        showOnNativeApp: true,
      },
      {
        label: 'Dashboard',
        icon: 'far fa-chart-bar',
        routerLink: ['/admin/dashboard'],
        stage: 'publish',
        permissions: ['role.admin'],
        showOnNativeApp: false,
        items: [
          {
            label: 'TA',
            icon: 'far fa-chart-bar',
            routerLink: ['/admin/dashboard/dashboard-ta'],
            stage: 'publish',
            permissions: ['menu.admin.dashboard.ta'],
            showOnNativeApp: false,
          },
          {
            label: 'OT',
            icon: 'far fa-chart-bar',
            routerLink: ['/admin/dashboard/dashboard-ot'],
            stage: 'publish',
            permissions: ['menu.admin.dashboard.ot'],
            showOnNativeApp: false,
          },
          {
            label: 'TOR',
            icon: 'far fa-chart-bar',
            routerLink: ['/admin/dashboard/dashboard-tor'],
            stage: 'publish',
            permissions: ['menu.admin.dashboard.tor'],
            showOnNativeApp: false,
          },
        ],
      },
      {
        label: 'การแจ้งเตือน (Notification)',
        icon: 'far fa-bell',
        routerLink: ['/admin/notification/list'],
        stage: 'publish',
        permissions: ['menu.admin.notification'],
      },
      {
        label: 'ศูนย์การอนุมัติ (Approval Center)',
        icon: 'fas fa-user-check',
        routerLink: ['/admin/approval-center'],
        stage: 'publish',
        permissions: ['menu.admin.approval-center'],
      },
      {
        label: 'ส่วนหลัก (Core)',
        icon: 'fas fa-border-all',
        routerLink: ['/admin/core'],
        stage: 'publish',
        permissions: ['role.admin'],
        items: [
          {
            label: 'ข้อมูลพื้นฐาน (Foundation)',
            icon: 'far fa-object-group',
            routerLink: ['/admin/core/foundation'],
            stage: 'publish',
            permissions: ['role.admin'],
            items: [
              {
                label: 'จัดการประเทศ (Country)',
                icon: 'far fa-flag',
                routerLink: ['/admin/core/foundation/countries'],
                permissions: ['menu.admin.core.foundation.countries'],
                stage: 'publish',
              },
              {
                label: 'จัดการบริษัท (Company)',
                icon: 'far fa-building',
                routerLink: ['/admin/core/manage-company'],
                permissions: ['menu.admin.manage-company'],
                stage: 'publish',
              },
              {
                label: 'จัดการพื้นที่ปฏิบัติงาน (Working Area)',
                icon: 'fas fa-map-marker-alt',
                routerLink: ['/admin/core/foundation/locations'],
                permissions: ['menu.admin.core.foundation.locations'],
                stage: 'publish',
              },
              {
                label: 'จัดการระดับหน่วยงาน (Unit Level)',
                icon: 'fas fa-sliders-h',
                routerLink: ['/admin/core/foundation/unit-level-of-system'],
                permissions: ['menu.admin.core.foundation.unit-level-of-system'],
                stage: 'publish',
              },
              {
                label: 'จัดการชั้นงาน (Job Grade)',
                icon: 'fas fa-server',
                routerLink: ['/admin/core/foundation/job-grade-of-system'],
                permissions: ['menu.admin.core.foundation.job-grade-of-system'],
                stage: 'publish',
              },
              {
                label: 'จัดการกลุ่มงาน (Job Family)',
                icon: 'fas fa-list-ul',
                routerLink: ['/admin/core/foundation/job-families'],
                permissions: ['menu.admin.core.foundation.job-families'],
                stage: 'publish',
              },
              {
                label: 'จัดการศูนย์ต้นทุน (Cost Center)',
                icon: 'fas fa-sliders-h',
                routerLink: ['/admin/core/foundation/cost-centers'],
                permissions: ['menu.admin.core.foundation.cost-centers'],
                stage: 'publish',
              },
              {
                label: 'จัดการรายการบัญชี (GL Code)',
                icon: 'fas fa-sliders-h',
                routerLink: ['/admin/core/foundation/gl-codes'],
                permissions: ['menu.admin.core.foundation.gl-codes'],
                stage: 'publish',
              },
            ],
          },
          {
            label: 'พนักงาน (Employee)',
            icon: 'fas fa-users',
            routerLink: ['/admin/core/employee'],
            stage: 'publish',
            permissions: ['role.admin'],
            items: [
              {
                label: 'พนักงานทั้งหมด (Employee List)',
                icon: 'fas fa-users',
                routerLink: ['/admin/core/employee/list'],
                permissions: ['menu.admin.employee'],
                stage: 'publish',
              },
              {
                label: 'จัดการจดหมายเตือน (Warning Letter)',
                icon: 'far fa-file-excel',
                routerLink: ['/admin/core/warning-letter/list'],
                permissions: ['menu.admin.core.warning-letter'],
                stage: 'publish',
              },
              {
                label: 'จัดการคำขอเปลี่ยนแปลงสถานภาพ (Promote Request)',
                icon: 'fas fa-file-upload',
                routerLink: ['/admin/core/promote-request/list'],
                permissions: ['menu.admin.core.promote-request'],
                stage: 'publish',
              },
              {
                label: 'จัดการการประเมินผลทดลองงาน (Probation Evaluation)',
                icon: 'fas fa-users-cog',
                routerLink: ['/admin/core/probation-evaluation/list'],
                permissions: ['menu.admin.core.probation-evaluation'],
                stage: 'publish',
              },
              {
                label: 'รายการแจ้งเตือนเอกสาร Certificate (Certificate Notification)',
                icon: 'far fa-file-archive',
                routerLink: ['/admin/core/notification-document/certificate'],
                permissions: ['menu.admin.core.notification-document.certificate'],
                stage: 'publish',
              },
              {
                label: 'รายการแจ้งเตือนเอกสารใบอนุญาตทำงาน (Workpermit Notification)',
                icon: 'far fa-file-archive',
                routerLink: ['/admin/core/notification-document/workpermit'],
                permissions: ['menu.admin.core.notification-document.workpermit'],
                stage: 'publish',
              },
              {
                label: 'รายการแจ้งเตือนเอกสารใบตรวจสุขภาพ (Health Check Nofitication)',
                icon: 'far fa-file-archive',
                routerLink: ['/admin/core/notification-document/healthcheck'],
                permissions: ['menu.admin.core.notification-document.healthcheck'],
                stage: 'publish',
              },
            ],
          },
          {
            label: 'ข่าวและประกาศ (News and Announcement)',
            icon: 'fas fa-bullhorn',
            routerLink: ['/admin/core/news-and-announcement'],
            stage: 'publish',
            permissions: ['role.admin'],
            items: [
              {
                label: 'จัดการประกาศ (Announcement)',
                icon: 'far fa-newspaper',
                routerLink: ['/admin/core/announcement/list'],
                permissions: ['menu.admin.core.announcement'],
                stage: 'publish',
              },
              {
                label: 'จัดการข่าว (News)',
                icon: 'far fa-newspaper',
                routerLink: ['/admin/core/news/list'],
                permissions: ['menu.admin.core.news'],
                stage: 'publish',
              },
            ],
          },
          {
            label: 'โครงสร้างองค์กร (Organization)',
            icon: 'fas fa-sitemap',
            routerLink: ['/admin/core/organization'],
            stage: 'publish',
            permissions: ['role.admin'],
            items: [
              {
                label: 'ผังองค์กร (Org Chart)',
                icon: 'fas fa-users-cog',
                routerLink: ['/admin/core/organization/org-chart'],
                permissions: ['menu.admin.core.organization.org-chart'],
                stage: 'publish',
                showOnNativeApp: false,
              },
              {
                label: 'สายการบังคับบัญชา (Chain Of Command)',
                icon: 'fas fa-users',
                routerLink: ['/admin/core/organization/chain-of-command'],
                permissions: ['menu.admin.core.organization.chain-of-command'],
                stage: 'publish',
                showOnNativeApp: false,
              },
              {
                label: 'จัดการ องค์กรแบบเมทริกซ์ (Org. Matrix)',
                icon: 'fas fa-sitemap',
                routerLink: ['/admin/core/organization/personnel-area'],
                permissions: ['menu.admin.core.organization.personnel-area'],
                stage: 'publish',
              },
              {
                label: 'จัดการหน่วยงาน (Unit)',
                icon: 'fas fa-sliders-h',
                routerLink: ['/admin/core/organization/unit'],
                permissions: ['menu.admin.core.organization.unit'],
                stage: 'publish',
              },
              {
                label: 'จัดการตำแหน่งงาน (Position)',
                icon: 'fas fa-briefcase',
                routerLink: ['/admin/core/organization/position'],
                permissions: ['menu.admin.core.organization.position'],
                stage: 'publish',
              },
            ],
          },
          {
            label: 'การตั้งค่า (Setting)',
            icon: 'fas fa-cog',
            routerLink: ['/admin/core/core-setting'],
            stage: 'publish',
            permissions: ['role.admin'],
            items: [
            ],
          },
          {
            label: 'รายงาน (Report)',
            icon: 'far fa-file',
            routerLink: ['/admin/core/report'],
            permissions: ['menu.admin.report'],
            stage: 'publish',
          },
        ],
      },
      {
        label: 'เวลาทำงาน (TA)',
        icon: 'far fa-clock',
        routerLink: ['/admin/ta'],
        stage: 'publish',
        permissions: ['role.admin'],
        items: [
          {
            label: 'บันทึกเวลาการทำงานประจำวัน (Timesheet)',
            icon: 'far fa-clock',
            routerLink: ['/admin/ta/working-time/normal'],
            permissions: ['menu.admin.ta.working-time-normal'],
            stage: 'publish',
          },
          {
            label: 'ตารางการทำงาน (Shift)',
            icon: 'far fa-calendar-alt',
            routerLink: ['/admin/ta/shifts/list'],
            permissions: ['menu.admin.ta.shifts'],
            stage: 'publish',
          },
          {
            label: 'พนักงานและกะการทำงาน (Employee And Shift)',
            icon: 'far fa-calendar-alt',
            routerLink: ['/admin/ta/employee-in-shift'],
            permissions: ['menu.admin.ta.employee-in-shift'],
            stage: 'publish',
          },
          {
            label: 'จัดการคำขอ OT (OT Request)',
            icon: 'fas fa-business-time',
            routerLink: ['/admin/ta/ot/list'],
            permissions: ['menu.admin.ta.ot'],
            stage: 'publish',
          },
          {
            label: 'จัดการคำขอรับรองเวลา (Attendance Certify Request)',
            icon: 'fas fa-file-signature',
            routerLink: ['/admin/ta/attendance-certify/list'],
            permissions: ['menu.admin.ta.attendance-certify'],
            stage: 'publish',
          },
          {
            label: 'จัดการคำขอลา (Leave Request)',
            icon: 'far fa-calendar-times',
            routerLink: ['/admin/ta/leave'],
            permissions: ['menu.admin.ta.leave'],
            stage: 'publish',
          },
          {
            label: 'รายงาน (Report)',
            icon: 'far fa-file-alt',
            routerLink: ['/admin/ta/report'],
            permissions: ['menu.admin.ta.report'],
            stage: 'publish',
          },
          {
            label: 'จัดการสิทธิ์การลา',
            icon: 'fas fa-hand-holding-heart',
            routerLink: ['/admin/benefit'],
            stage: 'publish',
            permissions: ['role.admin'],
            items: [
              {
                label: 'จัดการประเภทการลา (Leave Type Management)',
                icon: 'far fa-list-alt',
                routerLink: ['/admin/benefit/benefit-element'],
                permissions: ['menu.admin.benefit.benefit-element'],
                stage: 'publish',
              },
              {
                label: 'จัดการนโยบายการลา (Leave Policy)',
                icon: 'fas fa-gift',
                routerLink: ['/admin/benefit/benefit-policy'],
                permissions: ['menu.admin.benefit.benefit-policy'],
                stage: 'publish',
              },
              {
                label: 'ตรวจสอบสิทธิ์การลาของพนักงาน (Employee Leave)',
                icon: 'fas fa-gift',
                routerLink: ['/admin/benefit/benefit-employee-monitoring'],
                permissions: ['menu.admin.benefit.benefit-employee-monitoring'],
                stage: 'publish',
              },
            ],
          },
          {
            label: 'การตั้งค่า (Setting)',
            icon: 'fas fa-cog',
            routerLink: ['/admin/ta/setting'],
            stage: 'publish',
            permissions: ['role.admin'],
            items: [
              {
                label: 'จัดการวันหยุด (Holiday)',
                icon: 'fas fa-cloud-sun',
                routerLink: ['/admin/ta/setting/holiday'],
                permissions: ['menu.admin.ta.setting.holiday'],
                stage: 'publish',
              },
              {
                label: 'ตารางการทำงานย้อนหลัง (Generate Retrospective Timesheet)',
                icon: 'fas fa-clock',
                routerLink: ['/admin/ta/setting/generate-retrospective-timesheet'],
                permissions: ['menu.admin.ta.setting.generate-retrospective-timesheet'],
                stage: 'publish',
              },
            ],
          },
        ],
      },
      {
        label: 'Payroll',
        icon: 'fas fa-money-bill',
        routerLink: ['/admin/payroll'],
        stage: 'publish',
        permissions: ['menu.admin.payroll.pay-element'],
        items: [
          {
            label: 'จัดการ Pay Elements (Pay Elements Management)',
            icon: 'far fa-list-alt',
            routerLink: ['/admin/payroll/pay-element'],
            permissions: ['menu.admin.payroll.pay-element'],
            stage: 'publish',
          },
          {
            label: 'คำนวนเงินเดือน (Calculation)',
            icon: 'far fa-list-alt',
            routerLink: ['/admin/payroll/calculation'],
            stage: 'publish',
          },
          {
            label: 'จัดการเบี้ยเลี้ยง (Allowance)',
            icon: 'fas fa-file-invoice-dollar',
            routerLink: ['/admin/payroll/allowance'],
            stage: 'publish',
          },
          {
            label: 'นโยบายเบี้ยเลี้ยง (Allowance Policy)',
            icon: 'fas fa-gift',
            routerLink: ['/admin/payroll/allowance-policy'],
            stage: 'publish',
          },
          {
            label: 'นำเข้าเบี้ยเลี้ยง (Import Allowance)',
            icon: 'fas fa-dollar-sign',
            routerLink: ['/admin/payroll/import-allowance'],
            stage: 'publish',
          },
          {
            label: 'จัดการ Pay Slips (Pay Slips Management)',
            icon: 'far fa-file-alt',
            routerLink: ['/admin/payroll/pay-slip'],
            permissions: ['menu.admin.payroll.pay-slip'],
            stage: 'publish',
          },
          {
            label: 'รายได้ (Revenue)',
            icon: 'fas fa-dollar-sign',
            routerLink: ['/admin/payroll/revenue'],
            permissions: ['menu.admin.payroll.revenue'],
            stage: 'publish',
          },
          {
            label: 'รายงาน (Report)',
            icon: 'fas fa-file-alt',
            routerLink: ['/admin/payroll/report'],
            permissions: ['menu.admin.payroll.report'],
            stage: 'publish',
          },
        ],
      },
      {
        label: 'E-JD',
        icon: 'far fa-address-card',
        routerLink: ['/admin/ejd'],
        stage: 'dev',
        permissions: ['role.admin'],
        items: [
          {
            label: 'จัดการ JD',
            icon: 'far fa-list-alt',
            routerLink: ['/admin/ejd/jobs'],
            permissions: ['menu.admin.ejd.jobs'],
            stage: 'dev',
          },
          {
            label: 'อนุมัติ JD',
            icon: 'fas fa-file-signature',
            routerLink: ['/admin/ejd/approval'],
            permissions: ['menu.admin.ejd.approval'],
            stage: 'dev',
          },
          {
            label: 'กำหนด JD ให้กับพนักงาน',
            icon: 'fas fa-users',
            routerLink: ['/admin/ejd/assignment'],
            permissions: ['menu.admin.ejd.assignment'],
            stage: 'dev',
          },
          {
            label: 'รายงาน',
            icon: 'fas fa-file-alt',
            routerLink: ['/admin/ejd/reports'],
            permissions: ['menu.admin.ejd.reports'],
            stage: 'dev',
          },
          {
            label: 'การตั้งค่า (Setting)',
            icon: 'fas fa-cog',
            routerLink: ['/admin/ejd/settings'],
            permissions: ['menu.admin.ejd.settings'],
            stage: 'dev',
          },
        ],
      },
      {
        label: 'การตั้งค่า (Setting)',
        icon: 'fas fa-cog',
        routerLink: ['/admin/setting'],
        stage: 'publish',
        permissions: ['role.admin'],
        items: [
          {
            label: 'จัดการ Workflow (Workflow Management)',
            icon: 'fas fa-network-wired',
            routerLink: ['/admin/core/core-setting/workflow'],
            permissions: ['menu.admin.core.setting.workflow'],
            stage: 'publish',
          },
          {
            label: 'เงื่อนไขของ Workflow (Workflow Condition)',
            icon: 'fas fa-list-ol',
            routerLink: ['/admin/core/core-setting/workflow-type'],
            permissions: ['menu.admin.core.setting.workflow-condition'],
            stage: 'publish',
          },
          {
            label: 'ผู้ใช้งาน (User)',
            icon: 'fa fa-user',
            routerLink: ['/admin/setting/user'],
            permissions: ['menu.admin.setting.user'],
            stage: 'publish',
          },
          {
            label: 'สิทธิ์การใช้งาน (Permission)',
            icon: 'fas fa-book-reader',
            routerLink: ['/admin/setting/role'],
            permissions: ['menu.admin.setting.role'],
            stage: 'publish',
          },
          {
            label: 'ข้อมูลหลัก (Master Data)',
            icon: 'fas fa-database',
            routerLink: ['/admin/setting/master-data'],
            permissions: ['menu.admin.setting.user'],
            stage: 'publish',
          }
        ],
      },
      {
        label: 'ระบบ (System)',
        icon: 'fas fa-info-circle',
        routerLink: ['/admin/system'],
        stage: 'publish',
        permissions: ['role.admin'],
        items: [
          {
            label: 'Log การเข้าระบบ (Access Log)',
            icon: 'fas fa-key',
            routerLink: ['/admin/system/access-log'],
            permissions: ['menu.admin.system.access-log'],
            stage: 'publish',
          },
        ],
      },
    ];
    const employeePages: AppMenuItem[] = [
      {
        label: 'หน้าแรก (Home)',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/employee/home'],
        stage: 'dev',
        permissions: ['role.employee'],
      },
      {
        label: 'ข้อมูลส่วนตัว (Personal Info)',
        icon: 'fas fa-user-alt',
        routerLink: ['/employee/info'],
        stage: 'publish',
        permissions: ['role.employee'],
        showOnNativeApp: true,
      },
      // {
      //   label: 'ข้อมูลภาษี (Tax Info)',
      //   icon: 'fas fa-money-check-dollar',
      //   routerLink: ['/employee/info/taxInfo-edit-employee/' + this.authenticationService.getUserInfo()?.employeeId],
      //   stage: 'publish',
      //   //permissions: ['submenu.shared.employee-info.salary'],
      //   permissions: ['role.employee'],
      //   showOnNativeApp: true,
      // },
      {
        label: 'Dashboard',
        icon: 'far fa-chart-bar',
        routerLink: ['/employee/dashboard'],
        stage: 'publish',
        permissions: ['role.employee'],
        showOnNativeApp: false,
        items: [
          {
            label: 'TA',
            icon: 'far fa-chart-bar',
            routerLink: ['/employee/dashboard/dashboard-ta'],
            stage: 'publish',
            showOnNativeApp: false,
            permissions: ['menu.employee.dashboard.ta'],
          },
          {
            label: 'OT',
            icon: 'far fa-chart-bar',
            routerLink: ['/employee/dashboard/dashboard-ot'],
            stage: 'publish',
            showOnNativeApp: false,
            permissions: ['menu.employee.dashboard.ot'],
          },
        ],
      },
      {
        label: 'ข่าว (News)',
        icon: 'far fa-newspaper',
        routerLink: ['/employee/news'],
        stage: 'publish',
        permissions: ['menu.employee.news'],
        showOnNativeApp: true,
      },
      {
        label: 'ประกาศ (Announcement)',
        icon: 'far fa-newspaper',
        routerLink: ['/employee/announcement'],
        stage: 'publish',
        permissions: ['menu.employee.announcement'],
        showOnNativeApp: true,
      },
      {
        label: 'การแจ้งเตือน (Notification)',
        icon: 'far fa-bell',
        routerLink: ['/employee/notification/list'],
        stage: 'publish',
        permissions: ['menu.employee.notification'],
        showOnNativeApp: true,
      },
      {
        label: 'คำร้องของฉัน (My Request)',
        icon: 'fas fa-mail-bulk',
        routerLink: ['/employee/request'],
        stage: 'publish',
        permissions: ['menu.employee.request'],
        showOnNativeApp: true,
      },
      {
        label: 'ศูนย์การอนุมัติ (Approval Center)',
        icon: 'fas fa-user-check',
        routerLink: ['/employee/approval-center'],
        stage: 'publish',
        permissions: ['menu.employee.approval-center'],
        permissions2: ['isManager'],
        showOnNativeApp: true,
      },
      {
        label: 'ส่วนหลัก (Core)',
        icon: 'fas fa-user-friends',
        routerLink: ['/employee/core'],
        stage: 'publish',
        permissions2: ['isManager'],
        showOnNativeApp: true,
        items: [
          {
            label: 'พนักงานใต้บังคับบัญชาทั้งหมด (Subordinate List)',
            icon: 'fas fa-user-friends',
            routerLink: ['/employee/core/team'],
            permissions: ['menu.employee.core.team'],
            permissions2: ['isManager'],
            stage: 'publish',
            showOnNativeApp: true,
          },
          {
            label: 'สายการบังคับบัญชา (Chain Of Command)',
            icon: 'fas fa-users',
            routerLink: ['/employee/core/chain-of-command'],
            permissions: ['menu.employee.core.chain-of-command'],
            stage: 'publish',
            showOnNativeApp: true,
          },
          {
            label: 'รายการคำร้อง (Request)',
            icon: 'fas fa-mail-bulk',
            routerLink: ['/employee/core/request-document'],
            stage: 'publish',
            permissions: ['role.employee'],
            items: [
              {
                label: 'ขอหนังสือรับรอง',
                icon: 'fas fa-file-alt',
                routerLink: ['/employee/core/request-document/certificate'],
                permissions: ['menu.employee.core.request-document.certificate'],
                stage: 'publish',
              },
            ],
          },
        ],
      },
      {
        label: 'เวลาทำงาน (TA)',
        icon: 'far fa-clock',
        routerLink: ['/employee/ta'],
        stage: 'publish',
        permissions: ['role.employee'],
        showOnNativeApp: true,
        items: [
          {
            label: 'Check in / Check out',
            icon: 'far fa-check-circle',
            routerLink: ['/employee/ta/check-in-out'],
            permissions: ['menu.employee.ta.check-in-out'],
            stage: 'publish',
          },
          {
            label: 'เวลาการทำงานประจำวัน (Timesheet)',
            icon: 'far fa-clock',
            routerLink: ['/employee/ta/working-time'],
            stage: 'publish',
            permissions: ['role.employee'],
            showOnNativeApp: true,
            items: [
              {
                label: 'บันทึกเวลาการทำงานประจำวัน (Timesheet)',
                icon: 'pi pi-fw pi-sign-in',
                routerLink: ['/employee/ta/working-time/list'],
                permissions: ['menu.employee.ta.working-time.timesheet'],
                stage: 'publish',
                showOnNativeApp: true,
              },
              {
                label: 'บันทึกเวลาการทำงานประจำวันของพนักงานใต้สังกัด (Subordinate Timesheet)',
                icon: 'pi pi-fw pi-sign-in',
                routerLink: ['/employee/ta/working-time/team-time-sheet'],
                permissions: ['menu.employee.ta.working-time.team-time-sheet'],
                permissions2: ['isManager'],
                stage: 'publish',
                showOnNativeApp: true,
              },
              {
                label: 'รายการคำขอรับรองเวลา (Attendance Certify Request)',
                icon: 'pi pi-fw pi-sign-in',
                routerLink: ['/employee/ta/request-time/list'],
                permissions: ['menu.employee.ta.request-time.list'],
                stage: 'publish',
                showOnNativeApp: true,
              },
            ],
          },
          {
            label: 'สิทธิ์การลา (Leave)',
            icon: 'fas fa-user-times',
            routerLink: ['/employee/ta/leave'],
            permissions: ['menu.employee.ta.leave'],
            stage: 'publish',
            showOnNativeApp: true,
          },
          {
            label: 'จัดการคำขอค่าล่วงเวลา (OT Request)',
            icon: 'fas fa-business-time',
            routerLink: ['/employee/ta/ot/list'],
            permissions: ['menu.employee.ta.ot'],
            stage: 'publish',
            showOnNativeApp: true,
          },
        ],
      },
      {
        label: 'Payroll',
        icon: 'fas fa-money-bill',
        routerLink: ['/employee/payroll'],
        permissionSubrogate: ['menu.employee.payroll.admin'],
        stage: 'publish',
        permissions: ['role.employee'],
        showOnNativeApp: true,
        items: [
          {
            label: 'ดาวน์โหลด Payslip (Payslip Download)',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/employee/payroll/payslips'],
            permissions: ['menu.employee.payroll.payslips'],
            permissionSubrogate: ['menu.employee.payroll.admin'],
            stage: 'publish',
            showOnNativeApp: true,
          },
        ],
      },
    ];

    this.adminPages = adminPages; // .map(menu => this.addPermission(menu, 'menu.admin'));
    this.employeePages = employeePages; // .map(menu => this.addPermission(menu, 'menu.employee'));
  }

  getMegaMenu(): AppMenuItem[] {
    return [
      {
        label: 'ข้อมูลส่วนตัว',
        icon: 'fas fa-male',
        routerLink: ['/'],
        stage: 'publish',
      },
      {
        label: 'ศูนย์การอนุมัติ',
        icon: 'fas fa-user-check',
        routerLink: ['/'],
        stage: 'publish',
      },
      {
        label: 'พนักงานทั้งหมด',
        icon: 'fas fa-list-ul',
        routerLink: ['/'],
        stage: 'publish',
      },
      {
        label: 'โครงสร้างองค์กร',
        icon: 'fas fa-stamp',
        routerLink: ['/'],
        stage: 'publish',
      },
    ];
  }

  getMenu(): AppMenuItem[] {
    return [
      // -----------------------------------------------------------------------------------
      // Admin Pages
      // -----------------------------------------------------------------------------------
      ...this.adminPages,

      // -----------------------------------------------------------------------------------
      // Employee Pages
      // -----------------------------------------------------------------------------------
      ...this.employeePages,
    ];
  }

  private addPermission(menu: AppMenuItem, prefix: string): AppMenuItem {
    const prefixPerm = `${prefix}.${menu.label}`;
    const permissions = menu.permissions?.length ? [...menu.permissions, prefixPerm] : [prefixPerm];

    return {
      ...menu,
      permissions: menu.items?.length ? undefined : permissions,
      items: menu.items?.map(a => this.addPermission(a, prefixPerm)),
    };
  }

  checkMenuVisible(menu: AppMenuItem, settings: ApplicationSettingsExtended) {
    if (menu.items && menu.items.length == 0) {
      return false;
    }
    const showOnNativeApp = !!menu.showOnNativeApp;
    if (this.viewUtils.isMobile() && !showOnNativeApp) {
      return false;
    }

    const isVisible =
      this.routeHelpers.checkPermissions(menu) &&
      this.viewUtils.isShowByStage(menu.stage, settings) &&
      this.viewUtils.isShowByClientCode(menu.clientCode, settings);

    return isVisible;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }
}
