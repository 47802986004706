/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CountryModelPaginationResult } from '../models/country-model-pagination-result';
import { CountryModel } from '../models/country-model';
@Injectable({
  providedIn: 'root',
})
class CountryService extends __BaseService {
  static readonly GetCountryListPath = '/api/Country';
  static readonly CreateCountryPath = '/api/Country';
  static readonly GetCountryByIdPath = '/api/Country/{CountryId}';
  static readonly UpdateCountryPath = '/api/Country/{CountryId}';
  static readonly DeleteCountryPath = '/api/Country/{CountryId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CountryService.GetCountryListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetCountryListResponse(params: CountryService.GetCountryListParams): __Observable<__StrictHttpResponse<CountryModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Country`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CountryModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `CountryService.GetCountryListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetCountryList(params: CountryService.GetCountryListParams): __Observable<CountryModelPaginationResult> {
    return this.GetCountryListResponse(params).pipe(
      __map(_r => _r.body as CountryModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateCountryResponse(body?: CountryModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Country`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateCountry(body?: CountryModel): __Observable<string> {
    return this.CreateCountryResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param CountryId undefined
   * @return Success
   */
  GetCountryByIdResponse(CountryId: string): __Observable<__StrictHttpResponse<CountryModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Country/${encodeURIComponent(String(CountryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CountryModel>;
      })
    );
  }
  /**
   * @param CountryId undefined
   * @return Success
   */
  GetCountryById(CountryId: string): __Observable<CountryModel> {
    return this.GetCountryByIdResponse(CountryId).pipe(
      __map(_r => _r.body as CountryModel)
    );
  }

  /**
   * @param params The `CountryService.UpdateCountryParams` containing the following parameters:
   *
   * - `CountryId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateCountryResponse(params: CountryService.UpdateCountryParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Country/${encodeURIComponent(String(params.CountryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `CountryService.UpdateCountryParams` containing the following parameters:
   *
   * - `CountryId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateCountry(params: CountryService.UpdateCountryParams): __Observable<string> {
    return this.UpdateCountryResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param CountryId undefined
   * @return Success
   */
  DeleteCountryResponse(CountryId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Country/${encodeURIComponent(String(CountryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param CountryId undefined
   * @return Success
   */
  DeleteCountry(CountryId: string): __Observable<string> {
    return this.DeleteCountryResponse(CountryId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module CountryService {

  /**
   * Parameters for GetCountryList
   */
  export interface GetCountryListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for UpdateCountry
   */
  export interface UpdateCountryParams {
    CountryId: string;
    body?: CountryModel;
  }
}

export { CountryService }
