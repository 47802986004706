import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-inline-label',
  templateUrl: './formly-inline-label-wrapper.component.html',
  styleUrls: ['./formly-inline-label-wrapper.component.css']
})
export class FormlyInlineLabelWrapperComponent extends FieldWrapper {

}
