/* tslint:disable */
type EAttendanceCertifyTypeOfTime =
  'TimeIn' |
  'TimeOut' |
  'TimeInAndTimeOut';
module EAttendanceCertifyTypeOfTime {
  export const TIME_IN: EAttendanceCertifyTypeOfTime = 'TimeIn';
  export const TIME_OUT: EAttendanceCertifyTypeOfTime = 'TimeOut';
  export const TIME_IN_AND_TIME_OUT: EAttendanceCertifyTypeOfTime = 'TimeInAndTimeOut';
  export function values(): EAttendanceCertifyTypeOfTime[] {
    return [
      TIME_IN,
      TIME_OUT,
      TIME_IN_AND_TIME_OUT
    ];
  }
}

export { EAttendanceCertifyTypeOfTime }