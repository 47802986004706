/* tslint:disable */
type WorkflowStepActionType =
  'None' |
  'MoveToSpecificStep' |
  'MoveToNextStep' |
  'MoveToPreviousStep';
module WorkflowStepActionType {
  export const NONE: WorkflowStepActionType = 'None';
  export const MOVE_TO_SPECIFIC_STEP: WorkflowStepActionType = 'MoveToSpecificStep';
  export const MOVE_TO_NEXT_STEP: WorkflowStepActionType = 'MoveToNextStep';
  export const MOVE_TO_PREVIOUS_STEP: WorkflowStepActionType = 'MoveToPreviousStep';
  export function values(): WorkflowStepActionType[] {
    return [
      NONE,
      MOVE_TO_SPECIFIC_STEP,
      MOVE_TO_NEXT_STEP,
      MOVE_TO_PREVIOUS_STEP
    ];
  }
}

export { WorkflowStepActionType }