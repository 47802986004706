/* tslint:disable */
type EPenaltyType =
  'WarningByVerbal' |
  'WarningByLetter' |
  'WarningByLetterAndPunnishment' |
  'TerminateWithoutPay';
module EPenaltyType {
  export const WARNING_BY_VERBAL: EPenaltyType = 'WarningByVerbal';
  export const WARNING_BY_LETTER: EPenaltyType = 'WarningByLetter';
  export const WARNING_BY_LETTER_AND_PUNNISHMENT: EPenaltyType = 'WarningByLetterAndPunnishment';
  export const TERMINATE_WITHOUT_PAY: EPenaltyType = 'TerminateWithoutPay';
  export function values(): EPenaltyType[] {
    return [
      WARNING_BY_VERBAL,
      WARNING_BY_LETTER,
      WARNING_BY_LETTER_AND_PUNNISHMENT,
      TERMINATE_WITHOUT_PAY
    ];
  }
}

export { EPenaltyType }