import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { calcAge } from 'app/utils/calc-age';
import {
  EmployeeAddressModel,
  EmployeeCertificateModel,
  EmployeeEducationModel,
  EmployeeInterestModel,
  EmployeeRelativeModel,
} from 'app/api/models';
import { EmployeeService } from 'app/api/services';
import { ModalEmployeeInterestComponent } from '../../../modals/core/modal-employee-interest/modal-employee-interest.component';

interface GeneralInfoModel {
  birthday?: string;
  ageString?: string;
  employeeDate?: string;
  longWorkString?: string;
  raceName?: string;
  nationalityName?: string;
  bloodGroup?: string;
  maritalStatus?: string;
  facebook?: string;
  instagram?: string;
  line?: string;
  twitter?: string;
}

interface EmployeeRelativeDisplayModel extends EmployeeRelativeModel {
  relation: string;
  icon: string;
}

@Component({
  selector: 'app-important-info',
  templateUrl: './important-info.component.html',
  styleUrls: ['./important-info.component.css'],
  providers: [DialogService],
})
export class ImportantInfoComponent implements OnInit {
  isEditable = false;
  generalInfo: GeneralInfoModel = {};
  familyList: EmployeeRelativeDisplayModel[];
  educationList: EmployeeEducationModel[];
  certificateList: EmployeeCertificateModel[];
  interestList: EmployeeInterestModel[];
  currentAddress: string;
  officialAddress: string;
  ref: DynamicDialogRef;

  constructor(private router: Router, private dialogService: DialogService, private employeeService: EmployeeService) { }

  ngOnInit() {
    this.setEditablePermission();
    this.setGeneralInfo();
    this.setAddressInfo();
    this.setFamilyList();
    this.setEducationList();
    this.setCertificateList();
    this.setInterestList();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setGeneralInfo() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetGeneralInfo(employeeId).subscribe(generalInfo => {
      this.generalInfo = {
        birthday: generalInfo.birthday ?? 'N/A',
        ageString: generalInfo.birthday ? this.toAgeString(generalInfo.birthday) : 'N/A',
        employeeDate: generalInfo.employeeDate ?? 'N/A',
        longWorkString: generalInfo.employeeDate ? this.toAgeString(generalInfo.employeeDate) : 'N/A',
        bloodGroup: generalInfo.bloodGroupName,
        maritalStatus: generalInfo.maritalStatusName,
        nationalityName: generalInfo.nationalityName,
        raceName: generalInfo.raceName,
        facebook: generalInfo.employeeContacts.filter(con => con.contactTypeId === 'FB')[0]?.value ?? 'N/A',
        instagram: generalInfo.employeeContacts.filter(con => con.contactTypeId === 'IN')[0]?.value ?? 'N/A',
        line: generalInfo.employeeContacts.filter(con => con.contactTypeId === 'LINE')[0]?.value ?? 'N/A',
        twitter: generalInfo.employeeContacts.filter(con => con.contactTypeId === 'TW')[0]?.value ?? 'N/A',
      };
    });
  }

  setAddressInfo() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetAddressInfo(employeeId).subscribe(addressInfo => {
      this.officialAddress = this.toAddressFormatDisplay(addressInfo.employeeRegisteredAddress);
      this.currentAddress = this.toAddressFormatDisplay(addressInfo.employeeCurrentAddress);
    });
  }

  setFamilyList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetFamily(employeeId).subscribe(relativeList => {
      this.familyList = relativeList.map(relative => {
        let icon = '';

        switch (relative.relativeTypeId) {
          case 'Father':
            icon = 'fas fa-male';
            break;
          case 'Mother':
            icon = 'fas fa-female';
            break;
          case 'Spouse':
            icon = 'fas fa-female';
            break;
          case 'Child':
            icon = 'fas fa-child';
            break;
          default:
            break;
        }

        return {
          ...relative,
          relation: relative.birthday != null 
          ? relative.relativeTypeName + ` (${calcAge(relative.birthday).year} ปี)`
          : relative.relativeTypeName + ` (0 ปี)`,
          icon,
        };
      });
    });
  }

  setEducationList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetEducations(employeeId).subscribe(educationList => {
      this.educationList = educationList;
    });
  }

  setCertificateList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetCertificates(employeeId).subscribe(certificateList => {
      this.certificateList = certificateList;
    });
  }

  setInterestList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetInterests(employeeId).subscribe(interestList => {
      this.interestList = interestList;
    });
  }

  showCreateEmployeeInterestDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่มข้อมูลความสนใจ', ModalEmployeeInterestComponent, '50%');

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setInterestList();
      }
    });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  private showDialog(data: any, header: string, dialogComponent: any, width: string) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width,
      contentStyle: { overflow: 'auto' },
      data,
    });
  }

  private toAgeString(birthday: string): string {
    const { year, month, day } = calcAge(birthday);

    return `${year} ปี ${month} เดือน ${day} วัน`;
  }

  private toAddressFormatDisplay(address: EmployeeAddressModel): string {
    if (!address) {
      return 'N/A';
    }

    return `${address.address ?? ''}
    ต.${address.subDistrict ?? 'N/A'}
    อ.${address.district ?? 'N/A'}
    จ.${address.province ?? 'N/A'}
    ${address.zipcode ?? ''}`;
  }
}
