import { ImportRevenuesModel } from './../../../api/models/import-revenues-model';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ImportEmployeeWithShiftsModel } from 'app/api/models/import-employee-with-shifts-model';
import { EmployeeInShiftService, EmployeeService, RevenueService } from 'app/api/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';

interface SelectFileParam {
  currentFiles: File[];
}

@Component({
  selector: 'app-modal-import-revenue',
  templateUrl: './modal-import-revenue.component.html',
  styleUrls: ['./modal-import-revenue.component.css'],
})
export class ModalImportRevenueComponent implements OnInit {
  blob: Blob = null;
  model: any = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'file',
      type: 'upload-file',
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'ข้อมูลกำไรบริษัท (Excel)',
        chooseLabel: 'อัพโหลด',
        previewWidth: 150,
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
        onSelect: (selectFileParam: SelectFileParam) => {
          this.blob = new Blob([selectFileParam.currentFiles[0]]);
        },
        onRemove: (_, model: any, oldDocumentId: number) => {},
      },
    },
  ];
  step = 1;
  importModel: ImportRevenuesModel = {};

  constructor(
    private revenueService: RevenueService,
    private notificationService: AppNotificationServiceService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {}

  submit1() {
    if (this.blob == null) {
      this.notificationService.error('ข้อผิดพลาด', 'กรุณาเลือกไฟล์ที่จะนำเข้า');
      return;
    }

    this.revenueService
      .ImportRevenuesExcelStep1(this.blob)
      .subscribe(
        result => {
          this.importModel = result;
          this.step = 2;
          console.log(this.importModel);
        },
        err => {
          this.notificationService.error('ผลการนำเข้า', err.error.message);
        },
      )
      .add(() => {
        // finally
      });
  }

  submit2() {
    this.revenueService
      .ImportRevenuesExcelStep2(this.importModel)
      .subscribe(
        result => {
          this.notificationService.success('ผลการนำเข้า', 'นำเข้าสำเร็จ');
        },
        err => {
          this.notificationService.error('ผลการนำเข้า', 'นำเข้าไม่สำเร็จ');
        },
      )
      .add(() => {
        // finally
        this.close();
      });
  }
  close() {
    this.ref.close();
  }
}
