import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportConfigModalModuleComponent } from './report-config-modal-module/report-config-modal-module.component';
import { SharedModule } from 'shared/shared.module';
import { ComponentsModule } from '../../components/components.module';
import { FoundationComponentModule } from '../../components/foundation/FoundationComponent.module';

const components = [ReportConfigModalModuleComponent];

@NgModule({
    declarations: components,
    imports: [
      CommonModule,
      SharedModule,
      FoundationComponentModule
    ],
    exports: components
})
export class ModalSharedModule { }
