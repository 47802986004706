/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HeadcountDataModel } from '../models/headcount-data-model';
import { DashboardFilterModel } from '../models/dashboard-filter-model';
import { TurnoverRateDataModel } from '../models/turnover-rate-data-model';
import { TotalEmployeeDataByUnitModel } from '../models/total-employee-data-by-unit-model';
import { TotalEmployeeByYearChartModel } from '../models/total-employee-by-year-chart-model';
import { TurnoverRateSummaryByYear } from '../models/turnover-rate-summary-by-year';
import { TurnoverRateSummaryFilterModel } from '../models/turnover-rate-summary-filter-model';
import { TorYosByYear } from '../models/tor-yos-by-year';
import { TorAgeByYear } from '../models/tor-age-by-year';
import { AvgTurnoverEmployeeYosByYear } from '../models/avg-turnover-employee-yos-by-year';
import { AvgTurnoverEmployeeAgeByYear } from '../models/avg-turnover-employee-age-by-year';
@Injectable({
  providedIn: 'root',
})
class WorkforceProfileService extends __BaseService {
  static readonly GetHeadcountChartByUnitDataPath = '/api/WorkforceProfile/headcountChart';
  static readonly GetTurnoverRateChartDataPath = '/api/WorkforceProfile/turnoverRateChart';
  static readonly GetTotalEmployeeChartByUnitDataPath = '/api/WorkforceProfile/totalEmployeeChart';
  static readonly GetTotalEmployeeChartByYearDataPath = '/api/WorkforceProfile/totalEmployeeChartByYear';
  static readonly GetTurnoverRateSummaryByYearDataPath = '/api/WorkforceProfile/turnoverRateSummaryByYear';
  static readonly GetTurnoverRateEmployeeYOSByYearDataPath = '/api/WorkforceProfile/turnoverRateEmployeeYOSByYear';
  static readonly GetTurnoverRateEmployeeAgeByYearDataPath = '/api/WorkforceProfile/turnoverRateEmployeeAgeByYear';
  static readonly GetAvgTorEmployeeYosByYearPath = '/api/WorkforceProfile/avgTorEmployeeYosByYear';
  static readonly GetAvgTorEmployeeAgeByYearPath = '/api/WorkforceProfile/avgTorEmployeeAgeByYear';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetHeadcountChartByUnitDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<Array<HeadcountDataModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkforceProfile/headcountChart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HeadcountDataModel>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetHeadcountChartByUnitData(body?: DashboardFilterModel): __Observable<Array<HeadcountDataModel>> {
    return this.GetHeadcountChartByUnitDataResponse(body).pipe(
      __map(_r => _r.body as Array<HeadcountDataModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetTurnoverRateChartDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<Array<TurnoverRateDataModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkforceProfile/turnoverRateChart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TurnoverRateDataModel>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetTurnoverRateChartData(body?: DashboardFilterModel): __Observable<Array<TurnoverRateDataModel>> {
    return this.GetTurnoverRateChartDataResponse(body).pipe(
      __map(_r => _r.body as Array<TurnoverRateDataModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetTotalEmployeeChartByUnitDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<Array<TotalEmployeeDataByUnitModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkforceProfile/totalEmployeeChart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TotalEmployeeDataByUnitModel>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetTotalEmployeeChartByUnitData(body?: DashboardFilterModel): __Observable<Array<TotalEmployeeDataByUnitModel>> {
    return this.GetTotalEmployeeChartByUnitDataResponse(body).pipe(
      __map(_r => _r.body as Array<TotalEmployeeDataByUnitModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetTotalEmployeeChartByYearDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<Array<TotalEmployeeByYearChartModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkforceProfile/totalEmployeeChartByYear`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TotalEmployeeByYearChartModel>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetTotalEmployeeChartByYearData(body?: DashboardFilterModel): __Observable<Array<TotalEmployeeByYearChartModel>> {
    return this.GetTotalEmployeeChartByYearDataResponse(body).pipe(
      __map(_r => _r.body as Array<TotalEmployeeByYearChartModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetTurnoverRateSummaryByYearDataResponse(body?: TurnoverRateSummaryFilterModel): __Observable<__StrictHttpResponse<Array<TurnoverRateSummaryByYear>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkforceProfile/turnoverRateSummaryByYear`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TurnoverRateSummaryByYear>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetTurnoverRateSummaryByYearData(body?: TurnoverRateSummaryFilterModel): __Observable<Array<TurnoverRateSummaryByYear>> {
    return this.GetTurnoverRateSummaryByYearDataResponse(body).pipe(
      __map(_r => _r.body as Array<TurnoverRateSummaryByYear>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetTurnoverRateEmployeeYOSByYearDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<TorYosByYear>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkforceProfile/turnoverRateEmployeeYOSByYear`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TorYosByYear>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetTurnoverRateEmployeeYOSByYearData(body?: DashboardFilterModel): __Observable<TorYosByYear> {
    return this.GetTurnoverRateEmployeeYOSByYearDataResponse(body).pipe(
      __map(_r => _r.body as TorYosByYear)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetTurnoverRateEmployeeAgeByYearDataResponse(body?: DashboardFilterModel): __Observable<__StrictHttpResponse<TorAgeByYear>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkforceProfile/turnoverRateEmployeeAgeByYear`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TorAgeByYear>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetTurnoverRateEmployeeAgeByYearData(body?: DashboardFilterModel): __Observable<TorAgeByYear> {
    return this.GetTurnoverRateEmployeeAgeByYearDataResponse(body).pipe(
      __map(_r => _r.body as TorAgeByYear)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetAvgTorEmployeeYosByYearResponse(body?: TurnoverRateSummaryFilterModel): __Observable<__StrictHttpResponse<AvgTurnoverEmployeeYosByYear>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkforceProfile/avgTorEmployeeYosByYear`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AvgTurnoverEmployeeYosByYear>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetAvgTorEmployeeYosByYear(body?: TurnoverRateSummaryFilterModel): __Observable<AvgTurnoverEmployeeYosByYear> {
    return this.GetAvgTorEmployeeYosByYearResponse(body).pipe(
      __map(_r => _r.body as AvgTurnoverEmployeeYosByYear)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  GetAvgTorEmployeeAgeByYearResponse(body?: TurnoverRateSummaryFilterModel): __Observable<__StrictHttpResponse<AvgTurnoverEmployeeAgeByYear>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkforceProfile/avgTorEmployeeAgeByYear`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AvgTurnoverEmployeeAgeByYear>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  GetAvgTorEmployeeAgeByYear(body?: TurnoverRateSummaryFilterModel): __Observable<AvgTurnoverEmployeeAgeByYear> {
    return this.GetAvgTorEmployeeAgeByYearResponse(body).pipe(
      __map(_r => _r.body as AvgTurnoverEmployeeAgeByYear)
    );
  }
}

module WorkforceProfileService {
}

export { WorkforceProfileService }
