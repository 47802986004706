import { Component, OnInit,OnDestroy } from '@angular/core';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeService } from 'app/api/services';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, of } from 'rxjs';
import { EmployeeModel } from 'app/api/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';


interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeModel;
}

@Component({
  selector: 'app-modal-employee-resigns',
  templateUrl: './modal-employee-resigns.component.html',
  styleUrls: ['./modal-employee-resigns.component.scss']
})
export class ModalEmployeeResignsComponent implements OnInit {
  model: EmployeeModel = {};
  form = new FormGroup({});

  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'resignedReason',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'สาเหตุที่พนักงานลาออก',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: [
          { label: 'ลาออก', value: 'ลาออก' },
          { label: 'เลิกจ้าง', value: 'เลิกจ้าง' },
          { label: 'ปลดออก', value: 'ปลดออก' },
        ],
      },
    },
    {
      key: 'terminatedDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        required: true,
        translate: true,
        label: 'ระบุวันที่ลาออก',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'remarkResigned',
      type: 'textarea',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'เหตุผลการลาออก:',
        labelClass: 'col-4',
        inputClass: 'col-6',
        placeholder: '@blank'
      },
    },
  ]

  private unsubscribe$ = new Subject<any>();
  constructor(
    private notificationService: AppNotificationServiceService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private employeeService: EmployeeService,
  ) { }

  async ngOnInit() {
    // console.log(this.config.data?.id);
    await this.initData();
    }

  async initData() {
    const emp = await this.employeeService.GetEmployeeInfo(this.getEmpId()).toPromise();
    this.model = emp;
    this.model.terminatedDate = moment(new Date).format('YYYY-MM-DD');
  }

  submit() {
    this.employeeService
    .TerminateEmployee({
      employeeId: this.model.employeeId,
      body: this.model,
    })
    .subscribe(
      () => {
        this.notificationService.saveCompleted();
        this.ref.close(this.model);
      },
      (err: HttpErrorResponse) => {
        const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
        this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
      },
    );

  }

  close() {
    this.ref.close();
  }

  getEmpId() {
    return +this.config.data?.id;
  }


}
