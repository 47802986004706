import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  EmployeeAssetSurveyItemModel,
  EmployeeAssetSurveyModel,
  EmployeeAssetSurveyTemplateItemModel,
} from 'app/api/models';
import { EmployeeService, EmployeeAssetSurveyService } from 'app/api/services';
import { AppBreadcrumbService } from 'app/layout/layout-default/app-breadcrumb/app-breadcrumb.service';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';

type ComponentMode = 'CREATE' | 'UPDATE';

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeAssetSurveyModel | keyof EmployeeAssetSurveyItemModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-asset-form',
  templateUrl: './asset-form.component.html',
  styleUrls: ['./asset-form.component.css'],
})
export class AssetFormComponent implements OnInit {
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [];
  model: EmployeeAssetSurveyModel = {};
  componentMode: ComponentMode;

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    private route: ActivatedRoute,
    private router: Router,
    private employeeAssetSurveyService: EmployeeAssetSurveyService,
    private notificationService: AppNotificationServiceService,
    private employeeService: EmployeeService,
  ) {}

  ngOnInit() {
    this.initBreadcrumb();
    this.initData();
  }

  initBreadcrumb() {
    const url = this.router.url;

    if (url.includes('admin')) {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/admin' },
        { label: 'ส่วนหลัก', routerLink: '/admin/core' },
        { label: 'รายชื่อพนักงานทั้งหมด', routerLink: '/admin/core/employee/list' },
        { label: 'ข้อมูลพนักงาน', routerLink: `/admin/core/employee/list/${this.getEmployeeId()}/other-info` },
        { label: 'แบบฟอร์มตรวจสอบทรัพย์สิน (เข้า-ออก)' },
      ]);
    } else if (url.includes('employee') && url.includes('info')) {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/employee' },
        { label: 'ข้อมูลส่วนตัว', routerLink: '/employee/info' },
        { label: 'ข้อมูลพนักงาน', routerLink: `/employee/info/${this.getEmployeeId()}/other-info` },
        { label: 'แบบฟอร์มตรวจสอบทรัพย์สิน (เข้า-ออก)' },
      ]);
    } else {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/employee' },
        { label: 'ส่วนหลัก', routerLink: '/employee/core' },
        { label: 'พนักงานใต้บังคับบัญชาทั้งหมด', routerLink: '/employee/core/team' },
        { label: 'ข้อมูลพนักงาน', routerLink: `/employee/core/team/${this.getEmployeeId()}/other-info` },
        { label: 'แบบฟอร์มตรวจสอบทรัพย์สิน (เข้า-ออก)' },
      ]);
    }
  }

  initData() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetOtherInfo(employeeId).subscribe(({ employeeAssetSurvey }) => {
      if (employeeAssetSurvey) {
        this.componentMode = 'UPDATE';
        this.model = employeeAssetSurvey;
        this.setFormWithTemplate();
      } else {
        this.employeeAssetSurveyService.GetEmployeeAssetSurveyTemplateDefault().subscribe(surveyModel => {
          const employeeAssetSurveyTemplateId = surveyModel.employeeAssetSurveyTemplateId;
          this.model = {
            employeeId,
            employeeAssetSurveyTemplateId,
            employeeAssetSurveyItems: surveyModel.employeeAssetSurveyTemplateItems.map(item => {
              return {
                ...item,
                createdDate: undefined,
                createdBy: undefined,
                updatedDate: undefined,
                updatedBy: undefined,
              };
            }),
          };
          this.setFormWithTemplate();
        });

        this.componentMode = 'CREATE';
      }
    });
  }

  onSaveBtnClick() {
    console.log(this.model);

    this.notificationService
      .confirm('บันทึก', 'กรุณากด "ยืนยัน" หากต้องการบันทึกแบบฟอร์มตรวจสอบทรัพย์สิน (เข้า-ออก)')
      .then(res => {
        if (res.isConfirmed) {
          this.save();
        }
      });
  }

  save() {
    switch (this.componentMode) {
      case 'CREATE':
        this.employeeAssetSurveyService.CreateEmployeeAssetSurvey(this.model).subscribe(
          () => {
            this.notificationService.saveCompleted().then(v => {
              this.router.navigate(['..'], { relativeTo: this.route });
            });
          },
          (err: HttpErrorResponse) => {
            console.log(err);
          },
        );
        break;
      case 'UPDATE':
        this.employeeAssetSurveyService.UpdateEmployeeAssetSurvey(this.model).subscribe(
          () => {
            this.notificationService.saveCompleted().then(v => {
              this.router.navigate(['..'], { relativeTo: this.route });
            });
          },
          (err: HttpErrorResponse) => {
            console.log(err);
          },
        );
        break;
      default:
        break;
    }
  }

  setFormWithTemplate(): void {
    const items: EmployeeAssetSurveyTemplateItemModel[] = this.model.employeeAssetSurveyItems;

    const formCheckList: CustomFormlyFieldConfig[] = [];
    items.forEach((item, i) => {
      formCheckList.push(
        {
          key: i.toString() as any,
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'employeeAssetId',
              defaultValue: item.employeeAssetId,
            },
            {
              key: 'employeeId',
              defaultValue: this.model.employeeId,
            },
            {
              className: 'col-1',
              wrappers: ['inline-label'],
              templateOptions: {
                labelStyle: {
                  'text-align': 'center',
                },
                label: (i + 1).toString(),
                labelClass: 'col-12',
              },
            },
            this.buildTopicLabel(item),
            ,
            {
              key: 'isReturn',
              type: 'checkbox',
              className: 'col-1',
              defaultValue: false,
              templateOptions: {},
            },
            {
              key: 'isWithdraw',
              type: 'checkbox',
              className: 'col-6',
              defaultValue: false,
              templateOptions: {},
            },
          ],
        },
        { template: '<hr />' },
      );
    });

    this.fields = [
      {
        key: 'employeeAssetSurveyItems',
        fieldGroup: formCheckList,
      },
    ];
  }

  buildTopicLabel(item: EmployeeAssetSurveyTemplateItemModel): CustomFormlyFieldConfig {
    if (item.employeeAsset.isShowPrice) {
      return {
        className: 'col-4',
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            className: 'col-fixed',
            wrappers: ['inline-label'],
            templateOptions: {
              labelStyle: {
                'text-align': 'left',
              },
              label: item.employeeAsset.assetName,
              labelClass: 'col-12',
            },
          },
          {
            key: 'assetPrice',
            type: 'primeng-input',
            className: 'col-4',
            wrappers: ['inline-label'],
            defaultValue: 0,
            templateOptions: {
              placeholder: 'จำนวนเงิน',
              type: 'number',
            },
          },
          {
            className: 'col-fixed',
            wrappers: ['inline-label'],
            templateOptions: {
              labelStyle: {
                'text-align': 'left',
              },
              label: 'บาท',
              labelClass: 'col-12',
            },
          },
        ],
      };
    }

    return {
      className: 'col-4',
      wrappers: ['inline-label'],
      templateOptions: {
        labelStyle: {
          'text-align': 'left',
        },
        label: item.employeeAsset.assetName,
        labelClass: 'col-12',
      },
    };
  }

  getEmployeeId() {
    return +this.route.snapshot.paramMap.get('employeeId');
  }

  disbleSaveBtn(): boolean {
    return this.form.invalid;
  }
}
