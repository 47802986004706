import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ImportDutyStep1Model, ImportEmployeeWithShiftsModel } from 'app/api/models';
import { CompanyService, DocumentService, EmployeeInShiftService } from 'app/api/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';
import { toOptionList } from 'utils/formly-utils';

interface SelectFileParam {
  currentFiles: File[];
}

@Component({
  selector: 'app-modal-import-monthly-shift',
  templateUrl: './modal-import-monthly-shift.component.html',
  styleUrls: ['./modal-import-monthly-shift.component.css'],
})
export class ModalImportMonthlyShiftComponent implements OnInit {
  blob: Blob = null;
  model: ImportDutyStep1Model = {};
  documentId: number;
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'companyId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      className: 'col-6',
      templateOptions: {
        label: 'บริษัท:',
        placeholder: 'บริษัท',
        labelClass: 'col-4',
        inputClass: 'col-4',
        filter: true,
        required: true,
        options: this.companyService
          .GetCompaniesList({})
          .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'companyId', 'name')))),
      },
    },
    {
      key: 'month',
      type: 'month-picker',
      wrappers: ['inline-label'],
      className: 'col-4',
      templateOptions: {
        translate: true,
        label: 'เดือน:',
        placeholder: 'เดือน',
        labelClass: 'col-4',
        inputClass: 'col-fixed',
        required: true,
      },
    },
    {
      key: 'documentId',
      type: 'upload-file',
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        required: false,
        label: 'attach_file',
        placeholder: '@blank',
        chooseLabel: 'อัพโหลด',
        previewWidth: 150,

        labelClass: 'col-4',
        inputClass: 'col-6',
        onSelect: (selectFileParam: SelectFileParam) => {
          this.documentService
            .CreateDocument({
              description: 'Employee Attachment file',
              file: selectFileParam.currentFiles[0],
            })
            .subscribe(result => {
              this.documentId = result.documentId;
            });
        },
        onRemove: (_, model: any, oldDocumentId: number) => {},
      },
    },
  ];
  step = 1;
  importModel: ImportEmployeeWithShiftsModel = {};

  constructor(
    private companyService: CompanyService,
    private employeeInShiftService: EmployeeInShiftService,
    private notificationService: AppNotificationServiceService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private documentService: DocumentService,
  ) {}

  ngOnInit(): void {}

  submit1() {
    if (this.documentId == null) {
      this.notificationService.error('ข้อผิดพลาด', 'กรุณาเลือกไฟล์ที่จะนำเข้า');
      return;
    }
    this.model.documentId = this.documentId;
    this.employeeInShiftService
      .ImportDutyExcelStep1(this.model)
      .subscribe(result => {
        this.importModel = result;
        this.step = 2;
        console.log(result);
      })
      .add(() => {
        // finally
      });
  }

  submit2() {
    this.employeeInShiftService
      .ImportDutyExcelStep2(this.importModel)
      .subscribe(
        result => {
          this.notificationService.success('ผลการนำเข้า', 'นำเข้าสำเร็จ');
        },
        err => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      )
      .add(() => {
        // finally
        this.close();
      });
  }
  close() {
    this.ref.close();
  }
}
