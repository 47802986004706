import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { EmployeeAddressModel, EmployeeContactInfoModel, EmployeeContactModel, EmployeeModel } from 'app/api/models';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeService } from 'app/api/services';
import { map } from 'rxjs/operators';

interface ContactInfoModel {
  phone: EmployeeContactModel;
  mobile: EmployeeContactModel;
  email: EmployeeContactModel;
  line: EmployeeContactModel;
  twitter: EmployeeContactModel;
  facebook: EmployeeContactModel;
}

interface ContactFormModel extends EmployeeContactInfoModel {
  contactInfo?: ContactInfoModel;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof ContactFormModel | keyof EmployeeAddressModel | keyof ContactInfoModel | keyof EmployeeContactModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css'],
})
export class ContactInfoComponent implements OnInit {
  isEditable = false;
  employeeUpdatedEvent = new EventEmitter();
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  model: ContactFormModel = {};
  isSameAddress: boolean;
  registeredAddressFields: CustomFormlyFieldConfig[] = [
    {
      key: 'employeeRegisteredAddress',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        // {
        //   key: 'addressTypeId',
        //   type: 'filter-dropdown',
        //   className: 'col-4',
        //   templateOptions: {
        //     attributes: {
        //       style: 'width: 100%;',
        //     },
        //     label: 'ประเภทที่อยู่',
        //     placeholder: 'ประเภทที่อยู่',
        //
        //     options: this.masterDataRepo
        //       .getAddressTypeOptionList()
        //       .pipe(map(a => a.filter(b => b.label === 'ที่อยู่ตามทะเบียนบ้าน'))),
        //   },
        // },
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-12',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'address',
            placeholder: '@blank',
          },
        },
        {
          key: 'province',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'province',
            placeholder: '@blank',
            options: this.masterDataRepo.getProvinceOptionList(),
            change: (field,$event) => {
              const provinceId = this.masterDataRepo.getProvinceIdByName(field.formControl.value);
              console.log(field, provinceId);

              const controls_district = this.registeredAddressFields[0].fieldGroup.find(a => a.key === 'district');
              const controls_subdistrict = this.registeredAddressFields[0].fieldGroup.find(a => a.key === 'subDistrict');
              controls_district.templateOptions.options = [];
              controls_subdistrict.templateOptions.options = [];
              controls_district.templateOptions.options = this.masterDataRepo.getAmphoeOptionList(provinceId);
              (this.form.controls.employeeRegisteredAddress as FormGroup).controls.district.reset();
              (this.form.controls.employeeRegisteredAddress as FormGroup).controls.subDistrict.reset();
            },
            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'district',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'district',
            placeholder: '@blank',
            change: (field,$event) => {
              const amphoeId = this.masterDataRepo.getAmphoeIdByName(field.formControl.value);
              console.log(field, amphoeId);

              const controls_subdistrict = this.registeredAddressFields[0].fieldGroup.find(a => a.key === 'subDistrict');
              controls_subdistrict.templateOptions.options = [];
              controls_subdistrict.templateOptions.options = this.masterDataRepo.getTambonOptionList(amphoeId);
              (this.form.controls.employeeRegisteredAddress as FormGroup).controls.subDistrict.reset();
            },
            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'subDistrict',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'subdistrict',
            placeholder: '@blank',

            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'zipcode',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'postal_code',
            placeholder: '@blank',

            maxLength: 5,
          },
        },
      ],
    },
  ];
  currentAddressFields: CustomFormlyFieldConfig[] = [
    {
      key: 'employeeCurrentAddress',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'addressTypeId',
          type: 'filter-dropdown',
          className: 'col-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable || this.isSameAddress,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'housing_type',
            placeholder: '@blank',
            options: this.masterDataRepo
              .getAddressTypeOptionList()
              .pipe(map(a => a.filter(b => b.label !== 'ที่อยู่ตามทะเบียนบ้าน'))),
          },
        },
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-8',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable || this.isSameAddress,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'address',
            placeholder: '@blank',
          },
        },
        {
          key: 'province',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable || this.isSameAddress,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'province',
            placeholder: '@blank',
            options: this.masterDataRepo.getProvinceOptionList(),

            change: (field,$event) => {
              const provinceId = this.masterDataRepo.getProvinceIdByName(field.formControl.value);
              console.log(field, provinceId);
              const controls_district = this.currentAddressFields[0].fieldGroup.find(a => a.key === 'district');
              const controls_subdistrict = this.currentAddressFields[0].fieldGroup.find(a => a.key === 'subDistrict');
              controls_district.templateOptions.options = [];
              controls_subdistrict.templateOptions.options = [];
              controls_district.templateOptions.options = this.masterDataRepo.getAmphoeOptionList(provinceId);
              (this.form.controls.employeeCurrentAddress as FormGroup).controls.district.reset();
              (this.form.controls.employeeCurrentAddress as FormGroup).controls.subDistrict.reset();
            },
            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'district',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable || this.isSameAddress,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'district',
            placeholder: '@blank',
            options: this.masterDataRepo.getAmphoeOptionList(),

            change: (field,$event) => {
              const amphoeId = this.masterDataRepo.getAmphoeIdByName(field.formControl.value);
              console.log(field, amphoeId);

              const controls_subdistrict = this.currentAddressFields[0].fieldGroup.find(a => a.key === 'subDistrict');
              controls_subdistrict.templateOptions.options = [];
              controls_subdistrict.templateOptions.options = this.masterDataRepo.getTambonOptionList(amphoeId);
              (this.form.controls.employeeCurrentAddress as FormGroup).controls.subDistrict.reset();
            },
            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'subDistrict',
          type: 'filter-dropdown',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable || this.isSameAddress,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'subdistrict',
            placeholder: '@blank',
            options: this.masterDataRepo.getTambonOptionList(),

            filter: true,
            virtualScroll: true,
            itemSize: 30,
          },
        },
        {
          key: 'zipcode',
          type: 'primeng-input',
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable || this.isSameAddress,
          },
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },
            translate: true,
            label: 'postal_code',
            placeholder: '@blank',
            maxLength: 5,
          },
        },
      ],
    },
  ];
  contactInfoFields: CustomFormlyFieldConfig[] = [
    {
      key: 'contactInfo',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'phone',
          className: 'col-4',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'value',
              type: 'primeng-input',
              className: 'col-12',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                translate: true,
                label: 'home_phone',
                placeholder: '@blank',
                maxLength: 128,
              },
            },
          ],
        },
        {
          key: 'mobile',
          className: 'col-4',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'value',
              type: 'primeng-input',
              className: 'col-12',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                translate: true,
                label: 'phone_number',
                placeholder: '@blank',
                maxLength: 128,
              },
            },
          ],
        },
        {
          key: 'email',
          className: 'col-4',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'value',
              type: 'primeng-input',
              className: 'col-12',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                translate: true,
                label: 'private_email',
                placeholder: '@blank',
                maxLength: 128,
              },
            },
          ],
        },
        {
          key: 'line',
          className: 'col-4',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'value',
              type: 'primeng-input',
              className: 'col-12',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                translate: true,
                label: 'line',
                placeholder: '@blank',
                maxLength: 128,
              },
            },
          ],
        },
        {
          key: 'twitter',
          className: 'col-4',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'value',
              type: 'primeng-input',
              className: 'col-12',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                translate: true,
                label: 'twitter',
                placeholder: '@blank',
                maxLength: 128,
              },
            },
          ],
        },
        {
          key: 'facebook',
          className: 'col-4',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              key: 'value',
              type: 'primeng-input',
              className: 'col-12',
              expressionProperties: {
                'templateOptions.disabled': () => !this.isEditable,
              },
              templateOptions: {
                attributes: {
                  style: 'width: 100%;',
                },
                translate: true,
                label: 'facebook',
                placeholder: '@blank',
                maxLength: 128,
              },
            },
          ],
        },
      ],
    },
  ];

  constructor(
    private employeeService: EmployeeService,
    private masterDataRepo: MasterDataRepo,
    private router: Router,
    private notificationService: AppNotificationServiceService,
  ) {}

  ngOnInit(): void {
    this.setEditablePermission();
    this.setContactInfo();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setEmployeeData() {
    // const employeeId = this.getEmployeeId();
    // this.employeeRepo.getInfo(employeeId).subscribe(employee => {
    //   const { employeeCurrentAddress, employeeRegisteredAddress, employeeContacts } = employee;
    //   const registeredAddress: AddressModel = {
    //     addressTypeId: employeeRegisteredAddress.addressTypeId,
    //     address: employeeRegisteredAddress.address,
    //     province: employeeRegisteredAddress.province,
    //     district: employeeRegisteredAddress.district,
    //     subDistrict: employeeRegisteredAddress.subDistrict,
    //     zipcode: employeeRegisteredAddress.zipcode,
    //   };
    //   const currentAddress: AddressModel = {
    //     addressTypeId: employeeCurrentAddress.addressTypeId,
    //     address: employeeCurrentAddress.address,
    //     province: employeeCurrentAddress.province,
    //     district: employeeCurrentAddress.district,
    //     subDistrict: employeeCurrentAddress.subDistrict,
    //     zipcode: employeeCurrentAddress.zipcode,
    //   };
    //   const contactInfo: ContactInfoModel = {
    //     phone: { 'HT', value: '' },
    //     mobile: { contactTypeId: 'MOBILE', value: '' },
    //     email: { contactTypeId: eContactType.PERSONAL_EMAIL, value: '' },
    //     line: { contactTypeId: 'LINE', value: '' },
    //     twitter: { contactTypeId: eContactType.TWITTER, value: '' },
    //     facebook: { contactTypeId: 'FACEBOOK', value: '' },
    //   };
    //   employeeContacts.forEach(cont => {
    //     switch (cont.contactTypeId as eContactType) {
    //       case 'HT':
    //         contactInfo.phone = cont;
    //         break;
    //       case 'MOBILE':
    //         contactInfo.mobile = cont;
    //         break;
    //       case eContactType.PERSONAL_EMAIL:
    //         contactInfo.email = cont;
    //         break;
    //       case 'LINE':
    //         contactInfo.line = cont;
    //         break;
    //       case eContactType.TWITTER:
    //         contactInfo.twitter = cont;
    //         break;
    //       case 'FACEBOOK':
    //         contactInfo.facebook = cont;
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    //   this.form.reset();
    //   this.employee = employee;
    //   this.model = {
    //     currentAddress,
    //     registeredAddress,
    //     contactInfo,
    //   };
    //   this.isSameAddress = this.isEqualAddress(registeredAddress, currentAddress);
    //   this.setEnabledCurrentAddress();
    //   this.setAddressOptions();
    // });
  }

  setContactInfo() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetContactInfo(employeeId).subscribe(contactInfo => {
      const {
        employeeCurrentAddress,
        employeeRegisteredAddress,
        facebookAccount,
        homeTelephone,
        lineID,
        mobile,
        personalEmail,
        twitterAccount,
      } = contactInfo;

      console.log(employeeCurrentAddress);
      console.log(employeeRegisteredAddress);

      const contact: ContactInfoModel = {
        phone: homeTelephone,
        mobile,
        email: personalEmail,
        line: lineID,
        twitter: twitterAccount,
        facebook: facebookAccount,
      };

      this.form.reset();

      this.model = {
        employeeRegisteredAddress,
        employeeCurrentAddress,
        contactInfo: contact,
      };
      this.isSameAddress = this.isEqualAddress(employeeRegisteredAddress, employeeCurrentAddress);
      this.setEnabledCurrentAddress();
      this.setAddressOptions();
    });
  }

  onSaveBtnClick() {
    console.log(this.model);
    this.notificationService.confirm('บันทึก', 'ยืนยันการบันทึกข้อมูล').then(value => {
      if (value.isConfirmed) {
        this.updateEmployeeContactInfo();
      }
    });
  }

  onIsSameAddressChange() {
    this.form.markAsDirty();
    this.setEnabledCurrentAddress();

    if (!this.isSameAddress) {
      this.model = {
        ...this.model,
        employeeCurrentAddress: {
          ...this.model.employeeCurrentAddress,
          addressTypeId: null,
        },
      };
    }
  }

  setEnabledCurrentAddress() {
    if (!this.isEditable) {
      return;
    }

    if (this.isSameAddress) {
      this.form.controls.employeeCurrentAddress?.disable();
    } else {
      this.form.controls.employeeCurrentAddress?.enable();
    }
  }

  updateEmployeeContactInfo() {
    const employeeId = this.getEmployeeId();
    const registeredAddress = this.model.employeeRegisteredAddress;
    const currentAddress = this.model.employeeCurrentAddress;
    const { phone, mobile, email, line, twitter, facebook } = this.model.contactInfo;

    this.employeeService
      .UpdateEmployeeContact({
        employeeId,
        body: {
          employeeRegisteredAddress: registeredAddress,
          employeeCurrentAddress: {
            ...currentAddress,
            employeeAddressId:
              registeredAddress.employeeAddressId === currentAddress.employeeAddressId
                ? 0
                : currentAddress.employeeAddressId,
            addressTypeId: this.isSameAddress ? registeredAddress.addressTypeId : currentAddress.addressTypeId,
            address: this.isSameAddress ? registeredAddress.address : currentAddress.address,
            province: this.isSameAddress ? registeredAddress.province : currentAddress.province,
            district: this.isSameAddress ? registeredAddress.district : currentAddress.district,
            subDistrict: this.isSameAddress ? registeredAddress.subDistrict : currentAddress.subDistrict,
            zipcode: this.isSameAddress ? registeredAddress.zipcode : currentAddress.zipcode,
          },
          facebookAccount: this.toEmployeeContactModel(employeeId, facebook),
          twitterAccount: this.toEmployeeContactModel(employeeId, twitter),
          personalEmail: this.toEmployeeContactModel(employeeId, email),
          mobile: this.toEmployeeContactModel(employeeId, mobile),
          lineID: this.toEmployeeContactModel(employeeId, line),
          homeTelephone: this.toEmployeeContactModel(employeeId, phone),
        },
      })
      .subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.setEmployeeData();
          this.setContactInfo();
          this.employeeUpdatedEvent.next(null);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        },
      );
  }

  toEmployeeContactModel(employeeId: number, contact: EmployeeContactModel): EmployeeContactModel {
    return {
      employeeId,
      ...contact,
    };
  }

  private isEqualAddress(address1: EmployeeAddressModel, address2: EmployeeAddressModel): boolean {
    const keys: (keyof EmployeeAddressModel)[] = ['address', 'province', 'district', 'subDistrict', 'zipcode'];

    if (!address1 || !address2) {
      return false;
    }

    for (const key of keys) {
      if (address1[key] !== address2[key]) {
        return false;
      }
    }

    return true;
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  private setAddressOptions() {
    const employeeRegisteredAddress = this.model.employeeRegisteredAddress;
    const provinceId = employeeRegisteredAddress
      ? this.masterDataRepo.getProvinceIdByName(employeeRegisteredAddress.province)
      : undefined;
    const amphoeId = employeeRegisteredAddress
      ? this.masterDataRepo.getAmphoeIdByName(employeeRegisteredAddress.district)
      : undefined;

      if(provinceId != undefined){
        this.registeredAddressFields[0].fieldGroup.find(a => a.key === 'district').templateOptions.options =
        this.masterDataRepo.getAmphoeOptionList(provinceId);
      }

      if(amphoeId != undefined){
        this.registeredAddressFields[0].fieldGroup.find(a => a.key === 'subDistrict').templateOptions.options =
        this.masterDataRepo.getTambonOptionList(amphoeId);
      }

    const employeeCurrentAddress = this.model.employeeCurrentAddress;    
    const provinceId2 = employeeCurrentAddress
      ? this.masterDataRepo.getProvinceIdByName(employeeCurrentAddress.province)
      : undefined;
    const amphoeId2 = employeeCurrentAddress
      ? this.masterDataRepo.getAmphoeIdByName(employeeCurrentAddress.district)
      : undefined;

      if(provinceId2 != undefined){
        this.currentAddressFields[0].fieldGroup.find(a => a.key === 'district').templateOptions.options =
        this.masterDataRepo.getAmphoeOptionList(provinceId2);
      }

      if(amphoeId2 != undefined){
        this.currentAddressFields[0].fieldGroup.find(a => a.key === 'subDistrict').templateOptions.options =
        this.masterDataRepo.getTambonOptionList(amphoeId2);
      }
  }

}
