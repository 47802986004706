/* tslint:disable */
type EWagePayType =
  'Daily' |
  'Daily15_1' |
  'Daily15_2' |
  'Daily15_3' |
  'Weekly' |
  'BiWeekly' |
  'Monthly';
module EWagePayType {
  export const DAILY: EWagePayType = 'Daily';
  export const DAILY_15_1: EWagePayType = 'Daily15_1';
  export const DAILY_15_2: EWagePayType = 'Daily15_2';
  export const DAILY_15_3: EWagePayType = 'Daily15_3';
  export const WEEKLY: EWagePayType = 'Weekly';
  export const BI_WEEKLY: EWagePayType = 'BiWeekly';
  export const MONTHLY: EWagePayType = 'Monthly';
  export function values(): EWagePayType[] {
    return [
      DAILY,
      DAILY_15_1,
      DAILY_15_2,
      DAILY_15_3,
      WEEKLY,
      BI_WEEKLY,
      MONTHLY
    ];
  }
}

export { EWagePayType }