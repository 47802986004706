import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { JobGradeService, JobGradeOfSystemService } from 'app/api/services';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { JobGradeModel } from 'app/api/models';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';

interface OptionModel {
  value: number;
  label: string;
}

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'app-modal-job-grades',
  templateUrl: './modal-job-grades.component.html',
  styleUrls: ['./modal-job-grades.component.css'],
})
export class ModalJobGradesComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject();
  form = new FormGroup({});
  model: JobGradeModel = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'code',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'code_jg',
        placeholder: '@blank',
        maxLength: 8,
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        helpMessage: '* เลขมากคือระดับพนักงานที่มีตำแหน่งสูงกว่า'
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'name_jg',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    /*
    {
      key: 'order',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%'
        },
        label: 'ระดับ',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4',
        type: 'number'
      }
    },
    */
    {
      key: 'jobGradeOfSystemId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        // options: this.getJobGradeOfSystemOption(),
        attributes: {
          style: 'width: 100%',
        },
        label: 'central_level',
        labelClass: 'col-4',
        inputClass: 'col-4',
        required: true,
      },
    },
    {
      key: 'description',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'description',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
  ];
  componentMode: ComponentMode;

  constructor(
    private notificationService: AppNotificationServiceService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private jobGradeService: JobGradeService,
    private jobGradeOfSystem: JobGradeOfSystemService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const jobGradeId = this.getJobGradeId();

    if (jobGradeId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'jobGradeOfSystemId').templateOptions.options =
      await this.getJobGradeOfSystemOption().toPromise();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const companyId = this.config.data?.companyId;
      if (!companyId) {
        this.close(); // FIXME: handle this
      }
      this.model = { companyId };

      return;
    }

    this.model = await this.jobGradeService.GetJobGradeId(this.getJobGradeId()).toPromise();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    console.log(this.model);

    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createJobGrade()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateJobGrade()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createJobGrade() {
    return this.jobGradeService.CreateJobGrade({
      companyId: this.model.companyId,
      code: this.model.code,
      name: this.model.name,
      order: this.model.order,
      jobGradeOfSystemId: this.model.jobGradeOfSystemId,
      description: this.model.description,
    });
  }

  private updateJobGrade() {
    return this.jobGradeService.UpdateJobGrade({
      jobGradeId: this.model.jobGradeId,
      body: {
        jobGradeId: this.model.jobGradeId,
        companyId: this.model.companyId,
        code: this.model.code,
        name: this.model.name,
        order: this.model.order,
        jobGradeOfSystemId: this.model.jobGradeOfSystemId,
        description: this.model.description,
        createdDate: this.model.createdDate,
        createdBy: this.model.createdBy,
        status: this.model.status,
      },
    });
  }

  private getJobGradeOfSystemOption(): Observable<OptionModel[]> {
    return this.jobGradeOfSystem.GetJobGradeOfSystemsList({}).pipe(
      map(pageResult => {
        return pageResult.results.map(job => {
          return {
            label: job.order + ' : ' + job.description,
            value: job.jobGradeOfSystemId,
          };
        });
      }),
    );
  }

  getJobGradeId() {
    return this.config.data?.id;
  }
}
