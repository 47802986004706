import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { MasterDataRepo } from 'app/repo/master-data.repo';

import { BranchService, CompanyService, PositionService, UnitService } from 'app/api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { ModalPositionComponent } from '../../../../../../modals/core/modal-position/modal-position.component';
import { map, filter, switchMap, tap, distinctUntilChanged, startWith } from 'rxjs/operators';
import { toOptionList } from 'utils/formly-utils';
import { EWagePayType, EPayType } from 'app/api/models';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { LayoutDefaultComponent } from '../../../../../../layout/layout-default/layout-default.component';

export interface EmployeeEmploymentCreateModel {
  employeeCode: string;
  employeeTypeId?: string;
  employeeType2Id?: string;
  payDaily: number;
  payMonthly: number;
  companyId?: number;
  unitId?: string;
  branchId?: number;
  positionId?: number;
  workPermitNumber: string;
  employeeDate: string;
  probationDay: number;
  passedProbationDate: string;
  internalPhoneNumber: string;
  email: string;
  salary: number;
  isHaveSocialSecurity?: boolean;
  isDeductedSocialSecurity?: boolean;
  salaryPaymentMethodId?: string;
  bankName?: string;
  branchName: string;
  bankAccountNumber: string;
  hospitalId: string;
  wagePayType?: EWagePayType;
  payType?: EPayType;
  otherValue: {
    name?: string;
    value: number;
  }[];
}

interface ModelFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeEmploymentCreateModel;
  fieldGroup?: ModelFormlyFieldConfig[];
}

@Component({
  selector: 'app-employee-create-form-employment-information',
  templateUrl: './employee-create-form-employment-information.component.html',
  styleUrls: ['./employee-create-form-employment-information.component.css'],
})
export class EmployeeCreateFormEmploymentInformationComponent implements OnInit {
  companyIdChange = new ReplaySubject(1);
  unitIdChange = new ReplaySubject(1);
  currentCompanyId: number;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  isShowErr = true;
  ref: DynamicDialogRef;
  form = new FormGroup({});
  model: EmployeeEmploymentCreateModel;
  options: FormlyFormOptions = {};
  fields: ModelFormlyFieldConfig[] = [
    // {
    //   template: `<h1>ข้อมูลการจ้างงาน</h1>`,
    // },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          type: 'primeng-input',
          key: 'employeeCode',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            label: 'employee_id',
            placeholder: '(ถ้าไม่ใส่ระบบจะสร้างรหัสพนักงานให้โดยอัตโนมัติ)',
            labelClass: 'col-4',
            inputClass: 'col-6',
            tooltip: 'ห้ามกรอกรหัสพนักงานซํ้ากัน',
            disabled: false,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'filter-dropdown',
          key: 'employeeTypeId',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            placeholder: '@blank',
            label: 'employee_manpower',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-6',
            options: this.masterDataRepo.getEmployeeTypeOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },

        {
          type: 'filter-dropdown',
          key: 'payType',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'type_payment',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-6',
            options: this.masterDataRepo.getPayTypeOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'filter-dropdown',
          key: 'companyId',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'affiliated_company',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-6',
            change: (field, $event) => {
              this.form.controls.unitId.reset();
              this.form.controls.positionId.reset();
              this.form.controls.branchId.reset();
              let value = field.formControl.value ?? 0;
              this.companyService.GetCompanyById(value).subscribe(s => {
                this.model.probationDay = s.probationDays;
                this.calcuLatePassedProbationDate();
              });
              this.companyIdChange.next(value);
            },
            options: this.companyService
              .GetCompaniesList({})
              .pipe(map(pageResult => pageResult.results.map(c => toOptionList(c, 'companyId', 'name')))),
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          type: 'add-position',
          className: 'col-6',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': '!model.companyId',
          },
          templateOptions: {
            translate: true,
            label: 'add_position',
            labelClass: 'col-4',
            inputClass: 'col-6',
            placeholder: '@blank',
            companyChange: this.companyIdChange,
          }
        },
        {
          type: 'select-unit',
          key: 'unitId',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': '!model.companyId',
          },
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'agency',
            labelClass: 'col-4',
            inputClass: 'col-6',
            companyChange: this.companyIdChange,
            isWaitCompanyId: true,
            change: (_: FormlyFieldConfig, { value }) => {
              this.form.controls.positionId.reset();
              this.unitIdChange.next(value);
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: "branchId",
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'branch',
            translate: true,
            placeholder: '@blank',
            labelClass: 'col-4',
            inputClass: 'col-6',
            options: [],
          },
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              field.templateOptions.options = field.form
                .get('companyId')
                .valueChanges.pipe(
                  startWith(this.model.companyId),
                  switchMap(companyId => this.getBranchList(companyId))
                );
            }
          }
        },
        {
          key: 'positionId',
          type: 'select-position-2',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': '!model.companyId || !model.unitId',
          },
          templateOptions: {
            translate: true,
            label: 'position',
            placeholder: '@blank',
            labelClass: 'col-4',
            inputClass: 'col-6',
            isEmptyEmployee: true,
            companyIdChange: this.companyIdChange,
            unitIdChange: this.unitIdChange,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'primeng-input',
          key: 'workPermitNumber',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'work_permit_number',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'calendar',
          key: 'employeeDate',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'start_date_s',
            placeholder: '@blank',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          hooks: {
            afterViewInit: field => {
              field.formControl.valueChanges.pipe().subscribe(date => {
                const passedProbationDate = moment(date, 'YYYY-MM-DD')
                  .utc(true)
                  .add(this.model.probationDay, 'days')
                  .toISOString();

                this.model = {
                  ...this.model,
                  passedProbationDate,
                };
              });
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'primeng-input',
          key: 'probationDay',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'probation_period',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-6',
            type: 'number',
            min: 0,
            change: (field: FormlyFieldConfig, event: any) => {
              console.log("Day :" + field.formControl.value);
              this.calcuLatePassedProbationDate();
              console.log(JSON.stringify(this.model, null, 2));
            }
          },
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              field.formControl.valueChanges.pipe(tap(value => {
                const passedProbationDate = moment(this.model.employeeDate, 'YYYY-MM-DD')
                  .utc(true)
                  .add(value, 'days')
                  .toISOString();
                this.model = {
                  ...this.model,
                  passedProbationDate,
                };
              })).subscribe();
            },
            validation: {
              show: this.isShowErr,
            },
          }
        },
        {
          type: 'calendar',
          key: 'passedProbationDate',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            placeholder: moment().utc(true).add(120, 'days').toISOString(),
            label: 'probation_date',
            labelClass: 'col-4',
            inputClass: 'col-6',
            type: 'string',
            disabled: true,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'primeng-input',
          key: 'internalPhoneNumber',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'internal_phone_number',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'primeng-input',
          key: 'email',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'email',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ]
    },

    {
      template: `<hr style="margin-top: 20px; margin-bottom: 20px;"><h1>ข้อมูลเงินเดือน/ค่าจ้าง</h1>`,
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          type: 'primeng-input',
          key: 'salary',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            label: 'salary',
            placeholder: '@blank',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-6',
            type: 'number',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'isHaveSocialSecurity',
          type: 'radio',
          className: 'col-6',
          wrappers: ['inline-label'],
          defaultValue: false,
          templateOptions: {
            translate: true,
            label: 'social_security_rights',
            placeholder: '@blank',
            required: true,
            attributes: {
              style: 'width: 100%;',
            },
            options: [
              { value: true, label: 'ขึ้นสิทธิ์' },
              { value: false, label: 'ไม่ขึ้นสิทธิ์' },
            ],
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'isDeductedSocialSecurity',
          type: 'radio',
          className: 'col-12',
          wrappers: ['inline-label'],
          defaultValue: true,
          templateOptions: {
            translate: true,
            label: 'social_security_rights_deduction',
            placeholder: '@blank',
            attributes: {
              style: 'width: 100%;',
            },
            options: [
              { value: true, label: 'หักประกันสังคม' },
              { value: false, label: 'ไม่หักประกันสังคม' },
            ],
            labelClass: 'col-8',
            inputClass: 'col-4',
          },
          hideExpression: '!model.isHaveSocialSecurity',
          expressionProperties: {
            'model.isDeductedSocialSecurity': () => {
              if (!this.model.isHaveSocialSecurity) {
                return false;
              } else {
                return true;
              }
            },
          },
        },
        {
          type: 'filter-dropdown',
          key: 'salaryPaymentMethodId',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            placeholder: '@blank',
            label: 'salary_channels',
            labelClass: 'col-4',
            inputClass: 'col-6',
            options: this.masterDataRepo.getPayChannelsOptionList(),
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'filter-dropdown',
          key: 'bankName',
          wrappers: ['inline-label'],
          className: 'col-6',

          templateOptions: {
            translate: true,
            required: this.currentUser == null,
            attributes: {
              style: 'width: 100%;',
            },
            placeholder: '@blank',
            label: 'not_eligible',
            labelClass: 'col-4',
            inputClass: 'col-6',
            options: this.masterDataRepo.getBankOptionList(),
          },
          expressionProperties: {
            'templateOptions.required': `model.salaryPaymentMethodId === 'BT'`,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'primeng-input',
          key: 'branchName',
          wrappers: ['inline-label'],
          className: 'col-6',
          name: 'organization',

          templateOptions: {
            translate: true,
            required: this.currentUser == null,
            attributes: {
              style: 'width: 100%;',
              autocomplete: 'on',
            },
            placeholder: '@blank',
            label: 'branch',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          expressionProperties: {
            'templateOptions.required': `model.salaryPaymentMethodId === 'BT'`,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'primeng-input',
          key: 'bankAccountNumber',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            required: this.currentUser == null,
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            maxLength: 12,
            label: 'acount_number',
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
          expressionProperties: {
            'templateOptions.required': `model.salaryPaymentMethodId === 'BT'`,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'filter-dropdown',
          key: 'hospitalId',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            required: this.currentUser == null,
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'social_security_hospital',
            labelClass: 'col-4',
            inputClass: 'col-6',
            options: this.masterDataRepo.getHospitalOptionList(),
          },
          expressionProperties: {
            'templateOptions.required': 'model.isHaveSocialSecurity',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'filter-dropdown',
          key: 'wagePayType',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'pay_cycle',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-6',
            options: this.masterDataRepo.getWagePayTypesOptionList(),
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          type: 'primeng-input',
          key: 'payDaily',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            required: false,
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            placeholder: '@blank',
            label: 'daily_pay',
            labelClass: 'col-4',
            inputClass: 'col-6',
            type: 'number',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          className: 'col-12',
          fieldGroup: [
            {
              key: 'otherValue',
              type: 'repeat-other-value-salary',
              templateOptions: {
                label: 'ผู้บังคับบัญชา',
                addText: '+ เพิ่ม',
                isDisplayAddBtn: true,
              },
              fieldArray: {
                fieldGroupClassName: 'grid',
                fieldGroup: [
                  {
                    type: 'filter-dropdown',
                    key: 'name',
                    wrappers: ['inline-label'],
                    className: 'col-8',
                    validation: { show: true },
                    templateOptions: {
                      attributes: {
                        style: 'width: 100%',
                      },
                      placeholder: 'ค่าอื่นๆ',
                      label: 'ค่าอื่นๆ:',
                      required: true,
                      labelClass: 'col-6',
                      inputClass: 'col',
                      options: this.masterDataRepo.getOtherPayOptionList(),
                    },
                  },
                  {
                    type: 'primeng-input',
                    key: 'value',
                    className: 'col-4',
                    validation: { show: true },
                    templateOptions: {
                      attributes: {
                        style: 'width: 100%',
                      },
                      required: true,
                      type: 'number',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ];

  constructor(
    private notificationService: AppNotificationServiceService,
    private layout: LayoutDefaultComponent,
    private dialogService: DialogService,
    private masterDataRepo: MasterDataRepo,
    private unitService: UnitService,
    private companyService: CompanyService,
    private positionService: PositionService,
    private branchService: BranchService,
  ) { }

  ngOnInit(): void {
    this.model = {
      bankAccountNumber: '',
      branchName: '',
      email: '',
      employeeCode: '',
      employeeDate: moment().utc(true).toISOString(),
      hospitalId: '',
      internalPhoneNumber: '',
      otherValue: [],
      payDaily: 0,
      payMonthly: 0,
      probationDay: 120,
      passedProbationDate: moment().utc(true).add(120, 'days').toISOString(),
      salary: 0,
      workPermitNumber: '',
    };
  }

  showCreateDialog() {

    if (this.model.companyId == undefined) {
      this.notificationService.error('กรุณาระบุ สังกัดบริษัท ก่อน', '');
      return;
    }

    this.showDialog({ companyId: this.model.companyId }, '+ เพิ่มตำแหน่งงาน');

    this.ref.onClose.subscribe(data => {
      if (data) {
        //   this.model.unitId = data.unitId;
        //   this.form.get("unitId").valueChanges.subscribe(x => {
        //     this.form.controls.unitId.setValue(data.unitId);
        //  })
        //   this.model.positionId = data.positionId;
        //   this.form.controls.positionId.setValue(data.positionId);
      }
    });
  }

  calcuLatePassedProbationDate() {
    const passedProbationDate = moment(this.model.employeeDate, 'YYYY-MM-DD')
      .utc(true)
      .add(this.model.probationDay, 'days')
      .toISOString();
    this.model.passedProbationDate = passedProbationDate;
    this.model = { ...this.model };
  }

  showDialog(data: any, header: string) {
    this.ref = this.dialogService.open(ModalPositionComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }

  getBranchList(companyId: number) {
    return this.branchService.GetBranchList({ CompanyId: companyId, Page: 1, ItemPerPage: 1000 }).pipe(
      map(a => a.results.map(b =>
        ({ label: `${b.branchCode} ${b.name}`, value: b.branchId })
      )))
  }

}
