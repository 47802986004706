/* tslint:disable */
type EPayElementType =
  'NA' |
  'Income' |
  'Deduct';
module EPayElementType {
  export const NA: EPayElementType = 'NA';
  export const INCOME: EPayElementType = 'Income';
  export const DEDUCT: EPayElementType = 'Deduct';
  export function values(): EPayElementType[] {
    return [
      NA,
      INCOME,
      DEDUCT
    ];
  }
}

export { EPayElementType }