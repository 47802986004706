<div class="mt-2">
  <h1>
    {{ to.bigTitle }} &nbsp;
    <p-button
      *ngIf="!to.disabled"
      class="p-button-secondary p-button-sm tw-inline-block"
      type="button"
      (click)="addRow()"
      label="{{ to.addText }}"
    ></p-button>
  </h1>
</div>
<div *ngFor="let field of field.fieldGroup; let i = index" class="mr-1">
  <h3>
    {{ to.littleTitle }}{{ i + 1 }}: &nbsp;
    <p-button
      class="p-button-danger p-button-sm tw-inline-block"
      type="button"
      icon="fa fa-trash"
      iconPos="left"
      (click)="remove(i)"
      [label]="to.deleteTitle"
    ></p-button>
  </h3>

  <formly-field class="col" [field]="field"></formly-field>
</div>
