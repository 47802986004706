/* tslint:disable */
type EBPlusSyncStatus =
  'Success' |
  'Error';
module EBPlusSyncStatus {
  export const SUCCESS: EBPlusSyncStatus = 'Success';
  export const ERROR: EBPlusSyncStatus = 'Error';
  export function values(): EBPlusSyncStatus[] {
    return [
      SUCCESS,
      ERROR
    ];
  }
}

export { EBPlusSyncStatus }