<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div style="margin: 3% 10% 10% 10%">
        <div #blockTarget class="p-fluid">
          <div class="grid">
            <h1>iOS Redeemed Code</h1>

            <div class="col-12" style="text-align: center">
              <div>
                <img
                  src="../../../../assets/layout/images/ultraconnect_icon.png"
                  alt="Ultraconnect"
                  width="200"
                  height="200"
                />
              </div>
              <div>
                <h3>ดาวน์โหลด Ultraconnect เพื่อติดตั้งบนมือถือ iOS ด้วยการคลิก link แล้วใส่ redeemed code ลงไป</h3>
                <br /><br />
                <h3>
                  <b>Link:</b
                  ><a href="{{ iosRedeemedCode.codeRedemptionLink }}"> {{ iosRedeemedCode.codeRedemptionLink }}</a>
                </h3>
                <div class="grid justify-content-center">
                  <h3>
                    <b>Redeemed Code:</b>
                  </h3>
                  <div class="col-6 md:col-2">
                    <input
                      type="text"
                      pInputText
                      [value]="iosRedeemedCode.code"
                      id="redeemedCode"
                      style="text-align: center"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid justify-content-center">
            <div class="col-4 lg:col-2" style="margin-left: 2%">
              <button
                type="button"
                style="height: 120%; background-color: #acada8"
                pButton
                label="รับ redeemed code อีกรอบ"
                (click)="onGetLinkAndRedeemedCode()"
              ></button>
            </div>
            <div class="col-4 lg:col-2">
              <button
                type="button"
                style="height: 120%"
                pButton
                label="คัดลอก redeemed code"
                (click)="onCopyRedeemedCode()"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
