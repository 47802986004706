<div class="grid">
  <div class="col-12">
    <h1 class="d-in-block">{{'employment_information' | translate}}</h1>&nbsp;&nbsp;
    <div class="d-in-block">
    </div>
  </div>
</div>

<form [formGroup]="form">
  <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
</form>