/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeTASettingsModel } from '../models/employee-tasettings-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeTASettingsService extends __BaseService {
  static readonly GetEmployeeTASettingsPath = '/api/EmployeeTASettings/Employee/{employeeId}/ta-settings';
  static readonly UpdateEmployeeTASettingsPath = '/api/EmployeeTASettings/Employee/{employeeId}/ta-settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeTASettingsResponse(employeeId: number): __Observable<__StrictHttpResponse<EmployeeTASettingsModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeTASettings/Employee/${encodeURIComponent(String(employeeId))}/ta-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeTASettingsModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeTASettings(employeeId: number): __Observable<EmployeeTASettingsModel> {
    return this.GetEmployeeTASettingsResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeTASettingsModel)
    );
  }

  /**
   * @param params The `EmployeeTASettingsService.UpdateEmployeeTASettingsParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeTASettingsResponse(params: EmployeeTASettingsService.UpdateEmployeeTASettingsParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeTASettings/Employee/${encodeURIComponent(String(params.employeeId))}/ta-settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeTASettingsService.UpdateEmployeeTASettingsParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeTASettings(params: EmployeeTASettingsService.UpdateEmployeeTASettingsParams): __Observable<string> {
    return this.UpdateEmployeeTASettingsResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeTASettingsService {

  /**
   * Parameters for UpdateEmployeeTASettings
   */
  export interface UpdateEmployeeTASettingsParams {
    employeeId: number;
    body?: EmployeeTASettingsModel;
  }
}

export { EmployeeTASettingsService }
