<div *ngIf="loading" class="grid justify-content-center">
  <div class="col-fixed">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div *ngIf="!loading && !employeeList.length" class="grid justify-content-center">
  <div class="col-fixed">
    <h1>ไม่พบข้อมูล</h1>
  </div>
</div>
<div *ngIf="!loading" class="grid">
  <ng-container *ngFor="let employee of employeeList">
    <div class="col-12 md:col-3">
      <p-card>
        <div class="grid header">
          <div class="col-12">
            <img [attr.src]="getLinkProfileImage(employee.id)" class="tw-mx-auto"
              style="width:100px;min-height: 100px;position: center center;" />
          </div>
          <div style="position: absolute; right: 0">
            <div class="grid justify-content-end">
              <div class="col-fixed">
                <button pButton type="button" icon="far fa-eye" [routerLink]="[employee.id]">
                </button>
              </div>
              <div class="col-fixed">
                <button pButton type="button" icon="pi pi-ellipsis-v" (click)="onEllipsisIconClick($event, menu, employee)">
                </button>
                <!-- <p-menu #menu [model]="employeeMenuItems" [popup]="true"></p-menu> -->
              </div>
            </div>
          </div>
          <div class="col-12 employee-name">
            <b>{{ employee.fullnameTh }}</b>
          </div>
          <div class="col-12 employee-code">
            <span>{{ employee.employeeCode }}</span>
          </div>
        </div>

        <div class="grid">
          <div class="col-12"></div>
          <div class="col-12 divider"></div>

          <div class="col-1">
            <i class="fas fa-building"></i>
          </div>
          <div class="col-11">
            <span>{{ employee.companyName }}</span>
          </div>

          <div class="col-1">
            <i class="fas fa-suitcase"></i>
          </div>
          <div class="col-11">
            <span>{{ employee.unitName }}</span>
          </div>

          <div class="col-1">
            <i class="fas fa-folder"></i>
          </div>
          <div class="col-11">
            <span>{{ employee.positionName }}</span>
          </div>

          <div class="col-1">
            <i class="fas fa-map-marker-alt"></i>
          </div>
          <div class="col-11">
            <span>{{ employee.locationName }}</span>
          </div>

          <div class="col-1">
            <i class="fas fa-envelope"></i>
          </div>
          <div class="col-11">
            <span>{{ employee.c_Email }}</span>
          </div>

          <div class="col-1">
            <i class="fas fa-phone-alt"></i>
          </div>
          <div class="col-11">
            <span>{{ employee.phone }}</span>
          </div>

          <div class="col-1">
            <i class="fas fa-mobile"></i>
          </div>
          <div class="col-11">
            <span>{{ employee.mphone }}</span>
          </div>
        </div>
      </p-card>
    </div>
  </ng-container>
</div>
<div class="grid justify-content-between align-center">
  <div class="col-fixed">
    <label>
      {{ paginationParams.firstRowIdx + 1 }}-{{
        employeeList ? paginationParams.firstRowIdx + employeeList?.length : 0
      }}
      Results
    </label>
  </div>
  <div class="col-fixed">
    <label>Show :</label>
  </div>
  <div class="col-fixed">
    <p-dropdown [options]="pageSelectList" [(ngModel)]="paginationParams.rowPerPage"
      (onChange)="onPageChange($event.value)">
    </p-dropdown>
  </div>
  <div class="col"></div>
  <div class="col-fixed">
    <label>{{ paginationParams.page }}/{{ paginationParams.totalPage }}</label>
  </div>
  <div class="col-fixed">
    <button type="button" pButton class="p-button-secondary" icon="fas fa-chevron-left" (click)="onPrePageClick()"
      [disabled]="paginationParams.firstRowIdx === 0"></button>
  </div>
  <div class="col-fixed">
    <button type="button" pButton icon="fas fa-chevron-right" class="p-button-secondary" (click)="onNextPageClick()"
      [disabled]="paginationParams.totalRecords === paginationParams.firstRowIdx + employeeList?.length"></button>
  </div>
</div>
<p-menu #menu [model]="employeeMenuItems" [popup]="true"></p-menu>
