/* tslint:disable */
type ERequestType =
  'ChagePersonnelInfo' |
  'WarningLetter' |
  'OT' |
  'OTForOther' |
  'Leave' |
  'TimeCertify' |
  'ProbationEvaluation' |
  'Promotion' |
  'NewEmployee';
module ERequestType {
  export const CHAGE_PERSONNEL_INFO: ERequestType = 'ChagePersonnelInfo';
  export const WARNING_LETTER: ERequestType = 'WarningLetter';
  export const OT: ERequestType = 'OT';
  export const OTFOR_OTHER: ERequestType = 'OTForOther';
  export const LEAVE: ERequestType = 'Leave';
  export const TIME_CERTIFY: ERequestType = 'TimeCertify';
  export const PROBATION_EVALUATION: ERequestType = 'ProbationEvaluation';
  export const PROMOTION: ERequestType = 'Promotion';
  export const NEW_EMPLOYEE: ERequestType = 'NewEmployee';
  export function values(): ERequestType[] {
    return [
      CHAGE_PERSONNEL_INFO,
      WARNING_LETTER,
      OT,
      OTFOR_OTHER,
      LEAVE,
      TIME_CERTIFY,
      PROBATION_EVALUATION,
      PROMOTION,
      NEW_EMPLOYEE
    ];
  }
}

export { ERequestType }