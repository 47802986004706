<p-treeTable
  #personnelAreaTable
  [value]="personnelAreas"
  [autoLayout]="true"
  [paginator]="true"
  [rows]="10"
  [columns]="cols"
  class="personnelArea-select-table"
  [globalFilterFields]="['name', 'levelName']"
  dataKey="id"
>
  <ng-template pTemplate="caption">
    <div style="text-align: left">
      <i class="pi pi-search" style="margin: 4px 4px 0 0"></i>
      <input
        type="text"
        pInputText
        size="50"
        placeholder="คำค้นหา"
        (input)="personnelAreaTable.filterGlobal($any($event.target).value, 'contains')"
        style="width: auto"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{ col.header }}
      </th>
      <th style="width: 50px"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
      <td *ngFor="let col of cols; let i = index">
        <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
        {{ rowData[col.field] }}
      </td>
      <td>
        <button pButton type="button" label="เลือก" (click)="select($event, rowNode.node)"></button>
      </td>
    </tr>
  </ng-template>
</p-treeTable>
