import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeCertificateModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DocumentService, MasterDataService } from 'app/api/services';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { EmployeeCertificateService } from 'app/api/services/employee-certificate.service';


enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface SelectFileParam {
  currentFiles: File[];
}

interface EmployeeCertificateFormModel extends EmployeeCertificateModel {
  isNotSpecifyResignedDate?: Boolean;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeCertificateFormModel;
}

@Component({
  selector: 'app-modal-employee-certificate',
  templateUrl: './modal-employee-certificate.component.html',
  styleUrls: ['./modal-employee-certificate.component.css'],
})
export class ModalEmployeeCertificateComponent implements OnInit {
  model: EmployeeCertificateFormModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'certificateTypeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,

        label: 'type',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        // options: this.masterDataService.GetCertificateTypes().pipe(
        //   map(certificates => {
        //     return certificates.map(certificate => {
        //       return {
        //         value: certificate.certificateTypeId,
        //         label: certificate.name,
        //       };
        //     });
        //   }),
        // ),
      },
    },
    {
      key: 'certificateNo',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'no_',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 64,
      },
    },
    {
      key: 'certificateName',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'name',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 256,
      },
    },
    {
      key: 'institute',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'educational',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 256,
      },
    },
    {
      key: 'documentId',
      type: 'upload-file',
      templateOptions: {
        translate: true,
        required: true,
        label: 'attach_file',
        chooseLabel: 'อัพโหลด',
        previewWidth: 150,
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
        onSelect: (selectFileParam: SelectFileParam) => {
          this.documentService
            .CreateDocument({
              description: 'Employee Certificate',
              file: selectFileParam.currentFiles[0],
            })
            .subscribe(result => {
              this.model.documentId = result.documentId;
            });
        },
        onRemove: (_, model: EmployeeCertificateModel, oldDocumentId: number) => {
          model.documentId = oldDocumentId;
        },
      },
    },
    {
      key: 'issueDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'date_of_issue',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-3'
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'start_date_u',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-3'
      },
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'expireDate',
          type: 'calendar',
          wrappers: ['inline-label'],
          className: 'col-7',
          templateOptions: {
            translate: true,
            required: true,
            label: 'expiration_date',
            placeholder: '@blank',
            labelClass: 'col-7',
            inputClass: 'col-5'
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.isNotSpecifyResignedDate',
          },
        },
        {
          key: 'isNotSpecifyResignedDate',
          type: 'checkbox',
          className: 'col-fixed',
          wrappers: ['inline-label'],
          defaultValue: true,
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'no_date_specified',
            translate: true,
            placeholder: '@blank',
            labelClass: 'col',
          },
        },
      ],
    },
    {
      key: 'notifyBeforeExpireDays',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      defaultValue: 0,
      templateOptions: {
        translate: true,
        required: true,
        label: 'number_days_expired',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-3',
        type: 'number',
        min: 0,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isNotSpecifyResignedDate',
      },
    },
    {
      key: 'enableNotifyBeforeExpire',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        translate: true,
        required: false,
        placeholder: '@blank',
        label: 'notify_before_expiration',
        labelClass: 'col-4',
        inputClass: 'col-3',
        trueMessage: 'แจ้งเตือน',
        falseMessage: 'ไม่แจ้งเตือน',
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isNotSpecifyResignedDate',
      },
    },
  ];
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeCertificateService: EmployeeCertificateService,
    private masterDataService: MasterDataService,
    private documentService: DocumentService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  initComponentMode() {
    const employeeCertificateId = this.getEmployeeCertificateId();

    if (employeeCertificateId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'certificateTypeId').templateOptions.options = await this.masterDataService
      .GetCertificateTypes()
      .pipe(
        map(certificates => {
          return certificates.map(certificate => {
            return {
              value: certificate.certificateTypeId,
              label: certificate.name,
            };
          });
        }),
      )
      .toPromise();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    const res = await this.employeeCertificateService
      .GetEmployeeCertificateById(this.getEmployeeCertificateId())
      .toPromise();

    const isNotSpecifyResignedDate = new Date(res.expireDate).getFullYear() >= 9999 || null;

    this.model = {
      ...res,
      issueDate: (res.issueDate),
      effectiveDate: (res.effectiveDate),
      expireDate: (res.expireDate),
      isNotSpecifyResignedDate: isNotSpecifyResignedDate,
    };
  }

  submit() {
    if (this.model.isNotSpecifyResignedDate) {
      this.model.expireDate = '9999-12-31';
      this.model.notifyBeforeExpireDays = 0;
      this.model.enableNotifyBeforeExpire = false;
    }
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.employeeCertificateService
          .CreateEmployeeCertificate(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.employeeCertificateService
          .UpdateEmployeeCertificate({
            employeeCertificateId: this.model.employeeCertificateId,
            body: this.model,
          })
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getEmployeeCertificateId() {
    return this.config.data?.id;
  }
}
