import { Component, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EmployeeTASettingsModel, EmployeeTaxInfoModel } from '../../../api/models';
import { FormGroup } from '@angular/forms';
import { CustomFormlyFieldConfig } from '../../../utils/formly-utils';
import { Router } from '@angular/router';
import { EmployeeTASettingsService } from '../../../api/services';
import { AppNotificationServiceService } from '../../../shared/app-notification-service.service';

@Component({
  selector: 'app-employee-ta-settings',
  templateUrl: './employee-ta-settings.component.html',
  styleUrls: ['./employee-ta-settings.component.scss']
})
export class EmployeeTaSettingsComponent implements OnInit {
  isEditable = false;
  employeeId = -1;
  model: EmployeeTASettingsModel = null;
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig<keyof EmployeeTASettingsModel>[] = [
    {
      key: 'isCalculateTimeAttendance',
      type: 'switch-input',
      className: 'col-12',
      templateOptions: {
        label: 'คำนวณสถานะ Time Attendance',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      }
    },
    {
      key: 'canRequestOT',
      type: 'switch-input',
      className: 'col-12',
      templateOptions: {
        label: 'สามารถขอ OT ได้',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      }
    },

  ];

  constructor(
    private router: Router,
    private taSettingsService : EmployeeTASettingsService,
    private notificationService: AppNotificationServiceService,
  ) { }

  async ngOnInit() {
    this.isEditable = this.router.url.includes('admin', 1);
    const urls = this.router.url.split('/');
    this.employeeId = +urls[urls.length - 2];
    this.model = await this.taSettingsService.GetEmployeeTASettings(this.employeeId).toPromise();
  }
  async save() {
    await this.taSettingsService.UpdateEmployeeTASettings({
      employeeId: this.employeeId,
      body: this.model
    }).toPromise();

    this.notificationService.saveCompleted();
  }
}
