import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { MasterDataRepo } from 'app/repo/master-data.repo';

export interface EmployeePastEmploymentCreateModel {
  employeePastEmployments: {
    employerName?: string;
    businessTypeId?: string;
    phone?: string;
    address?: string;
    jobDescription?: string;
    entryPositionName?: string;
    exitPositionName?: string;
    entrySalary?: number;
    exitSalary?: number;
    employeeDate?: string;
    terminateDate?: string;
    exitReason?: string;
  }[];
}

@Component({
  selector: 'app-employee-create-form-experience',
  templateUrl: './employee-create-form-experience.component.html',
  styleUrls: ['./employee-create-form-experience.component.css'],
})
export class EmployeeCreateFormExperienceComponent implements OnInit {
  @Input('defaultModel')
  set defaultModel(val) {
    if (val) {
      Object.assign(this.model, val);
    }
  }

  isShowErr = true;

  form = new FormGroup({});
  model: EmployeePastEmploymentCreateModel = {
    employeePastEmployments: [],
  };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'employeePastEmployments',
      type: 'repeat',
      templateOptions: {
        addText: '+ เพิ่มประสบการณ์การทำงาน',
        bigTitle: 'ประสบการณ์ทำงาน ',
        littleTitle: 'ประสบการณ์ทำงาน ',
        deleteTitle: 'ลบข้อมูลประสบการณ์ทำงาน',
      },
      fieldArray: {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'primeng-input',
            key: 'employerName',
            wrappers: ['inline-label'],
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'company_name',
              required: true,
              labelClass: 'col-2',
              inputClass: 'col-9',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'entryPositionName',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'first_position',
              labelClass: 'col-4',
              inputClass: 'col-6',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'exitPositionName',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'last_position',
              labelClass: 'col-4',
              inputClass: 'col-6',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'calendar',
            key: 'employeeDate',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'start_date_s',
              placeholder: '@blank',
              labelClass: 'col-4',
              inputClass: 'col-6',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'calendar',
            key: 'terminateDate',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              label: 'work_end_date',
              placeholder: '@blank',
              labelClass: 'col-4',
              inputClass: 'col-6',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'entrySalary',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'first_deposit',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'exitSalary',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'last_money',
              labelClass: 'col-4',
              inputClass: 'col-6',
              type: 'number',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'textarea',
            key: 'jobDescription',
            wrappers: ['inline-label'],
            className: 'col-12',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%;',
              },
              placeholder: 'ไม่เกิน 16,000 ตัวอักษร',
              label: 'work_done',
              labelClass: 'col-2',
              inputClass: 'col-9',
              maxLength: 16000,
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'address',
            wrappers: ['inline-label'],
            className: 'col-12',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'company_location',
              labelClass: 'col-2',
              inputClass: 'col-9',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'filter-dropdown',
            key: 'businessTypeId',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%;',
              },
              placeholder: '@blank',
              label: 'type_business',
              labelClass: 'col-4',
              inputClass: 'col-6',
              options: this.masterDataRepo.getBusinessTypeOptionList(),
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'phone',
            wrappers: ['inline-label'],
            className: 'col-6',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'phone',
              labelClass: 'col-4',
              inputClass: 'col-6',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'exitReason',
            wrappers: ['inline-label'],
            className: 'col-12',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'reason_leaving_work',
              labelClass: 'col-2',
              inputClass: 'col-9',
            },
            validation: {
              show: this.isShowErr,
            },
          },
        ],
      },
    },
  ];

  constructor(private masterDataRepo: MasterDataRepo) {}

  ngOnInit(): void {}

  submit() {}
}
