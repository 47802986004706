import { Injectable, Injector, Inject } from '@angular/core';
import { map, take, catchError, finalize, flatMap, tap } from 'rxjs/operators';
import { throwError, observable, of, Observable, empty, EMPTY } from 'rxjs';
import { SystemService, TokenService, UserService } from '../api/services';
import { Authenticate_Param, UserData, UserModel } from '../api/models';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ResetPasswordModel } from '../api/models/reset-password-model';
import { Permission } from './app-menu.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private refreshAccessTokenInProgress = false;
  private lastCheckRefreshTokenTime: Date = null;

  constructor(
    private injector: Injector,
    private tokenService: TokenService,
    private systemService: SystemService,
    private userService: UserService,
    private cookieService: CookieService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
  ) {}

  bootstrap() {
    // เช็คและโหลดข้อมูลจาก local storage
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    if (permissions) {
      this.permissionsService.loadPermissions(permissions);
    }
  }

  loginWithPassword(loginType: string, username: string, password: string): Observable<Authenticate_Param> {
    const input: Authenticate_Param = {
      type: loginType,
      username,
      password,
    };

    this.saveLoginTypeToLocal(loginType);

    return this.tokenService.Login(input).pipe(
      tap(userData => {
        if (userData && userData.token) {
          this.cookieService.set('clientId', userData.clientId, 365);
          localStorage.setItem('currentUser', JSON.stringify(userData));
        }
      }),
      flatMap(userData => {
        // update user permission
        if (userData && userData.token) {
          return this.userService.GetCurrentUserPermissions(loginType).pipe(
            map(permissions => {
              this.permissionsService.loadPermissions(permissions);
              localStorage.setItem('permissions', JSON.stringify(permissions));

              return userData;
            }),
          );
        }

        return of(userData);
      }),
      flatMap(userData => {
        if (userData && userData.token) {
          return this.userService.GetCurrentUser().pipe(
            map(user => {
              this.saveUserInfoToStorage(user);

              return userData;
            }),
          );
        }

        return of(userData);
      }),
      // map(userData => {
      //   if (userData && userData.token) {
      //     // this.cookieService.set('clientId', userData.clientId, 365);
      //     // localStorage.setItem('currentUser', JSON.stringify(userData));

      //     this.userService.GetCurrentUserPermissions().subscribe(permissions => {
      //       // update user permission
      //       this.permissionsService.loadPermissions(permissions);
      //       localStorage.setItem('permissions', JSON.stringify(permissions));
      //     });
      //     this.userService.GetCurrentUser().subscribe(user => {
      //       this.saveUserInfoToStorage(user);
      //     });
      //     return userData;
      //   }
      // }),
    );
  }

  loginWithPasswordMobile(cipherText: string, initializationVector: string): Observable<Authenticate_Param> {
    const input: any = {
      cipherText: cipherText,
      initializationVector: initializationVector,
    };

    this.saveLoginTypeToLocal('Employee');

    return this.tokenService.LoginMobile(input).pipe(
      tap(userData => {
        if (userData && userData.token) {
          this.cookieService.set('clientId', userData.clientId, 365);
          localStorage.setItem('currentUser', JSON.stringify(userData));
        }
      }),
      flatMap(userData => {
        // update user permission
        if (userData && userData.token) {
          return this.userService.GetCurrentUserPermissions('Employee').pipe(
            map(permissions => {
              this.permissionsService.loadPermissions(permissions);
              localStorage.setItem('permissions', JSON.stringify(permissions));

              return userData;
            }),
          );
        }

        return of(userData);
      }),
      flatMap(userData => {
        if (userData && userData.token) {
          return this.userService.GetCurrentUser().pipe(
            map(user => {
              this.saveUserInfoToStorage(user);

              return userData;
            }),
          );
        }

        return of(userData);
      }),
    );
  }

  loginWithGoogleAccount(loginType: string, email: string, googleId: string): Observable<Authenticate_Param> {
    const input: Authenticate_Param = {
      type: loginType,
      googleId,
      email,
    };

    this.saveLoginTypeToLocal(loginType);

    return this.tokenService.LoginByGoogleAccount(input).pipe(
      tap(userData => {
        if (userData && userData.token) {
          this.cookieService.set('clientId', userData.clientId, 365);
          localStorage.setItem('currentUser', JSON.stringify(userData));
        }
      }),
      flatMap(userData => {
        // update user permission
        if (userData && userData.token) {
          return this.userService.GetCurrentUserPermissions(loginType).pipe(
            map(permissions => {
              this.permissionsService.loadPermissions(permissions);
              localStorage.setItem('permissions', JSON.stringify(permissions));

              return userData;
            }),
          );
        }

        return of(userData);
      }),
      flatMap(userData => {
        if (userData && userData.token) {
          return this.userService.GetCurrentUser().pipe(
            map(user => {
              this.saveUserInfoToStorage(user);

              return userData;
            }),
          );
        }

        return of(userData);
      }),
    );
  }

  loginWithGoogleAccountMobile(cipherText: string, initializationVector: string): Observable<Authenticate_Param> {
    const input: any = {
      cipherText: cipherText,
      initializationVector: initializationVector,
    };

    this.saveLoginTypeToLocal('Employee');

    return this.tokenService.LoginByGoogleAccountMobile(input).pipe(
      tap(userData => {
        if (userData && userData.token) {
          this.cookieService.set('clientId', userData.clientId, 365);
          localStorage.setItem('currentUser', JSON.stringify(userData));
        }
      }),
      flatMap(userData => {
        // update user permission
        if (userData && userData.token) {
          return this.userService.GetCurrentUserPermissions('Employee').pipe(
            map(permissions => {
              this.permissionsService.loadPermissions(permissions);
              localStorage.setItem('permissions', JSON.stringify(permissions));

              return userData;
            }),
          );
        }

        return of(userData);
      }),
      flatMap(userData => {
        if (userData && userData.token) {
          return this.userService.GetCurrentUser().pipe(
            map(user => {
              this.saveUserInfoToStorage(user);

              return userData;
            }),
          );
        }

        return of(userData);
      }),
    );
  }

  getUserInfo(): UserModel {
    const userInfoValue = localStorage.getItem('userInfo');
    if (userInfoValue) {
      const userInfo: UserModel = JSON.parse(userInfoValue);
      return userInfo;
    } else {
      // return default user value
      return {
        userId: -1,
        employeeId: -1,
        firstname: 'Guest',
        lastname: '',
        enableLoginAsAdmin: false,
        enableLoginAsEmployee: false,
      };
    }
  }

  isSubrogating(): boolean {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      return user.isSubrogating;
    }

    return false;
  }

  setToken(token: string, refreshToken: string) {
    const currentUser: UserData = JSON.parse(localStorage.getItem('currentUser'));
    currentUser.token = token;
    currentUser.refreshToken = refreshToken;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  clearToken() {
    this.permissionsService.flushPermissions();
    localStorage.removeItem('permissions');
    localStorage.removeItem('currentUser');
  }

  logout(): Observable<string> {
    const currentUser: UserData = JSON.parse(localStorage.getItem('currentUser'));
    return this.tokenService
      .Logout({
        clientId: currentUser.clientId,
      })
      .pipe(
        finalize(() => {
          // we will clear token what ever server response.
          this.clearToken();
          this.router.navigate(['/account/login']);
          window.parent.postMessage('Logout', '*');
        })
      )
  }

  getUserPermissions(): Permission[] {
    return JSON.parse(localStorage.getItem('permissions'));
  }
  hasPermission(permission: Permission) {
    return this.getUserPermissions().indexOf(permission) !== -1;
  }

  refreshToken(): Observable<string> {
    const currentUser: UserData = JSON.parse(localStorage.getItem('currentUser'));

    const refreshToken = currentUser.refreshToken;
    const clientId = this.cookieService.get('clientId');

    return this.tokenService
      .RefreshToken({
        clientId,
        refreshToken,
      })
      .pipe(
        map(
          user => {
            if(user)
            {
              this.setToken(user.token, user.refreshToken);
              return user.token;
            } else {
              this.logout().subscribe(a => {
                console.log('Log out success.');
              });;
            }
          },
          catchError((err, caught) => {
            this.logout().subscribe(a => {
              console.log('Log out success.');
            });;
            return EMPTY;
          }),
        ),
      );
  }

  getAccessToken(): string {
    const currentUser: UserData = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser != null) {
      return currentUser.token;
    }

    return null;
  }

  resetPassword(body: ResetPasswordModel) {
    return this.userService.ResetPassword(body).pipe(
      flatMap(() => {
        return this.userService.GetCurrentUser();
      }),
      tap(user => {
        this.saveUserInfoToStorage(user);
      }),
    );
  }

  saveGoogleIdToStorage(googleId: string) {
    this.saveUserInfoToStorage({
      ...this.getUserInfo(),
      googleId,
    });
  }

  private saveUserInfoToStorage(user: UserModel) {
    localStorage.setItem('userInfo', JSON.stringify(user));
  }

  subrogate(username: string, type: string): Observable<Authenticate_Param> {
    const input: Authenticate_Param = {
      type, // change depend on row data
      username,
      clientId: this.cookieService.get('clientId'),
    };

    const myPermissions: any = this.getPermission();
    this.subrogatePermission().set(myPermissions);

    return this.tokenService.Subrogate(input).pipe(
      tap(userData => {
        if (userData && userData.token) {
          this.cookieService.set('clientId', userData.clientId, 365);
          localStorage.setItem('currentUser', JSON.stringify(userData));
        }
      }),
      flatMap(userData => {
        if (userData && userData.token) {
          return this.userService.GetCurrentUserPermissions(type).pipe(
            map(permissions => {
              this.permissionsService.loadPermissions(permissions);
              localStorage.setItem('permissions', JSON.stringify(permissions));

              return userData;
            }),
          );
        }

        return of(userData);
      }),
      flatMap(userData => {
        if (userData && userData.token) {
          return this.userService.GetCurrentUser().pipe(
            map(user => {
              user.tempPassword = null;
              this.saveUserInfoToStorage(user);

              return userData;
            }),
          );
        }

        return of(userData);
      }),
    );
  }

  subrogateLogout(): Observable<UserData> {
    const currentUser: UserData = JSON.parse(localStorage.getItem('currentUser'));

    this.subrogatePermission().clear();

    return this.tokenService.SubrogateLogout(currentUser).pipe(
      catchError(err => {
        this.clearToken();
        return throwError(err);
      }),
      tap(userData => {
        if (userData && userData.token) {
          this.cookieService.set('clientId', userData.clientId, 365);
          localStorage.setItem('currentUser', JSON.stringify(userData));
        }
      }),
      flatMap(userData => {
        // update user permission !!!
        if (userData && userData.token) {
          return this.userService.GetCurrentUserPermissions(this.getLoginType()).pipe(
            map(permissions => {
              this.permissionsService.loadPermissions(permissions);
              localStorage.setItem('permissions', JSON.stringify(permissions));

              return userData;
            }),
          );
        }

        return of(userData);
      }),
      flatMap(userData => {
        if (userData && userData.token) {
          return this.userService.GetCurrentUser().pipe(
            map(user => {
              this.saveUserInfoToStorage(user);

              return userData;
            }),
          );
        }
        return of(userData);
      }),
    );
  }

  saveLoginTypeToLocal(loginType: string) {
    localStorage.setItem('loginType', loginType);
  }

  getLoginType() {
    return localStorage.getItem('loginType');
  }

  getCurrentUser(): UserData {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  isSuperAdmin() {
    return this.getCurrentUser().roles.includes('SuperAdmin');
  }

  isAdmin() {
    return this.getCurrentUser().roles.includes('Admin');
  }

  getPermission(): string[] {
    return JSON.parse(localStorage.getItem('permissions'));
  }

  subrogatePermission() {
    const key = 'permissionsSubrogee';

    const set = (permission: string) => {
      localStorage.setItem(key, JSON.stringify(permission));
    };
    const clear = () => {
      localStorage.removeItem(key);
    };
    const get = () => {
      return JSON.parse(localStorage.getItem(key));
    };

    return { set, clear, get };
  }
}
