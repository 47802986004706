import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeDrugAllergyModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeDrugAllergyService } from 'app/api/services';
import { finalize, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'app-modal-employee-drug-allergy',
  templateUrl: './modal-employee-drug-allergy.component.html',
  styleUrls: ['./modal-employee-drug-allergy.component.css'],
})
export class ModalEmployeeDrugAllergyComponent implements OnInit, OnDestroy {
  model: EmployeeDrugAllergyModel = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'drugName',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'drug_name',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'note',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'more_details',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeDrugAllergyService: EmployeeDrugAllergyService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const employeeDrugAllergyId = this.getEmployeeDrugAllergyId();

    if (employeeDrugAllergyId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    this.model = await this.employeeDrugAllergyService
      .GetEmployeeDrugAllergy(this.getEmployeeDrugAllergyId())
      .toPromise();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createEmployeeDrugAllergy()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateEmployeeDrugAllergy()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createEmployeeDrugAllergy() {
    return this.employeeDrugAllergyService.CreateEmployeeDrugAllergy({
      employeeId: this.model.employeeId,
      drugName: this.model.drugName,
      note: this.model.note,
    });
  }

  private updateEmployeeDrugAllergy() {
    console.log(this.model);
    return this.employeeDrugAllergyService.UpdateEmployeeDrugAllergy({
      EmployeeDrugAllergyId: this.model.employeeDrugAllergyId,
      body: {
        employeeId: this.model.employeeId,
        employeeDrugAllergyId: this.model.employeeDrugAllergyId,
        drugName: this.model.drugName,
        note: this.model.note,
        status: this.model.status,
      },
    });
  }

  getEmployeeDrugAllergyId() {
    return this.config.data?.id;
  }
}
