import { Component, OnInit, OnDestroy } from '@angular/core';
import { JobGradeOfSystemModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
// import { JobGradeOfSystemService } from 'app/api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MasterDataRepo } from 'app/repo/master-data.repo';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

class tagModel {
  tagName?: string;
  description?: string;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof tagModel;
}

@Component({
  selector: 'app-modal-tag',
  templateUrl: './modal-tag.component.html',
  styleUrls: ['./modal-tag.component.css'],
})
export class ModalTagComponent implements OnInit {
  model: tagModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'tagName',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,

        label: 'tags',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-3',
      },
    },
    {
      key: 'description',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,

        label: 'description',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',

        maxLength: 1024,
      },
    },
  ];
  unsubscripe$ = new Subject<any>();
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private noti: AppNotificationServiceService,
    private masterDataRepo: MasterDataRepo,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  // ngOnInit() {
  //   const jobGradeOfSystemId = this.config.data?.id;

  //   if (jobGradeOfSystemId) {
  //     this.componentMode = ComponentMode.UPDATE;
  //     this.initData(jobGradeOfSystemId);
  //   } else {
  //     this.componentMode = ComponentMode.CREATE;
  //     this.model = {};
  //   }
  // }

  ngOnDestroy(): void {
    this.unsubscripe$.unsubscribe();
  }

  initComponentMode() {
    const jobGradeOfSystemId = this.getTagId();

    if (jobGradeOfSystemId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    // this.fields.find(
    //   a => a.key === 'jobGradeLevel',
    // ).templateOptions.options = await this.masterDataRepo.getJobGradeLevelsOptionList().toPromise();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {};
      return;
    }

    // this.model = await this.jobGradeOfSystemService.GetJobGradeOfSystemById(this.getJobGradeOfSystemId()).toPromise();
  }

  submit() {
    if (this.componentMode === ComponentMode.CREATE) {
      // this.jobGradeOfSystemService
      //   .CreateJobGradeOfSystem(this.model)
      //   .subscribe(
      //     () => {
      //       this.noti.saveCompleted();
      //       this.ref.close(this.model);
      //     },
      //     (err: HttpErrorResponse) => {
              // const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              // this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
      //     },
      //   );
    } else {
      // this.jobGradeOfSystemService
      //   .UpdateJobGradeOfSystem(this.model)
      //   .subscribe(
      //     () => {
      //       this.noti.saveCompleted();
      //       this.ref.close(this.model);
      //     },
      //     (err: HttpErrorResponse) => {
              // const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              // this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
      //     },
      //   );
    }
  }

  close() {
    this.ref.close();
  }

  getTagId() {
    return this.config.data?.id;
  }
}
