import { FormlyFieldConfig } from '@ngx-formly/core';
import * as moment from 'moment';

export const toOptionList = <T>(item: T, valueKey: keyof T, labelKey: keyof T) => {
  return {
    value: item[valueKey],
    label: item[labelKey],
  };
};

export interface CustomFormlyFieldConfig<T extends string> extends FormlyFieldConfig {
  key?: T;
  fieldGroup?: CustomFormlyFieldConfig<T>[];
}
