/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeExpiredDocumentModelPaginationResult } from '../models/employee-expired-document-model-pagination-result';
@Injectable({
  providedIn: 'root',
})
class EmployeeExpiredDocumentService extends __BaseService {
  static readonly GetNotifyCertificatePath = '/api/EmployeeExpiredDocument/certificate';
  static readonly GetNotifyWorkPermitPath = '/api/EmployeeExpiredDocument/workpermit';
  static readonly GetNotifyHealthCheckPath = '/api/EmployeeExpiredDocument/healthcheck';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EmployeeExpiredDocumentService.GetNotifyCertificateParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsExpired`:
   *
   * - `IsCloseExpired`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `DocumentName`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetNotifyCertificateResponse(params: EmployeeExpiredDocumentService.GetNotifyCertificateParams): __Observable<__StrictHttpResponse<EmployeeExpiredDocumentModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsExpired != null) __params = __params.set('IsExpired', params.IsExpired.toString());
    if (params.IsCloseExpired != null) __params = __params.set('IsCloseExpired', params.IsCloseExpired.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.DocumentName != null) __params = __params.set('DocumentName', params.DocumentName.toString());
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeExpiredDocument/certificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeExpiredDocumentModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeeExpiredDocumentService.GetNotifyCertificateParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsExpired`:
   *
   * - `IsCloseExpired`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `DocumentName`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetNotifyCertificate(params: EmployeeExpiredDocumentService.GetNotifyCertificateParams): __Observable<EmployeeExpiredDocumentModelPaginationResult> {
    return this.GetNotifyCertificateResponse(params).pipe(
      __map(_r => _r.body as EmployeeExpiredDocumentModelPaginationResult)
    );
  }

  /**
   * @param params The `EmployeeExpiredDocumentService.GetNotifyWorkPermitParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsExpired`:
   *
   * - `IsCloseExpired`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `DocumentName`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetNotifyWorkPermitResponse(params: EmployeeExpiredDocumentService.GetNotifyWorkPermitParams): __Observable<__StrictHttpResponse<EmployeeExpiredDocumentModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsExpired != null) __params = __params.set('IsExpired', params.IsExpired.toString());
    if (params.IsCloseExpired != null) __params = __params.set('IsCloseExpired', params.IsCloseExpired.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.DocumentName != null) __params = __params.set('DocumentName', params.DocumentName.toString());
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeExpiredDocument/workpermit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeExpiredDocumentModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeeExpiredDocumentService.GetNotifyWorkPermitParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsExpired`:
   *
   * - `IsCloseExpired`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `DocumentName`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetNotifyWorkPermit(params: EmployeeExpiredDocumentService.GetNotifyWorkPermitParams): __Observable<EmployeeExpiredDocumentModelPaginationResult> {
    return this.GetNotifyWorkPermitResponse(params).pipe(
      __map(_r => _r.body as EmployeeExpiredDocumentModelPaginationResult)
    );
  }

  /**
   * @param params The `EmployeeExpiredDocumentService.GetNotifyHealthCheckParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsExpired`:
   *
   * - `IsCloseExpired`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `DocumentName`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetNotifyHealthCheckResponse(params: EmployeeExpiredDocumentService.GetNotifyHealthCheckParams): __Observable<__StrictHttpResponse<EmployeeExpiredDocumentModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsExpired != null) __params = __params.set('IsExpired', params.IsExpired.toString());
    if (params.IsCloseExpired != null) __params = __params.set('IsCloseExpired', params.IsCloseExpired.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.DocumentName != null) __params = __params.set('DocumentName', params.DocumentName.toString());
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeExpiredDocument/healthcheck`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeExpiredDocumentModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeeExpiredDocumentService.GetNotifyHealthCheckParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsExpired`:
   *
   * - `IsCloseExpired`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `DocumentName`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetNotifyHealthCheck(params: EmployeeExpiredDocumentService.GetNotifyHealthCheckParams): __Observable<EmployeeExpiredDocumentModelPaginationResult> {
    return this.GetNotifyHealthCheckResponse(params).pipe(
      __map(_r => _r.body as EmployeeExpiredDocumentModelPaginationResult)
    );
  }
}

module EmployeeExpiredDocumentService {

  /**
   * Parameters for GetNotifyCertificate
   */
  export interface GetNotifyCertificateParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsExpired?: boolean;
    IsCloseExpired?: boolean;
    EmployeeName?: string;
    EmployeeCode?: string;
    DocumentName?: string;
    Date?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for GetNotifyWorkPermit
   */
  export interface GetNotifyWorkPermitParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsExpired?: boolean;
    IsCloseExpired?: boolean;
    EmployeeName?: string;
    EmployeeCode?: string;
    DocumentName?: string;
    Date?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for GetNotifyHealthCheck
   */
  export interface GetNotifyHealthCheckParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsExpired?: boolean;
    IsCloseExpired?: boolean;
    EmployeeName?: string;
    EmployeeCode?: string;
    DocumentName?: string;
    Date?: string;
    CompanyId?: number;
  }
}

export { EmployeeExpiredDocumentService }
