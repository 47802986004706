<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <div class="grid justify-content-between">
          <div class="col-fixed">
            <h1>แบบฟอร์มตรวจสอบทรัพย์สิน (เข้า-ออก)</h1>
          </div>
          <div class="col"></div>
          <div class="col-fixed">
            <button
              pButton
              type="button"
              label="บันทึก"
              [disabled]="disbleSaveBtn()"
              (click)="onSaveBtnClick()"
            ></button>
          </div>
        </div>
        <hr />
        <div class="card">
          <div class="grid table-header">
            <div class="col-1 text-center">ลำดับ</div>
            <div class="col-4 text-center">รายการ</div>
            <div class="col-1">เข้า</div>
            <div class="col-6">ออก</div>
          </div>
          <form [formGroup]="form">
            <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
