/* tslint:disable */
type ELeaveRequestStatus =
  'Draft' |
  'Editing' |
  'Awaiting' |
  'Approved' |
  'PartiallyApproved' |
  'Cancel' |
  'Reject';
module ELeaveRequestStatus {
  export const DRAFT: ELeaveRequestStatus = 'Draft';
  export const EDITING: ELeaveRequestStatus = 'Editing';
  export const AWAITING: ELeaveRequestStatus = 'Awaiting';
  export const APPROVED: ELeaveRequestStatus = 'Approved';
  export const PARTIALLY_APPROVED: ELeaveRequestStatus = 'PartiallyApproved';
  export const CANCEL: ELeaveRequestStatus = 'Cancel';
  export const REJECT: ELeaveRequestStatus = 'Reject';
  export function values(): ELeaveRequestStatus[] {
    return [
      DRAFT,
      EDITING,
      AWAITING,
      APPROVED,
      PARTIALLY_APPROVED,
      CANCEL,
      REJECT
    ];
  }
}

export { ELeaveRequestStatus }