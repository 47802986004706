<h1>{{ 'attach_file' | translate }}</h1>
<p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="importanceFileList">
  <ng-template pTemplate="header">
    <tr>
      <th>{{ 'document_type' | translate }}</th>
      <th>Action</th>
      <th *ngIf="isApprovalCenterPage">Download</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>{{ rowData.type }}</td>
      <td class="col-upload-file">
        <p-fileUpload
          mode="advanced"
          [multiple]="false"
          [maxFileSize]="26214400"
          [showCancelButton]="false"
          [showUploadButton]="false"
          [fileLimit]="1"
          [chooseLabel]="rowData.buttonLabel"
          (onSelect)="onSelectFile($event, rowData)"
          [showCancelButton]="false"
          [previewWidth]="50"
          (onRemove)="onRemoveFile($event, rowData)"
        >
        </p-fileUpload>
      </td>
      <td *ngIf="isApprovalCenterPage">
        <span *ngIf="!rowData.documentId">N/A</span>
        <a *ngIf="rowData.documentId" (click)="downloadFile(rowData.documentId, rowData.fileName)">download</a>
      </td>
    </tr>
  </ng-template>
</p-table>
