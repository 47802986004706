import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../../components/components.module';
import { SharedModule } from '../../../shared/shared.module';
import { IosRedeemedCodeComponent } from './ios-redeemed-code.component';

@NgModule({
  declarations: [IosRedeemedCodeComponent],
  imports: [CommonModule, SharedModule, ComponentsModule],
})
export class IosRedeemedCodeModule {}
