import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { EmployeeListItemModel, PositionModel, EBossPositionType } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { forkJoin, from, Observable, of, scheduled, Subject, ReplaySubject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import {  toOptionList } from 'utils/formly-utils';
import * as moment from 'moment';
import { NotificationService, CostCenterService, PositionService, LocationService } from 'app/api/services';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { map, debounceTime, distinctUntilChanged, finalize, flatMap } from 'rxjs/operators';
import { MasterDataRepo } from 'app/repo/master-data.repo';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface PositionFormModel extends PositionModel {
  directBosses?: PositionModel[];
  currentEmployeeName?: string;
  isNotSpecifyTerminateDate?: boolean;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof PositionFormModel;
}

@Component({
  selector: 'app-modal-position',
  templateUrl: './modal-position.component.html',
  styleUrls: ['./modal-position.component.css'],
})
export class ModalPositionComponent implements OnInit {
  companyIdChange = new ReplaySubject<number>();
  asAofDateChange = new ReplaySubject<string>();
  model: PositionFormModel;
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'code',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'code_position',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        tooltip: 'ข้อมูลนี้จะถูกสร้างโดยระบบ'
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'position_th',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        required: true,
        maxLength: 1024,
      },
    },
    {
      key: 'nameEn',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'position_en',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        required: false,
        maxLength: 1024,
      },
    },
    // {
    //   key: 'directBosses',
    //   type: 'repeat-select-boss',
    //   templateOptions: {
    //     label: 'หัวหน้างาน',
    //     addSectionLabel: 'เลือกจากรายการ',
    //     labelClass: 'col-4',
    //     inputClass: 'col-4',
    //     companyChange: this.companyIdChange,
    //   },
    //   fieldArray: {
    //     fieldGroupClassName: 'grid',
    //     fieldGroup: [
    //       {
    //         key: 'positionDef.name',
    //         type: 'primeng-input',
    //         className: 'col-12',
    //         templateOptions: {
    //           disabled: true,
    //         },
    //       },
    //     ],
    //   },
    // },
    {
      key: 'costCenterId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'ศูนย์ต้นทุน',
        labelClass: 'col-4',
        inputClass: 'col-8',
        options: this.costCenterService
          .GetCostCenters({ Page: 1, ItemPerPage: 1000 })
          .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'costCenterId', 'name'))))
          .pipe(map(a => {
            return [
              {
                label: 'ใช้ค่าตามหน่วยงาน',
                value: null
              }, ...a];
          }))
      },
    },
    {
      key: 'bpCode',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'รหัส BPlus',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'personnelAreaId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'organization_matrix',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'locationId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'working_area',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        options: this.locationService
          .GetLocationsList({ Page: 1, ItemPerPage: 1000 })
          .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'locationId', 'name'))))
          .pipe(map(a => {
            return [
              {
                label: 'ใช้ค่าตามหน่วยงาน',
                value: null
              }, ...a];
          }))
      }
      ,
    },
    {
      key: 'unitId',
      type: 'select-unit',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,

        label: 'agency',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        required: true,
        companyChange: this.companyIdChange,
        asAofDateChange: this.asAofDateChange,
        isWaitCompanyId: true,
      },
    },
    {
      key: 'jobGradeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'class',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        required: true,
        tooltip:
          'เมื่อสร้างข้อมูลพนักงานใหม่ หากกำหนดตำแหน่งที่ระบุชั้นงาน (job grade) ให้พนักงานตามนโยบายสวัสดิการที่ตั้งค่าไว้ ระบบจะสร้างโควตาวันลาโดยอัตโนมัติ',
      },
    },
    {
      key: 'jobFamilyId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,

        label: 'work_group',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        // options: this.optionListRepo.getJobFamilyOptionList({}),
        required: true,
      },
    },
    {
      key: 'bossPositionType',
      type: 'radio',
      wrappers: ['inline-label'],
      defaultValue: ((): EBossPositionType => 'Specific')(),
      templateOptions: {
        translate: true,

        label: 'supervisor_type',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        required: true,
        options: this.masterDataRepo.getBossPositionTypesOptionList(),
      },
    },
    {
      key: 'directBosses',
      type: 'repeat-select-boss',
      templateOptions: {
        translate: true,
        label: 'set_supervisor',
        placeholder: '@blank',
        addSectionLabel: 'เลือกจากรายการ',
        labelClass: 'col-4',
        inputClass: 'col-4',
      },

      expressionProperties: {
        'templateOptions.isEditable': () => this.model?.bossPositionType === 'Specific',
      },
      fieldArray: {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            key: 'name',
            type: 'primeng-input',
            className: 'col-12',
            templateOptions: {
              disabled: true,
            },
          },
        ],
      },
    },
    {
      key: 'currentEmployeeName',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'persist_position',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        disabled: true,
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      defaultValue: moment().format('YYYY-MM-DD'),
      templateOptions: {
        translate: true,
        label: 'start_date_user',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
    {
      fieldGroupClassName: 'grid align-center',
      fieldGroup: [
        {
          key: 'terminateDate',
          type: 'calendar',
          className: 'col-8',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            required: true,
            label: 'end_use_date',
            placeholder: '@blank',
            labelClass: 'col-6',
            inputClass: 'col',
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.isNotSpecifyTerminateDate',
          },
        },
        {
          key: 'isNotSpecifyTerminateDate',
          type: 'checkbox',
          className: 'col-4',
          wrappers: ['inline-label'],
          defaultValue: true,
          templateOptions: {
            translate: true,
            required: true,
            label: 'no_date_specified',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            attributes: {
              style: 'width: 100%;',
            },
          },
        },
      ],
    },
    {
      key: 'bpCode',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'bp_code_position',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
  ];
  componentMode: ComponentMode;
  effectiveDate: string;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private noti: AppNotificationServiceService,
    private positionService: PositionService,
    private costCenterService: CostCenterService,
    private optionListRepo: OptionListRepo,
    private masterDataRepo: MasterDataRepo,
    private notificationService: AppNotificationServiceService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.initComponentMode();

    this.getPosition()
      .pipe(
        flatMap(position => {
          if (this.componentMode === ComponentMode.CREATE) {
            this.model = {
              companyId: this.config.data?.companyId,
            };
          } else {
            this.model = {
              ...position,
              currentEmployeeName: position.currentEmployeeInPosition?.employeeName || 'N/A',
              directBosses: position.directBosses.map<PositionModel>(boss => {
                return {
                  ...boss,
                  // positionDef: {
                  //   name: boss.directBossName,
                  // },
                  name: boss.directBossName,
                  positionId: boss.directBossId,
                };
              }),
              effectiveDate: (position.effectiveDate),
              terminateDate: (position.terminateDate),
              isNotSpecifyTerminateDate: (position.terminateDate) === '9999-12-31',
            };
          }

          return from([{ ...this.model }]);
        }),
        flatMap(positionFormModel => {
          const companyId = positionFormModel.companyId;

          return forkJoin([
            this.optionListRepo.getJobFamilyOptionList({}),
            this.optionListRepo.getPersonnelAreaOptionList({ companyId }),
            this.optionListRepo.getUnitOptionList({ CompanyId: companyId }),
            this.optionListRepo.getJobGradeOptionList({ companyId }),
          ]);
        }),
      )
      .subscribe(
        ([jobFamilyList, personelAreaList, unitList, jobGradeList]) => {
          this.fields.find(a => a.key === 'jobFamilyId').templateOptions.options = jobFamilyList;
          this.fields.find(a => a.key === 'personnelAreaId').templateOptions.options = personelAreaList;
          this.fields.find(a => a.key === 'unitId').templateOptions.options = unitList;
          this.fields.find(a => a.key === 'jobGradeId').templateOptions.options = jobGradeList;
          this.companyIdChange.next(this.model.companyId);
          this.asAofDateChange.next(this.getEffectiveDate());
        },
        err => {
          console.log(err);
        },
      );

    console.log(JSON.stringify(this.model));
  }

  initComponentMode() {
    const positionId = this.getPositionId();

    if (positionId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  getPosition(): Observable<PositionModel> {
    if (this.componentMode === ComponentMode.CREATE) {
      return from([{ companyId: this.config.data?.companyId }]);
    } else {
      return this.positionService.GetPositionAsOfDate({
        positionId: this.getPositionId(),
        effectiveDate: this.getEffectiveDate(),
      });
    }
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    console.log(this.model);
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createPosition()
          .subscribe(
            () => {
              this.noti.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updatePosition()
          .subscribe(
            () => {
              this.noti.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createPosition() {
    return this.positionService.CreatePosition({
      ...this.model,
      directBosses: this.model.directBosses.map(boss => {
        return {
          directBossId: boss.positionId,
        };
      }),
      terminateDate: this.model.isNotSpecifyTerminateDate ? '9999-12-31' : this.model.terminateDate,
    });
  }

  private updatePosition() {
    return this.positionService.EditPosition({
      PositionId: this.model.positionId,
      body: {
        ...this.model,
        directBosses: this.model.directBosses.map(boss => {
          return {
            ...boss,
            directBossId: boss.positionId,
          };
        }),
        terminateDate: this.model.isNotSpecifyTerminateDate ? '9999-12-31' : this.model.terminateDate,
      },
    });
  }

  getPositionId() {
    return this.config.data?.id;
  }

  getEffectiveDate() {
    return this.config.data?.effectiveDate;
  }
}
