import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeSalaryModel, EWagePayType } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { finalize, map, takeUntil, flatMap, tap } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeSalaryService, EmployeeService } from 'app/api/services';
import { HttpErrorResponse } from '@angular/common/http';

import * as moment from 'moment';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { MasterDataRepo } from 'app/repo/master-data.repo';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeSalaryModel;
}

@Component({
  selector: 'app-modal-employee-salary',
  templateUrl: './modal-employee-salary.component.html',
  styleUrls: ['./modal-employee-salary.component.css'],
})
export class ModalEmployeeSalaryComponent implements OnInit {
  dailyWageTypes: EWagePayType[] = [
    'Daily', 'Daily15_1', 'Daily15_2', 'Daily15_3', 'Weekly', 'BiWeekly'
  ];
  model: EmployeeSalaryModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      type: 'filter-dropdown',
      key: 'wagePayType',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ประเภทการจ่าย:',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: this.masterDataRepo.getWagePayTypesOptionList(),
      },
    },
    {
      key: 'salary',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เงินเดือน',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        type: 'number',
        min:0,
      },
      hideExpression: (model: EmployeeSalaryModel) => this.dailyWageTypes.indexOf(model.wagePayType) !== -1
    },
    {
      key: 'payDaily',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'จ่ายรายวัน',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        type: 'number',
        min:0,
      },
      hideExpression: (model: EmployeeSalaryModel) => this.dailyWageTypes.indexOf(model.wagePayType) == -1
    },
    {
      key: 'jobFamilyId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'กลุ่มงาน',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: []
      },
    },
    {
      key: 'jobGradeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ชั้นงาน',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: [],
      },
    },
    {
      key: 'positionName',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ตำแหน่ง',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
        filter: true,
        virtualScroll: true,
        itemSize: 30,
        options: [],
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      defaultValue: moment().format('YYYY-MM-DD'),
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'วันที่มีผล',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
  ];
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeSalaryService: EmployeeSalaryService,
    private optionListRepo: OptionListRepo,
    private employeeService: EmployeeService,
    private masterDataRepo: MasterDataRepo,
  ) { }

  ngOnInit() {
    this.initComponentMode();

    this.employeeService
      .GetEmployeeInfo(this.getEmployeeId())
      .pipe(
        tap(employee => {
          if (this.componentMode === ComponentMode.UPDATE) {
            this.employeeSalaryService.GetEmployeeSalary(this.getSalaryId()).subscribe(salary => {
              this.model = {
                ...salary,
                effectiveDate: (salary.effectiveDate),
              };
            });
          } else {
            this.model = {
              employeeId: employee.employeeId,
              positionName: employee.positionName,
              jobFamilyId: employee.currentPosition?.jobFamilyId,
              jobGradeId: employee.currentPosition?.jobGradeId,
            };
          }
        }),
        flatMap(employee => {
          return forkJoin([
            this.optionListRepo.getJobFamilyOptionList({}),
            this.optionListRepo.getJobGradeOptionList({ companyId: employee.currentPosition.companyId }),
            this.optionListRepo.getPositionItemListOptionList({ CompanyId: employee.currentPosition.companyId }).pipe(
              map(positionOpts => {
                return positionOpts.map(opt => {
                  return {
                    value: opt.label,
                    label: opt.label,
                  };
                });
              }),
            ),
          ]);
        }),
      )
      .subscribe(([jobFamilyList, jobGradeList, positionList]) => {
        this.fields.find(a => a.key === 'jobFamilyId').templateOptions.options = jobFamilyList;
        this.fields.find(a => a.key === 'jobGradeId').templateOptions.options = jobGradeList;
        this.fields.find(a => a.key === 'positionName').templateOptions.options = positionList;
      });
  }

  initComponentMode() {
    const salaryId = this.getSalaryId();

    if (salaryId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createEmployeeSalary()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateEmployeeSalary()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createEmployeeSalary() {
    return this.employeeSalaryService.CreateEmployeeSalary({
      employeeId: this.model.employeeId,
      salary: this.model.salary,
      payDaily: this.model.payDaily,
      jobFamilyId: this.model.jobFamilyId,
      jobGradeId: this.model.jobGradeId,
      positionName: this.model.positionName,
      effectiveDate: this.model.effectiveDate,
      wagePayType: this.model.wagePayType,
    });
  }

  private updateEmployeeSalary() {
    return this.employeeSalaryService.UpdateEmployeeSalary({
      EmployeeSalaryId: this.model.employeeSalaryId,
      body: {
        employeeId: this.model.employeeId,
        employeeSalaryId: this.model.employeeSalaryId,
        salary: this.model.salary,
        payDaily: this.model.payDaily,
        effectiveDate: this.model.effectiveDate,
        jobFamilyId: this.model.jobFamilyId,
        jobGradeId: this.model.jobGradeId,
        positionName: this.model.positionName,
        changeReason: this.model.changeReason,
        wagePayType: this.model.wagePayType,
        status: this.model.status,
      },
    });
  }

  getSalaryId() {
    return this.config.data?.id;
  }

  getEmployeeId() {
    return this.config.data?.employeeId;
  }
}
