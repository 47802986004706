import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EmployeeListItemModel, EmployeeModel } from 'app/api/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmployeeService } from 'app/api/services';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EventPageChange } from '../../../interfaces/EventPageChange';
import { PaginationParams } from '../../../interfaces/PaginationParams';

interface FilterModel {
  searchtext?: string;
  isOnlySubOrdinate: boolean;
  companyId?: number;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof FilterModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-modal-employee-search',
  templateUrl: './modal-employee-search.component.html',
  styleUrls: ['./modal-employee-search.component.css'],
})
export class ModalEmployeeSearchComponent implements OnInit {
  model: FilterModel = { isOnlySubOrdinate: false };
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'searchtext',
          type: 'primeng-input',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'ค้นหา',
            labelClass: 'col-2 md:col-1 lg:col-1',
            inputClass: 'col-10 lg:col-10 lg:col-10',
          },
          hooks: {
            afterViewInit: field => {
              field.formControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
                this.setEmployeeList();
              });
            },
          },
        },
      ],
    },
  ];

  loading = true;
  employeeList: EmployeeListItemModel[] = [];
  paginationParams: PaginationParams;

  constructor(
    private ref: DynamicDialogRef,
    private employeeService: EmployeeService,
    private config: DynamicDialogConfig,
  ) {}

  ngOnInit() {
    this.model.isOnlySubOrdinate = this.config.data?.isOnlySubOrdinate || false;
    this.model.companyId = this.config.data?.companyId;
    this.initPaginationParams();
    this.setEmployeeList();
  }

  initPaginationParams() {
    this.paginationParams = {
      firstRowIdx: 0,
      rowPerPage: 20,
      totalRecords: 0,
      rowPerPageSelectList: [5, 10, 20, 50, 100, 200],
    };
  }

  setEmployeeList() {
    this.loading = true;

    const params = this.paginationParams;
    const page = Math.floor(params.firstRowIdx / params.rowPerPage) + 1;

    this.employeeService
      .GetEmployees({
        ItemPerPage: params.rowPerPage,
        Page: page,
        SearchText: this.model.searchtext,
        IsOnlySubOrdinate: this.model.isOnlySubOrdinate,
        CompanyId: this.model.companyId,
      })
      .subscribe(
        pageResult => {
          this.employeeList = pageResult.results;

          params.totalRecords = pageResult.total;
          this.loading = false;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.employeeList = [];
          this.loading = false;
        },
      );
  }

  onPageChange(event: EventPageChange) {
    this.paginationParams.firstRowIdx = event.first;
    this.paginationParams.rowPerPage = event.rows;
    this.setEmployeeList();
  }

  search() {
    console.log(this.model);
    this.setEmployeeList();
  }

  close() {
    this.ref.close();
  }

  selectEmployee(employee: EmployeeModel) {
    this.ref.close(employee);
  }
}
