/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeInterestModel } from '../models/employee-interest-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeInterestService extends __BaseService {
  static readonly GetEmployeeInterestsListPath = '/api/EmployeeInterest';
  static readonly CreateEmployeeInterestPath = '/api/EmployeeInterest';
  static readonly UpdateEmployeeInterestPath = '/api/EmployeeInterest';
  static readonly GetEmployeeInterestByIdPath = '/api/EmployeeInterest/{EmployeeInterestId}';
  static readonly DeleteEmployeeInterestPath = '/api/EmployeeInterest/{EmployeeInterestId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeInterestsListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeInterestModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeInterest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeInterestModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeInterestsList(): __Observable<Array<EmployeeInterestModel>> {
    return this.GetEmployeeInterestsListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeInterestModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeInterestResponse(body?: EmployeeInterestModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeInterest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeInterest(body?: EmployeeInterestModel): __Observable<string> {
    return this.CreateEmployeeInterestResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeInterestResponse(body?: EmployeeInterestModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeInterest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeInterest(body?: EmployeeInterestModel): __Observable<string> {
    return this.UpdateEmployeeInterestResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeInterestId undefined
   * @return Success
   */
  GetEmployeeInterestByIdResponse(EmployeeInterestId: number): __Observable<__StrictHttpResponse<EmployeeInterestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeInterest/${encodeURIComponent(String(EmployeeInterestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeInterestModel>;
      })
    );
  }
  /**
   * @param EmployeeInterestId undefined
   * @return Success
   */
  GetEmployeeInterestById(EmployeeInterestId: number): __Observable<EmployeeInterestModel> {
    return this.GetEmployeeInterestByIdResponse(EmployeeInterestId).pipe(
      __map(_r => _r.body as EmployeeInterestModel)
    );
  }

  /**
   * @param EmployeeInterestId undefined
   * @return Success
   */
  DeleteEmployeeInterestResponse(EmployeeInterestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeInterest/${encodeURIComponent(String(EmployeeInterestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeInterestId undefined
   * @return Success
   */
  DeleteEmployeeInterest(EmployeeInterestId: number): __Observable<string> {
    return this.DeleteEmployeeInterestResponse(EmployeeInterestId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeInterestService {
}

export { EmployeeInterestService }
