<p-checkbox
  [binary]="true"
  [label]="to.label"
  [formControl]="$any(formControl)"
  [formlyAttributes]="field"
  [readonly]="to.readonly"
  (onChange)="to.change && to.change(field, $event)"
  [pTooltip]="to?.tooltip || undefined"
>
</p-checkbox>
