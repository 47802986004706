import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBenefitElementComponent } from './modal-benefit-element/modal-benefit-element.component';
import { ModalSumBenefitComponent } from './modal-sum-benefit/modal-sum-benefit.component';
import { SharedModule } from '../../shared/shared.module';
import { ModalBenefitAdvanceSettingComponent } from './modal-benefit-advance-setting/modal-benefit-advance-setting.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

const allModals = [ModalBenefitElementComponent, ModalBenefitAdvanceSettingComponent,ModalSumBenefitComponent];

@NgModule({
    declarations: [...allModals],
    imports: [CommonModule, DynamicDialogModule, SharedModule],
    exports: [...allModals]
})
export class BenefitsModule {}
