import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { EmployeePunishmentModel } from '../../../api/models';
import { EmployeeService, EmployeePunishmentService } from '../../../api/services';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { ModalEmployeePunishmentComponent } from '../../../modals/core/modal-employee-punishment/modal-employee-punishment.component';
import { MasterDataRepo } from '../../../repo/master-data.repo';
import { AppNotificationServiceService } from '../../../shared/app-notification-service.service';

@Component({
  selector: 'app-punishment',
  templateUrl: './punishment.component.html',
  styleUrls: ['./punishment.component.css'],
  providers: [DialogService],
})
export class PunishmentComponent implements OnInit {
  isEditable = false;
  punishmentList: EmployeePunishmentModel[];
  ref: DynamicDialogRef;

  constructor(
    private layout: LayoutDefaultComponent,
    private router: Router,
    private notificationService: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeService: EmployeeService,
    private employeePunishmentService: EmployeePunishmentService,
    private masterDataRepo: MasterDataRepo,
  ) {}

  ngOnInit(): void {
    this.setEditablePermission();
    this.setPunishmentList();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setPunishmentList() {
    const employeeId = this.getEmployeeId();

    this.masterDataRepo.getPenaltyTypeOptionList().subscribe((ops: any[]) => {
      this.employeeService.GetPunishments(employeeId).subscribe(punishments => {
        this.punishmentList = punishments.map(p => {
          return {
            ...p,
            punishment: ops.find(a => a.value === p.punishment)?.label || 'N/A',
          };
        });
      });
    });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  showCreatePunishmentDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่มโทษทางวินัย', ModalEmployeePunishmentComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setPunishmentList();
      }
    });
  }

  showUpdatePunishmentDialog(PunishmentId: number) {
    this.showDialog(
      { id: PunishmentId, employeeId: this.getEmployeeId() },
      'แก้ไขโทษทางวินัย',
      ModalEmployeePunishmentComponent,
    );

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setPunishmentList();
      }
    });
  }

  showDeletePunishmentDialog(PunishmentId: number) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeePunishmentService.DeleteEmployeePunishment(PunishmentId).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.setPunishmentList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }
}
