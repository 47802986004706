<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>เงินเดือน</h1>
        <hr />
        <div class="card">
          <div class="grid justify-between">
            <div class="col-fixed">
              <h1>เงินเดือน</h1>
            </div>
            <div class="col"></div>
            <div class="col-fixed">
              <button *ngIf="isEditable" pButton type="button" label="เพิ่มข้อมูล"
                (click)="onCreateSalaryBtnClick()"></button>
            </div>
          </div>
          <p-table [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="salaryList"
            [autoLayout]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>อัตราเงินเดือน</th>
                <th>ตำแหน่งงาน</th>
                <th>Job Grade</th>
                <th>Job Family</th>
                <th>วันที่มีผล</th>
                <th *ngIf="isEditable" style="width: 50px">Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns>
              <tr>
                <td>{{ rowData.salary | number: '0.2-2' }}</td>
                <td>{{ rowData.positionName }}</td>
                <td>{{ rowData.jobGradeName }}</td>
                <td>{{ rowData.jobFamilyName }}</td>
                <td>{{ rowData.effectiveDate | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i class="fa fa-pencil-alt" (click)="showUpdateEmployeeSalaryDialog(rowData)"></i>
                    </div>
                    <!-- <div class="col-fixed">
                      <i class="fa fa-trash" (click)="showDeleteEmployeeSalaryDialog(rowData)"></i>
                    </div> -->
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6" class="emptymessage">ไม่พบข้อมูล</td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <div class="card">
          <div class="grid justify-between">
            <div class="col-fixed">
              <h1>สาขา</h1>
            </div>
            <div class="col"></div>
            <div class="col-fixed">
              <button *ngIf="isEditable" pButton type="button" label="{{ 'record' | translate }}"
                (click)="onUpdateBranchBtnClick()"></button>
            </div>
          </div>
          <div class="col-12" style="margin-top: 3%">
            <form [formGroup]="branchForm">
              <formly-form [model]="branchModel" [fields]="branchFields" [form]="branchForm"> </formly-form>
            </form>
          </div>
        </div>

        <div class="card">
          <div class="grid justify-between">
            <div class="col-fixed">
              <h1>ประกันสังคม</h1>
            </div>
            <div class="col"></div>
            <div class="col-fixed">
              <button *ngIf="isEditable" pButton type="button" label="{{ 'record' | translate }}"
                (click)="onUpdateSocialSecurityBtnClick()"></button>
            </div>
          </div>
          <div class="col-12" style="margin-top: 3%">
            <form [formGroup]="ssoForm">
              <formly-form [model]="ssoModel" [fields]="ssoFields" [form]="ssoForm"> </formly-form>
            </form>
            <div class="col-6">
              <p>อัตราสมทบผู้ประกันตน {{employeeBranch.contribution_Rate}} %</p>
            </div>
          </div>
        </div>


        <div class="card">
          <h1>รายได้รายหักประจำ</h1>
          <div class="col-12" style="margin-top: 3%">
            <form [formGroup]="payElementForm">
              <formly-form [model]="payElementModel" [fields]="payElementFields" [form]="payElementForm"> </formly-form>
            </form>
          </div>
          <div class="grid justify-content-end">
            <div class="col-fixed">
              <button *ngIf="isEditable" pButton type="button" (click)="updatePayElements()"
                label="{{ 'record' | translate }}"></button>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>บัญชี</h1>
            </div>
            <div class="col"></div>
            <div class="col-fixed">
              <button *ngIf="isEditable" pButton type="button" label="เพิ่มข้อมูล"
                (click)="onCreateBankAccountBtnClick()"></button>
            </div>
          </div>
          <p-table [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="bankAccountList"
            [autoLayout]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>เลขที่บัญชี</th>
                <th>ธนาคาร</th>
                <th>สาขา</th>
                <th *ngIf="isEditable" style="width: 100px">Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns>
              <tr>
                <td>{{ rowData.accountNumber }}</td>
                <td>{{ rowData.bankName }}</td>
                <td>{{ rowData.branchName }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i class="fa fa-pencil-alt"
                        (click)="showUpdateEmployeeBankAccountDialog(rowData.employeeBankAccountId)"></i>
                    </div>
                    <div class="col-fixed">
                      <i class="fa fa-trash"
                        (click)="showDeleteEmployeeBankAccountDialog(rowData.employeeBankAccountId)"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="4" class="emptymessage">ไม่พบข้อมูล</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>