/* tslint:disable */
type ETaxCalculationType =
  'Auto' |
  'Manual' |
  'NoTaxCalculation';
module ETaxCalculationType {
  export const AUTO: ETaxCalculationType = 'Auto';
  export const MANUAL: ETaxCalculationType = 'Manual';
  export const NO_TAX_CALCULATION: ETaxCalculationType = 'NoTaxCalculation';
  export function values(): ETaxCalculationType[] {
    return [
      AUTO,
      MANUAL,
      NO_TAX_CALCULATION
    ];
  }
}

export { ETaxCalculationType }