/* tslint:disable */
type EPayType =
  'Daily' |
  'Monthly';
module EPayType {
  export const DAILY: EPayType = 'Daily';
  export const MONTHLY: EPayType = 'Monthly';
  export function values(): EPayType[] {
    return [
      DAILY,
      MONTHLY
    ];
  }
}

export { EPayType }