<div *ngFor="let afield of field.fieldGroup; let i = index" class="grid align-start">
  <div [class]="to.labelClass" style="text-align: right; padding-top: 14px">
    <label *ngIf="i === 0">{{ to.label }} <span *ngIf="to.required">*</span></label>
  </div>
  <div [class]="to.inputClass">
    <formly-field [field]="afield"></formly-field>
  </div>
  <div *ngIf="!(i === 0 && to.required)" class="col-fixed icon-remove">
    <i class="fa fa-trash" (click)="remove(i)"></i>
  </div>
</div>
<div class="grid align-center">
  <div [class]="to.labelClass" style="text-align: right">
    <label *ngIf="field.fieldGroup.length === 0">{{ to.label }} <span *ngIf="to.required">*</span></label>
  </div>
  <div class="col-fixed">
    <button pButton type="button" [label]="to.addText" (click)="add()"></button>
  </div>
</div>
