import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input,OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize, map } from 'rxjs/operators';
import { BenefitElementModel,EmployeeSumBenefitModel } from 'app/api/models';
import { BenefitElementService, BenefitEmployeeMonitorService,DocumentService, MasterDataService,UserService } from 'app/api/services';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';

@Component({
  selector: 'app-modal-sum-benefit',
  templateUrl: './modal-sum-benefit.component.html',
  styleUrls: ['./modal-sum-benefit.component.css'],
})
export class ModalSumBenefitComponent implements OnInit {
  employeeSumBenefitList: EmployeeSumBenefitModel[];
  employeeId: number;
  loading: boolean;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private benefitElementService: BenefitElementService,
    private benefitEmployeeMonitorService: BenefitEmployeeMonitorService,
    private documentService: DocumentService,
    private masterDataRepo: MasterDataRepo,
    private userService: UserService,
    private masterDataService: MasterDataService
  ) {}

  async ngOnInit() {
    
    this.initComponentMode();
    this.employeeId = +this.config.data?.employeeId;
    await this.initData();
  }


  initComponentMode() {
    // const benefitElementId = this.getBenefitElementId();

    // if (benefitElementId) {
    //   this.componentMode = ComponentMode.UPDATE;
    //   this.fields.find(a => a.key === 'isYearly').templateOptions.disabled = true;
    // } else {
    //   this.componentMode = ComponentMode.CREATE;
    // }
  }

  async initData() {
    this.loading = true;

    this.benefitEmployeeMonitorService
    .GetEmployeeSumBenefit(this.employeeId)
    .subscribe(
      
      results => {
        this.employeeSumBenefitList = results.map(emp => {
          return {
            benefitElementName: emp.benefitElementName,
              effectiveDate: emp.effectiveDate,
              totalQuota: emp.totalQuota,
              quotaUsed: emp.quotaUsed,
              quotaBal: emp.quotaBal,
            };
        });
      
        // const values = Object.values(this.employeeSumBenefitList);
        // console.log(values);
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        // FIXME: handle err
        this.employeeSumBenefitList = [];
      },
    );
  }

  close() {
    this.ref.close();
  }

}
