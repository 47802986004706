/* tslint:disable */
type EAttendanceCertifyType =
  'NA' |
  'Detailed' |
  'Brief';
module EAttendanceCertifyType {
  export const NA: EAttendanceCertifyType = 'NA';
  export const DETAILED: EAttendanceCertifyType = 'Detailed';
  export const BRIEF: EAttendanceCertifyType = 'Brief';
  export function values(): EAttendanceCertifyType[] {
    return [
      NA,
      DETAILED,
      BRIEF
    ];
  }
}

export { EAttendanceCertifyType }