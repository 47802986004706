<div #blockTarget class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <h3>ข้อมูลบันทึกการเข้าออกงาน วันที่ {{ selectedDate | date: 'dd/MM/yyyy' }}</h3>
      <div class="grid">
        <div class="col-fixed">
          <p-button class="p-button-info" (click)="calculateTimesheet()">คำนวณใหม่</p-button>
        </div>
      </div>
      <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0"
        [value]="timeEvents">
        <ng-template pTemplate="header">
          <tr>
            <th>วันที่</th>
            <th>เวลา</th>
            <th>ที่มาของข้อมูล</th>
            <th>ประเภท</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td>{{ rowData.eventTime | date: 'dd/MM/yyyy' }}</td>
            <td>{{ rowData.eventTime | date: 'HH:mm'}}</td>
            <td>{{ rowData.sourceId }} </td>
            <td>{{ rowData.timeEventType }} </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<div class="p-dialog-footer">
  <button type="button" pButton class="p-button-secondary" label="{{'close' | translate}}" (click)="close()"></button>
</div>
