/* tslint:disable */
type ERelativeInfoStatus =
  'HaveInfo' |
  'Dead' |
  'Unknown';
module ERelativeInfoStatus {
  export const HAVE_INFO: ERelativeInfoStatus = 'HaveInfo';
  export const DEAD: ERelativeInfoStatus = 'Dead';
  export const UNKNOWN: ERelativeInfoStatus = 'Unknown';
  export function values(): ERelativeInfoStatus[] {
    return [
      HAVE_INFO,
      DEAD,
      UNKNOWN
    ];
  }
}

export { ERelativeInfoStatus }