import {  FormControl, ValidationErrors } from "@angular/forms";

export function ThaiIDValidator(control: FormControl): ValidationErrors {
    const strID = (control.value+"").replace('-','')
    const matchID = strID.match(/(\d{12})(\d)/)
    if (matchID) {
        const digits = matchID[1].split('');
        const sum = digits.reduce((total: number, digit: string, i: number) => {
        return total + (13 - i) * +digit;
        }, 0)
        const lastDigit = `${(11 - sum % 11) % 10}`
        const inputLastDigit = matchID[2]
        if (lastDigit == inputLastDigit) {
            return null
        }
    }
    
    return { fieldMatch: { message: 'ข้อมูลบัตรประชาชนไม่ถูกต้อง' } };
  }
  