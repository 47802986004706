/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ShiftModelPaginationResult } from '../models/shift-model-pagination-result';
import { ShiftModel } from '../models/shift-model';
@Injectable({
  providedIn: 'root',
})
class ShiftService extends __BaseService {
  static readonly GetShiftsListPath = '/api/Shift';
  static readonly CreateShiftPath = '/api/Shift';
  static readonly GetActiveShiftsListPath = '/api/Shift/active';
  static readonly GetShiftByIdPath = '/api/Shift/{shiftId}';
  static readonly UpdateShiftPath = '/api/Shift/{shiftId}';
  static readonly DeleteShiftPath = '/api/Shift/{shiftId}';
  static readonly ActivateShiftPath = '/api/Shift/{shiftId}/activation';
  static readonly GenerateDayOfShiftPath = '/api/Shift/{shiftId}/dayOfShift';
  static readonly GenerateTimesheetPath = '/api/Shift/{shiftId}/timesheet';
  static readonly GenerateAllTimeSheetPath = '/api/Shift/run/GenerateAllTimeSheet';
  static readonly GenerateAllDayOfShiftPath = '/api/Shift/run/GenerateAllDayOfShift';
  static readonly ClearAllDayOfShiftPath = '/api/Shift/run/ClearAllDayOfShift';
  static readonly GenerateRetrospectiveTimesheetPath = '/api/Shift/{companyId}/retrospective/timesheet';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShiftService.GetShiftsListParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetShiftsListResponse(params: ShiftService.GetShiftsListParams): __Observable<__StrictHttpResponse<ShiftModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Shift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShiftModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `ShiftService.GetShiftsListParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetShiftsList(params: ShiftService.GetShiftsListParams): __Observable<ShiftModelPaginationResult> {
    return this.GetShiftsListResponse(params).pipe(
      __map(_r => _r.body as ShiftModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateShiftResponse(body?: ShiftModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Shift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateShift(body?: ShiftModel): __Observable<string> {
    return this.CreateShiftResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ShiftService.GetActiveShiftsListParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetActiveShiftsListResponse(params: ShiftService.GetActiveShiftsListParams): __Observable<__StrictHttpResponse<ShiftModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Shift/active`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShiftModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `ShiftService.GetActiveShiftsListParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetActiveShiftsList(params: ShiftService.GetActiveShiftsListParams): __Observable<ShiftModelPaginationResult> {
    return this.GetActiveShiftsListResponse(params).pipe(
      __map(_r => _r.body as ShiftModelPaginationResult)
    );
  }

  /**
   * @param shiftId undefined
   * @return Success
   */
  GetShiftByIdResponse(shiftId: number): __Observable<__StrictHttpResponse<ShiftModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Shift/${encodeURIComponent(String(shiftId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShiftModel>;
      })
    );
  }
  /**
   * @param shiftId undefined
   * @return Success
   */
  GetShiftById(shiftId: number): __Observable<ShiftModel> {
    return this.GetShiftByIdResponse(shiftId).pipe(
      __map(_r => _r.body as ShiftModel)
    );
  }

  /**
   * @param params The `ShiftService.UpdateShiftParams` containing the following parameters:
   *
   * - `shiftId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateShiftResponse(params: ShiftService.UpdateShiftParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Shift/${encodeURIComponent(String(params.shiftId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ShiftService.UpdateShiftParams` containing the following parameters:
   *
   * - `shiftId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateShift(params: ShiftService.UpdateShiftParams): __Observable<string> {
    return this.UpdateShiftResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param shiftId undefined
   * @return Success
   */
  DeleteShiftResponse(shiftId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Shift/${encodeURIComponent(String(shiftId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param shiftId undefined
   * @return Success
   */
  DeleteShift(shiftId: number): __Observable<string> {
    return this.DeleteShiftResponse(shiftId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param shiftId undefined
   * @return Success
   */
  ActivateShiftResponse(shiftId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Shift/${encodeURIComponent(String(shiftId))}/activation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param shiftId undefined
   * @return Success
   */
  ActivateShift(shiftId: number): __Observable<string> {
    return this.ActivateShiftResponse(shiftId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ShiftService.GenerateDayOfShiftParams` containing the following parameters:
   *
   * - `shiftId`:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  GenerateDayOfShiftResponse(params: ShiftService.GenerateDayOfShiftParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Shift/${encodeURIComponent(String(params.shiftId))}/dayOfShift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ShiftService.GenerateDayOfShiftParams` containing the following parameters:
   *
   * - `shiftId`:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  GenerateDayOfShift(params: ShiftService.GenerateDayOfShiftParams): __Observable<string> {
    return this.GenerateDayOfShiftResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ShiftService.GenerateTimesheetParams` containing the following parameters:
   *
   * - `shiftId`:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  GenerateTimesheetResponse(params: ShiftService.GenerateTimesheetParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Shift/${encodeURIComponent(String(params.shiftId))}/timesheet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ShiftService.GenerateTimesheetParams` containing the following parameters:
   *
   * - `shiftId`:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  GenerateTimesheet(params: ShiftService.GenerateTimesheetParams): __Observable<string> {
    return this.GenerateTimesheetResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ShiftService.GenerateAllTimeSheetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  GenerateAllTimeSheetResponse(params: ShiftService.GenerateAllTimeSheetParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Shift/run/GenerateAllTimeSheet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ShiftService.GenerateAllTimeSheetParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  GenerateAllTimeSheet(params: ShiftService.GenerateAllTimeSheetParams): __Observable<string> {
    return this.GenerateAllTimeSheetResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ShiftService.GenerateAllDayOfShiftParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  GenerateAllDayOfShiftResponse(params: ShiftService.GenerateAllDayOfShiftParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Shift/run/GenerateAllDayOfShift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ShiftService.GenerateAllDayOfShiftParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return Success
   */
  GenerateAllDayOfShift(params: ShiftService.GenerateAllDayOfShiftParams): __Observable<string> {
    return this.GenerateAllDayOfShiftResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param secret undefined
   * @return Success
   */
  ClearAllDayOfShiftResponse(secret?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (secret != null) __params = __params.set('secret', secret.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Shift/run/ClearAllDayOfShift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param secret undefined
   * @return Success
   */
  ClearAllDayOfShift(secret?: string): __Observable<string> {
    return this.ClearAllDayOfShiftResponse(secret).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ShiftService.GenerateRetrospectiveTimesheetParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `startDate`:
   *
   * @return Success
   */
  GenerateRetrospectiveTimesheetResponse(params: ShiftService.GenerateRetrospectiveTimesheetParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Shift/${encodeURIComponent(String(params.companyId))}/retrospective/timesheet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ShiftService.GenerateRetrospectiveTimesheetParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `startDate`:
   *
   * @return Success
   */
  GenerateRetrospectiveTimesheet(params: ShiftService.GenerateRetrospectiveTimesheetParams): __Observable<string> {
    return this.GenerateRetrospectiveTimesheetResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module ShiftService {

  /**
   * Parameters for GetShiftsList
   */
  export interface GetShiftsListParams {
    Status?: any;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    CompanyId?: number;
  }

  /**
   * Parameters for GetActiveShiftsList
   */
  export interface GetActiveShiftsListParams {
    Status?: any;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    CompanyId?: number;
  }

  /**
   * Parameters for UpdateShift
   */
  export interface UpdateShiftParams {
    shiftId: number;
    body?: ShiftModel;
  }

  /**
   * Parameters for GenerateDayOfShift
   */
  export interface GenerateDayOfShiftParams {
    shiftId: number;
    startDate?: string;
    endDate?: string;
  }

  /**
   * Parameters for GenerateTimesheet
   */
  export interface GenerateTimesheetParams {
    shiftId: number;
    startDate?: string;
    endDate?: string;
  }

  /**
   * Parameters for GenerateAllTimeSheet
   */
  export interface GenerateAllTimeSheetParams {
    startDate?: string;
    endDate?: string;
  }

  /**
   * Parameters for GenerateAllDayOfShift
   */
  export interface GenerateAllDayOfShiftParams {
    startDate?: string;
    endDate?: string;
  }

  /**
   * Parameters for GenerateRetrospectiveTimesheet
   */
  export interface GenerateRetrospectiveTimesheetParams {
    companyId: number;
    startDate?: string;
  }
}

export { ShiftService }
