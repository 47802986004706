import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeCreateFormAddressInfoComponent } from './employee-create-form-address-info/employee-create-form-address-info.component';
import { EmployeeCreateFormAttachFileComponent } from './employee-create-form-attach-file/employee-create-form-attach-file.component';
import { EmployeeCreateFormChildComponent } from './employee-create-form-child/employee-create-form-child.component';
import { EmployeeCreateFormEducationComponent } from './employee-create-form-education/employee-create-form-education.component';
import { EmployeeCreateFormEmergencyComponent } from './employee-create-form-emergency/employee-create-form-emergency.component';
import { EmployeeCreateFormEmploymentInformationComponent } from './employee-create-form-employment-information/employee-create-form-employment-information.component';
import { EmployeeCreateFormExperienceComponent } from './employee-create-form-experience/employee-create-form-experience.component';
import { EmployeeCreateFormFamilyInfoComponent } from './employee-create-form-family-info/employee-create-form-family-info.component';
import { EmployeeCreateFormOtherInfoComponent } from './employee-create-form-other-info/employee-create-form-other-info.component';
import { EmployeeCreateFormPersonalInfoComponent } from './employee-create-form-personal-info/employee-create-form-personal-info.component';
import { EmployeeCreateComponent } from './employee-create.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { ModalsModule } from '../../../../../modals/modals.module';
import { RepoModule } from '../../../../../repo/repo.module';
import { ComponentsModule } from '../../../../../components/components.module';


const components = [
  EmployeeCreateFormAddressInfoComponent,
  EmployeeCreateFormAttachFileComponent,
  EmployeeCreateFormChildComponent,
  EmployeeCreateFormEducationComponent,
  EmployeeCreateFormEmergencyComponent,
  EmployeeCreateFormEmploymentInformationComponent,
  EmployeeCreateFormExperienceComponent,
  EmployeeCreateFormFamilyInfoComponent,
  EmployeeCreateFormOtherInfoComponent,
  EmployeeCreateFormPersonalInfoComponent,
  EmployeeCreateComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    SharedModule,
    ModalsModule,
    RepoModule,
    ComponentsModule,
  ],
  exports: [...components]
})
export class EmployeeCreateModule { }
