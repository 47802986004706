/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CostCenterModelPaginationResult } from '../models/cost-center-model-pagination-result';
import { CostCenterModel } from '../models/cost-center-model';
@Injectable({
  providedIn: 'root',
})
class CostCenterService extends __BaseService {
  static readonly GetCostCentersPath = '/api/CostCenter';
  static readonly CreateCostCenterPath = '/api/CostCenter';
  static readonly GetCostCenterPath = '/api/CostCenter/{id}';
  static readonly UpdateCostCenterPath = '/api/CostCenter/{id}';
  static readonly DeleteCostCenterPath = '/api/CostCenter/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CostCenterService.GetCostCentersParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetCostCentersResponse(params: CostCenterService.GetCostCentersParams): __Observable<__StrictHttpResponse<CostCenterModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CostCenter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CostCenterModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `CostCenterService.GetCostCentersParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetCostCenters(params: CostCenterService.GetCostCentersParams): __Observable<CostCenterModelPaginationResult> {
    return this.GetCostCentersResponse(params).pipe(
      __map(_r => _r.body as CostCenterModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateCostCenterResponse(body?: CostCenterModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CostCenter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateCostCenter(body?: CostCenterModel): __Observable<string> {
    return this.CreateCostCenterResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetCostCenterResponse(id: number): __Observable<__StrictHttpResponse<CostCenterModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CostCenter/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CostCenterModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetCostCenter(id: number): __Observable<CostCenterModel> {
    return this.GetCostCenterResponse(id).pipe(
      __map(_r => _r.body as CostCenterModel)
    );
  }

  /**
   * @param params The `CostCenterService.UpdateCostCenterParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateCostCenterResponse(params: CostCenterService.UpdateCostCenterParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/CostCenter/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `CostCenterService.UpdateCostCenterParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateCostCenter(params: CostCenterService.UpdateCostCenterParams): __Observable<string> {
    return this.UpdateCostCenterResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteCostCenterResponse(id: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/CostCenter/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  DeleteCostCenter(id: number): __Observable<string> {
    return this.DeleteCostCenterResponse(id).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module CostCenterService {

  /**
   * Parameters for GetCostCenters
   */
  export interface GetCostCentersParams {
    companyId?: number;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for UpdateCostCenter
   */
  export interface UpdateCostCenterParams {
    id: number;
    body?: CostCenterModel;
  }
}

export { CostCenterService }
