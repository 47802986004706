import { ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UnitTreeNodeModel } from '../../api/models';
import { UnitTreeNodeService } from '../../api/services';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subject } from 'rxjs';
import { ModalPositionSearchComponent } from 'modals/core/modal-position-search/modal-position-search.component';
import { ModalUnitSearchComponent, UnitTreeNode } from 'modals/core/modal-unit-search/modal-unit-search.component';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MenuItem, TreeNode } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-select-unit',
  templateUrl: './formly-select-unit.component.html',
  styleUrls: ['./formly-select-unit.component.css'],
  providers: [DialogService],
})
export class FormlySelectUnitComponent extends FieldType implements OnInit {
  readonly mainLabel = 'เลือกหน่วยงาน';
  items: MenuItem[];
  ref: DynamicDialogRef;
  label = this.mainLabel;

  units: TreeNode[] = [];
  cols = [
    { field: 'name', header: 'ชื่อหน่วยงาน' },
    { field: 'unitLevel', header: 'ระดับหน่วยงาน' },
    { field: 'company', header: 'บริษัท' },
  ];
  updateUnitEvent = new EventEmitter<number>();
  companyId: number;
  asAofDate: string;
  isMultiSelectMode = false;
  selectedUnitIds: number[] = [];

  constructor(private unitTreeNodeService: UnitTreeNodeService, private dialogService: DialogService) {
    super();
  }

  findTreeNode(id: number, units: TreeNode[]): TreeNode {
    for (const unit of units) {
      if (unit.data.id === id) {
        return unit;
      }
      if (unit.children) {
        const foundNode = this.findTreeNode(id, unit.children);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  }

  mapUnitToTreeNode(a: UnitTreeNodeModel): TreeNode {
    const node: TreeNode = {
      label: a.name,
      data: {
        id: a.unitId,
        name: a.name,
        unitLevel: a.levelName,
        fullName: a.fullname,
        company: a.company,
      },
    };
    node.children = a.children.map(b => this.mapUnitToTreeNode(b));
    return node;
  }

  ngOnInit(): void {
    this.isMultiSelectMode = !!this.to.isMultiSelectMode;
    this.items = [
      {
        label: 'ล้าง',
        icon: 'pi pi-refresh',
        command: () => {
          if (this.isMultiSelectMode) {
            this.select({}, {}, []);
          } else {
            this.select({}, {});
          }
        },
      },
    ];
    this.updateUnitEvent.pipe(debounceTime(300)).subscribe(() => {
      this.setUnits();
    });
    this.subCompanyChange();

    const formValue = this.formControl.value;
    if (formValue) {
      if (this.isMultiSelectMode) {
        this.selectedUnitIds = formValue;
      } else {
        this.label = formValue;
      }
    } else {
      this.label = this.mainLabel;
    }
  }

  async setUnits() {
    this.units = [];
    if (this.to.isWaitCompanyId) {
      return;
    }

    let units = await this.unitTreeNodeService.GetUnitTreeNode({
      companyId: this.companyId, asOfDate: this.asAofDate
    }).toPromise();

    this.units = units.map(b => this.mapUnitToTreeNode(b));

    const formValue = this.formControl.value;
    if (formValue) {
      if (this.isMultiSelectMode) {
        this.selectedUnitIds = formValue;
      } else {
        const node = this.findTreeNode(formValue, this.units);
        if (node) {
          this.label = node.data.fullName;
        }
      }
    } else {
      this.label = this.mainLabel;
    }
  }

  select(event, selectNode: TreeNode, selectedUnitIds: number[] = []) {
    let value: any;

    if (this.isMultiSelectMode) {
      value = selectedUnitIds;
      this.selectedUnitIds = value;
    } else {
      this.label = selectNode.data?.fullName ?? this.mainLabel;
      value = selectNode.data?.id;
    }

    this.formControl.setValue(value);
    this.form.markAsDirty();

    if (typeof this.to?.change === 'function') {
      this.to.change(this, { event, value });
    }
  }

  subCompanyChange() {
    if (this.to?.companyChange instanceof EventEmitter || this.to?.companyChange instanceof Subject) {
      this.to.companyChange.subscribe(companyId => {
        this.to.isWaitCompanyId = false;
        this.companyId = companyId;
        this.updateUnitEvent.emit();
      });
    }

    if (this.to?.asAofDateChange instanceof EventEmitter || this.to?.asAofDateChange instanceof Subject) {
      this.to.asAofDateChange.subscribe(date => {
        // console.log(date);
        this.asAofDate = date;
        this.updateUnitEvent.emit();
      });
    }
  }

  async showUnitSearchModal() {
    await this.setUnits();

    this.ref = this.dialogService.open(ModalUnitSearchComponent, {
      header: 'ค้นหาหน่วยงาน',
      width: '70%',
      dismissableMask: false,
      contentStyle: { overflow: 'auto' },
      data: {
        units: this.units,
        isMultiSelectMode: this.isMultiSelectMode,
        selectedUnitIds: [...this.selectedUnitIds]
      },
    });

    this.ref.onClose.subscribe((value) => {
      if (value) {
        this.select(undefined, value, value);
      }
    });
  }
}
