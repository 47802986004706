<div class="grid align-center">
  <div *ngIf="to.label && to.hideLabel !== true" [class]="to.labelClass" style="text-align: right">
    <label>
      {{ to.label }}
    </label>
  </div>
  <div [class]="to.inputClass">
    <div class="grid">
      <diV></diV>
      <img *ngIf="to.isDisplayImage&&displayedImage!=''" src="{{displayedImage}}" width="140px" />
      <div class="col">
        <p-fileUpload
          #fileUpload
          [mode]="to.mode"
          [multiple]="to.multiple || false"
          [accept]="to.accept"
          [maxFileSize]="to.maxFileSize || 26214400"
          [chooseLabel]="to.chooseLabel || 'Choose'"
          (onSelect)="to.onSelect ? to.onSelect($event, model) : undefined"
          (onRemove)="to.onRemove ? to.onRemove($event, model, file.documentId) : undefined"
          [showCancelButton]="false"
          [showUploadButton]="false"
          [fileLimit]="to.fileLimit || 1"
          [previewWidth]="to.previewWidth || 50"
          [disabled]="to.disabled"
        >
          <ng-template pTemplate="content">
            <img *ngIf="to.showDefaultImage" src="/api/document/{{to.value}}/download" width="140px" />
            <div class="p-fileupload-row" *ngIf="file.documentId">
              <div>
                <a *ngIf="file.documentId" href="/api/document/{{ file.documentId }}/download">download</a>
              </div>
            </div>
          </ng-template>
        </p-fileUpload>
      </div>
      <p-button class="col-fixed" *ngIf="to.mode == 'basic'" (click)="clear()">
        Clear
      </p-button>
    </div>
    <div class="grid">
      <div class="row" *ngIf="file.documentId">
        <div class="col">
          <a *ngIf="file.documentId" href="/api/document/{{ file.documentId }}/download" style="text-decoration:underline;">{{model.documentName}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
