/* tslint:disable */
type EFileProcessingStatus =
  'Process' |
  'Success' |
  'Error';
module EFileProcessingStatus {
  export const PROCESS: EFileProcessingStatus = 'Process';
  export const SUCCESS: EFileProcessingStatus = 'Success';
  export const ERROR: EFileProcessingStatus = 'Error';
  export function values(): EFileProcessingStatus[] {
    return [
      PROCESS,
      SUCCESS,
      ERROR
    ];
  }
}

export { EFileProcessingStatus }