import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface PersonnelAreaTreeNode extends TreeNode {
  data?: {
    id: number;
    name: string;
  };
  children?: PersonnelAreaTreeNode[];
}

@Component({
  selector: 'app-modal-personnel-area-search',
  templateUrl: './modal-personnel-area-search.component.html',
  styleUrls: ['./modal-personnel-area-search.component.css'],
})
export class ModalPersonnelAreaSearchComponent implements OnInit {
  personnelAreas: PersonnelAreaTreeNode[];
  cols = [
    { field: 'name', header: 'ตามโครงสร้างหน่วยงาน' },
    { field: 'company', header: 'บริษัท' },
  ];

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.personnelAreas = this.config.data?.personnelAreas || [];
  }

  select(event, selectNode: PersonnelAreaTreeNode) {
    this.ref.close(selectNode);
  }
}
