/* tslint:disable */
type EJobWorkType =
  'Primary' |
  'Secondary' |
  'Special';
module EJobWorkType {
  export const PRIMARY: EJobWorkType = 'Primary';
  export const SECONDARY: EJobWorkType = 'Secondary';
  export const SPECIAL: EJobWorkType = 'Special';
  export function values(): EJobWorkType[] {
    return [
      PRIMARY,
      SECONDARY,
      SPECIAL
    ];
  }
}

export { EJobWorkType }