<div [ngStyle]="style">
  <ag-grid-angular
    #agGrid
    style="width: 100%; height: 100%"
    class="ag-theme-alpine"
    [gridOptions]="gridOptions"
    [rowData]="model"
    (firstDataRendered)="onFirstDataRendered($event)">
  </ag-grid-angular>
</div>
