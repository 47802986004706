import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PayrollPeriodModel } from '../../../api/models';
import { CompanyService, MasterDataService, NotificationService, PayrollPeriodService } from '../../../api/services';
import { AppNotificationServiceService } from '../../../shared/app-notification-service.service';
import { getMonthOptionList, getYearOptionList } from '../../../shared/defaultValues';
import { map } from 'rxjs/operators';

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof PayrollPeriodModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-modal-payroll-period-edit',
  templateUrl: './modal-payroll-period-edit.component.html',
  styleUrls: ['./modal-payroll-period-edit.component.css']
})
export class ModalPayrollPeriodEditComponent implements OnInit {
  model: PayrollPeriodModel;
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'wagePayType',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ประเภทการจ่าย:',
        placeholder: 'ประเภทการจ่าย',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: true,
        options: this.masterDataService.GetWagePayTypes().pipe(map(a => a.map(b => ({ label: b.name, value: b.wagePayType })))),
      },
    },
    {
      key: 'companyId',
      type: 'filter-dropdown',
      defaultValue: new Date().getFullYear(),
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'company',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: this.companyService
          .GetCompaniesList({ ItemPerPage: 9999 })
          .pipe(map(a => a.results.map(b => ({ label: b.name, value: b.companyId }))))
      },
    },
    {
      key: 'year',
      type: 'filter-dropdown',
      defaultValue: new Date().getFullYear(),
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'year',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: getYearOptionList()
      },
    },
    {
      key: 'month',
      type: 'filter-dropdown',
      defaultValue: new Date().getMonth(),
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'month',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: getMonthOptionList()
      },
    },
    {
      key: 'no',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'no_number',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'paydate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'paydate',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6'
      },
    },
    {
      key: 'startSalaryDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'startSalaryDate',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6'
      },
    },
    {
      key: 'endSalaryDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'endSalaryDate',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6'
      },
    },
    {
      key: 'startTADate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'startTADate',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6'
      },
    },
    {
      key: 'endTADate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'endTADate',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6'
      },
    }
  ];
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private payrollPeriodService: PayrollPeriodService,
    private notificationService: AppNotificationServiceService,
    private masterDataService: MasterDataService,
    private companyService: CompanyService
  ) {

  }
  async getCompanyOptionList() {

  }
  async ngOnInit() {
    let periodId = this.config.data?.periodId;
    if (periodId) {
      this.model = await this.payrollPeriodService.GetById(periodId).toPromise();
    } else {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const currentDate = new Date().getDate();
      this.model = {
        year: currentYear,
        month: currentMonth,
        startSalaryDate: new Date(Date.UTC(currentYear, currentMonth, 1)).toISOString(),
        endSalaryDate: new Date(Date.UTC(currentYear, currentMonth + 1, 0)).toISOString(),
        startTADate: new Date(Date.UTC(currentYear, currentMonth - 1, 21)).toISOString(),
        endTADate: new Date(Date.UTC(currentYear, currentMonth, 20)).toISOString(),
        paydate: new Date(Date.UTC(currentYear, currentMonth + 1, 0)).toISOString()
      }
    }
  }
  async submit() {
    try {
      if (this.model.payrollPeriodId) {
        await this.payrollPeriodService.Update({
          id: this.model.payrollPeriodId,
          body: this.model
        }).toPromise();
      } else {
        await this.payrollPeriodService.Create({
          ...this.model
        }).toPromise();
      }
      this.notificationService.saveCompleted();
      this.close();
    } catch (error) {
      this.notificationService.error('บันทึกไม่สำเร็จ', 'กรุณาลองใหม่อีกครั้ง หรือติดต่อผู้ดูแลระบบ');
    }
  }
  close() {
    this.ref.close();
  }
}
