import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuIconComponent } from './menu-icon/menu-icon.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ModalsModule } from '../modals/modals.module';
import { SharedModule } from '../shared/shared.module';
import { TreeNodeComponent } from './tree-node/tree-node.component';
import { AttendanceCardComponent } from './ta/attendance-card/attendance-card.component';
import { RequestTimeGuaranteeDetailComponent } from './request-time-guarantee-detail/request-time-guarantee-detail.component';
import { RepoModule } from '../repo/repo.module';
import { EmployeeComponentsModule } from './core/employee-component.module';
import { FoundationComponentModule } from './foundation/FoundationComponent.module';

const components = [
  MenuIconComponent,
  TreeNodeComponent,
  AttendanceCardComponent,
  RequestTimeGuaranteeDetailComponent
];
const modules = [
  EmployeeComponentsModule,
  FoundationComponentModule
];

@NgModule({
  declarations: components,
  imports: [CommonModule, NgxPermissionsModule, ModalsModule, SharedModule, RepoModule, ...modules],
  exports: [...modules, ...components],
})
export class ComponentsModule {}
