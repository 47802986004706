import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { SwitchInputComponent } from './switch-input/switch-input.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { SelectPositionComponent } from './select-position/select-position.component';
import { AutoCompleteInputComponent } from './auto-complete-input/auto-complete-input.component';
import { FilterDropdownComponent } from './filter-dropdown/filter-dropdown.component';
import { FormlySelectModule as FormlyCoreSelectModule } from '@ngx-formly/core/select';
import { FormlyTimePickerComponent } from './formly-time-picker/formly-time-picker.component';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { PrimeNgComponentsModule } from '../shared/prime-ng-components.module';
// tslint:disable-next-line: max-line-length
import { FormlyRepeatSelectEmployeeSectionComponent } from './formly-repeat-select-employee-section/formly-repeat-select-employee-section.component';
// tslint:disable-next-line: max-line-length
import { RepeatBenefitPolicyRowComponent } from '../pages/admin/benefit/benefit-policy/repeat-benefit-policy-row/repeat-benefit-policy-row.component';
import { RepeatInputComponent } from './repeat-input/repeat-input.component';
import { FormlyMonthPickerComponent } from './formly-month-picker/formly-month-picker.component';
import { FormGroup, FormArray, ReactiveFormsModule } from '@angular/forms';
import { FormlyArrayComponent } from './formly-array/formly-array.component';
// tslint:disable-next-line: max-line-length
import { RepeatOtherValueSectionComponent } from '../pages/admin/core/employee/employee-create/employee-create-form-employment-information/repeat-other-value-section/repeat-other-value-section.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { FormlyAgGridComponent } from './formly-ag-grid/formly-ag-grid.component';
import { FormlyAgGridCellComponent } from './formly-ag-grid-cell/formly-ag-grid-cell.component';
import { SharedModule } from '../shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { CalendarComponent } from './calendar/calendar.component';
import { FormlySelectExComponent } from './formly-select-ex/formly-select-ex.component';
import { FormlyInlineLabelWrapperComponent } from './formly-inline-label-wrapper/formly-inline-label-wrapper.component';
import { FormlyRepeatSectionComponent } from './formly-repeat-section/formly-repeat-section.component';
import { FormlySelectUnitComponent } from './formly-select-unit/formly-select-unit.component';
import { fieldMatchValidator } from './fieldMatchValidator';
import { maxValidationMessages, minlengthValidationMessages, minValidationMessages } from './validationMessages';
import { FormlySelectEmployeeComponent } from './formly-select-employee/formly-select-employee.component';
import { PasswordValidator } from './password.validator';
// tslint:disable-next-line: max-line-length
import { FormlyRepeatConditionComponent } from '../pages/admin/core/core-setting/workflow-condition/workflow-condition-detail/condition/formly-repeat-condition/formly-repeat-condition.component';
// tslint:disable-next-line: max-line-length
import { WorkflowStepActionComponent } from '../pages/admin/core/core-setting/manage-workflow/manage-workflow-detail/workflow-step/workflow-step-action/workflow-step-action.component';
// tslint:disable-next-line: max-line-length
import { WorkflowStepActorComponent } from '../pages/admin/core/core-setting/manage-workflow/manage-workflow-detail/workflow-step/workflow-step-actor/workflow-step-actor.component';
import { FormlyPrimengInputComponent } from './formly-primeng-input/formly-primeng-input.component';
import { EmailValidator } from './email.validator';
import { FormlyPrimengCheckboxComponent } from './formly-primeng-checkbox/formly-primeng-checkbox.component';
import { RepeatRequestTimeFormComponent } from 'pages/employee/ta/request-time/repeat-request-time-form/repeat-request-time-form.component';
// tslint:disable-next-line: max-line-length
import { RepeatAttendanceCertifySectionComponent } from 'pages/admin/ta/attendance-certify/repeat-attendance-certify-section/repeat-attendance-certify-section.component';
import { RepeatLeaveFormComponent } from 'pages/admin/ta/leave/repeat-leave-form/repeat-leave-form.component';
import { IntegerValidator } from './integer.validator';
import { RepeatCreateShiftsFormComponent } from 'pages/admin/ta/shifts/repeat-create-shifts-form/repeat-create-shifts-form.component';
import { FormlySelectPositionComponent } from './formly-select-position/formly-select-position.component';
import { FormlySelectPersonnelAreaComponent } from './formly-select-personnel-area/formly-select-personnel-area.component';
import { FormlyAboveLabelWrapperComponent } from './formly-above-label-wrapper/formly-above-label-wrapper.component';
import { SelectShiftComponent } from './select-shift/select-shift.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyTimeDropdownComponent } from './formly-time-dropdown/formly-time-dropdown.component';
import { FormlyAddPositionComponent } from './formly-add-position/formly-add-position.component';
import { FormlyBlockLabelWrapperComponent } from './formly-block-label-wrapper/formly-block-label-wrapper.component';
import { ThaiIDValidator } from './ThaiID.validator';

export let FormlyTemplateConfig = {
  types: [
    { name: 'ag-grid', component: FormlyAgGridComponent },
    { name: 'auto-complete', component: AutoCompleteInputComponent },
    { name: 'calendar', component: CalendarComponent },
    { name: 'filter-dropdown', component: FilterDropdownComponent, wrappers: ['form-field'] },
    { name: 'formly-array', component: FormlyArrayComponent },
    { name: 'month-picker', component: FormlyMonthPickerComponent },
    { name: 'multi-select', component: MultiSelectComponent },
    { name: 'primeng-checkbox', component: FormlyPrimengCheckboxComponent },
    { name: 'primeng-input', component: FormlyPrimengInputComponent, wrappers: ['form-field'] },
    { name: 'repeat-admin-request-time-attancedance-certify', component: RepeatAttendanceCertifySectionComponent },
    { name: 'repeat-benefit', component: RepeatBenefitPolicyRowComponent },
    { name: 'repeat-condition', component: FormlyRepeatConditionComponent },
    { name: 'repeat-create-shifts-form', component: RepeatCreateShiftsFormComponent },
    { name: 'repeat-input', component: RepeatInputComponent },
    { name: 'repeat-leave-form', component: RepeatLeaveFormComponent },
    { name: 'repeat-my-request-time-attancedance-certify', component: RepeatRequestTimeFormComponent },
    { name: 'repeat-other-value-salary', component: RepeatOtherValueSectionComponent },
    { name: 'repeat-select-boss', component: FormlyRepeatSelectEmployeeSectionComponent },
    { name: 'repeat', component: FormlyRepeatSectionComponent },
    { name: 'select-employee', component: FormlySelectEmployeeComponent },
    { name: 'select-ex', component: FormlySelectExComponent },
    { name: 'select-personnel-area', component: FormlySelectPersonnelAreaComponent },
    { name: 'select-position-2', component: FormlySelectPositionComponent },
    { name: 'select-position', component: SelectPositionComponent },
    { name: 'select-shift', component: SelectShiftComponent },
    { name: 'select-unit', component: FormlySelectUnitComponent },
    { name: 'switch-input', component: SwitchInputComponent },
    { name: 'time-picker', component: FormlyTimePickerComponent },
    { name: 'upload-file', component: UploadFileComponent },
    { name: 'workflow-step-actions', component: WorkflowStepActionComponent },
    { name: 'time-dropdown', component: FormlyTimeDropdownComponent },
    { name: 'add-position', component: FormlyAddPositionComponent }
  ],
  wrappers: [
    { name: 'above-label', component: FormlyAboveLabelWrapperComponent },
    { name: 'inline-label', component: FormlyInlineLabelWrapperComponent },
    { name: 'block-label', component: FormlyBlockLabelWrapperComponent },
    { name: 'workflow-step-actor', component: WorkflowStepActorComponent },
  ],
  validators: [
    { name: 'email', validation: EmailValidator },
    { name: 'fieldMatch', validation: fieldMatchValidator },
    { name: 'integer', validation: IntegerValidator },
    { name: 'password', validation: PasswordValidator },
    { name: 'thaiID', validation: ThaiIDValidator },
  ],
  validationMessages: [
    { name: 'max', message: maxValidationMessages },
    { name: 'min', message: minValidationMessages },
    { name: 'minlength', message: minlengthValidationMessages },
    { name: 'required', message: 'จำเป็นต้องกรอก' },
  ],
};

@NgModule({
  declarations: [
    FormlyRepeatSelectEmployeeSectionComponent,
    SwitchInputComponent,
    UploadFileComponent,
    SelectPositionComponent,
    AutoCompleteInputComponent,
    FilterDropdownComponent,
    FormlyTimePickerComponent,
    RepeatBenefitPolicyRowComponent,
    RepeatInputComponent,
    FormlyMonthPickerComponent,
    FormlyArrayComponent,
    RepeatOtherValueSectionComponent,
    MultiSelectComponent,
    FormlyAgGridComponent,
    FormlyAgGridCellComponent,
    CalendarComponent,
    FormlySelectExComponent,
    FormlyInlineLabelWrapperComponent,
    FormlyRepeatSectionComponent,
    FormlySelectUnitComponent,
    FormlySelectEmployeeComponent,
    FormlyRepeatConditionComponent,
    WorkflowStepActionComponent,
    WorkflowStepActorComponent,
    FormlyPrimengInputComponent,
    FormlyPrimengCheckboxComponent,
    RepeatRequestTimeFormComponent,
    RepeatAttendanceCertifySectionComponent,
    RepeatLeaveFormComponent,
    RepeatCreateShiftsFormComponent,
    FormlySelectPositionComponent,
    FormlySelectPersonnelAreaComponent,
    FormlyAboveLabelWrapperComponent,
    SelectShiftComponent,
    FormlyTimeDropdownComponent,
    FormlyAddPositionComponent,
    FormlyBlockLabelWrapperComponent
  ],
  imports: [
    ReactiveFormsModule,
    PrimeNgComponentsModule,
    FormlyPrimeNGModule,
    FormlyModule,
    CommonModule,
    AgGridModule,
    FormlyCoreSelectModule,
    NgbModule
  ],
  exports: [CalendarComponent],
})
export class FormlyTemplateModule { }
