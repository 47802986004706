<div class="grid">
  <div [class]="to.labelClass" style="text-align: right">
    <label>{{ to.label }}</label>
  </div>
  <div *ngIf="field.fieldGroup.length == 0" class="col-fixed">
    <button pButton type="button" label="เพิ่ม" (click)="add()"></button>
  </div>
  <div [class]="to.inputClass">
    <div class="grid" *ngFor="let afield of field.fieldGroup; let i = index">
      <div class="col-10">
        <formly-field [field]="buildFormlyFieldConfig(afield, i)"></formly-field>
      </div>
      <div class="col-2">
        <div class="grid">
          <div class="col-fixed">
            <button pButton type="button" label="ลบ" class="p-button-secondary" (click)="remove(i)"></button>
          </div>
          <div *ngIf="field.fieldGroup.length == i + 1" class="col-fixed">
            <button pButton type="button" label="เพิ่ม" (click)="add()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
