<div
  class="layout-sidebar no-print"
  [ngClass]="{ 'layout-sidebar-active': app.sidebarActive }"
  (click)="app.onSidebarClick($event)"
  (mouseover)="app.sidebarActive = true"
  (mouseleave)="app.sidebarActive = false"
  *ngIf="appSettings"
>
  <div class="sidebar-logo" style="margin: 15px">
    <div></div>
    <a routerLink="/">
      <div class="menu-logo" [ngStyle]="{'background-image': 'url(/api/document/' + appSettings.webLogoLargeImageId + '/download)'}"></div>
    </a>
  </div>

  <div class="layout-menu-container" (click)="onMenuClick()">
    <ul class="layout-menu">
      <ng-container *ngFor="let item of getMenuItems(); let i = index">
        <li app-menuitem [item]="item" [index]="i" [root]="true"></li>
      </ng-container>
    </ul>
  </div>
</div>
