import { saveAs } from 'file-saver';
import {
  Component,
  ElementRef,
  NgZone,
  asNativeElements,
  ChangeDetectorRef,
  OnInit,
  HostListener,
} from '@angular/core';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { PasswordModule, Password } from 'primeng/password';
import { CheckboxModule } from 'primeng/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ApplicationSettingsService } from '../../../../services/application-settings.service';
import { ViewUtilsService } from '../../../../services/view-utils.service';
import { GoogleAuthService } from 'app/services/google-auth.service';
import { finalize } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { CustomParamMap } from 'types/custom-param-map.type';

import { AppPlatform } from 'shared/globalUtils';
import { ApplicationSettingsExtended } from '../../../../api/models';
import { AppNotificationServiceService } from '../../../../shared/app-notification-service.service';

interface AuthenticationParams {
  cipherText?: string;
  IV?: string;
  loginSystem: string;
  platformType?: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit {
  username: string;
  password: string;
  activeTabIndex = 0;
  errorMessage: string;
  isGoogleAvailable = false;
  appSettings: ApplicationSettingsExtended;

  checked: boolean;
  property: Password;
  selectedValues: string[] = [];

  screenWidth = window.innerWidth;

  queryParams: AuthenticationParams = { loginSystem: 'Password' };

  constructor(
    private authenticationService: AuthenticationService,
    public viewUtilsService: ViewUtilsService,
    private appNotification: AppNotificationServiceService,
    public applicationSettingsService: ApplicationSettingsService,
    private router: Router,
    public googleAuthService: GoogleAuthService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    this.getQueryParams();
  }

  async ngOnInit() {
    this.googleAuthService.initGapiEvent.subscribe(() => {
      this.isGoogleAvailable = true;
    });

    this.appSettings = await this.applicationSettingsService.getApplicationSettings().toPromise();
    this.googleAuthService.loadAuth2(this.appSettings.googleAuthSettings.clientId);

    // login with google from mobile
    if (this.getQueryParams().loginSystem === 'Google') {
      this.autoLoginUsingGoogleAccountMobile();
    } else if (this.getQueryParams().loginSystem === 'Password') {
      this.autoLoginUsingPasswordMobile();
    }

    if (this.authenticationService.getCurrentUser()) {
      // auto redirect
      this.viewUtilsService.gotoHomePage();
    }
  }

  async loginUsingGoogleAccount() {
    const user = await this.googleAuthService.signIn();

    this.errorMessage = '';

    this.authenticationService
      .loginWithGoogleAccount(this.getLoginType(), user.getBasicProfile().getEmail(), user.getId())
      .subscribe(
        () => {
          // login success
          this.viewUtilsService.gotoHomePage();
        },
        error => {
          console.log(error);
          this.errorMessage = error.error.message;
        },
      );
  }

  autoLoginUsingGoogleAccountMobile() {
    this.authenticationService
      .loginWithGoogleAccountMobile(this.queryParams.cipherText, this.queryParams.IV)
      .subscribe(
        () => {
          // login success
          this.viewUtilsService.gotoHomePage();
        },
        error => {
          console.log(error);
          this.errorMessage = error.error.message;
          window.parent.postMessage(`Error: ${this.errorMessage}`, '*');
        },
      );
  }

  handleChange(e) {
    this.activeTabIndex = e.index;
  }

  loginUsingPassword() {
    this.errorMessage = '';

    this.authenticationService
      .loginWithPassword(this.getLoginType(), this.username, this.password)
      .subscribe(
        user => {
          // login success
          this.viewUtilsService.gotoHomePage();
        },
        error => {
          this.errorMessage = error.error.message;
          this.appNotification.error('ไม่สามารถเข้าสู่ระบบได้', this.errorMessage);
        },
      );
  }

  autoLoginUsingPasswordMobile() {
    this.errorMessage = '';

    this.authenticationService
      .loginWithPasswordMobile(this.queryParams.cipherText, this.queryParams.IV)
      .subscribe(
        user => {
          // load webpage
          // login success
          this.viewUtilsService.gotoHomePage();
        },
        error => {
          console.log(error);
          this.errorMessage = error.error.message;
          window.parent.postMessage(`Error: ${this.errorMessage}`, '*');
        },
      );
  }

  toggle() { }

  getLoginType() {
    let loginType = '';
    switch (this.activeTabIndex) {
      case 0:
        loginType = 'Employee';
        break;
      case 1:
        loginType = 'Admin';
        break;
    }

    return loginType;
  }

  getQueryParams() {
    const q = this.route.snapshot.queryParamMap;
    this.queryParams = {
      cipherText: q.get('cipherText'),
      IV: q.get('IV'),
      loginSystem: q.get('loginSystem'),
      platformType: q.get('platformType'),
    };
    console.log(q);

    return this.queryParams;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }
}
