<div #blockTarget class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <form [formGroup]="form">
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      </form>
    </div>
  </div>
</div>
<div class="p-dialog-footer">
  <button *ngIf="isCanCancel" type="button" pButton class="ui-button-danger" label="ยกเลิกคำขอรับรองเวลา" (click)="onCancelClicked()"></button>
  <!-- <button
    type="button"
    pButton
    class="p-button-primary"
    label="บันทึก"
    [disabled]="!form.valid"
    (click)="submit()"
  ></button> -->
  <button type="button" pButton class="ui-button-secondary" label="ปิด" (click)="close(false)"></button>
</div>
