/* tslint:disable */
type EEmployeeWarningLetterStatus =
  'Draft' |
  'Editing' |
  'Awaiting' |
  'Approved' |
  'Cancel' |
  'Reject';
module EEmployeeWarningLetterStatus {
  export const DRAFT: EEmployeeWarningLetterStatus = 'Draft';
  export const EDITING: EEmployeeWarningLetterStatus = 'Editing';
  export const AWAITING: EEmployeeWarningLetterStatus = 'Awaiting';
  export const APPROVED: EEmployeeWarningLetterStatus = 'Approved';
  export const CANCEL: EEmployeeWarningLetterStatus = 'Cancel';
  export const REJECT: EEmployeeWarningLetterStatus = 'Reject';
  export function values(): EEmployeeWarningLetterStatus[] {
    return [
      DRAFT,
      EDITING,
      AWAITING,
      APPROVED,
      CANCEL,
      REJECT
    ];
  }
}

export { EEmployeeWarningLetterStatus }