<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>Timeline</h1>
        <hr>
        <div class="card">
          <div class="timeline">
            <div class="container right">
              <div class="content">
                <h2>01/Apr/2015</h2>
                <p>เริ่มต้นทำงาน ตำแหน่ง พนักงานตรวจสอบผลิตภัณฑ์</p>
              </div>
            </div>
            <div class="container left">
              <div class="content">
                <h2>01/Aug/2016</h2>
                <p>อบรม สัมมนา การตรวจสอบผลิตภัณฑ์</p>
              </div>
            </div>
            <div class="container right">
              <div class="content">
                <h2>01/Apr/2015</h2>
                <p>เริ่มต้นทำงาน ตำแหน่ง พนักงานตรวจสอบผลิตภัณฑ์</p>
              </div>
            </div>
            <div class="container left">
              <div class="content">
                <h2>01/Aug/2016</h2>
                <p>อบรม สัมมนา การตรวจสอบผลิตภัณฑ์</p>
              </div>
            </div>
            <div class="container right">
              <div class="content">
                <h2>01/May/2017</h2>
                <p>เริ่มต้นทำงาน ตำแหน่ง พนักงานตรวจสอบผลิตภัณฑ์</p>
              </div>
            </div>
            <div class="container left">
              <div class="content">
                <h2>01/Aug/2017</h2>
                <p>อบรม สัมมนา การตรวจสอบผลิตภัณฑ์</p>
              </div>
            </div>
            <div class="container right">
              <div class="content">
                <h2>01/Jan/2018</h2>
                <p>เริ่มต้นทำงาน ตำแหน่ง พนักงานตรวจสอบผลิตภัณฑ์</p>
              </div>
            </div>
            <div class="container left">
              <div class="content">
                <h2>01/Jan/2019</h2>
                <p>อบรม สัมมนา การตรวจสอบผลิตภัณฑ์</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
