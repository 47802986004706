/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AnnouncementModelPaginationResult } from '../models/announcement-model-pagination-result';
import { AnnouncementModel } from '../models/announcement-model';
@Injectable({
  providedIn: 'root',
})
class AnnouncementService extends __BaseService {
  static readonly GetAnnouncementsPath = '/api/Announcement';
  static readonly CreateAnnouncementPath = '/api/Announcement';
  static readonly UpdateAnnouncementPath = '/api/Announcement';
  static readonly GetAnnouncementByIdPath = '/api/Announcement/{announcementId}';
  static readonly DeleteAnnouncementPath = '/api/Announcement/{announcementId}';
  static readonly PublishAnnouncementPath = '/api/Announcement/{announcementId}/publish';
  static readonly GetAnnouncementImagePath = '/api/Announcement/{announcementId}/image/{pictureId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AnnouncementService.GetAnnouncementsParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsPublish`:
   *
   * - `Headline`:
   *
   * - `Date`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  GetAnnouncementsResponse(params: AnnouncementService.GetAnnouncementsParams): __Observable<__StrictHttpResponse<AnnouncementModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsPublish != null) __params = __params.set('IsPublish', params.IsPublish.toString());
    if (params.Headline != null) __params = __params.set('Headline', params.Headline.toString());
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    (params.CompanyIds || []).forEach(val => {if (val != null) __params = __params.append('CompanyIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Announcement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnnouncementModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `AnnouncementService.GetAnnouncementsParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsPublish`:
   *
   * - `Headline`:
   *
   * - `Date`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  GetAnnouncements(params: AnnouncementService.GetAnnouncementsParams): __Observable<AnnouncementModelPaginationResult> {
    return this.GetAnnouncementsResponse(params).pipe(
      __map(_r => _r.body as AnnouncementModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateAnnouncementResponse(body?: AnnouncementModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Announcement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateAnnouncement(body?: AnnouncementModel): __Observable<number> {
    return this.CreateAnnouncementResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateAnnouncementResponse(body?: AnnouncementModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Announcement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateAnnouncement(body?: AnnouncementModel): __Observable<string> {
    return this.UpdateAnnouncementResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param announcementId undefined
   * @return Success
   */
  GetAnnouncementByIdResponse(announcementId: number): __Observable<__StrictHttpResponse<AnnouncementModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Announcement/${encodeURIComponent(String(announcementId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnnouncementModel>;
      })
    );
  }
  /**
   * @param announcementId undefined
   * @return Success
   */
  GetAnnouncementById(announcementId: number): __Observable<AnnouncementModel> {
    return this.GetAnnouncementByIdResponse(announcementId).pipe(
      __map(_r => _r.body as AnnouncementModel)
    );
  }

  /**
   * @param announcementId undefined
   * @return Success
   */
  DeleteAnnouncementResponse(announcementId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Announcement/${encodeURIComponent(String(announcementId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param announcementId undefined
   * @return Success
   */
  DeleteAnnouncement(announcementId: number): __Observable<string> {
    return this.DeleteAnnouncementResponse(announcementId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param announcementId undefined
   * @return Success
   */
  PublishAnnouncementResponse(announcementId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Announcement/${encodeURIComponent(String(announcementId))}/publish`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param announcementId undefined
   * @return Success
   */
  PublishAnnouncement(announcementId: number): __Observable<string> {
    return this.PublishAnnouncementResponse(announcementId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `AnnouncementService.GetAnnouncementImageParams` containing the following parameters:
   *
   * - `pictureId`:
   *
   * - `announcementId`:
   */
  GetAnnouncementImageResponse(params: AnnouncementService.GetAnnouncementImageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Announcement/${encodeURIComponent(String(params.announcementId))}/image/${encodeURIComponent(String(params.pictureId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AnnouncementService.GetAnnouncementImageParams` containing the following parameters:
   *
   * - `pictureId`:
   *
   * - `announcementId`:
   */
  GetAnnouncementImage(params: AnnouncementService.GetAnnouncementImageParams): __Observable<null> {
    return this.GetAnnouncementImageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AnnouncementService {

  /**
   * Parameters for GetAnnouncements
   */
  export interface GetAnnouncementsParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsPublish?: boolean;
    Headline?: string;
    Date?: string;
    CompanyIds?: Array<number>;
  }

  /**
   * Parameters for GetAnnouncementImage
   */
  export interface GetAnnouncementImageParams {
    pictureId: number;
    announcementId: number;
  }
}

export { AnnouncementService }
