import { Injectable } from '@angular/core';
import { MasterDataService } from 'app/api/services/master-data.service';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AmphoeModel, ProvinceModel, TambonModel, PermissionModel } from '../api/models';

interface Option {
  value: string | number;
  label: string;
  order?: number;
}

type Status = 'All' | 'Active' | 'InActive' | 'Draft';

@Injectable()
export class MasterDataRepo {
  private _MapProvince: { [id: number]: ProvinceModel } = {};
  private _MapProvince2: { [name: string]: ProvinceModel } = {};
  private _MapAmphoe: { [id: number]: AmphoeModel } = {};
  private _MapAmphoe2: { [name: string]: AmphoeModel } = {};
  // private _MapTambon: { [id: number]: TambonModel } = {};
  // private _MapTambon2: { [name: string]: TambonModel } = {};
  private cachedWorkflowStepActorTypes$: Observable<any>;
  private cachedWorkflowTypes$: Observable<any>;
  private cachedBenefitConditionTypes$: Observable<any>;
  private cachedBenefitUseTypes$: Observable<any>;
  private cachedWagePayTypes$: Observable<any>;
  private cachedOtTypes$: Observable<any>;
  private cachedWorkdayTypes$: Observable<any>;
  private cachedPayTypes$: Observable<any>;
  private cachedPenaltyTypes$: Observable<any>;
  private cachedRelativeInfoStatuses$: Observable<any>;
  private cachedBossPositionTypes$: Observable<any>;
  private cachedShiftsInHolidayTypes$: Observable<any>;
  private cachedProbationStatuses$: Observable<any>;
  private cachedPositionTakingTypes$: Observable<any>;
  private cachedPayAllowanceRounds$: Observable<any>;
  private cachedPayAllowanceTypes$: Observable<any>;
  private cachedShiftsInAllowanceTypes$: Observable<any>;
  private cachedLeaveTypes$: Observable<any>;
  private cachedMyLeaveTypes$: Observable<any>;
  private cachedLeaveTypesInAllowanceTypes$: Observable<any>;
  private cachedListRelativeTypes$: Observable<any>;

  constructor(private masterDataService: MasterDataService) {
    this.initMapProvince();
    this.initMapAmphoe();
    // this.initMapTambon();
    this.cachedWorkflowStepActorTypes$ = this.masterDataService.GetWorkflowStepActorTypes().pipe(
      map(actorList => {
        return actorList.map(actor => {
          return {
            value: actor.workflowStepActorTypeId,
            label: actor.workflowStepActorTypeName,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedWorkflowTypes$ = this.masterDataService.GetWorkflowTypes().pipe(
      map(workflowTypeList => {
        return workflowTypeList.map(workflowType => {
          return {
            value: workflowType.workflowTypeId,
            label: workflowType.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedBenefitConditionTypes$ = this.masterDataService.GetBenefitConditionTypes().pipe(
      map(benefitList => {
        return benefitList.map(a => {
          return {
            value: a.id,
            label: a.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedBenefitUseTypes$ = this.masterDataService.GetBenefitUseTypes().pipe(
      map(benefitList => {
        return benefitList.map(a => {
          return {
            value: a.id,
            label: a.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedWagePayTypes$ = this.masterDataService.GetWagePayTypes().pipe(
      map(wagePayList => {
        return wagePayList.map(wagePay => {
          return {
            value: wagePay.wagePayType,
            label: wagePay.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedOtTypes$ = this.masterDataService.GetOTTypesList().pipe(
      map(otTypeList => {
        return otTypeList.map(otType => {
          return {
            value: otType.otTypeId,
            label: otType.name,
            order: otType.order,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedWorkdayTypes$ = this.masterDataService.GetWorkDayTypes().pipe(
      map(workdayTypeList => {
        return workdayTypeList.map(workdayType => {
          return {
            value: workdayType.workDayTypeId,
            label: workdayType.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedPayTypes$ = this.masterDataService.GetPayTypes().pipe(
      map(payTypeList => {
        return payTypeList.map(payType => {
          return {
            value: payType.payType,
            label: payType.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedPenaltyTypes$ = this.masterDataService.GetPenaltyTypes().pipe(
      map(penaltyList => {
        return penaltyList.map(penalty => {
          return {
            value: penalty.penaltyType,
            label: penalty.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedRelativeInfoStatuses$ = this.masterDataService.GetRelativeInfoStatuses().pipe(
      map(itemList => {
        return itemList.map(item => {
          return {
            value: item.relativeInfoStatus,
            label: item.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedBossPositionTypes$ = this.masterDataService.GetBossPositionTypes().pipe(
      map(itemList => {
        return itemList.map(item => {
          return {
            value: item.bossPositionType,
            label: item.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedShiftsInHolidayTypes$ = this.masterDataService.GetShiftsInHolidayTypes().pipe(
      map(itemList => {
        return itemList.map(item => {
          return {
            value: item.shiftsInHolidayType,
            label: item.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedProbationStatuses$ = this.masterDataService.GetProbationEvaluationStatuses().pipe(
      map(itemList => {
        return itemList.map(item => {
          return {
            value: item.probationEvaluationFormStatusId,
            label: item.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedPositionTakingTypes$ = this.masterDataService.GetPositionTakingTypes().pipe(
      map(itemList => {
        return itemList.map(item => {
          return {
            value: item.positionTakingType,
            label: item.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedPayAllowanceRounds$ = this.masterDataService.GetPayAllowanceRounds().pipe(
      map(itemList => {
        return itemList.map(item => {
          return {
            value: item.payAllowanceRound,
            label: item.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedPayAllowanceTypes$ = this.masterDataService.GetPayAllowanceTypes().pipe(
      map(itemList => {
        return itemList.map(item => {
          return {
            value: item.payAllowanceType,
            label: item.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedShiftsInAllowanceTypes$ = this.masterDataService.GetShiftsInAllowanceTypes().pipe(
      map(itemList => {
        return itemList.map(item => {
          return {
            value: item.shiftsInAllowanceType,
            label: item.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedLeaveTypes$ = this.masterDataService.GetLeaveTypes().pipe(
      map(leaveTypeList => {
        return leaveTypeList.map(leaveType => {
          return {
            value: leaveType.benefitElementId,
            label: leaveType.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedMyLeaveTypes$ = this.masterDataService.GetMyLeaveTypes().pipe(
      map(leaveTypeList => {
        return leaveTypeList.map(leaveType => {
          return {
            value: leaveType.benefitElementId,
            label: leaveType.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedLeaveTypesInAllowanceTypes$ = this.masterDataService.GetLeaveTypesInAllowanceTypes().pipe(
      map(leaveTypeList => {
        return leaveTypeList.map(leaveType => {
          return {
            value: leaveType.leaveTypesInAllowanceType,
            label: leaveType.name,
          };
        });
      }),
      shareReplay(1),
    );

    this.cachedListRelativeTypes$ = this.masterDataService.GetListRelativeTypes().pipe(
      map(leaveTypeList => {
        return leaveTypeList.map(leaveType => {
          return {
            value: leaveType.listRelativeType,
            label: leaveType.name,
          };
        });
      }),
      shareReplay(1),
    );
  }

  private initMapProvince() {
    this.masterDataService.GetProvince().subscribe(provinceList => {
      provinceList.forEach(province => {
        this._MapProvince[province.provinceId] = province;
        this._MapProvince2[province.name] = province;
      });
    });
  }

  private initMapAmphoe() {
    this.masterDataService.GetAmphoes().subscribe(amphoeList => {
      amphoeList.forEach(amphoe => {
        this._MapAmphoe[amphoe.amphoeId] = amphoe;
        this._MapAmphoe2[amphoe.name] = amphoe;
      });
    });
  }

  // private initMapTambon() {
  //   this.masterDataService.GetTambons().subscribe(tambonList => {
  //     tambonList.forEach(tambon => {
  //       this._MapTambon[tambon.tumbonId] = tambon;
  //       this._MapTambon2[tambon.name] = tambon;
  //     });
  //   });
  // }

  getProvinceById(provinceId: number): string {
    return this._MapProvince[provinceId]?.name ?? '';
  }

  getAmphoeById(amphoeId: number): string {
    return this._MapAmphoe[amphoeId]?.name ?? '';
  }

  getProvinceIdByName(provinceName: string): number | undefined {
    return this._MapProvince2[provinceName]?.provinceId ?? undefined;
  }

  getAmphoeIdByName(amphoeName: string): number | undefined {
    return this._MapAmphoe2[amphoeName]?.amphoeId ?? undefined;
  }

  getAmphoeOptionList(provinceId?: number): Observable<Option[]> {
    return this.masterDataService.GetAmphoes(provinceId).pipe(
      map(amphoeList => {
        return amphoeList.map(amphoe => {
          return {
            value: amphoe.name,
            label: amphoe.name,
          };
        });
      }),
    );
  }

  getTambonOptionList(amphoeId?: number): Observable<Option[]> {
    return this.masterDataService.GetTambons(amphoeId).pipe(
      map(tambonList => {
        return tambonList.map(tambon => {
          return {
            value: tambon.name,
            label: tambon.name,
          };
        });
      }),
    );
  }

  getReligionOptionList(): Observable<Option[]> {
    return this.masterDataService.GetReligions().pipe(
      map(religionList => {
        return religionList.map(religion => {
          return {
            value: religion.religionId,
            label: religion.name,
          };
        });
      }),
    );
  }

  getNationalityOptionList(): Observable<Option[]> {
    return this.masterDataService.GetNationalities().pipe(
      map(nationalityList => {
        return nationalityList.map(nationality => {
          return {
            value: nationality.nationalityId,
            label: nationality.name,
          };
        });
      }),
    );
  }

  getRaceOptionList(): Observable<Option[]> {
    return this.masterDataService.GetRaces().pipe(
      map(raceList => {
        return raceList.map(race => {
          return {
            value: race.raceId,
            label: race.name,
          };
        });
      }),
    );
  }

  getProvinceOptionList(): Observable<Option[]> {
    return this.masterDataService.GetProvince().pipe(
      map(provinceList => {
        return provinceList.map(province => {
          return {
            value: province.name,
            label: province.name,
          };
        });
      }),
    );
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getTitleThOptionList(): Observable<Option[]> {
    return this.masterDataService.GetTitles().pipe(
      map(titleList => {
        return titleList
          .map(title => title.nameTh)
          .filter(this.onlyUnique)
          .map(title => {
            return {
              value: title,
              label: title,
            };
          });
      }),
    );
  }

  getTitleEnOptionList(): Observable<Option[]> {
    return this.masterDataService.GetTitles().pipe(
      map(titleList => {
        return titleList
          .map(title => title.nameEn)
          .filter(this.onlyUnique)
          .map(title => {
            return {
              value: title,
              label: title,
            };
          });
      }),
    );
  }

  getGenderOptionList(): Observable<Option[]> {
    return this.masterDataService.GetGenders().pipe(
      map(genderList => {
        return genderList.map(gender => {
          return {
            value: gender.genderId,
            label: gender.name,
          };
        });
      }),
    );
  }

  getMilitaryStatusOptionList(): Observable<Option[]> {
    return this.masterDataService.GetMilitaryStatuses().pipe(
      map(militaryStatusList => {
        return militaryStatusList.map(militaryStatus => {
          return {
            value: militaryStatus.militaryStatusId,
            label: militaryStatus.name,
          };
        });
      }),
    );
  }

  getEmployeeTypeOptionList(): Observable<Option[]> {
    return this.masterDataService.GetEmployeeTypes().pipe(
      map(employeeTypeList => {
        return employeeTypeList.map(employeeType => {
          return {
            value: employeeType.employeeTypeId.toString(),
            label: employeeType.name,
          };
        });
      }),
    );
  }

  getEmployeeType2OptionList(): Observable<Option[]> {
    return this.masterDataService.GetEmployeeTypes2().pipe(
      map(employeeType2List => {
        console.log(employeeType2List);
        return employeeType2List.map(employeeType2 => {
          return {
            value: employeeType2.employeeTypeId.toString(),
            label: employeeType2.name,
          };
        });
      }),
    );
  }

  getBusinessTypeOptionList(): Observable<Option[]> {
    return this.masterDataService.GetBusinesstypes().pipe(
      map(businessTypeList => {
        return businessTypeList.map(businessType => {
          return {
            value: businessType.businessTypeId,
            label: businessType.name,
          };
        });
      }),
    );
  }

  getPayChannelsOptionList(): Observable<Option[]> {
    return this.masterDataService.GetPayChannels().pipe(
      map(payChannelList => {
        return payChannelList.map(payChannel => {
          return {
            value: payChannel.payChannelId,
            label: payChannel.name,
          };
        });
      }),
    );
  }

  getBankOptionList(): Observable<Option[]> {
    return this.masterDataService.GetBanks().pipe(
      map(bankList => {
        return bankList.map(bank => {
          return {
            value: bank.bankId,
            label: bank.name,
          };
        });
      }),
    );
  }

  getHospitalOptionList(): Observable<Option[]> {
    return this.masterDataService.GetHospitals().pipe(
      map(hospitalList => {
        return hospitalList.map(hospital => {
          return {
            value: hospital.hospitalId,
            label: hospital.name,
          };
        });
      }),
    );
  }

  getWagePayTypesOptionList(): Observable<Option[]> {
    return this.cachedWagePayTypes$;
  }

  getOtherPayOptionList(): Observable<Option[]> {
    return of([
      { label: 'ค่าโทรศัพท์', value: '0' },
      { label: 'ค่าตำแหน่ง', value: '1' },
    ]);
  }

  getMaritalStatusOptionList(): Observable<Option[]> {
    return this.masterDataService.GetMaritalStatuses().pipe(
      map(maritalStatusList => {
        return maritalStatusList
          .filter(mari => mari.useWithSelf)
          .map(mari => {
            return {
              value: mari.maritalStatusId,
              label: mari.name,
            };
          });
      }),
    );
  }

  getParentStatusOptionList(): Observable<Option[]> {
    return this.masterDataService.GetMaritalStatuses().pipe(
      map(maritalStatusList => {
        return maritalStatusList
          .filter(mari => mari.useWithParents)
          .map(mari => {
            return {
              value: mari.maritalStatusId,
              label: mari.name,
            };
          });
      }),
    );
  }

  getAddressTypeOptionList(): Observable<Option[]> {
    return this.masterDataService.GetAddressTypes().pipe(
      map(addressTypeList => {
        return addressTypeList.map(add => {
          return {
            label: add.name,
            value: add.addressTypeId.toString(),
          };
        });
      }),
    );
  }

  getBloodGroupOptionList(): Observable<Option[]> {
    return this.masterDataService.GetBloodGroups().pipe(
      map(bloodGroupList => {
        return bloodGroupList.map(blood => {
          return {
            value: blood.bloodGroupId.toString(),
            label: blood.name,
          };
        });
      }),
    );
  }

  getPunishmentTypesOptionList(): Observable<Option[]> {
    return this.masterDataService.GetPunishmentTypes().pipe(
      map(punishmentTypeList => {
        return punishmentTypeList.map(punishmentType => {
          return {
            value: punishmentType.punishmentTypeId,
            label: punishmentType.name,
          };
        });
      }),
    );
  }

  getEducationLevelOptionList(): Observable<Option[]> {
    return this.masterDataService.GetEducationLevels().pipe(
      map(educationLevelList => {
        return educationLevelList.map(eduLevel => {
          return {
            value: eduLevel.educationLevelId.toString(),
            label: eduLevel.name,
          };
        });
      }),
    );
  }

  getRelativeTypeOptionList(): Observable<Option[]> {
    return this.masterDataService.GetRelativeTypes().pipe(
      map(relativeTypeList => {
        return relativeTypeList.map(relativeType => {
          return {
            value: relativeType.relativeTypeId.toString(),
            label: relativeType.name,
          };
        });
      }),
    );
  }

  getLanguageOptionList(): Observable<Option[]> {
    return this.masterDataService.GetLanguages().pipe(
      map(languageList => {
        return languageList.map(language => {
          return {
            value: language.languageId,
            label: language.name,
          };
        });
      }),
    );
  }

  geSkillTypeOptionList(): Observable<Option[]> {
    return this.masterDataService.GetSkillTypes().pipe(
      map(skillTypeList => {
        return skillTypeList.map(skillType => {
          return {
            value: skillType.skillTypeId.toString(),
            label: skillType.name,
          };
        });
      }),
    );
  }

  getLeaveTypeOptionList() {
    return this.cachedLeaveTypes$;
  }

  getMyLeaveTypeOptionList() {
    return this.cachedMyLeaveTypes$;
  }

  getLeaveStatusOptionList(): Observable<Option[]> {
    return this.masterDataService.GetLeaveStatusesList().pipe(
      map(leaveStatusList => {
        return leaveStatusList.map(leaveStatus => {
          return {
            value: leaveStatus.leaveStatusId,
            label: leaveStatus.name,
          };
        });
      }),
    );
  }

  geOTTypeOptionList() {
    return this.cachedOtTypes$;
  }

  geOTStatusOptionList(): Observable<Option[]> {
    return this.masterDataService.GetOTStatusesList().pipe(
      map(otStatusList => {
        return otStatusList.map(otStatus => {
          return {
            value: otStatus.otStatusId,
            label: otStatus.name,
          };
        });
      }),
    );
  }

  getWarningLetterStatusList(): Observable<Option[]> {
    return this.masterDataService.GetEmployeeWarningLetterStatuses().pipe(
      map(warningLetterStatus => {
        return warningLetterStatus.map(warningStatus => {
          return {
            value: warningStatus.employeeWarningLetterStatusId,
            label: warningStatus.name,
          };
        });
      }),
    );
  }

  getAttendanceCertifyTypeOptionList(): Observable<Option[]> {
    return this.masterDataService.GetAttendanceCertifyTypesList().pipe(
      map(attTypeList => {
        return attTypeList.map(attType => {
          return {
            value: attType.attendanceCertifyTypeId,
            label: attType.name,
            order: attType.order,
          };
        });
      }),
    );
  }

  getAttendanceCertifyStatusOptionList(): Observable<Option[]> {
    return this.masterDataService.GetAttendanceCertifyStatusesList().pipe(
      map(attStatusList => {
        return attStatusList.map(attStatus => {
          return {
            value: attStatus.attendanceStatusId,
            label: attStatus.name,
          };
        });
      }),
    );
  }

  getWorkDayTypeOptionList() {
    return this.cachedWorkdayTypes$;
  }

  getTimeAttendanceStatusOptionList(): Observable<Option[]> {
    return this.masterDataService.GetTimeAttendanceStatus().pipe(
      map(timeAttendanceStatusList => {
        return timeAttendanceStatusList.map(timeAttendanceStatus => {
          return {
            value: timeAttendanceStatus.timeAttendanceStatusId,
            label: timeAttendanceStatus.name,
          };
        });
      }),
    );
  }

  getPayElementTypesOptionList(): Observable<Option[]> {
    return this.masterDataService.GetPayElementTypes().pipe(
      map(payElementTypeList => {
        return payElementTypeList.map(payElementType => {
          return {
            value: payElementType.payElementType,
            label: payElementType.name,
          };
        });
      }),
    );
  }

  getBenefitQuotaTypesOptionList(): Observable<Option[]> {
    return this.masterDataService.GetBenefitQuotaTypes().pipe(
      map(benefitQuotaTypeList => {
        return benefitQuotaTypeList.map(benefitQuotaType => {
          return {
            value: benefitQuotaType.benefitQuotaTypeId,
            label: benefitQuotaType.name,
          };
        });
      }),
    );
  }

  getStatusOptionList(excludes: Status[] = []): Observable<Option[]> {
    const statuses: { value: any; label: Status }[] = [
      { value: undefined, label: 'All' },
      { value: 0, label: 'Active' },
      { value: 1, label: 'InActive' },
      { value: 3, label: 'Draft' },
    ];

    return of(statuses.filter(opt => !excludes.includes(opt.label)));
  }

  getJobGradeLevelsOptionList(): Observable<Option[]> {
    return this.masterDataService.GetJobGradeLevels().pipe(
      map(jobGradeLvlList => {
        return jobGradeLvlList.map(jobGradeLvl => {
          return {
            value: jobGradeLvl.id,
            label: jobGradeLvl.name,
          };
        });
      }),
    );
  }

  getHolidayTypeOptionList(): Observable<Option[]> {
    return this.masterDataService.GetHolidayTypes().pipe(
      map(holidayTypeList => {
        return holidayTypeList.map(holidayType => {
          return {
            value: holidayType.holidayTypeId,
            label: holidayType.name,
          };
        });
      }),
    );
  }

  GetRequestTypesOptionList() {
    return this.masterDataService.GetRequestTypes().pipe(
      map(requestTypeList => {
        return requestTypeList.map(requestType => {
          return {
            value: requestType.requestTypeId,
            label: requestType.name,
          };
        });
      }),
    );
  }

  GetWorkflowTypesOptionList() {
    return this.cachedWorkflowTypes$;
  }

  GetWorkflowStepActorTypesOptionList() {
    return this.cachedWorkflowStepActorTypes$;
  }

  getBenefitConditionTypesList() {
    return this.cachedBenefitConditionTypes$;
  }

  getBenefitUseTypesList() {
    return this.cachedBenefitUseTypes$;
  }

  GetWorkTypesOptionList() {
    return this.masterDataService.GetWorkTypes().pipe(
      map(worktypeList => {
        return worktypeList.map(worktype => {
          return {
            value: worktype.id,
            label: worktype.name,
          };
        });
      }),
    );
  }

  GetCompanyList() {
    return this.masterDataService.GetCompanys().pipe(
      map(items => {
        return items.map(item => {
          return {
            value: item.companyId,
            label: item.name,
          };
        });
      }),
    );
  }

  // topadd
  GetCountryList() {
    return this.masterDataService.GetCountry().pipe(
      map(items => {
        return items.map(item => {
          return {
            value: item.countryCode,
            label: item.nameTH,
          };
        });
      }),
    );
  }

  GetCostCenterList(companyId = null) {
    return this.masterDataService.GetCostCenters(companyId).pipe(
      map(items => {
        return items.map(item => {
          return {
            value: item.costCenterId,
            label: item.name,
          };
        });
      }),
    );
  }

  getPayTypeOptionList() {
    return this.cachedPayTypes$;
  }

  getPenaltyTypeOptionList() {
    return this.cachedPenaltyTypes$;
  }

  getRelativeInfoStatusesOptionList() {
    return this.cachedRelativeInfoStatuses$;
  }

  getBossPositionTypesOptionList() {
    return this.cachedBossPositionTypes$;
  }

  getShiftsInHolidayTypesOptionList() {
    return this.cachedShiftsInHolidayTypes$;
  }

  getProbationStatusesOptionList() {
    return this.cachedProbationStatuses$;
  }

  getPositionTakingTypesOptionList() {
    return this.cachedPositionTakingTypes$;
  }

  getPayAllowanceRoundsOptionList() {
    return this.cachedPayAllowanceRounds$;
  }

  getPayAllowanceTypesOptionList() {
    return this.cachedPayAllowanceTypes$;
  }

  getShiftsInAllowanceTypesOptionList() {
    return this.cachedShiftsInAllowanceTypes$;
  }

  getLeaveTypesInAllowanceTypesOptionList() {
    return this.cachedLeaveTypesInAllowanceTypes$;
  }

  getListRelativeTypesOptionList() {
    return this.cachedListRelativeTypes$;
  }
}
