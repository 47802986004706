import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { WorkflowConditionModel, WorkflowConditionCompanyModel, WorkflowConditionJobGradeOfSystemModel } from 'app/api/models';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import * as moment from 'moment';
import { Observable, from, forkJoin } from 'rxjs';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { WorkflowConditionService, WorkflowService } from 'app/api/services';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize, map } from 'rxjs/operators';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof WorkflowConditionModel;
}

@Component({
  selector: 'app-modal-workflow-condition',
  templateUrl: './modal-workflow-condition.component.html',
  styleUrls: ['./modal-workflow-condition.component.css']
})
export class ModalWorkflowConditionComponent implements OnInit {
  model: WorkflowConditionModel;
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'workflowId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'Workflow',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: true,
        options: []
      },
    },
    {
      key: 'description',
      type: 'textarea',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: { style: 'min-height: 120px' },
        label: 'คำอธิบาย',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: false,
        maxLength: 1024,
      },
    },
    {
      key: 'relativeWithCompany',
      type: 'radio',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'บริษัทที่สามารถใช้ได้',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        options: []
      },
    },
    {
      key: 'workflowConditionCompanies',
      type: 'repeat-input',
      templateOptions: {
        translate: true,
        label: 'not',
        placeholder: '@blank',
        addText: '+ เพิ่มรายการ',
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'companyId',
            type: 'filter-dropdown',
            templateOptions: {
              options: this.optionListRepo.getCachedCompanyOptionList(),
              required: true,
            },
          },
        ],
      },
    },
    {
      key: 'relativeWithJobGradeOfSystem',
      type: 'radio',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'Job Grade ที่สามารถใช้ได้',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        options: []
      },
    },
    {
      key: 'workflowConditionJobGradeOfSystems',
      type: 'repeat-input',
      templateOptions: {
        translate: true,
        label: 'not',
        placeholder: '@blank',
        addText: '+ เพิ่มรายการ',
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'jobGradeOfSystemId',
            type: 'filter-dropdown',
            templateOptions: {
              options: this.optionListRepo.getJobGradeOfSystemOptionList(),
              required: true,
            },
          },
        ],
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'วันที่มีผลใช้งาน',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: true,
      },
    },
  ];
  componentMode: ComponentMode;
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private masterDataRepo: MasterDataRepo,
    private optionListRepo: OptionListRepo,
    private workflowConditionService: WorkflowConditionService,
    private workflowService: WorkflowService
  ) { }

  ngOnInit() {
    this.initComponentMode();

    forkJoin([
      this.getWorkflowCondition(),
      this.workflowService.GetWorkflows({ ItemPerPage: 999, WorkflowTypeId: this.getWorkflowTypeId() })
        .pipe(map(pageResult => {
          return pageResult.results.map(workflow => {
            return { value: workflow.workflowId, label: workflow.name };
          });
        })),
      this.masterDataRepo.getListRelativeTypesOptionList(),
    ])
      .subscribe(
        ([model, workflowList, relativeTypeList]) => {
          this.fields.find(a => a.key === 'workflowId').templateOptions.options = workflowList;
          this.fields.find(a => a.key === 'relativeWithCompany').templateOptions.options = relativeTypeList;
          this.fields.find(a => a.key === 'relativeWithJobGradeOfSystem').templateOptions.options = relativeTypeList;

          setTimeout(() => {
            this.model = model;
          }, 0);
        },
        err => {
          console.log(err);
        },
      );
  }

  getWorkflowCondition(): Observable<WorkflowConditionModel> {
    if (this.componentMode === ComponentMode.CREATE) {
      const initialModel: WorkflowConditionModel = {
        relativeWithCompany: 'Exclude',
        relativeWithJobGradeOfSystem: 'Exclude',
        effectiveDate: moment().format('YYYY-MM-DD'),
      };
      return from([initialModel]);
    } else {
      return this.workflowConditionService.GetWorkflowCondition(this.getWorkflowConditionId());
    }
  }

  initComponentMode() {
    const positionId = this.getWorkflowConditionId();

    if (positionId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  submit() {
    const mapCompany = {};
    const uniqueCompanies: WorkflowConditionCompanyModel[] = [];
    this.model.workflowConditionCompanies.forEach(a => {
      const key = a.companyId;
      if (mapCompany[key]) {
        return;
      }
      mapCompany[key] = true;
      uniqueCompanies.push({ companyId: a.companyId });
    });

    const mapJobGrade = {};
    const uniqueJobGrades: WorkflowConditionJobGradeOfSystemModel[] = [];
    this.model.workflowConditionJobGradeOfSystems.forEach(a => {
      const key = a.jobGradeOfSystemId;
      if (mapJobGrade[key]) {
        return;
      }
      mapJobGrade[key] = true;
      uniqueJobGrades.push({ jobGradeOfSystemId: a.jobGradeOfSystemId });
    });

    const model: WorkflowConditionModel = {
      ...this.model,
      workflowConditionCompanies: uniqueCompanies,
      workflowConditionJobGradeOfSystems: uniqueJobGrades,
    };
    console.log(model);

    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.workflowConditionService.CreateWorkflowCondition(model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.workflowConditionService.UpdateWorkflowCondition(model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getWorkflowConditionId() {
    return this.config.data?.id;
  }

  getWorkflowTypeId() {
    return this.config.data?.workflowTypeId;
  }
}
