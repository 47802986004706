<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>{{ 'competence' | translate }}</h1>
        <hr />
        <div class="card">
          <form [formGroup]="form">
            <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
          </form>

          <div class="grid justify-content-end">
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                (click)="onUpdateComptenceBtnClick()"
                label="{{ 'record' | translate }}"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
