/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AllowanceModelPaginationResult } from '../models/allowance-model-pagination-result';
import { AllowanceModel } from '../models/allowance-model';
@Injectable({
  providedIn: 'root',
})
class AllowanceService extends __BaseService {
  static readonly GetAllowanceListPath = '/api/Allowance';
  static readonly CreateAllowancePath = '/api/Allowance';
  static readonly UpdateAllowancePath = '/api/Allowance';
  static readonly GetAllowanceByIdPath = '/api/Allowance/{allowanceId}';
  static readonly DeleteAllowancePath = '/api/Allowance/{allowanceId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AllowanceService.GetAllowanceListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetAllowanceListResponse(params: AllowanceService.GetAllowanceListParams): __Observable<__StrictHttpResponse<AllowanceModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Allowance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllowanceModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `AllowanceService.GetAllowanceListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetAllowanceList(params: AllowanceService.GetAllowanceListParams): __Observable<AllowanceModelPaginationResult> {
    return this.GetAllowanceListResponse(params).pipe(
      __map(_r => _r.body as AllowanceModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateAllowanceResponse(body?: AllowanceModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Allowance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateAllowance(body?: AllowanceModel): __Observable<string> {
    return this.CreateAllowanceResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateAllowanceResponse(body?: AllowanceModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Allowance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateAllowance(body?: AllowanceModel): __Observable<string> {
    return this.UpdateAllowanceResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param allowanceId undefined
   * @return Success
   */
  GetAllowanceByIdResponse(allowanceId: number): __Observable<__StrictHttpResponse<AllowanceModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Allowance/${encodeURIComponent(String(allowanceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllowanceModel>;
      })
    );
  }
  /**
   * @param allowanceId undefined
   * @return Success
   */
  GetAllowanceById(allowanceId: number): __Observable<AllowanceModel> {
    return this.GetAllowanceByIdResponse(allowanceId).pipe(
      __map(_r => _r.body as AllowanceModel)
    );
  }

  /**
   * @param allowanceId undefined
   * @return Success
   */
  DeleteAllowanceResponse(allowanceId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Allowance/${encodeURIComponent(String(allowanceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param allowanceId undefined
   * @return Success
   */
  DeleteAllowance(allowanceId: number): __Observable<string> {
    return this.DeleteAllowanceResponse(allowanceId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module AllowanceService {

  /**
   * Parameters for GetAllowanceList
   */
  export interface GetAllowanceListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    Date?: string;
    CompanyId?: number;
  }
}

export { AllowanceService }
