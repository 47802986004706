<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>{{ 'certificate' | translate }}</h1>
        <hr />
        <div class="card">
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>Certificate</h1>
            </div>
            <div class="col"></div>
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                label="{{ 'add_data' | translate }}"
                (click)="showCreateCertificateDialog()"
              ></button>
            </div>
          </div>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="certificateList">
            <ng-template pTemplate="header">
              <tr>
                <th>ประเภท</th>
                <th>เลขที่</th>
                <th>ชื่อ</th>
                <th>ไฟล์แนบ</th>
                <th>วันที่เริ่มใช้งาน</th>
                <th>วันที่หมดอายุ</th>
                <th *ngIf="isEditable" style="width: 100px">Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.certificateTypeName }}</td>
                <td>{{ rowData.certificateNo }}</td>
                <td>{{ rowData.certificateName }}</td>
                <td>
                  <span *ngIf="!rowData.documentId">N/A</span>
                  <a *ngIf="rowData.documentId" href="/api/document/{{ rowData.documentId }}/download">download</a>
                </td>
                <td>{{ rowData.effectiveDate | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="rowData.expireDate == null || getYearFromDateISOString(rowData.expireDate) >= 9999">
                  {{ 'ไม่มีวันหมดอายุ' }}
                </td>
                <td *ngIf="rowData.expireDate != null && getYearFromDateISOString(rowData.expireDate) < 9999">
                  {{ rowData.expireDate | date: 'dd/MM/yyyy' }}
                </td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i
                        class="fa fa-pencil-alt"
                        (click)="showUpdateCertificateDialog(rowData.employeeCertificateId)"
                      ></i>
                    </div>
                    <div class="col-fixed">
                      <i class="fa fa-trash" (click)="showDeleteCertificateDialog(rowData.employeeCertificateId)"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" class="emptymessage">ไม่พบข้อมูล</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
