import { Component, OnInit, ChangeDetectionStrategy, Renderer2 } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppMenuService } from './app-menu/app-menu.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ApplicationSettingsService } from '../../services/application-settings.service';
import { GoogleAuthService } from '../../services/google-auth.service';
import { ApplicationSettingsExtended } from '../../api/models';
@Component({
  selector: 'app-layout-default',
  templateUrl: './layout-default.component.html',
  animations: [
    trigger('mask-anim', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      state(
        'visible',
        style({
          opacity: 0.8,
        }),
      ),
      transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)')),
    ]),
  ],
})
export class LayoutDefaultComponent implements OnInit {

  horizontalMenu: boolean;

  darkMode = false;

  menuColorMode = 'light';

  menuColor = 'layout-menu-light';

  themeColor = 'stark';

  layoutColor = 'stark';

  rightPanelClick: boolean;

  rightPanelActive: boolean;

  menuClick: boolean;

  staticMenuActive: boolean = true;

  menuMobileActive: boolean;

  megaMenuClick: boolean;

  megaMenuActive: boolean;

  megaMenuMobileClick: boolean;

  megaMenuMobileActive: boolean;

  topbarItemClick: boolean;

  topbarMobileMenuClick: boolean;

  topbarMobileMenuActive: boolean;

  configDialogActive: boolean;

  sidebarActive: boolean = true;

  activeTopbarItem: any;

  topbarMenuActive: boolean;

  menuHoverActive: boolean;
  appSettings: ApplicationSettingsExtended;

  constructor(
    public renderer: Renderer2,
    private menuService: AppMenuService,
    public permissionService: NgxPermissionsService,
    private applicationSettingsService: ApplicationSettingsService,
    private googleAuthService: GoogleAuthService
  ) { }
  async ngOnInit() {
    this.appSettings = await this.applicationSettingsService.getApplicationSettings().toPromise();

    console.log('changing theme to ' + this.appSettings.themeName);
    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;

    themeLink.href = 'assets/theme/' + this.appSettings.themeName + '/theme-' + this.appSettings.themeMode + '.css';
    layoutLink.href = 'assets/layout/css/' + this.appSettings.themeName + '/layout-' + this.appSettings.themeMode + '.css';

    this.googleAuthService.loadAuth2(this.appSettings.googleAuthSettings.clientId);
  }
  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.rightPanelClick) {
      this.rightPanelActive = false;
    }

    if (!this.megaMenuClick) {
      this.megaMenuActive = false;
    }

    if (!this.megaMenuMobileClick) {
      this.megaMenuMobileActive = false;
    }

    if (!this.menuClick) {
      if (this.isHorizontal()) {
        this.menuService.reset();
      }

      if (this.menuMobileActive) {
        this.menuMobileActive = false;
      }

      this.menuHoverActive = false;
    }

    this.menuClick = false;
    this.topbarItemClick = false;
    this.megaMenuClick = false;
    this.megaMenuMobileClick = false;
    this.rightPanelClick = false;
  }

  onMegaMenuButtonClick(event) {
    this.megaMenuClick = true;
    this.megaMenuActive = !this.megaMenuActive;
    event.preventDefault();
  }

  onMegaMenuClick(event) {
    this.megaMenuClick = true;
    event.preventDefault();
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.rightPanelActive = !this.rightPanelActive;

    event.preventDefault();
  }

  onRightPanelClose(event) {
    this.rightPanelActive = false;
    this.rightPanelClick = false;

    event.preventDefault();
  }

  onRightPanelClick(event) {
    this.rightPanelClick = true;

    event.preventDefault();
  }

  onTopbarMobileMenuButtonClick(event) {
    this.topbarMobileMenuClick = true;
    this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

    event.preventDefault();
  }

  onMegaMenuMobileButtonClick(event) {
    this.megaMenuMobileClick = true;
    this.megaMenuMobileActive = !this.megaMenuMobileActive;

    event.preventDefault();
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarMenuActive = false;

    if (this.isMobile()) {
      this.menuMobileActive = !this.menuMobileActive;
    }

    event.preventDefault();
  }

  onSidebarClick(event: Event) {
    this.menuClick = true;
  }

  onToggleMenuClick(event: Event) {
    this.staticMenuActive = !this.staticMenuActive;
    event.preventDefault();
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  isHorizontal() {
    return this.horizontalMenu === true;
  }
}
