import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeBankAccountModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeBankAccountService, MasterDataService } from 'app/api/services';
import { HttpErrorResponse } from '@angular/common/http';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeBankAccountModel;
}

@Component({
  selector: 'app-modal-employee-bank-account',
  templateUrl: './modal-employee-bank-account.component.html',
  styleUrls: ['./modal-employee-bank-account.component.css'],
})
export class ModalEmployeeBankAccountComponent implements OnInit, OnDestroy {
  model: EmployeeBankAccountModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'bankName',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        required: true,

        label: 'not_eligible',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
        // options: this.masterDataService.GetBanks().pipe(
        //   map(result => {
        //     return result.map(v => {
        //       return { label: v.name, value: v.bankId };
        //     });
        //   }),
        // ),
      },
    },
    {
      key: 'branchName',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'branch',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'accountNumber',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'acount_number',
        placeholder: '@blank',
        maxLength: 10,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeBankAccountService: EmployeeBankAccountService,
    private masterDataService: MasterDataService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const bankAccountId = this.getBankAccountId();

    if (bankAccountId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'bankName').templateOptions.options = await this.masterDataService
      .GetBanks()
      .pipe(
        map(result => {
          return result.map(v => {
            return { label: v.name, value: v.bankId };
          });
        }),
      )
      .toPromise();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    this.model = await this.employeeBankAccountService.GetEmployeeBankAccountById(this.getBankAccountId()).toPromise();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createEmployeeBankAccount()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateEmployeeBankAccount()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createEmployeeBankAccount() {
    return this.employeeBankAccountService.CreateEmployeeBankAccount({
      employeeId: this.model.employeeId,
      bankName: this.model.bankName,
      branchName: this.model.branchName,
      accountNumber: this.model.accountNumber,
    });
  }

  private updateEmployeeBankAccount() {
    return this.employeeBankAccountService.UpdateEmployeeBankAccount({
      employeeBankAccountId: this.model.employeeBankAccountId,
      employeeId: this.model.employeeId,
      bankName: this.model.bankName,
      branchName: this.model.branchName,
      accountNumber: this.model.accountNumber,
      isPrimary: this.model.isPrimary,
      status: this.model.status,
    });
  }

  getBankAccountId() {
    return this.config.data?.id;
  }
}
