import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BranchModel } from 'app/api/models';
import { BranchService } from 'app/api/services';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { Subject } from 'rxjs';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof BranchModel;
}

@Component({
  selector: 'app-modal-branch',
  templateUrl: './modal-branch.component.html',
  styleUrls: ['./modal-branch.component.scss']
})
export class ModalBranchComponent implements OnInit, OnDestroy {
  model: BranchModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      template: "<h2 class='mb-0'>ข้อมูลทั่วไป </h2>"
    },
    {
      key: 'branchCode',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'branchCode',
        translate: true,
        required: true,
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'branch_name',
        translate: true,
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4',
      }
    },
    {
      key: 'nameEn',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'branch_name_en',
        translate: true,
        required: true,
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
      }
    },
    {
      template: "<h2 class='mb-0'>ข้อมูลที่อยู่ </h2>"
    },
    {
      key: 'address',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'address',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'province',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      expressionProperties: {
      },
      templateOptions: {
        translate: true,
        required: true,
        label: 'province',
        placeholder: '@blank',
        options: this.masterDataRepo.getProvinceOptionList(),
        labelClass: 'col-4',
        inputClass: 'col-8',
        change: (field,$event) => {
          const provinceId = this.masterDataRepo.getProvinceIdByName(field.formControl.value);
          console.log(field, provinceId);

          const controls_district = this.fields.find(a => a.key === 'district');
          const controls_subdistrict = this.fields.find(a => a.key === 'subDistrict');

          controls_district.formControl.setValue(undefined);
          controls_subdistrict.formControl.setValue(undefined);
          controls_subdistrict.templateOptions.options = [];
          controls_district.templateOptions.options = this.masterDataRepo.getAmphoeOptionList(provinceId);
        },
      },
    },
    {
      key: 'district',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'district',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        change: (field,$event) => {
          const amphoeId = this.masterDataRepo.getAmphoeIdByName(field.formControl.value);
          console.log(field, amphoeId);

          const controls_subdistrict = this.fields.find(a => a.key === 'subDistrict');
          controls_subdistrict.formControl.setValue(undefined);
          controls_subdistrict.templateOptions.options = this.masterDataRepo.getTambonOptionList(amphoeId);
        },
      },
    },
    {
      key: 'subDistrict',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        required: true,
        translate: true,
        label: 'subdistrict',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'countryCode',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'country',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-8',
        options: this.masterDataRepo.GetCountryList(),
      },
    },
    {
      key: 'zipcode',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'postal_code',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      template: "<h2 class='mb-0'>ข้อมูลประกันสังคม </h2>"
    },
    {
      key: 'social_Security_Number',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'social_security_number',
        required: true,
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
        maxLength: 13,
      }
    },
    {
      key: 'businessCode',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'business_code',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
      }
    },
    {
      key: 'contribution_Rate',
      defaultValue: 5,
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'contribution_rate',
        required: true,
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
        type: 'number',
        min:0,
      }
    }
  ]
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(private masterDataRepo: MasterDataRepo,
    private branchService: BranchService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
  ) { }

  async ngOnInit() {
    this.initComponentMode()
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }


  initComponentMode() {
    const branchId = this.getBranchId();

    if (branchId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {
        companyId: this.getCompanyId(),
      };
      return;
    }

    this.model = await this.branchService.GetBranchById(this.getBranchId()).toPromise();
  }


  getBranchId() {
    return +this.config.data?.id;
  }

  getCompanyId() {
    return +this.config.data?.companyId;
  }

  close() {
    this.ref.close();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.model.terminateDate = '9999-09-09T00:00:00';
        this.model.companyId = this.getCompanyId();
        this.branchService
          .CreateBranch(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:

        this.branchService
          .UpdateBranch({
            branchId: this.model.branchId,
            body: this.model,
          })
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

}
