import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppErrorComponent } from './error/app.error.component';
import { AppAccessdeniedComponent } from './accessdenied/app.accessdenied.component';
import { AppNotfoundComponent } from './notfound/app.notfound.component';
import { SharedModule } from '../../../shared/shared.module';
import { ComponentsModule } from '../../../components/components.module';



@NgModule({
  declarations: [
    AppAccessdeniedComponent,
    AppErrorComponent,
    AppNotfoundComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
  ]
})
export class ErrorsModule { }
