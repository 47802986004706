/* tslint:disable */
type EEmployeeUpdateInfoRequestStatus =
  'Draft' |
  'Editing' |
  'Awaiting' |
  'Approved' |
  'Cancel' |
  'Reject';
module EEmployeeUpdateInfoRequestStatus {
  export const DRAFT: EEmployeeUpdateInfoRequestStatus = 'Draft';
  export const EDITING: EEmployeeUpdateInfoRequestStatus = 'Editing';
  export const AWAITING: EEmployeeUpdateInfoRequestStatus = 'Awaiting';
  export const APPROVED: EEmployeeUpdateInfoRequestStatus = 'Approved';
  export const CANCEL: EEmployeeUpdateInfoRequestStatus = 'Cancel';
  export const REJECT: EEmployeeUpdateInfoRequestStatus = 'Reject';
  export function values(): EEmployeeUpdateInfoRequestStatus[] {
    return [
      DRAFT,
      EDITING,
      AWAITING,
      APPROVED,
      CANCEL,
      REJECT
    ];
  }
}

export { EEmployeeUpdateInfoRequestStatus }