/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeePunishmentModel } from '../models/employee-punishment-model';
@Injectable({
  providedIn: 'root',
})
class EmployeePunishmentService extends __BaseService {
  static readonly GetEmployeePunishmentsListPath = '/api/EmployeePunishment';
  static readonly CreateEmployeePunishmentPath = '/api/EmployeePunishment';
  static readonly UpdateEmployeePunishmentPath = '/api/EmployeePunishment';
  static readonly GetEmployeePunishmentByIdPath = '/api/EmployeePunishment/{EmployeePunishmentId}';
  static readonly DeleteEmployeePunishmentPath = '/api/EmployeePunishment/{EmployeePunishmentId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeePunishmentsListResponse(): __Observable<__StrictHttpResponse<Array<EmployeePunishmentModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeePunishment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeePunishmentModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeePunishmentsList(): __Observable<Array<EmployeePunishmentModel>> {
    return this.GetEmployeePunishmentsListResponse().pipe(
      __map(_r => _r.body as Array<EmployeePunishmentModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeePunishmentResponse(body?: EmployeePunishmentModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeePunishment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeePunishment(body?: EmployeePunishmentModel): __Observable<string> {
    return this.CreateEmployeePunishmentResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeePunishmentResponse(body?: EmployeePunishmentModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeePunishment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeePunishment(body?: EmployeePunishmentModel): __Observable<string> {
    return this.UpdateEmployeePunishmentResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeePunishmentId undefined
   * @return Success
   */
  GetEmployeePunishmentByIdResponse(EmployeePunishmentId: number): __Observable<__StrictHttpResponse<EmployeePunishmentModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeePunishment/${encodeURIComponent(String(EmployeePunishmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePunishmentModel>;
      })
    );
  }
  /**
   * @param EmployeePunishmentId undefined
   * @return Success
   */
  GetEmployeePunishmentById(EmployeePunishmentId: number): __Observable<EmployeePunishmentModel> {
    return this.GetEmployeePunishmentByIdResponse(EmployeePunishmentId).pipe(
      __map(_r => _r.body as EmployeePunishmentModel)
    );
  }

  /**
   * @param EmployeePunishmentId undefined
   * @return Success
   */
  DeleteEmployeePunishmentResponse(EmployeePunishmentId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeePunishment/${encodeURIComponent(String(EmployeePunishmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeePunishmentId undefined
   * @return Success
   */
  DeleteEmployeePunishment(EmployeePunishmentId: number): __Observable<string> {
    return this.DeleteEmployeePunishmentResponse(EmployeePunishmentId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeePunishmentService {
}

export { EmployeePunishmentService }
