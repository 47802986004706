import { FormControl, ValidationErrors } from '@angular/forms';

export function IntegerValidator(control: FormControl): ValidationErrors {
  const reg = /^-?[1-9]\d*$/;

  if (!control.value || reg.test(String(control.value).toLowerCase())) {
    return null;
  }

  return { fieldMatch: { message: 'ต้องเป็นจำนวนเต็ม' } };
}
