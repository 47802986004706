/* tslint:disable */
type EGender =
  'Male' |
  'Female';
module EGender {
  export const MALE: EGender = 'Male';
  export const FEMALE: EGender = 'Female';
  export function values(): EGender[] {
    return [
      MALE,
      FEMALE
    ];
  }
}

export { EGender }