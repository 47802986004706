<!-- button mode -->
<ng-container *ngIf="mode == formlySelectEmployeeDisplayMode.button">
  <div class="grid">
    <div class="col-fixed">
      <button pButton type="button" [label]="btnLabel" (click)="!disabled ? showEmployeeSearchModal() : null"></button>
    </div>
  </div>
</ng-container>

<!-- text mode -->
<ng-container *ngIf="mode == formlySelectEmployeeDisplayMode.text">
  <div style="display: flex; height: 100%; align-items: center; justify-content: center;">
    <a role="button" [ngClass]="{'action-link': !disabled}" (click)="!disabled ? showEmployeeSearchModal() : null">
      {{btnLabel}} <i class="pi pi-pencil" *ngIf="!disabled"></i>
    </a>
  </div>
</ng-container>