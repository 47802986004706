import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { BenefitsModule } from './benefits/benefits.module';
import { PayrollModule } from './payroll/payroll.module';
import { SystemModule } from './system/system.module';
import { TaModule } from './ta/ta.module';
import { CoreModule } from './core/core.module';
import { SettingModule } from './setting/setting.module';
import { ModalSharedModule } from './shared/shared.module';

const modules = [
  CommonModule,
  DynamicDialogModule,
  SharedModule,
  BenefitsModule,
  CoreModule,
  PayrollModule,
  SystemModule,
  TaModule,
  SettingModule,
  ModalSharedModule
];
@NgModule({
  declarations: [],
  imports: [
    ...modules
  ],
  exports: [
    ...modules
  ]
})
export class ModalsModule { }
