/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeUpdateInfoRequestModelPaginationResult } from '../models/employee-update-info-request-model-pagination-result';
import { EmployeeUpdateInfoRequestModel } from '../models/employee-update-info-request-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeUpdateInfoRequestService extends __BaseService {
  static readonly GetMyEditInfoRequestPath = '/api/EmployeeUpdateInfoRequest';
  static readonly CreateEditInfoRequestPath = '/api/EmployeeUpdateInfoRequest';
  static readonly UpdateEditInfoRequestPath = '/api/EmployeeUpdateInfoRequest';
  static readonly GetEditInfoRequestByRequestIdPath = '/api/EmployeeUpdateInfoRequest/request/{requestId}';
  static readonly CancelEditInfoRequestPath = '/api/EmployeeUpdateInfoRequest/{employeeUpdateInfoRequestId}/cancel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EmployeeUpdateInfoRequestService.GetMyEditInfoRequestParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeUpdateInfoRequestStatusId`:
   *
   * @return Success
   */
  GetMyEditInfoRequestResponse(params: EmployeeUpdateInfoRequestService.GetMyEditInfoRequestParams): __Observable<__StrictHttpResponse<EmployeeUpdateInfoRequestModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.EmployeeUpdateInfoRequestStatusId != null) __params = __params.set('EmployeeUpdateInfoRequestStatusId', params.EmployeeUpdateInfoRequestStatusId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeUpdateInfoRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeUpdateInfoRequestModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeeUpdateInfoRequestService.GetMyEditInfoRequestParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeUpdateInfoRequestStatusId`:
   *
   * @return Success
   */
  GetMyEditInfoRequest(params: EmployeeUpdateInfoRequestService.GetMyEditInfoRequestParams): __Observable<EmployeeUpdateInfoRequestModelPaginationResult> {
    return this.GetMyEditInfoRequestResponse(params).pipe(
      __map(_r => _r.body as EmployeeUpdateInfoRequestModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEditInfoRequestResponse(body?: EmployeeUpdateInfoRequestModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeUpdateInfoRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEditInfoRequest(body?: EmployeeUpdateInfoRequestModel): __Observable<number> {
    return this.CreateEditInfoRequestResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEditInfoRequestResponse(body?: EmployeeUpdateInfoRequestModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeUpdateInfoRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEditInfoRequest(body?: EmployeeUpdateInfoRequestModel): __Observable<string> {
    return this.UpdateEditInfoRequestResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param requestId undefined
   * @return Success
   */
  GetEditInfoRequestByRequestIdResponse(requestId: number): __Observable<__StrictHttpResponse<EmployeeUpdateInfoRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeUpdateInfoRequest/request/${encodeURIComponent(String(requestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeUpdateInfoRequestModel>;
      })
    );
  }
  /**
   * @param requestId undefined
   * @return Success
   */
  GetEditInfoRequestByRequestId(requestId: number): __Observable<EmployeeUpdateInfoRequestModel> {
    return this.GetEditInfoRequestByRequestIdResponse(requestId).pipe(
      __map(_r => _r.body as EmployeeUpdateInfoRequestModel)
    );
  }

  /**
   * @param employeeUpdateInfoRequestId undefined
   * @return Success
   */
  CancelEditInfoRequestResponse(employeeUpdateInfoRequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeUpdateInfoRequest/${encodeURIComponent(String(employeeUpdateInfoRequestId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeUpdateInfoRequestId undefined
   * @return Success
   */
  CancelEditInfoRequest(employeeUpdateInfoRequestId: number): __Observable<string> {
    return this.CancelEditInfoRequestResponse(employeeUpdateInfoRequestId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeUpdateInfoRequestService {

  /**
   * Parameters for GetMyEditInfoRequest
   */
  export interface GetMyEditInfoRequestParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    EmployeeUpdateInfoRequestStatusId?: any;
  }
}

export { EmployeeUpdateInfoRequestService }
