import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeePastEmploymentModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { MasterDataService, EmployeePastEmploymentService } from 'app/api/services';
import { HttpErrorResponse } from '@angular/common/http';

import { MasterDataRepo } from 'app/repo/master-data.repo';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeePastEmploymentModel;
}

@Component({
  selector: 'app-modal-employee-past-employment',
  templateUrl: './modal-employee-past-employment.component.html',
  styleUrls: ['./modal-employee-past-employment.component.css'],
})
export class ModalEmployeePastEmploymentComponent implements OnInit, OnDestroy {
  model: EmployeePastEmploymentModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'employerName',
          type: 'primeng-input',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'ชื่อบริษัท',
            required: true,
            labelClass: 'col-3',
            inputClass: 'col-9',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'businessTypeId',
          type: 'filter-dropdown',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'ประเภทธุรกิจ',
            labelClass: 'col-6',
            inputClass: 'col-6',
            // options: this.masterDataRepo.getBusinessTypeOptionList(),
          },
        },
        {
          key: 'phone',
          type: 'primeng-input',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'เบอร์โทรศัพท์',
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'ที่ตั้งบริษัท',
            labelClass: 'col-3',
            inputClass: 'col-9',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'jobDescription',
          type: 'primeng-input',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'ลักษณะงานที่ทำ',
            labelClass: 'col-3',
            inputClass: 'col-9',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'entryPositionName',
          type: 'primeng-input',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'ตำแหน่งแรกเข้า',
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
        },
        {
          key: 'exitPositionName',
          type: 'primeng-input',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'ตำแหน่งสุดท้าย',
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'entrySalary',
          type: 'primeng-input',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'เงินเดิอนแรกเข้า',
            labelClass: 'col-6',
            inputClass: 'col-6',
            type: 'number',
          },
        },
        {
          key: 'exitSalary',
          type: 'primeng-input',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'เงินเดิอนสุดท้าย',
            labelClass: 'col-6',
            inputClass: 'col-6',
            type: 'number',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'employeeDate',
          type: 'calendar',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            label: 'วันเริ่มงาน',
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
        },
        {
          key: 'terminateDate',
          type: 'calendar',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            label: 'วันสิ้นสุดการทำงาน',
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
        },
      ],
    },
    {
      key: 'exitReason',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'เหตุผลที่ออกจากงาน',
        labelClass: 'col-3',
        inputClass: 'col-9',
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeePastEmploymentService: EmployeePastEmploymentService,
    private masterDataRepo: MasterDataRepo,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const employeePastEmploymentId = this.getEmployeePastEmploymentId();

    if (employeePastEmploymentId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    this.fields[1].fieldGroup.find(a => a.key === 'businessTypeId').templateOptions.options = await this.masterDataRepo
      .getBusinessTypeOptionList()
      .toPromise();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    const res = await this.employeePastEmploymentService
      .GetEmployeePastEmployment(this.getEmployeePastEmploymentId())
      .toPromise();

    this.model = {
      ...res,
      employeeDate: (res.employeeDate),
      terminateDate: (res.terminateDate),
    };
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createEmployeePastEmployment()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateEmployeePastEmployment()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createEmployeePastEmployment() {
    return this.employeePastEmploymentService.CreateEmployeePastEmployment({
      employeeId: this.model.employeeId,
      employerName: this.model.employerName,
      businessTypeId: this.model.businessTypeId,
      phone: this.model.phone,
      address: this.model.address,
      jobDescription: this.model.jobDescription,
      entryPositionName: this.model.entryPositionName,
      exitPositionName: this.model.exitPositionName,
      entrySalary: this.model.entrySalary,
      exitSalary: this.model.exitSalary,
      employeeDate: this.model.employeeDate,
      terminateDate: this.model.terminateDate,
      exitReason: this.model.exitReason,
    });
  }

  private updateEmployeePastEmployment() {
    return this.employeePastEmploymentService.UpdateEmployeePastEmployment({
      EmployeePastEmploymentId: this.model.employeePastEmploymentId,
      body: {
        employeeId: this.model.employeeId,
        employeePastEmploymentId: this.model.employeePastEmploymentId,
        employerName: this.model.employerName,
        employeeTypeId: this.model.employeeTypeId,
        businessTypeId: this.model.businessTypeId,
        phone: this.model.phone,
        address: this.model.address,
        jobDescription: this.model.jobDescription,
        entryPositionName: this.model.entryPositionName,
        exitPositionName: this.model.exitPositionName,
        entrySalary: this.model.entrySalary,
        exitSalary: this.model.exitSalary,
        employeeDate: this.model.employeeDate,
        terminateDate: this.model.terminateDate,
        exitReason: this.model.exitReason,
        note: this.model.note,
        status: this.model.status,
      },
    });
  }

  getEmployeePastEmploymentId() {
    return this.config.data?.id;
  }
}
