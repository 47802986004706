/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RoleModelPaginationResult } from '../models/role-model-pagination-result';
import { RoleModel } from '../models/role-model';
@Injectable({
  providedIn: 'root',
})
class RoleService extends __BaseService {
  static readonly GetRolesPath = '/api/Role';
  static readonly CreateRolePath = '/api/Role';
  static readonly UpdateRolePath = '/api/Role';
  static readonly GetRolePath = '/api/Role/{roleId}';
  static readonly DeleteRolePath = '/api/Role/{roleId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RoleService.GetRolesParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetRolesResponse(params: RoleService.GetRolesParams): __Observable<__StrictHttpResponse<RoleModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RoleModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `RoleService.GetRolesParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetRoles(params: RoleService.GetRolesParams): __Observable<RoleModelPaginationResult> {
    return this.GetRolesResponse(params).pipe(
      __map(_r => _r.body as RoleModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateRoleResponse(body?: RoleModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Role`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateRole(body?: RoleModel): __Observable<string> {
    return this.CreateRoleResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateRoleResponse(body?: RoleModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Role`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateRole(body?: RoleModel): __Observable<string> {
    return this.UpdateRoleResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param roleId undefined
   * @return Success
   */
  GetRoleResponse(roleId: number): __Observable<__StrictHttpResponse<RoleModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/${encodeURIComponent(String(roleId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RoleModel>;
      })
    );
  }
  /**
   * @param roleId undefined
   * @return Success
   */
  GetRole(roleId: number): __Observable<RoleModel> {
    return this.GetRoleResponse(roleId).pipe(
      __map(_r => _r.body as RoleModel)
    );
  }

  /**
   * @param roleId undefined
   * @return Success
   */
  DeleteRoleResponse(roleId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Role/${encodeURIComponent(String(roleId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param roleId undefined
   * @return Success
   */
  DeleteRole(roleId: number): __Observable<string> {
    return this.DeleteRoleResponse(roleId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module RoleService {

  /**
   * Parameters for GetRoles
   */
  export interface GetRolesParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { RoleService }
