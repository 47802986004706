import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeHealthCheckModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DocumentService, EmployeeHealthCheckService, MasterDataService } from 'app/api/services';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';


enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}
interface SelectFileParam {
  currentFiles: File[];
}

@Component({
  selector: 'app-modal-employee-health-check',
  templateUrl: './modal-employee-health-check.component.html',
  styleUrls: ['./modal-employee-health-check.component.css'],
})
export class ModalEmployeeHealthCheckComponent implements OnInit, OnDestroy {
  model: EmployeeHealthCheckModel = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'name',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'documentId',
      type: 'upload-file',
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'attach_file',
        chooseLabel: 'อัพโหลด',
        previewWidth: 150,
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
        onSelect: (selectFileParam: SelectFileParam) => {
          this.documentService
            .CreateDocument({
              description: 'Employee Health Check',
              file: selectFileParam.currentFiles[0],
            })
            .subscribe(result => {
              this.model.documentId = result.documentId;
            });
        },
        onRemove: (_, model: EmployeeHealthCheckModel, oldDocumentId: number) => {
          model.documentId = oldDocumentId;
        },
      },
    },
    {
      key: 'comments',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'Comments',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'start_date_u',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-3'
      },
    },
    {
      key: 'terminateDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'expiration_date',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-3'
      },
    },
    {
      key: 'notifyBeforeTerminateDays',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      defaultValue: 60,
      templateOptions: {
        translate: true,
        placeholder: '@blank',
        label: 'number_days_expired',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-3',
        type: 'number',
      },
    },
    {
      key: 'enableNotifyBeforeTerminate',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        translate: true,
        required: false,
        placeholder: '@blank',
        label: 'notify_before_expiration',
        labelClass: 'col-4',
        inputClass: 'col-3',
        trueMessage: 'แจ้งเตือน',
        falseMessage: 'ไม่แจ้งเตือน',
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeHealthCheckService: EmployeeHealthCheckService,
    private masterDataService: MasterDataService,
    private documentService: DocumentService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const healthCheckId = this.getHealthCheckId();

    if (healthCheckId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    const res = await this.employeeHealthCheckService.GetEmployeeHealthCheckById(this.getHealthCheckId()).toPromise();

    this.model = {
      ...res,
      effectiveDate: (res.effectiveDate),
      terminateDate: (res.terminateDate),
    };
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.employeeHealthCheckService
          .CreateEmployeeHealthCheck(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.employeeHealthCheckService
          .UpdateEmployeeHealthCheck(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getHealthCheckId() {
    return this.config.data?.id;
  }
}
