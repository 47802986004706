/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeCertificateModel } from '../models/employee-certificate-model';
import { CertificateUploadStatusModel } from '../models/certificate-upload-status-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeCertificateService extends __BaseService {
  static readonly GetEmployeeCertificatesListPath = '/api/EmployeeCertificate';
  static readonly CreateEmployeeCertificatePath = '/api/EmployeeCertificate';
  static readonly GetEmployeeCertificateByIdPath = '/api/EmployeeCertificate/{employeeCertificateId}';
  static readonly UpdateEmployeeCertificatePath = '/api/EmployeeCertificate/{employeeCertificateId}';
  static readonly DeleteEmployeeCertificatePath = '/api/EmployeeCertificate/{employeeCertificateId}';
  static readonly ImportEmployeeEducationPath = '/api/EmployeeCertificate/Excel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeCertificatesListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeCertificateModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeCertificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeCertificateModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeCertificatesList(): __Observable<Array<EmployeeCertificateModel>> {
    return this.GetEmployeeCertificatesListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeCertificateModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeCertificateResponse(body?: EmployeeCertificateModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeCertificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeCertificate(body?: EmployeeCertificateModel): __Observable<string> {
    return this.CreateEmployeeCertificateResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeCertificateId undefined
   * @return Success
   */
  GetEmployeeCertificateByIdResponse(employeeCertificateId: number): __Observable<__StrictHttpResponse<EmployeeCertificateModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeCertificate/${encodeURIComponent(String(employeeCertificateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeCertificateModel>;
      })
    );
  }
  /**
   * @param employeeCertificateId undefined
   * @return Success
   */
  GetEmployeeCertificateById(employeeCertificateId: number): __Observable<EmployeeCertificateModel> {
    return this.GetEmployeeCertificateByIdResponse(employeeCertificateId).pipe(
      __map(_r => _r.body as EmployeeCertificateModel)
    );
  }

  /**
   * @param params The `EmployeeCertificateService.UpdateEmployeeCertificateParams` containing the following parameters:
   *
   * - `employeeCertificateId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeCertificateResponse(params: EmployeeCertificateService.UpdateEmployeeCertificateParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeCertificate/${encodeURIComponent(String(params.employeeCertificateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeCertificateService.UpdateEmployeeCertificateParams` containing the following parameters:
   *
   * - `employeeCertificateId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeCertificate(params: EmployeeCertificateService.UpdateEmployeeCertificateParams): __Observable<string> {
    return this.UpdateEmployeeCertificateResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeCertificateId undefined
   * @return Success
   */
  DeleteEmployeeCertificateResponse(employeeCertificateId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeCertificate/${encodeURIComponent(String(employeeCertificateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeCertificateId undefined
   * @return Success
   */
  DeleteEmployeeCertificate(employeeCertificateId: number): __Observable<string> {
    return this.DeleteEmployeeCertificateResponse(employeeCertificateId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  ImportEmployeeEducationResponse(file?: Blob): __Observable<__StrictHttpResponse<CertificateUploadStatusModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeCertificate/Excel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CertificateUploadStatusModel>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  ImportEmployeeEducation(file?: Blob): __Observable<CertificateUploadStatusModel> {
    return this.ImportEmployeeEducationResponse(file).pipe(
      __map(_r => _r.body as CertificateUploadStatusModel)
    );
  }
}

module EmployeeCertificateService {

  /**
   * Parameters for UpdateEmployeeCertificate
   */
  export interface UpdateEmployeeCertificateParams {
    employeeCertificateId: number;
    body?: EmployeeCertificateModel;
  }
}

export { EmployeeCertificateService }
