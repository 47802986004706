<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <div class="grid">
          <div class="col-fixed"><h1>ข้อมูลภาษี</h1></div>
          <div class="col"></div>
          <div class="col-fixed">
            <button
              *ngIf="isEditable"
              pButton
              type="button"
              (click)="save()"
              label="{{ 'record' | translate }}"
            ></button>
          </div>
        </div>
        <hr />
        <div class="grid card" *ngIf="isVisibleEmployee">
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>ตั้งค่าการคำนวณภาษี</h1>
            </div>
            <div class="col"></div>
            <div class="col-fixed"></div>
          </div>
          <div class="col-12">
            <form [formGroup]="taxCalculationForm">
              <formly-form [model]="model" [fields]="taxCalculationFields" [form]="taxCalculationForm"> </formly-form>
            </form>
          </div>
        </div>
        <div class="grid card">
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>การลดหย่อน</h1>
            </div>
            <div class="col"></div>
            <div class="col-fixed"></div>
          </div>
          <div class="col-12">
            <form [formGroup]="taxDeductionForm">
              <formly-form [model]="model" [fields]="taxDeductionFields" [form]="taxDeductionForm"> </formly-form>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-w-title">
        <div class="grid">
          <div class="col-fixed"><h1>ข้อมูลภาษีก่อนเข้าเป็นพนักงาน</h1></div>
          <div class="col"></div>
          <div class="col-fixed">
            <button
              *ngIf="isEditable"
              pButton
              type="button"
              (click)="saveBeforeHire()"
              label="{{ 'record' | translate }}"
            ></button>
          </div>
        </div>
        <hr />
        <div class="col-12">
          <form [formGroup]="taxBeforeHireForm">
            <formly-form [model]="modelBeforeHire" [fields]="taxBeforeHireFields" [form]="taxBeforeHireForm"> </formly-form>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
