import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ModalsModule } from "../../modals/modals.module";
import { RepoModule } from "../../repo/repo.module";
import { AdditionalComponent } from "./additional/additional.component";
import { CoreCertificateComponent } from "./certificate/certificate.component";
import { CompetenceComponent } from "./competence/competence.component";
import { ContactInfoComponent } from "./contact-info/contact-info.component";
import { EducationsComponent } from "./educations/educations.component";
import { FamilyInfoComponent } from "./family-info/family-info.component";
import { FileComponent } from "./file/file.component";
import { GeneralInfoComponent } from "./general-info/general-info.component";
import { HealthInfoComponent } from "./health-info/health-info.component";
import { ImportantInfoComponent } from "./important-info/important-info.component";
import { OtherInfoComponent } from "./other-info/other-info.component";
import { PastEmploymentComponent } from "./past-employment/past-employment.component";
import { PerformanceComponent } from "./performance/performance.component";
import { PunishmentComponent } from "./punishment/punishment.component";
import { PvdComponent } from "./pvd/pvd.component";
import { SalaryComponent } from "./salary/salary.component";
import { AssetFormComponent } from "./asset-form/asset-form.component";
import { WorkScheduleComponent } from "./work-schedule/work-schedule.component";
import { EmployeeViewComponent } from "./employee-view/employee-view.component";
import { EmployeeCardComponent } from "./employee-card/employee-card.component";
import { EmployeeListCardComponent } from "./employee-list-card/employee-list-card.component";
import { EmployeeListRowComponent } from "./employee-list-row/employee-list-row.component";
import { EmployeeListTableComponent } from "./employee-list-table/employee-list-table.component";
import { TaxInfoComponent } from "./tax-info/TaxInfo.component";
import { TimelineComponent } from "./timeline/timeline.component";
import { ResignationFormComponent } from "./resignation-form/resignation-form.component";
import { SharedModule } from "../../shared/shared.module";
import { UnitEditComponent } from "./unit-edit/unit-edit.component";
import { EmployeeBaseComponentsModule } from "./employee-base-component.module";
import { EmployeeTaSettingsComponent } from "./employee-ta-settings/employee-ta-settings.component";
import { TrainingComponent } from "./training/training.component";
import { InsigniaComponent } from "./insignia/insignia.component";

const sharedComponent: any[] = [
  AdditionalComponent,
  AssetFormComponent,
  CoreCertificateComponent,
  CompetenceComponent,
  ContactInfoComponent,
  EducationsComponent,
  EmployeeCardComponent,
  EmployeeListCardComponent,
  EmployeeListRowComponent,
  EmployeeListTableComponent,
  EmployeeViewComponent,
  FamilyInfoComponent,
  FileComponent,
  GeneralInfoComponent,
  HealthInfoComponent,
  ImportantInfoComponent,
  OtherInfoComponent,
  PastEmploymentComponent,
  PerformanceComponent,
  PunishmentComponent,
  PvdComponent,
  ResignationFormComponent,
  SalaryComponent,
  TaxInfoComponent,
  TimelineComponent,
  WorkScheduleComponent,
  EmployeeTaSettingsComponent,
  TrainingComponent,
  InsigniaComponent
];
const modules: any[] = [
  EmployeeBaseComponentsModule
]
@NgModule({
  declarations: sharedComponent,
  exports: [...sharedComponent, ...modules],
  imports: [
    CommonModule,
    SharedModule,
    ModalsModule,
    RepoModule,
    ...modules
  ],
})
export class EmployeeComponentsModule { }
