import { Component, OnDestroy, OnInit } from '@angular/core';
import { PersonnelAreaModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject, ReplaySubject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PersonnelAreaService } from 'app/api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

export enum ModalPersonnelAreaMode {
  UPDATE = 'UPDATE',
  CREATE_PERSONNEL_AREA = 'CREATE_PERSONNEL_AREA',
}

interface ManagerModel {
  positionId: number;
  name: string;
}

interface FormModel extends PersonnelAreaModel {
  managerPositions?: ManagerModel[];
}

interface PersonnelFormModel extends FormlyFieldConfig {
  key?: keyof FormModel;
  fieldArray?: PersonnelFormModel;
}

@Component({
  selector: 'app-modal-personnel-area',
  templateUrl: './modal-personnel-area.component.html',
  styleUrls: ['./modal-personnel-area.component.css'],
})
export class ModalPersonnelAreaComponent implements OnInit, OnDestroy {
  companyIdChange = new ReplaySubject(1);

  model: FormModel = {};
  form = new FormGroup({});
  fields: PersonnelFormModel[] = [
    {
      key: 'code',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'code',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 32,
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'name',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 128,
      },
    },
    {
      key: 'parentId',
      type: 'select-personnel-area',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'อยู่ภายใต้',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        companyChange: this.companyIdChange,
        isWaitCompanyId: true,
        // options: this.getPersonnelAreaSelectList(),
      },
    },
    {
      key: 'managerPositions',
      type: 'select-position',
      templateOptions: {
        translate: true,
        label: 'supervisor',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
        addSectionLabel: 'เลือกจากรายการ',
        isSingleSelect: true,
      },

      fieldArray: {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            key: 'name',
            type: 'primeng-input',
            className: 'col-12',
            templateOptions: {
              labelClass: 'col-4',
              inputClass: 'col-8',
              disabled: true,
              required: true,
            },
          },
        ],
      },
    },
  ];
  unsubscripe$ = new Subject<any>();
  modalMode: ModalPersonnelAreaMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private personnelAreaService: PersonnelAreaService,
    private noti: AppNotificationServiceService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscripe$.unsubscribe();
  }

  initComponentMode() {
    const personnelAreaId = this.getPersonnelAreaId();

    if (personnelAreaId) {
      this.modalMode = ModalPersonnelAreaMode.UPDATE;
    } else {
      this.modalMode = ModalPersonnelAreaMode.CREATE_PERSONNEL_AREA;
    }
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'parentId').templateOptions.options = await this.getPersonnelAreaSelectList(
      this.config.data.companyId,
    ).toPromise();
  }

  async initData() {
    if (this.modalMode === ModalPersonnelAreaMode.CREATE_PERSONNEL_AREA) {
      const companyId = this.config.data?.companyId;
      this.companyIdChange.next(companyId);
      if (!companyId) {
        this.close(); // FIXME: handle this
      }
      this.model = { companyId };

      return;
    }

    const personnelArea = await this.personnelAreaService.GetPersonnelAreaById(this.getPersonnelAreaId()).toPromise();
    this.model = {
      ...personnelArea,
      managerPositions: personnelArea.managerPositionId
        ? [{ positionId: personnelArea.managerPositionId, name: personnelArea.managerPositionName }]
        : [],
    };
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    switch (this.modalMode) {
      // case ModalPersonnelAreaMode.CREATE_COMPANY:
      //   this.createCompany().subscribe(
      //     () => {
      //       this.noti.saveCompleted();
      //       this.ref.close(this.model);
      //     },
      //     (err: HttpErrorResponse) => {
              // const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              // this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
      //     }
      //   );d
      //   break;
      case ModalPersonnelAreaMode.CREATE_PERSONNEL_AREA:
        console.log(this.model);
        this.createPersonnelArea()
          .subscribe(
            () => {
              this.noti.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ModalPersonnelAreaMode.UPDATE:
        this.updatePersonnelArea()
          .subscribe(
            () => {
              this.noti.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private getPersonnelAreaSelectList(companyId: number) {
    return this.personnelAreaService
      .GetPersonnelAreasList({
        companyId,
      })
      .pipe(
        map(res => {
          return res.map(personnelArea => {
            return {
              label: personnelArea.name,
              value: personnelArea.personnelAreaId,
            };
          });
        }),
      );
  }

  private createPersonnelArea() {
    return this.personnelAreaService.CreatePersonnelArea({
      code: this.model.code,
      name: this.model.name,
      parentId: this.model.parentId,
      companyId: this.model.companyId,
      managerPositionId: this.model.managerPositions[0]?.positionId,
    });
  }

  private updatePersonnelArea() {
    return this.personnelAreaService.UpdatePersonnelArea({
      personnelAreaId: this.model.personnelAreaId,
      name: this.model.name,
      code: this.model.code,
      companyId: this.model.companyId,
      parentId: this.model.parentId,
      status: this.model.status,
      createdDate: this.model.createdDate,
      createdBy: this.model.createdBy,
      managerPositionId: this.model.managerPositions[0]?.positionId,
    });
  }

  getPersonnelAreaId() {
    return this.config.data?.id;
  }
}
