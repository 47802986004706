/* tslint:disable */
type EOTRequestStatus =
  'Draft' |
  'Editing' |
  'Awaiting' |
  'Approved' |
  'AwaitingBeforeWork' |
  'AwaitingAfterWork' |
  'AwaitingNameList' |
  'Cancel' |
  'Reject';
module EOTRequestStatus {
  export const DRAFT: EOTRequestStatus = 'Draft';
  export const EDITING: EOTRequestStatus = 'Editing';
  export const AWAITING: EOTRequestStatus = 'Awaiting';
  export const APPROVED: EOTRequestStatus = 'Approved';
  export const AWAITING_BEFORE_WORK: EOTRequestStatus = 'AwaitingBeforeWork';
  export const AWAITING_AFTER_WORK: EOTRequestStatus = 'AwaitingAfterWork';
  export const AWAITING_NAME_LIST: EOTRequestStatus = 'AwaitingNameList';
  export const CANCEL: EOTRequestStatus = 'Cancel';
  export const REJECT: EOTRequestStatus = 'Reject';
  export function values(): EOTRequestStatus[] {
    return [
      DRAFT,
      EDITING,
      AWAITING,
      APPROVED,
      AWAITING_BEFORE_WORK,
      AWAITING_AFTER_WORK,
      AWAITING_NAME_LIST,
      CANCEL,
      REJECT
    ];
  }
}

export { EOTRequestStatus }