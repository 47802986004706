import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { UserModel, EAuthenticationType, UserUnitModel } from 'app/api/models';
import { UserService } from 'app/api/services';

import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import * as moment from 'moment';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from 'services/authentication.service';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof UserFormModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

interface UserFormModel extends UserModel {
  roleIds?: number[];
  password?: string;
  passwordConfirm?: string;
  unitIds?: number[];
  resignedDate?: string;
  resignedReason?: string;
  isNotSpecifyResignedDate?: boolean;
}

interface AuthenticationTypeOption {
  label: string;
  value: EAuthenticationType;
}

@Component({
  selector: 'app-modal-user',
  templateUrl: './modal-user.component.html',
  styleUrls: ['./modal-user.component.css'],
  styles: [
    `
      .checkbox {
        margin-top: 10px;
      }
    `,
  ],
})
export class ModalUserComponent implements OnInit, OnDestroy {
  oldAuthType: EAuthenticationType;
  componentMode: ComponentMode = ComponentMode.CREATE;
  model: UserFormModel;
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'roleIds',
      type: 'multi-select',
      wrappers: ['inline-label'],
      hideExpression: () => !this.authenticationService.isSuperAdmin(),
      templateOptions: {
        translate: true,

        label: 'choose_user_type',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'companyIds',
      type: 'multi-select',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'choose_company',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: this.optionListRepo.getCompanyOptionList({}),
      },
    },
    //
    {
      key: 'canSubrogate',
      type: 'checkbox',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'can_right',
        placeholder: '@blank',
        inputClass: 'col-offset-4 col-6',
      },
    },
    {
      key: 'unitIds',
      type: 'select-unit',
      wrappers: ['inline-label'],
      hideExpression: (model: UserFormModel) => !model.canSubrogate,
      templateOptions: {
        // translate: true,
        // required: true,

        label: 'หน่วยงานที่รับผิดชอบ',
        labelClass: 'col-4',
        inputClass: 'col-8',
        isMultiSelectMode: true,
      },
    },
    {
      key: 'employeeId',
      type: 'select-employee',
      wrappers: ['inline-label'],
      expressionProperties: {
        'templateOptions.required': (model: UserFormModel) => {
          return model.enableLoginAsEmployee;
        },
      },
      templateOptions: {
        translate: true,
        label: 'identify_employees',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        // required: true,
        successInitEmployee: () => {
          this.isSuccessInitEmployeeData = true;
        },
      },
    },
    {
      key: 'enableLoginAsAdmin',
      type: 'checkbox',
      wrappers: ['inline-label'],
      defaultValue: false,
      templateOptions: {
        translate: true,
        label: 'login_are_e',
        placeholder: '@blank',
        inputClass: 'col-offset-4 col-6',
      },
    },
    {
      key: 'enableLoginAsEmployee',
      type: 'checkbox',
      wrappers: ['inline-label'],
      defaultValue: false,
      templateOptions: {
        translate: true,
        label: 'login_are_a',
        placeholder: '@blank',
        inputClass: 'col-offset-4 col-6',
      },
    },
    {
      key: 'authenticationType',
      type: 'radio',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'login_type',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: [
          { label: 'Username และ Password', value: 'Password' },
          { label: 'Google', value: 'GoogleAccount' },
        ] as AuthenticationTypeOption[],
      },
    },
    {
      key: 'googleEmail',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      expressionProperties: {
        'templateOptions.disabled': (model: UserFormModel) => model.authenticationType !== 'GoogleAccount',
      },
      templateOptions: {
        translate: true,
        required: true,

        label: 'email',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',

        maxLength: 32,
      },
      validators: {
        validation: ['email'],
      },
    },
    {
      key: 'username',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      expressionProperties: {
        'templateOptions.disabled': (model: UserFormModel) => model.authenticationType !== 'Password',
      },
      templateOptions: {
        translate: true,
        required: true,

        label: 'set_user',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',

        maxLength: 64,
      },
    },
    {
      validators: {
        validation: [{ name: 'fieldMatch', options: { errorPath: 'passwordConfirm' } }],
      },
      fieldGroup: [],
    },
    {
      key: 'password',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      expressionProperties: {
        'templateOptions.disabled': (model: UserFormModel) => model.authenticationType !== 'Password',
        'templateOptions.required': () =>
          this.oldAuthType === 'GoogleAccount' || this.componentMode === ComponentMode.CREATE,
      },
      templateOptions: {
        translate: true,
        attributes: {
          autocomplete: 'new-password',
        },
        label: 'set_pass',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        // required: true,
        maxLength: 1024,
        type: 'password',
        minLength: 8,
      },
      // hooks: {
      //   afterContentInit: field => {
      //     field.templateOptions.required = this.componentMode === ComponentMode.CREATE;
      //   },
      // },
      // validators: {
      //   validation: ['password'],
      // },
    },
    {
      expressionProperties: {
        'templateOptions.disabled': (model: UserFormModel) => model.authenticationType !== 'Password',
        'templateOptions.required': (model: UserFormModel) => !!model.password,
      },
      key: 'passwordConfirm',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,

        label: 'confirm_setting_pass',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',

        maxLength: 1024,
        type: 'password',
      },
      hooks: {
        afterContentInit: field => {
          field.templateOptions.required = this.componentMode === ComponentMode.CREATE;
        },
      },
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          wrappers: ['inline-label'],
          className: 'col-4',
          templateOptions: {
            translate: true,
            required: true,
            label: 'date_use',
            placeholder: '@blank',
            labelClass: 'col-12',
          },
        },
        {
          key: 'startDate',
          type: 'calendar',
          wrappers: ['inline-label'],
          className: 'col-fixed',
          defaultValue: new Date().toISOString(),
          templateOptions: {
            inputClass: 'col-12',
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(9999, 11, 31),
            required: true,
          },
        },

      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          wrappers: ['inline-label'],
          className: 'col-4',
          templateOptions: {
            translate: true,
            required: true,
            label: 'to',
            placeholder: 'to',
            labelClass: 'col-12',
          },
        },
        {
          key: 'terminateDate',
          type: 'calendar',
          wrappers: ['inline-label'],
          className: 'col-fixed',
          defaultValue: new Date(9999, 11, 31).toISOString(),
          templateOptions: {
            inputClass: 'col-12',
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(9999, 11, 31),
            required: true,
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          wrappers: ['inline-label'],
          className: 'col-4',
          templateOptions: {
            translate: true,
            required: true,
            label: 'resigned_date',
            placeholder: '@blank',
            labelClass: 'col-12',
          },
        },
        {
          key: 'resignedDate',
          type: 'calendar',
          wrappers: ['inline-label'],
          className: 'col-fixed',
          templateOptions: {
            inputClass: 'col-12',
            required: true,
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.isNotSpecifyResignedDate',
          },
        },
        {
          key: 'isNotSpecifyResignedDate',
          type: 'checkbox',
          className: 'col-fixed',
          wrappers: ['inline-label'],
          defaultValue: true,
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            label: 'no_date_specified',
            translate: true,
            placeholder: '@blank',
            labelClass: 'col',
          },
        },
      ],
    },

    {
      key: 'resignedReason',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: false,
        label: 'resigned_reason',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 128,
      },
      expressionProperties: {
        'templateOptions.disabled': 'model.isNotSpecifyResignedDate',
      },
    },
    {
      key: 'isEnabled',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      defaultValue: true,
      templateOptions: {
        translate: true,
        required: true,
        label: 'usage_status',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: [
          { label: 'กำลังใช้งาน', value: true },
          { label: 'ระงับใช้งาน', value: false },
        ],
      },
    },
  ];
  unsubscripe$ = new Subject<any>();
  isSuccessInitEmployeeData = false;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private userService: UserService,
    private optionListRepo: OptionListRepo,
    private authenticationService: AuthenticationService,
  ) { }

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();

    if (this.componentMode === ComponentMode.CREATE || !this.model.employeeId) {
      this.isSuccessInitEmployeeData = true;
    }
  }

  initComponentMode() {
    const userId = this.getUserId();

    if (userId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  ngOnDestroy(): void {
    this.unsubscripe$.unsubscribe();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {
        authenticationType: 'Password',
        canSubrogate: false,
        companyIds: [],
        unitIds: [],
      };
      return;
    }

    const user = await this.userService.GetUser(this.getUserId()).toPromise();
    const isNotSpecifyResignedDate = new Date(user.resignedDate) >= new Date(9999, 11, 31);

    if (isNotSpecifyResignedDate === true) {
      user.resignedReason = '';
    }

    this.oldAuthType = user.authenticationType;
    this.model = {
      ...user,
      roleIds: user.userInRoles.map(x => x.roleId),
      unitIds: user.units.map(x => x.unitId),
      resignedReason: user.resignedReason,
      isNotSpecifyResignedDate: isNotSpecifyResignedDate,
    };
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'roleIds').templateOptions.options = await this.optionListRepo
      .getRoleOptionList()
      .toPromise();
  }

  showConfirmDialog() {
    this.notificationService.confirm('บันทึก', 'ยืนยันการบันทึกข้อมูล').then((result: any) => {
      if (result.value) {
        this.submit();
      }
    });
  }

  submit() {
    const model = this.buildCreateModel();
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.userService
          .AddUser(model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.userService
          .UpdateUser(model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  buildCreateModel(): UserModel {
    if (this.model.isNotSpecifyResignedDate) {
      this.model.resignedDate = '9999-12-31';
    }

    return {
      ...this.model,
      rawPassword: this.model.password,
      userInRoles: this.model.roleIds.map(roleId => {
        return { roleId };
      }),
      units: this.model.canSubrogate
        ? this.model.unitIds.map<UserUnitModel>(unitId => {
          return { unitId };
        })
        : [],
      resignedDate: this.model.resignedDate,
    };
  }

  getUserId() {
    return +this.config.data?.id;
  }
}
