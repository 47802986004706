/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeEmergencyContactModel } from '../models/employee-emergency-contact-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeEmergencyContactService extends __BaseService {
  static readonly GetEmployeeEmergencyContactsListPath = '/api/EmployeeEmergencyContact';
  static readonly CreateEmployeeEmergencyContactPath = '/api/EmployeeEmergencyContact';
  static readonly UpdateEmployeeEmergencyContactPath = '/api/EmployeeEmergencyContact';
  static readonly GetEmployeeEmergencyContactByIdPath = '/api/EmployeeEmergencyContact/{employeeEmergencyContactId}';
  static readonly DeleteEmployeeEmergencyContactPath = '/api/EmployeeEmergencyContact/{employeeEmergencyContactId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeEmergencyContactsListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeEmergencyContactModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeEmergencyContact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeEmergencyContactModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeEmergencyContactsList(): __Observable<Array<EmployeeEmergencyContactModel>> {
    return this.GetEmployeeEmergencyContactsListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeEmergencyContactModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeEmergencyContactResponse(body?: EmployeeEmergencyContactModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeEmergencyContact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeEmergencyContact(body?: EmployeeEmergencyContactModel): __Observable<string> {
    return this.CreateEmployeeEmergencyContactResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeEmergencyContactResponse(body?: EmployeeEmergencyContactModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeEmergencyContact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeEmergencyContact(body?: EmployeeEmergencyContactModel): __Observable<string> {
    return this.UpdateEmployeeEmergencyContactResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeEmergencyContactId undefined
   * @return Success
   */
  GetEmployeeEmergencyContactByIdResponse(employeeEmergencyContactId: number): __Observable<__StrictHttpResponse<EmployeeEmergencyContactModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeEmergencyContact/${encodeURIComponent(String(employeeEmergencyContactId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeEmergencyContactModel>;
      })
    );
  }
  /**
   * @param employeeEmergencyContactId undefined
   * @return Success
   */
  GetEmployeeEmergencyContactById(employeeEmergencyContactId: number): __Observable<EmployeeEmergencyContactModel> {
    return this.GetEmployeeEmergencyContactByIdResponse(employeeEmergencyContactId).pipe(
      __map(_r => _r.body as EmployeeEmergencyContactModel)
    );
  }

  /**
   * @param employeeEmergencyContactId undefined
   * @return Success
   */
  DeleteEmployeeEmergencyContactResponse(employeeEmergencyContactId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeEmergencyContact/${encodeURIComponent(String(employeeEmergencyContactId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeEmergencyContactId undefined
   * @return Success
   */
  DeleteEmployeeEmergencyContact(employeeEmergencyContactId: number): __Observable<string> {
    return this.DeleteEmployeeEmergencyContactResponse(employeeEmergencyContactId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeEmergencyContactService {
}

export { EmployeeEmergencyContactService }
