/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PositionModelPaginationResult } from '../models/position-model-pagination-result';
import { PositionModel } from '../models/position-model';
import { PositionListItemModelPaginationResult } from '../models/position-list-item-model-pagination-result';
import { PositionListItemModel } from '../models/position-list-item-model';
import { ChainOfCommandModel } from '../models/chain-of-command-model';
import { ClearTerminatedPositionModel } from '../models/clear-terminated-position-model';
@Injectable({
  providedIn: 'root',
})
class PositionService extends __BaseService {
  static readonly GetPositionsPath = '/api/Position';
  static readonly CreatePositionPath = '/api/Position';
  static readonly GetPositionItemListPath = '/api/Position/itemlist';
  static readonly GetPositionsListPath = '/api/Position/byunit';
  static readonly GetPositionAsOfDatePath = '/api/Position/{positionId}/asofdate';
  static readonly GetPositionPath = '/api/Position/{positionId}';
  static readonly EditPositionPath = '/api/Position/{PositionId}';
  static readonly DeletePositionPath = '/api/Position/{PositionId}';
  static readonly UpdateDirectBossFromOrganizationPath = '/api/Position/run/updateDirectBossFromOrganization';
  static readonly GetLinkFromIdPath = '/api/Position/{positionId}/ChainOfCommand';
  static readonly GetAllSubordinatesPath = '/api/Position/{positionId}/subordinates';
  static readonly CleanPositionPath = '/api/Position/CleanPosition';
  static readonly ClearTerminatedPositionPath = '/run/clearTerminatedPosition';
  static readonly UpdatePositionCodePath = '/run/updatePositionCode';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PositionService.GetPositionsParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsEmptyEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `EffectiveDate`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetPositionsResponse(params: PositionService.GetPositionsParams): __Observable<__StrictHttpResponse<PositionModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsEmptyEmployee != null) __params = __params.set('IsEmptyEmployee', params.IsEmptyEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.EffectiveDate != null) __params = __params.set('EffectiveDate', params.EffectiveDate.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Position`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PositionModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `PositionService.GetPositionsParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsEmptyEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `EffectiveDate`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetPositions(params: PositionService.GetPositionsParams): __Observable<PositionModelPaginationResult> {
    return this.GetPositionsResponse(params).pipe(
      __map(_r => _r.body as PositionModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreatePositionResponse(body?: PositionModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Position`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreatePosition(body?: PositionModel): __Observable<string> {
    return this.CreatePositionResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `PositionService.GetPositionItemListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsEmptyEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `EffectiveDate`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetPositionItemListResponse(params: PositionService.GetPositionItemListParams): __Observable<__StrictHttpResponse<PositionListItemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsEmptyEmployee != null) __params = __params.set('IsEmptyEmployee', params.IsEmptyEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.EffectiveDate != null) __params = __params.set('EffectiveDate', params.EffectiveDate.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Position/itemlist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PositionListItemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `PositionService.GetPositionItemListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsEmptyEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `EffectiveDate`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetPositionItemList(params: PositionService.GetPositionItemListParams): __Observable<PositionListItemModelPaginationResult> {
    return this.GetPositionItemListResponse(params).pipe(
      __map(_r => _r.body as PositionListItemModelPaginationResult)
    );
  }

  /**
   * @param unitId undefined
   * @return Success
   */
  GetPositionsListResponse(unitId?: number): __Observable<__StrictHttpResponse<Array<PositionListItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (unitId != null) __params = __params.set('unitId', unitId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Position/byunit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PositionListItemModel>>;
      })
    );
  }
  /**
   * @param unitId undefined
   * @return Success
   */
  GetPositionsList(unitId?: number): __Observable<Array<PositionListItemModel>> {
    return this.GetPositionsListResponse(unitId).pipe(
      __map(_r => _r.body as Array<PositionListItemModel>)
    );
  }

  /**
   * @param params The `PositionService.GetPositionAsOfDateParams` containing the following parameters:
   *
   * - `positionId`:
   *
   * - `effectiveDate`:
   *
   * @return Success
   */
  GetPositionAsOfDateResponse(params: PositionService.GetPositionAsOfDateParams): __Observable<__StrictHttpResponse<PositionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.effectiveDate != null) __params = __params.set('effectiveDate', params.effectiveDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Position/${encodeURIComponent(String(params.positionId))}/asofdate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PositionModel>;
      })
    );
  }
  /**
   * @param params The `PositionService.GetPositionAsOfDateParams` containing the following parameters:
   *
   * - `positionId`:
   *
   * - `effectiveDate`:
   *
   * @return Success
   */
  GetPositionAsOfDate(params: PositionService.GetPositionAsOfDateParams): __Observable<PositionModel> {
    return this.GetPositionAsOfDateResponse(params).pipe(
      __map(_r => _r.body as PositionModel)
    );
  }

  /**
   * @param positionId undefined
   * @return Success
   */
  GetPositionResponse(positionId: number): __Observable<__StrictHttpResponse<PositionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Position/${encodeURIComponent(String(positionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PositionModel>;
      })
    );
  }
  /**
   * @param positionId undefined
   * @return Success
   */
  GetPosition(positionId: number): __Observable<PositionModel> {
    return this.GetPositionResponse(positionId).pipe(
      __map(_r => _r.body as PositionModel)
    );
  }

  /**
   * @param params The `PositionService.EditPositionParams` containing the following parameters:
   *
   * - `PositionId`:
   *
   * - `body`:
   *
   * @return Success
   */
  EditPositionResponse(params: PositionService.EditPositionParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Position/${encodeURIComponent(String(params.PositionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `PositionService.EditPositionParams` containing the following parameters:
   *
   * - `PositionId`:
   *
   * - `body`:
   *
   * @return Success
   */
  EditPosition(params: PositionService.EditPositionParams): __Observable<string> {
    return this.EditPositionResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param PositionId undefined
   * @return Success
   */
  DeletePositionResponse(PositionId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Position/${encodeURIComponent(String(PositionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param PositionId undefined
   * @return Success
   */
  DeletePosition(PositionId: number): __Observable<string> {
    return this.DeletePositionResponse(PositionId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param companyId undefined
   * @return Success
   */
  UpdateDirectBossFromOrganizationResponse(companyId?: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (companyId != null) __params = __params.set('companyId', companyId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Position/run/updateDirectBossFromOrganization`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param companyId undefined
   * @return Success
   */
  UpdateDirectBossFromOrganization(companyId?: number): __Observable<string> {
    return this.UpdateDirectBossFromOrganizationResponse(companyId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param positionId undefined
   * @return Success
   */
  GetLinkFromIdResponse(positionId: number): __Observable<__StrictHttpResponse<ChainOfCommandModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Position/${encodeURIComponent(String(positionId))}/ChainOfCommand`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChainOfCommandModel>;
      })
    );
  }
  /**
   * @param positionId undefined
   * @return Success
   */
  GetLinkFromId(positionId: number): __Observable<ChainOfCommandModel> {
    return this.GetLinkFromIdResponse(positionId).pipe(
      __map(_r => _r.body as ChainOfCommandModel)
    );
  }

  /**
   * @param params The `PositionService.GetAllSubordinatesParams` containing the following parameters:
   *
   * - `positionId`:
   *
   * - `asOfDate`:
   *
   * @return Success
   */
  GetAllSubordinatesResponse(params: PositionService.GetAllSubordinatesParams): __Observable<__StrictHttpResponse<Array<PositionListItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.asOfDate != null) __params = __params.set('asOfDate', params.asOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Position/${encodeURIComponent(String(params.positionId))}/subordinates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PositionListItemModel>>;
      })
    );
  }
  /**
   * @param params The `PositionService.GetAllSubordinatesParams` containing the following parameters:
   *
   * - `positionId`:
   *
   * - `asOfDate`:
   *
   * @return Success
   */
  GetAllSubordinates(params: PositionService.GetAllSubordinatesParams): __Observable<Array<PositionListItemModel>> {
    return this.GetAllSubordinatesResponse(params).pipe(
      __map(_r => _r.body as Array<PositionListItemModel>)
    );
  }

  /**
   * @return Success
   */
  CleanPositionResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Position/CleanPosition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  CleanPosition(): __Observable<string> {
    return this.CleanPositionResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  ClearTerminatedPositionResponse(): __Observable<__StrictHttpResponse<ClearTerminatedPositionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/run/clearTerminatedPosition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClearTerminatedPositionModel>;
      })
    );
  }
  /**
   * @return Success
   */
  ClearTerminatedPosition(): __Observable<ClearTerminatedPositionModel> {
    return this.ClearTerminatedPositionResponse().pipe(
      __map(_r => _r.body as ClearTerminatedPositionModel)
    );
  }

  /**
   * @return Success
   */
  UpdatePositionCodeResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/run/updatePositionCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  UpdatePositionCode(): __Observable<string> {
    return this.UpdatePositionCodeResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module PositionService {

  /**
   * Parameters for GetPositions
   */
  export interface GetPositionsParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsEmptyEmployee?: boolean;
    IsAllEmployee?: boolean;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    EffectiveDate?: string;
    CompanyId?: number;
    BranchId?: number;
  }

  /**
   * Parameters for GetPositionItemList
   */
  export interface GetPositionItemListParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsEmptyEmployee?: boolean;
    IsAllEmployee?: boolean;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    EffectiveDate?: string;
    CompanyId?: number;
    BranchId?: number;
  }

  /**
   * Parameters for GetPositionAsOfDate
   */
  export interface GetPositionAsOfDateParams {
    positionId: number;
    effectiveDate?: string;
  }

  /**
   * Parameters for EditPosition
   */
  export interface EditPositionParams {
    PositionId: number;
    body?: PositionModel;
  }

  /**
   * Parameters for GetAllSubordinates
   */
  export interface GetAllSubordinatesParams {
    positionId: number;
    asOfDate?: string;
  }
}

export { PositionService }
