<div class="p-fluid">
    <div class="grid">
      <div class="col-12">
        <div class="card card-w-title">
          <h1>{{ 'ข้อมูลการฝึกอบรม' | translate }}</h1>
          <hr />
          <div class="card">
            <div class="grid justify-content-between">
              <div class="col-fixed">
                <h1>ข้อมูลการฝึกอบรม</h1>
              </div>
              <div class="col"></div>
              <div class="col-fixed">
                <button
                  *ngIf="isEditable"
                  pButton
                  type="button"
                  label="{{ 'add_data' | translate }}"
                  (click)="showCreateTrainingDialog()"
                ></button>
              </div>
            </div>
            <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="trainingList">
              <ng-template pTemplate="header">
                <tr>
                  <th>หัวข้อการฝึกอบรม</th>
                  <th>วันที่เริ่มฝึก</th>
                  <th>วันที่จบการฝึก</th>
                  <th>สถาบัน</th>
                  <th>ไฟล์แนบ</th>
                  <th>หมายเหตุ</th>
                  <th *ngIf="isEditable" style="width: 100px">Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td>{{ rowData.courseName }}</td>
                  <td>{{ rowData.startDate | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ rowData.endDate | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ rowData.institute }}</td>
                  <td>
                    <span *ngIf="!rowData.documentId">N/A</span>
                    <a *ngIf="rowData.documentId" href="/api/document/{{ rowData.documentId }}/download">download</a>
                  </td>
                  <td>{{ rowData.remark }}</td>
                  <td *ngIf="isEditable">
                    <div class="grid justify-content-around icon-action">
                      <div class="col-fixed">
                        <i
                          class="fa fa-pencil-alt"
                          (click)="showUpdateTrainingDialog(rowData.employeeTrainingId)"
                        ></i>
                      </div>
                      <div class="col-fixed">
                        <i class="fa fa-trash" (click)="showDeleteTrainingDialog(rowData.employeeTrainingId)"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" class="emptymessage">ไม่พบข้อมูล</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  