/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ObjectPropertyInfo } from '../models/object-property-info';
@Injectable({
  providedIn: 'root',
})
class MasterDataEditorService extends __BaseService {
  static readonly GetObjectTypeInfoPath = '/api/MasterDataEditor/{dbSetName}/type-info';
  static readonly GetObjectsByTypePath = '/api/MasterDataEditor/{dbSetName}';
  static readonly CreateObjectPath = '/api/MasterDataEditor/{dbSetName}';
  static readonly UpdateObjectPath = '/api/MasterDataEditor/{dbSetName}/{key}';
  static readonly DeleteObjectPath = '/api/MasterDataEditor/{dbSetName}/{key}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param dbSetName undefined
   * @return Success
   */
  GetObjectTypeInfoResponse(dbSetName: string): __Observable<__StrictHttpResponse<Array<ObjectPropertyInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterDataEditor/${encodeURIComponent(String(dbSetName))}/type-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ObjectPropertyInfo>>;
      })
    );
  }
  /**
   * @param dbSetName undefined
   * @return Success
   */
  GetObjectTypeInfo(dbSetName: string): __Observable<Array<ObjectPropertyInfo>> {
    return this.GetObjectTypeInfoResponse(dbSetName).pipe(
      __map(_r => _r.body as Array<ObjectPropertyInfo>)
    );
  }

  /**
   * @param dbSetName undefined
   * @return Success
   */
  GetObjectsByTypeResponse(dbSetName: string): __Observable<__StrictHttpResponse<Array<any>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterDataEditor/${encodeURIComponent(String(dbSetName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<any>>;
      })
    );
  }
  /**
   * @param dbSetName undefined
   * @return Success
   */
  GetObjectsByType(dbSetName: string): __Observable<Array<any>> {
    return this.GetObjectsByTypeResponse(dbSetName).pipe(
      __map(_r => _r.body as Array<any>)
    );
  }

  /**
   * @param params The `MasterDataEditorService.CreateObjectParams` containing the following parameters:
   *
   * - `dbSetName`:
   *
   * - `jsonValue`:
   *
   * @return Success
   */
  CreateObjectResponse(params: MasterDataEditorService.CreateObjectParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.jsonValue != null) __params = __params.set('jsonValue', params.jsonValue.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MasterDataEditor/${encodeURIComponent(String(params.dbSetName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `MasterDataEditorService.CreateObjectParams` containing the following parameters:
   *
   * - `dbSetName`:
   *
   * - `jsonValue`:
   *
   * @return Success
   */
  CreateObject(params: MasterDataEditorService.CreateObjectParams): __Observable<string> {
    return this.CreateObjectResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `MasterDataEditorService.UpdateObjectParams` containing the following parameters:
   *
   * - `key`:
   *
   * - `dbSetName`:
   *
   * - `jsonValue`:
   *
   * @return Success
   */
  UpdateObjectResponse(params: MasterDataEditorService.UpdateObjectParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.jsonValue != null) __params = __params.set('jsonValue', params.jsonValue.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/MasterDataEditor/${encodeURIComponent(String(params.dbSetName))}/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `MasterDataEditorService.UpdateObjectParams` containing the following parameters:
   *
   * - `key`:
   *
   * - `dbSetName`:
   *
   * - `jsonValue`:
   *
   * @return Success
   */
  UpdateObject(params: MasterDataEditorService.UpdateObjectParams): __Observable<string> {
    return this.UpdateObjectResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `MasterDataEditorService.DeleteObjectParams` containing the following parameters:
   *
   * - `key`:
   *
   * - `dbSetName`:
   *
   * @return Success
   */
  DeleteObjectResponse(params: MasterDataEditorService.DeleteObjectParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/MasterDataEditor/${encodeURIComponent(String(params.dbSetName))}/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `MasterDataEditorService.DeleteObjectParams` containing the following parameters:
   *
   * - `key`:
   *
   * - `dbSetName`:
   *
   * @return Success
   */
  DeleteObject(params: MasterDataEditorService.DeleteObjectParams): __Observable<string> {
    return this.DeleteObjectResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module MasterDataEditorService {

  /**
   * Parameters for CreateObject
   */
  export interface CreateObjectParams {
    dbSetName: string;
    jsonValue?: string;
  }

  /**
   * Parameters for UpdateObject
   */
  export interface UpdateObjectParams {
    key: string;
    dbSetName: string;
    jsonValue?: string;
  }

  /**
   * Parameters for DeleteObject
   */
  export interface DeleteObjectParams {
    key: string;
    dbSetName: string;
  }
}

export { MasterDataEditorService }
