/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AccessLogService } from './services/access-log.service';
import { ActionService } from './services/action.service';
import { AESCryptoService } from './services/aescrypto.service';
import { AllowanceService } from './services/allowance.service';
import { AllowancePolicyService } from './services/allowance-policy.service';
import { AllowanceTypeService } from './services/allowance-type.service';
import { AnnouncementService } from './services/announcement.service';
import { ApprovalCenterService } from './services/approval-center.service';
import { AttendanceCertifyService } from './services/attendance-certify.service';
import { AttendanceCertifyRequestService } from './services/attendance-certify-request.service';
import { BackgroundJobService } from './services/background-job.service';
import { BenefitElementService } from './services/benefit-element.service';
import { BenefitEmployeeMonitorService } from './services/benefit-employee-monitor.service';
import { BenefitPolicyService } from './services/benefit-policy.service';
import { BranchService } from './services/branch.service';
import { CertificationService } from './services/certification.service';
import { ChainOfCommandService } from './services/chain-of-command.service';
import { CompanyService } from './services/company.service';
import { CompetencyService } from './services/competency.service';
import { CompetencyTypeService } from './services/competency-type.service';
import { CostCenterService } from './services/cost-center.service';
import { CountryService } from './services/country.service';
import { DashboardService } from './services/dashboard.service';
import { DemographicService } from './services/demographic.service';
import { DocumentService } from './services/document.service';
import { EmailTemplateService } from './services/email-template.service';
import { EmployeeService } from './services/employee.service';
import { EmployeeAddressService } from './services/employee-address.service';
import { EmployeeAssetSurveyService } from './services/employee-asset-survey.service';
import { EmployeeBankAccountService } from './services/employee-bank-account.service';
import { EmployeeCardService } from './services/employee-card.service';
import { EmployeeCertificateService } from './services/employee-certificate.service';
import { EmployeeChronicDiseaseService } from './services/employee-chronic-disease.service';
import { EmployeeCompetenceService } from './services/employee-competence.service';
import { EmployeeContactService } from './services/employee-contact.service';
import { EmployeeCurrentEmploymentService } from './services/employee-current-employment.service';
import { EmployeeDocumentService } from './services/employee-document.service';
import { EmployeeDrugAllergyService } from './services/employee-drug-allergy.service';
import { EmployeeEducationService } from './services/employee-education.service';
import { EmployeeEmergencyContactService } from './services/employee-emergency-contact.service';
import { EmployeeExpiredDocumentService } from './services/employee-expired-document.service';
import { EmployeeHealthCheckService } from './services/employee-health-check.service';
import { EmployeeInPositionService } from './services/employee-in-position.service';
import { EmployeeInShiftService } from './services/employee-in-shift.service';
import { EmployeeInsigniaService } from './services/employee-insignia.service';
import { EmployeeInterestService } from './services/employee-interest.service';
import { EmployeeLanguageService } from './services/employee-language.service';
import { EmployeeNameService } from './services/employee-name.service';
import { EmployeePastEmploymentService } from './services/employee-past-employment.service';
import { EmployeePastOperationService } from './services/employee-past-operation.service';
import { EmployeePerformanceService } from './services/employee-performance.service';
import { EmployeePhysicalMeasurementService } from './services/employee-physical-measurement.service';
import { EmployeePromoteRequestService } from './services/employee-promote-request.service';
import { EmployeePunishmentService } from './services/employee-punishment.service';
import { EmployeeRelativeService } from './services/employee-relative.service';
import { EmployeeSalaryService } from './services/employee-salary.service';
import { EmployeeSocialSecurityService } from './services/employee-social-security.service';
import { EmployeeSurgeryService } from './services/employee-surgery.service';
import { EmployeeTASettingsService } from './services/employee-tasettings.service';
import { EmployeeTrainingService } from './services/employee-training.service';
import { EmployeeTransferService } from './services/employee-transfer.service';
import { EmployeeUpdateInfoRequestService } from './services/employee-update-info-request.service';
import { EmployeeWarningLetterService } from './services/employee-warning-letter.service';
import { EmployeeWorkPermitService } from './services/employee-work-permit.service';
import { FileProcessingTaskService } from './services/file-processing-task.service';
import { FilterSettingService } from './services/filter-setting.service';
import { GlCodeService } from './services/gl-code.service';
import { HolidayService } from './services/holiday.service';
import { HotFixService } from './services/hot-fix.service';
import { IosRedeemedCodeService } from './services/ios-redeemed-code.service';
import { JobService } from './services/job.service';
import { JobFamilyService } from './services/job-family.service';
import { JobGradeService } from './services/job-grade.service';
import { JobGradeOfSystemService } from './services/job-grade-of-system.service';
import { LeaveService } from './services/leave.service';
import { LeaveRequestService } from './services/leave-request.service';
import { LocationService } from './services/location.service';
import { MasterDataService } from './services/master-data.service';
import { MasterDataEditorService } from './services/master-data-editor.service';
import { NewEmployeeService } from './services/new-employee.service';
import { NewsService } from './services/news.service';
import { NotificationService } from './services/notification.service';
import { NotificationTypeService } from './services/notification-type.service';
import { OTService } from './services/ot.service';
import { OTRequestService } from './services/otrequest.service';
import { PayElementService } from './services/pay-element.service';
import { PayrollPeriodService } from './services/payroll-period.service';
import { PaySlipService } from './services/pay-slip.service';
import { PermissionService } from './services/permission.service';
import { PersonnelAreaService } from './services/personnel-area.service';
import { PositionService } from './services/position.service';
import { ProbationEvaluationFormService } from './services/probation-evaluation-form.service';
import { ReportService } from './services/report.service';
import { RequestService } from './services/request.service';
import { RequestDocumentService } from './services/request-document.service';
import { ResignSurveyService } from './services/resign-survey.service';
import { RevenueService } from './services/revenue.service';
import { RevenueChartService } from './services/revenue-chart.service';
import { RoleService } from './services/role.service';
import { RuleEngineService } from './services/rule-engine.service';
import { SettingsService } from './services/settings.service';
import { ShiftService } from './services/shift.service';
import { StarkBPlusLogService } from './services/stark-bplus-log.service';
import { StarkEmployeeService } from './services/stark-employee.service';
import { StarkMasterDataService } from './services/stark-master-data.service';
import { StarkMigrationService } from './services/stark-migration.service';
import { StarkPayrollService } from './services/stark-payroll.service';
import { StarkPaySlipService } from './services/stark-pay-slip.service';
import { StarkReportService } from './services/stark-report.service';
import { SystemService } from './services/system.service';
import { TimeEventService } from './services/time-event.service';
import { TimesheetService } from './services/timesheet.service';
import { TokenService } from './services/token.service';
import { TrainingService } from './services/training.service';
import { UnitService } from './services/unit.service';
import { UnitDefService } from './services/unit-def.service';
import { UnitLevelService } from './services/unit-level.service';
import { UnitLevelOfSystemService } from './services/unit-level-of-system.service';
import { UnitTreeNodeService } from './services/unit-tree-node.service';
import { UserService } from './services/user.service';
import { WorkflowService } from './services/workflow.service';
import { WorkflowConditionService } from './services/workflow-condition.service';
import { WorkforceProfileService } from './services/workforce-profile.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AccessLogService,
    ActionService,
    AESCryptoService,
    AllowanceService,
    AllowancePolicyService,
    AllowanceTypeService,
    AnnouncementService,
    ApprovalCenterService,
    AttendanceCertifyService,
    AttendanceCertifyRequestService,
    BackgroundJobService,
    BenefitElementService,
    BenefitEmployeeMonitorService,
    BenefitPolicyService,
    BranchService,
    CertificationService,
    ChainOfCommandService,
    CompanyService,
    CompetencyService,
    CompetencyTypeService,
    CostCenterService,
    CountryService,
    DashboardService,
    DemographicService,
    DocumentService,
    EmailTemplateService,
    EmployeeService,
    EmployeeAddressService,
    EmployeeAssetSurveyService,
    EmployeeBankAccountService,
    EmployeeCardService,
    EmployeeCertificateService,
    EmployeeChronicDiseaseService,
    EmployeeCompetenceService,
    EmployeeContactService,
    EmployeeCurrentEmploymentService,
    EmployeeDocumentService,
    EmployeeDrugAllergyService,
    EmployeeEducationService,
    EmployeeEmergencyContactService,
    EmployeeExpiredDocumentService,
    EmployeeHealthCheckService,
    EmployeeInPositionService,
    EmployeeInShiftService,
    EmployeeInsigniaService,
    EmployeeInterestService,
    EmployeeLanguageService,
    EmployeeNameService,
    EmployeePastEmploymentService,
    EmployeePastOperationService,
    EmployeePerformanceService,
    EmployeePhysicalMeasurementService,
    EmployeePromoteRequestService,
    EmployeePunishmentService,
    EmployeeRelativeService,
    EmployeeSalaryService,
    EmployeeSocialSecurityService,
    EmployeeSurgeryService,
    EmployeeTASettingsService,
    EmployeeTrainingService,
    EmployeeTransferService,
    EmployeeUpdateInfoRequestService,
    EmployeeWarningLetterService,
    EmployeeWorkPermitService,
    FileProcessingTaskService,
    FilterSettingService,
    GlCodeService,
    HolidayService,
    HotFixService,
    IosRedeemedCodeService,
    JobService,
    JobFamilyService,
    JobGradeService,
    JobGradeOfSystemService,
    LeaveService,
    LeaveRequestService,
    LocationService,
    MasterDataService,
    MasterDataEditorService,
    NewEmployeeService,
    NewsService,
    NotificationService,
    NotificationTypeService,
    OTService,
    OTRequestService,
    PayElementService,
    PayrollPeriodService,
    PaySlipService,
    PermissionService,
    PersonnelAreaService,
    PositionService,
    ProbationEvaluationFormService,
    ReportService,
    RequestService,
    RequestDocumentService,
    ResignSurveyService,
    RevenueService,
    RevenueChartService,
    RoleService,
    RuleEngineService,
    SettingsService,
    ShiftService,
    StarkBPlusLogService,
    StarkEmployeeService,
    StarkMasterDataService,
    StarkMigrationService,
    StarkPayrollService,
    StarkPaySlipService,
    StarkReportService,
    SystemService,
    TimeEventService,
    TimesheetService,
    TokenService,
    TrainingService,
    UnitService,
    UnitDefService,
    UnitLevelService,
    UnitLevelOfSystemService,
    UnitTreeNodeService,
    UserService,
    WorkflowService,
    WorkflowConditionService,
    WorkforceProfileService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
