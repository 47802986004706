/* tslint:disable */
type ETimeEventType =
  'Unknown' |
  'ClockIn' |
  'ClockOut' |
  'BreakStart' |
  'BreakEnd' |
  'Other';
module ETimeEventType {
  export const UNKNOWN: ETimeEventType = 'Unknown';
  export const CLOCK_IN: ETimeEventType = 'ClockIn';
  export const CLOCK_OUT: ETimeEventType = 'ClockOut';
  export const BREAK_START: ETimeEventType = 'BreakStart';
  export const BREAK_END: ETimeEventType = 'BreakEnd';
  export const OTHER: ETimeEventType = 'Other';
  export function values(): ETimeEventType[] {
    return [
      UNKNOWN,
      CLOCK_IN,
      CLOCK_OUT,
      BREAK_START,
      BREAK_END,
      OTHER
    ];
  }
}

export { ETimeEventType }