import { ApprovalListComponent } from './../pages/employee/home/main-task/approval-list/approval-list.component';



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';

import { LayoutDefaultComponent } from './layout-default/layout-default.component';
import { AppMenuComponent } from './layout-default/app-menu/app-menu.component';
import { AppTopbarComponent } from './layout-default/app-menu/app-topbar.component';
import { AppMenuItemComponent } from './layout-default/app-menu/app-menu-item.component';
import { AppRightpanelComponent } from './layout-default/app-menu/app-rightpanel.component';
import { AppConfigComponent } from './layout-default/app-menu/app-config.component';
import { AppFooterComponent } from './layout-default/app-menu/app-footer.component';
import { AppBreadcrumbComponent } from './layout-default/app-breadcrumb/app-breadcrumb.component';

//Change language
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { registerTranslateExtension } from './translate.extension'

import { TranslateService } from '@ngx-translate/core';
import { FORMLY_CONFIG } from '@ngx-formly/core'
import { MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [LayoutDefaultComponent,
    AppMenuComponent,
    AppMenuItemComponent,
    AppTopbarComponent,
    AppRightpanelComponent,
    AppConfigComponent,
    AppFooterComponent, AppBreadcrumbComponent
  ],
  imports: [
    CommonModule,
    BadgeModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forChild()

  ],
  exports: [LayoutDefaultComponent, AppMenuComponent, AppMenuItemComponent, AppTopbarComponent, AppRightpanelComponent, AppConfigComponent],
  providers: [{ provide: FORMLY_CONFIG, multi: true, useFactory: registerTranslateExtension, deps: [TranslateService] }, MessageService]
})
export class LayoutModule { }
