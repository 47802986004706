import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-primeng-checkbox',
  templateUrl: './formly-primeng-checkbox.component.html',
  styleUrls: ['./formly-primeng-checkbox.component.css'],
})
export class FormlyPrimengCheckboxComponent extends FieldType {
  defaultOptions = {
    templateOptions: {
      hideLabel: true,
    },
  };
}
