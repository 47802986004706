<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>{{ 'work_history' | translate }}</h1>
        <hr />
        <div class="card">
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>{{ 'work_history_organization' | translate }}</h1>
            </div>
            <div class="col"></div>
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                label="{{ 'add_data' | translate }}"
                (click)="onCreateInPositionBtnClick()"
              ></button>
            </div>
          </div>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="employeeInPositionList">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'code' | translate }}</th>
                <th>{{ 'position' | translate }}</th>
                <th>{{ 'company' | translate }}</th>
                <th>{{ 'employment_type' | translate }}</th>
                <th>{{ 'date_commencement' | translate }}</th>
                <th>{{ 'eip_terminated_date' | translate }}</th>
                <th *ngIf="isEditable" style="width: 50px">{{ 'action' | translate }}</th>
                <th>{{ 'remark' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.positionCode }}</td>
                <td>{{ rowData.positionName }}</td>
                <td>{{ rowData.companyName }}</td>
                <td>{{ rowData.positionTakingTypeName }}</td>
                <td>{{ rowData.effectiveDate | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rowData.terminateDate | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i class="fa fa-pencil-alt" (click)="showUpdateEmployeeInPositionDialog(rowData.employeeInPositionId)"></i>
                    </div>
                    <div class="col-fixed">
                      <i class="fa fa-trash" (click)="showDeleteEmployeeInPositionDialog(rowData.employeeInPositionId)"></i>
                    </div>
                  </div>
                </td>
                <td>{{ rowData.remark }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" class="emptymessage">{{ 'no_data' | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
          <div class="col-12" style="margin-top: 3%">
            <form [formGroup]="form">
              <formly-form [model]="model" [fields]="fields" [form]="form"> </formly-form>
            </form>
          </div>
          <!-- TODO: Fix this -->
          <div class="grid justify-content-end">
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                (click)="updateCurrentEmployment()"
                label="{{ 'record' | translate }}"
              ></button>
            </div>
          </div>
          <hr style="margin-top: 5px; margin-bottom: 30px" />
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>{{ 'work_history_organization_past' | translate }}</h1>
            </div>
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                label="{{ 'add_data' | translate }}"
                (click)="onCreatePastEmploymentBtnClick()"
              ></button>
            </div>
          </div>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="employeePastEmploymentList">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'last_position' | translate }}</th>
                <th>{{ 'company' | translate }}</th>
                <th>{{ 'start_date_' | translate }}</th>
                <th>{{ 'end_date' | translate }}</th>
                <th *ngIf="isEditable" style="width: 100px">{{ 'action' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.exitPositionName }}</td>
                <td>{{ rowData.employerName }}</td>
                <td>{{ rowData.employeeDate | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rowData.terminateDate | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i
                        class="fa fa-pencil-alt"
                        (click)="showUpdateEmployeePastEmploymentDialog(rowData.employeePastEmploymentId)"
                      ></i>
                    </div>
                    <div class="col-fixed">
                      <i
                        class="fa fa-trash"
                        (click)="showDeleteEmployeePastEmploymentDialog(rowData.employeePastEmploymentId)"
                      ></i>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" class="emptymessage">{{ 'no_data' | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
