/* tslint:disable */
type EOTRequestType =
  'Self' |
  'Other';
module EOTRequestType {
  export const SELF: EOTRequestType = 'Self';
  export const OTHER: EOTRequestType = 'Other';
  export function values(): EOTRequestType[] {
    return [
      SELF,
      OTHER
    ];
  }
}

export { EOTRequestType }