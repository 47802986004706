/* tslint:disable */
type EPayAllowanceRound =
  'Frequency' |
  'Round';
module EPayAllowanceRound {
  export const FREQUENCY: EPayAllowanceRound = 'Frequency';
  export const ROUND: EPayAllowanceRound = 'Round';
  export function values(): EPayAllowanceRound[] {
    return [
      FREQUENCY,
      ROUND
    ];
  }
}

export { EPayAllowanceRound }