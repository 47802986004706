import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService, private router: Router) {}

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
    return next.handle(this.addTokenToRequest(request, this.authService.getAccessToken())).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>err).status) {
            case 401:
              return this.handle401Error(request, next);
          }
        }
        return throwError(err);
      }),
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (request.method === 'GET') {
      return request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${token}`)
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache'),
      });
    } else {
      return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if(this.authService.getAccessToken() == null)
    {
      if(this.router.url.indexOf("new-employee") <= -1){
        this.router.navigate(['/account/login']);
      }
    }
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        catchError(err => {
          this.authService.logout().subscribe(a => {
            console.log('Log out success.');
          });;
          return throwError(err);
        }),
        switchMap((token) => {
          if (token) {
            this.tokenSubject.next(token);
            return next.handle(this.addTokenToRequest(request, token));
          } else {
            this.authService.logout().subscribe(a => {
              console.log('Log out success.');
            });;
          }
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addTokenToRequest(request, token));
        }),
      );
    }
  }
}
