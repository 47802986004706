/* tslint:disable */
type EPayElementSubType =
  'NA' |
  'OT_1_Hour' |
  'OT_15_Hour' |
  'OT_2_Hour' |
  'OT_3_Hour' |
  'LeaveNoIncome_Hour' |
  'LateMinutes' |
  'LateCount' |
  'WorkingDays' |
  'AbsentCount' |
  'RegularIncome' |
  'BaseSalary' |
  'IrregularIncome' |
  'CommuPayment' |
  'Commission' |
  'DeligentAllowance' |
  'Bonus' |
  'OtherIncome' |
  'OT_1' |
  'OT_15' |
  'OT_2' |
  'OT_3' |
  'Allowance' |
  'OtherPay' |
  'BackPay' |
  'BackGuarantee' |
  'RegularDeduct' |
  'SocialSecurity' |
  'Tax' |
  'EduLoanDeduct' |
  'IrregularDeduct' |
  'AbsentDeduct' |
  'LateDeduct' |
  'LeaveNoIncomeDeduct' |
  'OtherDeduct' |
  'GuaranteeDeduct';
module EPayElementSubType {
  export const NA: EPayElementSubType = 'NA';
  export const OT_1_HOUR: EPayElementSubType = 'OT_1_Hour';
  export const OT_15_HOUR: EPayElementSubType = 'OT_15_Hour';
  export const OT_2_HOUR: EPayElementSubType = 'OT_2_Hour';
  export const OT_3_HOUR: EPayElementSubType = 'OT_3_Hour';
  export const LEAVE_NO_INCOME_HOUR: EPayElementSubType = 'LeaveNoIncome_Hour';
  export const LATE_MINUTES: EPayElementSubType = 'LateMinutes';
  export const LATE_COUNT: EPayElementSubType = 'LateCount';
  export const WORKING_DAYS: EPayElementSubType = 'WorkingDays';
  export const ABSENT_COUNT: EPayElementSubType = 'AbsentCount';
  export const REGULAR_INCOME: EPayElementSubType = 'RegularIncome';
  export const BASE_SALARY: EPayElementSubType = 'BaseSalary';
  export const IRREGULAR_INCOME: EPayElementSubType = 'IrregularIncome';
  export const COMMU_PAYMENT: EPayElementSubType = 'CommuPayment';
  export const COMMISSION: EPayElementSubType = 'Commission';
  export const DELIGENT_ALLOWANCE: EPayElementSubType = 'DeligentAllowance';
  export const BONUS: EPayElementSubType = 'Bonus';
  export const OTHER_INCOME: EPayElementSubType = 'OtherIncome';
  export const OT_1: EPayElementSubType = 'OT_1';
  export const OT_15: EPayElementSubType = 'OT_15';
  export const OT_2: EPayElementSubType = 'OT_2';
  export const OT_3: EPayElementSubType = 'OT_3';
  export const ALLOWANCE: EPayElementSubType = 'Allowance';
  export const OTHER_PAY: EPayElementSubType = 'OtherPay';
  export const BACK_PAY: EPayElementSubType = 'BackPay';
  export const BACK_GUARANTEE: EPayElementSubType = 'BackGuarantee';
  export const REGULAR_DEDUCT: EPayElementSubType = 'RegularDeduct';
  export const SOCIAL_SECURITY: EPayElementSubType = 'SocialSecurity';
  export const TAX: EPayElementSubType = 'Tax';
  export const EDU_LOAN_DEDUCT: EPayElementSubType = 'EduLoanDeduct';
  export const IRREGULAR_DEDUCT: EPayElementSubType = 'IrregularDeduct';
  export const ABSENT_DEDUCT: EPayElementSubType = 'AbsentDeduct';
  export const LATE_DEDUCT: EPayElementSubType = 'LateDeduct';
  export const LEAVE_NO_INCOME_DEDUCT: EPayElementSubType = 'LeaveNoIncomeDeduct';
  export const OTHER_DEDUCT: EPayElementSubType = 'OtherDeduct';
  export const GUARANTEE_DEDUCT: EPayElementSubType = 'GuaranteeDeduct';
  export function values(): EPayElementSubType[] {
    return [
      NA,
      OT_1_HOUR,
      OT_15_HOUR,
      OT_2_HOUR,
      OT_3_HOUR,
      LEAVE_NO_INCOME_HOUR,
      LATE_MINUTES,
      LATE_COUNT,
      WORKING_DAYS,
      ABSENT_COUNT,
      REGULAR_INCOME,
      BASE_SALARY,
      IRREGULAR_INCOME,
      COMMU_PAYMENT,
      COMMISSION,
      DELIGENT_ALLOWANCE,
      BONUS,
      OTHER_INCOME,
      OT_1,
      OT_15,
      OT_2,
      OT_3,
      ALLOWANCE,
      OTHER_PAY,
      BACK_PAY,
      BACK_GUARANTEE,
      REGULAR_DEDUCT,
      SOCIAL_SECURITY,
      TAX,
      EDU_LOAN_DEDUCT,
      IRREGULAR_DEDUCT,
      ABSENT_DEDUCT,
      LATE_DEDUCT,
      LEAVE_NO_INCOME_DEDUCT,
      OTHER_DEDUCT,
      GUARANTEE_DEDUCT
    ];
  }
}

export { EPayElementSubType }