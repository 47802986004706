import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeCertificateModel, EmployeeDocumentModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DocumentService, EmployeeDocumentService, MasterDataService } from 'app/api/services';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}
interface SelectFileParam {
  currentFiles: File[];
}

interface ModelFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeDocumentModel;
}

@Component({
  selector: 'app-modal-employee-attachment',
  templateUrl: './modal-employee-attachment.component.html',
  styleUrls: ['./modal-employee-attachment.component.css'],
})
export class ModalEmployeeAttachmentComponent implements OnInit {
  model: EmployeeDocumentModel = {};
  form = new FormGroup({});
  fields: ModelFormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        required: true,
        label: 'name',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'documentId',
      type: 'upload-file',
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        required: true,
        label: 'attach_file',
        placeholder: '@blank',
        chooseLabel: 'อัพโหลด',
        previewWidth: 150,

        labelClass: 'col-4',
        inputClass: 'col-6',
        onSelect: (selectFileParam: SelectFileParam) => {
          this.documentService
            .CreateDocument({
              description: 'Employee Attachment file',
              file: selectFileParam.currentFiles[0],
            })
            .subscribe(result => {
              if (this.componentMode === ComponentMode.CREATE) {
                this.form.get('documentId').setValue(result.documentId);
              } else {
                this.form.get('newDocumentId').setValue(result.documentId);
              }
            });
        },
        onRemove: (_, model: EmployeeDocumentModel) => {
          if (this.componentMode === ComponentMode.CREATE) {
            this.model.documentId = undefined;
          } else {
            this.model.newDocumentId = undefined;
          }
        },
      },
    },
    {
      key: 'description',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: false,
        attributes: {
          style: 'width: 100%',
        },
        label: 'description',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
  ];
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeDocumentService: EmployeeDocumentService,
    private documentService: DocumentService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
  }

  initComponentMode() {
    const docId = this.getDocId();

    if (docId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    const employeeId = this.config.data?.employeeId;
    if (this.componentMode === ComponentMode.CREATE) {
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    this.model = await this.employeeDocumentService
      .GetEmployeeDocument({
        docId: this.getDocId(),
        employeeId,
      })
      .toPromise();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        if (!this.model.documentId) {
          return this.notificationService.error('กรุณาเลือกไฟล์', '');
        }

        this.employeeDocumentService
          .CreateEmployeeDocument(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.employeeDocumentService
          .UpdateEmployeeDocument(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getDocId() {
    return this.config.data?.id;
  }
}
