/* tslint:disable */
type EWorkDayType =
  'Workday' |
  'Dayoff' |
  'Holiday';
module EWorkDayType {
  export const WORKDAY: EWorkDayType = 'Workday';
  export const DAYOFF: EWorkDayType = 'Dayoff';
  export const HOLIDAY: EWorkDayType = 'Holiday';
  export function values(): EWorkDayType[] {
    return [
      WORKDAY,
      DAYOFF,
      HOLIDAY
    ];
  }
}

export { EWorkDayType }