import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'timeFormat' })
export class TimeFormatPipe implements PipeTransform {
  pad2(number) {
    return (number < 10 ? '0' : '') + number
 }

  transform(value : number) : string {
    return Math.floor(value) + ':' + this.pad2(Math.round((value % 1) * 60));
  }
}
