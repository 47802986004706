/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TimeEventModel } from '../models/time-event-model';
@Injectable({
  providedIn: 'root',
})
class TimeEventService extends __BaseService {
  static readonly GetTimeEventByIdPath = '/api/TimeEvent/{timeEventId}';
  static readonly GetTimeEventByDatePath = '/api/TimeEvent/employee/{employeeId}';
  static readonly GetLastTimeEventPath = '/api/TimeEvent/employee/{employeeId}/lasttimeevent';
  static readonly CheckInTimeEventByMobilePath = '/api/TimeEvent/check-in';
  static readonly CheckOutTimeEventByMobilePath = '/api/TimeEvent/check-out';
  static readonly UpdateTimeEventPath = '/api/TimeEvent';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param timeEventId undefined
   * @return Success
   */
  GetTimeEventByIdResponse(timeEventId: number): __Observable<__StrictHttpResponse<TimeEventModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TimeEvent/${encodeURIComponent(String(timeEventId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TimeEventModel>;
      })
    );
  }
  /**
   * @param timeEventId undefined
   * @return Success
   */
  GetTimeEventById(timeEventId: number): __Observable<TimeEventModel> {
    return this.GetTimeEventByIdResponse(timeEventId).pipe(
      __map(_r => _r.body as TimeEventModel)
    );
  }

  /**
   * @param params The `TimeEventService.GetTimeEventByDateParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `date`:
   *
   * @return Success
   */
  GetTimeEventByDateResponse(params: TimeEventService.GetTimeEventByDateParams): __Observable<__StrictHttpResponse<Array<TimeEventModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TimeEvent/employee/${encodeURIComponent(String(params.employeeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TimeEventModel>>;
      })
    );
  }
  /**
   * @param params The `TimeEventService.GetTimeEventByDateParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `date`:
   *
   * @return Success
   */
  GetTimeEventByDate(params: TimeEventService.GetTimeEventByDateParams): __Observable<Array<TimeEventModel>> {
    return this.GetTimeEventByDateResponse(params).pipe(
      __map(_r => _r.body as Array<TimeEventModel>)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetLastTimeEventResponse(employeeId: number): __Observable<__StrictHttpResponse<TimeEventModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TimeEvent/employee/${encodeURIComponent(String(employeeId))}/lasttimeevent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TimeEventModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetLastTimeEvent(employeeId: number): __Observable<TimeEventModel> {
    return this.GetLastTimeEventResponse(employeeId).pipe(
      __map(_r => _r.body as TimeEventModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CheckInTimeEventByMobileResponse(body?: TimeEventModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TimeEvent/check-in`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CheckInTimeEventByMobile(body?: TimeEventModel): __Observable<string> {
    return this.CheckInTimeEventByMobileResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CheckOutTimeEventByMobileResponse(body?: TimeEventModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TimeEvent/check-out`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CheckOutTimeEventByMobile(body?: TimeEventModel): __Observable<string> {
    return this.CheckOutTimeEventByMobileResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateTimeEventResponse(body?: TimeEventModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/TimeEvent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateTimeEvent(body?: TimeEventModel): __Observable<string> {
    return this.UpdateTimeEventResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module TimeEventService {

  /**
   * Parameters for GetTimeEventByDate
   */
  export interface GetTimeEventByDateParams {
    employeeId: number;
    date?: string;
  }
}

export { TimeEventService }
