import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ExportEmployeeWithShiftExcelParam } from 'app/api/models';
import { CompanyService, EmployeeInShiftService } from 'app/api/services';
import { map } from 'rxjs/operators';
import { toOptionList } from 'utils/formly-utils';
import { saveAs } from 'file-saver';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof ExportEmployeeWithShiftExcelParam;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-modal-export-monthly-shift',
  templateUrl: './modal-export-monthly-shift.component.html',
  styleUrls: ['./modal-export-monthly-shift.component.css']
})
export class ModalExportMonthlyShiftComponent implements OnInit {
  model: ExportEmployeeWithShiftExcelParam = {};
  form = new FormGroup({});
  options: FormlyFormOptions = {
    formState: {
      disabled: false,
    },
  };
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'companyId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      className: 'col-6',
      templateOptions: {
        label: 'บริษัท:',
        placeholder: 'บริษัท',
        labelClass: 'col-4',
        inputClass: 'col-4',
        filter: true,
        required: true,
        options: this.companyService
          .GetCompaniesList({})
          .pipe(map(pageResult => pageResult.results.map(u => toOptionList(u, 'companyId', 'name')))),
      }
    },
    {
      key: 'month',
      type: 'month-picker',
      wrappers: ['inline-label'],
      className: 'col-4',
      templateOptions: {
        translate: true,
        label: 'เดือน:',
        placeholder: 'เดือน',
        labelClass: 'col-4',
        inputClass: 'col-fixed',
        required: true
      },
    }
  ]

  constructor(private companyService: CompanyService,
    private employeeInShiftService: EmployeeInShiftService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    ) { }

  ngOnInit(): void {
    this.model = this.config.data;
    this.model.month = null;
  }

  export(){
    this.employeeInShiftService.ExportDutyExcel(this.model).subscribe(async (result: any) => {
      console.log(this.model);
      const blob = new Blob([result], { type: result.type });


      this.companyService.GetCompanyById(this.model.companyId).subscribe((company: any) => {
        saveAs(blob, company.name+this.model.month+'.xlsx');
      })

    })
  }

  close() {
    this.ref.close();
  }
}
