import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeCurrentEmploymentModel, EmployeeInPositionModel, EmployeePastEmploymentModel } from 'app/api/models';
import {
  EmployeePastEmploymentService,
  EmployeeCurrentEmploymentService,
  EmployeeService,
  EmployeeInPositionService,
} from 'app/api/services';
import * as moment from 'moment';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalEmployeeInPositionComponent } from 'modals/core/modal-employee-in-position/modal-employee-in-position.component';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { ModalEmployeePastEmploymentComponent } from '../../../modals/core/modal-employee-past-employment/modal-employee-past-employment.component';
import { map } from 'rxjs/operators';

export interface EmployeeInPositionList extends EmployeePastEmploymentModel {
  resignedDate?: string;
}

interface EmployeeCurrentEmploymentDisplayModel extends EmployeeCurrentEmploymentModel {
  probationDay: number;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeCurrentEmploymentDisplayModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-past-employment',
  templateUrl: './past-employment.component.html',
  styleUrls: ['./past-employment.component.css'],
  providers: [DialogService],
})
export class PastEmploymentComponent implements OnInit {
  isEditable = false;
  isResigned = false;
  currentEmployement: EmployeeCurrentEmploymentModel;
  employeeInPositionList: EmployeeInPositionList[];
  employeePastEmploymentList: EmployeePastEmploymentModel[];
  ref: DynamicDialogRef;

  employeeUpdatedEvent = new EventEmitter();

  model: EmployeeCurrentEmploymentDisplayModel;
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          type: 'filter-dropdown',
          key: 'employeeTypeId',
          wrappers: ['inline-label'],
          className: 'col-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            placeholder: '@blank',
            label: 'working_type',
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
            options: this.masterDataRepo.getEmployeeTypeOptionList(),
          },
        },
        {
          type: 'filter-dropdown',
          key: 'employeeType2Id',
          wrappers: ['inline-label'],
          className: 'col-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            placeholder: '@blank',
            label: 'employee_manpower',
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
            options: this.masterDataRepo.getEmployeeType2OptionList(),
          },
        },
        {
          type: 'filter-dropdown',
          key: 'payType',
          wrappers: ['inline-label'],
          className: 'col-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'type_payment',
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
            options: this.masterDataRepo.getPayTypeOptionList(),
          },
        },
        {
          type: 'calendar',
          key: 'employeeDate',
          wrappers: ['inline-label'],
          className: 'col-4',
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'start_date_s',
            placeholder: '@blank',
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
            change: (field, event) => this.updateProbationDate(field)
          },
        },
        {
          type: 'primeng-input',
          key: 'probationDay',
          wrappers: ['inline-label'],
          className: 'col-4',
          defaultValue: 90,
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'probation_period',
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
            type: 'number',
            min: 0,
            change: (field, event) => this.updateProbationDate(field)
          },
        },
        {
          type: 'calendar',
          key: 'probationDate',
          wrappers: ['inline-label'],
          className: 'col-4',
          expressionProperties: {
            'templateOptions.disabled': () => true,
          },
          templateOptions: {
            translate: true,
            label: 'probation_date',
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
            type: 'string',
          },
        },
        {
          type: 'primeng-input',
          key: 'workingEmail',
          wrappers: ['inline-label'],
          className: 'col-8',
          templateOptions: {
            translate: true,
            placeholder: '@blank',
            label: 'working_email',
            required: true,
            labelClass: 'col-3',
            inputClass: 'col-9',
            type: 'string',
          },
        },
        {
          key: 'accPreviousWorkingYears',
          wrappers: ['inline-label'],
          type: 'primeng-input',
          className: 'col-4',
          templateOptions: {
            label: 'อายุงานก่อนหน้า(ปี)',
            type: 'number',
            labelClass: 'col-6',
            inputClass: 'col-6',
            required: true,
            min: 0,
          },
        },
        {
          type: 'primeng-input',
          key: 'resignedReason',
          wrappers: ['inline-label'],
          className: 'col-8',
          templateOptions: {
            translate: true,
            label: 'resigned_reason',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-9',
            type: 'string',
          },
          hideExpression: () => {
            return this.hideExpression;
          },
        },
      ],
    },
  ];

  constructor(
    private layout: LayoutDefaultComponent,
    private router: Router,
    private notification: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeService: EmployeeService,
    private employeeInPositionService: EmployeeInPositionService,
    private employeePastEmploymentService: EmployeePastEmploymentService,
    private employeeCurrentEmployementService: EmployeeCurrentEmploymentService,
    private masterDataRepo: MasterDataRepo,
  ) {}

  ngOnInit(): void {
    this.setEditablePermission();
    this.setInPositionList();
    this.setPastEmploymentList();
    this.setCurrentEmployementData();
    this.setEmployeeData();
  }
  updateProbationDate(field:FormlyFieldConfig) {
    const probationDate = moment(this.model.employeeDate, 'YYYY-MM-DD')
    .utc(true)
    .add(this.model.probationDay, 'days')
    .toISOString();
    var probationDateControl = field.parent.fieldGroup.find(c=>c.key == "probationDate")
    probationDateControl.formControl.setValue(probationDate);
  }
  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setEmployeeData() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetEmployeeInfo(employeeId).subscribe(employeeInfo => {
      const today = new Date();
      const terminateDate = new Date(employeeInfo.terminatedDate);

      this.isResigned = terminateDate <= today;
      console.log(this.isResigned);
      console.log(this.isEditable);
    });
  }

  get hideExpression() {
    return !(this.isResigned && this.isEditable);
  }

  setInPositionList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetInPositions({ employeeId, isOrderedByDescending: true }).subscribe(inPositionList => {
      this.employeeInPositionList = inPositionList;
    });
  }

  setPastEmploymentList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetPastEmployments(employeeId).subscribe(pastEmploymentList => {
      this.employeePastEmploymentList = pastEmploymentList;
      this.employeeUpdatedEvent.next(null);
    });
  }

  setCurrentEmployementData() {
    const employeeId = this.getEmployeeId();

    this.employeeCurrentEmployementService.GetEmployeeCurrentEmployment(employeeId).subscribe(currentEmployment => {
      this.form.reset();
      this.model = {
        ...currentEmployment,
        probationDay: this.calProbationDay(currentEmployment.probationDate,currentEmployment.employeeDate), // TODO: Fix this
      };
    });
  }
  private calProbationDay(probationDate:string,employeeDate:string):number{
    if(probationDate == null || employeeDate == null){
      return 0;
    }
    let result =  moment(probationDate, 'YYYY-MM-DD').diff(moment(employeeDate, 'YYYY-MM-DD'),'days')
    if(result < 0){
      result =0;
    }
    return result
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  updateCurrentEmployment() {
    this.employeeCurrentEmployementService
      .UpdateEmployeeCurrentEmployment({
        employeeId: this.getEmployeeId(),
        body: this.model,
      })
      .subscribe(
        () => {
          this.notification.saveCompleted();
          this.setCurrentEmployementData();
          this.employeeUpdatedEvent.next(null);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notification.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  onCreatePastEmploymentBtnClick() {
    this.showCreateEmployeePastEmploymentDialog();
  }

  onCreateInPositionBtnClick() {
    this.showCreateEmployeeInPositionDialog();
  }

  showCreateEmployeeInPositionDialog() {
    this.showDialog({ employeeId: this.getEmployeeId() }, '+ เพิ่มประวัติการทำงาน', ModalEmployeeInPositionComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setInPositionList();
      }
    });
  }

  showUpdateEmployeeInPositionDialog(employeeInPositionId: number) {
    this.showDialog(
      { employeeInPositionId: employeeInPositionId, employeeId: this.getEmployeeId() },
      'แก้ไขประวัติการทำงาน',
      ModalEmployeeInPositionComponent,
    );

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setInPositionList();
      }
    });
  }

  showDeleteEmployeeInPositionDialog(employeeInPositionId: number) {
    this.notification.confirm('ยืนยันการลบข้อมูล', '').then((result: any) => {
      if (result.value) {
        this.employeeInPositionService
          .DeleteEmployeeInPosition(employeeInPositionId)
          .subscribe(
            () => {
              this.notification.deleteCompleted();
              this.setInPositionList();
            },
            err => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notification.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
            },
          );
      }
    });
  }

  showCreateEmployeePastEmploymentDialog() {
    this.showDialog(
      { employeeId: this.getEmployeeId() },
      '+ เพิ่มประวัติการทำงานก่อนหน้า',
      ModalEmployeePastEmploymentComponent,
    );
  }

  showUpdateEmployeePastEmploymentDialog(employeePastEmploymentId: number) {
    this.showDialog(
      { id: employeePastEmploymentId },
      'แก้ไขประวัติการทำงานก่อนหน้า',
      ModalEmployeePastEmploymentComponent,
    );
  }

  showDeleteEmployeePastEmploymentDialog(employeePastEmploymentId: number) {
    this.notification.confirm('ยืนยันการลบข้อมูล', '').then((result: any) => {
      if (result.value) {
        this.employeePastEmploymentService.DeleteEmployeePastEmployment(employeePastEmploymentId).subscribe(
          () => {
            this.notification.deleteCompleted();
            this.setPastEmploymentList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notification.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'visible' },
      data,
      dismissableMask: false,
    });
    this.ref.onClose.pipe(map(data => data)).subscribe(data => {
      debugger;
      if (data) {
        this.setPastEmploymentList();
      }
    });
  }
}
