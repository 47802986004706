/* tslint:disable */
type WorkflowStepActorNotFoundAction =
  'Skip' |
  'AssignToAdmin';
module WorkflowStepActorNotFoundAction {
  export const SKIP: WorkflowStepActorNotFoundAction = 'Skip';
  export const ASSIGN_TO_ADMIN: WorkflowStepActorNotFoundAction = 'AssignToAdmin';
  export function values(): WorkflowStepActorNotFoundAction[] {
    return [
      SKIP,
      ASSIGN_TO_ADMIN
    ];
  }
}

export { WorkflowStepActorNotFoundAction }