/* tslint:disable */
type ETimeEventSource =
  'NA' |
  'Finger' |
  'Face' |
  'Mobile' |
  'Manual' |
  'CertifyTime';
module ETimeEventSource {
  export const NA: ETimeEventSource = 'NA';
  export const FINGER: ETimeEventSource = 'Finger';
  export const FACE: ETimeEventSource = 'Face';
  export const MOBILE: ETimeEventSource = 'Mobile';
  export const MANUAL: ETimeEventSource = 'Manual';
  export const CERTIFY_TIME: ETimeEventSource = 'CertifyTime';
  export function values(): ETimeEventSource[] {
    return [
      NA,
      FINGER,
      FACE,
      MOBILE,
      MANUAL,
      CERTIFY_TIME
    ];
  }
}

export { ETimeEventSource }