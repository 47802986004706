import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeePunishmentModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { finalize, map, takeUntil, tap, flatMap } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeePunishmentService, EmployeeService } from 'app/api/services';
import { HttpErrorResponse } from '@angular/common/http';

import { MasterDataRepo } from 'app/repo/master-data.repo';
import { OptionListRepo } from 'app/repo/option-list.repo';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeePunishmentModel;
}

@Component({
  selector: 'app-modal-employee-punishment',
  templateUrl: './modal-employee-punishment.component.html',
  styleUrls: ['./modal-employee-punishment.component.css'],
})
export class ModalEmployeePunishmentComponent implements OnInit {
  model: EmployeePunishmentModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'date',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'วันที่ได้รับโทษ',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-3',
      },
    },
    {
      key: 'positionName',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ตำแหน่ง',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        disabled: true,
      },
    },
    {
      key: 'punishmentTypeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ประเภทการลงโทษ',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        // options: this.masterDataRepo.getPunishmentTypesOptionList(),
      },
    },
    {
      key: 'punishment',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'โทษที่ได้รับ',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: this.masterDataRepo.getPenaltyTypeOptionList(),
      },
    },
  ];
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeePunishmentService: EmployeePunishmentService,
    private masterDataRepo: MasterDataRepo,
    private optionListRepo: OptionListRepo,
    private employeeService: EmployeeService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();

    this.employeeService
      .GetEmployeeInfo(this.getEmployeeId())
      .pipe(
        tap(employee => {
          if (this.componentMode === ComponentMode.UPDATE) {
            this.employeePunishmentService
              .GetEmployeePunishmentById(this.getEmployeePunishmentId())
              .subscribe(salary => {
                this.model = {
                  ...salary,
                  date: (salary.date),
                };
              });
          } else {
            this.model = {
              employeeId: employee.employeeId,
              positionName: employee.positionName,
            };
          }
        }),
        flatMap(employee => {
          return forkJoin([this.masterDataRepo.getPunishmentTypesOptionList()]);
        }),
      )
      .subscribe(([punishmentList]) => {
        this.fields.find(a => a.key === 'punishmentTypeId').templateOptions.options = punishmentList;
      });
  }

  initComponentMode() {
    const employeePunishmentId = this.getEmployeePunishmentId();

    if (employeePunishmentId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.employeePunishmentService
          .CreateEmployeePunishment(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.employeePunishmentService
          .UpdateEmployeePunishment(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getEmployeePunishmentId() {
    return this.config.data?.id;
  }

  getEmployeeId() {
    return this.config.data?.employeeId;
  }
}
