/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GlCodeModelPaginationResult } from '../models/gl-code-model-pagination-result';
import { GlCodeModel } from '../models/gl-code-model';
@Injectable({
  providedIn: 'root',
})
class GlCodeService extends __BaseService {
  static readonly GetGlCodesPath = '/api/GlCode';
  static readonly CreateGlCodePath = '/api/GlCode';
  static readonly GetGlCodeByIdPath = '/api/GlCode/{id}';
  static readonly UpdateGlCodePath = '/api/GlCode/{id}';
  static readonly DeleteGlCodePath = '/api/GlCode/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `GlCodeService.GetGlCodesParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetGlCodesResponse(params: GlCodeService.GetGlCodesParams): __Observable<__StrictHttpResponse<GlCodeModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/GlCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GlCodeModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `GlCodeService.GetGlCodesParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetGlCodes(params: GlCodeService.GetGlCodesParams): __Observable<GlCodeModelPaginationResult> {
    return this.GetGlCodesResponse(params).pipe(
      __map(_r => _r.body as GlCodeModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateGlCodeResponse(body?: GlCodeModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/GlCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateGlCode(body?: GlCodeModel): __Observable<number> {
    return this.CreateGlCodeResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetGlCodeByIdResponse(id: number): __Observable<__StrictHttpResponse<GlCodeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/GlCode/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GlCodeModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetGlCodeById(id: number): __Observable<GlCodeModel> {
    return this.GetGlCodeByIdResponse(id).pipe(
      __map(_r => _r.body as GlCodeModel)
    );
  }

  /**
   * @param params The `GlCodeService.UpdateGlCodeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateGlCodeResponse(params: GlCodeService.UpdateGlCodeParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/GlCode/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `GlCodeService.UpdateGlCodeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateGlCode(params: GlCodeService.UpdateGlCodeParams): __Observable<string> {
    return this.UpdateGlCodeResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteGlCodeResponse(id: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/GlCode/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  DeleteGlCode(id: number): __Observable<string> {
    return this.DeleteGlCodeResponse(id).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module GlCodeService {

  /**
   * Parameters for GetGlCodes
   */
  export interface GetGlCodesParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for UpdateGlCode
   */
  export interface UpdateGlCodeParams {
    id: number;
    body?: GlCodeModel;
  }
}

export { GlCodeService }
