import { FormControl, ValidationErrors } from '@angular/forms';

export function PasswordValidator(control: FormControl): ValidationErrors {
  const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])/;

  if (!control.value || reg.test(control.value)) {
    return null;
  }

  return { fieldMatch: { message: 'รหัสผ่านต้องประกอบด้วยตัวเลข และตัวอักษร' } };
}
