<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>{{'basic_health_information' | translate}}</h1>
        <hr />
        <div class="card">
          <div class="grid">
            <div class="col-12">
              <h1>{{'personal_health_information' | translate}}</h1>
            </div>
            <div class="col"></div>
            <div class="col-12">
              <div class="grid align-center">
                <div class="col-fixed">
                  <form [formGroup]="form">
                    <formly-form [form]="form" [fields]="fields" [model]="physicalMeasurementModel"></formly-form>
                  </form>
                </div>
                <div class="col"></div>
                <div class="col-fixed">
                  <button
                    *ngIf="isEditable"
                    pButton
                    type="button"
                    label="{{'record' | translate}}"
                    [disabled]="!form.dirty"
                    (click)="onUpdateHealthInfoBtnClick()"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-12 divider">
              <div class="grid justify-content-between">
                <div class="col-fixed">
                  <h1>{{'congenital_disease' | translate}}</h1>
                </div>
                <div class="col-fixed">
                  <button
                    *ngIf="isEditable"
                    pButton
                    type="button"
                    label="{{'add_data' | translate}}"
                    (click)="showCreateDiseaseDialog()"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-12">
              <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="diseaseList">
                <ng-template pTemplate="header">
                  <tr>
                    <th>{{'disease_name' | translate}}</th>
                    <th>{{'more_details' | translate}}</th>
                    <th *ngIf="isEditable" style="width: 100px">Action</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td>{{ rowData.diseaseName }}</td>
                    <td>{{ rowData.note }}</td>
                    <td *ngIf="isEditable">
                      <div class="grid justify-content-around icon-action">
                        <div class="col-fixed">
                          <i
                            class="fa fa-pencil-alt"
                            (click)="showUpdateDiseaseDialog(rowData.employeeChronicDiseaseId)"
                          ></i>
                        </div>
                        <div class="col-fixed">
                          <i
                            class="fa fa-trash"
                            (click)="showDeleteDiseaseDialog(rowData.employeeChronicDiseaseId)"
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3" class="emptymessage">{{'no_data' | translate}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="col-12 divider">
              <div class="grid justify-content-between">
                <div class="col-fixed">
                  <h1>{{'history_drug_allergy' | translate}}</h1>
                </div>
                <div class="col-fixed">
                  <button
                    *ngIf="isEditable"
                    pButton
                    type="button"
                    label="{{'add_data' | translate}}"
                    (click)="showCreateDrugAllergyDialog()"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-12">
              <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="drugAllergyList">
                <ng-template pTemplate="header">
                  <tr>
                    <th>{{'drug_name' | translate}}</th>
                    <th>{{'more_details' | translate}}</th>
                    <th *ngIf="isEditable" style="width: 100px">Action</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td>{{ rowData.drugName }}</td>
                    <td>{{ rowData.note }}</td>
                    <td *ngIf="isEditable">
                      <div class="grid justify-content-around icon-action">
                        <div class="col-fixed">
                          <i
                            class="fa fa-pencil-alt"
                            (click)="showUpdateDrugAllergyDialog(rowData.employeeDrugAllergyId)"
                          ></i>
                        </div>
                        <div class="col-fixed">
                          <i
                            class="fa fa-trash"
                            (click)="showDeleteDrugAllergyDialog(rowData.employeeDrugAllergyId)"
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3" class="emptymessage">{{'no_data' | translate}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="col-12 divider">
              <div class="grid justify-content-between">
                <div class="col-fixed">
                  <h1>{{'history_surgery' | translate}}</h1>
                </div>
                <div class="col-fixed">
                  <button
                    *ngIf="isEditable"
                    pButton
                    type="button"
                    label="{{'add_data' | translate}}"
                    (click)="showCreateSurgeryDialog()"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-12">
              <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="surgeryList">
                <ng-template pTemplate="header">
                  <tr>
                    <th>{{'surgery' | translate}}</th>
                    <th>{{'more_details' | translate}}</th>
                    <th>{{'date_surgery' | translate}}</th>
                    <th *ngIf="isEditable" style="width: 100px">Action</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td>{{ rowData.name }}</td>
                    <td>{{ rowData.note }}</td>
                    <td>{{ rowData.date | date: 'dd/MM/yyyy' }}</td>
                    <td *ngIf="isEditable">
                      <div class="grid justify-content-around icon-action">
                        <div class="col-fixed">
                          <i class="fa fa-pencil-alt" (click)="showUpdateSurgeryDialog(rowData.employeeSurgeryId)"></i>
                        </div>
                        <div class="col-fixed">
                          <i class="fa fa-trash" (click)="showDeleteSurgeryDialog(rowData.employeeSurgeryId)"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="4" class="emptymessage">{{'no_data' | translate}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="col-12 divider">
              <div class="grid justify-content-between">
                <div class="col-fixed">
                  <h1>{{'examination_card' | translate}}</h1>
                </div>
                <div class="col-fixed">
                  <button
                    *ngIf="isEditable"
                    pButton
                    type="button"
                    label="{{'add_data' | translate}}"
                    (click)="showCreateHealthCheckDialog()"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-12">
              <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="healthFileList">
                <ng-template pTemplate="header">
                  <tr>
                    <th>{{'name' | translate}}</th>
                    <th>{{'attach_file' | translate}}</th>
                    <th>{{'effective_date' | translate}}</th>
                    <th>{{'expiration_date' | translate}}</th>
                    <th *ngIf="isEditable" style="width: 100px">{{'action' | translate}}</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td>{{ rowData.name }}</td>
                    <td>
                      <span *ngIf="!rowData.documentId">N/A</span>
                      <a *ngIf="rowData.documentId" href="/api/document/{{ rowData.documentId }}/download">download</a>
                    </td>
                    <td>{{ rowData.effectiveDate | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ rowData.terminateDate | date: 'dd/MM/yyyy' }}</td>
                    <td *ngIf="isEditable">
                      <div class="grid justify-content-around icon-action">
                        <div class="col-fixed">
                          <i class="fa fa-pencil-alt" (click)="editHealthCheck(rowData.employeeHealthCheckId)"></i>
                        </div>
                        <div class="col-fixed">
                          <i class="fa fa-trash" (click)="removeHealthCheck(rowData.employeeHealthCheckId)"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="7" class="emptymessage">{{'no_data' | translate}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
