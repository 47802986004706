<div class="p-inputgroup">
  <span *ngIf="to.addonLeft?.class" class="p-inputgroup-addon"
    ><i class="{{ to.addonLeft.class }}" style="line-height: 1.25"></i
  ></span>
  <input
    [formControl]="$any(formControl)"
    [formlyAttributes]="field"
    [type]="to.type || 'text'"
    pInputText
    [pTooltip]="to?.tooltip || undefined"
    placeholder="{{ to.placeholder }}"
    [defaultValue]="to?.defaultValue"
    (change)="!to.change ? undefined : to.change(this, $event)"
  />
  <span *ngIf="to.addonRight?.class" class="p-inputgroup-addon"
    ><i class="{{ to.addonLeft.class }}" style="line-height: 1.25"></i
  ></span>
</div>
