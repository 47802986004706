/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeDocumentModel } from '../models/employee-document-model';
import { ImagesUploadStatusModel } from '../models/images-upload-status-model';
import { DocumentUploadStatusModel } from '../models/document-upload-status-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeDocumentService extends __BaseService {
  static readonly GetEmployeeDocumentsPath = '/api/EmployeeDocument';
  static readonly CreateEmployeeDocumentPath = '/api/EmployeeDocument';
  static readonly UpdateEmployeeDocumentPath = '/api/EmployeeDocument';
  static readonly GetEmployeeDocumentPath = '/api/EmployeeDocument/{employeeId}/{docId}';
  static readonly DeleteEmployeeDocumentPath = '/api/EmployeeDocument/{employeeId}/{docId}';
  static readonly ImportEmployeeImagesPath = '/api/EmployeeDocument/importemployeeimages';
  static readonly ImportEmployeeDocumentPath = '/api/EmployeeDocument/ExcelDocument';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeDocumentsResponse(employeeId?: number): __Observable<__StrictHttpResponse<Array<EmployeeDocumentModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (employeeId != null) __params = __params.set('employeeId', employeeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeDocumentModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeDocuments(employeeId?: number): __Observable<Array<EmployeeDocumentModel>> {
    return this.GetEmployeeDocumentsResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeDocumentModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeDocumentResponse(body?: EmployeeDocumentModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeDocument(body?: EmployeeDocumentModel): __Observable<string> {
    return this.CreateEmployeeDocumentResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeDocumentResponse(body?: EmployeeDocumentModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeDocument(body?: EmployeeDocumentModel): __Observable<string> {
    return this.UpdateEmployeeDocumentResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `EmployeeDocumentService.GetEmployeeDocumentParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `docId`:
   *
   * @return Success
   */
  GetEmployeeDocumentResponse(params: EmployeeDocumentService.GetEmployeeDocumentParams): __Observable<__StrictHttpResponse<EmployeeDocumentModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeDocument/${encodeURIComponent(String(params.employeeId))}/${encodeURIComponent(String(params.docId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeDocumentModel>;
      })
    );
  }
  /**
   * @param params The `EmployeeDocumentService.GetEmployeeDocumentParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `docId`:
   *
   * @return Success
   */
  GetEmployeeDocument(params: EmployeeDocumentService.GetEmployeeDocumentParams): __Observable<EmployeeDocumentModel> {
    return this.GetEmployeeDocumentResponse(params).pipe(
      __map(_r => _r.body as EmployeeDocumentModel)
    );
  }

  /**
   * @param params The `EmployeeDocumentService.DeleteEmployeeDocumentParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `docId`:
   *
   * @return Success
   */
  DeleteEmployeeDocumentResponse(params: EmployeeDocumentService.DeleteEmployeeDocumentParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeDocument/${encodeURIComponent(String(params.employeeId))}/${encodeURIComponent(String(params.docId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeDocumentService.DeleteEmployeeDocumentParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `docId`:
   *
   * @return Success
   */
  DeleteEmployeeDocument(params: EmployeeDocumentService.DeleteEmployeeDocumentParams): __Observable<string> {
    return this.DeleteEmployeeDocumentResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  ImportEmployeeImagesResponse(): __Observable<__StrictHttpResponse<ImagesUploadStatusModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeDocument/importemployeeimages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImagesUploadStatusModel>;
      })
    );
  }
  /**
   * @return Success
   */
  ImportEmployeeImages(): __Observable<ImagesUploadStatusModel> {
    return this.ImportEmployeeImagesResponse().pipe(
      __map(_r => _r.body as ImagesUploadStatusModel)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  ImportEmployeeDocumentResponse(file?: Blob): __Observable<__StrictHttpResponse<DocumentUploadStatusModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeDocument/ExcelDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentUploadStatusModel>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  ImportEmployeeDocument(file?: Blob): __Observable<DocumentUploadStatusModel> {
    return this.ImportEmployeeDocumentResponse(file).pipe(
      __map(_r => _r.body as DocumentUploadStatusModel)
    );
  }
}

module EmployeeDocumentService {

  /**
   * Parameters for GetEmployeeDocument
   */
  export interface GetEmployeeDocumentParams {
    employeeId: number;
    docId: number;
  }

  /**
   * Parameters for DeleteEmployeeDocument
   */
  export interface DeleteEmployeeDocumentParams {
    employeeId: number;
    docId: number;
  }
}

export { EmployeeDocumentService }
