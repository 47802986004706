import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

import { MasterDataRepo } from 'app/repo/master-data.repo';
import { ERelativeInfoStatus } from 'app/api/models';

export interface EmployeeParentInfoModel {
  titleTh?: string;
  firstnameTh?: string;
  middlenameTh?: string;
  lastnameTh?: string;
  birthday?: string;
  religionId?: string;
  raceId?: string;
  nationalityId?: string;
  job?: string;
  phone?: string;
  taxId?: string;
  address?: string;
  district?: string;
  subDistrict?: string;
  province?: string;
  zipcode?: string;
}

export interface EmployeeFatherModel extends EmployeeParentInfoModel {
  isFatherAlive?: ERelativeInfoStatus;
  fatherAddressIsTheSameAsHomeAddress?: boolean;
  fatherAddressIsTheSameAsCurrentAddress?: boolean;
}

export interface EmployeeMotherModel extends EmployeeParentInfoModel {
  isMotherAlive?: ERelativeInfoStatus;
  motherAddressIsTheSameAsHomeAddress?: boolean;
  motherAddressIsTheSameAsCurrentAddress?: boolean;
  motherAddressIsTheSameAsFatherAddress?: boolean;
}

export interface EmployeeSpouseModel extends EmployeeParentInfoModel {
  spouseAddressIsTheSameAsHomeAddress?: boolean;
  spouseAddressIsTheSameAsCurrentAddress?: boolean;
}

export interface EmployeeFamilyCreateModel {
  parentMaritalStatusId: string;
  employeeFather: EmployeeFatherModel;
  employeeMother: EmployeeMotherModel;
  employeeSpouse: EmployeeSpouseModel;
  otherParent: {
    siblingNumber?: number;
    siblingPosition?: number;
  };
}

interface FamilyFormlyField extends FormlyFieldConfig {
  key?:
    | keyof EmployeeFamilyCreateModel
    | keyof EmployeeFatherModel
    | keyof EmployeeMotherModel
    | keyof EmployeeSpouseModel
    | keyof EmployeeFamilyCreateModel
    | 'siblingNumber'
    | 'siblingPosition';
  fieldGroup?: FamilyFormlyField[];
}

@Component({
  selector: 'app-employee-create-form-family-info',
  templateUrl: './employee-create-form-family-info.component.html',
  styleUrls: ['./employee-create-form-family-info.component.css'],
})
export class EmployeeCreateFormFamilyInfoComponent implements OnInit, OnChanges {
  @Input('defaultModel')
  set defaultModel(val) {
    if (val) {
      Object.assign(this.model, val);
    }
  }
  @Input() maritalStatus: string;

  isShowErr = true;

  form = new FormGroup({});
  model: EmployeeFamilyCreateModel = {
    parentMaritalStatusId: null,
    employeeFather: {},
    employeeMother: {},
    employeeSpouse: {},
    otherParent: {},
  };
  options: FormlyFormOptions = {};
  fields: FamilyFormlyField[] = [
    {
      template: `<h1>ข้อมูลบิดามารดา</h1>`,
    },
    // {
    //   fieldGroupClassName: 'grid',
    //   fieldGroup: [
    //     {
    //       key: 'parentMaritalStatusId',
    //       type: 'radio',
    //       className: 'col-12',
    //       wrappers: ['inline-label'],
    //       templateOptions: {
    //         translate: true,
    //         label: 'parents_marital',
    //         placeholder: '@blank',
    //         labelStyle: { 'text-align': 'right' },

    //         options: this.masterDataRepo.getParentStatusOptionList(),
    //         labelClass: 'col-fixed',
    //         inputClass: 'col',
    //       },
    //       validation: {
    //         show: this.isShowErr,
    //       },
    //     },
    //   ],
    // },
    {
      template: `<h4>รายละเอียดข้อมูลบิดา :</h4>`,
    },
    {
      key: 'employeeFather',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isFatherAlive',
          type: 'radio',
          className: 'col-12',
          wrappers: ['inline-label'],
          defaultValue: 'HaveInfo' as ERelativeInfoStatus,
          templateOptions: {
            translate: true,
            label: 'father_status',
            placeholder: '@blank',
            labelStyle: { 'text-align': 'right' },
            labelClass: 'col-1',
            inputClass: 'col-fixed',
            options: this.masterDataRepo.getRelativeInfoStatusesOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'titleTh',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'name_surname_th',
            placeholder: 'titleTh',
            options: this.masterDataRepo.getTitleThOptionList(),
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'firstnameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'not',
            placeholder: 'name_th',
            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
          //
          // templateOptions: {
          //   // translate: true,
          //   //
          //   label: '',
          //   placeholder: 'ชื่อ(ภาษาไทย)',

          //   labelClass: 'col',
          //   inputClass: 'col-12',
          // },
        },
        {
          key: 'middlenameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'not',
            placeholder: 'middle_name_th',
            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'lastnameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'not',
            placeholder: 'surname_th',

            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'birthday',
          type: 'calendar',
          className: 'lg:col-6',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'd/m/y',
            placeholder: 'd/m/y',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'religionId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'religion',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            options: this.masterDataRepo.getReligionOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'nationalityId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'nationality',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            options: this.masterDataRepo.getNationalityOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'raceId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'race',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            options: this.masterDataRepo.getRaceOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'job',
          type: 'primeng-input',
          className: 'lg:col-6 md:col-12',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'occupation_position',
            placeholder: '@blank',

            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'phone',
          type: 'primeng-input',
          className: 'lg:col-6 md:col-12',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'phone',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          className: 'col-12',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              type: 'primeng-input',
              key: 'taxId',
              wrappers: ['inline-label'],
              className: 'col-6',
              expressionProperties: {
                'templateOptions.disabled': () => this.disabledFatherForm(),
              },
              templateOptions: {
                translate: true,
                placeholder: '@blank',
                label: 'card_number',
                labelClass: 'col-3',
                inputClass: 'col-6',
              },
              validation: {
                show: this.isShowErr,
              },
            },
          ],
        },
        {
          key: 'fatherAddressIsTheSameAsHomeAddress',
          type: 'checkbox',
          className: 'col-2',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'address_house_registration',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            change: () => {
              if ((this.form.controls.employeeFather as FormGroup).controls.fatherAddressIsTheSameAsHomeAddress.value) {
                (
                  this.form.controls.employeeFather as FormGroup
                ).controls.fatherAddressIsTheSameAsCurrentAddress.setValue(false);
              }
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'fatherAddressIsTheSameAsCurrentAddress',
          type: 'checkbox',
          className: 'col-2',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'same_address_current',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            change: () => {
              if (
                (this.form.controls.employeeFather as FormGroup).controls.fatherAddressIsTheSameAsCurrentAddress.value
              ) {
                (this.form.controls.employeeFather as FormGroup).controls.fatherAddressIsTheSameAsHomeAddress.setValue(
                  false,
                );
              }
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-9',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(true),
          },
          templateOptions: {
            translate: true,
            label: 'address',
            placeholder: '@blank',
            labelClass: 'col-2',
            inputClass: 'col',
          },
        },
        {
          key: 'province',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': () => this.disabledFatherForm(true),
          },
          className: 'col-6',
          templateOptions: {
            translate: true,

            label: 'province',
            placeholder: '@blank',
            options: this.masterDataRepo.getProvinceOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',
            change: (field,$event) => {
              const provinceId = this.masterDataRepo.getProvinceIdByName(field.formControl.value);
              const controls_district = this.fields.find(a => a.key === 'employeeFather').fieldGroup.find(a => a.key === 'district');
              const controls_subdistrict = this.fields.find(a => a.key === 'employeeFather').fieldGroup.find(a => a.key === 'subDistrict');

              controls_district.formControl.setValue(undefined);
              controls_subdistrict.formControl.setValue(undefined);
              controls_district.templateOptions.options = this.masterDataRepo.getAmphoeOptionList(provinceId);
              (this.form.controls.employeeFather as FormGroup).controls.district.reset();
              (this.form.controls.employeeFather as FormGroup).controls.subDistrict.reset();
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'district',
          type: 'filter-dropdown',
          className: 'col-6',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeFatherModel) => {
              return this.disabledFatherForm(true) || !model.province;
            },
          },
          templateOptions: {
            translate: true,

            label: 'district',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            change: (field,$event) => {
              const amphoeId = this.masterDataRepo.getAmphoeIdByName(field.formControl.value);

              const controls_subdistrict = this.fields.find(a => a.key === 'employeeFather').fieldGroup.find(a => a.key === 'subDistrict');
              controls_subdistrict.formControl.setValue(undefined);
              controls_subdistrict.templateOptions.options = this.masterDataRepo.getTambonOptionList(amphoeId);
              (this.form.controls.employeeFather as FormGroup).controls.subDistrict.reset();
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'subDistrict',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeFatherModel) => {
              return this.disabledFatherForm(true) || !model.district;
            },
          },
          templateOptions: {
            translate: true,

            label: 'subdistrict',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'zipcode',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeFatherModel) => this.disabledFatherForm(true),
          },
          templateOptions: {
            translate: true,

            label: 'postal_code',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-5',

            maxLength: 5,
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
    {
      template: `<h4>รายละเอียดข้อมูลมารดา :</h4>`,
    },
    {
      key: 'employeeMother',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isMotherAlive',
          type: 'radio',
          className: 'col-12',
          wrappers: ['inline-label'],
          defaultValue: 'HaveInfo' as ERelativeInfoStatus,
          templateOptions: {
            translate: true,

            label: 'mom_status',
            placeholder: '@blank',
            labelStyle: { 'text-align': 'right' },
            labelClass: 'col-1',
            inputClass: 'col-fixed',
            options: this.masterDataRepo.getRelativeInfoStatusesOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'titleTh',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'name_surname_th',
            placeholder: '@blank',

            options: this.masterDataRepo.getTitleThOptionList(),
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'firstnameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-3',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'not',
            placeholder: 'name_th',

            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'middlenameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'not',
            placeholder: 'middle_name_th',
            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'lastnameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'not',
            placeholder: 'surname_th',

            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'birthday',
          type: 'calendar',
          className: 'lg:col-6',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'd/m/y',
            placeholder: 'd/m/y',

            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'religionId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'religion',
            placeholder: '@blank',
            options: this.masterDataRepo.getReligionOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'nationalityId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'nationality',
            placeholder: '@blank',
            options: this.masterDataRepo.getNationalityOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'raceId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'race',
            placeholder: '@blank',
            options: this.masterDataRepo.getRaceOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'job',
          type: 'primeng-input',
          className: 'lg:col-6 md:col-12',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'occupation_position',
            placeholder: '@blank',

            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'phone',
          type: 'primeng-input',
          className: 'lg:col-6 md:col-12',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'phone',
            placeholder: '@blank',

            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          className: 'col-12',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              type: 'primeng-input',
              key: 'taxId',
              wrappers: ['inline-label'],
              className: 'col-6',
              expressionProperties: {
                'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
              },
              templateOptions: {
                translate: true,

                placeholder: '@blank',
                label: 'card_number',

                labelClass: 'col-3',
                inputClass: 'col-6',
              },
              validation: {
                show: this.isShowErr,
              },
            },
          ],
        },
        {
          key: 'motherAddressIsTheSameAsHomeAddress',
          type: 'checkbox',
          className: 'col-2',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'address_house_registration',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            change: () => {
              if ((this.form.controls.employeeMother as FormGroup).controls.motherAddressIsTheSameAsHomeAddress.value) {
                (
                  this.form.controls.employeeMother as FormGroup
                ).controls.motherAddressIsTheSameAsCurrentAddress.setValue(false);
                (
                  this.form.controls.employeeMother as FormGroup
                ).controls.motherAddressIsTheSameAsFatherAddress.setValue(false);
              }
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'motherAddressIsTheSameAsCurrentAddress',
          type: 'checkbox',
          className: 'col-2',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'same_address_current',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            change: () => {
              if (
                (this.form.controls.employeeMother as FormGroup).controls.motherAddressIsTheSameAsCurrentAddress.value
              ) {
                (this.form.controls.employeeMother as FormGroup).controls.motherAddressIsTheSameAsHomeAddress.setValue(
                  false,
                );
                (
                  this.form.controls.employeeMother as FormGroup
                ).controls.motherAddressIsTheSameAsFatherAddress.setValue(false);
              }
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'motherAddressIsTheSameAsFatherAddress',
          type: 'checkbox',
          className: 'col-2',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,

            label: 'same_addres_father',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            change: () => {
              if (
                (this.form.controls.employeeMother as FormGroup).controls.motherAddressIsTheSameAsFatherAddress.value
              ) {
                (
                  this.form.controls.employeeMother as FormGroup
                ).controls.motherAddressIsTheSameAsCurrentAddress.setValue(false);
                (this.form.controls.employeeMother as FormGroup).controls.motherAddressIsTheSameAsHomeAddress.setValue(
                  false,
                );
              }
            },
          },

          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-9',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(true),
          },
          templateOptions: {
            translate: true,

            label: 'address',
            placeholder: '@blank',
            labelClass: 'col-2',
            inputClass: 'col',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'province',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(true),
          },
          templateOptions: {
            translate: true,

            label: 'province',
            placeholder: '@blank',
            options: this.masterDataRepo.getProvinceOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',

            change: (field,$event) => {
              const provinceId = this.masterDataRepo.getProvinceIdByName(field.formControl.value);
              const controls_district = this.fields.find(a => a.key === 'employeeMother').fieldGroup.find(a => a.key === 'district');
              const controls_subdistrict = this.fields.find(a => a.key === 'employeeMother').fieldGroup.find(a => a.key === 'subDistrict');

              controls_district.formControl.setValue(undefined);
              controls_subdistrict.formControl.setValue(undefined);
              controls_district.templateOptions.options = this.masterDataRepo.getAmphoeOptionList(provinceId);
              (this.form.controls.employeeFather as FormGroup).controls.district.reset();
              (this.form.controls.employeeFather as FormGroup).controls.subDistrict.reset();
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'district',
          type: 'filter-dropdown',
          className: 'col-6',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => {
              return this.disabledMotherForm(true) || !model.province;
            },
          },
          templateOptions: {
            translate: true,

            label: 'district',
            placeholder: '@blank',

            labelClass: 'col-3',
            inputClass: 'col-6',
            change: (field,$event) => {
              const amphoeId = this.masterDataRepo.getAmphoeIdByName(field.formControl.value);

              const controls_subdistrict = this.fields.find(a => a.key === 'employeeMother').fieldGroup.find(a => a.key === 'subDistrict');
              controls_subdistrict.formControl.setValue(undefined);
              controls_subdistrict.templateOptions.options = this.masterDataRepo.getTambonOptionList(amphoeId);
              (this.form.controls.employeeFather as FormGroup).controls.subDistrict.reset();
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'subDistrict',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => {
              return this.disabledMotherForm(true) || !model.district;
            },
          },
          templateOptions: {
            translate: true,

            label: 'subdistrict',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'zipcode',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeMotherModel) => this.disabledMotherForm(true),
          },
          templateOptions: {
            translate: true,

            label: 'postal_code',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-5',

            maxLength: 5,
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
    {
      template: `<h4>ข้อมูลครอบครัวอื่นๆ :</h4>`,
    },
    {
      key: 'otherParent',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'siblingNumber',
          type: 'primeng-input',
          className: 'col-3',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,

            labelClass: 'col-6',
            inputClass: 'col-6',
            label: 'sibling_number',
            placeholder: '@blank',
            min: 1,
            tooltip: 'หากเป็นลูกคนเดียว ให้ระบุ 1',
            type: 'number',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'siblingPosition',
          type: 'primeng-input',
          className: 'col-3',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,

            labelClass: 'col-6',
            inputClass: 'col-6',
            label: 'you_order',
            placeholder: '@blank',
            min: 1,
            tooltip: 'หากเป็นลูกคนเดียว ให้ระบุ 1',
            type: 'number',
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
    {
      template: `<h4>รายละเอียดข้อมูลสามี/ภรรยา :</h4>`,
    },
    {
      key: 'employeeSpouse',
      fieldGroupClassName: 'grid',
      templateOptions: {
        disabled: true,
      },
      fieldGroup: [
        {
          key: 'titleTh',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-3',
          templateOptions: {
            translate: true,
            label: 'name_surname_th',
            placeholder: '@blank',

            options: this.masterDataRepo.getTitleThOptionList(),
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'firstnameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-3',
          templateOptions: {
            translate: true,
            label: 'not',
            placeholder: 'name_th',

            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'middlenameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          templateOptions: {
            translate: true,

            label: 'not',
            placeholder: 'middle_name_th',
            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'lastnameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          templateOptions: {
            translate: true,

            label: 'not',
            placeholder: 'surname_th',

            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'birthday',
          type: 'calendar',
          className: 'lg:col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'd/m/y',
            placeholder: 'd/m/y',

            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'religionId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'religion',
            placeholder: '@blank',
            options: this.masterDataRepo.getReligionOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'nationalityId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'nationality',
            placeholder: '@blank',
            options: this.masterDataRepo.getNationalityOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'raceId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'race',
            placeholder: '@blank',
            options: this.masterDataRepo.getRaceOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'job',
          type: 'primeng-input',
          className: 'lg:col-6 md:col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'occupation_position',
            placeholder: '@blank',

            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'phone',
          type: 'primeng-input',
          className: 'lg:col-6 md:col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'phone',
            placeholder: '@blank',

            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          className: 'col-12',
          fieldGroupClassName: 'grid',
          fieldGroup: [
            {
              type: 'primeng-input',
              key: 'taxId',
              wrappers: ['inline-label'],
              className: 'col-6',
              templateOptions: {
                translate: true,
                placeholder: '@blank',
                label: 'card_number',

                labelClass: 'col-3',
                inputClass: 'col-6',
              },
              validation: {
                show: this.isShowErr,
              },
            },
          ],
        },
        {
          key: 'spouseAddressIsTheSameAsHomeAddress',
          type: 'checkbox',
          className: 'col-2',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,

            placeholder: '@blank',
            label: 'address_house_registration',
            labelClass: 'col',
            inputClass: 'col',
            change: () => {
              if ((this.form.controls.employeeSpouse as FormGroup).controls.spouseAddressIsTheSameAsHomeAddress.value) {
                (
                  this.form.controls.employeeSpouse as FormGroup
                ).controls.spouseAddressIsTheSameAsCurrentAddress.setValue(false);
                (this.form.controls.employeeSpouse as FormGroup).controls.address.reset();
                (this.form.controls.employeeSpouse as FormGroup).controls.district.reset();
                (this.form.controls.employeeSpouse as FormGroup).controls.subDistrict.reset();
                (this.form.controls.employeeSpouse as FormGroup).controls.province.reset();
                (this.form.controls.employeeSpouse as FormGroup).controls.zipcode.reset();
                (this.form.controls.employeeSpouse as FormGroup).controls.address.disable();
                (this.form.controls.employeeSpouse as FormGroup).controls.district.disable();
                (this.form.controls.employeeSpouse as FormGroup).controls.subDistrict.disable();
                (this.form.controls.employeeSpouse as FormGroup).controls.province.disable();
                (this.form.controls.employeeSpouse as FormGroup).controls.zipcode.disable();
              } else if (
                !(this.form.controls.employeeSpouse as FormGroup).controls.spouseAddressIsTheSameAsCurrentAddress.value
              ) {
                (this.form.controls.employeeSpouse as FormGroup).controls.address.enable();
                (this.form.controls.employeeSpouse as FormGroup).controls.district.enable();
                (this.form.controls.employeeSpouse as FormGroup).controls.subDistrict.enable();
                (this.form.controls.employeeSpouse as FormGroup).controls.province.enable();
                (this.form.controls.employeeSpouse as FormGroup).controls.zipcode.enable();
              }
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'spouseAddressIsTheSameAsCurrentAddress',
          type: 'checkbox',
          className: 'col-2',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,

            placeholder: '@blank',
            label: 'same_address_current',

            labelClass: 'col',
            inputClass: 'col',
            change: () => {
              if (
                (this.form.controls.employeeSpouse as FormGroup).controls.spouseAddressIsTheSameAsCurrentAddress.value
              ) {
                (this.form.controls.employeeSpouse as FormGroup).controls.spouseAddressIsTheSameAsHomeAddress.setValue(
                  false,
                );
                (this.form.controls.employeeSpouse as FormGroup).controls.address.reset();
                (this.form.controls.employeeSpouse as FormGroup).controls.district.reset();
                (this.form.controls.employeeSpouse as FormGroup).controls.subDistrict.reset();
                (this.form.controls.employeeSpouse as FormGroup).controls.province.reset();
                (this.form.controls.employeeSpouse as FormGroup).controls.zipcode.reset();
                (this.form.controls.employeeSpouse as FormGroup).controls.address.disable();
                (this.form.controls.employeeSpouse as FormGroup).controls.district.disable();
                (this.form.controls.employeeSpouse as FormGroup).controls.subDistrict.disable();
                (this.form.controls.employeeSpouse as FormGroup).controls.province.disable();
                (this.form.controls.employeeSpouse as FormGroup).controls.zipcode.disable();
              } else if (
                !(this.form.controls.employeeSpouse as FormGroup).controls.spouseAddressIsTheSameAsHomeAddress.value
              ) {
                (this.form.controls.employeeSpouse as FormGroup).controls.address.enable();
                (this.form.controls.employeeSpouse as FormGroup).controls.district.enable();
                (this.form.controls.employeeSpouse as FormGroup).controls.subDistrict.enable();
                (this.form.controls.employeeSpouse as FormGroup).controls.province.enable();
                (this.form.controls.employeeSpouse as FormGroup).controls.zipcode.enable();
              }
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'address',
          type: 'primeng-input',
          className: 'col-9',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'address',
            placeholder: '@blank',
            labelClass: 'col-2',
            inputClass: 'col',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'province',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'province',
            placeholder: '@blank',
            options: this.masterDataRepo.getProvinceOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',

            change: (field,$event) => {
              const provinceId = this.masterDataRepo.getProvinceIdByName(field.formControl.value);
              const controls_district = this.fields.find(a => a.key === 'employeeSpouse').fieldGroup.find(a => a.key === 'district');
              const controls_subdistrict = this.fields.find(a => a.key === 'employeeSpouse').fieldGroup.find(a => a.key === 'subDistrict');

              controls_district.formControl.setValue(undefined);
              controls_subdistrict.formControl.setValue(undefined);
              controls_district.templateOptions.options = this.masterDataRepo.getAmphoeOptionList(provinceId);
              (this.form.controls.employeeFather as FormGroup).controls.district.reset();
              (this.form.controls.employeeFather as FormGroup).controls.subDistrict.reset();
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'district',
          type: 'filter-dropdown',
          className: 'col-6',
          wrappers: ['inline-label'],
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeFatherModel) => {
              return this.disabledMotherForm() || !model.province;
            },
          },
          templateOptions: {
            translate: true,
            label: 'district',
            placeholder: '@blank',

            labelClass: 'col-3',
            inputClass: 'col-6',
            change: (field,$event) => {
              const amphoeId = this.masterDataRepo.getAmphoeIdByName(field.formControl.value);

              const controls_subdistrict = this.fields.find(a => a.key === 'employeeSpouse').fieldGroup.find(a => a.key === 'subDistrict');
              controls_subdistrict.formControl.setValue(undefined);
              controls_subdistrict.templateOptions.options = this.masterDataRepo.getTambonOptionList(amphoeId);
              (this.form.controls.employeeFather as FormGroup).controls.subDistrict.reset();
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'subDistrict',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeFatherModel) => {
              return this.disabledFatherForm() || !model.district;
            },
          },
          templateOptions: {
            translate: true,
            label: 'subdistrict',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'zipcode',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeFatherModel) => this.disabledMotherForm(),
          },
          templateOptions: {
            translate: true,
            label: 'postal_code',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-5',

            maxLength: 5,
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
  ];

  constructor(private masterDataRepo: MasterDataRepo) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maritalStatus.currentValue) {
      if (changes.maritalStatus.currentValue === '11') {
        this.form.controls.employeeSpouse.enable();
      } else {
        this.form.controls.employeeSpouse.disable();
      }
    }
  }

  disabledFatherForm(isAddressControl = false) {
    const disabledAddressForm =
      isAddressControl &&
      (this.model.employeeFather.fatherAddressIsTheSameAsCurrentAddress ||
        this.model.employeeFather.fatherAddressIsTheSameAsHomeAddress);
    return this.model.employeeFather.isFatherAlive === 'Unknown' || disabledAddressForm;
  }

  disabledMotherForm(isAddressControl = false) {
    const disabledAddressForm =
      isAddressControl &&
      (this.model.employeeMother.motherAddressIsTheSameAsCurrentAddress ||
        this.model.employeeMother.motherAddressIsTheSameAsHomeAddress ||
        this.model.employeeMother.motherAddressIsTheSameAsFatherAddress);
    return this.model.employeeMother.isMotherAlive === 'Unknown' || disabledAddressForm;
  }
}
