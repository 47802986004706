<div *ngIf="loading" class="grid justify-content-center">
  <div class="col-fixed">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<div *ngIf="!loading && !employeeList.length" class="grid justify-content-center">
  <div class="col-fixed">
    <h1>ไม่พบข้อมูล</h1>
  </div>
</div>
<div *ngIf="!loading" class="grid">
  <ng-container *ngFor="let employee of employeeList">
    <div class="col-12">
      <p-card>
        <div class="grid employee-card">
          <div class="col-fixed">
            <img src="/api/employee/{{ employee.id }}/image" width="100" class="tw-mx-auto" />
          </div>
          <div class="col">
            <div class="grid detail-1">
              <div class="col-12">
                <span>{{ employee.employeeCode }}</span>
              </div>
              <div class="col-12">
                <b>{{ employee.fullnameTh }}</b>
              </div>
              <div class="col-12">
                <b>{{ employee.companyName }}</b>
              </div>
              <div class="col-12">
                <b>{{ employee.unitName }}</b>
              </div>
              <div class="col-12">
                <b>{{ employee.positionName }}</b>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="col-12">
              <i class="fas fa-envelope"></i>
              <span>{{ employee.c_Email }}</span>
            </div>
            <div class="col-12">
              <i class="fas fa-phone-alt"></i>
              <span>{{ employee.phone }}</span>
            </div>
            <div class="col-12">
              <i class="fas fa-mobile"></i>
              <span>{{ employee.mphone }}</span>
            </div>
          </div>
          <div class="col">
            <div class="col-12">
              <i class="fas fa-globe-asia"></i>
              <span>{{ employee.raceName }}</span>
            </div>
            <div class="col-12">
              <i class="fas fa-birthday-cake"></i>
              <span>{{ employee.birthday | date: 'dd/MM/yyyy' }} ({{ employee.ageString }})</span>
            </div>
            <div class="col-12">
              <i class="fas fa-clock"></i>
              <span>{{ employee.startWorkingDate | date: 'dd/MM/yyyy' }} ({{ employee.workLongString }})</span>
            </div>
          </div>
          <div class="col-fixed" *ngIf="showActionBtn">
            <button pButton type="button" icon="far fa-eye" [routerLink]="[employee.id]"></button>
          </div>
          <div class="col-fixed" *ngIf="showViewBtn">
            <button pButton icon="pi pi-ellipsis-v" style="cursor: pointer"
              (click)="onEllipsisIconClick($event, menu, employee)"></button>
            <p-menu #menu [model]="employeeMenuItems" [popup]="true" [hidden]="!employeeMenuItems"></p-menu>
          </div>
        </div>
      </p-card>
    </div>
  </ng-container>
</div>
<div class="grid justify-content-between align-center">
  <div class="col-fixed">
    <label>
      {{ paginationParams.firstRowIdx + 1 }}-{{
        employeeList ? paginationParams.firstRowIdx + employeeList?.length : 0
      }}
      Results
    </label>
  </div>
  <div class="col-fixed">
    <label>Show :</label>
  </div>
  <div class="col-fixed">
    <p-dropdown [options]="pageSelectList" [(ngModel)]="paginationParams.rowPerPage"
      (onChange)="onPageChange($event.value)">
    </p-dropdown>
  </div>
  <div class="col"></div>
  <div class="col-fixed">
    <label>{{ paginationParams.page }}/{{ paginationParams.totalPage }}</label>
  </div>
  <div class="col-fixed">
    <button type="button" pButton class="p-button-secondary" icon="fas fa-chevron-left" (click)="onPrePageClick()"
      [disabled]="paginationParams.firstRowIdx === 0"></button>
  </div>
  <div class="col-fixed">
    <button type="button" pButton icon="fas fa-chevron-right" class="p-button-secondary" (click)="onNextPageClick()"
      [disabled]="paginationParams.totalRecords === paginationParams.firstRowIdx + employeeList?.length"></button>
  </div>
</div>
