<div class="grid pt-1">
  <div class="col-12">
    <div class="card card-w-title">
      <h1>{{ 'important_information' | translate }}</h1>
      <hr />
      <div class="grid">
        <div class="col-12">
          <div class="card shadow-1">
            <div class="grid justify-content-between">
              <div class="col-fixed">
                <h1>{{ 'general_information' | translate }}</h1>
              </div>
              <!-- <div class="col-fixed">
                <button pButton type="button" label="ส่งคำขอแก้ไขข้อมูล" icon="fa fa-pencil-alt"></button>
              </div> -->
            </div>
            <div class="grid general-info">
              <div class="col-6">
                <div class="col-12">
                  <i class="fas fa-birthday-cake" style="margin-right: 0.5vw"></i>
                  <b
                    >{{ generalInfo.birthday | date: 'dd/MM/yyyy' }}
                    {{ generalInfo.ageString ? '(' + generalInfo.ageString + ')' : '' }}</b
                  >
                </div>
                <div class="col-12">
                  <i class="fas fa-clock" style="margin-right: 0.5vw"></i>
                  <b>{{ generalInfo.employeeDate | date: 'dd/MM/yyyy' }} ({{ generalInfo.longWorkString }})</b>
                </div>
                <div class="col-12">
                  <i class="fas fa-globe-asia" style="margin-right: 0.5vw"></i>
                  <b>{{ generalInfo.raceName }}</b>
                </div>
                <div class="col-12">
                  <i class="fas fa-flag" style="margin-right: 0.5vw"></i>
                  <b>{{ generalInfo.nationalityName }}</b>
                </div>
                <div class="col-12">
                  <i class="fas fa-syringe" style="margin-right: 0.5vw"></i>
                  <b>{{ generalInfo.bloodGroup }}</b>
                </div>
                <div class="col-12">
                  <i class="far fa-life-ring" style="margin-right: 0.5vw"></i>
                  <b>{{ generalInfo.maritalStatus }}</b>
                </div>
              </div>
              <div class="col-6">
                <h1>Social Media</h1>
                <div class="col-12">
                  <i class="fab fa-facebook" style="margin-right: 0.5vw"></i>
                  <b>{{ generalInfo.facebook }}</b>
                </div>
                <div class="col-12">
                  <i class="fab fa-line" style="margin-right: 0.5vw"></i>
                  <b>{{ generalInfo.line }}</b>
                </div>
                <div class="col-12">
                  <i class="fab fa-instagram" style="margin-right: 0.5vw"></i>
                  <b>{{ generalInfo.instagram }}</b>
                </div>
                <div class="col-12">
                  <i class="fab fa-twitter" style="margin-right: 0.5vw"></i>
                  <b>{{ generalInfo.twitter }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-1">
            <div class="grid">
              <div class="col-6">
                <h1>{{ 'current_address' | translate }}</h1>
                <p>{{ currentAddress }}</p>
              </div>
              <div class="col-6">
                <h1>{{ 'address_house' | translate }}</h1>
                <p>{{ officialAddress }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-1">
            <div class="col-12">
              <h1>{{ 'family' | translate }}</h1>
              <p-carousel [value]="familyList" [numVisible]="4" [numScroll]="3" [circular]="false">
                <ng-template let-family pTemplate="item">
                  <div class="grid nogutter card-family">
                    <div class="col-12 card-family-header">
                      <i class="{{ family.icon }}"></i>
                    </div>
                    <div class="col-12 card-family-detail">
                      <div class="title">{{ family.firstnameTh }} {{ family.lastnameTh }}</div>
                      <div>{{ family.relation }}</div>
                      <div>{{ family.phone }}</div>
                    </div>
                  </div>
                </ng-template>
              </p-carousel>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-1">
            <div class="grid">
              <div class="col-fixed">
                <h1>{{ 'education' | translate }}</h1>
              </div>
            </div>
            <div class="col-12">
              <p-carousel [value]="educationList" [numVisible]="4" [numScroll]="3" [circular]="false">
                <ng-template let-education pTemplate="item">
                  <div class="grid nogutter card-education">
                    <div class="col-12 card-education-header">
                      <i class="fas fa-graduation-cap"></i>
                    </div>
                    <div class="col-12 card-education-detail">
                      <div>{{ education.educationLevelName }}</div>
                      <div class="title">{{ education.institute }}</div>
                      <div>{{ education.fieldName }}</div>
                      <div>{{ education.startYear }}-{{ education.endYear }}</div>
                    </div>
                  </div>
                </ng-template>
              </p-carousel>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-1">
            <div class="col-12">
              <h1>Certificates</h1>
              <p-carousel [value]="certificateList" [numVisible]="4" [numScroll]="3" [circular]="false">
                <ng-template let-certificate pTemplate="item">
                  <div class="grid nogutter card-certificate">
                    <div class="col-12 card-certificate-header">
                      <i class="fa fa-user-tie"></i>
                    </div>
                    <div class="col-12 card-certificate-detail">
                      <div>Certificates</div>
                      <div class="title">{{ certificate.certificateName }}</div>
                      <div>{{ certificate.issueDate || 'N/A' | date: 'yyyy' }}</div>
                    </div>
                  </div>
                </ng-template>
              </p-carousel>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-1">
            <div class="grid justify-content-between">
              <div class="col-fixed">
                <h1>{{ 'preferences_interests' | translate }}</h1>
              </div>
              <div class="col-fixed">
                <button
                  *ngIf="isEditable"
                  pButton
                  type="button"
                  label="{{ 'add_data' | translate }}"
                  (click)="showCreateEmployeeInterestDialog()"
                ></button>
              </div>
            </div>
            <div class="grid">
              <div class="col-fixed" *ngFor="let interest of interestList">
                <div class="card-interest-header">
                  <!-- <i class="fas fa-basketball-ball"></i> -->
                  <p>{{ interest.customTypeName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
