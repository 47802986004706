/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeTrainingListItemModel } from '../models/employee-training-list-item-model';
import { EmployeeTrainingModel } from '../models/employee-training-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeTrainingService extends __BaseService {
  static readonly GetEmployeeTrainingsListPath = '/api/EmployeeTraining';
  static readonly CreateEmployeeTrainingPath = '/api/EmployeeTraining';
  static readonly GetEmployeeTrainingByIdPath = '/api/EmployeeTraining/{EmployeeTrainingId}';
  static readonly UpdateEmployeeTrainingPath = '/api/EmployeeTraining/{EmployeeTrainingId}';
  static readonly DeleteEmployeeTrainingPath = '/api/EmployeeTraining/{EmployeeTrainingId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeTrainingsListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeTrainingListItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeTraining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeTrainingListItemModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeTrainingsList(): __Observable<Array<EmployeeTrainingListItemModel>> {
    return this.GetEmployeeTrainingsListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeTrainingListItemModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeTrainingResponse(body?: EmployeeTrainingModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeTraining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeTraining(body?: EmployeeTrainingModel): __Observable<string> {
    return this.CreateEmployeeTrainingResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeTrainingId undefined
   * @return Success
   */
  GetEmployeeTrainingByIdResponse(EmployeeTrainingId: number): __Observable<__StrictHttpResponse<EmployeeTrainingModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeTraining/${encodeURIComponent(String(EmployeeTrainingId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeTrainingModel>;
      })
    );
  }
  /**
   * @param EmployeeTrainingId undefined
   * @return Success
   */
  GetEmployeeTrainingById(EmployeeTrainingId: number): __Observable<EmployeeTrainingModel> {
    return this.GetEmployeeTrainingByIdResponse(EmployeeTrainingId).pipe(
      __map(_r => _r.body as EmployeeTrainingModel)
    );
  }

  /**
   * @param params The `EmployeeTrainingService.UpdateEmployeeTrainingParams` containing the following parameters:
   *
   * - `EmployeeTrainingId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeTrainingResponse(params: EmployeeTrainingService.UpdateEmployeeTrainingParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeTraining/${encodeURIComponent(String(params.EmployeeTrainingId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeTrainingService.UpdateEmployeeTrainingParams` containing the following parameters:
   *
   * - `EmployeeTrainingId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeTraining(params: EmployeeTrainingService.UpdateEmployeeTrainingParams): __Observable<string> {
    return this.UpdateEmployeeTrainingResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeTrainingId undefined
   * @return Success
   */
  DeleteEmployeeTrainingResponse(EmployeeTrainingId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeTraining/${encodeURIComponent(String(EmployeeTrainingId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeTrainingId undefined
   * @return Success
   */
  DeleteEmployeeTraining(EmployeeTrainingId: number): __Observable<string> {
    return this.DeleteEmployeeTrainingResponse(EmployeeTrainingId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeTrainingService {

  /**
   * Parameters for UpdateEmployeeTraining
   */
  export interface UpdateEmployeeTrainingParams {
    EmployeeTrainingId: number;
    body?: EmployeeTrainingModel;
  }
}

export { EmployeeTrainingService }
