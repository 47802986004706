import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import {
  EmployeeBankAccountModel,
  EmployeeBranchModel,
  EmployeeFixPayElementModel,
  EmployeeSalaryModel,
  EmployeeSocialSecurityModel,
  FixPayElementModel
} from 'app/api/models';
import {
  BranchService,
  EmployeeBankAccountService,
  EmployeeSalaryService,
  EmployeeService,
  EmployeeSocialSecurityService,
  PayElementService,
} from 'app/api/services';
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { ModalEmployeeBankAccountComponent } from '../../../modals/core/modal-employee-bank-account/modal-employee-bank-account.component';
import { ModalEmployeeSalaryComponent } from '../../../modals/core/modal-employee-salary/modal-employee-salary.component';
import { MasterDataRepo } from '../../../repo/master-data.repo';
import { CustomFormlyFieldConfig } from '../../../utils/formly-utils';

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.css'],
  providers: [DialogService],
})
export class SalaryComponent implements OnInit {
  isEditable = false;
  socialSecurity: EmployeeSocialSecurityModel;
  salaryList: EmployeeSalaryModel[];
  bankAccountList: EmployeeBankAccountModel[];
  ref: DynamicDialogRef;
  employeeBranch: EmployeeBranchModel;

  employeeUpdatedEvent = new EventEmitter();

  ssoModel: EmployeeSocialSecurityModel;
  ssoForm = new FormGroup({});
  ssoFields: CustomFormlyFieldConfig<keyof EmployeeSocialSecurityModel>[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'hasSocialSecurity',
          type: 'radio',
          className: 'col-6',
          wrappers: ['inline-label'],
          defaultValue: true,
          expressionProperties: {
            'templateOptions.disabled': () => !this.isEditable,
          },
          templateOptions: {
            translate: true,
            label: 'social_security_rights',
            placeholder: '@blank',
            required: true,
            attributes: {
              style: 'width: 100%;',
            },
            options: [
              { value: true, label: 'ขึ้นสิทธิ์' },
              { value: false, label: 'ไม่ขึ้นสิทธิ์' },
            ],
            labelClass: 'col-4',
            inputClass: 'col-8',
          },
        },
        {
          key: 'isDeductedSocialSecurity',
          type: 'radio',
          className: 'col-6',
          wrappers: ['inline-label'],
          defaultValue: true,
          templateOptions: {
            translate: true,
            label: 'social_security_rights_deduction',
            options: [
              { value: true, label: 'หักประกันสังคม' },
              { value: false, label: 'ไม่หักประกันสังคม' },
            ],
            labelClass: 'col-4',
            inputClass: 'col-8',
          },
          hideExpression: (model: EmployeeSocialSecurityModel) => !model.hasSocialSecurity,
          expressionProperties: {
            'templateOptions.disabled': (model: EmployeeSocialSecurityModel) => !this.isEditable,
            'model.isDeductedSocialSecurity': (model: EmployeeSocialSecurityModel) => {
              if (!this.ssoModel.hasSocialSecurity) {
                return false;
              } else {
                return true;
              }
            },
          },
        },
        {
          type: 'filter-dropdown',
          key: 'hospitalId',
          wrappers: ['inline-label'],
          className: 'col-8',
          expressionProperties: {
            'templateOptions.required': (model: EmployeeSocialSecurityModel) => model.hasSocialSecurity,
          },
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%',
            },
            placeholder: '@blank',
            label: 'social_security_hospital',
            required: false,
            labelClass: 'col-3',
            inputClass: 'col-6',
            options: this.masterDataRepo.getHospitalOptionList(),
          },
          hideExpression: (model: EmployeeSocialSecurityModel) => !model.hasSocialSecurity,
        },
      ],
    },
  ];

  branchModel = {
    branchId: 0,
  };

  branchOptions: FormlyFormOptions = {};
  branchForm = new FormGroup({});
  branchFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'branchId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          defaultValue: this.branchModel.branchId,
          className: 'col-8',
          templateOptions: {
            label: 'สาขา',
            labelClass: 'col-3',
            inputClass: 'col-6',

            attributes: {
              style: 'width: 100%;',
            },
            options: this.getBranchList(0)
          },
          hooks: {
            afterViewInit: (field: FormlyFieldConfig) => {
              field.defaultValue = this.employeeBranch.branchId;
            }
          }
        },

      ]
    }
  ]

  payElementModel: EmployeeFixPayElementModel;
  payElementForm = new FormGroup({});
  payElementFields: CustomFormlyFieldConfig<keyof EmployeeFixPayElementModel | keyof FixPayElementModel>[] = [
    {
      key: 'fixPayElements',
      type: 'repeat',
      templateOptions: {
        addText: '+ เพิ่มรายการรายได้รายหักประจำ',
        bigTitle: 'รายละเอียดรายได้รายหัก:',
        littleTitle: 'รายการที่ ',
        deleteTitle: 'ลบ',
      },
      fieldArray: {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            key: 'payElementId',
            type: 'select-ex',
            className: 'col-12',
            templateOptions: {
              label: 'รายได้ รายหัก',
              required: true,
              options: this.payElementService.GetPayElementList({
                ItemPerPage: 100000
              })
                .pipe(map(items => items.results.map(item => ({
                  value: item.payElementId,
                  label: item.name,
                  order: item.order
                }))))
            },
          },
          {
            key: 'amount',
            type: 'primeng-input',
            className: 'col-6',
            templateOptions: {
              label: 'จำนวนเงิน',
              type: 'number',
              required: true
            },
          },
          {
            key: 'effectiveDate',
            type: 'calendar',
            className: 'col-3',
            templateOptions: {
              label: 'วันที่มีผลใช้งาน',
              placeholder: 'd/m/y',
            },
          },
          {
            key: 'terminateDate',
            type: 'calendar',
            className: 'col-3',
            templateOptions: {
              label: 'วันที่สิ้นสุดใช้งาน',
              placeholder: 'd/m/y',
            },
          },
        ]
      }
    }
  ];

  constructor(
    private layout: LayoutDefaultComponent,
    private router: Router,
    private notification: AppNotificationServiceService,
    private dialogService: DialogService,
    private employeeService: EmployeeService,
    private employeeSalaryService: EmployeeSalaryService,
    private employeeSocialSecurityService: EmployeeSocialSecurityService,
    private employeeBankAccountService: EmployeeBankAccountService,
    private payElementService: PayElementService,
    private masterDataRepo: MasterDataRepo,
    private branchService: BranchService,
  ) { }

  ngOnInit(): void {
    this.setEditablePermission();
    this.setEmployeeBranch();
    this.setSalaryList();
    this.setBankAccountList();
    // this.setCashAdvancesList();
    this.setSocialSecurityData();

    this.employeeService.GetFixPayElement(this.getEmployeeId()).subscribe(data => {
      this.payElementForm.reset();
      this.payElementModel = {
        ...data,
      };
    });
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setSalaryList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetSalaries(employeeId).subscribe(salaries => {
      this.salaryList = salaries;
    });
  }

  // setCashAdvancesList() {
  //   const employeeId = this.getEmployeeId();

  //   this.starkMigrationService.GetCashAdvances(employeeId).subscribe(a => {
  //     this.cashAdvances = a.cashAdvances;
  //   });
  // }

  setBankAccountList() {
    const employeeId = this.getEmployeeId();

    this.employeeService.GetBankAccounts(employeeId).subscribe(bankAccs => {
      this.bankAccountList = bankAccs;
    });
  }

  setSocialSecurityData() {
    const employeeId = this.getEmployeeId();

    this.employeeSocialSecurityService.GetEmployeeSocialSecurity(employeeId).subscribe(socialSecurity => {
      this.ssoForm.reset();
      this.ssoModel = {
        ...socialSecurity,
      };
    });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  onCreateSalaryBtnClick() {
    this.showCreateEmployeeSalaryDialog();
  }

  onCreateBankAccountBtnClick() {
    this.showCreateEmployeeBankAccountDialog();
  }

  updatePayElements() {
    this.employeeService.UpdateFixPayElement({
      employeeId: this.getEmployeeId(),
      body: this.payElementModel
    }).subscribe(
      () => {
        this.notification.saveCompleted();
        this.setSocialSecurityData();
        this.employeeUpdatedEvent.next(null);
      },
      (err: HttpErrorResponse) => {
        const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
        this.notification.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
      },
    );
  }

  onUpdateSocialSecurityBtnClick() {
    this.employeeSocialSecurityService
      .UpdateEmployeeSocialSecurity({
        employeeId: this.getEmployeeId(),
        body: this.ssoModel,
      })
      .subscribe(
        () => {
          this.notification.saveCompleted();
          this.setSocialSecurityData();
          this.employeeUpdatedEvent.next(null);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notification.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  onUpdateBranchBtnClick() {
    this.employeeService.UpdateBranch({ employeeId: this.getEmployeeId(), branchId: this.branchModel.branchId }).subscribe(() => {
      this.notification.saveCompleted();
      this.setEmployeeBranch();
      this.employeeUpdatedEvent.next(null);
    },
      (err: HttpErrorResponse) => {
        const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
        this.notification.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
      });
  }

  showCreateEmployeeSalaryDialog() {
    this.showDialog({ employeeId: this.getEmployeeId() }, '+ เพิ่มข้อมูลเงินเดือน', ModalEmployeeSalaryComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setSalaryList();
      }
    });
  }

  showUpdateEmployeeSalaryDialog(rowData: EmployeeSalaryModel) {
    this.showDialog(
      { id: rowData.employeeSalaryId, employeeId: this.getEmployeeId() },
      'แก้ไขข้อมูลเงินเดือน',
      ModalEmployeeSalaryComponent,
    );

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setSalaryList();
      }
    });
  }

  setEmployeeBranch() {
    const employeeId = this.getEmployeeId();
    this.employeeService.GetBranch(employeeId).subscribe(data => {
      this.employeeBranch = { ...data };
      this.branchForm.get("branchId").setValue(this.employeeBranch.branchId);
    });

  }

  showDeleteEmployeeSalaryDialog(rowData: EmployeeSalaryModel) {
    this.notification.confirm('ยืนยันการลบข้อมูล', '').then((result: any) => {
      if (result.value) {
        this.employeeSalaryService.DeleteEmployeeSalary(rowData.employeeSalaryId).subscribe(
          () => {
            this.notification.deleteCompleted();
            this.setSalaryList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notification.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  showCreateEmployeeBankAccountDialog() {
    this.showDialog({ employeeId: this.getEmployeeId() }, '+ เพิ่มข้อมูลบัญชี', ModalEmployeeBankAccountComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setBankAccountList();
      }
    });
  }

  showUpdateEmployeeBankAccountDialog(employeeBankAccountId: number) {
    this.showDialog({ id: employeeBankAccountId }, 'แก้ไขข้อมูลบัญชี', ModalEmployeeBankAccountComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setBankAccountList();
      }
    });
  }

  showDeleteEmployeeBankAccountDialog(employeeBankAccountId: number) {
    this.notification.confirm('ยืนยันการลบข้อมูล', '').then((result: any) => {
      if (result.value) {
        this.employeeBankAccountService.DeleteEmployeeBankAccount(employeeBankAccountId).subscribe(
          () => {
            this.notification.deleteCompleted();
            this.setBankAccountList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notification.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  getBranchList(companyId: number) {
    return this.branchService.GetBranchList({ CompanyId: companyId, Page: 1, ItemPerPage: 1000 }).pipe(
      map(a => a.results.map(b =>
        ({ label: `${b.branchCode} ${b.name}`, value: b.branchId })
      )))
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'visible' },
      data,
      dismissableMask: false,
    });
  }
}
