import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BenefitElementModel, EnumItemModel, LeaveItemModel } from 'app/api/models';
import { LeaveService, DocumentService, LeaveRequestService, BenefitElementService, MasterDataService } from 'app/api/services';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'services/authentication.service';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';

interface SelectFileParam {
  currentFiles: File[];
}

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface LeaveFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof LeaveItemModel;
  fieldGroup?: LeaveFormlyFieldConfig[];
}

@Component({
  selector: 'app-modal-leave-request',
  templateUrl: './modal-leave-request.component.html',
  styleUrls: ['./modal-leave-request.component.css'],
})
export class ModalLeaveRequestComponent implements OnInit {
  model: LeaveItemModel = {};
  form = new FormGroup({});
  sickLeaveElement: BenefitElementModel = null;
  leaveTimeTypes: EnumItemModel[];
  fields: LeaveFormlyFieldConfig[] = [
    {
      key: 'benefitElementId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ประเภทการลา',
        labelClass: 'col-3',
        inputClass: 'col-4',
        options: this.masterDataRepo.getLeaveTypeOptionList(),
        disabled: true,
      },
    },
    {
      key: 'isSickFromAccident',
      type: 'primeng-checkbox',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เป็นการลาป่วยจากอุบัติเหตุ',
        labelClass: 'col-3',
        inputClass: 'col-9 col-offset-3',
        disabled: true
      },
      hideExpression: (model) => {
        return model.benefitElementId !== this.sickLeaveElement.benefitElementId;
      }
    },
    {
      key: 'leaveTimeType',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ประเภทการกำหนดเวลา',
        labelClass: 'col-3',
        inputClass: 'col-4',
        disabled: true,
        options: this.masterDataService.GetLeaveTimeTypes()
          .pipe(map(a => a.map(b => ({ label: b.name, value: b.value }))))
      },
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'beginDate',
          type: 'calendar',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            label: 'วันเริ่มต้น',
            labelClass: 'col-6',
            inputClass: 'col-6',
            disabled: true,
          },
        },
        {
          key: 'beginTime',
          type: 'time-picker',
          className: 'col-2',
          defaultValue: '00:00',
          wrappers: ['inline-label'],
          templateOptions: {
            inputClass: 'col',
            disabled: true,
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'endDate',
          type: 'calendar',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            label: 'วันที่สิ้นสุด',
            labelClass: 'col-6',
            inputClass: 'col-6',
            disabled: true,
          },
        },
        {
          key: 'endTime',
          type: 'time-picker',
          className: 'col-2',
          defaultValue: '00:00',
          wrappers: ['inline-label'],
          templateOptions: {
            inputClass: 'col',
            disabled: true,
          },
        },
      ],
    },
    {
      key: 'remark',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เหตุผลการลา',
        labelClass: 'col-3',
        inputClass: 'col-6',
        disabled: true,
      },
    },
    {
      key: 'documentOfLeaveId',
      type: 'upload-file',
      templateOptions: {
        label: 'หลักฐานการลา',
        chooseLabel: 'อัพโหลดไฟล์',
        labelClass: 'col-3',
        inputClass: 'col-6',
        disabled: true,
        onSelect: (selectFileParam: SelectFileParam, model: LeaveItemModel) => {
          this.documentService
            .CreateDocument({
              description: 'Leave Image',
              file: selectFileParam.currentFiles[0],
            })
            .subscribe(result => {
              model.documentOfLeave = {
                documentId: result.documentId,
                description: 'Leave Image',
                name: result.name,
              };
            });
        },
        onRemove: (_, model: LeaveItemModel) => {
          model.documentOfLeave = undefined;
        },
      },
    },
    {
      key: 'isApproved',
      defaultValue: true,
      hide: true
    },
  ];
  employeeId: number;
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private masterDataRepo: MasterDataRepo,
    private masterDataService: MasterDataService,
    private documentService: DocumentService,
    private leaveService: LeaveService,
    private leaveRequestService: LeaveRequestService,
    private notification: AppNotificationServiceService,
    private authService: AuthenticationService,
    private benefitElementService: BenefitElementService
  ) {
  }

  async ngOnInit(): Promise<void> {
    [this.leaveTimeTypes, this.sickLeaveElement] = await Promise.all([
      this.masterDataService.GetLeaveTimeTypes().toPromise(),
      this.benefitElementService.GetSickLeaveElement().toPromise()
    ]);
    this.initComponentMode();
    await this.setLeaveData();
  }

  initComponentMode() {
    this.componentMode = ComponentMode.UPDATE;
  }

  async setLeaveData() {
    const leaveId = this.getLeaveId();
    const leaveRequest = await this.leaveService.GetLeaveById(leaveId).toPromise();

    this.employeeId = leaveRequest.employeeId;

    this.model = leaveRequest;
  }

  getLeaveId() {
    return +this.config.data?.id;
  }

  getLeaveTimeTypes() {
    return this.leaveTimeTypes.map(a => ({
      label: a.name,
      value: a.value
    }));
  }

  onCancelClicked() {

    this.notification.confirm('ยืนยันการยกเลิกคำขอลา', '').then((result: any) => {
      if (result.value) {

        this.leaveRequestService.CancelLeaveRequest(this.model.leaveRequestId).subscribe(() => {
          this.notification.success('ยกเลิกคำขอลาสำเร็จ', '');
          this.close(true);
        }, err => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notification.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        });

      }
    });

  }

  close(isValueChanged: boolean) {
    this.ref.close(isValueChanged);
  }
}
