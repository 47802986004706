import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { map } from 'rxjs/operators';
import { DocumentService } from '../../../../../../api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EGender } from '../../../../../../api/models';
import * as moment from 'moment';
import { Form } from 'stimulsoft-viewer-angular/lib/services/objects';

export interface EmployeePersonalInfoCreateModel {
  c_Picture?: string;
  genderId?: EGender;
  employeeNameModel?: {
    titleTh?: string;
    firstnameTh?: string;
    middlenameTh?: string;
    lastnameTh?: string;
    titleEn?: string;
    firstnameEn?: string;
    middlenameEn?: string;
    lastnameEn?: string;
  };
  nickNameTh?: string;
  nickNameEn?: string;
  birthday?: string;
  religionId?: string;
  nationalityId?: string;
  raceId?: string;
  bloodGroup?: string;
  maritalStatusId?: string;
  cardNumber?: string;
  expireDate?: string;
  issuerPlace?: string;
  militaryStatusId?: string;
  imageDocumentId?: number;
  employeePhysicalMeasurementModel?: {
    weight?: number;
    heigth?: number;
  };
}

interface SelectFileParam {
  currentFiles: File[];
}

@Component({
  selector: 'app-employee-create-form-personal-info',
  templateUrl: './employee-create-form-personal-info.component.html',
  styleUrls: ['./employee-create-form-personal-info.component.css'],
})
export class EmployeeCreateFormPersonalInfoComponent implements OnInit {
  @Input('defaultModel')
  set defaultModel(val) {
    if (val) {
      Object.assign(this.model, val);
      this.fields[0].fieldGroup[0].templateOptions.value = this.model.imageDocumentId;
      this.fields[0].fieldGroup[0].templateOptions.showDefaultImage = true;
    }
  }

  isShowErr = true;

  form = new FormGroup({});
  model: EmployeePersonalInfoCreateModel = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'c_Picture',
          type: 'upload-file',
          className: 'col-9',
          templateOptions: {
            translate: true,
            required: false,
            label: 'avatar_picture',
            placeholder: '@blank',
            chooseLabel: 'อัพโหลดรูป',
            previewWidth: 150,
            accept: 'image/*',
            labelClass: 'col-2',
            inputClass: 'col',
            value: this.model.imageDocumentId,
            showDefaultImage: false,
            isDisplayImage:true,
            onSelect: (selectFileParam: SelectFileParam) => {
              this.documentService
                .CreateDocument({
                  description: 'Employee Image',
                  file: selectFileParam.currentFiles[0],
                })
                .subscribe(result => {
                  this.model.imageDocumentId = result.documentId;
                  this.fields[0].fieldGroup[0].templateOptions.showDefaultImage = false;
                  this.fields[0].fieldGroup[0].templateOptions.isDisplayImage=false;
                });
            },
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'genderId',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'sex',
            placeholder: '@blank',
            description: 'Description',
            required: true,
            labelClass: 'col-3',
            inputClass: 'col',
            options: this.masterDataRepo.getGenderOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
    {
      key: 'employeeNameModel',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'titleTh',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-3',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'name_surname_th',
            placeholder: '@blank',
            required: true,
            options: this.masterDataRepo.getTitleThOptionList(),
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'firstnameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-3',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'not',
            placeholder: 'name_th',
            required: true,
            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'middlenameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'not',
            placeholder: 'middle_name_th',
            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'lastnameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 125%;',
            },
            label: 'not',
            placeholder: 'surname_th',
            required: true,
            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'titleEn',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-3',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'name_surname_en',
            placeholder: '@blank',
            options: this.masterDataRepo.getTitleEnOptionList(),
            labelClass: 'col-6',
            inputClass: 'col-6',
            required: true
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'firstnameEn',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-3',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'not',
            placeholder: 'name_en',
            labelClass: 'col',
            inputClass: 'col-12',
            required: true
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'middlenameEn',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'not',
            placeholder: 'middle_name_en',
            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'lastnameEn',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 125%;',
            },
            label: 'not',
            placeholder: 'surname_en',
            labelClass: 'col',
            inputClass: 'col-12',
            required: true
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        // Line 3
        {
          key: 'nickNameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'nickname_th',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            required: true
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'nickNameEn',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'nickname_en',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },

        // Line 4
        {
          key: 'birthday',
          type: 'calendar',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'd/m/y',
            placeholder: 'd/m/y',
            labelClass: 'col-3',
            inputClass: 'col-6',
            required: true,
            change: (field: FormlyFieldConfig, event: any) => {
              // const value_birthday = field.formControl.value;
              // const value_today = moment(new Date).format('YYYY-MM-DD');
              // const controls_subdistrict = this.fields[3].fieldGroup.find(a => a.key === 'birthday');
              // console.log(value_birthday)
              // if(value_birthday => value_today){
              //   controls_subdistrict.formControl.setValue(undefined);
              //   this.notificationService.error('แจ้งเตือน', 'วันเกิด ห้ามเป็นวันที่ปัจจุบัน หรือวันที่อนาคต');
              //   return;
              // }
            },
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'religionId',
          type: 'filter-dropdown', // น่าจะเป็น input มากกว่า (หลายเชื้อชาติ)
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'religion',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            options: this.masterDataRepo.getReligionOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        // Line 5
        {
          key: 'nationalityId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'nationality',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            options: this.masterDataRepo.getNationalityOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'raceId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'race',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            options: this.masterDataRepo.getRaceOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        // Line 6
        {
          key: 'bloodGroup',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-6 ',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'blood_type',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            options: this.masterDataRepo.getBloodGroupOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'maritalStatusId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'marital_status',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            options: this.masterDataRepo.getMaritalStatusOptionList(),
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },

    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'cardNumber',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'card_number',
            placeholder: '@blank',
            required: true,
            labelClass: 'col-3',
            inputClass: 'col-6',
            maxLength: 13,
          },
          validators: {
            validation: ['thaiID'],
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'expireDate',
          type: 'calendar',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'card_expiration_date',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'issuerPlace',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'place_issue',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            options: this.masterDataRepo.getProvinceOptionList().pipe(
              map(optList => {
                return optList.map(opt => {
                  return {
                    label: opt.label,
                    value: opt.label,
                  };
                });
              }),
            ),
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'militaryStatusId',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          expressionProperties: {
            'templateOptions.required': `model.genderId == 'Male'`,
          },
          templateOptions: {
            translate: true,
            label: 'military_status',
            placeholder: '@blank',
            options: this.masterDataRepo.getMilitaryStatusOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',
            required: false
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },

    {
      key: 'employeePhysicalMeasurementModel',
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'weight',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'weight',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            type: 'number',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'heigth',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'height',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
            type: 'number',
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },

  ];


  constructor(
    private masterDataRepo: MasterDataRepo,
    private documentService: DocumentService,
    private notificationService: AppNotificationServiceService,
    ) {}

  ngOnInit(): void {}

  ShowErrorMessage(title: string,message: string): void {
  this.notificationService.error('แจ้งเตือน', 'วันเกิด ห้ามเป้นวันที่ปัจจุบัน หรือวันที่อนาคต');
  }

}
