/* tslint:disable */
type EBossPositionType =
  'Unit' |
  'Specific';
module EBossPositionType {
  export const UNIT: EBossPositionType = 'Unit';
  export const SPECIFIC: EBossPositionType = 'Specific';
  export function values(): EBossPositionType[] {
    return [
      UNIT,
      SPECIFIC
    ];
  }
}

export { EBossPositionType }