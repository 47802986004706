/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CompanyListItemModelPaginationResult } from '../models/company-list-item-model-pagination-result';
import { CompanyModel } from '../models/company-model';
@Injectable({
  providedIn: 'root',
})
class CompanyService extends __BaseService {
  static readonly GetCompaniesListPath = '/api/Company';
  static readonly CreateCompanyPath = '/api/Company';
  static readonly GetCompanyByIdPath = '/api/Company/{CompanyId}';
  static readonly UpdateCompanyPath = '/api/Company/{CompanyId}';
  static readonly DeleteCompanyPath = '/api/Company/{CompanyId}';
  static readonly GetCompanyByCodePath = '/api/Company/code:{CompanyCode}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CompanyService.GetCompaniesListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetCompaniesListResponse(params: CompanyService.GetCompaniesListParams): __Observable<__StrictHttpResponse<CompanyListItemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyListItemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `CompanyService.GetCompaniesListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetCompaniesList(params: CompanyService.GetCompaniesListParams): __Observable<CompanyListItemModelPaginationResult> {
    return this.GetCompaniesListResponse(params).pipe(
      __map(_r => _r.body as CompanyListItemModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateCompanyResponse(body?: CompanyModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateCompany(body?: CompanyModel): __Observable<string> {
    return this.CreateCompanyResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param CompanyId undefined
   * @return Success
   */
  GetCompanyByIdResponse(CompanyId: number): __Observable<__StrictHttpResponse<CompanyModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(CompanyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyModel>;
      })
    );
  }
  /**
   * @param CompanyId undefined
   * @return Success
   */
  GetCompanyById(CompanyId: number): __Observable<CompanyModel> {
    return this.GetCompanyByIdResponse(CompanyId).pipe(
      __map(_r => _r.body as CompanyModel)
    );
  }

  /**
   * @param params The `CompanyService.UpdateCompanyParams` containing the following parameters:
   *
   * - `CompanyId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateCompanyResponse(params: CompanyService.UpdateCompanyParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(params.CompanyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `CompanyService.UpdateCompanyParams` containing the following parameters:
   *
   * - `CompanyId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateCompany(params: CompanyService.UpdateCompanyParams): __Observable<string> {
    return this.UpdateCompanyResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param CompanyId undefined
   * @return Success
   */
  DeleteCompanyResponse(CompanyId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Company/${encodeURIComponent(String(CompanyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param CompanyId undefined
   * @return Success
   */
  DeleteCompany(CompanyId: number): __Observable<string> {
    return this.DeleteCompanyResponse(CompanyId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param CompanyCode undefined
   * @return Success
   */
  GetCompanyByCodeResponse(CompanyCode: string): __Observable<__StrictHttpResponse<CompanyModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Company/code:${encodeURIComponent(String(CompanyCode))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyModel>;
      })
    );
  }
  /**
   * @param CompanyCode undefined
   * @return Success
   */
  GetCompanyByCode(CompanyCode: string): __Observable<CompanyModel> {
    return this.GetCompanyByCodeResponse(CompanyCode).pipe(
      __map(_r => _r.body as CompanyModel)
    );
  }
}

module CompanyService {

  /**
   * Parameters for GetCompaniesList
   */
  export interface GetCompaniesListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for UpdateCompany
   */
  export interface UpdateCompanyParams {
    CompanyId: number;
    body?: CompanyModel;
  }
}

export { CompanyService }
