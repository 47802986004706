<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>ข้อมูลครอบครัว</h1>
        <hr />
        <div class="card">
          <div class="grid">
            <div class="col-12">
              <form [formGroup]="parentForm">
                <formly-form [model]="parentModel" [fields]="parentInfoFields" [form]="parentForm"> </formly-form>
              </form>
            </div>
            <div class="col-12">
              <div class="grid justify-content-end">
                <div class="col-fixed">
                  <button
                    *ngIf="isEditable"
                    pButton
                    type="button"
                    label="บันทึก"
                    (click)="onUpdateParentInfoBtnClick()"
                    [disabled]="
                      parentForm.controls.employeeFather.invalid ||
                      parentForm.controls.employeeMother.invalid ||
                      parentForm.controls.siblingCount.invalid ||
                      parentForm.controls.siblingIndex.invalid ||
                      !parentForm.dirty
                    "
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-12 divider"></div>
            <div class="col-12">
              <form [formGroup]="spouseForm">
                <formly-form [model]="spouseModel" [fields]="spouseInfoFields" [form]="spouseForm"> </formly-form>
              </form>
            </div>
            <div class="col-12">
              <div class="grid justify-content-end">
                <div class="col-fixed">
                  <button
                    *ngIf="isEditable"
                    pButton
                    type="button"
                    label="บันทึก"
                    (click)="onUpdateSpouseInfoBtnClick()"
                    [disabled]="spouseForm.controls.employeeSpouse.invalid || !spouseForm.dirty"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-12 divider"></div>
            <div class="col-12">
              <form [formGroup]="childrenForm">
                <formly-form [model]="childrenModel" [fields]="childrenInfoFields" [form]="childrenForm"> </formly-form>
              </form>
            </div>
            <div class="col-12">
              <div class="grid justify-content-end">
                <div class="col-fixed">
                  <button
                    *ngIf="isEditable"
                    pButton
                    type="button"
                    label="บันทึก"
                    (click)="onUpdateChildrenInfoBtnClick()"
                    [disabled]="childrenForm.controls.employeeChildren.invalid || !childrenForm.dirty"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-12 divider"></div>
            <div class="col-12">
              <form [formGroup]="emergencyForm">
                <formly-form [model]="emergencyModel" [fields]="emergencyContactInfoFields" [form]="emergencyForm">
                </formly-form>
              </form>
            </div>
            <div class="col-12">
              <div class="grid justify-content-end">
                <div class="col-fixed">
                  <button
                    *ngIf="isEditable"
                    pButton
                    type="button"
                    label="บันทึก"
                    (click)="onUpdateEmergencyContactInfoBtnClick()"
                    [disabled]="emergencyForm.invalid || !emergencyForm.dirty"
                  ></button>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 25%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
