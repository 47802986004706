<button
  *ngIf="to.required"
  type="text"
  [disabled]="personnelAreas.length == 0 || to.disabled"
  pButton
  type="button"
  [label]="label"
  (click)="showPersonnelAreaSearchModal()"
></button>
<p-splitButton
  *ngIf="!to.required"
  [disabled]="personnelAreas.length == 0 || to.disabled"
  (onClick)="showPersonnelAreaSearchModal()"
  [label]="label"
  [model]="items"
  [style]="{'width':'100%'}"
></p-splitButton>
