export let defaultRowPerPage = [5, 10, 20, 50, 100, 200];

export function getYearOptionList() {
  const currentYear = new Date().getFullYear();
  const end = currentYear + 20;
  const start = currentYear - 20;
  const yearList = [];
  for (let year = start; year <= end; year++) {
    yearList.push({ label: year, value: year });
  }
  return yearList;
}

export function getMonthOptionList() {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return monthNames.map((name, idx) => ({ label: name, value: idx }));
}
