import { Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-formly-repeat-condition',
  templateUrl: './formly-repeat-condition.component.html',
  styleUrls: ['./formly-repeat-condition.component.css'],
})
export class FormlyRepeatConditionComponent extends FieldArrayType {
  buildFormlyFieldConfig(field: FormlyFieldConfig, index: number): FormlyFieldConfig {
    if (index === 0) {
      const newField = field;
      newField.fieldGroup[0].fieldGroup[0].templateOptions.disabled = true;
      return newField;
    }

    return field;
  }
}
