/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeWithoutBenefitModelPaginationResult } from '../models/employee-without-benefit-model-pagination-result';
import { EmployeeSumBenefitModel } from '../models/employee-sum-benefit-model';
@Injectable({
  providedIn: 'root',
})
class BenefitEmployeeMonitorService extends __BaseService {
  static readonly GetEmployeeWithoutBenefitListPath = '/api/BenefitEmployeeMonitor/EmployeeWithoutBenefit';
  static readonly GenerateBenefitForEmployeePath = '/api/BenefitEmployeeMonitor/GenerateBenefitForEmployee';
  static readonly GetEmployeeSumBenefitPath = '/api/BenefitEmployeeMonitor/{employeeId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BenefitEmployeeMonitorService.GetEmployeeWithoutBenefitListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetEmployeeWithoutBenefitListResponse(params: BenefitEmployeeMonitorService.GetEmployeeWithoutBenefitListParams): __Observable<__StrictHttpResponse<EmployeeWithoutBenefitModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsOnlySubOrdinate != null) __params = __params.set('IsOnlySubOrdinate', params.IsOnlySubOrdinate.toString());
    if (params.IsExpiredEmployee != null) __params = __params.set('IsExpiredEmployee', params.IsExpiredEmployee.toString());
    if (params.IsAllEmployee != null) __params = __params.set('IsAllEmployee', params.IsAllEmployee.toString());
    if (params.EmployeeTypeId != null) __params = __params.set('EmployeeTypeId', params.EmployeeTypeId.toString());
    if (params.EmployeeType2Id != null) __params = __params.set('EmployeeType2Id', params.EmployeeType2Id.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.BranchId != null) __params = __params.set('BranchId', params.BranchId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BenefitEmployeeMonitor/EmployeeWithoutBenefit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeWithoutBenefitModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `BenefitEmployeeMonitorService.GetEmployeeWithoutBenefitListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsOnlySubOrdinate`:
   *
   * - `IsExpiredEmployee`:
   *
   * - `IsAllEmployee`:
   *
   * - `EmployeeTypeId`:
   *
   * - `EmployeeType2Id`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeId`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * - `BranchId`:
   *
   * @return Success
   */
  GetEmployeeWithoutBenefitList(params: BenefitEmployeeMonitorService.GetEmployeeWithoutBenefitListParams): __Observable<EmployeeWithoutBenefitModelPaginationResult> {
    return this.GetEmployeeWithoutBenefitListResponse(params).pipe(
      __map(_r => _r.body as EmployeeWithoutBenefitModelPaginationResult)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GenerateBenefitForEmployeeResponse(employeeId?: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (employeeId != null) __params = __params.set('employeeId', employeeId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/BenefitEmployeeMonitor/GenerateBenefitForEmployee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GenerateBenefitForEmployee(employeeId?: number): __Observable<string> {
    return this.GenerateBenefitForEmployeeResponse(employeeId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeSumBenefitResponse(employeeId: number): __Observable<__StrictHttpResponse<Array<EmployeeSumBenefitModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BenefitEmployeeMonitor/${encodeURIComponent(String(employeeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeSumBenefitModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeSumBenefit(employeeId: number): __Observable<Array<EmployeeSumBenefitModel>> {
    return this.GetEmployeeSumBenefitResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeSumBenefitModel>)
    );
  }
}

module BenefitEmployeeMonitorService {

  /**
   * Parameters for GetEmployeeWithoutBenefitList
   */
  export interface GetEmployeeWithoutBenefitListParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsOnlySubOrdinate?: boolean;
    IsExpiredEmployee?: boolean;
    IsAllEmployee?: boolean;
    EmployeeTypeId?: string;
    EmployeeType2Id?: string;
    EmployeeName?: string;
    EmployeeId?: number;
    EmployeeCode?: string;
    CompanyId?: number;
    BranchId?: number;
  }
}

export { BenefitEmployeeMonitorService }
