import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-select-ex',
  templateUrl: './formly-select-ex.component.html',
  styleUrls: ['./formly-select-ex.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlySelectExComponent extends FieldType implements OnInit {

  defaultOptions = {
    templateOptions: { options: [] },
  };

  ngOnInit(): void {
  }

}
