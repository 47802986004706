import { ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { PersonnelAreaTreeNodeModel } from '../../api/models';
import { PersonnelAreaService } from '../../api/services';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import {
  ModalPersonnelAreaSearchComponent,
  PersonnelAreaTreeNode,
} from 'modals/core/modal-personnel-area-search/modal-personnel-area-search.component';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MenuItem, TreeNode } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-formly-select-personnel-area',
  templateUrl: './formly-select-personnel-area.component.html',
  styleUrls: ['./formly-select-personnel-area.component.css'],
  providers: [DialogService],
})
export class FormlySelectPersonnelAreaComponent extends FieldType implements OnInit {
  readonly mainLabel = 'เลือก Org. Matrix';
  items: MenuItem[];
  ref: DynamicDialogRef;
  label = this.mainLabel;

  personnelAreas: TreeNode[] = [];
  cols = [
    { field: 'name', header: 'ชื่อ Org. Matrix' },
    { field: 'company', header: 'บริษัท' },
  ];
  updatePersonnelAreaEvent = new EventEmitter<number>();
  constructor(private personnelAreaService: PersonnelAreaService, private dialogService: DialogService) {
    super();
  }

  findTreeNode(id: number, personnelAreas: TreeNode[]): TreeNode {
    for (const personnelArea of personnelAreas) {
      if (personnelArea.data.id === id) {
        return personnelArea;
      }
      if (personnelArea.children) {
        const foundNode = this.findTreeNode(id, personnelArea.children);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  }

  mapPersonnelAreaToTreeNode(a: PersonnelAreaTreeNodeModel): TreeNode {
    const node: TreeNode = {
      label: a.name,
      data: {
        id: a.personalAreaId,
        name: a.name,
        fullName: a.fullName,
        company: a.company,
      },
    };
    node.children = a.children.map(b => this.mapPersonnelAreaToTreeNode(b));
    return node;
  }

  ngOnInit(): void {
    this.items = [
      {
        label: 'ล้าง',
        icon: 'pi pi-refresh',
        command: () => {
          this.select({}, {});
        },
      },
    ];
    this.updatePersonnelAreaEvent.pipe(debounceTime(300)).subscribe(companyId => {
      this.setPersonnelAreas(companyId);
    });
    this.updatePersonnelAreaEvent.emit();
    this.subCompanyChange();
  }
  setPersonnelAreas(companyId?: number) {
    this.personnelAreas = [];
    if (this.to.isWaitCompanyId) {
      return;
    }

    this.personnelAreaService.GetPersonnelAreaTreeNode({ companyId }).subscribe(a => {
      this.personnelAreas = a.map(b => this.mapPersonnelAreaToTreeNode(b));
      if (this.formControl.value) {
        const node = this.findTreeNode(this.formControl.value, this.personnelAreas);
        if (node) {
          this.label = node.data.fullName;
        }
      } else {
        this.label = this.mainLabel;
      }
    });
  }

  select(event, selectNode: TreeNode, panel?: OverlayPanel) {
    this.label = selectNode.data?.fullName ?? this.mainLabel;
    this.formControl.setValue(selectNode.data?.id);

    if (typeof this.to?.change === 'function') {
      this.to.change(this, { event, value: selectNode.data?.id });
    }

    // panel.hide();
  }

  subCompanyChange() {
    if (this.to?.companyChange instanceof EventEmitter || this.to?.companyChange instanceof Subject) {
      this.to.companyChange.subscribe(companyId => {
        // this.setPersonnelAreas(companyId);
        this.to.isWaitCompanyId = false;
        this.updatePersonnelAreaEvent.emit(companyId);
      });
    }
  }

  showPersonnelAreaSearchModal() {
    this.ref = this.dialogService.open(ModalPersonnelAreaSearchComponent, {
      header: 'ค้นหา Org. Matrix',
      width: '70%',
      dismissableMask: false,
      contentStyle: { overflow: 'auto' },
      data: {
        personnelAreas: this.personnelAreas,
      },
    });

    this.ref.onClose.subscribe((selectNode: PersonnelAreaTreeNode) => {
      if (selectNode) {
        this.select(undefined, selectNode);
      }
    });
  }
}
