import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CompanyService, LocationService } from 'app/api/services';
import { FormGroup, FormControl } from '@angular/forms';
import { CompanyModel } from 'app/api/models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject, of } from 'rxjs';
import { finalize, takeUntil, map, catchError, debounceTime,tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { toOptionList } from 'utils/formly-utils';
import { min } from 'moment';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof CompanyModel;
}

@Component({
  selector: 'app-modal-company',
  templateUrl: './modal-company.component.html',
  styleUrls: ['./modal-company.component.css'],
})
export class ModalCompanyComponent implements OnInit, OnDestroy {
  model: CompanyModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      template: "<h2 class='mb-0'>ข้อมูลทั่วไป</h2>"
    },
    {
      key: 'code',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'code',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4',
        maxLength: 32,
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'name_company',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
        maxLength: 128,
      },
    },
    {
      key: 'nameEn',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'name_company_en',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-8',
        maxLength: 128,
      },
    },
    {
      key: 'taxId',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'tax_id',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
        maxLength: 13,
      },
    },
    {
      key: "tel_Number",
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'company_tel',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-8',
        maxLength: 10,
      }
    },
    {
      key: 'branchName',
      type: 'primeng-input',
      defaultValue: 'สำนักงานใหญ่',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'branch_main_name',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      }
    },
    {
      key: 'branchNameEn',
      type: 'primeng-input',
      defaultValue: 'Head Quarter',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'branch_main_name_en',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      }
    },
    {
      key: 'branchCode',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      defaultValue: '00000',
      templateOptions: {
        translate: true,
        label: 'branch_main_code',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
      }
    },
    {
      template: "<h2 class='mb-0'>ข้อมูลที่อยู่ </h2>"
    },
    {
      key: 'address',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'address',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-8',
        maxLength: 512,
      },
    },
    {
      key: 'province',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      expressionProperties: {
      },
      templateOptions: {
        translate: true,
        required: true,
        label: 'province',
        placeholder: '@blank',
        options: this.masterDataRepo.getProvinceOptionList(),
        labelClass: 'col-4',
        inputClass: 'col-8',
        change: (_: FormlyFieldConfig, { value }) => {
        },
      },
      hooks: {
        onInit: (field) => {
          field.formControl.valueChanges.pipe(
            tap(obj => this.OnProvinceChanged(field)),
          ).subscribe();
        },
        afterViewInit: (field) => {
          field.formControl.updateValueAndValidity();
        }
      }
    },
    {
      key: 'district',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'district',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        change: (_: FormlyFieldConfig, { value }) => {
        },
      },
      hooks: {
        onInit: (field) => {
          field.formControl.valueChanges.pipe(
            tap(obj => this.OnAmphoeChanged(field)),
          ).subscribe();
        },
        afterViewInit: (field) => {
          field.formControl.updateValueAndValidity();
        }
      }
    },
    {
      key: 'subDistrict',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        required: true,
        translate: true,
        label: 'subdistrict',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
    {
      key: 'zipcode',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'postal_code',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        maxLength: 5,
      },
    },
    {
      key: 'countryCode',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'country',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-8',
        options: this.masterDataRepo
          .GetCountryList(),
      },
    },
    {
      template: "<h2 class='mb-0'>ข้อมูลสำหรับ Payroll </h2>"
    },
    {
      key: 'pvdContributionPercent',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'pvd_contribution_per',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-8',
        type: 'number',
        min:0,
      },
    },
    {
      template: "<h2 class='mb-0'>ข้อมูลประกันสังคม </h2>"
    },
    {
      key: 'social_Security_Number',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'social_security_number',
        required: true,
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        maxLength: 13,
      }
    },
    {
      key: 'businessCode',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'business_code',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
      }
    },
    {
      key: 'contribution_Rate',
      type: 'primeng-input',
      defaultValue: 5,
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'contribution_rate',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
        type: 'number',
        min:0,
      }
    },
    {
      template: "<h2 class='mb-0'>ข้อมูลการทดลองงาน </h2>"
    },
    {
      key: 'probationDays',
      type: 'primeng-input',
      defaultValue: 90,
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'probationDays',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
        type: 'number',
        min:0,
      }
    },
    {
      key: 'firstProbationEvaluationDays',
      type: 'primeng-input',
      defaultValue: 60,
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'firstProbationEvaluationDays',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
        type: 'number',
        min:0,
      }
    },
    {
      key: 'secondProbationEvaluationDays',
      type: 'primeng-input',
      defaultValue: 90,
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'secondProbationEvaluationDays',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-8',
        type: 'number',
        min:0,
      }
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private companyService: CompanyService,
    private locationService: LocationService,
    private masterDataRepo: MasterDataRepo
  ) { }

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();

  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const companyId = this.getCompanyId();

    if (companyId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {};
      return;
    }

    this.model = await this.companyService.GetCompanyById(this.getCompanyId()).toPromise();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.model.terminateDate = '9999-09-09T00:00:00';

        this.companyService
          .CreateCompany(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.companyService
          .UpdateCompany({
            CompanyId: this.model.companyId,
            body: this.model,
          })
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getCompanyId() {
    return +this.config.data?.id;
  }

  OnProvinceChanged(val: any) {
    const districtControl = this.fields.find(a => a.key === 'district');
    const subDistrictControl = this.fields.find(a => a.key === 'subDistrict');

    const provinceId = this.masterDataRepo.getProvinceIdByName(val.formControl.value);
    if(provinceId != undefined){
      districtControl.templateOptions.options = this.masterDataRepo.getAmphoeOptionList(provinceId);
    }
    districtControl.formControl.setValue(undefined);
    subDistrictControl.formControl.setValue(undefined);
  }

  OnAmphoeChanged(val: any) {
    const subDistrictControl = this.fields.find(a => a.key === 'subDistrict');

    const AmphoeId = this.masterDataRepo.getAmphoeIdByName(val.formControl.value);
    if(AmphoeId != undefined){
      subDistrictControl.templateOptions.options = this.masterDataRepo.getTambonOptionList(AmphoeId);
    }
    subDistrictControl.formControl.setValue(undefined);
  }


}
