<div class="grid">
  <div *ngIf="to.label && to.hideLabel !== true" [class]="to.labelClass" style="text-align: right">
    <label>
      {{ to.label }}
      <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
    </label>
  </div>
  <div [class]="to.inputClass">
    <!-- <ng-container #fieldComponent></ng-container> -->
    <div class="grid">
      <div class="col-fixed">
        <p-inputSwitch [formControl]="$any(formControl)" [pTooltip]="to?.tooltip || undefined"></p-inputSwitch>
      </div>
      <div class="col-fixed" *ngIf="formControl.value === true">
        {{ to.trueMessage }}
      </div>
      <div class="col-fixed" *ngIf="formControl.value === false">
        {{ to.falseMessage }}
      </div>
    </div>
    <!-- <div class="p-message ui-widget ui-corner-all p-message-error p-messages-error" *ngIf="showError">
      <formly-validation-message class="p-message-text" [field]="field"></formly-validation-message>
    </div> -->
  </div>
</div>
