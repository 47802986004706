/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PayrollPeriodListItemModelPaginationResult } from '../models/payroll-period-list-item-model-pagination-result';
import { PayrollPeriodModel } from '../models/payroll-period-model';
import { PayrollPeriodEmployeeListItemModelPaginationResult } from '../models/payroll-period-employee-list-item-model-pagination-result';
@Injectable({
  providedIn: 'root',
})
class PayrollPeriodService extends __BaseService {
  static readonly GetPayrollPeriodListPath = '/api/PayrollPeriod';
  static readonly CreatePath = '/api/PayrollPeriod';
  static readonly DeletePath = '/api/PayrollPeriod';
  static readonly GetByIdPath = '/api/PayrollPeriod/{id}';
  static readonly UpdatePath = '/api/PayrollPeriod/{id}';
  static readonly GetEmployeesPath = '/api/PayrollPeriod/{payrollPeriodId}/employees';
  static readonly AddEmployeesPath = '/api/PayrollPeriod/{payrollPeriodId}/employees';
  static readonly RemoveEmployeesPath = '/api/PayrollPeriod/{payrollPeriodId}/employees';
  static readonly ProcessPath = '/api/PayrollPeriod/{payrollPeriodId}/process';
  static readonly ValidatePath = '/api/PayrollPeriod/{payrollPeriodId}/validate';
  static readonly ClosePeriodPath = '/api/PayrollPeriod/{payrollPeriodId}/close-period';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PayrollPeriodService.GetPayrollPeriodListParams` containing the following parameters:
   *
   * - `Year`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `Month`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetPayrollPeriodListResponse(params: PayrollPeriodService.GetPayrollPeriodListParams): __Observable<__StrictHttpResponse<PayrollPeriodListItemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Year != null) __params = __params.set('Year', params.Year.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.Month != null) __params = __params.set('Month', params.Month.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PayrollPeriod`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PayrollPeriodListItemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `PayrollPeriodService.GetPayrollPeriodListParams` containing the following parameters:
   *
   * - `Year`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `Month`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetPayrollPeriodList(params: PayrollPeriodService.GetPayrollPeriodListParams): __Observable<PayrollPeriodListItemModelPaginationResult> {
    return this.GetPayrollPeriodListResponse(params).pipe(
      __map(_r => _r.body as PayrollPeriodListItemModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateResponse(body?: PayrollPeriodModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PayrollPeriod`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  Create(body?: PayrollPeriodModel): __Observable<string> {
    return this.CreateResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DeleteResponse(id?: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PayrollPeriod`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Delete(id?: number): __Observable<string> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetByIdResponse(id: number): __Observable<__StrictHttpResponse<PayrollPeriodModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PayrollPeriod/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PayrollPeriodModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetById(id: number): __Observable<PayrollPeriodModel> {
    return this.GetByIdResponse(id).pipe(
      __map(_r => _r.body as PayrollPeriodModel)
    );
  }

  /**
   * @param params The `PayrollPeriodService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateResponse(params: PayrollPeriodService.UpdateParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/PayrollPeriod/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `PayrollPeriodService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`:
   *
   * @return Success
   */
  Update(params: PayrollPeriodService.UpdateParams): __Observable<string> {
    return this.UpdateResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `PayrollPeriodService.GetEmployeesParams` containing the following parameters:
   *
   * - `payrollPeriodId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetEmployeesResponse(params: PayrollPeriodService.GetEmployeesParams): __Observable<__StrictHttpResponse<PayrollPeriodEmployeeListItemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PayrollPeriod/${encodeURIComponent(String(params.payrollPeriodId))}/employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PayrollPeriodEmployeeListItemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `PayrollPeriodService.GetEmployeesParams` containing the following parameters:
   *
   * - `payrollPeriodId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetEmployees(params: PayrollPeriodService.GetEmployeesParams): __Observable<PayrollPeriodEmployeeListItemModelPaginationResult> {
    return this.GetEmployeesResponse(params).pipe(
      __map(_r => _r.body as PayrollPeriodEmployeeListItemModelPaginationResult)
    );
  }

  /**
   * @param params The `PayrollPeriodService.AddEmployeesParams` containing the following parameters:
   *
   * - `payrollPeriodId`:
   *
   * - `body`:
   *
   * @return Success
   */
  AddEmployeesResponse(params: PayrollPeriodService.AddEmployeesParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PayrollPeriod/${encodeURIComponent(String(params.payrollPeriodId))}/employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `PayrollPeriodService.AddEmployeesParams` containing the following parameters:
   *
   * - `payrollPeriodId`:
   *
   * - `body`:
   *
   * @return Success
   */
  AddEmployees(params: PayrollPeriodService.AddEmployeesParams): __Observable<string> {
    return this.AddEmployeesResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `PayrollPeriodService.RemoveEmployeesParams` containing the following parameters:
   *
   * - `payrollPeriodId`:
   *
   * - `body`:
   *
   * @return Success
   */
  RemoveEmployeesResponse(params: PayrollPeriodService.RemoveEmployeesParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PayrollPeriod/${encodeURIComponent(String(params.payrollPeriodId))}/employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `PayrollPeriodService.RemoveEmployeesParams` containing the following parameters:
   *
   * - `payrollPeriodId`:
   *
   * - `body`:
   *
   * @return Success
   */
  RemoveEmployees(params: PayrollPeriodService.RemoveEmployeesParams): __Observable<string> {
    return this.RemoveEmployeesResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param payrollPeriodId undefined
   * @return Success
   */
  ProcessResponse(payrollPeriodId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PayrollPeriod/${encodeURIComponent(String(payrollPeriodId))}/process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param payrollPeriodId undefined
   * @return Success
   */
  Process(payrollPeriodId: number): __Observable<string> {
    return this.ProcessResponse(payrollPeriodId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param payrollPeriodId undefined
   * @return Success
   */
  ValidateResponse(payrollPeriodId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PayrollPeriod/${encodeURIComponent(String(payrollPeriodId))}/validate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param payrollPeriodId undefined
   * @return Success
   */
  Validate(payrollPeriodId: number): __Observable<string> {
    return this.ValidateResponse(payrollPeriodId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param payrollPeriodId undefined
   * @return Success
   */
  ClosePeriodResponse(payrollPeriodId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PayrollPeriod/${encodeURIComponent(String(payrollPeriodId))}/close-period`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param payrollPeriodId undefined
   * @return Success
   */
  ClosePeriod(payrollPeriodId: number): __Observable<string> {
    return this.ClosePeriodResponse(payrollPeriodId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module PayrollPeriodService {

  /**
   * Parameters for GetPayrollPeriodList
   */
  export interface GetPayrollPeriodListParams {
    Year?: number;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    Month?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    CompanyId?: number;
  }

  /**
   * Parameters for Update
   */
  export interface UpdateParams {
    id: number;
    body?: PayrollPeriodModel;
  }

  /**
   * Parameters for GetEmployees
   */
  export interface GetEmployeesParams {
    payrollPeriodId: number;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for AddEmployees
   */
  export interface AddEmployeesParams {
    payrollPeriodId: number;
    body?: Array<number>;
  }

  /**
   * Parameters for RemoveEmployees
   */
  export interface RemoveEmployeesParams {
    payrollPeriodId: number;
    body?: Array<number>;
  }
}

export { PayrollPeriodService }
