import { PipeTransform, Pipe, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'dayHourFormat' })
export class DayHourFormatPipe implements PipeTransform {
  transform(value: number): string {
    
    const convert_day_hour = Number(value).toFixed(2);

    let [day, hour] = convert_day_hour.split('.');
    let hourNumber = (Math.round(Number(hour) / 100 * 8));

    let display_day_hour = '';
    if (hour == null || hour == '00') {
      display_day_hour = `${day} วัน`;
    }else if (day == '0' && hour != '00'){
      display_day_hour = `${hourNumber} ชั่วโมง`;
    }else{
      display_day_hour = `${day} วัน ${hourNumber} ชั่วโมง`;
    }

    return display_day_hour;
  }
}
