import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { GridOptions } from 'ag-grid-community';
import { FormlyAgGridCellComponent } from '../formly-ag-grid-cell/formly-ag-grid-cell.component';

@Component({
  selector: 'app-formly-ag-grid',
  templateUrl: './formly-ag-grid.component.html',
  styleUrls: ['./formly-ag-grid.component.css'],
})
export class FormlyAgGridComponent extends FieldArrayType implements OnInit {
  @ViewChild('agGrid') agGrid: TemplateRef<any>;

  gridOptions: GridOptions;
  style: any = {};

  ngOnInit() {
    this.style = {
      width: this.to.width,
      height: this.to.height,
    };

    // map cell Renderer to Formly Component
    this.to.gridOptions.columnDefs.forEach(column => (column.cellRendererFramework = FormlyAgGridCellComponent));

    // set grid options and context of the parent formly field
    const gridOptions: GridOptions = this.to.gridOptions || {};
    gridOptions.context = {
      parentField: this.field,
    };

    this.gridOptions = gridOptions;
  }

  onFirstDataRendered(params) {
    if (this.to.sizeColumnsToFit == null || this.to.sizeColumnsToFit) {
      params.api.sizeColumnsToFit();
    }
  }
}
