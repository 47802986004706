import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject, ReplaySubject, of, Observable } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { map, distinctUntilChanged, debounceTime, finalize, catchError } from 'rxjs/operators';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { UnitModel, PositionModel } from 'app/api/models';
import { HttpErrorResponse } from '@angular/common/http';
import { toOptionList } from 'utils/formly-utils';
import * as moment from 'moment';
import { BranchService, CostCenterService, UnitLevelService, UnitService } from 'app/api/services';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { MasterDataRepo } from 'app/repo/master-data.repo';


interface UnitFormModal extends UnitModel {
  headOfUnitList: PositionModel[];
  unitDefEffectiveDate?: string;
  isNotSpecifyTerminateDate?: boolean;
}

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface UnitLevelOptionListModel {
  label: string;
  value: number;
  level: number;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof UnitFormModal;
}

@Component({
  selector: 'app-unit-edit',
  templateUrl: './unit-edit.component.html',
  styleUrls: ['./unit-edit.component.scss']
})
export class UnitEditComponent implements OnInit, OnDestroy {
  _unitId = null;
  @Input()
  set unitId(v) { this._unitId = v; this.initData(); }
  get unitId() { return this._unitId; }
  _companyId = null;
  @Input()
  set companyId(v) { this._companyId = v; this.companyIdChange.next(v); }
  get companyId() { return this._companyId; }
  @Input()
  effectiveDate = new Date();
  @Output()
  onSaved = new EventEmitter<UnitModel>();
  @Output()
  onClose = new EventEmitter();

  unitIdChange = new ReplaySubject(1);
  companyIdChange = new ReplaySubject<number>(1);
  model: UnitFormModal;
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'code',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'agency_code',
        placeholder: '@blank',
        // required: true,
        disabled: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'unitLevelId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'department_level',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'organization_name',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'nameEn',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'organization_name_en',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'parentId',
      type: 'select-unit',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'agency_under',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        companyChange: this.companyIdChange,
        isWaitCompanyId: true,
      },
    },
    {
      key: 'locationId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'working_area',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: true,
      },
    },
    {
      key: 'costCenterId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'ศูนย์ต้นทุน',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: new Observable(a => this.companyIdChange.subscribe(async b => {
          const result = await this.costCenterService.GetCostCenters({ companyId: b, Page: 1, ItemPerPage: 1000 }).toPromise();
          a.next(result.results.map(c => ({ label: `${c.code} ${c.name}`, value: c.costCenterId })));
        })),
      },
    },
    {
      key: 'bpCode',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'รหัส BPlus',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: "branchId",
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'branch',
        translate: true,
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: true,
        options: new Observable(a => this.companyIdChange.subscribe(async b => {
          const result = await this.branchService.GetBranchList({ CompanyId: b, Page: 1, ItemPerPage: 1000 }).toPromise();
          a.next(result.results.map(c => ({ label: `${c.branchCode} ${c.name}`, value: c.branchId })));
        }))
      },
    },
    {
      key: 'effectiveDate',
      type: 'calendar',
      defaultValue: moment().format('YYYY-MM-DD'),
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'start_date_user',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
    {
      fieldGroupClassName: 'grid align-center',
      fieldGroup: [
        {
          key: 'terminateDate',
          type: 'calendar',
          className: 'col-8',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'end_use_date',
            placeholder: '@blank',
            labelClass: 'col-6',
            inputClass: 'col',
            required: true,
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.isNotSpecifyTerminateDate',
          },
        },
        {
          key: 'isNotSpecifyTerminateDate',
          type: 'checkbox',
          className: 'col-4',
          wrappers: ['inline-label'],
          defaultValue: true,
          templateOptions: {
            translate: true,
            label: 'no_date_specified',
            placeholder: '@blank',
            labelClass: 'col',
            inputClass: 'col',
            attributes: {
              style: 'width: 100%;',
            },
          },
        },
      ],
    },

    {
      key: 'headOfUnitList',
      type: 'select-position',
      templateOptions: {
        translate: true,
        label: 'supervisor',
        placeholder: '@blank',
        labelClass: 'col-4 text-label',
        inputClass: 'col-4',
        addSectionLabel: 'เลือกจากรายการ',
        companyChange: this.companyIdChange,
        unitIdChange: this.unitIdChange,
        isWaitCompanyId: true,
        isSingleSelect: true,
        disabledSelectCompany: false,
        tooltip: 'เลือกผู้บังคับบัญชาของหน่วยงานนี้ กรณีที่มีคนอยู่ในหน่วยงานมากกว่า 1 คน',
      },

      fieldArray: {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            key: 'name',
            type: 'primeng-input',
            className: 'col-12',
            templateOptions: {
              attributes: {
                style: 'width: 100%',
              },
              labelClass: 'col-4',
              inputClass: 'col-8',
              disabled: true,
              required: true,
            },
          },
        ],
      },
    },
    // {
    //   key: 'bpCode',
    //   type: 'primeng-input',
    //   wrappers: ['inline-label'],
    //   templateOptions: {
    //     translate: true,
    //     label: 'bp_code_unit',
    //     placeholder: '@blank',
    //     labelClass: 'col-4',
    //     inputClass: 'col-4',
    //   },
    // },
  ];
  unsubscripe$ = new Subject<any>();
  componentMode: ComponentMode;
  unitLevelOptionList: UnitLevelOptionListModel[] = [];

  constructor(
    private noti: AppNotificationServiceService,
    private unitService: UnitService,
    private unitLevelService: UnitLevelService,
    private optionListRepo: OptionListRepo,
    public cdr: ChangeDetectorRef,
    private costCenterService: CostCenterService,
    private branchService: BranchService,
    private notification: AppNotificationServiceService
  ) {
  }

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
    await this.initOptionList();
  }

  ngOnDestroy(): void {
    this.unsubscripe$.unsubscribe();
  }

  initComponentMode() {
    if (this.unitId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'locationId').templateOptions.options = await this.optionListRepo
      .getLocationOptionList({})
      .toPromise();
    await this.setUnitLevelOptionList();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {
        companyId: this.companyId,
        headOfUnitList: []
      };
      return;
    }

    if (this.unitId) {
      const unit = await this.unitService
        .GetUnitById({
          unitId: this.unitId,
          effectiveDate: new Date(this.effectiveDate).toJSON(),
        })
        .toPromise();

      this.model = {
        ...unit,
        effectiveDate: (unit.effectiveDate),
        nameEn: unit.nameEn,
        terminateDate: (unit.terminateDate),
        isNotSpecifyTerminateDate: (unit.terminateDate) === '9999-12-31',
        headOfUnitList: unit.headOfUnitPosition
          ? [
            {
              positionId: unit.headOfUnitPositionId,
              name: unit.headOfUnitPosition.name,
            },
          ]
          : [],
      };

    }

    // assign value unitId เพื่อจะส่งไปกรอง ตำแหน่ง ผู้บังคับบัญชา ในหน่วยงาน
    this.unitIdChange.next(this.model.unitId);
    //console.log(this.model.unitId)
  }


  async setUnitLevelOptionList() {
    this.unitLevelOptionList = await this.unitLevelService
      .GetUnitLevels({ companyId: this.model.companyId })
      .pipe(
        map(pageResult => {
          return pageResult.results.map(unitLvl => {
            return {
              label: unitLvl.name,
              value: unitLvl.unitLevelId,
              level: unitLvl.level,
            };
          });
        }),
      )
      .toPromise();
    this.fields.find(a => a.key === 'unitLevelId').templateOptions.options = this.unitLevelOptionList;
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createUnit();
        break;
      case ComponentMode.UPDATE:
        this.updateUnit();
        break;
    }
  }

  close() {
    this.onClose.emit();
  }


  private createUnit() {
    this.unitService
      .CreateUnit({
        code: this.model.code,
        unitId: this.model.unitId,
        unitDefId: this.model.unitDefId,
        unitLevelId: this.model.unitLevelId,
        name: this.model.name,
        nameEn: this.model.nameEn,
        companyId: this.model.companyId,
        parentId: this.model.parentId,
        locationId: this.model.locationId,
        costCenterId: this.model.costCenterId,
        effectiveDate: this.model.effectiveDate,
        terminateDate: this.model.isNotSpecifyTerminateDate ? '9999-12-31' : this.model.terminateDate,
        unitDefEffectiveDate: this.model.effectiveDate,
        headOfUnitPositionId: this.model.headOfUnitList[0]?.positionId,
        bpCode: this.model.bpCode,
        branchId: this.model.branchId,
      })
      .subscribe(
        () => {
          this.noti.saveCompleted();
          this.onSaved.emit(this.model);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  private updateUnit() {
    this.notification.confirm(
      'แก้ไขหน่วยงาน',
      'หากมีการเปลี่ยนผู้บังคับบัญชา พนักงานที่ดำรงตำแหน่งในหน่วยงานนี้ที่เลือกประเภทผู้บังคับบัญชาแบบ "ตามผู้บังคับบัญชาที่กำหนด" จะถูกเปลี่ยนทั้งหมด',
    ).then((v) => {
      if (v.isConfirmed) {
        this.unitService
          .UpdateUnit({
            unitId: this.model.unitId,
            unitDefId: this.model.unitDefId,
            unitLevelId: this.model.unitLevelId,
            code: this.model.code,
            name: this.model.name,
            nameEn: this.model.nameEn,
            companyId: this.model.companyId,
            parentId: this.model.parentId,
            locationId: this.model.locationId,
            costCenterId: this.model.costCenterId,
            status: this.model.status,
            createdDate: this.model.createdDate,
            createdBy: this.model.createdBy,
            effectiveDate: this.model.effectiveDate,
            terminateDate: this.model.isNotSpecifyTerminateDate ? '9999-12-31' : this.model.terminateDate,
            unitDefEffectiveDate: this.model.effectiveDate,
            headOfUnitPositionId: this.model.headOfUnitList[0]?.positionId,
            bpCode: this.model.bpCode,
            branchId: this.model.branchId,
          })
          .subscribe(
            () => {
              this.noti.saveCompleted();
              this.onSaved.emit(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
      }
    });
  }
}
