import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { EGender } from 'app/api/models';
import { MasterDataRepo } from 'app/repo/master-data.repo';

export interface EmployeeChild {
  genderId: EGender;
  titleTh: string;
  firstnameTh: string;
  lastnameTh: string;
  birthday: string;
  taxId: string;
  isStudent: boolean;
}

export interface EmployeeChildCreateModel {
  employeeChildren: EmployeeChild[];
}

@Component({
  selector: 'app-employee-create-form-child',
  templateUrl: './employee-create-form-child.component.html',
  styleUrls: ['./employee-create-form-child.component.css'],
})
export class EmployeeCreateFormChildComponent implements OnInit {
  @Input('defaultModel')
  set defaultModel(val) {
    if (val) {
      Object.assign(this.model, val);
    }
  }

  isShowErr = true;

  form = new FormGroup({});
  model: EmployeeChildCreateModel = {
    employeeChildren: [],
  };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'employeeChildren',
      type: 'repeat',
      templateOptions: {
        addText: '+ เพิ่มบุตร',
        bigTitle: 'รายละเอียดข้อมูลบุตร',
        littleTitle: 'รายละเอียดบุตรคนที่ ',
        deleteTitle: 'ลบข้อมูลบุตร',
      },
      fieldArray: {
        key: 'employeeChildren',
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            className: 'col-8 col-offset-1',
            type: 'radio',
            key: 'genderId',
            wrappers: ['inline-label'],
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              label: 'sex',
              placeholder: '@blank',

              labelClass: 'col-1',
              inputClass: 'col',
              options: this.masterDataRepo.getGenderOptionList(),
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            className: 'col-4',
            type: 'filter-dropdown',
            key: 'titleTh',
            wrappers: ['inline-label'],
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'prefix_th',

              labelClass: 'col-5',
              inputClass: 'col-7',
              options: this.masterDataRepo.getTitleThOptionList(),
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'firstnameTh',
            wrappers: ['inline-label'],
            className: 'col-4',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'name_th',

              labelClass: 'col-5',
              inputClass: 'col-7',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'lastnameTh',
            wrappers: ['inline-label'],
            className: 'col-4',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'surname_th',

              labelClass: 'col-5',
              inputClass: 'col-7',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'calendar',
            key: 'birthday',
            wrappers: ['inline-label'],
            className: 'col-4',
            templateOptions: {
              translate: true,
              label: 'd/m/y',
              placeholder: 'd/m/y',

              labelClass: 'col-5',
              inputClass: 'col-7',
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'primeng-input',
            key: 'taxId',
            wrappers: ['inline-label'],
            className: 'col-4',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'card_number',
              labelClass: 'col-5',
              inputClass: 'col-7',
            },
            validators: {
              validation: ['thaiID'],
            },
            validation: {
              show: this.isShowErr,
            },
          },
          {
            type: 'radio',
            key: 'isStudent',
            wrappers: ['inline-label'],
            className: 'col-4',
            templateOptions: {
              translate: true,
              attributes: {
                style: 'width: 100%',
              },
              placeholder: '@blank',
              label: 'studying',

              labelClass: 'col-5',
              inputClass: 'col-7',
              options: [
                { label: 'ใช่', value: true },
                { label: 'ไม่ใช่', value: false },
              ],
            },
            validation: {
              show: this.isShowErr,
            },
          },
        ],
      },
    },
  ];

  constructor(private masterDataRepo: MasterDataRepo) {}

  ngOnInit(): void {}

  submit() {
    alert(JSON.stringify(this.model));
  }
}
