/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeContactModel } from '../models/employee-contact-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeContactService extends __BaseService {
  static readonly GetEmployeeContactListPath = '/api/EmployeeContact';
  static readonly CreateEmployeeContactPath = '/api/EmployeeContact';
  static readonly GetEmployeeContactPath = '/api/EmployeeContact/{EmployeeContactId}';
  static readonly UpdateEmployeeContactPath = '/api/EmployeeContact/{EmployeeContactId}';
  static readonly DeleteEmployeeContactPath = '/api/EmployeeContact/{EmployeeContactId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeContactListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeContactModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeContact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeContactModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeContactList(): __Observable<Array<EmployeeContactModel>> {
    return this.GetEmployeeContactListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeContactModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeContactResponse(body?: EmployeeContactModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeContact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeContact(body?: EmployeeContactModel): __Observable<string> {
    return this.CreateEmployeeContactResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeContactId undefined
   * @return Success
   */
  GetEmployeeContactResponse(EmployeeContactId: number): __Observable<__StrictHttpResponse<EmployeeContactModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeContact/${encodeURIComponent(String(EmployeeContactId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeContactModel>;
      })
    );
  }
  /**
   * @param EmployeeContactId undefined
   * @return Success
   */
  GetEmployeeContact(EmployeeContactId: number): __Observable<EmployeeContactModel> {
    return this.GetEmployeeContactResponse(EmployeeContactId).pipe(
      __map(_r => _r.body as EmployeeContactModel)
    );
  }

  /**
   * @param params The `EmployeeContactService.UpdateEmployeeContactParams` containing the following parameters:
   *
   * - `EmployeeContactId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeContactResponse(params: EmployeeContactService.UpdateEmployeeContactParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeContact/${encodeURIComponent(String(params.EmployeeContactId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeContactService.UpdateEmployeeContactParams` containing the following parameters:
   *
   * - `EmployeeContactId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeContact(params: EmployeeContactService.UpdateEmployeeContactParams): __Observable<string> {
    return this.UpdateEmployeeContactResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeContactId undefined
   * @return Success
   */
  DeleteEmployeeContactResponse(EmployeeContactId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeContact/${encodeURIComponent(String(EmployeeContactId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeContactId undefined
   * @return Success
   */
  DeleteEmployeeContact(EmployeeContactId: number): __Observable<string> {
    return this.DeleteEmployeeContactResponse(EmployeeContactId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeContactService {

  /**
   * Parameters for UpdateEmployeeContact
   */
  export interface UpdateEmployeeContactParams {
    EmployeeContactId: number;
    body?: EmployeeContactModel;
  }
}

export { EmployeeContactService }
