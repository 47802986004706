/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class StarkEmployeeService extends __BaseService {
  static readonly CreateEmployeesPath = '/api/StarkEmployee/run/BPlus/Employees';
  static readonly PromotePath = '/api/StarkEmployee/run/BPlus/Promote';
  static readonly ResignPath = '/api/StarkEmployee/run/BPlus/Resign';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param bPlus_DB undefined
   * @return Success
   */
  CreateEmployeesResponse(bPlusDB?: any): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (bPlusDB != null) __params = __params.set('bPlus_DB', bPlusDB.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkEmployee/run/BPlus/Employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param bPlus_DB undefined
   * @return Success
   */
  CreateEmployees(bPlusDB?: any): __Observable<string> {
    return this.CreateEmployeesResponse(bPlusDB).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param bPlus_DB undefined
   * @return Success
   */
  PromoteResponse(bPlusDB?: any): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (bPlusDB != null) __params = __params.set('bPlus_DB', bPlusDB.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkEmployee/run/BPlus/Promote`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param bPlus_DB undefined
   * @return Success
   */
  Promote(bPlusDB?: any): __Observable<string> {
    return this.PromoteResponse(bPlusDB).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param bPlus_DB undefined
   * @return Success
   */
  ResignResponse(bPlusDB?: any): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (bPlusDB != null) __params = __params.set('bPlus_DB', bPlusDB.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/StarkEmployee/run/BPlus/Resign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param bPlus_DB undefined
   * @return Success
   */
  Resign(bPlusDB?: any): __Observable<string> {
    return this.ResignResponse(bPlusDB).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module StarkEmployeeService {
}

export { StarkEmployeeService }
