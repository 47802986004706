import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeEducationModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeEducationService, MasterDataService } from 'app/api/services';
import { HttpErrorResponse } from '@angular/common/http';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeEducationModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-modal-employee-education',
  templateUrl: './modal-employee-education.component.html',
  styleUrls: ['./modal-employee-education.component.css'],
})
export class ModalEmployeeEducationComponent implements OnInit, OnDestroy {
  model: EmployeeEducationModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          type: 'filter-dropdown',
          key: 'educationLevelId',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            attributes: {
              style: 'width: 100%;',
            },

            translate: true,

            placeholder: '@blank',
            label: 'education_level',
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
            // options: this.masterDataService.GetEducationLevels().pipe(
            //   map(educationLevels => {
            //     return educationLevels.map(educationLevel => {
            //       return {
            //         value: educationLevel.educationLevelId,
            //         label: educationLevel.name,
            //       };
            //     });
            //   }),
            // ),
          },
        },
        {
          type: 'primeng-input',
          key: 'locationName',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            translate: true,
            placeholder: '@blank',
            label: 't_location',
            maxLength: 256,
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          type: 'primeng-input',
          key: 'institute',
          wrappers: ['inline-label'],
          className: 'col-12',
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            translate: true,
            placeholder: '@blank',
            label: 'educational_name',
            maxLength: 256,
            required: true,
            labelClass: 'col-3',
            inputClass: 'col-8',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          type: 'primeng-input',
          key: 'educationalBackground',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            translate: true,
            placeholder: '@blank',
            label: 'qualifications_earned',
            maxLength: 256,
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
        },
        {
          type: 'primeng-input',
          key: 'fieldName',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            translate: true,
            placeholder: '@blank',
            maxLength: 256,
            label: 'branch',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-6',
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          type: 'primeng-input',
          key: 'startYear',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            translate: true,
            placeholder: '@blank',
            label: 'since_year',
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
            type: 'number',
            min: 0,
          },
        },
        {
          type: 'primeng-input',
          key: 'endYear',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            translate: true,
            placeholder: '@blank',
            label: 'to_year',
            required: true,
            labelClass: 'col-4',
            inputClass: 'col-6',
            type: 'number',
            min: 0,
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          type: 'primeng-input',
          key: 'gpa',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            attributes: {
              style: 'width: 100%',
            },
            translate: true,
            placeholder: '@blank',
            label: 'average_score',
            maxLength: 32,
            required: true,
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
        },
      ],
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeEducationService: EmployeeEducationService,
    private masterDataService: MasterDataService,
  ) { }

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const employeeEducationId = this.getEmployeeEducationId();

    if (employeeEducationId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    this.fields[0].fieldGroup.find(a => a.key === 'educationLevelId').templateOptions.options =
      await this.masterDataService
        .GetEducationLevels()
        .pipe(
          map(educationLevels => {
            return educationLevels.map(educationLevel => {
              return {
                value: educationLevel.educationLevelId,
                label: educationLevel.name,
              };
            });
          }),
        )
        .toPromise();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    this.model = await this.employeeEducationService
      .GetEmployeeEducationById(this.getEmployeeEducationId())
      .toPromise();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createEmployeeEducation()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateEmployeeEducation()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createEmployeeEducation() {
    console.log(this.model);
    return this.employeeEducationService.CreateEmployeeEducation({
      employeeId: this.model.employeeId,
      educationLevelId: this.model.educationLevelId,
      locationName: this.model.locationName,
      institute: this.model.institute,
      educationalBackground: this.model.educationalBackground,
      fieldName: this.model.fieldName,
      startYear: this.model.startYear,
      endYear: this.model.endYear,
      gpa: this.model.gpa,
    });
  }

  private updateEmployeeEducation() {
    return this.employeeEducationService.UpdateEmployeeEducation({
      ...this.model,
      educationLevelId: this.model.educationLevelId,
      locationName: this.model.locationName,
      institute: this.model.institute,
      educationalBackground: this.model.educationalBackground,
      fieldName: this.model.fieldName,
      startYear: this.model.startYear,
      endYear: this.model.endYear,
      gpa: this.model.gpa,
    });
  }

  getEmployeeEducationId() {
    return this.config.data?.id;
  }
}
