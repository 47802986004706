import { Component, EventEmitter } from '@angular/core';
import { FieldArrayType, FieldWrapper, FieldType, FormlyField } from '@ngx-formly/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DirectBossOfPositionModel, PositionModel } from 'app/api/models';
import { ModalPositionSearchComponent } from '../../modals/core/modal-position-search/modal-position-search.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-position',
  templateUrl: './select-position.component.html',
  styleUrls: ['./select-position.component.css'],
  providers: [DialogService],
})
export class SelectPositionComponent extends FieldArrayType {
  ref: DynamicDialogRef;
  companyId: number;
  unitId: number;
  updateUnitEvent = new EventEmitter<number>();

  constructor(private dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.updateCompany();

    if (this.to.unitIdChange instanceof Subject || this.to?.unitIdChange instanceof EventEmitter) {
      this.to.unitIdChange.subscribe(unitId => (this.unitId = unitId));
    }
  }

  updateCompany() {
    if (this.to?.companyChange instanceof EventEmitter || this.to?.companyChange instanceof Subject) {
      this.to.companyChange.subscribe(companyId => {
        this.to.isWaitCompanyId = false;
        this.companyId = companyId;
        this.updateUnitEvent.emit();
      });
    }
  }

  showPositionSearchModal() {
    this.ref = this.dialogService.open(ModalPositionSearchComponent, {
      header: 'ค้นหาตำแหน่งงาน',
      width: '70%',
      contentStyle: { overflow: 'auto' },
      data: { companyId: this.companyId, unitId: this.unitId, disabledSelectCompany: this.to.disabledSelectCompany ?? false, },
    });

    this.ref.onClose.subscribe((data: PositionModel) => {
      if (data) {
        if (this.to?.isSingleSelect) {
          if ((this.model as []).length) {
            this.remove(0);
          }

          this.add(undefined, data);
        } else {
          if (!this.isExistInModel(data)) {
            this.add(undefined, data);
          }
        }
      }
    });
  }

  private isExistInModel(position: PositionModel): boolean {
    const idx = this.model.findIndex(pos => {
      if (pos.directBossId) {
        return (pos as DirectBossOfPositionModel).directBossId === position.positionId;
      } else {
        return (pos as PositionModel).positionId === position.positionId;
      }
    });

    return idx !== -1;
  }
}
