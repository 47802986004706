import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeTrainingModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DocumentService, EmployeeTrainingService, MasterDataService } from 'app/api/services';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';


enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface SelectFileParam {
  currentFiles: File[];
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeTrainingModel;
}

@Component({
  selector: 'app-modal-employee-training',
  templateUrl: './modal-employee-training.component.html',
  styleUrls: ['./modal-employee-training.component.scss']
})
export class ModalEmployeeTrainingComponent implements OnInit {

  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeTrainingService: EmployeeTrainingService,
    private masterDataService: MasterDataService,
    private documentService: DocumentService,
  ) {}

  model: EmployeeTrainingModel = {};
  form = new FormGroup({});

  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'courseName',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'หัวข้อการฝึกอบรม',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 300,
      },
    },
    {
      key: 'startDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        required: true,
        translate: true,
        label: 'วันที่เริ่มฝึก',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'endDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        required: true,
        translate: true,
        label: 'วันที่จบการฝึก',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'institute',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'educational',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        maxLength: 256,
      },
    },
    {
      key: 'documentId',
      type: 'upload-file',
      templateOptions: {
        translate: true,
        required: false,
        label: 'attach_file',
        chooseLabel: 'อัพโหลด',
        previewWidth: 150,
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
        onSelect: (selectFileParam: SelectFileParam) => {
          this.documentService
            .CreateDocument({
              description: 'Employee Training',
              file: selectFileParam.currentFiles[0],
            })
            .subscribe(result => {
              this.model.documentId = result.documentId;
            });
        },
        onRemove: (_, model: EmployeeTrainingModel, oldDocumentId: number) => {
          model.documentId = oldDocumentId;
        },
      },
    },
    {
      key: 'remark',
      type: 'textarea',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'หมายเหตุ:',
        labelClass: 'col-4',
        inputClass: 'col-6',
        placeholder: '@blank'
      },
    },
  ];

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  initComponentMode() {
    const employeeTrainingId = this.getEmployeeTrainingId();

    if (employeeTrainingId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    const res = await this.employeeTrainingService
      .GetEmployeeTrainingById(this.getEmployeeTrainingId())
      .toPromise();

    this.model = {
      ...res,
      startDate: (res.startDate),
      endDate: (res.endDate),
    };
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.employeeTrainingService
          .CreateEmployeeTraining(this.model)
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.employeeTrainingService
          .UpdateEmployeeTraining({
            EmployeeTrainingId: this.model.employeeTrainingId,
            body: this.model,
          })
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getEmployeeTrainingId() {
    return this.config.data?.id;
  }
}
