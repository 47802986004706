/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { JobListItemModelPaginationResult } from '../models/job-list-item-model-pagination-result';
import { JobModel } from '../models/job-model';
@Injectable({
  providedIn: 'root',
})
class JobService extends __BaseService {
  static readonly GetJobListItemsPath = '/api/Job';
  static readonly CreateJobPath = '/api/Job';
  static readonly UpdateJobPath = '/api/Job';
  static readonly GetJobByIdPath = '/api/Job/{jobId}';
  static readonly DeleteJobPath = '/api/Job/{jobId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `JobService.GetJobListItemsParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetJobListItemsResponse(params: JobService.GetJobListItemsParams): __Observable<__StrictHttpResponse<JobListItemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Job`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobListItemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `JobService.GetJobListItemsParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetJobListItems(params: JobService.GetJobListItemsParams): __Observable<JobListItemModelPaginationResult> {
    return this.GetJobListItemsResponse(params).pipe(
      __map(_r => _r.body as JobListItemModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateJobResponse(body?: JobModel): __Observable<__StrictHttpResponse<JobModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Job`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobModel>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateJob(body?: JobModel): __Observable<JobModel> {
    return this.CreateJobResponse(body).pipe(
      __map(_r => _r.body as JobModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateJobResponse(body?: JobModel): __Observable<__StrictHttpResponse<JobModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Job`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobModel>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateJob(body?: JobModel): __Observable<JobModel> {
    return this.UpdateJobResponse(body).pipe(
      __map(_r => _r.body as JobModel)
    );
  }

  /**
   * @param jobId undefined
   * @return Success
   */
  GetJobByIdResponse(jobId: number): __Observable<__StrictHttpResponse<JobModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Job/${encodeURIComponent(String(jobId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobModel>;
      })
    );
  }
  /**
   * @param jobId undefined
   * @return Success
   */
  GetJobById(jobId: number): __Observable<JobModel> {
    return this.GetJobByIdResponse(jobId).pipe(
      __map(_r => _r.body as JobModel)
    );
  }

  /**
   * @param jobId undefined
   * @return Success
   */
  DeleteJobResponse(jobId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Job/${encodeURIComponent(String(jobId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param jobId undefined
   * @return Success
   */
  DeleteJob(jobId: number): __Observable<string> {
    return this.DeleteJobResponse(jobId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module JobService {

  /**
   * Parameters for GetJobListItems
   */
  export interface GetJobListItemsParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { JobService }
