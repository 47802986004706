/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NewEmployeeModel } from '../models/new-employee-model';
import { UserDataDetailModel } from '../models/user-data-detail-model';
import { CreateEmployeeModel } from '../models/create-employee-model';
@Injectable({
  providedIn: 'root',
})
class NewEmployeeService extends __BaseService {
  static readonly GetNewEmployeeRequestPath = '/api/NewEmployee';
  static readonly CreateNewEmployeeRequestPath = '/api/NewEmployee';
  static readonly CreateNewEmployeeFromRequestPath = '/api/NewEmployee/requests/{requestId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param requestId undefined
   * @return Success
   */
  GetNewEmployeeRequestResponse(requestId?: number): __Observable<__StrictHttpResponse<NewEmployeeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (requestId != null) __params = __params.set('requestId', requestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/NewEmployee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NewEmployeeModel>;
      })
    );
  }
  /**
   * @param requestId undefined
   * @return Success
   */
  GetNewEmployeeRequest(requestId?: number): __Observable<NewEmployeeModel> {
    return this.GetNewEmployeeRequestResponse(requestId).pipe(
      __map(_r => _r.body as NewEmployeeModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateNewEmployeeRequestResponse(body?: UserDataDetailModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/NewEmployee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateNewEmployeeRequest(body?: UserDataDetailModel): __Observable<string> {
    return this.CreateNewEmployeeRequestResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `NewEmployeeService.CreateNewEmployeeFromRequestParams` containing the following parameters:
   *
   * - `requestId`:
   *
   * - `body`:
   *
   * @return Success
   */
  CreateNewEmployeeFromRequestResponse(params: NewEmployeeService.CreateNewEmployeeFromRequestParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/NewEmployee/requests/${encodeURIComponent(String(params.requestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `NewEmployeeService.CreateNewEmployeeFromRequestParams` containing the following parameters:
   *
   * - `requestId`:
   *
   * - `body`:
   *
   * @return Success
   */
  CreateNewEmployeeFromRequest(params: NewEmployeeService.CreateNewEmployeeFromRequestParams): __Observable<string> {
    return this.CreateNewEmployeeFromRequestResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module NewEmployeeService {

  /**
   * Parameters for CreateNewEmployeeFromRequest
   */
  export interface CreateNewEmployeeFromRequestParams {
    requestId: number;
    body?: CreateEmployeeModel;
  }
}

export { NewEmployeeService }
