/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AllowanceTypeModelPaginationResult } from '../models/allowance-type-model-pagination-result';
import { AllowanceTypeModel } from '../models/allowance-type-model';
@Injectable({
  providedIn: 'root',
})
class AllowanceTypeService extends __BaseService {
  static readonly GetAllowanceTypeListPath = '/api/AllowanceType';
  static readonly CreateAllowanceTypePath = '/api/AllowanceType';
  static readonly UpdateAllowanceTypePath = '/api/AllowanceType';
  static readonly GetShowAllowanceTypeListPath = '/api/AllowanceType/show';
  static readonly GetAllowanceTypeByIdPath = '/api/AllowanceType/{allowanceTypeId}';
  static readonly DeleteAllowanceTypePath = '/api/AllowanceType/{allowanceTypeId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AllowanceTypeService.GetAllowanceTypeListParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetAllowanceTypeListResponse(params: AllowanceTypeService.GetAllowanceTypeListParams): __Observable<__StrictHttpResponse<AllowanceTypeModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllowanceType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllowanceTypeModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `AllowanceTypeService.GetAllowanceTypeListParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetAllowanceTypeList(params: AllowanceTypeService.GetAllowanceTypeListParams): __Observable<AllowanceTypeModelPaginationResult> {
    return this.GetAllowanceTypeListResponse(params).pipe(
      __map(_r => _r.body as AllowanceTypeModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateAllowanceTypeResponse(body?: AllowanceTypeModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllowanceType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateAllowanceType(body?: AllowanceTypeModel): __Observable<string> {
    return this.CreateAllowanceTypeResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateAllowanceTypeResponse(body?: AllowanceTypeModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/AllowanceType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateAllowanceType(body?: AllowanceTypeModel): __Observable<string> {
    return this.UpdateAllowanceTypeResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetShowAllowanceTypeListResponse(): __Observable<__StrictHttpResponse<Array<AllowanceTypeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllowanceType/show`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AllowanceTypeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetShowAllowanceTypeList(): __Observable<Array<AllowanceTypeModel>> {
    return this.GetShowAllowanceTypeListResponse().pipe(
      __map(_r => _r.body as Array<AllowanceTypeModel>)
    );
  }

  /**
   * @param allowanceTypeId undefined
   * @return Success
   */
  GetAllowanceTypeByIdResponse(allowanceTypeId: string): __Observable<__StrictHttpResponse<AllowanceTypeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllowanceType/${encodeURIComponent(String(allowanceTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllowanceTypeModel>;
      })
    );
  }
  /**
   * @param allowanceTypeId undefined
   * @return Success
   */
  GetAllowanceTypeById(allowanceTypeId: string): __Observable<AllowanceTypeModel> {
    return this.GetAllowanceTypeByIdResponse(allowanceTypeId).pipe(
      __map(_r => _r.body as AllowanceTypeModel)
    );
  }

  /**
   * @param allowanceTypeId undefined
   * @return Success
   */
  DeleteAllowanceTypeResponse(allowanceTypeId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/AllowanceType/${encodeURIComponent(String(allowanceTypeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param allowanceTypeId undefined
   * @return Success
   */
  DeleteAllowanceType(allowanceTypeId: string): __Observable<string> {
    return this.DeleteAllowanceTypeResponse(allowanceTypeId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module AllowanceTypeService {

  /**
   * Parameters for GetAllowanceTypeList
   */
  export interface GetAllowanceTypeListParams {
    Status?: any;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { AllowanceTypeService }
