import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { MasterDataRepo } from 'app/repo/master-data.repo';

export interface EmployeeEmergencyContactCreateModel {
  titleTh?: 'นาย' | 'นาง';
  firstnameTh?: string;
  middlenameTh?: string;
  lastnameTh?: string;
  birthday?: string;
  emergencyContactRelation?: string;
  phone?: string;
}

@Component({
  selector: 'app-employee-create-form-emergency',
  templateUrl: './employee-create-form-emergency.component.html',
  styleUrls: ['./employee-create-form-emergency.component.css'],
})
export class EmployeeCreateFormEmergencyComponent implements OnInit {
  @Input('defaultModel')
  set defaultModel(val) {
    if (val) {
      Object.assign(this.model, val);
    }
  }

  // เอาไว้เช็คเงื่อนไข login หรือไม่ login
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  isShowErr = true;

  form = new FormGroup({});
  model: EmployeeEmergencyContactCreateModel = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'titleTh',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-3',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'name_surname_th',
            placeholder: '@blank',
            labelClass: 'col-6',
            inputClass: 'col-6',
            options: this.masterDataRepo.getTitleThOptionList(),
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'firstnameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-3',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'not',
            placeholder: 'name_th',
            labelClass: 'col',
            inputClass: 'col-12',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'middlenameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 100%;',
            },
            label: 'not',
            placeholder: 'middle_name_th',
            labelClass: 'col',
            inputClass: 'col-12',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'lastnameTh',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-2',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 125%;',
            },
            label: 'not',
            placeholder: 'surname_th',
            labelClass: 'col',
            inputClass: 'col-12',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'birthday',
          type: 'calendar',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'd/m/y',
            placeholder: 'd/m/y',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'emergencyContactRelation',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'relationship',
            placeholder: '@blank',
            options: this.masterDataRepo.getRelativeTypeOptionList(),
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
        },
        {
          key: 'phone',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-6',
          templateOptions: {
            translate: true,
            label: 'phone',
            placeholder: '@blank',
            labelClass: 'col-3',
            inputClass: 'col-6',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
        },
      ],
    },
  ];

  constructor(private masterDataRepo: MasterDataRepo) {}

  ngOnInit(): void {}
}
