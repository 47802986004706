<div class="p-fluid">
  <form [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  </form>
  <hr style="margin-top: 10px; margin-bottom: 5px;">
  <div class="p-dialog-footer" style="text-align:center">
    <button type="button" pButton class="p-button-primary"label="{{'record' | translate}}" [disabled]="!form.valid" (click)="submit(model)"></button>
    <button type="button" pButton class="p-button-secondary" label="{{'cancel' | translate}}"(click)="close()" ></button>
  </div>
</div>
