import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LayoutDefaultComponent } from './layout/layout-default/layout-default.component';
import { AuthGuard } from './guards/auth.guard';
import { AppForgetComponent } from './pages/shared/account/forget-password/app.forget.component';
import { AppLoginComponent } from './pages/shared/account/login/app.login.component';
import { AppResetComponent } from './pages/shared/account/reset-password/app.reset.component';
import { AppAccessdeniedComponent } from './pages/shared/errors/accessdenied/app.accessdenied.component';
import { AppErrorComponent } from './pages/shared/errors/error/app.error.component';
import { AppNotfoundComponent } from './pages/shared/errors/notfound/app.notfound.component';
import { NeedResetPasswordGuard } from './guards/need-reset-password.guard';
import { PagePermissionGuard } from './guards/page-permission.guard';
import { NewEmployeeComponent } from 'pages/shared/new-employee/new-employee.component';
import { PrivacyPolicyComponent } from 'pages/shared/privacy-policy/privacy-policy.component';
import { IosRedeemedCodeComponent } from 'pages/shared/ios-redeemed-code/ios-redeemed-code.component';

export const routes: Routes = [
  {
    path: 'account',
    children: [
      { path: 'login', component: AppLoginComponent },
      { path: 'forget', component: AppForgetComponent },
      { path: 'reset', component: AppResetComponent },
    ],
  },
  {
    path: 'signup',
    children: [{ path: 'new-employee', component: NewEmployeeComponent }],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    pathMatch: 'full'
  },
  {
    path: 'ios-redeemed-code',
    component: IosRedeemedCodeComponent,
    pathMatch: 'full'
  },
  {
    path: 'system',
    loadChildren: () => import('./pages/system/system.module').then(m => m.SystemModule),
  },
  {
    path: 'errors',
    children: [
      { path: 'error', component: AppErrorComponent },
      { path: 'accessdenied', component: AppAccessdeniedComponent },
      { path: 'notfound', component: AppNotfoundComponent },
    ],
  },
  {
    path: 'notification',
    component: LayoutDefaultComponent,
    canActivate: [AuthGuard, NeedResetPasswordGuard],
    //canActivateChild: [PagePermissionGuard],
    loadChildren: () => import('./pages/shared/notification/notification.module').then(m => m.NotificationModule),
  },
  {
    path: 'admin',
    component: LayoutDefaultComponent,
    canActivate: [AuthGuard, NeedResetPasswordGuard],
    //canActivateChild: [PagePermissionGuard],
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'employee',
    component: LayoutDefaultComponent,
    canActivate: [AuthGuard, NeedResetPasswordGuard],
    //canActivateChild: [PagePermissionGuard],
    loadChildren: () => import('./pages/employee/employee.module').then(m => m.EmployeeModule),
  },
  {
    path: 'platform',
    loadChildren: () => import('./pages/platform/Platform.module').then(m => m.PlatformModule)
  },
  { path: '', redirectTo: '/account/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/errors/notfound' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
