<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>{{ 'education' | translate }}</h1>
        <hr />
        <div class="card">
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>{{ 'education' | translate }}</h1>
            </div>
            <div class="col"></div>
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                label="{{ 'add_data' | translate }}"
                (click)="showCreateEmployeeEducationDialog()"
              ></button>
            </div>
          </div>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="educationList">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'education_level' | translate }}</th>
                <th>{{ 'educational_name' | translate }}</th>
                <th>{{ 'field_study' | translate }}</th>
                <th>{{ 'since_year' | translate }} - {{ 'to_year' | translate }}</th>
                <th *ngIf="isEditable" style="width: 100px">{{ 'action' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.educationLevelName }}</td>
                <td>{{ rowData.institute }}</td>
                <td>{{ rowData.fieldName }}</td>
                <td>{{ rowData.startYear }}-{{ rowData.endYear }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i class="fa fa-pencil-alt" (click)="showUpdateEmployeeEducationDialog(rowData)"></i>
                    </div>
                    <div class="col-fixed">
                      <i class="fa fa-trash" (click)="showDeleteEmployeeEducationDialog(rowData)"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" class="emptymessage">{{ 'no_data' | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
