<div *ngFor="let afield of field.fieldGroup; let i = index" class="grid align-center">
  <div [class]="to.labelClass" style="text-align: right">
    <label *ngIf="i === 0">{{ to?.label }}</label>
  </div>
  <formly-field [class]="to.inputClass" style="padding-bottom: 0" [field]="afield"></formly-field>
  <div class="col icon-col">
    <i class="fa fa-trash" *ngIf="to.isEditable" (click)="remove(i)"></i>
  </div>
</div>
<div class="grid align-center">
  <div [class]="to.labelClass" style="text-align: right">
    <label *ngIf="field.fieldGroup.length === 0">{{ to?.label }} <span *ngIf="to.required">*</span></label>
  </div>
  <div class="col-fixed">
    <button
      pButton
      type="button"
      [disabled]="!to.isEditable"
      (click)="showEmployeeSearchModal()"
      label="{{ to?.addSectionLabel }}"
    ></button>
  </div>
</div>
