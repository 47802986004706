import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPayElementComponent } from './modal-pay-element/modal-pay-element.component';
import { ModalAllowanceComponent } from './modal-allowance/modal-allowance.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { SharedModule } from '../../shared/shared.module';
import { ModalAllowanceTypeComponent } from './modal-allowance-type/modal-allowance-type.component';
import { ModalImportRevenueComponent } from './modal-import-revenue/modal-import-revenue.component';
import { ModalPayrollPeriodEditComponent } from './modal-payroll-period-edit/modal-payroll-period-edit.component';

const allModals = [
  ModalAllowanceComponent,
  ModalPayElementComponent,
  ModalAllowanceTypeComponent,
  ModalImportRevenueComponent,
  ModalPayrollPeriodEditComponent
];

@NgModule({
    declarations: [...allModals],
    imports: [CommonModule, DynamicDialogModule, SharedModule],
    exports: [...allModals]
})
export class PayrollModule {}
