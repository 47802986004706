import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ShiftDetailModel } from 'app/api/models';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { ShiftService } from 'app/api/services';
import * as moment from 'moment';

interface ViewShiftDetailModel extends ShiftDetailModel {
  dayname?: string;
  exampleDate?: string;
  isnameholiday?: string;
}

@Component({
  selector: 'app-modal-view-shiftdetail',
  templateUrl: './modal-view-shiftdetail.component.html',
  styleUrls: ['./modal-view-shiftdetail.component.scss']
})

export class ModalViewShiftdetailComponent implements OnInit {
  loading = true;
  shiftdetailList: ViewShiftDetailModel[] = [];

  constructor(
    private ref: DynamicDialogRef,
    private shiftService: ShiftService,
    private config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    console.log(this.config.data?.id)
    this.shiftService.GetShiftById(this.config.data?.id,
      ).subscribe(
      ListResult => {
        this.shiftdetailList = ListResult.shiftDetails;
        this.UpdateFomatData();
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.shiftdetailList = [];
        this.loading = false;
      },
    );   
  }

  UpdateFomatData() {
      for (let item of this?.shiftdetailList) {
        let exampleDateString = null;
        let dayname = "";
        let exampleDate = new Date();
        
        exampleDate.setDate(exampleDate.getDate() + item.order);
        exampleDateString = `${exampleDate.getDate()}/${exampleDate.getMonth() + 1}/${exampleDate.getFullYear()}`;
        dayname = exampleDate.toLocaleString('th-TH', {weekday: 'long'});
        
        item.order = item.order + 1
        item.dayname = dayname;
        item.exampleDate = exampleDateString;
        item.isnameholiday = item.isHoliday == false ? 'วันทำงาน' : 'วันหยุด';
        item.startTimeDiff = item.startTimeDiff.slice(0,item.startTimeDiff.lastIndexOf(":"));
        item.endTimeDiff = item.endTimeDiff.slice(0,item.endTimeDiff.lastIndexOf(":"));
        item.breakStartDiff = item.breakStartDiff.slice(0,item.breakStartDiff.lastIndexOf(":"));
        item.breakEndDiff = item.breakEndDiff.slice(0,item.breakEndDiff.lastIndexOf(":"));
      }
    
  };

  close() {
    this.ref.close();
  }

  getShiftId() {
    return this.config.data?.id;
  }

}
