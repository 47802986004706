/* tslint:disable */
type EBenefitQuotaSubType =
  'SickLeave' |
  'PrivateLeave' |
  'Vacation' |
  'Ordination' |
  'Maternity';
module EBenefitQuotaSubType {
  export const SICK_LEAVE: EBenefitQuotaSubType = 'SickLeave';
  export const PRIVATE_LEAVE: EBenefitQuotaSubType = 'PrivateLeave';
  export const VACATION: EBenefitQuotaSubType = 'Vacation';
  export const ORDINATION: EBenefitQuotaSubType = 'Ordination';
  export const MATERNITY: EBenefitQuotaSubType = 'Maternity';
  export function values(): EBenefitQuotaSubType[] {
    return [
      SICK_LEAVE,
      PRIVATE_LEAVE,
      VACATION,
      ORDINATION,
      MATERNITY
    ];
  }
}

export { EBenefitQuotaSubType }