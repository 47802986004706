/* tslint:disable */
type EProbationEvaluationFormStep =
  'AwaitEval1' |
  'Evaluation1' |
  'AwaitEval2' |
  'Evaluation2' |
  'Finish';
module EProbationEvaluationFormStep {
  export const AWAIT_EVAL_1: EProbationEvaluationFormStep = 'AwaitEval1';
  export const EVALUATION_1: EProbationEvaluationFormStep = 'Evaluation1';
  export const AWAIT_EVAL_2: EProbationEvaluationFormStep = 'AwaitEval2';
  export const EVALUATION_2: EProbationEvaluationFormStep = 'Evaluation2';
  export const FINISH: EProbationEvaluationFormStep = 'Finish';
  export function values(): EProbationEvaluationFormStep[] {
    return [
      AWAIT_EVAL_1,
      EVALUATION_1,
      AWAIT_EVAL_2,
      EVALUATION_2,
      FINISH
    ];
  }
}

export { EProbationEvaluationFormStep }