import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasterDataRepo } from './master-data.repo';
import { OptionListRepo } from './option-list.repo';

@NgModule({
  providers: [OptionListRepo, MasterDataRepo],
  imports: [CommonModule],
})
export class RepoModule {}
