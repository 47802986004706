<p-multiSelect
  [formControl]="$any(formControl)"
  [filter]="to.filter || false"
  [options]="to.options | formlySelectOptions: field | async"
  [formlyAttributes]="field"
  (onChange)="to.change && to.change(field, $event)"
  [readonly]="to.readonly"
  [maxSelectedLabels]="to.maxSelectedLabels || 3"
  [defaultLabel]="to.defaultLabel || 'เลือก'"
  [appendTo]="'body'"
></p-multiSelect>
