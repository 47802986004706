/* tslint:disable */
type ETimeAttendanceStatus =
  'NA' |
  'Normal' |
  'Absent' |
  'Late' |
  'LeftEarly' |
  'LateAndLeftEarly' |
  'Invalid' |
  'Weekend' |
  'QuotaLeave' |
  'PublicHolidays';
module ETimeAttendanceStatus {
  export const NA: ETimeAttendanceStatus = 'NA';
  export const NORMAL: ETimeAttendanceStatus = 'Normal';
  export const ABSENT: ETimeAttendanceStatus = 'Absent';
  export const LATE: ETimeAttendanceStatus = 'Late';
  export const LEFT_EARLY: ETimeAttendanceStatus = 'LeftEarly';
  export const LATE_AND_LEFT_EARLY: ETimeAttendanceStatus = 'LateAndLeftEarly';
  export const INVALID: ETimeAttendanceStatus = 'Invalid';
  export const WEEKEND: ETimeAttendanceStatus = 'Weekend';
  export const QUOTA_LEAVE: ETimeAttendanceStatus = 'QuotaLeave';
  export const PUBLIC_HOLIDAYS: ETimeAttendanceStatus = 'PublicHolidays';
  export function values(): ETimeAttendanceStatus[] {
    return [
      NA,
      NORMAL,
      ABSENT,
      LATE,
      LEFT_EARLY,
      LATE_AND_LEFT_EARLY,
      INVALID,
      WEEKEND,
      QUOTA_LEAVE,
      PUBLIC_HOLIDAYS
    ];
  }
}

export { ETimeAttendanceStatus }