import { style } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { CreateEmployeeModel, EmployeeModel } from '../../../../../../api/models';


@Component({
  selector: 'app-employee-create-form-other-info',
  templateUrl: './employee-create-form-other-info.component.html',
  styleUrls: ['./employee-create-form-other-info.component.css'],
})
export class EmployeeCreateFormOtherInfoComponent implements OnInit {
  @Input('defaultModel')
  set defaultModel(val) {
    if (val) {
      Object.assign(this.model, val);
    }
  }

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  isShowErr = true;

  form = new FormGroup({});
  model: CreateEmployeeModel = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    // {
    //   template: `<h1>ข้อมูลอื่นๆ</h1>`,
    // },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isDisabled',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          templateOptions: {
            translate: true,
            label: 'question1',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6 p-text-left',
            inputClass: 'col-6',
            options: [
              {
                label: 'มี',
                value: true,
              },
              {
                label: 'ไม่มี',
                value: false,
              },
            ],
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        {
          key: 'isDisabledDescription',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          templateOptions: {
            translate: true,
            label: 'specify',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6',
            inputClass: 'col-5',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.isDisabled',
        },
        // Line 2
        {
          key: 'everSeriousIllness',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          templateOptions: {
            translate: true,
            label: 'question2',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6 p-text-left',
            inputClass: 'col-6',
            options: [
              {
                label: 'เคย',
                value: true,
              },
              {
                label: 'ไม่เคย',
                value: false,
              },
            ],
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        {
          key: 'everSeriousIllnessDescription',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          templateOptions: {
            translate: true,
            label: 'specify',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6',
            inputClass: 'col-5',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everSeriousIllness',
        },
        // Line 3
        {
          key: 'generalHealhCondition',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          templateOptions: {
            translate: true,
            label: 'question3',
            placeholder: '@blank',
            description: 'Description',
            required: false,
            labelClass: 'col-6 p-text-left',
            inputClass: 'col-6',
            options: [
              {
                label: 'ดีเลิศ',
                value: 'ดีเลิศ',
              },
              {
                label: 'ดี',
                value: 'ดี',
              },
              {
                label: 'พอใช้',
                value: 'พอใช้',
              },
              {
                label: 'ไม่ดี',
                value: 'ไม่ดี',
              },
            ],
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        // Line 4
        {
          key: 'everPenalized',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          templateOptions: {
            translate: true,
            label: 'question4',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6 p-text-left',
            inputClass: 'col-6',
            options: [
              {
                label: 'เคย',
                value: true,
              },
              {
                label: 'ไม่เคย',
                value: false,
              },
            ],
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        {
          key: 'everPenalizedDescription',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          templateOptions: {
            translate: true,
            label: 'fault_base',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6',
            inputClass: 'col-5',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everPenalized',
        },
        // Line 4 part 2
        {
          key: 'everPenalizedFinished',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          templateOptions: {
            translate: true,
            label: 'specify',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6',
            inputClass: 'col-6',
            options: [
              {
                label: 'ถึงที่สุด',
                value: true,
              },
              {
                label: 'ยังไม่ถึงที่สุด',
                value: false,
              },
            ],
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
          hideExpression: '!model.everPenalized',
        },
        {
          key: 'everPenalizedJailYear',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-3 col-offset-6',
          templateOptions: {
            translate: true,
            label: 'imprisonment',
            placeholder: 'จำนวนเดือน',
            description: 'Description',
            labelClass: 'col-6',
            inputClass: 'col-6',
            type: 'number',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everPenalizedFinished || !model.everPenalized',
        },
        {
          key: 'everPenalizedJailMonth',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-3',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 108%',
            },
            label: 'not',
            placeholder: 'จำนวนเดือน',
            description: 'Description',
            labelClass: 'col-4',
            inputClass: 'col-6',
            type: 'number',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everPenalizedFinished || !model.everPenalized',
        },
        {
          key: 'everPenalizedExcapeYear',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-3 col-offset-6',
          templateOptions: {
            translate: true,
            label: 'past_punishment',
            placeholder: 'จำนวนปี',
            description: 'Description',
            labelClass: 'col-6',
            inputClass: 'col-6',
            type: 'number',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everPenalizedFinished || !model.everPenalized',
        },
        {
          key: 'everPenalizedExcapeMonth',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-3',
          templateOptions: {
            translate: true,
            attributes: {
              style: 'width: 108%',
            },
            label: 'not',
            placeholder: 'จำนวนเดือน',
            description: 'Description',
            labelClass: 'col-4',
            inputClass: 'col-6',
            type: 'number',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everPenalizedFinished || !model.everPenalized',
        },

        // Line 5
        {
          key: 'everGotOut',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          templateOptions: {
            translate: true,
            label: 'question5',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6 p-text-left',
            inputClass: 'col-6',
            options: [
              {
                label: 'เคย',
                value: true,
                style: 'margin-right: 300px',
              },
              {
                label: 'ไม่เคย',
                value: false,
              },
            ],
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        {
          key: 'everGotOutDescription',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          templateOptions: {
            translate: true,
            label: 'specify',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6',
            inputClass: 'col-5',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.everGotOut',
        },
        // Line 6
        {
          key: 'haveRelativeWorkingHere',
          type: 'radio',
          wrappers: ['inline-label'],
          className: 'col-12 p-display-grid-4',
          templateOptions: {
            translate: true,
            label: 'question6',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6 p-text-left',
            inputClass: 'col-6',
            options: [
              {
                label: 'มี',
                value: true,
              },
              {
                label: 'ไม่มี',
                value: false,
              },
            ],
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          defaultValue: null,
        },
        {
          key: 'haveRelativeWorkingHereDescription',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          className: 'col-12',
          templateOptions: {
            translate: true,
            label: 'specify',
            placeholder: '@blank',
            description: 'Description',
            labelClass: 'col-6',
            inputClass: 'col-5',
          },
          expressionProperties: {
            'templateOptions.required': () => this.currentUser == null,
          },
          validation: {
            show: this.isShowErr,
          },
          hideExpression: '!model.haveRelativeWorkingHere',
        },
      ],
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
