/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AccessLogModelPaginationResult } from '../models/access-log-model-pagination-result';
import { AccessLogModel } from '../models/access-log-model';
@Injectable({
  providedIn: 'root',
})
class AccessLogService extends __BaseService {
  static readonly GetAccessLogListPath = '/api/AccessLog';
  static readonly GetAccessLogByIdPath = '/api/AccessLog/{accessLogId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AccessLogService.GetAccessLogListParams` containing the following parameters:
   *
   * - `Username`:
   *
   * - `Url`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `DateTo`:
   *
   * - `DateFrom`:
   *
   * @return Success
   */
  GetAccessLogListResponse(params: AccessLogService.GetAccessLogListParams): __Observable<__StrictHttpResponse<AccessLogModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Username != null) __params = __params.set('Username', params.Username.toString());
    if (params.Url != null) __params = __params.set('Url', params.Url.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.DateTo != null) __params = __params.set('DateTo', params.DateTo.toString());
    if (params.DateFrom != null) __params = __params.set('DateFrom', params.DateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AccessLog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccessLogModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `AccessLogService.GetAccessLogListParams` containing the following parameters:
   *
   * - `Username`:
   *
   * - `Url`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `DateTo`:
   *
   * - `DateFrom`:
   *
   * @return Success
   */
  GetAccessLogList(params: AccessLogService.GetAccessLogListParams): __Observable<AccessLogModelPaginationResult> {
    return this.GetAccessLogListResponse(params).pipe(
      __map(_r => _r.body as AccessLogModelPaginationResult)
    );
  }

  /**
   * @param accessLogId undefined
   * @return Success
   */
  GetAccessLogByIdResponse(accessLogId: number): __Observable<__StrictHttpResponse<AccessLogModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AccessLog/${encodeURIComponent(String(accessLogId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccessLogModel>;
      })
    );
  }
  /**
   * @param accessLogId undefined
   * @return Success
   */
  GetAccessLogById(accessLogId: number): __Observable<AccessLogModel> {
    return this.GetAccessLogByIdResponse(accessLogId).pipe(
      __map(_r => _r.body as AccessLogModel)
    );
  }
}

module AccessLogService {

  /**
   * Parameters for GetAccessLogList
   */
  export interface GetAccessLogListParams {
    Username?: string;
    Url?: string;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    DateTo?: string;
    DateFrom?: string;
  }
}

export { AccessLogService }
