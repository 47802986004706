import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { BenefitPolicyElementConditionModel, BenefitPolicyElementModel } from 'app/api/models';
import { SelectItem } from 'primeng/api';

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof BenefitPolicyElementModel | keyof BenefitPolicyElementConditionModel;
  fieldGroup?: CustomFormlyFieldConfig[];
  fieldArray?: CustomFormlyFieldConfig;
}

@Component({
  selector: 'app-modal-benefit-advance-setting',
  templateUrl: './modal-benefit-advance-setting.component.html',
  styleUrls: ['./modal-benefit-advance-setting.component.css'],
})
export class ModalBenefitAdvanceSettingComponent implements OnInit {
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private masterDataRepo: MasterDataRepo,
  ) { }
  model: BenefitPolicyElementModel;
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isActiveFirstYear',
          className: 'col-6',
          type: 'switch-input',
          defaultValue: false,
          templateOptions: {
            label: 'Active ในปีแรก',
            labelClass: 'col-4',
            inputClass: 'col-8',
            trueMessage: 'Yes',
            falseMessage: 'No',
          },
        },
      ]
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isProrate',
          className: 'col-6',
          type: 'switch-input',
          defaultValue: false,
          templateOptions: {
            // translate: true,
            // required: true,

            label: 'Prorate',
            labelClass: 'col-4',
            inputClass: 'col-8',
            trueMessage: 'Yes',
            falseMessage: 'No',
          },
        },
      ]
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'benefitUseTypeId',
          type: 'filter-dropdown',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            required: true,
            label: 'right_use',
            placeholder: '@blank',
            labelClass: 'col-4',
            inputClass: 'col-8',
            options: this.masterDataRepo.getBenefitUseTypesList(),
          },
        },
      ]
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'isCarryForward',
          className: 'col-6',
          type: 'switch-input',
          defaultValue: false,
          templateOptions: {
            // translate: true,
            // required: true,

            label: 'สะสม',
            labelClass: 'col-4',
            inputClass: 'col-8',
            trueMessage: 'Yes',
            falseMessage: 'No',
          },
        },
      ]
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'maxCarryForward',
          className: 'col-6',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          hideExpression: (model, state, field) => !model.isCarryForward,
          defaultValue: 0,
          templateOptions: {
            // translate: true,
            // required: true,
            label: 'จำนวนวันสูงสุดที่สะสม',
            labelClass: 'col-4',
            inputClass: 'col-8',
            type: 'number',
            min: 0,
          },
        },
        {
          key: 'carryForwardAge',
          className: 'col-6',
          type: 'primeng-input',
          defaultValue: 0,
          wrappers: ['inline-label'],
          hideExpression: (model, state, field) => !model.isCarryForward,
          templateOptions: {
            // translate: true,
            // required: true,
            label: 'อายุการใช้งานของยอดสะสม(เดือน)',
            labelClass: 'col-4',
            inputClass: 'col-8',
            type: 'number',
            min: 0,
          },
          validators: {
            validation: [{ name: 'integer' }],
          },
        },
      ]
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'minimumRequestBeforePeriod',
          className: 'col-6',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          defaultValue: 0,
          templateOptions: {
            label: 'เงื่อนไขต้องขอก่อนกี่วัน',
            labelClass: 'col-4',
            inputClass: 'col-8',
            description: 'ใส่ 0 คือขอตอนไหนก็ได้',
            type: 'number',
            min: 0,
          },
        },
      ],
    },
    {
      key: 'benefitPolicyElementConditions',
      type: 'repeat-input',
      templateOptions: {
        // translate: true,
        // required: true,
        label: 'เงือนไขการเพิ่มจำนวน',
        addText: '+ เพิ่มรายการเงือนไข',
        labelClass: 'col-2',
        inputClass: 'col-6',
        // required: true,
      },
      fieldArray: {
        fieldGroupClassName: 'grid',
        fieldGroup: [
          {
            key: 'yearExperience',
            className: 'col-6',
            type: 'primeng-input',
            defaultValue: 0,
            wrappers: ['inline-label'],
            templateOptions: {
              label: 'อายุงาน(ปี)',
              labelClass: 'col-6',
              inputClass: 'col-6',
              type: 'number',
              min: 0,
            },
          },
          {
            key: 'amount',
            className: 'col-6',
            type: 'primeng-input',
            defaultValue: 0,
            wrappers: ['inline-label'],
            templateOptions: {
              label: 'จำนวนวันที่เพิ่ม',
              labelClass: 'col-6',
              inputClass: 'col-6',
              type: 'number',
              min: 0,
              tooltip: `จำนวนวันที่เพิ่มจะไม่ทบกับอายุงาน เช่น สวัสดิการวันลาพักผ่อนประจำปีเริ่มต้นที่ 6 วันหลังจากพยักงานเข้าทำงานปีที่ 1

              หากอายุงานครบ 3 ปี (และผ่านเงื่อนไขในการได้รับสิทธิ์) จะได้จำนวนวันลาเพิ่ม 2 วัน เมื่อรวมกับวันลาเริ่มต้น ปีนี้พนักงานจะได้วันลาทั้งหมด 8 วัน (6+2)

              หากอายุงานครบ 5 ปี (และผ่านเงื่อนไขในการได้รับสิทธิ์) จะได้จำนวนวันลาเพิ่ม 4 วัน เมื่อรวมกับวันลาเริ่มต้น ปีนี้พนักงานจะได้วันลาทั้งหมด10 วัน (6+4)`,
            },
          },
        ],
      },
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'benefitConditionTypeId',
          className: 'col-6',
          type: 'filter-dropdown',
          wrappers: ['inline-label'],
          hideExpression: 'model.benefitPolicyElementConditions.length == 0',
          templateOptions: {
            translate: true,
            required: true,
            label: 'เงือนไขการได้รับสิทธิ์เพิ่มจำนวน',
            placeholder: '@blank',
            labelClass: 'col-4',
            inputClass: 'col-8',
            options: this.masterDataRepo.getBenefitConditionTypesList(),
          },
        },
      ]
    }
  ];

  static toDropdownDayValue(date: Date) {
    return `2020-${date.getMonth() + 1}-${date.getDate()}`;
  }

  getDropdownDays(): SelectItem[] {
    const days: SelectItem[] = [];

    let date = new Date(2020, 0, 1);
    while (date.getFullYear() < 2021) {
      days.push({
        label: `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}`,
        value: ModalBenefitAdvanceSettingComponent.toDropdownDayValue(date),
      });

      date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    }

    return days;
  }

  ngOnInit() {
    this.model = this.config.data || {};
  }

  submit() {
    this.ref.close(this.model);
  }

  close() {
    this.ref.close();
  }
}
