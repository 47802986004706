/* tslint:disable */
type ESpouseIncomeType =
  'HaveIncome' |
  'NoIncome';
module ESpouseIncomeType {
  export const HAVE_INCOME: ESpouseIncomeType = 'HaveIncome';
  export const NO_INCOME: ESpouseIncomeType = 'NoIncome';
  export function values(): ESpouseIncomeType[] {
    return [
      HAVE_INCOME,
      NO_INCOME
    ];
  }
}

export { ESpouseIncomeType }