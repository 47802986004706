/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeAssetSurveyModel } from '../models/employee-asset-survey-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeAssetSurveyService extends __BaseService {
  static readonly GetEmployeeAssetSurveyByIdPath = '/api/EmployeeAssetSurvey/{employeeAssetSurveyId}';
  static readonly DeleteEmployeeAssetSurveyPath = '/api/EmployeeAssetSurvey/{employeeAssetSurveyId}';
  static readonly GetEmployeeAssetSurveyTemplateDefaultPath = '/api/EmployeeAssetSurvey/template/default';
  static readonly GetEmployeeAssetSurveyTemplatePath = '/api/EmployeeAssetSurvey/template/{templateId}';
  static readonly CreateEmployeeAssetSurveyPath = '/api/EmployeeAssetSurvey';
  static readonly UpdateEmployeeAssetSurveyPath = '/api/EmployeeAssetSurvey';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param employeeAssetSurveyId undefined
   * @return Success
   */
  GetEmployeeAssetSurveyByIdResponse(employeeAssetSurveyId: number): __Observable<__StrictHttpResponse<EmployeeAssetSurveyModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeAssetSurvey/${encodeURIComponent(String(employeeAssetSurveyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeAssetSurveyModel>;
      })
    );
  }
  /**
   * @param employeeAssetSurveyId undefined
   * @return Success
   */
  GetEmployeeAssetSurveyById(employeeAssetSurveyId: number): __Observable<EmployeeAssetSurveyModel> {
    return this.GetEmployeeAssetSurveyByIdResponse(employeeAssetSurveyId).pipe(
      __map(_r => _r.body as EmployeeAssetSurveyModel)
    );
  }

  /**
   * @param employeeAssetSurveyId undefined
   * @return Success
   */
  DeleteEmployeeAssetSurveyResponse(employeeAssetSurveyId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeAssetSurvey/${encodeURIComponent(String(employeeAssetSurveyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeAssetSurveyId undefined
   * @return Success
   */
  DeleteEmployeeAssetSurvey(employeeAssetSurveyId: number): __Observable<string> {
    return this.DeleteEmployeeAssetSurveyResponse(employeeAssetSurveyId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetEmployeeAssetSurveyTemplateDefaultResponse(): __Observable<__StrictHttpResponse<EmployeeAssetSurveyModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeAssetSurvey/template/default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeAssetSurveyModel>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeAssetSurveyTemplateDefault(): __Observable<EmployeeAssetSurveyModel> {
    return this.GetEmployeeAssetSurveyTemplateDefaultResponse().pipe(
      __map(_r => _r.body as EmployeeAssetSurveyModel)
    );
  }

  /**
   * @param templateId undefined
   * @return Success
   */
  GetEmployeeAssetSurveyTemplateResponse(templateId: number): __Observable<__StrictHttpResponse<EmployeeAssetSurveyModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeAssetSurvey/template/${encodeURIComponent(String(templateId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeAssetSurveyModel>;
      })
    );
  }
  /**
   * @param templateId undefined
   * @return Success
   */
  GetEmployeeAssetSurveyTemplate(templateId: number): __Observable<EmployeeAssetSurveyModel> {
    return this.GetEmployeeAssetSurveyTemplateResponse(templateId).pipe(
      __map(_r => _r.body as EmployeeAssetSurveyModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeAssetSurveyResponse(body?: EmployeeAssetSurveyModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeAssetSurvey`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeAssetSurvey(body?: EmployeeAssetSurveyModel): __Observable<string> {
    return this.CreateEmployeeAssetSurveyResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeAssetSurveyResponse(body?: EmployeeAssetSurveyModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeAssetSurvey`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeAssetSurvey(body?: EmployeeAssetSurveyModel): __Observable<string> {
    return this.UpdateEmployeeAssetSurveyResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeAssetSurveyService {
}

export { EmployeeAssetSurveyService }
