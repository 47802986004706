import { Component, OnInit, OnDestroy } from '@angular/core';
import { JobGradeOfSystemModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { JobGradeOfSystemService } from 'app/api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MasterDataRepo } from 'app/repo/master-data.repo';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof JobGradeOfSystemModel;
}

@Component({
  selector: 'app-modal-job-grade-of-system',
  templateUrl: './modal-job-grade-of-system.component.html',
  styleUrls: ['./modal-job-grade-of-system.component.css'],
})
export class ModalJobGradeOfSystemComponent implements OnInit, OnDestroy {
  model: JobGradeOfSystemModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'order',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'degree',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        type: 'number',
        required: true,
        helpMessage: '* เลขมากคือระดับพนักงานที่มีตำแหน่งสูงกว่า'
      },
    },
    {
      key: 'description',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'description',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: true,
        maxLength: 1024,
      },
    },
    {
      key: 'jobGradeLevel',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'employee_level_type',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        required: true,
        // options: this.masterDataRepo.getJobGradeLevelsOptionList(),
      },
    },
  ];
  unsubscripe$ = new Subject<any>();
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private jobGradeOfSystemService: JobGradeOfSystemService,
    private noti: AppNotificationServiceService,
    private masterDataRepo: MasterDataRepo,
    private notificationService: AppNotificationServiceService
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  // ngOnInit() {
  //   const jobGradeOfSystemId = this.config.data?.id;

  //   if (jobGradeOfSystemId) {
  //     this.componentMode = ComponentMode.UPDATE;
  //     this.initData(jobGradeOfSystemId);
  //   } else {
  //     this.componentMode = ComponentMode.CREATE;
  //     this.model = {};
  //   }
  // }

  ngOnDestroy(): void {
    this.unsubscripe$.unsubscribe();
  }

  initComponentMode() {
    const jobGradeOfSystemId = this.getJobGradeOfSystemId();

    if (jobGradeOfSystemId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'jobGradeLevel').templateOptions.options = await this.masterDataRepo
      .getJobGradeLevelsOptionList()
      .toPromise();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {};
      return;
    }

    this.model = await this.jobGradeOfSystemService.GetJobGradeOfSystemById(this.getJobGradeOfSystemId()).toPromise();
  }

  submit() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.jobGradeOfSystemService
        .CreateJobGradeOfSystem(this.model)
        .subscribe(
          () => {
            this.noti.saveCompleted();
            this.ref.close(this.model);
          },
          (err: HttpErrorResponse) => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
          },
        );
    } else {
      this.jobGradeOfSystemService
        .UpdateJobGradeOfSystem(this.model)
        .subscribe(
          () => {
            this.noti.saveCompleted();
            this.ref.close(this.model);
          },
          (err: HttpErrorResponse) => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
          },
        );
    }
  }

  close() {
    this.ref.close();
  }

  getJobGradeOfSystemId() {
    return this.config.data?.id;
  }
}
