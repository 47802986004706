<form [formGroup]="form">
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
</form>
<!-- <div class="p-dialog-footer">
  <button
    type="button"
    pButton
    class="p-button-primary"
    label="ค้นหา"
    [disabled]="!form.valid"
    (click)="search()"
  ></button>
  <button type="button" pButton class="p-button-secondary" label="ยกเลิก" (click)="close()"></button>
</div> -->

<p-table [value]="employeeList" [loading]="loading" [autoLayout]="true"
  [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 50px"></th>
      <th style="width: 150px">Employee Code</th>
      <th>{{ 'name' | translate }}</th>
      <th>{{ 'surname' | translate }}</th>
      <th>{{ 'sex' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>
        <button pButton icon="fa fa-user-plus" (click)="selectEmployee(rowData)"></button>
      </td>
      <td>{{ rowData.employeeCode }}</td>
      <td>{{ rowData.employeeName?.firstnameTh || 'N/A' }}</td>
      <td>{{ rowData.employeeName?.lastnameTh || 'N/A' }}</td>
      <td>{{ rowData.genderName }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="10" class="emptymessage">ไม่พบข้อมูล</td>
    </tr>
  </ng-template>
</p-table>
<p-paginator [rows]="paginationParams.rowPerPage" [first]="paginationParams.firstRowIdx"
  [totalRecords]="paginationParams.totalRecords" [showCurrentPageReport]="true"
  [rowsPerPageOptions]="paginationParams.rowPerPageSelectList"
  currentPageReportTemplate="{first} - {last} of {totalRecords} Results" (onPageChange)="onPageChange($event)">
</p-paginator>
