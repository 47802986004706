/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeInsigniaListItemModel } from '../models/employee-insignia-list-item-model';
import { EmployeeInsigniaModel } from '../models/employee-insignia-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeInsigniaService extends __BaseService {
  static readonly GetEmployeeInsigniasListPath = '/api/EmployeeInsignia';
  static readonly CreateEmployeeInsigniaPath = '/api/EmployeeInsignia';
  static readonly GetEmployeeInsigniaByIdPath = '/api/EmployeeInsignia/{EmployeeInsigniaId}';
  static readonly UpdateEmployeeInsigniaPath = '/api/EmployeeInsignia/{EmployeeInsigniaId}';
  static readonly DeleteEmployeeInsigniaPath = '/api/EmployeeInsignia/{EmployeeInsigniaId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeInsigniasListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeInsigniaListItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeInsignia`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeInsigniaListItemModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeInsigniasList(): __Observable<Array<EmployeeInsigniaListItemModel>> {
    return this.GetEmployeeInsigniasListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeInsigniaListItemModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeInsigniaResponse(body?: EmployeeInsigniaModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeInsignia`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeInsignia(body?: EmployeeInsigniaModel): __Observable<string> {
    return this.CreateEmployeeInsigniaResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeInsigniaId undefined
   * @return Success
   */
  GetEmployeeInsigniaByIdResponse(EmployeeInsigniaId: number): __Observable<__StrictHttpResponse<EmployeeInsigniaModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeInsignia/${encodeURIComponent(String(EmployeeInsigniaId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeInsigniaModel>;
      })
    );
  }
  /**
   * @param EmployeeInsigniaId undefined
   * @return Success
   */
  GetEmployeeInsigniaById(EmployeeInsigniaId: number): __Observable<EmployeeInsigniaModel> {
    return this.GetEmployeeInsigniaByIdResponse(EmployeeInsigniaId).pipe(
      __map(_r => _r.body as EmployeeInsigniaModel)
    );
  }

  /**
   * @param params The `EmployeeInsigniaService.UpdateEmployeeInsigniaParams` containing the following parameters:
   *
   * - `EmployeeInsigniaId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeInsigniaResponse(params: EmployeeInsigniaService.UpdateEmployeeInsigniaParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeInsignia/${encodeURIComponent(String(params.EmployeeInsigniaId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeInsigniaService.UpdateEmployeeInsigniaParams` containing the following parameters:
   *
   * - `EmployeeInsigniaId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeInsignia(params: EmployeeInsigniaService.UpdateEmployeeInsigniaParams): __Observable<string> {
    return this.UpdateEmployeeInsigniaResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeInsigniaId undefined
   * @return Success
   */
  DeleteEmployeeInsigniaResponse(EmployeeInsigniaId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeInsignia/${encodeURIComponent(String(EmployeeInsigniaId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeInsigniaId undefined
   * @return Success
   */
  DeleteEmployeeInsignia(EmployeeInsigniaId: number): __Observable<string> {
    return this.DeleteEmployeeInsigniaResponse(EmployeeInsigniaId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeInsigniaService {

  /**
   * Parameters for UpdateEmployeeInsignia
   */
  export interface UpdateEmployeeInsigniaParams {
    EmployeeInsigniaId: number;
    body?: EmployeeInsigniaModel;
  }
}

export { EmployeeInsigniaService }
