/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RevenueModelPaginationResult } from '../models/revenue-model-pagination-result';
import { ImportRevenuesModel } from '../models/import-revenues-model';
import { ExportRevenueExcelParam } from '../models/export-revenue-excel-param';
@Injectable({
  providedIn: 'root',
})
class RevenueService extends __BaseService {
  static readonly GetRevenueListPath = '/api/Revenue';
  static readonly ImportRevenuesExcelStep1Path = '/api/Revenue/run/importexcel/step1';
  static readonly ImportRevenuesExcelStep2Path = '/api/Revenue/run/importexcel/step2';
  static readonly ExportExcelPath = '/api/Revenue/run/exportexcel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RevenueService.GetRevenueListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetRevenueListResponse(params: RevenueService.GetRevenueListParams): __Observable<__StrictHttpResponse<RevenueModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Revenue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RevenueModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `RevenueService.GetRevenueListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetRevenueList(params: RevenueService.GetRevenueListParams): __Observable<RevenueModelPaginationResult> {
    return this.GetRevenueListResponse(params).pipe(
      __map(_r => _r.body as RevenueModelPaginationResult)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  ImportRevenuesExcelStep1Response(file?: Blob): __Observable<__StrictHttpResponse<ImportRevenuesModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Revenue/run/importexcel/step1`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImportRevenuesModel>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  ImportRevenuesExcelStep1(file?: Blob): __Observable<ImportRevenuesModel> {
    return this.ImportRevenuesExcelStep1Response(file).pipe(
      __map(_r => _r.body as ImportRevenuesModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ImportRevenuesExcelStep2Response(body?: ImportRevenuesModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Revenue/run/importexcel/step2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ImportRevenuesExcelStep2(body?: ImportRevenuesModel): __Observable<string> {
    return this.ImportRevenuesExcelStep2Response(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ExportExcelResponse(body?: ExportRevenueExcelParam): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Revenue/run/exportexcel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ExportExcel(body?: ExportRevenueExcelParam): __Observable<Blob> {
    return this.ExportExcelResponse(body).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module RevenueService {

  /**
   * Parameters for GetRevenueList
   */
  export interface GetRevenueListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    CompanyId?: number;
  }
}

export { RevenueService }
