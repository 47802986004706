import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeChronicDiseaseModel } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EmployeeChronicDiseaseService } from 'app/api/services';
import { Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'app-modal-employee-chronic-disease',
  templateUrl: './modal-employee-chronic-disease.component.html',
  styleUrls: ['./modal-employee-chronic-disease.component.css'],
})
export class ModalEmployeeChronicDiseaseComponent implements OnInit, OnDestroy {
  model: EmployeeChronicDiseaseModel = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'diseaseName',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'disease_name',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'note',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        translate: true,
        label: 'more_details',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeChronicDiseaseService: EmployeeChronicDiseaseService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const employeeChronicDiseaseId = this.getEmployeeChronicDiseaseId();

    if (employeeChronicDiseaseId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    this.model = await this.employeeChronicDiseaseService
      .GetEmployeeChronicDisease(this.getEmployeeChronicDiseaseId())
      .toPromise();
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createEmployeeChronicDisease()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateEmployeeChronicDisease()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createEmployeeChronicDisease() {
    return this.employeeChronicDiseaseService.CreateEmployeeChronicDisease({
      employeeId: this.model.employeeId,
      diseaseName: this.model.diseaseName,
      note: this.model.note,
    });
  }

  private updateEmployeeChronicDisease() {
    return this.employeeChronicDiseaseService.UpdateEmployeeChronicDisease({
      EmployeeChronicDiseaseId: this.model.employeeChronicDiseaseId,
      body: {
        employeeId: this.model.employeeId,
        employeeChronicDiseaseId: this.model.employeeChronicDiseaseId,
        diseaseName: this.model.diseaseName,
        note: this.model.note,
        status: this.model.status,
      },
    });
  }

  getEmployeeChronicDiseaseId() {
    return this.config.data?.id;
  }
}
