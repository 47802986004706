/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeePromoteRequestModelPaginationResult } from '../models/employee-promote-request-model-pagination-result';
import { EmployeePromoteRequestModel } from '../models/employee-promote-request-model';
@Injectable({
  providedIn: 'root',
})
class EmployeePromoteRequestService extends __BaseService {
  static readonly GetEmployeePromoteRequestModelListPath = '/api/EmployeePromoteRequest';
  static readonly CreateEmployeePromoteRequestPath = '/api/EmployeePromoteRequest';
  static readonly UpdateEmployeePromoteRequestPath = '/api/EmployeePromoteRequest';
  static readonly GetEmployeePromoteRequestByIdPath = '/api/EmployeePromoteRequest/{employeePromoteRequestId}';
  static readonly DeleteEmployeePromoteRequestPath = '/api/EmployeePromoteRequest/{employeePromoteRequestId}';
  static readonly CancelEmployeePromoteRequestPath = '/api/EmployeePromoteRequest/{employeePromoteRequestId}/cancel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EmployeePromoteRequestService.GetEmployeePromoteRequestModelListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `HasUnitModified`:
   *
   * - `HasSalaryModified`:
   *
   * - `HasPositionModified`:
   *
   * - `HasOtherIncomeModified`:
   *
   * - `HasEmployeeTypeModified`:
   *
   * - `HasCompanyModified`:
   *
   * - `CreatedDate`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetEmployeePromoteRequestModelListResponse(params: EmployeePromoteRequestService.GetEmployeePromoteRequestModelListParams): __Observable<__StrictHttpResponse<EmployeePromoteRequestModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.HasUnitModified != null) __params = __params.set('HasUnitModified', params.HasUnitModified.toString());
    if (params.HasSalaryModified != null) __params = __params.set('HasSalaryModified', params.HasSalaryModified.toString());
    if (params.HasPositionModified != null) __params = __params.set('HasPositionModified', params.HasPositionModified.toString());
    if (params.HasOtherIncomeModified != null) __params = __params.set('HasOtherIncomeModified', params.HasOtherIncomeModified.toString());
    if (params.HasEmployeeTypeModified != null) __params = __params.set('HasEmployeeTypeModified', params.HasEmployeeTypeModified.toString());
    if (params.HasCompanyModified != null) __params = __params.set('HasCompanyModified', params.HasCompanyModified.toString());
    if (params.CreatedDate != null) __params = __params.set('CreatedDate', params.CreatedDate.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeePromoteRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePromoteRequestModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeePromoteRequestService.GetEmployeePromoteRequestModelListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `HasUnitModified`:
   *
   * - `HasSalaryModified`:
   *
   * - `HasPositionModified`:
   *
   * - `HasOtherIncomeModified`:
   *
   * - `HasEmployeeTypeModified`:
   *
   * - `HasCompanyModified`:
   *
   * - `CreatedDate`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetEmployeePromoteRequestModelList(params: EmployeePromoteRequestService.GetEmployeePromoteRequestModelListParams): __Observable<EmployeePromoteRequestModelPaginationResult> {
    return this.GetEmployeePromoteRequestModelListResponse(params).pipe(
      __map(_r => _r.body as EmployeePromoteRequestModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeePromoteRequestResponse(body?: EmployeePromoteRequestModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeePromoteRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeePromoteRequest(body?: EmployeePromoteRequestModel): __Observable<number> {
    return this.CreateEmployeePromoteRequestResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeePromoteRequestResponse(body?: EmployeePromoteRequestModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeePromoteRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeePromoteRequest(body?: EmployeePromoteRequestModel): __Observable<string> {
    return this.UpdateEmployeePromoteRequestResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeePromoteRequestId undefined
   * @return Success
   */
  GetEmployeePromoteRequestByIdResponse(employeePromoteRequestId: number): __Observable<__StrictHttpResponse<EmployeePromoteRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeePromoteRequest/${encodeURIComponent(String(employeePromoteRequestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePromoteRequestModel>;
      })
    );
  }
  /**
   * @param employeePromoteRequestId undefined
   * @return Success
   */
  GetEmployeePromoteRequestById(employeePromoteRequestId: number): __Observable<EmployeePromoteRequestModel> {
    return this.GetEmployeePromoteRequestByIdResponse(employeePromoteRequestId).pipe(
      __map(_r => _r.body as EmployeePromoteRequestModel)
    );
  }

  /**
   * @param employeePromoteRequestId undefined
   * @return Success
   */
  DeleteEmployeePromoteRequestResponse(employeePromoteRequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeePromoteRequest/${encodeURIComponent(String(employeePromoteRequestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeePromoteRequestId undefined
   * @return Success
   */
  DeleteEmployeePromoteRequest(employeePromoteRequestId: number): __Observable<string> {
    return this.DeleteEmployeePromoteRequestResponse(employeePromoteRequestId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeePromoteRequestId undefined
   * @return Success
   */
  CancelEmployeePromoteRequestResponse(employeePromoteRequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeePromoteRequest/${encodeURIComponent(String(employeePromoteRequestId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeePromoteRequestId undefined
   * @return Success
   */
  CancelEmployeePromoteRequest(employeePromoteRequestId: number): __Observable<string> {
    return this.CancelEmployeePromoteRequestResponse(employeePromoteRequestId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeePromoteRequestService {

  /**
   * Parameters for GetEmployeePromoteRequestModelList
   */
  export interface GetEmployeePromoteRequestModelListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    HasUnitModified?: boolean;
    HasSalaryModified?: boolean;
    HasPositionModified?: boolean;
    HasOtherIncomeModified?: boolean;
    HasEmployeeTypeModified?: boolean;
    HasCompanyModified?: boolean;
    CreatedDate?: string;
    CompanyId?: number;
  }
}

export { EmployeePromoteRequestService }
