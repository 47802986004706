import { Component, EventEmitter, OnInit } from '@angular/core';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalPositionComponent } from 'modals/core/modal-position/modal-position.component';
import { LayoutDefaultComponent } from 'app/layout/layout-default/layout-default.component';


@Component({
  selector: 'formly-add-position',
  templateUrl: './formly-add-position.component.html',
  styleUrls: ['./formly-add-position.component.scss']
})
export class FormlyAddPositionComponent extends FieldType implements OnInit {
  companyId: number;
  isWaitCompanyId = true;
  ref: DynamicDialogRef;

  constructor(private notificationService: AppNotificationServiceService,
    private dialogService: DialogService,
    private layout: LayoutDefaultComponent) {
    super();
  }

  ngOnInit() {
    if (this.to?.companyChange instanceof EventEmitter || this.to?.companyChange instanceof Subject) {
      this.to.companyChange.subscribe(companyId => {
        this.companyId = companyId;
        console.log(this.companyId);
        this.isWaitCompanyId = false;
      });
    }
  };

  showCreateDialog() {
    if (this.companyId == undefined) {
      this.notificationService.error('กรุณาระบุ สังกัดบริษัท ก่อน', '');
      return;
    }

    this.showDialog({ companyId: this.companyId }, '+ เพิ่มตำแหน่งงาน');

    this.ref.onClose.subscribe(data => {
      if (data) {
        //   this.model.unitId = data.unitId;
        //   this.form.get("unitId").valueChanges.subscribe(x => {
        //     this.form.controls.unitId.setValue(data.unitId);
        //  })
        //   this.model.positionId = data.positionId;
        //   this.form.controls.positionId.setValue(data.positionId);
      }
    });
  }

  showDialog(data: any, header: string) {
    this.ref = this.dialogService.open(ModalPositionComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }


}
