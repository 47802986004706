import { Component, OnInit, OnDestroy } from '@angular/core';
import { HolidayModel, EShiftsInHolidayType } from 'app/api/models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HolidayService, ShiftService } from 'app/api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';

import { MasterDataRepo } from 'app/repo/master-data.repo';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { finalize, tap, flatMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of, Observable, forkJoin } from 'rxjs';

interface HolidayFormModel extends HolidayModel {
  shiftIds?: number[];
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof HolidayFormModel;
}

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

@Component({
  selector: 'app-modal-holiday',
  templateUrl: './modal-holiday.component.html',
  styleUrls: ['./modal-holiday.component.css'],
})
export class ModalHolidayComponent implements OnInit {
  model: HolidayFormModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'date',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'a_date',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        label: 'name_annual_holiday',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-8',
        maxLength: 128,
      },
    },
    {
      key: 'holidayTypeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,

        label: 'holiday_type',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
    {
      key: 'remark',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'detail',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        maxLength: 1024,
      },
    },
    {
      key: 'shiftsInHolidayType',
      type: 'radio',
      wrappers: ['inline-label'],
      defaultValue: ((): EShiftsInHolidayType => 'Exclude')(),
      templateOptions: {
        translate: true,
        required: true,

        label: 'relationship_work_shift',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
        options: this.masterDataRepo.getShiftsInHolidayTypesOptionList(),
      },
    },
    //
    {
      key: 'shiftIds',
      type: 'multi-select',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'select_shift',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-8',
      },
    },
  ];
  componentMode: ComponentMode;

  constructor(
    protected ref: DynamicDialogRef,
    protected config: DynamicDialogConfig,
    protected noti: AppNotificationServiceService,
    private holidayService: HolidayService,
    private masterDataRepo: MasterDataRepo,
    private shiftService: ShiftService,
    private optionListRepo: OptionListRepo,
  ) {}

  ngOnInit(): void {
    this.initComponentMode();

    forkJoin([
      this.getDataModel(),
      this.masterDataRepo.getHolidayTypeOptionList(),
      this.shiftService.GetShiftsList({ ItemPerPage: 999, CompanyId: this.getCompanyId(), Status: 'Active' }),
    ])
      .subscribe(([model, holidayTypeOpts, shiftPage]) => {
        this.model = model;
        this.fields.find(f => f.key === 'holidayTypeId').templateOptions.options = holidayTypeOpts;
        this.fields.find(f => f.key === 'shiftIds').templateOptions.options = shiftPage.results.map(s => {
          return { label: s.name, value: s.shiftId };
        });
      });
  }

  initComponentMode() {
    const id = this.getId();

    if (id) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  getDataModel(): Observable<HolidayFormModel> {
    if (this.componentMode === ComponentMode.CREATE) {
      return of({ companyId: this.config.data?.companyId, shiftIds: [] });
    }

    return this.holidayService.GetHolidayById(this.getId()).pipe(
      flatMap(holiday => {
        return of({
          ...holiday,
          shiftIds: holiday.shiftsInHoliday.map(a => a.shiftId),
          date: (holiday.date),
        });
      }),
    );
  }

  // async initOptionList() {
  //   this.fields.find(
  //     f => f.key === 'holidayTypeId',
  //   ).templateOptions.options = await this.masterDataRepo.getHolidayTypeOptionList().toPromise();
  // }

  // async initData() {
  //   if (this.componentMode === ComponentMode.CREATE) {
  //     this.model = { companyId: this.config.data?.companyId };
  //     return;
  //   }

  //   const holiday = await this.holidayService.GetHolidayById(this.getId()).toPromise();

  //   this.model = {
  //     ...holiday,
  //     shiftIds: holiday.shiftsInHoliday.map(a => a.shiftId),
  //     date: (holiday.date),
  //   };
  // }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.holidayService
          .CreateHoliday({
            ...this.model,
            shiftsInHoliday: this.model.shiftIds.map(shiftId => {
              return { shiftId };
            }),
          })
          .subscribe(
            () => {
              this.noti.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.holidayService
          .UpdateHoliday({
            ...this.model,
            shiftsInHoliday: this.model.shiftIds.map(shiftId => {
              return { shiftId };
            }),
          })
          .subscribe(
            () => {
              this.noti.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.noti.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  getId() {
    return this.config.data?.id;
  }

  getCompanyId() {
    return this.config.data?.companyId;
  }
}
