/* tslint:disable */
type EAccessLogEventType =
  'InUse' |
  'Disabled' |
  'Terminated';
module EAccessLogEventType {
  export const IN_USE: EAccessLogEventType = 'InUse';
  export const DISABLED: EAccessLogEventType = 'Disabled';
  export const TERMINATED: EAccessLogEventType = 'Terminated';
  export function values(): EAccessLogEventType[] {
    return [
      IN_USE,
      DISABLED,
      TERMINATED
    ];
  }
}

export { EAccessLogEventType }