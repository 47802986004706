/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UnitLevelOfSystemModelPaginationResult } from '../models/unit-level-of-system-model-pagination-result';
import { UnitLevelOfSystemModel } from '../models/unit-level-of-system-model';
@Injectable({
  providedIn: 'root',
})
class UnitLevelOfSystemService extends __BaseService {
  static readonly GetUnitLevelOfSystemsListPath = '/api/UnitLevelOfSystem';
  static readonly CreateUnitLevelOfSystemPath = '/api/UnitLevelOfSystem';
  static readonly UpdateUnitLevelOfSystemPath = '/api/UnitLevelOfSystem';
  static readonly GetUnitLevelOfSystemByIdPath = '/api/UnitLevelOfSystem/{unitLevelOfSystemId}';
  static readonly DeleteUnitLevelOfSystemPath = '/api/UnitLevelOfSystem/{unitLevelOfSystemId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UnitLevelOfSystemService.GetUnitLevelOfSystemsListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetUnitLevelOfSystemsListResponse(params: UnitLevelOfSystemService.GetUnitLevelOfSystemsListParams): __Observable<__StrictHttpResponse<UnitLevelOfSystemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UnitLevelOfSystem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnitLevelOfSystemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `UnitLevelOfSystemService.GetUnitLevelOfSystemsListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetUnitLevelOfSystemsList(params: UnitLevelOfSystemService.GetUnitLevelOfSystemsListParams): __Observable<UnitLevelOfSystemModelPaginationResult> {
    return this.GetUnitLevelOfSystemsListResponse(params).pipe(
      __map(_r => _r.body as UnitLevelOfSystemModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateUnitLevelOfSystemResponse(body?: UnitLevelOfSystemModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UnitLevelOfSystem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateUnitLevelOfSystem(body?: UnitLevelOfSystemModel): __Observable<string> {
    return this.CreateUnitLevelOfSystemResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateUnitLevelOfSystemResponse(body?: UnitLevelOfSystemModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/UnitLevelOfSystem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateUnitLevelOfSystem(body?: UnitLevelOfSystemModel): __Observable<string> {
    return this.UpdateUnitLevelOfSystemResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param unitLevelOfSystemId undefined
   * @return Success
   */
  GetUnitLevelOfSystemByIdResponse(unitLevelOfSystemId: number): __Observable<__StrictHttpResponse<UnitLevelOfSystemModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UnitLevelOfSystem/${encodeURIComponent(String(unitLevelOfSystemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnitLevelOfSystemModel>;
      })
    );
  }
  /**
   * @param unitLevelOfSystemId undefined
   * @return Success
   */
  GetUnitLevelOfSystemById(unitLevelOfSystemId: number): __Observable<UnitLevelOfSystemModel> {
    return this.GetUnitLevelOfSystemByIdResponse(unitLevelOfSystemId).pipe(
      __map(_r => _r.body as UnitLevelOfSystemModel)
    );
  }

  /**
   * @param unitLevelOfSystemId undefined
   * @return Success
   */
  DeleteUnitLevelOfSystemResponse(unitLevelOfSystemId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/UnitLevelOfSystem/${encodeURIComponent(String(unitLevelOfSystemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param unitLevelOfSystemId undefined
   * @return Success
   */
  DeleteUnitLevelOfSystem(unitLevelOfSystemId: number): __Observable<string> {
    return this.DeleteUnitLevelOfSystemResponse(unitLevelOfSystemId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module UnitLevelOfSystemService {

  /**
   * Parameters for GetUnitLevelOfSystemsList
   */
  export interface GetUnitLevelOfSystemsListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { UnitLevelOfSystemService }
