import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RepoModule } from 'app/repo/repo.module';
import { SharedModule } from 'app/shared/shared.module';

import { ModalRoleComponent } from './modal-role/modal-role.component';
import { ModalUserComponent } from './modal-user/modal-user.component';
import { ModalEditMasterdataComponent } from './modal-edit-masterdata/modal-edit-masterdata.component';

const components = [ModalUserComponent, ModalRoleComponent, ModalEditMasterdataComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, SharedModule, RepoModule],
  exports: components
})
export class SettingModule { }
