import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ModalsModule } from '../../modals/modals.module';
import { RepoModule } from '../../repo/repo.module';
import { SharedModule } from '../../shared/shared.module';
import { FilterSettingComponent } from './filter-setting/filter-setting.component';
const components = [
  FilterSettingComponent
];
@NgModule({
  imports: [CommonModule, NgxPermissionsModule, SharedModule, RepoModule],
  declarations: [...components],
  exports: [...components]
})
export class FoundationComponentModule { }
