/* tslint:disable */
type EEmployeePayrollProcessingStatus =
  'NotProcess' |
  'Error' |
  'Finish';
module EEmployeePayrollProcessingStatus {
  export const NOT_PROCESS: EEmployeePayrollProcessingStatus = 'NotProcess';
  export const ERROR: EEmployeePayrollProcessingStatus = 'Error';
  export const FINISH: EEmployeePayrollProcessingStatus = 'Finish';
  export function values(): EEmployeePayrollProcessingStatus[] {
    return [
      NOT_PROCESS,
      ERROR,
      FINISH
    ];
  }
}

export { EEmployeePayrollProcessingStatus }