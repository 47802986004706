/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeLanguageModel } from '../models/employee-language-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeLanguageService extends __BaseService {
  static readonly GetEmployeeLanguagesListPath = '/api/EmployeeLanguage';
  static readonly CreateEmployeeLanguagePath = '/api/EmployeeLanguage';
  static readonly UpdateEmployeeLanguagePath = '/api/EmployeeLanguage';
  static readonly GetEmployeeLanguageByIdPath = '/api/EmployeeLanguage/{EmployeeLanguageId}';
  static readonly DeleteEmployeeLanguagePath = '/api/EmployeeLanguage/{EmployeeLanguageId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeLanguagesListResponse(employeeId?: number): __Observable<__StrictHttpResponse<Array<EmployeeLanguageModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (employeeId != null) __params = __params.set('employeeId', employeeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeLanguage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeLanguageModel>>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeLanguagesList(employeeId?: number): __Observable<Array<EmployeeLanguageModel>> {
    return this.GetEmployeeLanguagesListResponse(employeeId).pipe(
      __map(_r => _r.body as Array<EmployeeLanguageModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeLanguageResponse(body?: EmployeeLanguageModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeLanguage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeLanguage(body?: EmployeeLanguageModel): __Observable<string> {
    return this.CreateEmployeeLanguageResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeLanguageResponse(body?: EmployeeLanguageModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeLanguage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeLanguage(body?: EmployeeLanguageModel): __Observable<string> {
    return this.UpdateEmployeeLanguageResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeLanguageId undefined
   * @return Success
   */
  GetEmployeeLanguageByIdResponse(EmployeeLanguageId: number): __Observable<__StrictHttpResponse<EmployeeLanguageModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeLanguage/${encodeURIComponent(String(EmployeeLanguageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeLanguageModel>;
      })
    );
  }
  /**
   * @param EmployeeLanguageId undefined
   * @return Success
   */
  GetEmployeeLanguageById(EmployeeLanguageId: number): __Observable<EmployeeLanguageModel> {
    return this.GetEmployeeLanguageByIdResponse(EmployeeLanguageId).pipe(
      __map(_r => _r.body as EmployeeLanguageModel)
    );
  }

  /**
   * @param EmployeeLanguageId undefined
   * @return Success
   */
  DeleteEmployeeLanguageResponse(EmployeeLanguageId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeLanguage/${encodeURIComponent(String(EmployeeLanguageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeLanguageId undefined
   * @return Success
   */
  DeleteEmployeeLanguage(EmployeeLanguageId: number): __Observable<string> {
    return this.DeleteEmployeeLanguageResponse(EmployeeLanguageId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeLanguageService {
}

export { EmployeeLanguageService }
