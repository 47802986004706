<div *ngFor="let afield of field.fieldGroup; let i = index" class="grid align-center">
  <div class="col-2 text-label">สิทธิประโยชน์ {{ i + 1 }}</div>
  <formly-field class="col-7" [field]="afield"></formly-field>
  <div class="col-1 icon-remove">
    <i class="fa fa-trash" (click)="remove(i)"></i>
  </div>
  <div class="col-fixed">
    <button pButton type="button" (click)="to.advanceSettingClick(i)" label="การตั้งค่าขั้นสูง"></button>
  </div>
  <div class="col-12 line-no-padding">
    <hr />
  </div>
</div>
<div class="grid justify-content-end">
  <div class="col-fixed">
    <button pButton type="button" (click)="addRow()" label="{{ to?.addText }}"></button>
  </div>
</div>
