<div #blockTarget>
  <div *ngIf="step == 1">
    <div #blockTarget class="p-fluid">
      <div class="grid">
        <div class="col-12">
          <form [formGroup]="form">
            <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
          </form>
        </div>
      </div>
    </div>
    <div class="p-dialog-footer">
      <button
        type="button"
        pButton
        class="p-button-primary"
        label="{{ 'check_' | translate }}"
        [disabled]="!form.valid"
        (click)="submit1()"
      ></button>
      <button
        type="button"
        pButton
        class="p-button-secondary"
        label="{{ 'cancel' | translate }}"
        (click)="close()"
      ></button>
    </div>
  </div>
  <div *ngIf="step == 2">
    <div>
      <p>{{ 'all_employee' | translate }} : {{ importModel.employees.length }}</p>
      <p>{{ 'all_family' | translate }} : {{ importModel.relatives.length }}</p>
      <p>{{ 'all_job' | translate }} : {{ importModel.positions.length }}</p>
      <p>{{ 'all_education' | translate }} : {{ importModel.educations.length }}</p>
    </div>
    <div *ngIf="importModel.errorData.length > 0">
      <h3>{{ 'error' | translate }}</h3>
      <ol>
        <li *ngFor="let item of importModel.errorData">{{ item.worksheet }}:{{ item.row }} => {{ item.message }}</li>
      </ol>
    </div>
    <div *ngIf="importModel.warningData.length > 0">
      <h3>{{ 'caution' | translate }}</h3>
      <ol>
        <li *ngFor="let item of importModel.warningData">{{ item.worksheet }}:{{ item.row }} => {{ item.message }}</li>
      </ol>
    </div>
    <div></div>
    <div class="p-dialog-footer">
      <button
        *ngIf="importModel.errorData.length == 0"
        type="button"
        pButton
        class="p-button-primary"
        label="{{ 'Import_data' | translate }}"
        (click)="submit2()"
      ></button>
      <button
        type="button"
        pButton
        class="p-button-secondary"
        label="{{ 'cancel' | translate }}"
        (click)="close()"
      ></button>
    </div>
  </div>
</div>
