/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PersonnelAreaModel } from '../models/personnel-area-model';
import { PersonnelAreaTreeNodeModel } from '../models/personnel-area-tree-node-model';
@Injectable({
  providedIn: 'root',
})
class PersonnelAreaService extends __BaseService {
  static readonly GetPersonnelAreasListPath = '/api/PersonnelArea';
  static readonly CreatePersonnelAreaPath = '/api/PersonnelArea';
  static readonly UpdatePersonnelAreaPath = '/api/PersonnelArea';
  static readonly GetPersonnelAreaByIdPath = '/api/PersonnelArea/{personnelAreaId}';
  static readonly DeletePersonnelAreaPath = '/api/PersonnelArea/{personnelAreaId}';
  static readonly GetPersonnelAreaTreeNodePath = '/api/PersonnelArea/personnelAreaTreeNode';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PersonnelAreaService.GetPersonnelAreasListParams` containing the following parameters:
   *
   * - `searchDate`:
   *
   * - `companyId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetPersonnelAreasListResponse(params: PersonnelAreaService.GetPersonnelAreasListParams): __Observable<__StrictHttpResponse<Array<PersonnelAreaModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchDate != null) __params = __params.set('searchDate', params.searchDate.toString());
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PersonnelArea`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonnelAreaModel>>;
      })
    );
  }
  /**
   * @param params The `PersonnelAreaService.GetPersonnelAreasListParams` containing the following parameters:
   *
   * - `searchDate`:
   *
   * - `companyId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetPersonnelAreasList(params: PersonnelAreaService.GetPersonnelAreasListParams): __Observable<Array<PersonnelAreaModel>> {
    return this.GetPersonnelAreasListResponse(params).pipe(
      __map(_r => _r.body as Array<PersonnelAreaModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreatePersonnelAreaResponse(body?: PersonnelAreaModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PersonnelArea`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreatePersonnelArea(body?: PersonnelAreaModel): __Observable<string> {
    return this.CreatePersonnelAreaResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdatePersonnelAreaResponse(body?: PersonnelAreaModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/PersonnelArea`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdatePersonnelArea(body?: PersonnelAreaModel): __Observable<string> {
    return this.UpdatePersonnelAreaResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param personnelAreaId undefined
   * @return Success
   */
  GetPersonnelAreaByIdResponse(personnelAreaId: number): __Observable<__StrictHttpResponse<PersonnelAreaModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PersonnelArea/${encodeURIComponent(String(personnelAreaId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonnelAreaModel>;
      })
    );
  }
  /**
   * @param personnelAreaId undefined
   * @return Success
   */
  GetPersonnelAreaById(personnelAreaId: number): __Observable<PersonnelAreaModel> {
    return this.GetPersonnelAreaByIdResponse(personnelAreaId).pipe(
      __map(_r => _r.body as PersonnelAreaModel)
    );
  }

  /**
   * @param personnelAreaId undefined
   * @return Success
   */
  DeletePersonnelAreaResponse(personnelAreaId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PersonnelArea/${encodeURIComponent(String(personnelAreaId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param personnelAreaId undefined
   * @return Success
   */
  DeletePersonnelArea(personnelAreaId: number): __Observable<string> {
    return this.DeletePersonnelAreaResponse(personnelAreaId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `PersonnelAreaService.GetPersonnelAreaTreeNodeParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `asOfDate`:
   *
   * @return Success
   */
  GetPersonnelAreaTreeNodeResponse(params: PersonnelAreaService.GetPersonnelAreaTreeNodeParams): __Observable<__StrictHttpResponse<Array<PersonnelAreaTreeNodeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.companyId != null) __params = __params.set('companyId', params.companyId.toString());
    if (params.asOfDate != null) __params = __params.set('asOfDate', params.asOfDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PersonnelArea/personnelAreaTreeNode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonnelAreaTreeNodeModel>>;
      })
    );
  }
  /**
   * @param params The `PersonnelAreaService.GetPersonnelAreaTreeNodeParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `asOfDate`:
   *
   * @return Success
   */
  GetPersonnelAreaTreeNode(params: PersonnelAreaService.GetPersonnelAreaTreeNodeParams): __Observable<Array<PersonnelAreaTreeNodeModel>> {
    return this.GetPersonnelAreaTreeNodeResponse(params).pipe(
      __map(_r => _r.body as Array<PersonnelAreaTreeNodeModel>)
    );
  }
}

module PersonnelAreaService {

  /**
   * Parameters for GetPersonnelAreasList
   */
  export interface GetPersonnelAreasListParams {
    searchDate?: string;
    companyId?: number;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for GetPersonnelAreaTreeNode
   */
  export interface GetPersonnelAreaTreeNodeParams {
    companyId?: number;
    asOfDate?: string;
  }
}

export { PersonnelAreaService }
