import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyTemplateModule } from '../formly-template/formly-template.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FormlyModule } from '@ngx-formly/core';
import { PrimeNgComponentsModule } from './prime-ng-components.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AgGridModule } from 'ag-grid-angular';
import { PipeModule } from 'pipes/pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FormlyTemplateModule,
  NgxPermissionsModule,
  FormlyPrimeNGModule,
  SweetAlert2Module,
  FormlyModule,
  PrimeNgComponentsModule,
  PerfectScrollbarModule,
  AgGridModule,
  PipeModule,
  TranslateModule
];

const components = [];

@NgModule({
  declarations: components,
  imports: [...modules],
  exports: [...modules, ...components],
  providers: [DialogService]
})
export class SharedModule {}
