/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BenefitElementModelPaginationResult } from '../models/benefit-element-model-pagination-result';
import { BenefitElementModel } from '../models/benefit-element-model';
@Injectable({
  providedIn: 'root',
})
class BenefitElementService extends __BaseService {
  static readonly GetBenefitElementListPath = '/api/BenefitElement';
  static readonly CreateBenefitElementPath = '/api/BenefitElement';
  static readonly UpdateBenefitElementPath = '/api/BenefitElement';
  static readonly GetBenefitElementByIdPath = '/api/BenefitElement/{benefitElementId}';
  static readonly DeleteBenefitElementPath = '/api/BenefitElement/{benefitElementId}';
  static readonly GetSickLeaveElementPath = '/api/BenefitElement/leave/sickLeave';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BenefitElementService.GetBenefitElementListParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `BenefitQuotaTypeId`:
   *
   * @return Success
   */
  GetBenefitElementListResponse(params: BenefitElementService.GetBenefitElementListParams): __Observable<__StrictHttpResponse<BenefitElementModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.BenefitQuotaTypeId != null) __params = __params.set('BenefitQuotaTypeId', params.BenefitQuotaTypeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BenefitElement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BenefitElementModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `BenefitElementService.GetBenefitElementListParams` containing the following parameters:
   *
   * - `Status`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `BenefitQuotaTypeId`:
   *
   * @return Success
   */
  GetBenefitElementList(params: BenefitElementService.GetBenefitElementListParams): __Observable<BenefitElementModelPaginationResult> {
    return this.GetBenefitElementListResponse(params).pipe(
      __map(_r => _r.body as BenefitElementModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateBenefitElementResponse(body?: BenefitElementModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BenefitElement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateBenefitElement(body?: BenefitElementModel): __Observable<string> {
    return this.CreateBenefitElementResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateBenefitElementResponse(body?: BenefitElementModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/BenefitElement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateBenefitElement(body?: BenefitElementModel): __Observable<string> {
    return this.UpdateBenefitElementResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param benefitElementId undefined
   * @return Success
   */
  GetBenefitElementByIdResponse(benefitElementId: number): __Observable<__StrictHttpResponse<BenefitElementModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BenefitElement/${encodeURIComponent(String(benefitElementId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BenefitElementModel>;
      })
    );
  }
  /**
   * @param benefitElementId undefined
   * @return Success
   */
  GetBenefitElementById(benefitElementId: number): __Observable<BenefitElementModel> {
    return this.GetBenefitElementByIdResponse(benefitElementId).pipe(
      __map(_r => _r.body as BenefitElementModel)
    );
  }

  /**
   * @param benefitElementId undefined
   * @return Success
   */
  DeleteBenefitElementResponse(benefitElementId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/BenefitElement/${encodeURIComponent(String(benefitElementId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param benefitElementId undefined
   * @return Success
   */
  DeleteBenefitElement(benefitElementId: number): __Observable<string> {
    return this.DeleteBenefitElementResponse(benefitElementId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetSickLeaveElementResponse(): __Observable<__StrictHttpResponse<BenefitElementModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/BenefitElement/leave/sickLeave`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BenefitElementModel>;
      })
    );
  }
  /**
   * @return Success
   */
  GetSickLeaveElement(): __Observable<BenefitElementModel> {
    return this.GetSickLeaveElementResponse().pipe(
      __map(_r => _r.body as BenefitElementModel)
    );
  }
}

module BenefitElementService {

  /**
   * Parameters for GetBenefitElementList
   */
  export interface GetBenefitElementListParams {
    Status?: any;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    BenefitQuotaTypeId?: any;
  }
}

export { BenefitElementService }
