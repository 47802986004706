import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppMenuItem, AppMenuService } from 'services/app-menu.service';
import { ApplicationSettingsExtended } from '../../api/models';
import { ApplicationSettingsService } from '../../services/application-settings.service';
import { ViewUtilsService } from '../../services/view-utils.service';

export interface MenuIconItem extends AppMenuItem {
  text?: string;
}

@Component({
  selector: 'app-menu-icon',
  templateUrl: './menu-icon.component.html',
  styleUrls: ['./menu-icon.component.css'],
})
export class MenuIconComponent implements OnInit {
  @Input() menuItems: MenuIconItem[];
  @Input() router: Router;
  itemHover = '';
  appSettings: ApplicationSettingsExtended;

  constructor(
    public viewUtil: ViewUtilsService,
    public appMenuService: AppMenuService,
    private appSettingsService: ApplicationSettingsService
    ) {}

 async ngOnInit() {
  this.appSettings = await this.appSettingsService.getApplicationSettings().toPromise();
 }

  onIconClick(itemClicked: MenuIconItem) {
    if (this.router && itemClicked.routerLink) {
      this.router.navigate(itemClicked.routerLink);
    }
  }

  getText(item: MenuIconItem) {
    if (item.text) {
      return item.text;
    }
    if (item.label) {
      return item.label;
    }
    return '-*-';
  }
}
