import { FormGroup, FormArray } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

export function assignObject(oldObj, newObj)
{
  Object.keys(newObj).forEach(key => key in oldObj? oldObj[key] = newObj[key] : null);
}
export function formlyDefaultTransform(formName: string) {
  return (fields: FormlyFieldConfig[], model: any, form: FormGroup | FormArray, options: FormlyFormOptions) => {
    const transformFn = (fields: FormlyFieldConfig[]) => {
      fields.forEach(field => {
        if (field.key) {
          field.id = formName + field.key;
        }
        if (field?.fieldArray?.fieldGroup) {
          transformFn(field?.fieldArray?.fieldGroup);
        }
        if (field?.fieldGroup) {
          transformFn(field?.fieldGroup);
        }
      });
    };
    transformFn(fields);
    // console.log(fields);
    return fields;
  };
}

export class AppPlatform {
  public static platform: string = 'Web';
}
