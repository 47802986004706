/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LocationModelPaginationResult } from '../models/location-model-pagination-result';
import { LocationModel } from '../models/location-model';
@Injectable({
  providedIn: 'root',
})
class LocationService extends __BaseService {
  static readonly GetLocationsListPath = '/api/Location';
  static readonly CreateLocationPath = '/api/Location';
  static readonly GetLocationByIdPath = '/api/Location/{LocationId}';
  static readonly UpdateLocationPath = '/api/Location/{LocationId}';
  static readonly DeleteLocationPath = '/api/Location/{LocationId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `LocationService.GetLocationsListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetLocationsListResponse(params: LocationService.GetLocationsListParams): __Observable<__StrictHttpResponse<LocationModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Location`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocationModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `LocationService.GetLocationsListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetLocationsList(params: LocationService.GetLocationsListParams): __Observable<LocationModelPaginationResult> {
    return this.GetLocationsListResponse(params).pipe(
      __map(_r => _r.body as LocationModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateLocationResponse(body?: LocationModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Location`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateLocation(body?: LocationModel): __Observable<string> {
    return this.CreateLocationResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param LocationId undefined
   * @return Success
   */
  GetLocationByIdResponse(LocationId: number): __Observable<__StrictHttpResponse<LocationModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Location/${encodeURIComponent(String(LocationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocationModel>;
      })
    );
  }
  /**
   * @param LocationId undefined
   * @return Success
   */
  GetLocationById(LocationId: number): __Observable<LocationModel> {
    return this.GetLocationByIdResponse(LocationId).pipe(
      __map(_r => _r.body as LocationModel)
    );
  }

  /**
   * @param params The `LocationService.UpdateLocationParams` containing the following parameters:
   *
   * - `LocationId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateLocationResponse(params: LocationService.UpdateLocationParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Location/${encodeURIComponent(String(params.LocationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `LocationService.UpdateLocationParams` containing the following parameters:
   *
   * - `LocationId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateLocation(params: LocationService.UpdateLocationParams): __Observable<string> {
    return this.UpdateLocationResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param LocationId undefined
   * @return Success
   */
  DeleteLocationResponse(LocationId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Location/${encodeURIComponent(String(LocationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param LocationId undefined
   * @return Success
   */
  DeleteLocation(LocationId: number): __Observable<string> {
    return this.DeleteLocationResponse(LocationId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module LocationService {

  /**
   * Parameters for GetLocationsList
   */
  export interface GetLocationsListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for UpdateLocation
   */
  export interface UpdateLocationParams {
    LocationId: number;
    body?: LocationModel;
  }
}

export { LocationService }
