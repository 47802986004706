import { Component, OnInit } from '@angular/core';
import { FieldWrapper, FieldType } from '@ngx-formly/core';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-formly-primeng-input',
  templateUrl: './formly-primeng-input.component.html',
  styleUrls: ['./formly-primeng-input.component.css'],
})
export class FormlyPrimengInputComponent extends FieldType implements OnInit {
  ngOnInit(): void {
    if (this.to.required) {
      this.formControl.valueChanges.pipe(debounceTime(1000), filter(a => a != null)).subscribe((a) => {
        if (typeof a === 'string') {
          this.formControl.setValue(a.trim());
        }
      });
    }
    
    if (this.to.min) {
      this.formControl.valueChanges.pipe(debounceTime(1000), filter(a => a != null)).subscribe((a) => {
        if (typeof a === 'number') {
          if (a < this.to.min) {
            this.formControl.setValue(this.to.min);
          }
        }
      });
    }
  }
}
