/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FilterSettingModel } from '../models/filter-setting-model';
import { FilterSettingKey } from '../models/filter-setting-key';
@Injectable({
  providedIn: 'root',
})
class FilterSettingService extends __BaseService {
  static readonly GetFilterSettingListPath = '/api/FilterSetting/list';
  static readonly UpsertFilterSettingPath = '/api/FilterSetting';
  static readonly DeleteFilterSettingPath = '/api/FilterSetting';
  static readonly UpsertLastFilterSettingPath = '/api/FilterSetting/last';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `FilterSettingService.GetFilterSettingListParams` containing the following parameters:
   *
   * - `UserId`:
   *
   * - `PageUrl`:
   *
   * - `Name`:
   *
   * @return Success
   */
  GetFilterSettingListResponse(params: FilterSettingService.GetFilterSettingListParams): __Observable<__StrictHttpResponse<Array<FilterSettingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UserId != null) __params = __params.set('UserId', params.UserId.toString());
    if (params.PageUrl != null) __params = __params.set('PageUrl', params.PageUrl.toString());
    if (params.Name != null) __params = __params.set('Name', params.Name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/FilterSetting/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FilterSettingModel>>;
      })
    );
  }
  /**
   * @param params The `FilterSettingService.GetFilterSettingListParams` containing the following parameters:
   *
   * - `UserId`:
   *
   * - `PageUrl`:
   *
   * - `Name`:
   *
   * @return Success
   */
  GetFilterSettingList(params: FilterSettingService.GetFilterSettingListParams): __Observable<Array<FilterSettingModel>> {
    return this.GetFilterSettingListResponse(params).pipe(
      __map(_r => _r.body as Array<FilterSettingModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpsertFilterSettingResponse(body?: FilterSettingModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/FilterSetting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpsertFilterSetting(body?: FilterSettingModel): __Observable<string> {
    return this.UpsertFilterSettingResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  DeleteFilterSettingResponse(body?: FilterSettingKey): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/FilterSetting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  DeleteFilterSetting(body?: FilterSettingKey): __Observable<string> {
    return this.DeleteFilterSettingResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpsertLastFilterSettingResponse(body?: FilterSettingModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/FilterSetting/last`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpsertLastFilterSetting(body?: FilterSettingModel): __Observable<string> {
    return this.UpsertLastFilterSettingResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module FilterSettingService {

  /**
   * Parameters for GetFilterSettingList
   */
  export interface GetFilterSettingListParams {
    UserId?: number;
    PageUrl?: string;
    Name?: string;
  }
}

export { FilterSettingService }
