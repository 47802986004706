/* tslint:disable */
type ECompetencyType =
  'Core' |
  'Functional' |
  'Managerial';
module ECompetencyType {
  export const CORE: ECompetencyType = 'Core';
  export const FUNCTIONAL: ECompetencyType = 'Functional';
  export const MANAGERIAL: ECompetencyType = 'Managerial';
  export function values(): ECompetencyType[] {
    return [
      CORE,
      FUNCTIONAL,
      MANAGERIAL
    ];
  }
}

export { ECompetencyType }