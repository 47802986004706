/* tslint:disable */
type EBenefitQuotaType =
  'Leave' |
  'Loan' |
  'PVD';
module EBenefitQuotaType {
  export const LEAVE: EBenefitQuotaType = 'Leave';
  export const LOAN: EBenefitQuotaType = 'Loan';
  export const PVD: EBenefitQuotaType = 'PVD';
  export function values(): EBenefitQuotaType[] {
    return [
      LEAVE,
      LOAN,
      PVD
    ];
  }
}

export { EBenefitQuotaType }