<div class="grid step-actor">
  <!-- <div
    *ngIf="to.label && to.hideLabel !== true"
    [class]="to.labelClass"
    [ngStyle]="to.labelStyle || { 'text-align': 'right', 'padding-top': '14px' }"
  >
    <label>
      {{ to.label }}
      <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
    </label>
  </div> -->
  <div class="col-12">
    <ng-container #fieldComponent></ng-container>

    <!-- <div class="p-message ui-widget ui-corner-all p-message-error p-messages-error" *ngIf="showError">
      <formly-validation-message class="p-message-text" [field]="field"></formly-validation-message>
    </div> -->
  </div>
</div>
