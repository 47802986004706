import { Component, EventEmitter, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { PositionModel } from 'app/api/models';
import { ModalPositionSearchComponent } from 'modals/core/modal-position-search/modal-position-search.component';

@Component({
  selector: 'app-formly-repeat-select-employee-section',
  templateUrl: './formly-repeat-select-employee-section.component.html',
  styleUrls: ['./formly-repeat-select-employee-section.component.css'],
  providers: [DialogService],
})
export class FormlyRepeatSelectEmployeeSectionComponent extends FieldArrayType implements OnInit {
  ref: DynamicDialogRef;
  companyId: number;

  constructor(private dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.subCompanyChange();
  }

  showEmployeeSearchModal() {
    this.ref = this.dialogService.open(ModalPositionSearchComponent, {
      header: 'ค้นหาตำแหน่งงาน',
      width: '90%',
      contentStyle: { overflow: 'auto' },
      data: {
        companyId: this.companyId,
      },
    });

    this.ref.onClose.pipe(map(res => res as PositionModel)).subscribe(data => {
      if (data) {
        if (!this.isExistInModel(data)) {
          this.add(undefined, data);
        }
      }
    });
  }

  private isExistInModel(position: PositionModel): boolean {
    const idx = this.model.findIndex(pos => {
      return (pos as PositionModel).positionId === position.positionId;
    });

    return idx !== -1;
  }

  subCompanyChange() {
    if (this.to?.companyChange instanceof EventEmitter) {
      this.to.companyChange.subscribe(companyId => {
        this.companyId = companyId;
      });
    }
  }
}
