import { Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig, FieldType, FieldWrapper, } from '@ngx-formly/core';
import { ShiftService } from 'app/api/services';
import { FormGroup } from '@angular/forms';
import { AllowanceTypeShiftModel } from 'app/api/models/allowance-type-shift-model';
import { debounceTime, filter, startWith } from 'rxjs/operators';
import { OptionListRepo } from 'app/repo/option-list.repo';

interface ShiftInAllowanceForm extends FormlyFieldConfig {
  key?: keyof AllowanceTypeShiftModel;
  fieldGroup?: ShiftInAllowanceForm[];
}

@Component({
  selector: 'app-select-shift',
  templateUrl: './select-shift.component.html',
  styleUrls: ['./select-shift.component.css']
})
export class SelectShiftComponent extends FieldArrayType {
  fields: ShiftInAllowanceForm[] = [
    {
      fieldGroupClassName: 'grid p-mb-d2',
      fieldGroup: [
        {
          key: 'shiftId',
          type: 'filter-dropdown',
          className: 'col-7',
          templateOptions: {
            inputClass: 'col-6 col-offset-1',
            options: this.optionListRepo.getCachedActiveShiftOptionList(),
            required: true,
          },
          hooks: {
            onInit: (field) => {
              field.formControl.valueChanges.pipe(
                startWith(field.formControl.value),
                debounceTime(300),
                filter(shiftId => shiftId != null)).subscribe(shiftId => {
                  this.shiftService.GetShiftById(shiftId).subscribe(shift => {
                    const isHasSubShift = shift.shiftDefinitionType === 'AutoMatchAbsentQuota';
                    const subShfitfield = this.fields[0].fieldGroup[1];
                    subShfitfield.templateOptions.disabled = !isHasSubShift;
                    subShfitfield.templateOptions.options = [];

                    if (isHasSubShift) {
                      const subShiftList: { value: number; label: string }[] = [{ value: 1, label: shift.timeName }];
                      if (shift.timeName2) {
                        subShiftList.push({ value: 2, label: shift.timeName2 });
                      }
                      if (shift.timeName3) {
                        subShiftList.push({ value: 3, label: shift.timeName3 });
                      }

                      subShfitfield.templateOptions.options = subShiftList;
                    }
                  });
                });
            },
          }
        },
        {
          key: 'subShiftNumber',
          type: 'filter-dropdown',
          className: 'col-5',
          templateOptions: {
            inputClass: 'col-6 col-offset-1',
            disabled: true,
            required: true,
            options: [],
          },
        },
      ]
    },
  ];

  constructor(private shiftService: ShiftService, private optionListRepo: OptionListRepo) {
    super();
  }
}
