<p-table [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="shiftdetailList" [autoLayout]="true" [loading]="loading">
    <ng-template pTemplate="header">
      <tr>
        <th class="!tw-text-center">{{ 'ลำดับ' | translate }}</th>
        <th class="!tw-text-center">{{ 'วันที่' | translate }}</th>
        <th class="!tw-text-center">{{ 'เป็นวัน' | translate }}</th>
        <th class="!tw-text-center">{{ 'ประเภทวัน' | translate }}</th>
        <th class="!tw-text-center">{{ 'เวลาเริ่มต้น' | translate }}</th>
        <th class="!tw-text-center">{{ 'เวลาสิ้นสุด' | translate }}</th>
        <th class="!tw-text-center">{{ 'เวลาเริ่มพัก' | translate }}</th>
        <th class="!tw-text-center">{{ 'เวลาสิ้นสุดพัก' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-shiftdetail let-rowIndex="rowIndex">
      <tr>
        <td class="!tw-text-center">{{ shiftdetail.order }}</td>
        <td class="!tw-text-center">{{ shiftdetail.exampleDate }}</td>
        <td class="!tw-text-center">{{ shiftdetail.dayname }}</td>
        <td class="!tw-text-center">{{ shiftdetail.isnameholiday }}</td>
        <td class="!tw-text-center">{{ shiftdetail.startTimeDiff }}</td>
        <td class="!tw-text-center">{{ shiftdetail.endTimeDiff }}</td>
        <td class="!tw-text-center">{{ shiftdetail.breakStartDiff }}</td>
        <td class="!tw-text-center">{{ shiftdetail.breakEndDiff }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7" class="emptymessage">{{ 'no_data' | translate }}</td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-dialog-footer">
    <button type="button" pButton class="p-button-secondary" label="{{'close' | translate}}" (click)="close()"></button>
  </div>
