import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Location } from '@angular/common'
import { AppBreadcrumbService } from 'app/layout/layout-default/app-breadcrumb/app-breadcrumb.service';
import {
  EmployeeCreateFormPersonalInfoComponent,
  EmployeePersonalInfoCreateModel,
} from './employee-create-form-personal-info/employee-create-form-personal-info.component';
import { EmployeeCreateFormFamilyInfoComponent } from './employee-create-form-family-info/employee-create-form-family-info.component';
import { EmployeeCreateFormExperienceComponent } from './employee-create-form-experience/employee-create-form-experience.component';
// tslint:disable-next-line: max-line-length
import {
  EmployeeCreateFormEmploymentInformationComponent,
  EmployeeEmploymentCreateModel,
} from './employee-create-form-employment-information/employee-create-form-employment-information.component';
import { EmployeeCreateFormEmergencyComponent } from './employee-create-form-emergency/employee-create-form-emergency.component';
import { EmployeeCreateFormEducationComponent } from './employee-create-form-education/employee-create-form-education.component';
import { EmployeeCreateFormChildComponent } from './employee-create-form-child/employee-create-form-child.component';
import { EmployeeCreateFormAddressInfoComponent } from './employee-create-form-address-info/employee-create-form-address-info.component';
import { EmployeeCreateFormOtherInfoComponent } from './employee-create-form-other-info/employee-create-form-other-info.component';
import {
  CreateEmployeeModel,
  EGender,
  EmployeeEmergencyContactModel,
  EmployeeCardModel,
  EmployeeRelativeModel,
  EmployeeAddressModel,
  EmployeeDefModel,
  EmployeeEducationModel,
  EmployeeInPositionModel,
  EmployeeLanguageModel,
  EmployeeNameModel,
  EmployeePastEmploymentModel,
  EmployeePhysicalMeasurementModel,
  EmployeeWorkPermitModel,
  ESpouseIncomeType,
  EmployeeBankAccountModel,
  EmployeeSalaryModel,
  DocumentOfEmployeeModel,
  EmployeeSkillModel,
  ERelativeInfoStatus,
  UserDataDetailModel,
  ApplicationSettingsExtended,
} from 'app/api/models';
import { ApprovalCenterService, EmployeeService, NewEmployeeService } from 'app/api/services';
import { EmployeeContactModel } from 'app/api/models';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { EmployeeCreateFormAttachFileComponent } from './employee-create-form-attach-file/employee-create-form-attach-file.component';
import { HttpErrorResponse } from '@angular/common/http';
import { distinctUntilChanged, debounceTime, startWith } from 'rxjs/operators';
import { ViewUtilsService } from '../../../../../services/view-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { ApplicationSettingsService } from '../../../../../services/application-settings.service';

interface GeneralInfoModel {
  c_Picture: string;
  imageDocumentId?: number;
  genderId: EGender;
  employeeName: EmployeeNameModel;
  nickNameTh: string;
  nickNameEn: string;
  birthday: string;
  religionId: string;
  raceId: string;
  nationalityId: string;
  bloodGroupId: string;
  maritalStatusId: string;
  militaryStatusId: string;
  weight?: number;
  height?: number;
  employeeCard: EmployeeCardModel;
  employeePhysicalMeasurement: EmployeePhysicalMeasurementModel;
  employeeRegisteredAddress: EmployeeAddressModel;
  employeeCurrentAddress: EmployeeAddressModel;
  facebookAccount: EmployeeContactModel;
  homeTelephone: EmployeeContactModel;
  igAccount: EmployeeContactModel;
  lineID: EmployeeContactModel;
  mobile: EmployeeContactModel;
  twitterAccount: EmployeeContactModel;
  workEmail: EmployeeContactModel;
  workTelephone: EmployeeContactModel;
  personalEmail?: EmployeeContactModel;
}

interface FamilyInfoModel {
  parentMaritalStatusId: string;
  isFatherAlive: ERelativeInfoStatus;
  employeeFather?: EmployeeRelativeModel;
  isMotherAlive: ERelativeInfoStatus;
  employeeMother?: EmployeeRelativeModel;
  spouseIncomeType?: ESpouseIncomeType;
  employeeSpouse?: EmployeeRelativeModel;
  employeeChildren: Array<EmployeeRelativeModel>;
  childCount: number;
  siblingCount: number;
  siblingIndex: number;
  emergencyContact: EmployeeEmergencyContactModel;
}

interface EducationInfoModel {
  employeeEducations: Array<EmployeeEducationModel>;
  employeeLanguages: Array<EmployeeLanguageModel>;
  driveCarSkill: EmployeeSkillModel;
  driveMotorcySkill: EmployeeSkillModel;
  typingSkill: EmployeeSkillModel;
}

interface PastEmploymentInfoModel {
  employeePastEmployments?: Array<EmployeePastEmploymentModel>;
}

interface EmploymentInfoModel {
  employeeCode: string;
  employeeDef: EmployeeDefModel;
  branchId?: number;
  employeeInPosition: EmployeeInPositionModel;
  employeeWorkPermit: EmployeeWorkPermitModel;
  employeeDate: string;
  probationDate: string;
  email: string;
  hasSocialSecurity: boolean;
  isDeductedSocialSecurity: boolean;
  payChannelId: string;
  hospitalId: string;
  employeeSalary: EmployeeSalaryModel;
  employeeBankAccount: EmployeeBankAccountModel;
}

@Component({
  selector: 'app-employee-create',
  templateUrl: './employee-create.component.html',
  styleUrls: ['./employee-create.component.css'],
})
export class EmployeeCreateComponent implements OnInit, AfterViewInit {
  @Input() isFromExternalRequest = false;
  @Input() isFromApprovalCenter = false;
  @Input() defaultUserDataModel: UserDataDetailModel = {};
  @Input() requestId: number;

  @ViewChild(EmployeeCreateFormPersonalInfoComponent) personalInfo: EmployeeCreateFormPersonalInfoComponent;
  @ViewChild(EmployeeCreateFormAddressInfoComponent) addressInfo: EmployeeCreateFormAddressInfoComponent;
  @ViewChild(EmployeeCreateFormFamilyInfoComponent) familyInfo: EmployeeCreateFormFamilyInfoComponent;
  @ViewChild(EmployeeCreateFormChildComponent) childInfo: EmployeeCreateFormChildComponent;
  @ViewChild(EmployeeCreateFormEmergencyComponent) emergencyInfo: EmployeeCreateFormEmergencyComponent;
  @ViewChild(EmployeeCreateFormEducationComponent) educationInfo: EmployeeCreateFormEducationComponent;
  @ViewChild(EmployeeCreateFormExperienceComponent) experienceInfo: EmployeeCreateFormExperienceComponent;
  @ViewChild(EmployeeCreateFormOtherInfoComponent) otherInfo: EmployeeCreateFormOtherInfoComponent;
  @ViewChild(EmployeeCreateFormAttachFileComponent) attachFileInfo: EmployeeCreateFormAttachFileComponent;
  @ViewChild(EmployeeCreateFormEmploymentInformationComponent)
  employmentInfo: EmployeeCreateFormEmploymentInformationComponent;

  isInvalidPersonalInfoForm = true;
  isInvalidAddressInfoForm = false;
  isInvalidFamilyInfoForm = false;
  isInvalidChildInfoForm = false;
  isInvalidEmergencyInfoForm = true;
  isInvalidEducationInfoForm = false;
  isInvalidExperienceInfoForm = false;
  isInvalidOtherInfoForm = true;
  isInvalidEmploymentInfoForm = true;
  activeTabIndex = 0;
  lastActiveTabIndex = 0;
  countTab = 7;
  cnt = 0;

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  defaultLoading = true;

  companyOption: { label: string; value: number }[] = [];
  selectedCompany: number;
  appSettings: ApplicationSettingsExtended;

  // Original Case
  get isAdminEmployeeCreate() {
    return !this.isFromApprovalCenter && !this.isFromExternalRequest;
  }

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    private notificationService: AppNotificationServiceService,
    private employeeService: EmployeeService,
    public viewUtil: ViewUtilsService,
    private newEmployeeService: NewEmployeeService,
    private router: Router,
    private route: ActivatedRoute,
    private optionListRepo: OptionListRepo,
    private approvalCenterService: ApprovalCenterService,
    private appSettingsService: ApplicationSettingsService,
    private location: Location
  ) { }

  async ngOnInit() {
    this.appSettings = await this.appSettingsService.getApplicationSettings().toPromise();
    this.initBreadcrumb();

    if (this.isFromApprovalCenter) {
      this.activeTabIndex = 6;
    }
    // setTimeout(() => {this.defaultPersonalInfo.employeePhysicalMeasurementModel = {
    //   weight: 9999
    // };
    // this.defaultLoading = true;  this.subscribeFormStatus(); }, 5000)

    // this.defaultPersonalInfo.employeePhysicalMeasurementModel = {
    //   weight: 9999
    // };
    // this.defaultLoading = true;

    if (this.isFromExternalRequest) {
      this.setCompanyOption();
    }

    console.log(this.currentUser);
  }
  goBack() {
    this.location.back();
  }
  private setCompanyOption() {
    this.optionListRepo
      .getCompanyOptionList({})
      .toPromise()
      .then(val => {
        val.forEach(com => {
          this.companyOption.push({ label: com.label.toString(), value: +com.value });
        });
        this.selectedCompany = this.companyOption.length ? this.companyOption[0].value : undefined;
      });
  }

  ngAfterViewInit() {
    this.subscribeFormStatus();
  }

  initBreadcrumb() {
    this.breadcrumbService.setItems([
      { label: 'หน้าแรก', routerLink: '/admin' },
      { label: 'ส่วนหลัก', routerLink: '/admin/core' },
      { label: 'รายชื่อพนักงานทั้งหมด', routerLink: '/admin/core/employee/list' },
      { label: 'เพิ่มพนักงานใหม่' },
    ]);
  }

  subscribeFormStatus() {
    this.personalInfo.form.statusChanges
      .pipe(startWith(this.personalInfo.form.status), distinctUntilChanged())
      .subscribe(status => {
        this.isInvalidPersonalInfoForm = status === 'INVALID';
        console.log(this.cnt++, 'PersonalInfo', this.isInvalidPersonalInfoForm ? 'invalid' : 'valid');
      });
    this.addressInfo.form.statusChanges
      .pipe(startWith(this.addressInfo.form.status), distinctUntilChanged())
      .subscribe(status => {
        this.isInvalidAddressInfoForm = status === 'INVALID';
        console.log(this.cnt++, 'AddressInfoForm', this.isInvalidAddressInfoForm ? 'invalid' : 'valid');
      });
    this.familyInfo.form.statusChanges
      .pipe(startWith(this.familyInfo.form.status), distinctUntilChanged())
      .subscribe(status => {
        this.isInvalidFamilyInfoForm = status === 'INVALID';
        console.log(this.cnt++, 'FamilyInfoForm', this.isInvalidFamilyInfoForm ? 'invalid' : 'valid');
      });
    this.childInfo.form.statusChanges
      .pipe(startWith(this.childInfo.form.status), distinctUntilChanged())
      .subscribe(status => {
        this.isInvalidChildInfoForm = status === 'INVALID';
        console.log(this.cnt++, 'ChildInfoForm', this.isInvalidChildInfoForm ? 'invalid' : 'valid');
      });
    this.emergencyInfo.form.statusChanges
      .pipe(startWith(this.emergencyInfo.form.status), distinctUntilChanged())
      .subscribe(status => {
        this.isInvalidEmergencyInfoForm = status === 'INVALID';
        console.log(this.cnt++, 'EmergencyInfoForm', this.isInvalidEmergencyInfoForm ? 'invalid' : 'valid');
      });
    this.educationInfo.form.statusChanges
      .pipe(startWith(this.educationInfo.form.status), distinctUntilChanged())
      .subscribe(status => {
        this.isInvalidEducationInfoForm = status === 'INVALID';
        console.log(this.cnt++, 'EducationInfoForm', this.isInvalidEducationInfoForm ? 'invalid' : 'valid');
      });
    this.experienceInfo.form.statusChanges
      .pipe(startWith(this.experienceInfo.form.status), distinctUntilChanged())
      .subscribe(status => {
        this.isInvalidExperienceInfoForm = status === 'INVALID';
        console.log(this.cnt++, 'ExperienceInfoForm', this.isInvalidExperienceInfoForm ? 'invalid' : 'valid');
      });
    this.otherInfo.form.statusChanges
      .pipe(startWith(this.otherInfo.form.status), distinctUntilChanged())
      .subscribe(status => {
        this.isInvalidOtherInfoForm = status === 'INVALID';

        console.log(this.cnt++, 'OtherInfoForm', this.isInvalidOtherInfoForm ? 'invalid' : 'valid');
      });

    if (!this.isFromExternalRequest) {
      this.employmentInfo.form.statusChanges
        .pipe(startWith(this.employmentInfo.form.status), distinctUntilChanged())
        .subscribe(status => {
          this.isInvalidEmploymentInfoForm = status === 'INVALID';
          console.log(this.cnt++, 'EmploymentInfoForm', this.isInvalidEmploymentInfoForm ? 'invalid' : 'valid');
        });
    }


  }

  CheckEducationInfoChanges() {
    console.log(this.educationInfo.model.employeeEducations.length);
    if (this.educationInfo.form.invalid) {
      console.log("this.educationInfo.form.invalid " + this.educationInfo.form.invalid);
      this.isInvalidEducationInfoForm = true;
    } else {
      console.log("!this.educationInfo.form.invalid " + +this.educationInfo.form.invalid);
      this.isInvalidEducationInfoForm = false;
    }
  }

  canMoveTab(targetTab: number): boolean {
    const tab = targetTab;

    if (tab > 0 && (this.isInvalidPersonalInfoForm || this.isInvalidAddressInfoForm)) {
      console.log(0, this.isInvalidPersonalInfoForm, this.isInvalidAddressInfoForm);
      return false;
    }

    if (tab > 1 && (this.isInvalidFamilyInfoForm || this.isInvalidChildInfoForm || this.isInvalidEmergencyInfoForm)) {
      console.log(1, this.isInvalidFamilyInfoForm, this.isInvalidChildInfoForm, this.isInvalidEmergencyInfoForm);
      return false;
    }

    if (tab > 2 && this.isInvalidEducationInfoForm) {
      console.log(2, this.isInvalidEducationInfoForm);
      return false;
    }

    if (tab > 3 && this.isInvalidExperienceInfoForm) {
      console.log(3, this.isInvalidExperienceInfoForm);
      return false;
    }

    if (tab > 4 && this.isInvalidOtherInfoForm) {
      console.log(4, this.isInvalidOtherInfoForm);
      return false;
    }
    return true;
  }

  onTabChange(e: any) {
    const tabIndex = +e.index;
    const isMoveForward = tabIndex > this.lastActiveTabIndex;

    if (isMoveForward && !this.canMoveTab(tabIndex)) {
      setTimeout(() => {
        this.notificationService.error('กรุณาใส่ข้อมูลที่จำเป็นให้ครบ', '');
        this.activeTabIndex = this.lastActiveTabIndex;
      }, 0);
    } else {
      this.lastActiveTabIndex = tabIndex;
    }
  }

  onOpenNextTabBtnClick() {
    if (!this.canMoveTab(this.activeTabIndex + 1)) {
      this.notificationService.error('กรุณาใส่ข้อมูลที่จำเป็นให้ครบ', '');
      return;
    }

    this.activeTabIndex = this.activeTabIndex === this.countTab ? 0 : this.activeTabIndex + 1;
    this.lastActiveTabIndex = this.activeTabIndex;
  }

  onOpenPrevTabBtnClick() {
    this.activeTabIndex = this.activeTabIndex === 0 ? this.countTab : this.activeTabIndex - 1;
  }

  onSaveDraft() {
    this.createEmployee();
  }

  onCreateEmployeeBtnClick() {
    const personalInfo = this.personalInfo.model;
    const addressInfo = this.addressInfo.model;
    const familyInfo = this.familyInfo.model;
    const childInfo = this.childInfo.model;
    const emergencyInfo = this.emergencyInfo.model;
    const educationInfo = this.educationInfo.model;
    const experienceInfo = this.experienceInfo.model;
    const otherInfo = this.otherInfo.model;
    const employmentInfo = this.employmentInfo.model;
    // this.CheckEducationInfoChanges();
    // if (this.isInvalidEducationInfoForm) {
    //   this.notificationService.error('กรุณาเพิ่ม ข้อมูลประวัติการศึกษา หรือ ความสามารถทางภาษา', '');
    //   return;
    // }

    this.notificationService
      .confirm('บันทึกพนักงาน', 'กรุณากด "ยืนยัน" หากต้องการบันทึกพนักงาน และเพิ่มพนักงานเข้าสู่ระบบ')
      .then(res => {
        if (res.isConfirmed) {
          if (this.isFromApprovalCenter) {
            this.createNewEmployeeFromRequest();
          } else {
            this.createEmployee();
          }
        }
      });
  }

  createEmployee() {
    this.employeeService
      .CreateEmployee({
        ...this.buildGeneralInfoData(),
        ...this.buildFamilyInfoData(),
        ...this.buildEducationInfoData(),
        ...this.buildPastEmploymentInfoData(),
        ...this.buildEmploymentInfoData(),
        ...this.buildAttachmentInfoData(),
        ...this.otherInfo.model,
      })
      .subscribe(
        res => {
          this.notificationService.saveCompleted();
          this.resetForm();
          // topadd
          this.router.navigate(['..'], { relativeTo: this.route });
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  sendNewEmployeeForm() {
    const personalInfo = this.personalInfo.model;
    const addressInfo = this.addressInfo.model;
    const familyInfo = this.familyInfo.model;
    const childInfo = this.childInfo.model;
    const emergencyInfo = this.emergencyInfo.model;
    const educationInfo = this.educationInfo.model;
    const experienceInfo = this.experienceInfo.model;
    const otherInfo = this.otherInfo.model;
    const attachmentInfo = this.attachFileInfo.importanceFileList;

    console.log(attachmentInfo);
    // this.CheckEducationInfoChanges();
    // if (this.isInvalidEducationInfoForm) {
    //   this.notificationService.error('กรุณาเพิ่ม ข้อมูลประวัติการศึกษา หรือ ความสามารถทางภาษา', '');
    //   return;
    // }
    this.newEmployeeService
      .CreateNewEmployeeRequest({
        companyId: this.selectedCompany,
        personalInfo,
        addressInfo,
        familyInfo,
        childInfo,
        emergencyInfo,
        educationInfo,
        experienceInfo,
        otherInfo,
        attachmentInfo,
      })
      .subscribe(
        res => {
          this.notificationService.saveCompleted();
          this.router.navigate(['/account/login']);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  createNewEmployeeFromRequest() {
    const model = {
      ...this.buildGeneralInfoData(),
      ...this.buildFamilyInfoData(),
      ...this.buildEducationInfoData(),
      ...this.buildPastEmploymentInfoData(),
      ...this.buildEmploymentInfoData(),
      ...this.buildAttachmentInfoData(),
      ...this.otherInfo.model,
    };
    this.newEmployeeService
      .CreateNewEmployeeFromRequest({
        requestId: this.requestId,
        body: model,
      })
      .subscribe(
        res => {
          this.notificationService.saveCompleted();
          this.router.navigate(['..'], { relativeTo: this.route });
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  private resetForm() {
    this.personalInfo.form.reset();
    this.addressInfo.form.reset();
    this.familyInfo.form.reset();
    this.childInfo.form.reset();
    this.emergencyInfo.form.reset();
    this.educationInfo.form.reset();
    this.experienceInfo.form.reset();
    this.otherInfo.form.reset();
    this.employmentInfo.form.reset();
  }

  private buildGeneralInfoData(): GeneralInfoModel {
    const personalInfo = this.personalInfo.model;
    const addressInfo = this.addressInfo.model;
    let employmentInfo: EmployeeEmploymentCreateModel;

    if (!this.isFromExternalRequest) {
      employmentInfo = this.employmentInfo.model;
    }

    const registeredAddress = {
      address: addressInfo.employeeRegisteredAddress.address,
      district: addressInfo.employeeRegisteredAddress.district,
      subDistrict: addressInfo.employeeRegisteredAddress.subDistrict,
      province: addressInfo.employeeRegisteredAddress.province,
      zipcode: addressInfo.employeeRegisteredAddress.zipcode,
    };

    return {
      c_Picture: personalInfo.c_Picture,
      imageDocumentId: personalInfo.imageDocumentId,
      genderId: personalInfo.genderId,
      employeeName: {
        titleTh: personalInfo.employeeNameModel.titleTh,
        firstnameTh: personalInfo.employeeNameModel.firstnameTh,
        middlenameTh: personalInfo.employeeNameModel.middlenameTh,
        lastnameTh: personalInfo.employeeNameModel.lastnameTh,

        titleEn: personalInfo.employeeNameModel.titleEn,
        lastnameEn: personalInfo.employeeNameModel.lastnameEn,
        firstnameEn: personalInfo.employeeNameModel.firstnameEn,
        middlenameEn: personalInfo.employeeNameModel.middlenameEn,
      },
      nickNameTh: personalInfo.nickNameTh,
      nickNameEn: personalInfo.nickNameEn,
      birthday: personalInfo.birthday,
      religionId: personalInfo.religionId,
      raceId: personalInfo.raceId,
      nationalityId: personalInfo.nationalityId,
      bloodGroupId: personalInfo.bloodGroup,
      maritalStatusId: personalInfo.maritalStatusId,
      employeeCard: {
        cardNumber: personalInfo.cardNumber,
        expireDate: personalInfo.expireDate,
        issuerPlace: personalInfo.issuerPlace,
      },
      militaryStatusId: personalInfo.militaryStatusId,
      height: personalInfo.employeePhysicalMeasurementModel.heigth,
      weight: personalInfo.employeePhysicalMeasurementModel.weight,
      employeePhysicalMeasurement: {
        height: personalInfo.employeePhysicalMeasurementModel.heigth,
        weight: personalInfo.employeePhysicalMeasurementModel.weight,
      },
      employeeRegisteredAddress: registeredAddress,
      employeeCurrentAddress: addressInfo.currentAddressIsTheSameAsHomeAddress
        ? registeredAddress
        : {
          addressTypeId: addressInfo.employeeCurrentAddress.addressTypeId,
          address: addressInfo.employeeCurrentAddress.address,
          district: addressInfo.employeeCurrentAddress.district,
          subDistrict: addressInfo.employeeCurrentAddress.subDistrict,
          province: addressInfo.employeeCurrentAddress.province,
          zipcode: addressInfo.employeeCurrentAddress.zipcode,
        },
      homeTelephone: { value: addressInfo.employeePhone?.homePhone },
      mobile: { value: addressInfo.employeePhone?.mobilePhone },
      facebookAccount: { value: addressInfo.employeePhone?.facebookAccount },
      twitterAccount: { value: addressInfo.employeePhone?.twitterAccount },
      igAccount: { value: addressInfo.employeePhone?.instragramAccount },
      lineID: { value: addressInfo.employeePhone?.lineID },
      personalEmail: { value: addressInfo.employeePhone.personalEmail },
      workEmail: { value: employmentInfo?.email },
      workTelephone: { value: employmentInfo?.internalPhoneNumber },
    };
  }

  private buildFamilyInfoData(): FamilyInfoModel {
    const familyInfo = this.familyInfo.model;
    const childInfo = this.childInfo.model;
    const emergencyInfo = this.emergencyInfo.model;
    const { employeeRegisteredAddress, employeeCurrentAddress, currentAddressIsTheSameAsHomeAddress } =
      this.addressInfo.model;

    const isFatherAddressSameHome = !!familyInfo.employeeFather.fatherAddressIsTheSameAsHomeAddress;
    const isFatherAddressSameCurrent = !!familyInfo.employeeFather.fatherAddressIsTheSameAsCurrentAddress;

    const isMotherAddressSameHome = !!familyInfo.employeeMother.motherAddressIsTheSameAsHomeAddress;
    const isMotherAddressSameCurrent = !!familyInfo.employeeMother.motherAddressIsTheSameAsCurrentAddress;
    const isMotherAddressSameFather = !!familyInfo.employeeMother.motherAddressIsTheSameAsFatherAddress;

    const isSpouseAddressSameHome = !!familyInfo.employeeSpouse.spouseAddressIsTheSameAsHomeAddress;
    const isSpouseAddressSameCurrent = !!familyInfo.employeeSpouse.spouseAddressIsTheSameAsCurrentAddress;

    const currentAddress = currentAddressIsTheSameAsHomeAddress ? employeeRegisteredAddress : employeeCurrentAddress;
    const fatherAddress = {
      address: isFatherAddressSameHome
        ? employeeRegisteredAddress.address
        : isFatherAddressSameCurrent
          ? currentAddress.address
          : familyInfo.employeeFather.address,
      province: isFatherAddressSameHome
        ? employeeRegisteredAddress.province
        : isFatherAddressSameCurrent
          ? currentAddress.province
          : familyInfo.employeeFather.province,
      district: isFatherAddressSameHome
        ? employeeRegisteredAddress.district
        : isFatherAddressSameCurrent
          ? currentAddress.district
          : familyInfo.employeeFather.district,
      subDistrict: isFatherAddressSameHome
        ? employeeRegisteredAddress.subDistrict
        : isFatherAddressSameCurrent
          ? currentAddress.subDistrict
          : familyInfo.employeeFather.subDistrict,
      zipcode: isFatherAddressSameHome
        ? employeeRegisteredAddress.zipcode
        : isFatherAddressSameCurrent
          ? currentAddress.zipcode
          : familyInfo.employeeFather.zipcode,
    };

    return {
      parentMaritalStatusId: familyInfo.parentMaritalStatusId,
      isFatherAlive: familyInfo.employeeFather.isFatherAlive,
      employeeFather:
        familyInfo.employeeFather.isFatherAlive === 'Unknown'
          ? undefined
          : {
            titleTh: familyInfo.employeeFather.titleTh,
            firstnameTh: familyInfo.employeeFather.firstnameTh,
            middlenameTh: familyInfo.employeeFather.middlenameTh,
            lastnameTh: familyInfo.employeeFather.lastnameTh,
            birthday: familyInfo.employeeFather.birthday,
            religionId: familyInfo.employeeFather.religionId,
            raceId: familyInfo.employeeFather.raceId,
            nationalityId: familyInfo.employeeFather.nationalityId,
            job: familyInfo.employeeFather.job,
            phone: familyInfo.employeeFather.phone,
            address: fatherAddress.address,
            taxId: familyInfo.employeeFather.taxId,
            district: fatherAddress.district,
            subDistrict: fatherAddress.subDistrict,
            province: fatherAddress.province,
            zipcode: fatherAddress.zipcode,
            // countryCode: familyInfo.employeeFatherModel,
            // genderId: familyInfo.employeeFatherModel,
          },
      isMotherAlive: familyInfo.employeeMother.isMotherAlive,
      employeeMother:
        familyInfo.employeeMother.isMotherAlive === 'Unknown'
          ? undefined
          : {
            titleTh: familyInfo.employeeMother.titleTh,
            firstnameTh: familyInfo.employeeMother.firstnameTh,
            middlenameTh: familyInfo.employeeMother.middlenameTh,
            lastnameTh: familyInfo.employeeMother.lastnameTh,
            birthday: familyInfo.employeeMother.birthday,
            religionId: familyInfo.employeeMother.religionId,
            raceId: familyInfo.employeeMother.raceId,
            nationalityId: familyInfo.employeeMother.nationalityId,
            job: familyInfo.employeeMother.job,
            phone: familyInfo.employeeMother.phone,
            taxId: familyInfo.employeeMother.taxId,

            address: isMotherAddressSameHome
              ? employeeRegisteredAddress.address
              : isMotherAddressSameCurrent
                ? currentAddress.address
                : isMotherAddressSameFather
                  ? fatherAddress.address
                  : familyInfo.employeeMother.address,
            province: isMotherAddressSameHome
              ? employeeRegisteredAddress.province
              : isMotherAddressSameCurrent
                ? currentAddress.province
                : isMotherAddressSameFather
                  ? fatherAddress.province
                  : familyInfo.employeeMother.province,
            district: isMotherAddressSameHome
              ? employeeRegisteredAddress.district
              : isMotherAddressSameCurrent
                ? currentAddress.district
                : isMotherAddressSameFather
                  ? fatherAddress.district
                  : familyInfo.employeeMother.district,
            subDistrict: isMotherAddressSameHome
              ? employeeRegisteredAddress.subDistrict
              : isMotherAddressSameCurrent
                ? currentAddress.subDistrict
                : isMotherAddressSameFather
                  ? fatherAddress.subDistrict
                  : familyInfo.employeeMother.subDistrict,
            zipcode: isMotherAddressSameHome
              ? employeeRegisteredAddress.zipcode
              : isMotherAddressSameCurrent
                ? currentAddress.zipcode
                : isMotherAddressSameFather
                  ? fatherAddress.zipcode
                  : familyInfo.employeeMother.zipcode,
            // countryCode: familyInfo.employeeMother,
            // genderId: familyInfo.employeeMother,
          },
      employeeSpouse: {
        titleTh: familyInfo.employeeSpouse.titleTh,
        firstnameTh: familyInfo.employeeSpouse.firstnameTh,
        middlenameTh: familyInfo.employeeSpouse.middlenameTh,
        lastnameTh: familyInfo.employeeSpouse.lastnameTh,
        birthday: familyInfo.employeeSpouse.birthday,
        religionId: familyInfo.employeeSpouse.religionId,
        raceId: familyInfo.employeeSpouse.raceId,
        nationalityId: familyInfo.employeeSpouse.nationalityId,
        job: familyInfo.employeeSpouse.job,
        phone: familyInfo.employeeSpouse.phone,
        taxId: familyInfo.employeeSpouse.taxId,

        address: isSpouseAddressSameHome
          ? employeeRegisteredAddress.address
          : isSpouseAddressSameCurrent
            ? currentAddress.address
            : familyInfo.employeeSpouse.address,
        province: isSpouseAddressSameHome
          ? employeeRegisteredAddress.province
          : isSpouseAddressSameCurrent
            ? currentAddress.province
            : familyInfo.employeeSpouse.province,
        district: isSpouseAddressSameHome
          ? employeeRegisteredAddress.district
          : isSpouseAddressSameCurrent
            ? currentAddress.district
            : familyInfo.employeeSpouse.district,
        subDistrict: isSpouseAddressSameHome
          ? employeeRegisteredAddress.subDistrict
          : isSpouseAddressSameCurrent
            ? currentAddress.subDistrict
            : familyInfo.employeeSpouse.subDistrict,
        zipcode: isSpouseAddressSameHome
          ? employeeRegisteredAddress.zipcode
          : isSpouseAddressSameCurrent
            ? currentAddress.zipcode
            : familyInfo.employeeSpouse.zipcode,
        // countryCode: familyInfo.employeeSpouse,
        // genderId: familyInfo.employeeSpouse,
      },
      siblingCount: familyInfo.otherParent.siblingNumber,
      siblingIndex: familyInfo.otherParent.siblingPosition,
      employeeChildren: childInfo.employeeChildren.map(child => {
        return {
          titleTh: child.titleTh,
          firstnameTh: child.firstnameTh,
          lastnameTh: child.lastnameTh,
          birthday: child.birthday,
          genderId: child.genderId,
          taxId: child.taxId,
          isStudent: child.isStudent,
        };
      }),
      childCount: childInfo.employeeChildren.length,
      emergencyContact: {
        titleTh: emergencyInfo.titleTh,
        firstnameTh: emergencyInfo.firstnameTh,
        middlenameTh: emergencyInfo.middlenameTh,
        lastnameTh: emergencyInfo.lastnameTh,
        birthday: emergencyInfo.birthday,
        emergencyContactRelation: emergencyInfo.emergencyContactRelation,
        phone: emergencyInfo.phone,
      },
    };
  }

  private buildEducationInfoData(): EducationInfoModel {
    const educationInfo = this.educationInfo.model;

    let driveCarSkill: EmployeeSkillModel;
    let driveMotorcySkill: EmployeeSkillModel;
    let typingSkill: EmployeeSkillModel;

    if (educationInfo.typingSkill.isHasTypingSkill) {
      typingSkill = {
        text1: educationInfo.typingSkill.th,
        text2: educationInfo.typingSkill.eng,
      };
    }
    if (educationInfo.drivingSkill.isHasDrivingSkill) {
      driveCarSkill = {
        boolean1: !!educationInfo.drivingSkill.isHasPersonalCar,
        boolean2: !educationInfo.drivingSkill.isHasPersonalCar,
        text1: educationInfo.drivingSkill.licenseNumber,
        dateTime1: educationInfo.drivingSkill.expireDate,
      };
    }
    if (educationInfo.mortorCyclingSkill.isHasMortorCyclingSkill) {
      driveMotorcySkill = {
        boolean1: !!educationInfo.mortorCyclingSkill.isHasMotorcycle,
        boolean2: !educationInfo.mortorCyclingSkill.isHasMotorcycle,
        text1: educationInfo.mortorCyclingSkill.motorcycleLicenseNumber,
        dateTime1: educationInfo.mortorCyclingSkill.motorcycleExpireDate,
      };
    }

    return {
      employeeEducations: educationInfo.employeeEducations.map(edu => {
        return {
          educationLevelId: edu.educationLevelId,
          institute: edu.institute,
          fieldName: edu.fieldName,
          startYear: edu.startYear,
          endYear: edu.endYear,
          gpa: edu.gpa,
          locationName: edu.location,
          educationalBackground: edu.educationalBackground,
        };
      }),
      employeeLanguages: educationInfo.employeeLanguages.map(lang => {
        return {
          languageId: lang.languageId,
          speakingProficiency: lang.speakingProficiency,
          listeningProficiency: lang.listeningProficiency,
          readingProficiency: lang.readingProficiency,
          writingProficiency: lang.writingProficiency,
          toeicScore: lang.toeicScore ?? 0,
          tofelScore: lang.tofelScore ?? 0,
          ieltsScore: lang.ieltsScore ?? 0,
          otherScore: lang.otherScore ?? 0,
        };
      }),
      driveCarSkill,
      driveMotorcySkill,
      typingSkill,
    };
  }

  private buildAttachmentInfoData(): { documentOfEmployees: DocumentOfEmployeeModel[] } {
    return {
      documentOfEmployees: this.attachFileInfo.importanceFileList
        .filter(v => {
          return v.documentId;
        })
        .map(v => {
          return {
            name: v.type,
            description: v.desc,
            documentId: v.documentId,
          };
        }),
    };
  }

  private buildPastEmploymentInfoData(): PastEmploymentInfoModel {
    const experienceInfo = this.experienceInfo.model;

    return {
      employeePastEmployments: experienceInfo.employeePastEmployments.map(past => {
        return {
          employerName: past.employerName,
          businessTypeId: past.businessTypeId,
          phone: past.phone,
          address: past.address,
          jobDescription: past.jobDescription,
          entryPositionName: past.entryPositionName,
          exitPositionName: past.exitPositionName,
          entrySalary: past.entrySalary,
          exitSalary: past.exitSalary,
          employeeDate: past.employeeDate,
          terminateDate: past.terminateDate,
          exitReason: past.exitReason,
        };
      }),
    };
  }

  private buildEmploymentInfoData(): EmploymentInfoModel {
    const employmentInfo = this.employmentInfo.model;

    return {
      employeeCode: employmentInfo.employeeCode,
      employeeDate: employmentInfo.employeeDate,
      branchId: employmentInfo.branchId,
      probationDate: employmentInfo.passedProbationDate,
      email: employmentInfo.email,
      employeeWorkPermit: {
        workPermitNo: employmentInfo.workPermitNumber,
      },
      employeeDef: {
        employeeTypeId: employmentInfo.employeeTypeId,
        employeeType2Id: employmentInfo.employeeType2Id,
        effectiveDate: employmentInfo.employeeDate,
        companyId: employmentInfo.companyId,
        payType: employmentInfo.payType,
      },
      employeeInPosition: {
        effectiveDate: employmentInfo.employeeDate,
        probationDay: employmentInfo.probationDay,
        positionId: employmentInfo.positionId || 0,
      },
      hasSocialSecurity: employmentInfo.isHaveSocialSecurity,
      isDeductedSocialSecurity: employmentInfo.isDeductedSocialSecurity,
      payChannelId: employmentInfo.salaryPaymentMethodId,
      hospitalId: employmentInfo.hospitalId || undefined,
      employeeSalary: {
        salary: employmentInfo.salary,
        payDaily: employmentInfo.payDaily,
        wagePayType: employmentInfo.wagePayType,
      },
      employeeBankAccount: {
        accountNumber: employmentInfo.bankAccountNumber,
        bankName: employmentInfo.bankName,
        branchName: employmentInfo.branchName,
        isPrimary: true,
      },
    };
  }

  deleteEmployee() {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then((result: any) => {
      if (result.value) {
        this.approvalCenterService.DeleteNewEmployee(this.requestId).subscribe(res => {
          this.notificationService.deleteCompleted();
          this.router.navigate(['admin/approval-center']);
        });
      }
    });
  }

  // topadd
  onGoToEmployeeBtnClick() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

}
