
<div class="grid main">
  <div class="col-12">
    <div class="card shadow-1">
      <div class="grid align-center">
        <div>
          <h1>{{ cardName }}</h1>
        </div>
      </div>

      <div class="grid align-center" [hidden]="!loading">
        <div class="col-fixed justify-content-center">
          <p-progressSpinner></p-progressSpinner>
        </div>
      </div>

      <div [hidden]="loading">
        <label class="big-number">{{ (times || 0)  | number }}</label>
        <label> ครั้ง</label>
      </div>
    </div>

    <div class="card shadow-1" [hidden]="cardName == 'ขาดงาน'">
      <div class="grid align-center"></div>
      <br />

      <div class="grid align-center" [hidden]="!loading">
        <div class="col-fixed justify-content-center">
          <p-progressSpinner></p-progressSpinner>
        </div>
      </div>

      <div [hidden]="loading">
        <label class="big-number">{{ amountOfTime }}</label>
        <label> {{ unitOfAmount }}</label>
      </div>
    </div>
  </div>
</div>
