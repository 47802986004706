<div class="p-fluid" *ngIf="model">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>ข้อมูล PVD</h1>
        <hr />
        <div class="card">
          <div class="grid">
            <div class="col-fixed"><h1>ข้อมูลทั่วไป</h1></div>
            <div class="col"></div>
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                (click)="save()"
                label="{{ 'record' | translate }}"
              ></button>
            </div>
          </div>

          <div class="col-12">
            <form [formGroup]="form">
              <formly-form [model]="model" [fields]="fields" [form]="form"> </formly-form>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
