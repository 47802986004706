<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>{{ 'general_information' | translate }}</h1>
        <hr />
        <div class="card">
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>{{ 'personal_information_t' | translate }}</h1>
            </div>
            <!-- <div class="col-fixed">
              <button pButton type="button" label="ส่งคำขอแก้ไขข้อมูล" icon="fa fa-pencil-alt"
                (click)="onEditGeneralInfoBtnCLick()"></button>
            </div> -->
          </div>
          <div class="grid justify-content-end">
            <div class="col-12">
              <form [formGroup]="form">
                <formly-form [form]="form" [fields]="personalFields" [model]="personalInfoModel"></formly-form>
              </form>
            </div>
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                label="{{ 'record' | translate }}"
                (click)="onUpdatePersonalInfoBtnClick()"
                [disabled]="!form.valid || !form.dirty"
              ></button>
            </div>
          </div>
          <hr />
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>{{ 'identity_information' | translate }}</h1>
            </div>
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                label="{{ 'add_data' | translate }}"
                (click)="showCreateEmployeeCardDialog()"
              ></button>
            </div>
          </div>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="personnalCardList">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'id_type' | translate }}</th>
                <th>{{ 'id_card_code' | translate }}</th>
                <th>{{ 'expiration_date' | translate }}</th>
                <th *ngIf="isEditable" style="width: 100px">{{ 'action' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.employeeCardTypeName }}</td>
                <td>{{ rowData.cardNumber }}</td>
                <td>{{ rowData.expireDate | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i class="fa fa-pencil-alt" (click)="showUpdateEmployeeCardDialog(rowData.employeeCardId)"></i>
                    </div>
                    <div class="col-fixed">
                      <i class="fa fa-trash" (click)="showDeleteEmployeeCardDialog(rowData.employeeCardId)"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="4" class="emptymessage">{{ 'no_data' | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
          <hr />
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>{{ 'name_change_information' | translate }}</h1>
            </div>
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                label="{{ 'add_data' | translate }}"
                (click)="showCreateEmployeeNameDialog()"
              ></button>
            </div>
          </div>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="nameHistoryList">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'prefix' | translate }}</th>
                <th>{{ 'real_name' | translate }}</th>
                <th>{{ 'middle_name' | translate }}</th>
                <th>{{ 'surname' | translate }}</th>
                <!-- <th>ชื่อเล่น</th> -->
                <th>{{ 'effective_date' | translate }}</th>
                <th *ngIf="isEditable" style="width: 100px">{{ 'action' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.titleTh }}</td>
                <td>{{ rowData.firstnameTh }}</td>
                <td>{{ rowData.middlenameTh }}</td>
                <td>{{ rowData.lastnameTh }}</td>
                <!-- <td>{{ rowData.nicknameTh }}</td> -->
                <td>{{ rowData.effectiveDate | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i class="fa fa-pencil-alt" (click)="showUpdateEmployeeNameDialog(rowData)"></i>
                    </div>
                    <div class="col-fixed">
                      <i class="fa fa-trash" (click)="showDeleteEmployeeNameDialog(rowData)"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="7" class="emptymessage">{{ 'no_data' | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
          <hr />
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>{{ 'work_permit_information' | translate }}</h1>
            </div>
            <div class="col-fixed">
              <button
                *ngIf="isEditable"
                pButton
                type="button"
                label="{{ 'add_data' | translate }}"
                (click)="showCreateEmployeeWorkPermitDialog()"
              ></button>
            </div>
          </div>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="workPermitList">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ 'license_number' | translate }}</th>
                <th>{{ 'issuer' | translate }}</th>
                <th>{{ 'effective_date' | translate }}</th>
                <th>{{ 'end_date' | translate }}</th>
                <th *ngIf="isEditable" style="width: 100px">{{ 'action' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.workPermitNo }}</td>
                <td>{{ rowData.issuer }}</td>
                <td>{{ rowData.effectiveDate | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rowData.expireDate | date: 'dd/MM/yyyy' }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i
                        class="fa fa-pencil-alt"
                        (click)="showUpdateEmployeeWorkPermitDialog(rowData.employeeWorkPermitId)"
                      ></i>
                    </div>
                    <div class="col-fixed">
                      <i
                        class="fa fa-trash"
                        (click)="showDeleteEmployeeWorkPermitDialog(rowData.employeeWorkPermitId)"
                      ></i>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" class="emptymessage">{{ 'no_data' | translate }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
