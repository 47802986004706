interface FullAge {
  year: number;
  month: number;
  day: number;
}

export const calcAge = (birthday: string, dateNow?: string): FullAge => {
  const now = dateNow ? new Date(dateNow) : new Date();
  let birth = new Date(birthday);
  let [year, month, day] = [0, 0, 0];

  while (birth && now && birth <= now) {
    if ((now.getFullYear() - birth.getFullYear() > 1) ||
      (now.getFullYear() > birth.getFullYear() && now.getMonth() >= birth.getMonth())) {
      if (now.getMonth() === birth.getMonth()) {
        if (now.getDate() >= birth.getDate()) {
          year++;
          birth = new Date(birth.getFullYear() + 1, birth.getMonth(), birth.getDate());

          continue;
        }
      } else {
        year++;
        birth = new Date(birth.getFullYear() + 1, birth.getMonth(), birth.getDate());

        continue;
      }
    }
    if ((now.getFullYear() - birth.getFullYear() === 1) ||
      (now.getMonth() > birth.getMonth() && now.getDate() >= birth.getDate())) {
      month++;
      birth = new Date(birth.getFullYear(), birth.getMonth() + 1, birth.getDate());

      continue;
    }

    day++;
    birth = new Date(birth.getFullYear(), birth.getMonth(), birth.getDate() + 1);
  }

  return { year, month, day };
};
