/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AllowancePolicyModelPaginationResult } from '../models/allowance-policy-model-pagination-result';
import { AllowancePolicyModel } from '../models/allowance-policy-model';
@Injectable({
  providedIn: 'root',
})
class AllowancePolicyService extends __BaseService {
  static readonly GetAllowancePolicyListPath = '/api/AllowancePolicy';
  static readonly CreateAllowancePolicyPath = '/api/AllowancePolicy';
  static readonly UpdateAllowancePolicyPath = '/api/AllowancePolicy';
  static readonly GetAllowancePolicyByIdPath = '/api/AllowancePolicy/{allowancePolicyId}';
  static readonly DeleteAllowancePolicyPath = '/api/AllowancePolicy/{allowancePolicyId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AllowancePolicyService.GetAllowancePolicyListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetAllowancePolicyListResponse(params: AllowancePolicyService.GetAllowancePolicyListParams): __Observable<__StrictHttpResponse<AllowancePolicyModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllowancePolicy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllowancePolicyModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `AllowancePolicyService.GetAllowancePolicyListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetAllowancePolicyList(params: AllowancePolicyService.GetAllowancePolicyListParams): __Observable<AllowancePolicyModelPaginationResult> {
    return this.GetAllowancePolicyListResponse(params).pipe(
      __map(_r => _r.body as AllowancePolicyModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateAllowancePolicyResponse(body?: AllowancePolicyModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AllowancePolicy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateAllowancePolicy(body?: AllowancePolicyModel): __Observable<string> {
    return this.CreateAllowancePolicyResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateAllowancePolicyResponse(body?: AllowancePolicyModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/AllowancePolicy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateAllowancePolicy(body?: AllowancePolicyModel): __Observable<string> {
    return this.UpdateAllowancePolicyResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param allowancePolicyId undefined
   * @return Success
   */
  GetAllowancePolicyByIdResponse(allowancePolicyId: number): __Observable<__StrictHttpResponse<AllowancePolicyModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AllowancePolicy/${encodeURIComponent(String(allowancePolicyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllowancePolicyModel>;
      })
    );
  }
  /**
   * @param allowancePolicyId undefined
   * @return Success
   */
  GetAllowancePolicyById(allowancePolicyId: number): __Observable<AllowancePolicyModel> {
    return this.GetAllowancePolicyByIdResponse(allowancePolicyId).pipe(
      __map(_r => _r.body as AllowancePolicyModel)
    );
  }

  /**
   * @param allowancePolicyId undefined
   * @return Success
   */
  DeleteAllowancePolicyResponse(allowancePolicyId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/AllowancePolicy/${encodeURIComponent(String(allowancePolicyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param allowancePolicyId undefined
   * @return Success
   */
  DeleteAllowancePolicy(allowancePolicyId: number): __Observable<string> {
    return this.DeleteAllowancePolicyResponse(allowancePolicyId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module AllowancePolicyService {

  /**
   * Parameters for GetAllowancePolicyList
   */
  export interface GetAllowancePolicyListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    CompanyId?: number;
  }
}

export { AllowancePolicyService }
