import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AppBreadcrumbService } from 'app/layout/layout-default/app-breadcrumb/app-breadcrumb.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AttendanceCertifyRequestModel,
  AttendanceCertifyModel,
  EmployeeModel,
  WorkflowStepActionInfo,
  RequestPerformActionInput,
  EmployeeTimesheetModel,
  EAttendanceCertifyTypeOfTime,
} from 'app/api/models';
import { MasterDataRepo } from 'app/repo/master-data.repo';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { flatMap, map,tap } from 'rxjs/operators';
import {
  ActionService,
  AttendanceCertifyRequestService,
  AttendanceCertifyService,
  DocumentService,
  EmployeeService,
  LocationService,
  RequestService,
} from 'app/api/services';
import { AuthenticationService } from 'app/services/authentication.service';
import { forkJoin, of } from 'rxjs';
import { hide } from '@popperjs/core';

enum ComponentMode {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

interface SelectFileParam {
  currentFiles: File[];
}

interface AttendanceCertifyDisplayModel extends AttendanceCertifyModel {
  tempDocId?: number;
}

interface AttendanceCertifyRequestDisplayModel extends AttendanceCertifyRequestModel {
  attendanceCertifies?: Array<AttendanceCertifyDisplayModel>;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof AttendanceCertifyRequestDisplayModel | keyof AttendanceCertifyDisplayModel;
  fieldGroup?: CustomFormlyFieldConfig[];
  fieldArray?: CustomFormlyFieldConfig;
}

interface ActionFormModel {
  action?: WorkflowStepActionInfo;
}

interface ActionFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof ActionFormModel;
  fieldGroup?: ActionFormlyFieldConfig[];
}

enum DefaultMessage {
  CREATE_CONFIRM = 'ยืนยันการบันทึกข้อมูล',
  CREATE_FAILED = 'บันทึกคำขอรับรองเวลาล้มเหลว',
  UPDATE_FAILED = 'บันทึกคำขอรับรองเวลาล้มเหลว',
  PERFORM_ACTION_CONFIRM = 'ยืนยันดำเนินการ',
  PERFORM_ACTION_SUCCESS = 'ดำเนินการสำเร็จ',
  PERFORM_ACTION_FAILED = 'ดำเนินการล้มเหลว',
}

@Component({
  selector: 'app-request-time-guarantee-detail-2',
  templateUrl: './request-time-guarantee-detail.component.html',
  styleUrls: ['./request-time-guarantee-detail.component.css'],
  providers: [DialogService],
})
export class RequestTimeGuaranteeDetailComponent implements OnInit {
  model: AttendanceCertifyRequestDisplayModel = { attendanceCertifies: [{}] };
  form: FormGroup;
  options: FormlyFormOptions;
  fields: CustomFormlyFieldConfig[] = [];
  componentMode: ComponentMode;
  employee: EmployeeModel;

  actionForm = new FormGroup({});
  actionList: WorkflowStepActionInfo[] = [];
  actionModel: ActionFormModel = {};
  actionFields: ActionFormlyFieldConfig[] = [];
  isRequestPage = false;

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  isAdmin = this.currentUser.loginType === 'Admin';

  successReturnedRoute = '..';

  initialData: EmployeeTimesheetModel[] = [];

  queryData = {
    date: null,
    employeeId: null,
  };

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    private router: Router,
    private route: ActivatedRoute,
    private attendanceCertifyRequestService: AttendanceCertifyRequestService,
    private employeeService: EmployeeService,
    private notificationService: AppNotificationServiceService,
    private masterDataRepo: MasterDataRepo,
    private documentService: DocumentService,
    private locationService: LocationService,
    private authenticationService: AuthenticationService,
    private requestService: RequestService,
    private actionService: ActionService,
  ) {
    const initialData = this.router.getCurrentNavigation()?.extras?.state?.data;
    if (initialData) {
      this.initialData = initialData;
    }
  }

  async ngOnInit() {
    this.initQueryData();
    this.initFormlyFields();
    this.initBreadcrumb();
    this.initComponentMode();

    if (this.componentMode === ComponentMode.CREATE) {
      this.initDataFormAnotherPage();
    } else {
      this.initData();
    }
    await this.initEmployeeData();
  }

  initQueryData() {
    const q = this.route.snapshot.queryParamMap;

    this.queryData.date = q.get('date');
    this.queryData.employeeId = +q.get('employeeId');
  }

  initFormlyFields() {
    this.form = new FormGroup({});
    this.options = {
      formState: {
        disabled: false,
      },
    };
    this.fields = [
      {
        key: 'note',
        type: 'textarea',
        wrappers: ['inline-label'],
        templateOptions: {
          label: 'ความคิดเห็น:',
          labelClass: 'col-4 md:col-2',
          inputClass: 'col-8 md:col-4',
          disabled: true,
        },
        hideExpression: '!model.note',
      },
      {
        key: 'attendanceCertifies',
        type: 'repeat-my-request-time-attancedance-certify',
        templateOptions: {
          addText: '+ เพิ่มคำขอรับรองเวลา',
          deleteTitle: 'ลบข้อมูลคำขอรับรองเวลา',
          isShowRemoveBtn: true,
          isShowAddBtn: true,
        },
        expressionProperties: {
          'templateOptions.disabled': 'formState.disabled',
        },
        fieldArray: {
          fieldGroup: [
            {
              key: 'attendanceCertifyTypeId',
              type: 'filter-dropdown',
              wrappers: ['inline-label'],
              templateOptions: {
                label: 'สาเหตุที่ขอรับรองเวลา',
                required: true,
                labelClass: 'col-4 md:col-2',
                inputClass: 'col-8 md:col-4',
                options: this.masterDataRepo.getAttendanceCertifyTypeOptionList(),
              },
            },
            {
              key: 'locationId',
              type: 'filter-dropdown',
              wrappers: ['inline-label'],
              templateOptions: {
                label: 'สถานที่ปฎิบัติงาน',
                labelClass: 'col-4 md:col-2',
                inputClass: 'col-8 md:col-4',
                tooltip:
                  'ถ้าไม่มีสถานที่ที่ตนเองไปให้ใส่เป็นสถานที่ที่ทำงาน\nแล้วใส่สถานที่ปฏิบัติงานจริงในช่อง "เหตุผลการขอรับรองเวลา"',
                options: this.locationService.GetLocationsList({}).pipe(
                  map(pageResult => {
                    return pageResult.results.map(loc => {
                      return {
                        label: loc.name,
                        value: loc.locationId,
                      };
                    });
                  }),
                ),
              },
            },
            {
              key: 'attendanceCertifyTypeOfTime',
              type: 'filter-dropdown',
              wrappers: ['inline-label'],
              templateOptions: {
                label: 'ประเภทการขอรับรองเวลา',
                required: true,
                labelClass: 'col-4 md:col-2',
                inputClass: 'col-8 md:col-4',
                options: [
                  { label: 'เวลาเข้างาน', value: EAttendanceCertifyTypeOfTime.TIME_IN },
                  { label: 'เวลาออกจากงาน', value: EAttendanceCertifyTypeOfTime.TIME_OUT },
                  { label: 'ทั้งเวลาเข้างาน และออกจากงาน', value: EAttendanceCertifyTypeOfTime.TIME_IN_AND_TIME_OUT },
                ],
                // change: (field, $event) => {
                //   let attendanceCertifyTypeOfTime_Control = field.formControl.parent.controls['attendanceCertifyTypeOfTime'].value;
                //   let beginTime_Control = field.formControl.parent.controls['beginTime'];
                //   let endTime_Control = field.formControl.parent.controls['endTime'];

                //   // beginTime_Control.setValue('00:00');
                //   // endTime_Control.setValue('00:00');

                //   if(attendanceCertifyTypeOfTime_Control == "TimeIn"){
                //     // ล็อกเวลาออก
                //     endTime_Control.disable();
                //     beginTime_Control.enable();
                //   }else if(attendanceCertifyTypeOfTime_Control == "TimeOut"){
                //     // ล็อกเวลาเข้า
                //     endTime_Control.enable();
                //     beginTime_Control.disable();
                //   }else{
                //     // ปลดล็อกเวลา เวลาเข้า - ออก
                //     beginTime_Control.enable();
                //     endTime_Control.enable();
                //   }
                // }
              },
              hooks: {
                onInit: (field) => {
                  field.formControl.parent.controls['attendanceCertifyTypeOfTime'].valueChanges.pipe(
                    tap(obj => this.onCertifyTypeOfTimeChanged(field)),
                  ).subscribe();
                },
                afterViewInit: (field) => {
                  field.formControl.updateValueAndValidity();
                }
              },
            },
            {
              fieldGroupClassName: 'grid',
              fieldGroup: [
                {
                  key: 'beginDate',
                  type: 'calendar',
                  className: 'col-12',
                  wrappers: ['inline-label'],
                  templateOptions: {
                    required: true,
                    label: 'วันที่',
                    labelClass: 'col-4 md:col-2',
                    inputClass: 'col-8 md:col-4',
                    change: (field, $event) => {
                      let data_Control = field.formControl.parent.controls['attendanceCertifyTypeOfTime'].value;
                      let beginTime_Control = field.formControl.parent.controls['beginTime'];
                      let endTime_Control = field.formControl.parent.controls['endTime'];
                      if(data_Control != null || data_Control != undefined){
                        let beginDate_Control = field.formControl.parent.controls['beginDate'].value;
                        field.formControl.parent.controls['endDate'].setValue(beginDate_Control);
                        beginTime_Control.setValue(null);
                        endTime_Control.setValue(null);
                      }
                    }
                  },
                },
              ],
            },
            {
              fieldGroupClassName: 'grid',
              fieldGroup: [
                {
                  key: 'beginTime',
                  type: 'time-picker',
                  className: 'md:col-offset-2 col-offset-4 md:col-2 col-8',
                  wrappers: ['block-label'],
                  //defaultValue: '00:00',
                  templateOptions: {
                    //required: true,
                    label: 'เวลาเข้างาน',
                    labelClass: 'col-12 pb-0',
                    inputClass: 'col-12',
                  },
                  expressionProperties: {
                    'templateOptions.required': (model) => {
                      return model.attendanceCertifyTypeOfTime == "TimeIn" as EAttendanceCertifyTypeOfTime
                      || model.attendanceCertifyTypeOfTime == "TimeInAndTimeOut" as EAttendanceCertifyTypeOfTime},
                      'templateOptions.disabled': (model) => {
                        return model.attendanceCertifyTypeOfTime == "TimeOut" as EAttendanceCertifyTypeOfTime }
                  },
                },
                {
                  key: 'endTime',
                  type: 'time-picker',
                  className: 'md:col-offset-0 col-offset-4 md:col-2 col-8',
                  //defaultValue: '00:00',
                  wrappers: ['block-label'],
                  templateOptions: {
                    //required: true,
                    label: 'เวลาออกงาน',
                    labelClass: 'col-12 pb-0',
                    inputClass: 'col-12',
                  },
                  expressionProperties: {
                    'templateOptions.required': (model) => { return model.attendanceCertifyTypeOfTime == "TimeOut" as EAttendanceCertifyTypeOfTime
                    || model.attendanceCertifyTypeOfTime == "TimeInAndTimeOut" as EAttendanceCertifyTypeOfTime},
                    'templateOptions.disabled': (model) => {
                      return model.attendanceCertifyTypeOfTime == "TimeIn" as EAttendanceCertifyTypeOfTime }
                  },
                },
              ]
            },
            {
              fieldGroupClassName: 'grid hidden',
              fieldGroup: [
                {
                  key: 'endDate',
                  type: 'calendar',
                  className: 'col-8 md:col-4 col-4',
                  wrappers: ['inline-label'],
                  templateOptions: {
                    label: 'วันที่สิ้นสุด',
                    labelClass: 'col-6',
                    inputClass: 'col-6',
                  },
                  //hideExpression: true,
                },
              ],
            },
            {
              key: 'remark',
              type: 'textarea',
              className: 'pt-1',
              wrappers: ['inline-label'],
              templateOptions: {
                label: 'ระบุเหตุผลเพิ่มเติม',
                labelClass: 'col-4 md:col-2',
                inputClass: 'col-8 md:col-4',
              },
            },
            {
              key: 'documentOfAttendanceCertifyId',
              type: 'upload-file',
              templateOptions: {
                label: 'หลักฐานการขอรับรองเวลา',
                chooseLabel: 'อัพโหลดไฟล์',
                labelClass: 'col-4 md:col-2',
                inputClass: 'col-8 md:col-6',
                onSelect: (selectFileParam: SelectFileParam, model: AttendanceCertifyModel) => {
                  this.form.markAsDirty();
                  this.documentService
                    .CreateDocument({
                      description: 'Attendance Certify File',
                      file: selectFileParam.currentFiles[0],
                    })
                    .subscribe(result => {
                      model.documentOfAttendanceCertify = {
                        documentId: result.documentId,
                        description: 'Attendance Certify File',
                        name: 'หลักฐานการขอรับรองเวลา',
                      };
                    });
                },
                onRemove: (_, model: AttendanceCertifyModel) => {
                  model.documentOfAttendanceCertify = undefined;
                },
              },
            },
          ],
        },
      },
    ];
  }

  initBreadcrumb() {
    if (this.isAdmin) {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/admin' },
        { label: 'เวลาทำงาน (TA)', routerLink: '/admin/ta' },
        { label: 'รายการคำขอรับรองเวลา', routerLink: '/admin/ta/attendance-certify' },
      ]);
    } else {
      this.breadcrumbService.setItems([
        { label: 'หน้าแรก', routerLink: '/employee' },
        { label: 'เวลาทำงาน (TA)', routerLink: '/employee/ta' },
        { label: 'รายการคำขอรับรองเวลา', routerLink: '/employee/ta/request-time' },
        { label: 'ส่งคำขอรับรองเวลา' },
      ]);
    }
  }

  initComponentMode() {
    const attendanceCertiifyId = this.route.snapshot.params.id;
    const routePath = this.route.snapshot.routeConfig.path;

    if (attendanceCertiifyId) {
      switch (routePath) {
        case ':id':
          this.componentMode = ComponentMode.UPDATE;
          this.successReturnedRoute = '..';
          break;
        case 'request/:id':
          this.componentMode = ComponentMode.UPDATE;
          this.successReturnedRoute = '../..';
          this.isRequestPage = true;
          break;
        default:
          this.componentMode = ComponentMode.UPDATE;
          break;
      }
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  initData() {
    this.form.reset();

    this.getRequestAttendanceCertify()
      .pipe(
        flatMap(attModel => {
          return forkJoin([of(attModel), this.requestService.GetStepAction(attModel.requestId)]);
        }),
      )
      .subscribe(([attModel, actions]) => {
        if (attModel.attendanceCertifyStatusId !== 'Draft' && attModel.attendanceCertifyStatusId !== 'Editing') {
          this.disableForm();
        } else {
          this.actionList = actions;

          this.actionFields = [{
            key: 'action',
            type: 'radio',
            wrappers: ['inline-label'],
            templateOptions: {
              label: 'การดำเนินการ:',
              labelClass: 'col-4 md:col-3 lg:col-3',
              inputClass: 'col-8 md:col-8',
              options: this.actionList.map((action, index) => ({ value: action, label: action.name })),
              },
          }];

          if (this.actionFields.length === 0) {
            this.disableForm();
          }
        }

        this.model = {
          ...attModel,
          attendanceCertifies: attModel.attendanceCertifies.map(attendanceCertify => {
            return {
              ...attendanceCertify,
              documentOfAttendanceCertifyId: undefined,
              tempDocId: attendanceCertify.documentOfAttendanceCertifyId,
              // checktimein: attendanceCertify.beginDate != undefined,
              // checktimeout: attendanceCertify.endDate != undefined,
            };
          }),
        };

        // set document id in order to emit value changes for upload-file
        setTimeout(() => {
          this.model = {
            ...attModel,
            attendanceCertifies: this.model.attendanceCertifies.map(attendanceCertify => {
              return {
                ...attendanceCertify,
                documentOfAttendanceCertifyId: attendanceCertify.tempDocId,
              };
            }),
          };
        }, 1000);
      });
  }

  getRequestAttendanceCertify() {
    if (this.isRequestPage) {
      return this.requestService.GetAttendanceCertifyRequest(this.getAttendanceCertiifyId());
    } else {
      return this.attendanceCertifyRequestService.GetAttendanceCertifyRequestById(this.getAttendanceCertiifyId());
    }
  }

  initDataFormAnotherPage() {
    debugger;
    if (!this.initialData.length) {
      return;
    }

    this.model = {
      attendanceCertifies: this.initialData.map(a => {
        return {
          beginDate: (a.date),
          //beginTime: a.clockInId ? (a.clockInTime) : undefined,
          endDate: (a.date),
          //endTime: a.clockOutId ? (a.clockOutTime) : undefined,
        };
      }),
    };
  }

  async initEmployeeData() {
    let employeeId: number = null;

    if (this.componentMode === ComponentMode.CREATE) {
      if (this.queryData.employeeId) {
        employeeId = this.queryData.employeeId;
      } else {
        employeeId = this.authenticationService.getUserInfo().employeeId;
      }

      if (this.queryData.date) {
        this.model = {
          attendanceCertifies: [
            {
              beginDate: (this.queryData.date),
              endDate: (this.queryData.date),
            },
          ],
        };
      }
    } else {
      employeeId = this.employee.employeeId;
    }

    await this.setEmployeeData(employeeId);
  }

  onSaveBtnClick() {
    console.log(this.componentMode, this.model, this.employee);

    this.notificationService.confirm('บันทึก', 'ยืนยันการบันทึกข้อมูล').then(value => {
      if (value.isConfirmed) {
        this.save();
      }
    });
  }

  save() {
    switch (this.componentMode) {
      case ComponentMode.CREATE: {
        this.model.attendanceCertifies.filter(a => a.beginDate).forEach(a => {
          a.endDate =a.beginDate
        });
        this.attendanceCertifyRequestService
          .CreateAttendanceCertifyRequest({
            employeeId: this.employee.employeeId,
            attendanceCertifies: this.model.attendanceCertifies,
          })
          .subscribe(
            requestId => {
              this.notificationService.saveCompleted().then(() => {
                this.router.navigate(['..', 'request', requestId], { relativeTo: this.route });
              });
            },
            (err: HttpErrorResponse) => {
              console.log(err); // FIXME: handle err

              let errMsg = DefaultMessage.CREATE_FAILED;
              if (typeof err.error === 'string') {
                try {
                  const error = JSON.parse(err.error);
                  errMsg = error.message;
                } catch (error) {
                  console.log(error);
                }
              }

              this.notificationService.error(errMsg, '');
            },
          );
        break;
      }
      case ComponentMode.UPDATE: {
        this.updateAttendanceCertifyRequest().subscribe(
          () => {
            this.initFormlyFields();
            this.initData();
            this.notificationService.saveCompleted().then(() => {
              // this.navigateToList();
            });
          },
          (err: HttpErrorResponse) => {
            console.log(err); // FIXME: handle err

            let errMsg = '';
            if (typeof err.error === 'string') {
              try {
                const error = JSON.parse(err.error);
                errMsg = error.message;
              } catch (error) {
                console.log(error);
              }
            } else {
              errMsg = err?.error?.message;
            }

            this.notificationService.error(errMsg || DefaultMessage.UPDATE_FAILED, '');
          },
        );
        break;
      }
      default:
        break;
    }
  }

  updateAttendanceCertifyRequest() {
    return this.attendanceCertifyRequestService.UpdateAttendanceCertifyRequest(this.model);
  }

  async setEmployeeData(employeeId: number) {
    if(!employeeId) return;

    this.employee = undefined;

    this.employee = await this.employeeService
      .GetEmployeeInfo(employeeId)
      .toPromise()
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        return undefined;
      });
  }

  private disableForm() {
    this.options.formState.disabled = true;
    this.fields[0].templateOptions.isShowRemoveBtn = false;
    this.fields[0].templateOptions.isShowAddBtn = false;
  }

  private navigateToList() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  getMockData() {
    // FIXME: wrong seq when saved
    const prefixRemark = 'date 2018-05-';
    const yearMonth = '2018-05-';
    return [
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '01',
        endDate: yearMonth + '01',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 1,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '02',
        endDate: yearMonth + '02',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 2,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '03',
        endDate: yearMonth + '03',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 3,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '04',
        endDate: yearMonth + '04',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 4,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '05',
        endDate: yearMonth + '05',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 5,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '06',
        endDate: yearMonth + '06',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 6,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '07',
        endDate: yearMonth + '07',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 7,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '08',
        endDate: yearMonth + '08',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 8,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '09',
        endDate: yearMonth + '09',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 9,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '10',
        endDate: yearMonth + '10',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 10,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '11',
        endDate: yearMonth + '11',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 11,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '12',
        endDate: yearMonth + '12',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 12,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '13',
        endDate: yearMonth + '13',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 13,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '14',
        endDate: yearMonth + '14',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 14,
      },
      {
        attendanceCertifyTypeId: 'GAME',
        location: 1,
        beginDate: yearMonth + '15',
        endDate: yearMonth + '15',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 15,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '16',
        endDate: yearMonth + '16',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 16,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '17',
        endDate: yearMonth + '17',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 17,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '18',
        endDate: yearMonth + '18',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 18,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '19',
        endDate: yearMonth + '19',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 19,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '20',
        endDate: yearMonth + '20',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 20,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '21',
        endDate: yearMonth + '21',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 21,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '22',
        endDate: yearMonth + '22',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 22,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '23',
        endDate: yearMonth + '23',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 23,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '24',
        endDate: yearMonth + '24',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 24,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '25',
        endDate: yearMonth + '25',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 25,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '26',
        endDate: yearMonth + '26',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 26,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '27',
        endDate: yearMonth + '27',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 27,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '28',
        endDate: yearMonth + '28',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 28,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '29',
        endDate: yearMonth + '29',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 29,
      },
      {
        attendanceCertifyTypeId: 'WFH',
        location: 1,
        beginDate: yearMonth + '30',
        endDate: yearMonth + '30',
        beginTime: '09:00',
        endTime: '18:00',
        remark: prefixRemark + 30,
      },
    ];
  }

  getAttendanceCertiifyId(): number {
    return +this.route.snapshot.params.id;
  }

  submitActionForm() {
    this.notificationService.confirm(DefaultMessage.PERFORM_ACTION_CONFIRM, '').then(value => {
      if (value.isConfirmed) {
        this.performAction();
      }
    });
  }

  performAction() {
    const actioinInput: RequestPerformActionInput = {
      requestId: this.model.requestId,
      note: this.model.note,
    };

    if (this.actionList.length === 1) {
      actioinInput.workflowStepActionId = this.actionList[0].workflowStepActionId;
    } else {
      actioinInput.workflowStepActionId = this.actionModel.action.workflowStepActionId;
    }

    this.updateAttendanceCertifyRequest()
      .pipe(
        flatMap(() => {
          return this.actionService.PerformAction(actioinInput);
        }),
      )
      .subscribe(
        actions => {
          this.notificationService.success(DefaultMessage.PERFORM_ACTION_SUCCESS, '').then(() => {
            this.router.navigate([`${this.successReturnedRoute}`], { relativeTo: this.route });
          });
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.notificationService.error(err?.error?.message ?? DefaultMessage.PERFORM_ACTION_FAILED, '');
        },
      );
  }

  onCertifyTypeOfTimeChanged(val: any) {
    let attendanceCertifyTypeOfTime_Control = val.formControl.parent.controls['attendanceCertifyTypeOfTime'].value;
    let beginTime_Control = val.formControl.parent.controls['beginTime'];
    let endTime_Control = val.formControl.parent.controls['endTime'];

    // beginTime_Control.setValue('00:00');
    // endTime_Control.setValue('00:00');

    if(attendanceCertifyTypeOfTime_Control == "TimeIn"){
      // ล็อกเวลาออก
      endTime_Control.disable();
      beginTime_Control.enable();
    }else if(attendanceCertifyTypeOfTime_Control == "TimeOut"){
      // ล็อกเวลาเข้า
      endTime_Control.enable();
      beginTime_Control.disable();
    }else{
      // ปลดล็อกเวลา เวลาเข้า - ออก
      beginTime_Control.enable();
      endTime_Control.enable();
    }
  }

}
