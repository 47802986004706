<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>ผลประเมินประสิทธิภาพการทำงาน</h1>
        <hr />
        <div class="card">
          <div class="grid justify-content-between">
            <div class="col-fixed"></div>
            <div class="col"></div>
            <div class="col-fixed"></div>
          </div>
          <p-table [autoLayout]="true" [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="performanceList">
            <ng-template pTemplate="header">
              <tr>
                <th>ปีที่ประเมิน</th>
                <th>คะแนนรวม</th>
                <th>เกรด</th>
                <th>รายละเอียดเพิ่มเติม</th>
                <th *ngIf="isEditable" style="width: 50px">Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.year }}</td>
                <td>{{ rowData.point }}</td>
                <td>{{ rowData.grade }}</td>
                <td>{{ rowData.remark }}</td>
                <td *ngIf="isEditable">
                  <div class="grid justify-content-center icon-action">
                    <div class="col-fixed">
                      <i class="fa fa-search"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" class="emptymessage">ไม่พบข้อมูล</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
