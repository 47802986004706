import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult, SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AppNotificationServiceService {
  constructor() { }

  public success(title: string, message: string): Promise<SweetAlertResult> {
    return Swal.fire(title, message, 'success');
  }

  public error(title: string, message: string): Promise<SweetAlertResult> {
    const replaceNewLineMsg = message.replace('\r\n', '</br>');
    const containerWrapper =
      '<div style="background: #ebedf2;max-height: 200px;overflow-y: auto;">' + replaceNewLineMsg + '</div>';
    return Swal.fire(title, containerWrapper, 'error');
  }

  public confirm(title: string, message: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      text: message,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    } as SweetAlertOptions);
  }
  public info(message: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'ข้อมูลเพิ่มเติม',
      text: message,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ปิด',
    } as SweetAlertOptions);
  }
  public infoHtml(message: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'ข้อมูลเพิ่มเติม',
      html: message,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ปิด',
    } as SweetAlertOptions);
  }
  public confirmHTML(title: string, message: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      html: message,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    } as SweetAlertOptions);
  }

  public unknownError(): Promise<SweetAlertResult> {
    return Swal.fire('An unknown error has occurred', 'Please contact your system administrator.', 'error');
  }

  public saveCompleted(): Promise<SweetAlertResult> {
    return this.success('บันทึกข้อมูลสำเร็จ', 'การบันทึกข้อมูลสำเร็จแล้ว');
  }

  public AddCompleted(): Promise<SweetAlertResult> {
    return this.success('เพิ่มข้อมูลสำเร็จ', 'การเพิ่มข้อมูลสำเร็จแล้ว');
  }

  public uploadCompleted(): Promise<SweetAlertResult> {
    return this.success('อัพโหลดสำเร็จ', 'การอัพโหลดสำเร็จแล้ว');
  }

  public deleteCompleted(): Promise<SweetAlertResult> {
    return this.success('ลบข้อมูลสำเร็จ', 'การลบข้อมูลสำเร็จแล้ว');
  }

  public input(title: string, maxLength: string, confirmButton = 'บันทึก'): Promise<SweetAlertResult> {
    return Swal.fire({
      title,
      input: 'text',
      inputAttributes: {
        maxLength,
      },
      confirmButtonText: confirmButton,
    });
  }
}
