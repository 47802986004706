/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UnitModelPaginationResult } from '../models/unit-model-pagination-result';
import { UnitModel } from '../models/unit-model';
@Injectable({
  providedIn: 'root',
})
class UnitService extends __BaseService {
  static readonly GetUnitsPath = '/api/Unit';
  static readonly CreateUnitPath = '/api/Unit';
  static readonly UpdateUnitPath = '/api/Unit';
  static readonly GetUnitByIdPath = '/api/Unit/{unitId}';
  static readonly DeleteUnitPath = '/api/Unit/{unitId}';
  static readonly InitialUnitCodePath = '/api/Unit/InitialUnitCode';
  static readonly ClearInActiveUnitOfActivePositionPath = '/api/Unit/clearInActiveUnitOfActivePosition';
  static readonly ClerUnitUnderInActiveUnitPath = '/api/Unit/clearUnitUnderInActiveUnit';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UnitService.GetUnitsParams` containing the following parameters:
   *
   * - `UnitLevel`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `ParentOfUnitLevel`:
   *
   * - `ParentId`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IncludeJobGradeDetail`:
   *
   * - `InCludeParentUnit`:
   *
   * - `InCludeInDirectParent`:
   *
   * - `EffectiveDate`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetUnitsResponse(params: UnitService.GetUnitsParams): __Observable<__StrictHttpResponse<UnitModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitLevel != null) __params = __params.set('UnitLevel', params.UnitLevel.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.ParentOfUnitLevel != null) __params = __params.set('ParentOfUnitLevel', params.ParentOfUnitLevel.toString());
    if (params.ParentId != null) __params = __params.set('ParentId', params.ParentId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IncludeJobGradeDetail != null) __params = __params.set('IncludeJobGradeDetail', params.IncludeJobGradeDetail.toString());
    if (params.InCludeParentUnit != null) __params = __params.set('InCludeParentUnit', params.InCludeParentUnit.toString());
    if (params.InCludeInDirectParent != null) __params = __params.set('InCludeInDirectParent', params.InCludeInDirectParent.toString());
    if (params.EffectiveDate != null) __params = __params.set('EffectiveDate', params.EffectiveDate.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Unit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnitModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `UnitService.GetUnitsParams` containing the following parameters:
   *
   * - `UnitLevel`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `ParentOfUnitLevel`:
   *
   * - `ParentId`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IncludeJobGradeDetail`:
   *
   * - `InCludeParentUnit`:
   *
   * - `InCludeInDirectParent`:
   *
   * - `EffectiveDate`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetUnits(params: UnitService.GetUnitsParams): __Observable<UnitModelPaginationResult> {
    return this.GetUnitsResponse(params).pipe(
      __map(_r => _r.body as UnitModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateUnitResponse(body?: UnitModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Unit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateUnit(body?: UnitModel): __Observable<string> {
    return this.CreateUnitResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateUnitResponse(body?: UnitModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Unit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateUnit(body?: UnitModel): __Observable<string> {
    return this.UpdateUnitResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `UnitService.GetUnitByIdParams` containing the following parameters:
   *
   * - `unitId`:
   *
   * - `effectiveDate`:
   *
   * @return Success
   */
  GetUnitByIdResponse(params: UnitService.GetUnitByIdParams): __Observable<__StrictHttpResponse<UnitModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.effectiveDate != null) __params = __params.set('effectiveDate', params.effectiveDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Unit/${encodeURIComponent(String(params.unitId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnitModel>;
      })
    );
  }
  /**
   * @param params The `UnitService.GetUnitByIdParams` containing the following parameters:
   *
   * - `unitId`:
   *
   * - `effectiveDate`:
   *
   * @return Success
   */
  GetUnitById(params: UnitService.GetUnitByIdParams): __Observable<UnitModel> {
    return this.GetUnitByIdResponse(params).pipe(
      __map(_r => _r.body as UnitModel)
    );
  }

  /**
   * @param unitId undefined
   * @return Success
   */
  DeleteUnitResponse(unitId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Unit/${encodeURIComponent(String(unitId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param unitId undefined
   * @return Success
   */
  DeleteUnit(unitId: number): __Observable<string> {
    return this.DeleteUnitResponse(unitId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  InitialUnitCodeResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Unit/InitialUnitCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  InitialUnitCode(): __Observable<string> {
    return this.InitialUnitCodeResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param isSaveChange undefined
   */
  ClearInActiveUnitOfActivePositionResponse(isSaveChange?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (isSaveChange != null) __params = __params.set('isSaveChange', isSaveChange.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Unit/clearInActiveUnitOfActivePosition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param isSaveChange undefined
   */
  ClearInActiveUnitOfActivePosition(isSaveChange?: boolean): __Observable<null> {
    return this.ClearInActiveUnitOfActivePositionResponse(isSaveChange).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param isSaveChange undefined
   */
  ClerUnitUnderInActiveUnitResponse(isSaveChange?: boolean): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (isSaveChange != null) __params = __params.set('isSaveChange', isSaveChange.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Unit/clearUnitUnderInActiveUnit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param isSaveChange undefined
   */
  ClerUnitUnderInActiveUnit(isSaveChange?: boolean): __Observable<null> {
    return this.ClerUnitUnderInActiveUnitResponse(isSaveChange).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UnitService {

  /**
   * Parameters for GetUnits
   */
  export interface GetUnitsParams {
    UnitLevel?: number;
    Sorting?: string;
    SearchText?: string;
    ParentOfUnitLevel?: number;
    ParentId?: number;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IncludeJobGradeDetail?: boolean;
    InCludeParentUnit?: boolean;
    InCludeInDirectParent?: boolean;
    EffectiveDate?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for GetUnitById
   */
  export interface GetUnitByIdParams {
    unitId: number;
    effectiveDate?: string;
  }
}

export { UnitService }
