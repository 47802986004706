import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, ErrorHandler, NgZone } from "@angular/core";
import { AppNotificationServiceService } from "../shared/app-notification-service.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private appNotification: AppNotificationServiceService,
    private zone: NgZone
  ) { }

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    // Note : for http error, we will use HttpErrorInterceptor
    if ((error instanceof HttpErrorResponse)) {
      return
    }
    //error = error.rejection; // get the error object
    this.zone.run(() => {
      const message = error?.message || 'ไม่ทราบสาเหตุที่ผิดพลาด กรุณาลองใหม่อีกครั้ง';
      if (message.indexOf('ExpressionChangedAfterItHasBeenCheckedError')) {
        return;
      }
      const status = error?.status;
      // render error here
      this.appNotification.error(
        'เกิดข้อผิดพลาด',
        message
      )
    });
  }
}
