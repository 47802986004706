import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmployeeCardModel, CompanyModel } from 'app/api/models';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { EmployeeCardService, MasterDataService } from 'app/api/services';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { takeUntil, map, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { ThaiIDValidator } from 'app/formly-template/ThaiID.validator';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof EmployeeCardModel;
}

@Component({
  selector: 'app-modal-employee-card',
  templateUrl: './modal-employee-card.component.html',
  styleUrls: ['./modal-employee-card.component.css'],
})
export class ModalEmployeeCardComponent implements OnInit, OnDestroy {
  model: EmployeeCardModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'employeeCardTypeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,

        attributes: {
          style: 'width: 100%',
        },
        label: 'ประเภทบัตร',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
        change: (field: CustomFormlyFieldConfig, value: any) => {
          if(value=="N") {
            const cardNumberControl = this.form.get('cardNumber') as FormControl;
            cardNumberControl.addValidators(ThaiIDValidator);
            cardNumberControl.updateValueAndValidity();
          }
          else {
            const cardNumberControl = this.form.get('cardNumber') as FormControl;
            cardNumberControl.removeValidators(ThaiIDValidator);
            cardNumberControl.updateValueAndValidity();
          }
         },
        // options: this.masterDataService.GetEmployeeCardTypes().pipe(
        //   map(cardTypes => {
        //     return cardTypes.map(cardType => {
        //       return {
        //         value: cardType.employeeCardTypeId,
        //         label: cardType.name,
        //       };
        //     });
        //   }),
        // ),
      },
    },
    {
      key: 'cardNumber',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'card_n',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'issuerPlace',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'place_issue',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'issueDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'date_issue',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-4'
      },
    },
    {
      key: 'expireDate',
      type: 'calendar',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        label: 'expiration_date',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-4'
      },
    },
  ];
  componentMode: ComponentMode;
  private unsubscribe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private employeeCardService: EmployeeCardService,
    private masterDataService: MasterDataService,
  ) {}

  async ngOnInit() {
    this.initComponentMode();
    await this.initOptionList();
    await this.initData();
  }

  // ngOnInit(): void {
  //   const employeeCardId = this.config.data?.id;

  //   if (employeeCardId) {
  //     this.componentMode = ComponentMode.UPDATE;
  //     this.initData(employeeCardId);
  //   } else {
  //     const employeeId = this.config.data?.employeeId;
  //     if (!employeeId) {
  //       throw new Error('EmployeeId not found.');
  //     }
  //     this.componentMode = ComponentMode.CREATE;
  //     this.model = { employeeId };
  //   }
  // }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }

  initComponentMode() {
    const employeeCardId = this.getEmployeeCardId();

    if (employeeCardId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initOptionList() {
    this.fields.find(a => a.key === 'employeeCardTypeId').templateOptions.options = await this.masterDataService
      .GetEmployeeCardTypes()
      .pipe(
        map(cardTypes => {
          return cardTypes.map(cardType => {
            return {
              value: cardType.employeeCardTypeId,
              label: cardType.name,
            };
          });
        }),
      )
      .toPromise();
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      const employeeId = this.config.data?.employeeId;
      if (!employeeId) {
        this.close(); // FIXME: handle this
      }
      this.model = { employeeId };

      return;
    }

    const res = await this.employeeCardService.GetEmployeeCardById(this.getEmployeeCardId()).toPromise();

    this.model = {
      ...res,
      issueDate: (res.issueDate),
      expireDate: (res.expireDate),
    };
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        if (!this.model.expireDate) {
          this.model.expireDate = '9999-09-09T00:00:00';
        }

        this.createEmployeeCard()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      case ComponentMode.UPDATE:
        this.updateEmployeeCard()
          .subscribe(
            () => {
              this.notificationService.saveCompleted();
              this.ref.close(this.model);
            },
            (err: HttpErrorResponse) => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createEmployeeCard() {
    return this.employeeCardService.CreateEmployeeCard({
      employeeId: this.model.employeeId,
      employeeCardTypeId: this.model.employeeCardTypeId,
      cardNumber: this.model.cardNumber,
      issuerPlace: this.model.issuerPlace,
      issueDate: this.model.issueDate,
      expireDate: this.model.expireDate,
    });
  }

  private updateEmployeeCard() {
    return this.employeeCardService.UpdateEmployeeCard({
      employeeCardId: this.model.employeeCardId,
      employeeId: this.model.employeeId,
      employeeCardTypeId: this.model.employeeCardTypeId,
      cardNumber: this.model.cardNumber,
      issuerPlace: this.model.issuerPlace,
      issuerDepartmentName: this.model.issuerDepartmentName,
      issueDate: this.model.issueDate,
      expireDate: this.model.expireDate,
      isPrimary: this.model.isPrimary,
      status: this.model.status,
    });
  }

  getEmployeeCardId() {
    return this.config.data?.id;
  }
}
