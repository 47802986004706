import { Component, OnInit } from '@angular/core';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { PasswordModule , Password} from 'primeng/password';
import {CheckboxModule} from 'primeng/checkbox';
import { ApplicationSettingsService } from '../../../../services/application-settings.service';
import { ApplicationSettingsExtended } from '../../../../api/models';

@Component({
  selector: 'app-app.forget',
  templateUrl: './app.forget.component.html',
  styleUrls: ['./app.forget.component.css'],
})
export class AppForgetComponent implements OnInit{
  appSettings: ApplicationSettingsExtended;

  constructor(
    public applicationSettingService: ApplicationSettingsService
  ){

  }
  async ngOnInit() {
    this.appSettings = await this.applicationSettingService.getApplicationSettings().toPromise();
  }

  dark: boolean;

  checked: boolean;

  property: Password;

  selectedValues: string[] = [];

  submit() {

  }

  close()  {

  }
  toggle() {
  }

}
