import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PositionModel } from 'app/api/models';
import { NotificationService, PositionService } from 'app/api/services';
import { finalize } from 'rxjs/operators';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { ReplaySubject } from 'rxjs';
import { EventPageChange } from '../../../interfaces/EventPageChange';
import { PaginationParams } from '../../../interfaces/PaginationParams';
import { AppNotificationServiceService } from '../../../shared/app-notification-service.service';

interface FilterFormModel {
  searchtext?: string;
  employeeName?: string;
  companyId?: number;
  unitId?: number;
  isEmptyEmployee?: boolean;
}

@Component({
  selector: 'app-modal-position-search',
  templateUrl: './modal-position-search.component.html',
  styleUrls: ['./modal-position-search.component.css'],
})
export class ModalPositionSearchComponent implements OnInit {
  companyIdChange = new ReplaySubject<number>();
  model: FilterFormModel = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid p-fluid',
      fieldGroup: [
        {
          key: 'searchtext',
          type: 'primeng-input',
          className: 'col-3',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'search_term',
            placeholder: '@blank',
            labelClass: 'col-4',
            inputClass: 'col-8',
          },
        },
        {
          key: 'employeeName',
          type: 'primeng-input',
          className: 'col-3',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'name_persist_position',
            placeholder: '@blank',
            labelClass: 'col-6',
            inputClass: 'col-6',
          },
        },
        {
          key: 'unitId',
          type: 'select-unit',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'agency',
            placeholder: '@blank',
            labelClass: 'col-2',
            inputClass: 'col-10',
            companyChange: this.companyIdChange,
          },
        },
        {
          key: 'companyId',
          type: 'filter-dropdown',
          className: 'col-3',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            label: 'company',
            placeholder: '@blank',
            required: false,
            disabled: this.config.data?.disabledSelectCompany,
            labelClass: 'col-4',
            inputClass: 'col-8',
            options: this.masterDataRepo.GetCompanyList(),
            change:(fields,$event)=>{
              this.companyIdChange.next($event);
            }
          },
          
        },
      ],
    },
  ];
  positionList: PositionModel[] = [];
  isLoading = true;
  paginationParams: PaginationParams;

  constructor(
    private ref: DynamicDialogRef,
    private positionService: PositionService,
    private masterDataRepo: MasterDataRepo,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService
  ) { }

  ngOnInit() {
    this.initPaginationParams();
    this.initFilterModel();
    this.setPositionList();
  }

  initPaginationParams() {
    this.paginationParams = {
      firstRowIdx: 0,
      rowPerPage: 20,
      totalRecords: 0,
      rowPerPageSelectList: [5, 10, 20, 50, 100, 200]
    };
    this.paginationParams.rowPerPage = this.paginationParams.rowPerPage;
  }

  initFilterModel() {
    this.model = {
      unitId: this.config.data?.unitId,
      companyId: this.config.data?.companyId,
      isEmptyEmployee: this.config.data?.isEmptyEmployee || false,
    };
    this.companyIdChange.next(this.model.companyId);
  }

  setPositionList() {
    this.isLoading = true;

    const params = this.paginationParams;
    const page = Math.floor(params.firstRowIdx / params.rowPerPage) + 1;

    this.positionService
      .GetPositions({
        ItemPerPage: params.rowPerPage,
        Page: page,
        SearchText: this.model.searchtext,
        CompanyId: this.model.companyId,
        UnitId: this.model.unitId,
        IsEmptyEmployee: this.model.isEmptyEmployee,
        EmployeeName: this.model.employeeName,
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        paginationRes => {
          this.positionList = paginationRes.results;

          this.paginationParams.totalRecords = paginationRes.total;
        },
        (err: HttpErrorResponse) => {
          // FIXME: handle err
          console.log(err);
          this.positionList = [];
        },
      );
  }

  onPageChange(event: EventPageChange): void {
    this.paginationParams.firstRowIdx = event.first;
    this.paginationParams.rowPerPage = event.rows;
    this.setPositionList();
  }

  search() {
    this.setPositionList();
  }

  close() {
    this.ref.close();
  }

  selectPosition(position: any) {
    this.ref.close(position);
  }
}
