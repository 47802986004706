/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WorkflowConditionModel } from '../models/workflow-condition-model';
@Injectable({
  providedIn: 'root',
})
class WorkflowConditionService extends __BaseService {
  static readonly GetWorkflowConditionPath = '/api/WorkflowCondition/{workflowConditionId}';
  static readonly DeleteWorkflowConditionPath = '/api/WorkflowCondition/{workflowConditionId}';
  static readonly GetWorkflowConditionsByWorkflowTypePath = '/api/WorkflowCondition/workflowType/{workflowType}';
  static readonly CreateWorkflowConditionPath = '/api/WorkflowCondition';
  static readonly UpdateWorkflowConditionPath = '/api/WorkflowCondition';
  static readonly MoveConditionUpPath = '/api/WorkflowCondition/{workflowConditionId}/moveup';
  static readonly MoveConditionDownPath = '/api/WorkflowCondition/{workflowConditionId}/movedown';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param workflowConditionId undefined
   * @return Success
   */
  GetWorkflowConditionResponse(workflowConditionId: number): __Observable<__StrictHttpResponse<WorkflowConditionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/WorkflowCondition/${encodeURIComponent(String(workflowConditionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkflowConditionModel>;
      })
    );
  }
  /**
   * @param workflowConditionId undefined
   * @return Success
   */
  GetWorkflowCondition(workflowConditionId: number): __Observable<WorkflowConditionModel> {
    return this.GetWorkflowConditionResponse(workflowConditionId).pipe(
      __map(_r => _r.body as WorkflowConditionModel)
    );
  }

  /**
   * @param workflowConditionId undefined
   * @return Success
   */
  DeleteWorkflowConditionResponse(workflowConditionId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/WorkflowCondition/${encodeURIComponent(String(workflowConditionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param workflowConditionId undefined
   * @return Success
   */
  DeleteWorkflowCondition(workflowConditionId: number): __Observable<string> {
    return this.DeleteWorkflowConditionResponse(workflowConditionId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param workflowType undefined
   * @return Success
   */
  GetWorkflowConditionsByWorkflowTypeResponse(workflowType: string): __Observable<__StrictHttpResponse<Array<WorkflowConditionModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/WorkflowCondition/workflowType/${encodeURIComponent(String(workflowType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WorkflowConditionModel>>;
      })
    );
  }
  /**
   * @param workflowType undefined
   * @return Success
   */
  GetWorkflowConditionsByWorkflowType(workflowType: string): __Observable<Array<WorkflowConditionModel>> {
    return this.GetWorkflowConditionsByWorkflowTypeResponse(workflowType).pipe(
      __map(_r => _r.body as Array<WorkflowConditionModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateWorkflowConditionResponse(body?: WorkflowConditionModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/WorkflowCondition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateWorkflowCondition(body?: WorkflowConditionModel): __Observable<string> {
    return this.CreateWorkflowConditionResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateWorkflowConditionResponse(body?: WorkflowConditionModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/WorkflowCondition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateWorkflowCondition(body?: WorkflowConditionModel): __Observable<string> {
    return this.UpdateWorkflowConditionResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param workflowConditionId undefined
   * @return Success
   */
  MoveConditionUpResponse(workflowConditionId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/WorkflowCondition/${encodeURIComponent(String(workflowConditionId))}/moveup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param workflowConditionId undefined
   * @return Success
   */
  MoveConditionUp(workflowConditionId: number): __Observable<string> {
    return this.MoveConditionUpResponse(workflowConditionId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param workflowConditionId undefined
   * @return Success
   */
  MoveConditionDownResponse(workflowConditionId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/WorkflowCondition/${encodeURIComponent(String(workflowConditionId))}/movedown`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param workflowConditionId undefined
   * @return Success
   */
  MoveConditionDown(workflowConditionId: number): __Observable<string> {
    return this.MoveConditionDownResponse(workflowConditionId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module WorkflowConditionService {
}

export { WorkflowConditionService }
