import { FormControl, ValidationErrors } from '@angular/forms';

export function EmailValidator(control: FormControl): ValidationErrors {
  // tslint:disable-next-line: max-line-length
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!control.value || reg.test(String(control.value).toLowerCase())) {
    return null;
  }

  return { fieldMatch: { message: 'รูปแบบ Email ไม่ถูกต้อง' } };
}
