/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UnitDefModelPaginationResult } from '../models/unit-def-model-pagination-result';
import { UnitDefModel } from '../models/unit-def-model';
@Injectable({
  providedIn: 'root',
})
class UnitDefService extends __BaseService {
  static readonly GetUnitDefsPath = '/api/UnitDef';
  static readonly CreateUnitDefPath = '/api/UnitDef';
  static readonly UpdateUnitDefPath = '/api/UnitDef';
  static readonly GetUnitDefByIdPath = '/api/UnitDef/{unitDefId}';
  static readonly DeleteUnitDefPath = '/api/UnitDef/{unitDefId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UnitDefService.GetUnitDefsParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetUnitDefsResponse(params: UnitDefService.GetUnitDefsParams): __Observable<__StrictHttpResponse<UnitDefModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UnitDef`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnitDefModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `UnitDefService.GetUnitDefsParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetUnitDefs(params: UnitDefService.GetUnitDefsParams): __Observable<UnitDefModelPaginationResult> {
    return this.GetUnitDefsResponse(params).pipe(
      __map(_r => _r.body as UnitDefModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateUnitDefResponse(body?: UnitDefModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UnitDef`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateUnitDef(body?: UnitDefModel): __Observable<number> {
    return this.CreateUnitDefResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateUnitDefResponse(body?: UnitDefModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/UnitDef`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateUnitDef(body?: UnitDefModel): __Observable<string> {
    return this.UpdateUnitDefResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param unitDefId undefined
   * @return Success
   */
  GetUnitDefByIdResponse(unitDefId: number): __Observable<__StrictHttpResponse<UnitDefModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UnitDef/${encodeURIComponent(String(unitDefId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnitDefModel>;
      })
    );
  }
  /**
   * @param unitDefId undefined
   * @return Success
   */
  GetUnitDefById(unitDefId: number): __Observable<UnitDefModel> {
    return this.GetUnitDefByIdResponse(unitDefId).pipe(
      __map(_r => _r.body as UnitDefModel)
    );
  }

  /**
   * @param unitDefId undefined
   * @return Success
   */
  DeleteUnitDefResponse(unitDefId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/UnitDef/${encodeURIComponent(String(unitDefId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param unitDefId undefined
   * @return Success
   */
  DeleteUnitDef(unitDefId: number): __Observable<string> {
    return this.DeleteUnitDefResponse(unitDefId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module UnitDefService {

  /**
   * Parameters for GetUnitDefs
   */
  export interface GetUnitDefsParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { UnitDefService }
