import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-block-label-wrapper',
  templateUrl: './formly-block-label-wrapper.component.html',
  styleUrls: ['./formly-block-label-wrapper.component.scss']
})

export class FormlyBlockLabelWrapperComponent extends FieldWrapper {

}
