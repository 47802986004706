/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeHealthCheckModel } from '../models/employee-health-check-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeHealthCheckService extends __BaseService {
  static readonly GetEmployeeHealthChecksPath = '/api/EmployeeHealthCheck';
  static readonly CreateEmployeeHealthCheckPath = '/api/EmployeeHealthCheck';
  static readonly UpdateEmployeeHealthCheckPath = '/api/EmployeeHealthCheck';
  static readonly GetEmployeeHealthCheckByIdPath = '/api/EmployeeHealthCheck/{employeeHealthCheckId}';
  static readonly DeleteEmployeeHealthCheckPath = '/api/EmployeeHealthCheck/{employeeHealthCheckId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeHealthChecksResponse(): __Observable<__StrictHttpResponse<Array<EmployeeHealthCheckModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeHealthCheck`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeHealthCheckModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeHealthChecks(): __Observable<Array<EmployeeHealthCheckModel>> {
    return this.GetEmployeeHealthChecksResponse().pipe(
      __map(_r => _r.body as Array<EmployeeHealthCheckModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeHealthCheckResponse(body?: EmployeeHealthCheckModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeHealthCheck`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeHealthCheck(body?: EmployeeHealthCheckModel): __Observable<string> {
    return this.CreateEmployeeHealthCheckResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeHealthCheckResponse(body?: EmployeeHealthCheckModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeHealthCheck`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeHealthCheck(body?: EmployeeHealthCheckModel): __Observable<string> {
    return this.UpdateEmployeeHealthCheckResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeHealthCheckId undefined
   * @return Success
   */
  GetEmployeeHealthCheckByIdResponse(employeeHealthCheckId: number): __Observable<__StrictHttpResponse<EmployeeHealthCheckModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeHealthCheck/${encodeURIComponent(String(employeeHealthCheckId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeHealthCheckModel>;
      })
    );
  }
  /**
   * @param employeeHealthCheckId undefined
   * @return Success
   */
  GetEmployeeHealthCheckById(employeeHealthCheckId: number): __Observable<EmployeeHealthCheckModel> {
    return this.GetEmployeeHealthCheckByIdResponse(employeeHealthCheckId).pipe(
      __map(_r => _r.body as EmployeeHealthCheckModel)
    );
  }

  /**
   * @param employeeHealthCheckId undefined
   * @return Success
   */
  DeleteEmployeeHealthCheckResponse(employeeHealthCheckId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeHealthCheck/${encodeURIComponent(String(employeeHealthCheckId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeHealthCheckId undefined
   * @return Success
   */
  DeleteEmployeeHealthCheck(employeeHealthCheckId: number): __Observable<string> {
    return this.DeleteEmployeeHealthCheckResponse(employeeHealthCheckId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeHealthCheckService {
}

export { EmployeeHealthCheckService }
