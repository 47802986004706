import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-repeat-section',
  templateUrl: './formly-repeat-section.component.html',
  styleUrls: ['./formly-repeat-section.component.css']
})
export class FormlyRepeatSectionComponent extends FieldArrayType {
  addRow() {
    this.add();
    this.form.updateValueAndValidity();
  }

}
