import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';

export interface TreeModel<T = any> extends TreeNode {
  data: T;
  children?: TreeModel[];
}

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.css'],
})
export class TreeNodeComponent implements OnInit, OnChanges {
  @Input() loading = true;
  @Input() dataList: any[] = [];
  @Input() keyName = '';
  @Input() parentKeyName = '';
  @Input() selectedNode: TreeModel;
  @Output() nodeSelect = new EventEmitter();
  @Output() nodeUnSelect = new EventEmitter();
  treeModel: TreeModel[] = [];
  mapPersonalTree: { [personaAreaId: number]: TreeModel } = {};

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataList?.currentValue && this.keyName && this.parentKeyName) {
      const mapExpandedState = this.getMapExpandedState(this.treeModel ?? []);
      const [personalMap, tree] = this.getPersonalMapAndTree(changes.dataList.currentValue, mapExpandedState);
      this.mapPersonalTree = personalMap;
      this.treeModel = tree;

      this.loading = false;
      if (this.selectedNode && !this.isFoundSelectedNode(this.treeModel, this.selectedNode)) {
        this.selectedNode = undefined;
      }
    }
  }

  onNodeSelect(event) {
    this.nodeSelect.emit(event);
  }

  onNodeUnselect(event) {
    this.nodeUnSelect.emit(event);
  }

  getPersonalMapAndTree(personnelAreas: any[], mapTreeExpand: any): [any, TreeModel[]] {
    const hashTable: { [id: string]: TreeModel } = Object.create(null);

    for (const p of personnelAreas) {
      hashTable[p[this.keyName]] = {
        label: p.name,
        expandedIcon: 'fa fa-folder-open',
        collapsedIcon: 'fa fa-folder',
        data: p,
        expanded: mapTreeExpand[p[this.keyName]],
        key: p[this.keyName].toString(),
      };

      // Customize icon to conveniently differentiate the unit level
      switch (hashTable[p[this.keyName]].data.unitLevelName) {
        case 'Plant': {
          hashTable[p[this.keyName]].expandedIcon = 'fa fa-industry';
          hashTable[p[this.keyName]].collapsedIcon = 'fa fa-industry';
          break;
        }

        case 'Division': {
          hashTable[p[this.keyName]].expandedIcon = 'fa fa-city';
          hashTable[p[this.keyName]].collapsedIcon = 'fa fa-city';
          break;
        }

        case 'Department': {
          hashTable[p[this.keyName]].expandedIcon = 'fa fa-building';
          hashTable[p[this.keyName]].collapsedIcon = 'fa fa-building';
          break;
        }

        case 'Section': {
          hashTable[p[this.keyName]].expandedIcon = 'fa fa-sitemap';
          hashTable[p[this.keyName]].collapsedIcon = 'fa fa-sitemap';
          break;
        }

        case 'Sub-Section': {
          hashTable[p[this.keyName]].expandedIcon = 'fa fa-square';
          hashTable[p[this.keyName]].collapsedIcon = 'fa fa-square';
          break;
        }
      }
    }

    const dataTree: TreeModel[] = [];
    for (const p of personnelAreas) {
      if (p[this.parentKeyName] == null) {
        dataTree.push(hashTable[p[this.keyName]]);
      } else {
        if (!hashTable[p[this.parentKeyName]]) {
          continue;
        }

        if (!hashTable[p[this.parentKeyName]].children) {
          hashTable[p[this.parentKeyName]].children = [];
        }

        hashTable[p[this.parentKeyName]].children.push(hashTable[p[this.keyName]]);
      }
    }

    return [hashTable, dataTree];
  }

  isFoundSelectedNode(personnelTrees: TreeModel[], selectedNode: TreeModel): boolean {
    const unitId = selectedNode.data[this.keyName];

    for (const tree of personnelTrees ?? []) {
      if (tree.data[this.keyName] === unitId) {
        return true;
      }
      if (this.isFoundSelectedNode(tree.children, selectedNode)) {
        return true;
      }
    }

    return false;
  }

  getMapExpandedState(personnelTrees: TreeModel[]) {
    const mapState = {};

    personnelTrees.forEach(per => {
      this.setMapExpandedState(per, mapState);
    });

    return mapState;
  }

  setMapExpandedState(personnelTree: TreeModel, mapResult: any) {
    mapResult[personnelTree.data[this.keyName]] = personnelTree.expanded;

    for (const child of personnelTree.children ?? []) {
      this.setMapExpandedState(child, mapResult);
    }
  }
}
