import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationSettingsExtended } from '../api/models';
import { ApplicationSettingsService } from './application-settings.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ViewUtilsService {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
  }
  isShowByStage(stage: string, settings: ApplicationSettingsExtended) {
    if (!settings || ! stage) { return true; }
    return settings.showFeatureStages.indexOf(stage) !== -1;

  }

  isShowByClientCode(clientCode: string, settings: ApplicationSettingsExtended) {
    if (!clientCode || !settings) { return true; }
    if (!settings.clientCode || settings.clientCode === '') { return true; }
    return settings.clientCode === clientCode;
  }

  isShowOnlyClientCode(clientCode: string, settings: ApplicationSettingsExtended) {
    if (!clientCode || !settings) { return true; }
    return settings.clientCode === clientCode;
  }

  gotoHomePage(){
    const logInType = this.authenticationService.getCurrentUser().loginType as 'Admin' | 'Employee';
    if (logInType === 'Admin') {
      this.router.navigate(['/admin/home']);
    }
    else if (logInType === 'Employee') {
      this.router.navigate(['/employee/home']);
    } else {
      this.router.navigate(['/account/login']);
    }
  }
  isDesktop() {
    return window.innerWidth > 740;
  }

  isMobile() {
    return window.innerWidth <= 740;
  }
}
