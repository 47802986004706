/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeInShiftModelPaginationResult } from '../models/employee-in-shift-model-pagination-result';
import { EmployeeWithShiftsModelPaginationResult } from '../models/employee-with-shifts-model-pagination-result';
import { ImportEmployeeWithShiftsModel } from '../models/import-employee-with-shifts-model';
import { ExportEmployeeWithShiftExcelParam } from '../models/export-employee-with-shift-excel-param';
import { EmployeeInShiftModel } from '../models/employee-in-shift-model';
import { ImportEmployeeDutyModel } from '../models/import-employee-duty-model';
import { ImportDutyStep1Model } from '../models/import-duty-step-1model';
@Injectable({
  providedIn: 'root',
})
class EmployeeInShiftService extends __BaseService {
  static readonly GetEmployeeInShiftsListByEmployeeIdPath = '/api/Employee/{employeeId}/EmployeeInShift';
  static readonly GetEmployeeWithShiftsPath = '/api/EmployeeWithShifts';
  static readonly ImportExcelStep1Path = '/api/EmployeeWithShifts/importexcel/step1';
  static readonly ImportExcelStep2Path = '/api/EmployeeWithShifts/importexcel/step2';
  static readonly ExportExcelPath = '/api/EmployeeWithShifts/exportexcel';
  static readonly GetEmployeeInShiftsListPath = '/api/EmployeeInShift';
  static readonly CreateEmployeeInShiftPath = '/api/EmployeeInShift';
  static readonly UpdateEmployeeInShiftPath = '/api/EmployeeInShift';
  static readonly GetEmployeeInShiftByIdPath = '/api/EmployeeInShift/{employeeInShiftId}';
  static readonly DeleteEmployeeInShiftPath = '/api/EmployeeInShift/{employeeInShiftId}';
  static readonly RemoveDuplicateEffectEmployeeInShiftPath = '/api/removeDuplicateEffectEmployeeInShift';
  static readonly ExportDutyExcelPath = '/api/EmployeeWithShifts/dutyexcel';
  static readonly ImportDutyExcelStep1Path = '/api/EmployeeWithShifts/importdutyexcel/step1';
  static readonly ImportDutyExcelStep2Path = '/api/EmployeeWithShifts/importdutyexcel/step2';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EmployeeInShiftService.GetEmployeeInShiftsListByEmployeeIdParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetEmployeeInShiftsListByEmployeeIdResponse(params: EmployeeInShiftService.GetEmployeeInShiftsListByEmployeeIdParams): __Observable<__StrictHttpResponse<EmployeeInShiftModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employee/${encodeURIComponent(String(params.employeeId))}/EmployeeInShift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeInShiftModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeeInShiftService.GetEmployeeInShiftsListByEmployeeIdParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetEmployeeInShiftsListByEmployeeId(params: EmployeeInShiftService.GetEmployeeInShiftsListByEmployeeIdParams): __Observable<EmployeeInShiftModelPaginationResult> {
    return this.GetEmployeeInShiftsListByEmployeeIdResponse(params).pipe(
      __map(_r => _r.body as EmployeeInShiftModelPaginationResult)
    );
  }

  /**
   * @param params The `EmployeeInShiftService.GetEmployeeWithShiftsParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `ShiftId`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetEmployeeWithShiftsResponse(params: EmployeeInShiftService.GetEmployeeWithShiftsParams): __Observable<__StrictHttpResponse<EmployeeWithShiftsModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.ShiftId != null) __params = __params.set('ShiftId', params.ShiftId.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.PositionId != null) __params = __params.set('PositionId', params.PositionId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.EmployeeName != null) __params = __params.set('EmployeeName', params.EmployeeName.toString());
    if (params.EmployeeCode != null) __params = __params.set('EmployeeCode', params.EmployeeCode.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeWithShifts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeWithShiftsModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeeInShiftService.GetEmployeeWithShiftsParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `ShiftId`:
   *
   * - `SearchText`:
   *
   * - `PositionId`:
   *
   * - `Page`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeName`:
   *
   * - `EmployeeCode`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetEmployeeWithShifts(params: EmployeeInShiftService.GetEmployeeWithShiftsParams): __Observable<EmployeeWithShiftsModelPaginationResult> {
    return this.GetEmployeeWithShiftsResponse(params).pipe(
      __map(_r => _r.body as EmployeeWithShiftsModelPaginationResult)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  ImportExcelStep1Response(file?: Blob): __Observable<__StrictHttpResponse<ImportEmployeeWithShiftsModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeWithShifts/importexcel/step1`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImportEmployeeWithShiftsModel>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  ImportExcelStep1(file?: Blob): __Observable<ImportEmployeeWithShiftsModel> {
    return this.ImportExcelStep1Response(file).pipe(
      __map(_r => _r.body as ImportEmployeeWithShiftsModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep2Response(body?: ImportEmployeeWithShiftsModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeWithShifts/importexcel/step2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep2(body?: ImportEmployeeWithShiftsModel): __Observable<string> {
    return this.ImportExcelStep2Response(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ExportExcelResponse(body?: ExportEmployeeWithShiftExcelParam): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeWithShifts/exportexcel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ExportExcel(body?: ExportEmployeeWithShiftExcelParam): __Observable<Blob> {
    return this.ExportExcelResponse(body).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `EmployeeInShiftService.GetEmployeeInShiftsListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetEmployeeInShiftsListResponse(params: EmployeeInShiftService.GetEmployeeInShiftsListParams): __Observable<__StrictHttpResponse<EmployeeInShiftModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeInShift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeInShiftModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `EmployeeInShiftService.GetEmployeeInShiftsListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetEmployeeInShiftsList(params: EmployeeInShiftService.GetEmployeeInShiftsListParams): __Observable<EmployeeInShiftModelPaginationResult> {
    return this.GetEmployeeInShiftsListResponse(params).pipe(
      __map(_r => _r.body as EmployeeInShiftModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeInShiftResponse(body?: EmployeeInShiftModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeInShift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeInShift(body?: EmployeeInShiftModel): __Observable<string> {
    return this.CreateEmployeeInShiftResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeInShiftResponse(body?: EmployeeInShiftModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeInShift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateEmployeeInShift(body?: EmployeeInShiftModel): __Observable<string> {
    return this.UpdateEmployeeInShiftResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeInShiftId undefined
   * @return Success
   */
  GetEmployeeInShiftByIdResponse(employeeInShiftId: number): __Observable<__StrictHttpResponse<EmployeeInShiftModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeInShift/${encodeURIComponent(String(employeeInShiftId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeInShiftModel>;
      })
    );
  }
  /**
   * @param employeeInShiftId undefined
   * @return Success
   */
  GetEmployeeInShiftById(employeeInShiftId: number): __Observable<EmployeeInShiftModel> {
    return this.GetEmployeeInShiftByIdResponse(employeeInShiftId).pipe(
      __map(_r => _r.body as EmployeeInShiftModel)
    );
  }

  /**
   * @param employeeInShiftId undefined
   * @return Success
   */
  DeleteEmployeeInShiftResponse(employeeInShiftId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeInShift/${encodeURIComponent(String(employeeInShiftId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeInShiftId undefined
   * @return Success
   */
  DeleteEmployeeInShift(employeeInShiftId: number): __Observable<string> {
    return this.DeleteEmployeeInShiftResponse(employeeInShiftId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param companyId undefined
   * @return Success
   */
  RemoveDuplicateEffectEmployeeInShiftResponse(companyId?: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (companyId != null) __params = __params.set('companyId', companyId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/removeDuplicateEffectEmployeeInShift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param companyId undefined
   * @return Success
   */
  RemoveDuplicateEffectEmployeeInShift(companyId?: number): __Observable<string> {
    return this.RemoveDuplicateEffectEmployeeInShiftResponse(companyId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ExportDutyExcelResponse(body?: ExportEmployeeWithShiftExcelParam): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeWithShifts/dutyexcel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ExportDutyExcel(body?: ExportEmployeeWithShiftExcelParam): __Observable<Blob> {
    return this.ExportDutyExcelResponse(body).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ImportDutyExcelStep1Response(body?: ImportDutyStep1Model): __Observable<__StrictHttpResponse<ImportEmployeeDutyModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeWithShifts/importdutyexcel/step1`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImportEmployeeDutyModel>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ImportDutyExcelStep1(body?: ImportDutyStep1Model): __Observable<ImportEmployeeDutyModel> {
    return this.ImportDutyExcelStep1Response(body).pipe(
      __map(_r => _r.body as ImportEmployeeDutyModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ImportDutyExcelStep2Response(body?: ImportEmployeeDutyModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeWithShifts/importdutyexcel/step2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ImportDutyExcelStep2(body?: ImportEmployeeDutyModel): __Observable<string> {
    return this.ImportDutyExcelStep2Response(body).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeInShiftService {

  /**
   * Parameters for GetEmployeeInShiftsListByEmployeeId
   */
  export interface GetEmployeeInShiftsListByEmployeeIdParams {
    employeeId: number;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }

  /**
   * Parameters for GetEmployeeWithShifts
   */
  export interface GetEmployeeWithShiftsParams {
    UnitId?: number;
    Sorting?: string;
    ShiftId?: number;
    SearchText?: string;
    PositionId?: number;
    Page?: number;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    EmployeeName?: string;
    EmployeeCode?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for GetEmployeeInShiftsList
   */
  export interface GetEmployeeInShiftsListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { EmployeeInShiftService }
