/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OTRequestModelPaginationResult } from '../models/otrequest-model-pagination-result';
import { OTRequestModel } from '../models/otrequest-model';
import { RequestPerformActionInput } from '../models/request-perform-action-input';
import { ImportOTRequestModel } from '../models/import-otrequest-model';
import { ImportOTRequestStep1Model } from '../models/import-otrequest-step-1model';
@Injectable({
  providedIn: 'root',
})
class OTRequestService extends __BaseService {
  static readonly GetOTRequestsListPath = '/api/OTRequest';
  static readonly CreateOTRequestPath = '/api/OTRequest';
  static readonly UpdateOTRequestPath = '/api/OTRequest';
  static readonly GetMyOTRequestsListPath = '/api/OTRequest/MyOTRequests';
  static readonly GetOTRequestByIdPath = '/api/OTRequest/{OTRequestId}';
  static readonly DeleteOTRequestPath = '/api/OTRequest/{OTRequestId}';
  static readonly CreateOTforOthersRequestByEmployeePath = '/api/OTRequest/CreateOTforOthersRequestByEmployee';
  static readonly UpdateOTforOthersRequestByEmployeePath = '/api/OTRequest/UpdateOTforOthersRequestByEmployee';
  static readonly CancelOTRequestPath = '/api/OTRequest/{otRequestId}/cancel';
  static readonly ImportExcelStep1Path = '/api/OTRequest/importexcel/step1';
  static readonly ImportExcelStep2Path = '/api/OTRequest/importexcel/step2';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OTRequestService.GetOTRequestsListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestDate`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `OTTypeId`:
   *
   * - `OTStatusId`:
   *
   * - `OTRequestType`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeId`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetOTRequestsListResponse(params: OTRequestService.GetOTRequestsListParams): __Observable<__StrictHttpResponse<OTRequestModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RequestDate != null) __params = __params.set('RequestDate', params.RequestDate.toString());
    if (params.PersonnelAreaId != null) __params = __params.set('PersonnelAreaId', params.PersonnelAreaId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OTTypeId != null) __params = __params.set('OTTypeId', params.OTTypeId.toString());
    if (params.OTStatusId != null) __params = __params.set('OTStatusId', params.OTStatusId.toString());
    if (params.OTRequestType != null) __params = __params.set('OTRequestType', params.OTRequestType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OTRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTRequestModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `OTRequestService.GetOTRequestsListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestDate`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `OTTypeId`:
   *
   * - `OTStatusId`:
   *
   * - `OTRequestType`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeId`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetOTRequestsList(params: OTRequestService.GetOTRequestsListParams): __Observable<OTRequestModelPaginationResult> {
    return this.GetOTRequestsListResponse(params).pipe(
      __map(_r => _r.body as OTRequestModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateOTRequestResponse(body?: OTRequestModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/OTRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateOTRequest(body?: OTRequestModel): __Observable<number> {
    return this.CreateOTRequestResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateOTRequestResponse(body?: OTRequestModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/OTRequest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateOTRequest(body?: OTRequestModel): __Observable<string> {
    return this.UpdateOTRequestResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `OTRequestService.GetMyOTRequestsListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestDate`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `OTTypeId`:
   *
   * - `OTStatusId`:
   *
   * - `OTRequestType`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeId`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetMyOTRequestsListResponse(params: OTRequestService.GetMyOTRequestsListParams): __Observable<__StrictHttpResponse<OTRequestModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.UnitId != null) __params = __params.set('UnitId', params.UnitId.toString());
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.RequestDate != null) __params = __params.set('RequestDate', params.RequestDate.toString());
    if (params.PersonnelAreaId != null) __params = __params.set('PersonnelAreaId', params.PersonnelAreaId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OTTypeId != null) __params = __params.set('OTTypeId', params.OTTypeId.toString());
    if (params.OTStatusId != null) __params = __params.set('OTStatusId', params.OTStatusId.toString());
    if (params.OTRequestType != null) __params = __params.set('OTRequestType', params.OTRequestType.toString());
    if (params.LocationId != null) __params = __params.set('LocationId', params.LocationId.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OTRequest/MyOTRequests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTRequestModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `OTRequestService.GetMyOTRequestsListParams` containing the following parameters:
   *
   * - `UnitId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `RequestDate`:
   *
   * - `PersonnelAreaId`:
   *
   * - `Page`:
   *
   * - `OTTypeId`:
   *
   * - `OTStatusId`:
   *
   * - `OTRequestType`:
   *
   * - `LocationId`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeId`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  GetMyOTRequestsList(params: OTRequestService.GetMyOTRequestsListParams): __Observable<OTRequestModelPaginationResult> {
    return this.GetMyOTRequestsListResponse(params).pipe(
      __map(_r => _r.body as OTRequestModelPaginationResult)
    );
  }

  /**
   * @param OTRequestId undefined
   * @return Success
   */
  GetOTRequestByIdResponse(OTRequestId: number): __Observable<__StrictHttpResponse<OTRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OTRequest/${encodeURIComponent(String(OTRequestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OTRequestModel>;
      })
    );
  }
  /**
   * @param OTRequestId undefined
   * @return Success
   */
  GetOTRequestById(OTRequestId: number): __Observable<OTRequestModel> {
    return this.GetOTRequestByIdResponse(OTRequestId).pipe(
      __map(_r => _r.body as OTRequestModel)
    );
  }

  /**
   * @param OTRequestId undefined
   * @return Success
   */
  DeleteOTRequestResponse(OTRequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/OTRequest/${encodeURIComponent(String(OTRequestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param OTRequestId undefined
   * @return Success
   */
  DeleteOTRequest(OTRequestId: number): __Observable<string> {
    return this.DeleteOTRequestResponse(OTRequestId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateOTforOthersRequestByEmployeeResponse(body?: OTRequestModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/OTRequest/CreateOTforOthersRequestByEmployee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateOTforOthersRequestByEmployee(body?: OTRequestModel): __Observable<string> {
    return this.CreateOTforOthersRequestByEmployeeResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateOTforOthersRequestByEmployeeResponse(body?: OTRequestModel): __Observable<__StrictHttpResponse<Array<RequestPerformActionInput>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/OTRequest/UpdateOTforOthersRequestByEmployee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RequestPerformActionInput>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateOTforOthersRequestByEmployee(body?: OTRequestModel): __Observable<Array<RequestPerformActionInput>> {
    return this.UpdateOTforOthersRequestByEmployeeResponse(body).pipe(
      __map(_r => _r.body as Array<RequestPerformActionInput>)
    );
  }

  /**
   * @param otRequestId undefined
   * @return Success
   */
  CancelOTRequestResponse(otRequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/OTRequest/${encodeURIComponent(String(otRequestId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param otRequestId undefined
   * @return Success
   */
  CancelOTRequest(otRequestId: number): __Observable<string> {
    return this.CancelOTRequestResponse(otRequestId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep1Response(body?: ImportOTRequestStep1Model): __Observable<__StrictHttpResponse<ImportOTRequestModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/OTRequest/importexcel/step1`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImportOTRequestModel>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep1(body?: ImportOTRequestStep1Model): __Observable<ImportOTRequestModel> {
    return this.ImportExcelStep1Response(body).pipe(
      __map(_r => _r.body as ImportOTRequestModel)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep2Response(body?: ImportOTRequestModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/OTRequest/importexcel/step2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  ImportExcelStep2(body?: ImportOTRequestModel): __Observable<string> {
    return this.ImportExcelStep2Response(body).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module OTRequestService {

  /**
   * Parameters for GetOTRequestsList
   */
  export interface GetOTRequestsListParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    RequestDate?: string;
    PersonnelAreaId?: number;
    Page?: number;
    OTTypeId?: string;
    OTStatusId?: string;
    OTRequestType?: any;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    EmployeeId?: number;
    CompanyId?: number;
  }

  /**
   * Parameters for GetMyOTRequestsList
   */
  export interface GetMyOTRequestsListParams {
    UnitId?: number;
    Sorting?: string;
    SearchText?: string;
    RequestDate?: string;
    PersonnelAreaId?: number;
    Page?: number;
    OTTypeId?: string;
    OTStatusId?: string;
    OTRequestType?: any;
    LocationId?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    EmployeeId?: number;
    CompanyId?: number;
  }
}

export { OTRequestService }
