/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AttendanceCertifyModelPaginationResult } from '../models/attendance-certify-model-pagination-result';
import { AttendanceCertifyModel } from '../models/attendance-certify-model';
@Injectable({
  providedIn: 'root',
})
class AttendanceCertifyService extends __BaseService {
  static readonly GetAttendanceCertifiesListPath = '/api/AttendanceCertify';
  static readonly CreateAttendanceCertifyPath = '/api/AttendanceCertify';
  static readonly UpdateAttendanceCertifyPath = '/api/AttendanceCertify';
  static readonly GetAttendanceCertifyByIdPath = '/api/AttendanceCertify/{attendanceCertifyId}';
  static readonly DeleteAttendanceCertifyPath = '/api/AttendanceCertify/{attendanceCertifyId}';
  static readonly UpdateAttendanceCertifyStatusPath = '/api/AttendanceCertify/{attendanceCertifyId}/status';
  static readonly CancelAttendanceCertifyPath = '/api/AttendanceCertify/{attendanceCertifyRequestId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AttendanceCertifyService.GetAttendanceCertifiesListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeId`:
   *
   * - `CreatedDate`:
   *
   * @return Success
   */
  GetAttendanceCertifiesListResponse(params: AttendanceCertifyService.GetAttendanceCertifiesListParams): __Observable<__StrictHttpResponse<AttendanceCertifyModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.EmployeeId != null) __params = __params.set('EmployeeId', params.EmployeeId.toString());
    if (params.CreatedDate != null) __params = __params.set('CreatedDate', params.CreatedDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AttendanceCertify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttendanceCertifyModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `AttendanceCertifyService.GetAttendanceCertifiesListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EmployeeId`:
   *
   * - `CreatedDate`:
   *
   * @return Success
   */
  GetAttendanceCertifiesList(params: AttendanceCertifyService.GetAttendanceCertifiesListParams): __Observable<AttendanceCertifyModelPaginationResult> {
    return this.GetAttendanceCertifiesListResponse(params).pipe(
      __map(_r => _r.body as AttendanceCertifyModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateAttendanceCertifyResponse(body?: AttendanceCertifyModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AttendanceCertify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateAttendanceCertify(body?: AttendanceCertifyModel): __Observable<string> {
    return this.CreateAttendanceCertifyResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateAttendanceCertifyResponse(body?: AttendanceCertifyModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/AttendanceCertify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateAttendanceCertify(body?: AttendanceCertifyModel): __Observable<string> {
    return this.UpdateAttendanceCertifyResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param attendanceCertifyId undefined
   * @return Success
   */
  GetAttendanceCertifyByIdResponse(attendanceCertifyId: number): __Observable<__StrictHttpResponse<AttendanceCertifyModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AttendanceCertify/${encodeURIComponent(String(attendanceCertifyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttendanceCertifyModel>;
      })
    );
  }
  /**
   * @param attendanceCertifyId undefined
   * @return Success
   */
  GetAttendanceCertifyById(attendanceCertifyId: number): __Observable<AttendanceCertifyModel> {
    return this.GetAttendanceCertifyByIdResponse(attendanceCertifyId).pipe(
      __map(_r => _r.body as AttendanceCertifyModel)
    );
  }

  /**
   * @param attendanceCertifyId undefined
   * @return Success
   */
  DeleteAttendanceCertifyResponse(attendanceCertifyId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/AttendanceCertify/${encodeURIComponent(String(attendanceCertifyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param attendanceCertifyId undefined
   * @return Success
   */
  DeleteAttendanceCertify(attendanceCertifyId: number): __Observable<string> {
    return this.DeleteAttendanceCertifyResponse(attendanceCertifyId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `AttendanceCertifyService.UpdateAttendanceCertifyStatusParams` containing the following parameters:
   *
   * - `attendanceCertifyId`:
   *
   * - `statusId`:
   *
   * @return Success
   */
  UpdateAttendanceCertifyStatusResponse(params: AttendanceCertifyService.UpdateAttendanceCertifyStatusParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.statusId != null) __params = __params.set('statusId', params.statusId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AttendanceCertify/${encodeURIComponent(String(params.attendanceCertifyId))}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `AttendanceCertifyService.UpdateAttendanceCertifyStatusParams` containing the following parameters:
   *
   * - `attendanceCertifyId`:
   *
   * - `statusId`:
   *
   * @return Success
   */
  UpdateAttendanceCertifyStatus(params: AttendanceCertifyService.UpdateAttendanceCertifyStatusParams): __Observable<string> {
    return this.UpdateAttendanceCertifyStatusResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param attendanceCertifyRequestId undefined
   * @return Success
   */
  CancelAttendanceCertifyResponse(attendanceCertifyRequestId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/AttendanceCertify/${encodeURIComponent(String(attendanceCertifyRequestId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param attendanceCertifyRequestId undefined
   * @return Success
   */
  CancelAttendanceCertify(attendanceCertifyRequestId: number): __Observable<string> {
    return this.CancelAttendanceCertifyResponse(attendanceCertifyRequestId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module AttendanceCertifyService {

  /**
   * Parameters for GetAttendanceCertifiesList
   */
  export interface GetAttendanceCertifiesListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    EmployeeId?: number;
    CreatedDate?: string;
  }

  /**
   * Parameters for UpdateAttendanceCertifyStatus
   */
  export interface UpdateAttendanceCertifyStatusParams {
    attendanceCertifyId: number;
    statusId?: string;
  }
}

export { AttendanceCertifyService }
