/* tslint:disable */
type ERelativeType =
  'Father' |
  'Mother' |
  'Spouse' |
  'Child' |
  'Sibling' |
  'Relative' |
  'Lover' |
  'Friend' |
  'Protege' |
  'Other';
module ERelativeType {
  export const FATHER: ERelativeType = 'Father';
  export const MOTHER: ERelativeType = 'Mother';
  export const SPOUSE: ERelativeType = 'Spouse';
  export const CHILD: ERelativeType = 'Child';
  export const SIBLING: ERelativeType = 'Sibling';
  export const RELATIVE: ERelativeType = 'Relative';
  export const LOVER: ERelativeType = 'Lover';
  export const FRIEND: ERelativeType = 'Friend';
  export const PROTEGE: ERelativeType = 'Protege';
  export const OTHER: ERelativeType = 'Other';
  export function values(): ERelativeType[] {
    return [
      FATHER,
      MOTHER,
      SPOUSE,
      CHILD,
      SIBLING,
      RELATIVE,
      LOVER,
      FRIEND,
      PROTEGE,
      OTHER
    ];
  }
}

export { ERelativeType }