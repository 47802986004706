/* tslint:disable */
type EReturnFileType =
  'NotSpecified' |
  'Excel' |
  'PDF';
module EReturnFileType {
  export const NOT_SPECIFIED: EReturnFileType = 'NotSpecified';
  export const EXCEL: EReturnFileType = 'Excel';
  export const PDF: EReturnFileType = 'PDF';
  export function values(): EReturnFileType[] {
    return [
      NOT_SPECIFIED,
      EXCEL,
      PDF
    ];
  }
}

export { EReturnFileType }