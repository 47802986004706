/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NewsModelPaginationResult } from '../models/news-model-pagination-result';
import { NewsModel } from '../models/news-model';
@Injectable({
  providedIn: 'root',
})
class NewsService extends __BaseService {
  static readonly GetNewsPath = '/api/News';
  static readonly CreateNewPath = '/api/News';
  static readonly UpdateNewPath = '/api/News';
  static readonly GetNewByIdPath = '/api/News/{newId}';
  static readonly DeleteNewPath = '/api/News/{newId}';
  static readonly PublishNewPath = '/api/News/{newId}/publish';
  static readonly GetNewsImagePath = '/api/News/{newId}/image/{pictureId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `NewsService.GetNewsParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsPublish`:
   *
   * - `Headline`:
   *
   * - `Date`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  GetNewsResponse(params: NewsService.GetNewsParams): __Observable<__StrictHttpResponse<NewsModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.IsPublish != null) __params = __params.set('IsPublish', params.IsPublish.toString());
    if (params.Headline != null) __params = __params.set('Headline', params.Headline.toString());
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    (params.CompanyIds || []).forEach(val => {if (val != null) __params = __params.append('CompanyIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/News`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NewsModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `NewsService.GetNewsParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `IsPublish`:
   *
   * - `Headline`:
   *
   * - `Date`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  GetNews(params: NewsService.GetNewsParams): __Observable<NewsModelPaginationResult> {
    return this.GetNewsResponse(params).pipe(
      __map(_r => _r.body as NewsModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateNewResponse(body?: NewsModel): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/News`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateNew(body?: NewsModel): __Observable<number> {
    return this.CreateNewResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateNewResponse(body?: NewsModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/News`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateNew(body?: NewsModel): __Observable<string> {
    return this.UpdateNewResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param newId undefined
   * @return Success
   */
  GetNewByIdResponse(newId: number): __Observable<__StrictHttpResponse<NewsModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/News/${encodeURIComponent(String(newId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NewsModel>;
      })
    );
  }
  /**
   * @param newId undefined
   * @return Success
   */
  GetNewById(newId: number): __Observable<NewsModel> {
    return this.GetNewByIdResponse(newId).pipe(
      __map(_r => _r.body as NewsModel)
    );
  }

  /**
   * @param newId undefined
   * @return Success
   */
  DeleteNewResponse(newId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/News/${encodeURIComponent(String(newId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param newId undefined
   * @return Success
   */
  DeleteNew(newId: number): __Observable<string> {
    return this.DeleteNewResponse(newId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param newId undefined
   * @return Success
   */
  PublishNewResponse(newId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/News/${encodeURIComponent(String(newId))}/publish`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param newId undefined
   * @return Success
   */
  PublishNew(newId: number): __Observable<string> {
    return this.PublishNewResponse(newId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `NewsService.GetNewsImageParams` containing the following parameters:
   *
   * - `pictureId`:
   *
   * - `newId`:
   */
  GetNewsImageResponse(params: NewsService.GetNewsImageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/News/${encodeURIComponent(String(params.newId))}/image/${encodeURIComponent(String(params.pictureId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `NewsService.GetNewsImageParams` containing the following parameters:
   *
   * - `pictureId`:
   *
   * - `newId`:
   */
  GetNewsImage(params: NewsService.GetNewsImageParams): __Observable<null> {
    return this.GetNewsImageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module NewsService {

  /**
   * Parameters for GetNews
   */
  export interface GetNewsParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    IsPublish?: boolean;
    Headline?: string;
    Date?: string;
    CompanyIds?: Array<number>;
  }

  /**
   * Parameters for GetNewsImage
   */
  export interface GetNewsImageParams {
    pictureId: number;
    newId: number;
  }
}

export { NewsService }
