<div class="grid">
  <div class="col-12">
    <p-tree
      [value]="treeModel"
      [loading]="loading"
      selectionMode="single"
      [selection]="selectedNode"
      (onNodeSelect)="onNodeSelect($event)"
      (onNodeUnselect)="onNodeUnselect($event)"
    ></p-tree>
  </div>
</div>
