import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-new-employee',
  templateUrl: './new-employee.component.html',
  styleUrls: ['./new-employee.component.css'],
  providers: [TranslateService]
})
export class NewEmployeeComponent implements OnInit {

  constructor(   public translate: TranslateService,) {
    const browserLang = translate.getBrowserLang();
		//translate.use(browserLang.match(/th|en/)? browserLang :'th');
        // this.translate = translate;
        translate.use('th');
         this.translate.setDefaultLang('th');
   }

  ngOnInit(): void {
    // disable responsive layout
    var viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute('content', 'width=1020');
    window.dispatchEvent(new Event('resize'));
    document.querySelector('app-new-employee').setAttribute('style','width:100%;min-width:1024px;display:block');

  }

}
