import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { FilterSettingService } from 'app/api/services';
import { AppNotificationServiceService } from '../../../shared/app-notification-service.service';
import { AuthenticationService } from 'services/authentication.service';

@Component({
  selector: 'app-filter-setting',
  templateUrl: './filter-setting.component.html',
  styleUrls: ['./filter-setting.component.css'],
})
export class FilterSettingComponent implements OnInit {
  readonly lastFilterName = 'Last';

  selectFilter: any;
  filterList: any[] = [];
  filterEvent = new EventEmitter<any>();

  @Output() filterChange = new EventEmitter();
  @Input() getSettingStr = () => '{}';
  @Input() getExraUrl = () => '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private filterSettingService: FilterSettingService,
    private notificationService: AppNotificationServiceService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.setFilterList();
    this.observAndEmitFilterObj();
  }

  setFilterList() {
    this.filterSettingService
      .GetFilterSettingList({ UserId: this.authenticationService.getUserInfo().userId, PageUrl: this.getPurePageUrl() })
      .subscribe(filterSettingList => {
        this.filterList = filterSettingList.map(f => {
          return {
            label: f.name,
            value: f.name,
            filter: f.setting,
          };
        });
        this.selectFilter = this.filterList.find(v => v.value === this.lastFilterName);
        if (!this.router.url.includes('?') && this.selectFilter?.filter) {
          this.filterChange.emit(JSON.parse(this.selectFilter.filter));
        }
      });
  }

  onUpsertFilterSettingClick() {
    this.notificationService.input('ชื่อ Setting', '128').then(value => {
      if (value.isConfirmed && value.value) {
        this.upsertFilterSetting(value.value).subscribe(
          () => {
            this.setFilterList();
            this.notificationService.saveCompleted();
          },
          err => {
            console.log(err);
            this.notificationService.error('บันทึกล้มเหลว', '');
          },
        );
      }
    });
  }

  upsertFilterSetting(name?: string) {
    const setting = this.getSettingStr();

    if (!name) {
      return this.filterSettingService
        .UpsertLastFilterSetting({
          userId: this.authenticationService.getUserInfo().userId,
          pageUrl: this.getPurePageUrl(),
          name,
          setting,
        })
        .pipe(
          finalize(() => {
            this.setFilterList();
          }),
        );
    }

    return this.filterSettingService
      .UpsertFilterSetting({
        userId: this.authenticationService.getUserInfo().userId,
        pageUrl: this.getPurePageUrl(),
        name,
        setting,
      })
      .pipe(
        finalize(() => {
          this.setFilterList();
        }),
      );
  }

  onDeleteFilterSetting() {
    this.notificationService.confirm('ยืนยันการลบ', '').then(value => {
      if (value.isConfirmed) {
        this.filterSettingService
          .DeleteFilterSetting({
            userId: this.authenticationService.getUserInfo().userId,
            pageUrl: this.getPurePageUrl(),
            name: this.selectFilter.value,
          })
          .subscribe(
            () => {
              this.setFilterList();
              this.notificationService.deleteCompleted();
            },
            err => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
      }
    });
  }

  getPurePageUrl() {
    let url = this.router.url;
    if (url.includes('?')) {
      url = url.split('?')[0];
    }

    return url + this.getExraUrl();
  }

  onFilterDropdownChange() {
    this.filterEvent.emit(JSON.parse(this.selectFilter.filter));
  }

  observAndEmitFilterObj() {
    this.filterEvent.pipe(debounceTime(100)).subscribe(filterObj => {
      this.filterChange.emit(filterObj);
      this.filterSettingService
        .UpsertLastFilterSetting({
          userId: this.authenticationService.getUserInfo().userId,
          pageUrl: this.getPurePageUrl(),
          setting: this.getSettingStr(),
        })
        .subscribe();
    });
  }
}
