import { Component, OnInit } from '@angular/core';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { CostCenterService } from 'app/api/services';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CostCenterModel } from 'app/api/models';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { MasterDataRepo } from 'app/repo/master-data.repo';

@Component({
  selector: 'app-modal-cost-centers',
  templateUrl: './modal-cost-centers.component.html',
  styleUrls: ['./modal-cost-centers.component.css'],
})
export class ModalCostCentersComponent implements OnInit {
  constructor(
    private _notificationService: AppNotificationServiceService,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private costCenterService: CostCenterService,
    private masterDataRepo: MasterDataRepo,
  ) {}

  options: FormlyFormOptions = {};
  data: any;
  private unsubscribe$: Subject<any> = new Subject();

  form = new FormGroup({});
  model: CostCenterModel = {};

  fields: FormlyFieldConfig[] = [
    {
      key: 'code',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'code',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'cost_center_name',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'companyId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'company',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: this.masterDataRepo.GetCompanyList(),
      },
    },
  ];

  ngOnInit(): void {
    this.data = this.config.data;
    this.costCenterService
      .GetCostCenter(this.data.id)
      .pipe(takeUntil(this.unsubscribe$))
      .toPromise()
      .then((response: CostCenterModel) => {
        this.model = response;
      });
  }

  submit(model) {
    if (this.config.data.id == 0) {
      this.costCenterService
        .CreateCostCenter(this.model)
        .pipe(takeUntil(this.unsubscribe$))
        .toPromise()
        .then((response: any) => {
          this._notificationService.saveCompleted();
          this.ref.close(this.model);
        });
    } else {
      this.costCenterService
        .UpdateCostCenter({
          id: this.config.data.id,
          body: this.model,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .toPromise()
        .then((response: any) => {
          this._notificationService.saveCompleted();
          this.ref.close(this.model);
        });
    }
  }

  close() {
    this.ref.close();
  }
}
