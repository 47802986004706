import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHolidayComponent } from './modal-holiday/modal-holiday.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { SharedModule } from '../../shared/shared.module';
import { ModalLeaveRequestComponent } from './modal-leave-request/modal-leave-request.component';
import { ModalAttendanceCertifyRequestComponent } from './modal-attendance-certify-request/modal-attendance-certify-request.component';
import { ModalOtRequestComponent } from './modal-ot-request/modal-ot-request.component';
import { ModalImportEmployeeWithShiftsComponent } from './modal-import-employee-with-shifts/modal-import-employee-with-shifts.component';
import { ModalExportMonthlyShiftComponent } from './modal-export-monthly-shift/modal-export-monthly-shift.component';
import { ModalImportMonthlyShiftComponent } from './modal-import-monthly-shift/modal-import-monthly-shift.component';
import { ModalImportLeaveRequestComponent } from './modal-import-leave-request/modal-import-leave-request.component';
import { ModalShowTimeEventComponent } from './modal-show-time-event/modal-show-time-event.component';

const allModals = [
  ModalHolidayComponent,
  ModalLeaveRequestComponent,
  ModalAttendanceCertifyRequestComponent,
  ModalOtRequestComponent,
  ModalImportEmployeeWithShiftsComponent,
  ModalExportMonthlyShiftComponent,
  ModalImportMonthlyShiftComponent,
  ModalShowTimeEventComponent
];

@NgModule({
  declarations: [...allModals, ModalImportLeaveRequestComponent, ModalShowTimeEventComponent],
  imports: [CommonModule, DynamicDialogModule, SharedModule],
  exports: [...allModals]
})
export class TaModule { }
