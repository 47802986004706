<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <div class="grid justify-content-between">
          <div class="col-fixed">
            <h1 *ngIf="isFromExternalRequest || isFromApprovalCenter">{{ 'new_employee' | translate }}</h1>
            <h1 *ngIf="isAdminEmployeeCreate">{{ 'add_employees' | translate }}</h1>
          </div>
          <div class="grid col-fixed">
            <div class="grid col-fixed">
              <div  class="col-fixed" *ngIf="!isFromExternalRequest">
                <button pButton class="p-button-danger" label="กลับไปหน้าที่แล้ว" (click)="goBack()"></button>
              </div>
              <div class="col-fixed" hidden>
                <button pButton label="{{ 'save_draft' | translate }}" (click)="onSaveDraft()"></button>
              </div>
              <div  class="col-fixed" *ngIf="!isFromExternalRequest && this.requestId">
                <button pButton class="p-button-danger" label="{{ 'delete_data' | translate }}" (click)="deleteEmployee()"></button>
              </div>
              <div class="col-fixed">

                <button
                  *ngIf="!isFromExternalRequest"
                  pButton
                  type="submit"
                  label="{{ 'create_new_employees' | translate }}"
                  (click)="onCreateEmployeeBtnClick()"
                  [disabled]="
                    isInvalidPersonalInfoForm ||
                    isInvalidAddressInfoForm ||
                    isInvalidFamilyInfoForm ||
                    isInvalidChildInfoForm ||
                    isInvalidEmergencyInfoForm ||
                    isInvalidEducationInfoForm ||
                    isInvalidExperienceInfoForm ||
                    isInvalidOtherInfoForm ||
                    isInvalidEmploymentInfoForm
                  "
                ></button>
              </div>
              <div class="col-fixed">
                <button
                  *ngIf="isFromExternalRequest"
                  pButton
                  type="button"
                  label="{{ 'submit_request_form' | translate }}"
                  (click)="sendNewEmployeeForm()"
                  [disabled]="
                    isInvalidPersonalInfoForm ||
                    isInvalidAddressInfoForm ||
                    isInvalidFamilyInfoForm ||
                    isInvalidChildInfoForm ||
                    isInvalidEmergencyInfoForm ||
                    isInvalidEducationInfoForm ||
                    isInvalidExperienceInfoForm ||
                    isInvalidOtherInfoForm
                  "
                ></button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-11 md:col-8 lg:col-4 ml-auto" *ngIf="isFromExternalRequest">
          <div class="grid align-center">
            <div class="col-4 text-right">{{ 'choose_company' | translate }} :</div>
            <div class="col-8">
              <p-dropdown
                [options]="companyOption"
                [(ngModel)]="selectedCompany"
                [placeholder]="'choose_company' | translate">
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card">
            <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event)">
              <p-tabPanel header="{{ 'general_information' | translate }}" leftIcon="pi pi-calendar">
                <app-employee-create-form-personal-info
                  [defaultModel]="defaultUserDataModel.personalInfo"
                ></app-employee-create-form-personal-info>
                <hr style="margin-top: 20px; margin-bottom: 20px" />
                <app-employee-create-form-address-info
                  [defaultModel]="defaultUserDataModel.addressInfo"
                ></app-employee-create-form-address-info>
                <hr style="margin-top: 20px; margin-bottom: 20px" />
              </p-tabPanel>

              <p-tabPanel header="{{ 'family_information' | translate }}" leftIcon="pi pi-inbox">
                <app-employee-create-form-family-info
                  [maritalStatus]="personalInfo?.model?.maritalStatusId"
                  [defaultModel]="defaultUserDataModel.familyInfo"
                >
                </app-employee-create-form-family-info>
                <app-employee-create-form-child
                  [defaultModel]="defaultUserDataModel.childInfo"
                ></app-employee-create-form-child>
                <hr style="margin-top: 20px; margin-bottom: 20px" />
                <app-employee-create-form-emergency
                  [defaultModel]="defaultUserDataModel.emergencyInfo"
                ></app-employee-create-form-emergency>
              </p-tabPanel>

              <p-tabPanel header="{{ 'educational_history' | translate }}" leftIcon="pi pi-inbox">
                <app-employee-create-form-education
                  [defaultModel]="defaultUserDataModel.educationInfo"
                ></app-employee-create-form-education>
              </p-tabPanel>

              <p-tabPanel header="{{ 'work_experience' | translate }}" leftIcon="pi pi-user">
                <app-employee-create-form-experience
                  [defaultModel]="defaultUserDataModel.experienceInfo"
                ></app-employee-create-form-experience>
              </p-tabPanel>

              <p-tabPanel header="{{ 'other_information' | translate }}" leftIcon="pi pi-user">
                <app-employee-create-form-other-info
                  [defaultModel]="defaultUserDataModel.otherInfo"
                ></app-employee-create-form-other-info>
                <hr style="margin-top: 20px; margin-bottom: 20px" />
              </p-tabPanel>

              <p-tabPanel header="{{ 'attach_file' | translate }}" leftIcon="pi pi-file">
                <app-employee-create-form-attach-file
                  [defaultModel]="defaultUserDataModel.attachmentInfo"
                ></app-employee-create-form-attach-file>
                <hr style="margin-top: 20px; margin-bottom: 20px" />
              </p-tabPanel>

              <p-tabPanel
                header="{{ 'employment_information' | translate }}"
                leftIcon="pi pi-user"
                *ngIf="!isFromExternalRequest"
              >
                <app-employee-create-form-employment-information></app-employee-create-form-employment-information>
                <hr style="margin-top: 20px; margin-bottom: 20px" />
              </p-tabPanel>
            </p-tabView>
            <div class="grid justify-content-between">
              <div class="col-fixed">
                <div [hidden]="activeTabIndex === 0">
                  <button
                    type="button"
                    pButton
                    (click)="onOpenPrevTabBtnClick()"
                    label="{{ 'back' | translate }}"
                    class="p-button-secondary"
                  ></button>
                </div>
              </div>
              <div class="col-fixed">
                <!-- <div [hidden]="(activeTabIndex + 2 < 8)">

                  <button
                    type="button"
                    pButton
                    (click)="onGoToEmployeeBtnClick()"
                    label="{{ 'goto employee' | translate }}"
                    class="p-button-secondary"
                  ></button>
                </div> -->
                <div [hidden]="activeTabIndex === countTab - 1">
                  <button
                    type="button"
                    pButton
                    (click)="onOpenNextTabBtnClick()"
                    label="{{ 'next' | translate }}"
                    class="p-button-secondary"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
