/* tslint:disable */
type EShiftDefinitionType =
  'Normal' |
  'AutoMatchAbsentQuota';
module EShiftDefinitionType {
  export const NORMAL: EShiftDefinitionType = 'Normal';
  export const AUTO_MATCH_ABSENT_QUOTA: EShiftDefinitionType = 'AutoMatchAbsentQuota';
  export function values(): EShiftDefinitionType[] {
    return [
      NORMAL,
      AUTO_MATCH_ABSENT_QUOTA
    ];
  }
}

export { EShiftDefinitionType }