import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PayElementModel } from 'app/api/models';
import { MasterDataService, PayElementService } from 'app/api/services';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize, map } from 'rxjs/operators';
import { GlCodeService } from '../../../api/services/gl-code.service';

enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface PayElementFormModel extends PayElementModel {
  isActive?: boolean;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof PayElementFormModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-modal-pay-element',
  templateUrl: './modal-pay-element.component.html',
  styleUrls: ['./modal-pay-element.component.css'],
})
export class ModalPayElementComponent implements OnInit, OnDestroy {
  payElementIdList: string[];
  componentMode: ComponentMode;
  model: PayElementFormModel;
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'payElementId',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'code',
        placeholder: '@blank',

        labelClass: 'col-4',
        inputClass: 'col-6',
      },
      asyncValidators: {
        uniqueId: {
          expression: (control: FormControl) => {
            return this.payElementService
              .GetPayElementIdList()
              .pipe(map(a => !a.includes(control.value)))
              .toPromise();
          },
          message: 'รหัสซ้ำ กรุณากรอกใหม่',
        },
      },
      hooks: {
        afterContentInit: field => {
          if (this.componentMode === ComponentMode.UPDATE) {
            field.formControl.disable();
          }
        },
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,

        attributes: {
          style: 'width: 100%',
        },
        label: 'item_name',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'payElementType',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'type',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: this.masterDataService.GetPayElementTypes()
          .pipe(map(a => a.map(b => ({ label: b.description, value: b.payElementType }))))
      },
    },
    {
      key: 'glCodeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'รหัสบัญชี (GL Code)',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: this.glCodeService.GetGlCodes({ Page: 1, ItemPerPage: 1000 })
          .pipe(map(a => a.results.map(a => ({ label: `${a.code} ${a.name}`, value: a.glCodeId }))))
      },
    },
    {
      key: 'includedInTax',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'ใช้คำนวณภาษี',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
      hideExpression: (model: PayElementModel) => model.payElementType !== 'Income'
    },
    {
      key: 'includedInSso',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'ใช้คำนวณประกันสังคม',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6'
      },
      hideExpression: (model: PayElementModel) => model.payElementType !== 'Income'
    },
    {
      key: 'payElementSubType',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        required: false,
        attributes: {
          style: 'width: 100%',
        },
        label: 'subtype',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: this.masterDataService.GetPayElementSubTypes()
          .pipe(map(a => a.map(b => ({ label: b.description, value: b.payElementSubType }))))
      },
    },
    {
      key: 'description',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'description',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'isActive',
      type: 'switch-input',
      defaultValue: true,
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'status',
        placeholder: '@blank',
        labelClass: 'col-4',
        inputClass: 'col-6',
        trueMessage: 'กำลังใช้งาน',
        falseMessage: 'ระงับใช้งาน',
      },
    },
  ];
  unsubscripe$ = new Subject<any>();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notificationService: AppNotificationServiceService,
    private payElementService: PayElementService,
    private masterDataService: MasterDataService,
    private masterDataRepo: MasterDataRepo,
    private glCodeService: GlCodeService
  ) { }

  async ngOnInit() {
    this.initComponentMode();
    await this.initData();

    console.log(this.config.data);
  }

  ngOnDestroy(): void {
    this.unsubscripe$.unsubscribe();
  }

  initComponentMode() {
    const payElementId = this.getPayElementId();

    if (payElementId) {
      this.componentMode = ComponentMode.UPDATE;
    } else {
      this.componentMode = ComponentMode.CREATE;
    }
  }

  async initData() {
    if (this.componentMode === ComponentMode.CREATE) {
      this.model = {};
      return;
    }

    const payElement = await this.payElementService.GetPayElementById(this.getPayElementId()).toPromise();
    this.model = {
      ...payElement,
      isActive: payElement.status === 'Active',
    };
  }

  submit() {
    switch (this.componentMode) {
      case ComponentMode.CREATE:
        this.createPayElement();
        break;
      case ComponentMode.UPDATE:
        this.updatePayElement();
        break;
      default:
        break;
    }
  }

  close() {
    this.ref.close();
  }

  private createPayElement() {
    this.payElementService
      .CreatePayElement({
        ...this.model,
        status: this.model.isActive ? 'Active' : 'InActive',
      })
      .subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.ref.close(this.model);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  private updatePayElement() {
    this.payElementService
      .UpdatePayElement({
        ...this.model,
        status: this.model.isActive ? 'Active' : 'InActive',
      })
      .subscribe(
        () => {
          this.notificationService.saveCompleted();
          this.ref.close(this.model);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  getPayElementId() {
    return this.config.data?.id;
  }

  payElementIdValidator(control: FormControl): ValidationErrors {
    return this.config.data.includes(control.value);
  }

  // payElementId: {
  //   expression: [true],
  //   message: (error, field: CustomFormlyFieldConfig) => `รหัส ${field.formControl.value} ซ้ำ กรุณาลองอีกครั้ง`,
  // },
}
