<div class="login-body layout-light" *ngIf="appSettings">
  <div class="login-content" style="width: 100%">
    <div class="login-panel" style="width: 100%">
      <div class="col-6 non-desktop-hide" style="
          height: 100%;
          background-size: cover;
        "
        [ngStyle]="{'background-image': 'url(/api/document/' + appSettings.webBackgroundLargeImageId1 + '/download'}">
      </div>
      <div class="login-panel-content !tw-px-10 !tw-py-8"
        style="height: 100%; text-align: left; background-color: white">
        <div class="grid align-center" style="height: 100%">
          <form class="col align-center" #form="ngForm" (ngSubmit)="loginUsingPassword()">
            <img [src]="'/api/document/' + appSettings.webLoginLogoImageId + '/download'" width="300px" />
            <h1>ยินดีต้อนรับสู่ระบบบริหารงานบุคคล</h1>
            <p>
              กรุณาเลือกบทบาทสำหรับการใช้งานระบบ
            </p>
            <p-tabView (onChange)="handleChange($event)">
              <p-tabPanel header="1. พนักงาน">
                <h3>เข้าสู่ระบบสำหรับพนักงาน</h3>
                <h4>Username</h4>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="fas fa-user" style="line-height: 1.25"></i></span>
                  <input type="text" style="width: 100%" pInputText placeholder="username" [(ngModel)]="username"
                    name="username" />
                </div>
                <h3>Password</h3>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="fas fa-lock" style="line-height: 1.25"></i></span>
                  <input type="password" style="width: 100%" pInputText placeholder="Password" [(ngModel)]="password"
                    name="password" />
                </div>
                <p class="error">{{ errorMessage }}</p>
              </p-tabPanel>
              <p-tabPanel header="2. เจ้าหน้าที่ฝ่ายบุคคล" *ngIf="!viewUtilsService.isMobile()">
                <h3>เข้าสู่ระบบสำหรับเจ้าหน้าที่ฝ่ายบุคคล</h3>
                <h4>Username</h4>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="fas fa-user" style="line-height: 1.25"></i></span>
                  <input type="text" style="width: 100%" pInputText placeholder="username" [(ngModel)]="username"
                    name="username" />
                </div>
                <h3>Password</h3>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="fas fa-lock" style="line-height: 1.25"></i></span>
                  <input type="password" style="width: 100%" pInputText placeholder="Password" [(ngModel)]="password"
                    name="password" />
                </div>
                <p class="error">{{ errorMessage }}</p>
              </p-tabPanel>
            </p-tabView>
            <!-- ซ่อนไว้ก่อนยังไม่รู้จะต้องมี Function นี้ไหม
            <div class="grid" style="margin-bottom: 2%;">
              <div class="col">
                <p-checkbox name="groupname" label="Remember Me" value="val1" [(ngModel)]="selectedValues"></p-checkbox>
              </div>
              <div class="col" style="text-align: right;">
                <a [routerLink]="['/forget']">Forget Password?</a>
              </div>
            </div>
            -->
            <div class="p-fluid tw-pt-4">
              <div class="grid justify-content-even">
                <div class="col-12">
                  <button type="submit" style="height: 50px; background-color: rgb(81, 185, 226)" pButton
                    class="p-button-primary" label="เข้าสู่ระบบ" [disabled]="!form.valid"></button>
                </div>
                <div class="col-12">
                  <button type="button" style="height: 50px" pButton class="p-button-secondary"
                    label="เข้าสู่ระบบด้วย Google Account" [disabled]="!isGoogleAvailable"
                    (click)="loginUsingGoogleAccount()"></button>
                </div>
              </div>
            </div>

            <div class="ui-fluid">
              <div class="p-grid p-justify-left" style="margin-top: 1%; margin-left: 1%">
                <a style="color: rgb(81, 185, 226) !important"
                  href="https://starkempowerment.com:8443/2024/public/login">เข้าสู่ระบบ PMR</a>
              </div>
              <!-- Download แบบ .pdf -->
              <!-- <div class="p-grid p-justify-left" style="margin-top: 1%; margin-left: 1%">
                <a style="color: rgb(81, 185, 226) !important; cursor: pointer" (click)="downloadEmployeeManual()"
                  >Download คู่มือการใช้ระบบสำหรับพนักงาน
                </a>
              </div> -->
              <!-- Gitbook -->
              <div class="p-grid p-justify-left" style="margin-top: 1%; margin-left: 1%">
                <a style="color: rgb(81, 185, 226) !important; cursor: pointer"
                  href="https://ayodia.gitbook.io/stark-hr-user-manual-for-employee/">คู่มือการใช้ระบบสำหรับพนักงาน</a>
              </div>
              <div class="p-grid p-justify-left" style="margin-top: 1%; margin-left: 1%">
                <a style="color: rgb(81, 185, 226) !important; cursor: pointer"
                  href="http://starkempowerment.com/#/ios-redeemed-code">ดาวน์โหลดแอปพลิเคชันสำหรับ iOS</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
