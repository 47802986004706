import { Component, OnInit } from '@angular/core';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PasswordModule, Password } from 'primeng/password';
import { ApplicationSettingsService } from '../../../../services/application-settings.service';
import { AuthenticationService } from 'app/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationSettingsExtended, EAuthenticationType } from 'app/api/models';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

interface ResetPasswordFormModel {
  authenticationType?: EAuthenticationType;
  password?: string;
  passwordConfirm?: string;
  email?: string;
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof ResetPasswordFormModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

@Component({
  selector: 'app-app.reset',
  templateUrl: './app.reset.component.html',
  styleUrls: ['./app.reset.component.css'],
})
export class AppResetComponent implements OnInit {
  model: ResetPasswordFormModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      validators: {
        validation: [{ name: 'fieldMatch', options: { errorPath: 'passwordConfirm' } }],
      },
      fieldGroup: [
        {
          key: 'authenticationType',
          type: 'radio',
          wrappers: ['inline-label'],
          defaultValue: 'Password',
          templateOptions: {
            label: 'ประเภทการเข้าสู่ระบบ',
            labelClass: 'col-5 md:col-4',
            inputClass: 'col-6 md:col-6',
            options: [
              { label: 'Username และ Password', value: 'Password' },
              { label: 'Google Account', value: 'GoogleAccount' }
            ],
          },
        },
        {
          key: 'email',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          hideExpression: `model.authenticationType == 'Password'`,
          templateOptions: {
            addonLeft: {
              class: 'fas fa-envelope-square',
            },
            label: 'Email',
            labelClass: 'col-5 md:col-4',
            inputClass: 'col-6 md:col-6',
            required: true,
            maxLength: 32,
          },
          validators: {
            validation: ['email'],
          },
        },
        {
          key: 'password',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          hideExpression: `model.authenticationType == 'GoogleAccount'`,
          templateOptions: {
            addonLeft: {
              class: 'fas fa-lock',
            },
            attributes: {
              autocomplete: 'new-password',
            },
            label: 'รหัสผ่าน',
            labelClass: 'col-5 md:col-4',
            inputClass: 'col-6 md:col-6',
            required: true,
            maxLength: 1024,
            type: 'password',
            minLength: 8,
          },
          hooks: {
            afterViewInit: (f) => {
              document.getElementById(f.id).focus();
            }
          }
          // validators: {
          //   validation: ['password'],
          // },
        },
        {
          key: 'passwordConfirm',
          type: 'primeng-input',
          wrappers: ['inline-label'],
          hideExpression: `model.authenticationType == 'GoogleAccount'`,
          templateOptions: {
            addonLeft: {
              class: 'fas fa-lock',
            },
            label: 'ยืนยันหัสผ่าน',
            labelClass: 'col-5 md:col-4',
            inputClass: 'col-6 md:col-6',
            required: true,
            maxLength: 1024,
            type: 'password',
          },
        },
      ],
    },
  ];

  dark: boolean;
  checked: boolean;
  property: Password;
  selectedValues: string[] = [];
  appSettings: ApplicationSettingsExtended;

  constructor(
    public applicationSettingService: ApplicationSettingsService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: AppNotificationServiceService,
  ) { }

  async ngOnInit() {
    this.model = {
      email: this.authenticationService.getUserInfo().email,
      authenticationType: 'Password',
    };
    this.appSettings = await this.applicationSettingService.getApplicationSettings().toPromise();
  }

  submit() {

    this.authenticationService
      .resetPassword({
        newPassword: this.model.password,
        newEmail: this.model.email,
        authenticationType: this.model.authenticationType,
      })
      .subscribe(
        () => {
          this.router.navigate(['/account/login']);
        },
        (err: HttpErrorResponse) => {
          const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
          this.notificationService.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
        },
      );
  }

  close() { }

  toggle() {
    console.log('toggle');
  }
}
