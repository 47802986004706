/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeRelativeModel } from '../models/employee-relative-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeRelativeService extends __BaseService {
  static readonly GetEmployeeRelativeListPath = '/api/EmployeeRelative';
  static readonly CreateEmployeeRelativePath = '/api/EmployeeRelative';
  static readonly MultiUpsertEmployeeRelativePath = '/api/EmployeeRelative';
  static readonly GetEmployeeRelativePath = '/api/EmployeeRelative/{employeeRelativeId}';
  static readonly UpdateEmployeeRelativePath = '/api/EmployeeRelative/{employeeRelativeId}';
  static readonly DeleteEmployeeRelativePath = '/api/EmployeeRelative/{employeeRelativeId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeRelativeListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeRelativeModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeRelative`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeRelativeModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeRelativeList(): __Observable<Array<EmployeeRelativeModel>> {
    return this.GetEmployeeRelativeListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeRelativeModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeRelativeResponse(body?: EmployeeRelativeModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeRelative`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeRelative(body?: EmployeeRelativeModel): __Observable<string> {
    return this.CreateEmployeeRelativeResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  MultiUpsertEmployeeRelativeResponse(body?: Array<EmployeeRelativeModel>): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeRelative`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  MultiUpsertEmployeeRelative(body?: Array<EmployeeRelativeModel>): __Observable<string> {
    return this.MultiUpsertEmployeeRelativeResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeRelativeId undefined
   * @return Success
   */
  GetEmployeeRelativeResponse(employeeRelativeId: number): __Observable<__StrictHttpResponse<EmployeeRelativeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeRelative/${encodeURIComponent(String(employeeRelativeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeRelativeModel>;
      })
    );
  }
  /**
   * @param employeeRelativeId undefined
   * @return Success
   */
  GetEmployeeRelative(employeeRelativeId: number): __Observable<EmployeeRelativeModel> {
    return this.GetEmployeeRelativeResponse(employeeRelativeId).pipe(
      __map(_r => _r.body as EmployeeRelativeModel)
    );
  }

  /**
   * @param params The `EmployeeRelativeService.UpdateEmployeeRelativeParams` containing the following parameters:
   *
   * - `employeeRelativeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeRelativeResponse(params: EmployeeRelativeService.UpdateEmployeeRelativeParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeRelative/${encodeURIComponent(String(params.employeeRelativeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeRelativeService.UpdateEmployeeRelativeParams` containing the following parameters:
   *
   * - `employeeRelativeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeRelative(params: EmployeeRelativeService.UpdateEmployeeRelativeParams): __Observable<string> {
    return this.UpdateEmployeeRelativeResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param employeeRelativeId undefined
   * @return Success
   */
  DeleteEmployeeRelativeResponse(employeeRelativeId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeRelative/${encodeURIComponent(String(employeeRelativeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param employeeRelativeId undefined
   * @return Success
   */
  DeleteEmployeeRelative(employeeRelativeId: number): __Observable<string> {
    return this.DeleteEmployeeRelativeResponse(employeeRelativeId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeRelativeService {

  /**
   * Parameters for UpdateEmployeeRelative
   */
  export interface UpdateEmployeeRelativeParams {
    employeeRelativeId: number;
    body?: EmployeeRelativeModel;
  }
}

export { EmployeeRelativeService }
