/* tslint:disable */
type EListRelativeType =
  'Include' |
  'Exclude';
module EListRelativeType {
  export const INCLUDE: EListRelativeType = 'Include';
  export const EXCLUDE: EListRelativeType = 'Exclude';
  export function values(): EListRelativeType[] {
    return [
      INCLUDE,
      EXCLUDE
    ];
  }
}

export { EListRelativeType }