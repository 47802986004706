<div class="grid step-action">
  <div class="col-12">
    <div class="grid header-action" *ngIf="field.fieldGroup.length">
      <div class="col-12">
        <div class="grid">
          <div class="col-3"><b>ชื่อ Action *</b></div>
          <div class="col-3"><b>ขั้นตอนถัดไป *</b></div>
          <div class="col-4"><b>สถานะหลังจากดำเนินการ *</b></div>
          <div class="col-fixed"><b>อยู่ขั้นตอนปัจจุบัน</b></div>
        </div>
      </div>
    </div>
    <div class="grid" *ngFor="let afield of field.fieldGroup; let i = index">
      <div class="col">
        <formly-field [field]="afield"></formly-field>
      </div>
      <div class="col-fixed" *ngIf="field.validation.show">
        <div class="grid">
          <div class="col-fixed">
            <button pButton type="button" class="p-button-danger" icon="pi pi-minus" label="ลบ"
              (click)="remove(i)"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="grid align-center step-action">
      <div class="col-fixed" *ngIf="field.validation.show">
        <button pButton type="button" icon="pi pi-plus" label="เพิ่ม action" (click)="add()"></button>
      </div>
    </div>
  </div>
</div>