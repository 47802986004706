import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AttendanceCertifyModel } from 'app/api/models';
import { AttendanceCertifyService, DocumentService, LeaveService, LocationService } from 'app/api/services';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'services/authentication.service';
import { AppNotificationServiceService } from 'shared/app-notification-service.service';
enum ComponentMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

interface SelectFileParam {
  currentFiles: File[];
}

interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof AttendanceCertifyModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

enum DefaultMessage {
  CONFIRM_CANCEL = 'ยืนยันการยกเลิกคำขอรับรองเวลา',
  CANCEL_SUCCESS = 'ยกเลิกคำขอรับรองเวลาสำเร็จ',
  CANCEL_FAILED = 'ยกเลิกคำขอรับรองเวลาล้มเหลว',
}

@Component({
  selector: 'app-modal-attendance-certify-request',
  templateUrl: './modal-attendance-certify-request.component.html',
  styleUrls: ['./modal-attendance-certify-request.component.css'],
})
export class ModalAttendanceCertifyRequestComponent implements OnInit {
  model: AttendanceCertifyModel = {};
  form = new FormGroup({});
  options: FormlyFormOptions = {
    formState: {
      disabled: false,
    },
  };
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'attendanceCertifyTypeId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'ประเภทการขอรับรองเวลา',
        labelClass: 'col-3',
        inputClass: 'col-9',
        options: this.masterDataRepo.getAttendanceCertifyTypeOptionList(),
        disabled: true,
      },
    },
    {
      key: 'locationId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'สถานที่ปฎิบัติงาน',
        labelClass: 'col-3',
        inputClass: 'col-4',
        options: this.locationService.GetLocationsList({}).pipe(
          map(pageResult => {
            return pageResult.results.map(loc => {
              return {
                label: loc.name,
                value: loc.locationId,
              };
            });
          }),
        ),
        disabled: true,
      },
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'beginDate',
          type: 'calendar',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            label: 'วันเริ่มต้น',
            labelClass: 'col-6',
            inputClass: 'col-6',
            disabled: true,
          },
        },
        {
          key: 'beginTime',
          type: 'time-picker',
          className: 'col-2',
          defaultValue: '00:00',
          wrappers: ['inline-label'],
          templateOptions: {
            inputClass: 'col',
            disabled: true,
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'endDate',
          type: 'calendar',
          className: 'col-6',
          wrappers: ['inline-label'],
          templateOptions: {
            label: 'วันที่สิ้นสุด',
            labelClass: 'col-6',
            inputClass: 'col-6',
            disabled: true,
          },
        },
        {
          key: 'endTime',
          type: 'time-picker',
          className: 'col-2',
          defaultValue: '00:00',
          wrappers: ['inline-label'],
          templateOptions: {
            inputClass: 'col',
            disabled: true,
          },
        },
      ],
    },
    {
      key: 'remark',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        label: 'เหตุผลการขอรับรองเวลา',
        labelClass: 'col-3',
        inputClass: 'col-6',
        disabled: true,
      },
    },
    {
      key: 'documentOfAttendanceCertifyId',
      type: 'upload-file',
      templateOptions: {
        label: 'หลักฐานการขอรับรองเวลา',
        chooseLabel: 'อัพโหลดไฟล์',
        labelClass: 'col-3',
        inputClass: 'col-6',
        disabled: true,
        onSelect: (selectFileParam: SelectFileParam, model: AttendanceCertifyModel) => {
          this.documentService
            .CreateDocument({
              description: 'Attendance Certify File',
              file: selectFileParam.currentFiles[0],
            })
            .subscribe(result => {
              model.documentOfAttendanceCertify = {
                documentId: result.documentId,
                description: 'Attendance Certify File',
                name: result.name,
              };
            });
        },
        onRemove: (_, model: AttendanceCertifyModel) => {
          model.documentOfAttendanceCertify = undefined;
        },
      },
    },
  ];
  employeeId: number;
  componentMode: ComponentMode;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private masterDataRepo: MasterDataRepo,
    private documentService: DocumentService,
    private locationService: LocationService,
    private attendanceCertifyService: AttendanceCertifyService,
    private notification: AppNotificationServiceService,
    private authService: AuthenticationService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.initComponentMode();
    await this.setAttendanceCertifyData();
  }

  initComponentMode() {
    this.componentMode = ComponentMode.UPDATE;
  }

  async setAttendanceCertifyData() {
    const itemId = this.getAttendanceCertifyItemId();
    this.model = await this.attendanceCertifyService.GetAttendanceCertifyById(itemId).toPromise();
  }

  getAttendanceCertifyItemId() {
    return +this.config.data?.id;
  }

  get isCanCancel(): boolean {
    if(this.authService.getLoginType() === 'Admin'){
      return true;
    }

    // const currentUserEmployeeId = this.authService.getUserInfo()?.employeeId;
    // const isCurrentUserCanCancel = this.model.directBossEmployeeIds.includes(currentUserEmployeeId);
    return (this.model.attendanceCertifyStatusId === "Approved" || this.model.attendanceCertifyStatusId === "Awaiting")  
  }

  onCancelClicked() {
    this.notification.confirm(DefaultMessage.CONFIRM_CANCEL, '').then((result: any) => {
      if (result.value) {
        this.attendanceCertifyService
          .CancelAttendanceCertify(this.model.attendanceCertifyRequestId)
          .subscribe(
            () => {
              this.notification.success(DefaultMessage.CANCEL_SUCCESS, '');
              this.close(true);
            },
            err => {
              const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
              this.notification.error('ไม่สามารถบันทึกข้อมูลได้', 'กรุณาตรวจสอบแบบฟอร์ม หรือทดลองบันทึกอีกครั้ง - ' + errMsg);
            },
          );
      }
    });
  }

  close(isValueChanged: boolean) {
    this.ref.close(isValueChanged);
  }
}
