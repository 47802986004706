/* tslint:disable */
type EShiftsInHolidayType =
  'Include' |
  'Exclude';
module EShiftsInHolidayType {
  export const INCLUDE: EShiftsInHolidayType = 'Include';
  export const EXCLUDE: EShiftsInHolidayType = 'Exclude';
  export function values(): EShiftsInHolidayType[] {
    return [
      INCLUDE,
      EXCLUDE
    ];
  }
}

export { EShiftsInHolidayType }