import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
import { ApplicationSettingsExtended } from "../api/models";
import { AppMenuItem } from "../services/app-menu.service";
import { ApplicationSettingsService } from "../services/application-settings.service";
import { AuthenticationService } from "../services/authentication.service";

@Injectable({
  providedIn: "root",
})
export class RouteHelpers {
  constructor(
    private router: Router,
    private ngxPermissionsService: NgxPermissionsService,
    private authenticationService: AuthenticationService
  ) {
  }

  checkPermissions(menu: AppMenuItem) {
    const isSubrogating = this.authenticationService.isSubrogating();
    const isHasPermissionSubrogate = !!menu.permissionSubrogate?.length;

    const isHasPermission = !!menu.permissions?.length;
    const isHasPermission2 = !!menu.permissions2?.length;

    if (isSubrogating && isHasPermissionSubrogate) {
      const subrogatorPermissions: string[] = this.authenticationService.subrogatePermission().get();
      const hide = menu.permissionSubrogate.some(a => !subrogatorPermissions.includes(a));
      return !hide;
    }

    if (isHasPermission || isHasPermission2) {
      const myPermissions = this.ngxPermissionsService.getPermissions();
      let hide = false;
      if (isHasPermission) {
        hide = menu.permissions.findIndex(a => myPermissions[a]) === -1;
      }
      if (isHasPermission2) {
        hide = menu.permissions2.findIndex(a => myPermissions[a]) === -1;
      }

      if (hide && isHasPermission) {
        // in case user is logging in
        const loginType = this.authenticationService.getCurrentUser().loginType;
        hide = menu.permissions.findIndex(a => a === loginType) === -1;
      }

      if (!hide) {
        // prevent admin user found employee menu
        const loginType = this.authenticationService.getCurrentUser().loginType;

        if (!hide) {
          if (menu.routerLink?.length === 1) {
            hide = !menu.routerLink[0].includes(loginType.toLowerCase());
          }
        }
      }

      return !hide;
    }
    return true;
  }
}
