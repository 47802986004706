/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeChronicDiseaseModel } from '../models/employee-chronic-disease-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeChronicDiseaseService extends __BaseService {
  static readonly GetEmployeeChronicDiseaseListPath = '/api/EmployeeChronicDisease';
  static readonly CreateEmployeeChronicDiseasePath = '/api/EmployeeChronicDisease';
  static readonly GetEmployeeChronicDiseasePath = '/api/EmployeeChronicDisease/{EmployeeChronicDiseaseId}';
  static readonly UpdateEmployeeChronicDiseasePath = '/api/EmployeeChronicDisease/{EmployeeChronicDiseaseId}';
  static readonly DeleteEmployeeChronicDiseasePath = '/api/EmployeeChronicDisease/{EmployeeChronicDiseaseId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetEmployeeChronicDiseaseListResponse(): __Observable<__StrictHttpResponse<Array<EmployeeChronicDiseaseModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeChronicDisease`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeChronicDiseaseModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployeeChronicDiseaseList(): __Observable<Array<EmployeeChronicDiseaseModel>> {
    return this.GetEmployeeChronicDiseaseListResponse().pipe(
      __map(_r => _r.body as Array<EmployeeChronicDiseaseModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeChronicDiseaseResponse(body?: EmployeeChronicDiseaseModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmployeeChronicDisease`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateEmployeeChronicDisease(body?: EmployeeChronicDiseaseModel): __Observable<string> {
    return this.CreateEmployeeChronicDiseaseResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeChronicDiseaseId undefined
   * @return Success
   */
  GetEmployeeChronicDiseaseResponse(EmployeeChronicDiseaseId: number): __Observable<__StrictHttpResponse<EmployeeChronicDiseaseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeChronicDisease/${encodeURIComponent(String(EmployeeChronicDiseaseId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeChronicDiseaseModel>;
      })
    );
  }
  /**
   * @param EmployeeChronicDiseaseId undefined
   * @return Success
   */
  GetEmployeeChronicDisease(EmployeeChronicDiseaseId: number): __Observable<EmployeeChronicDiseaseModel> {
    return this.GetEmployeeChronicDiseaseResponse(EmployeeChronicDiseaseId).pipe(
      __map(_r => _r.body as EmployeeChronicDiseaseModel)
    );
  }

  /**
   * @param params The `EmployeeChronicDiseaseService.UpdateEmployeeChronicDiseaseParams` containing the following parameters:
   *
   * - `EmployeeChronicDiseaseId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeChronicDiseaseResponse(params: EmployeeChronicDiseaseService.UpdateEmployeeChronicDiseaseParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeChronicDisease/${encodeURIComponent(String(params.EmployeeChronicDiseaseId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeChronicDiseaseService.UpdateEmployeeChronicDiseaseParams` containing the following parameters:
   *
   * - `EmployeeChronicDiseaseId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeChronicDisease(params: EmployeeChronicDiseaseService.UpdateEmployeeChronicDiseaseParams): __Observable<string> {
    return this.UpdateEmployeeChronicDiseaseResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param EmployeeChronicDiseaseId undefined
   * @return Success
   */
  DeleteEmployeeChronicDiseaseResponse(EmployeeChronicDiseaseId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmployeeChronicDisease/${encodeURIComponent(String(EmployeeChronicDiseaseId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param EmployeeChronicDiseaseId undefined
   * @return Success
   */
  DeleteEmployeeChronicDisease(EmployeeChronicDiseaseId: number): __Observable<string> {
    return this.DeleteEmployeeChronicDiseaseResponse(EmployeeChronicDiseaseId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeChronicDiseaseService {

  /**
   * Parameters for UpdateEmployeeChronicDisease
   */
  export interface UpdateEmployeeChronicDiseaseParams {
    EmployeeChronicDiseaseId: number;
    body?: EmployeeChronicDiseaseModel;
  }
}

export { EmployeeChronicDiseaseService }
