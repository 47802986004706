import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { WorkflowModel } from 'app/api/models';
import { WorkflowService } from 'app/api/services';
import { EventPageChange } from '../../../interfaces/EventPageChange';
import { PaginationParams } from '../../../interfaces/PaginationParams';

interface FilterFormModel {
  searchText?: string;
}


interface CustomFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof FilterFormModel;
  fieldGroup?: CustomFormlyFieldConfig[];
}

interface WorkflowDisplayModel extends WorkflowModel {
  seq: number;
}

@Component({
  selector: 'app-modal-workflow-search',
  templateUrl: './modal-workflow-search.component.html',
  styleUrls: ['./modal-workflow-search.component.css'],
})
export class ModalWorkflowSearchComponent implements OnInit {
  filterModel: FilterFormModel = {};
  form = new FormGroup({});
  fields: CustomFormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'grid',
      fieldGroup: [
        {
          key: 'searchText',
          type: 'primeng-input',
          className: 'col-12',
          wrappers: ['inline-label'],
          templateOptions: {
            translate: true,
            required: true,
            label: 'search',
            placeholder: '@blank',
            labelClass: 'col-1',
            inputClass: 'col-10',
          },
          hooks: {
            afterViewInit: field => {
              field.formControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
                this.setWorkflowList();
              });
            },
          },
        },
      ],
    },
  ];

  loading = true;
  workflowList: WorkflowDisplayModel[] = [];
  paginationParams: PaginationParams;

  constructor(private ref: DynamicDialogRef, private workflowService: WorkflowService) {}

  ngOnInit() {
    this.initPaginationParams();
    this.setWorkflowList();
  }

  initPaginationParams() {
    this.paginationParams = {
      firstRowIdx: 0,
      rowPerPage: 20,
      totalRecords: 0,
      rowPerPageSelectList: [5, 10, 20, 50, 100, 200],
    };
  }

  setWorkflowList() {
    this.loading = true;

    const params = this.paginationParams;
    const page = Math.floor(params.firstRowIdx / params.rowPerPage) + 1;

    this.workflowService
      .GetWorkflows({
        ItemPerPage: params.rowPerPage,
        Page: page,
        SearchText: this.filterModel.searchText,
      })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        pageResult => {
          this.workflowList = pageResult.results.map((workflow, idx) => {
            return {
              ...workflow,
              seq: params.firstRowIdx + idx + 1,
              statusName: workflow.status === 'Active' ? 'กำลังใช้งาน' : 'ระงับใช้งาน',
            };
          });

          this.paginationParams.totalRecords = pageResult.total;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          // FIXME: handle err
          this.workflowList = [];
        },
      );
  }

  onPageChange(event: EventPageChange) {
    this.paginationParams.firstRowIdx = event.first;
    this.paginationParams.rowPerPage = event.rows;
    this.setWorkflowList();
  }

  search() {
    this.setWorkflowList();
  }

  close() {
    this.ref.close();
  }

  selectWorkflow(workflow: WorkflowModel) {
    this.ref.close(workflow);
  }
}
