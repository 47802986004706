/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeSocialSecurityModel } from '../models/employee-social-security-model';
@Injectable({
  providedIn: 'root',
})
class EmployeeSocialSecurityService extends __BaseService {
  static readonly GetEmployeeSocialSecurityPath = '/api/EmployeeSocialSecurity';
  static readonly UpdateEmployeeSocialSecurityPath = '/api/EmployeeSocialSecurity/{employeeId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeSocialSecurityResponse(employeeId?: number): __Observable<__StrictHttpResponse<EmployeeSocialSecurityModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (employeeId != null) __params = __params.set('employeeId', employeeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmployeeSocialSecurity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeSocialSecurityModel>;
      })
    );
  }
  /**
   * @param employeeId undefined
   * @return Success
   */
  GetEmployeeSocialSecurity(employeeId?: number): __Observable<EmployeeSocialSecurityModel> {
    return this.GetEmployeeSocialSecurityResponse(employeeId).pipe(
      __map(_r => _r.body as EmployeeSocialSecurityModel)
    );
  }

  /**
   * @param params The `EmployeeSocialSecurityService.UpdateEmployeeSocialSecurityParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeSocialSecurityResponse(params: EmployeeSocialSecurityService.UpdateEmployeeSocialSecurityParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmployeeSocialSecurity/${encodeURIComponent(String(params.employeeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `EmployeeSocialSecurityService.UpdateEmployeeSocialSecurityParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateEmployeeSocialSecurity(params: EmployeeSocialSecurityService.UpdateEmployeeSocialSecurityParams): __Observable<string> {
    return this.UpdateEmployeeSocialSecurityResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module EmployeeSocialSecurityService {

  /**
   * Parameters for UpdateEmployeeSocialSecurity
   */
  export interface UpdateEmployeeSocialSecurityParams {
    employeeId: number;
    body?: EmployeeSocialSecurityModel;
  }
}

export { EmployeeSocialSecurityService }
