<div #blockTarget>
  <div>
    <div #blockTarget class="p-fluid">
      <div class="grid">
        <div class="col-12" style="text-align: center">
          <h1 class="mb-0">QR Code</h1>
          <div>
            <qrcode #parent elementType="img" [qrdata]="baseUrl" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            <!-- <img src="../../../../assets/layout/images/employee/new-employee-sign-up.png" alt="QR code for sign up" /> -->
          </div>
          <div>
            <h3>ส่ง QR Code ให้พนักงานใหม่แสกนแล้วกรอกแบบฟอร์มพนักงานใหม่ด้วยตนเอง</h3>
            <h3>
              หรือคลิกที่
              <a href="{{baseUrl}}" (click)="ToSignupEmployee()"
                >{{this.baseUrl}}</a
              >
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="p-dialog-footer">
      <div class="grid justify-content-end">
        <button
          type="button"
          pButton
          class="p-button-secondary"
          label="{{ 'cancel' | translate }}"
          (click)="close()"
        ></button>
      </div>
    </div>
  </div>
</div>
