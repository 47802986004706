/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ChainOfCommandModel } from '../models/chain-of-command-model';
import { DirectBossOfPositionModel } from '../models/direct-boss-of-position-model';
@Injectable({
  providedIn: 'root',
})
class ChainOfCommandService extends __BaseService {
  static readonly GetChainOfCommandOfCompanyPath = '/api/ChainOfCommand/company/{companyId}';
  static readonly CreateLinkPath = '/api/ChainOfCommand';
  static readonly UpdateLinkPath = '/api/ChainOfCommand/{directBossOfPositionId}';
  static readonly DeleteLinkPath = '/api/ChainOfCommand/{directBossOfPositionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ChainOfCommandService.GetChainOfCommandOfCompanyParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EffectiveDate`:
   *
   * @return Success
   */
  GetChainOfCommandOfCompanyResponse(params: ChainOfCommandService.GetChainOfCommandOfCompanyParams): __Observable<__StrictHttpResponse<Array<ChainOfCommandModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    if (params.EffectiveDate != null) __params = __params.set('EffectiveDate', params.EffectiveDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ChainOfCommand/company/${encodeURIComponent(String(params.companyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ChainOfCommandModel>>;
      })
    );
  }
  /**
   * @param params The `ChainOfCommandService.GetChainOfCommandOfCompanyParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * - `EffectiveDate`:
   *
   * @return Success
   */
  GetChainOfCommandOfCompany(params: ChainOfCommandService.GetChainOfCommandOfCompanyParams): __Observable<Array<ChainOfCommandModel>> {
    return this.GetChainOfCommandOfCompanyResponse(params).pipe(
      __map(_r => _r.body as Array<ChainOfCommandModel>)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateLinkResponse(body?: DirectBossOfPositionModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ChainOfCommand`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateLink(body?: DirectBossOfPositionModel): __Observable<string> {
    return this.CreateLinkResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ChainOfCommandService.UpdateLinkParams` containing the following parameters:
   *
   * - `directBossOfPositionId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateLinkResponse(params: ChainOfCommandService.UpdateLinkParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ChainOfCommand/${encodeURIComponent(String(params.directBossOfPositionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ChainOfCommandService.UpdateLinkParams` containing the following parameters:
   *
   * - `directBossOfPositionId`:
   *
   * - `body`:
   *
   * @return Success
   */
  UpdateLink(params: ChainOfCommandService.UpdateLinkParams): __Observable<string> {
    return this.UpdateLinkResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param directBossOfPositionId undefined
   * @return Success
   */
  DeleteLinkResponse(directBossOfPositionId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ChainOfCommand/${encodeURIComponent(String(directBossOfPositionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param directBossOfPositionId undefined
   * @return Success
   */
  DeleteLink(directBossOfPositionId: number): __Observable<string> {
    return this.DeleteLinkResponse(directBossOfPositionId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module ChainOfCommandService {

  /**
   * Parameters for GetChainOfCommandOfCompany
   */
  export interface GetChainOfCommandOfCompanyParams {
    companyId: number;
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
    EffectiveDate?: string;
  }

  /**
   * Parameters for UpdateLink
   */
  export interface UpdateLinkParams {
    directBossOfPositionId: number;
    body?: DirectBossOfPositionModel;
  }
}

export { ChainOfCommandService }
