/* tslint:disable */
type EPhysicalCondition =
  'None' |
  'Disabled';
module EPhysicalCondition {
  export const NONE: EPhysicalCondition = 'None';
  export const DISABLED: EPhysicalCondition = 'Disabled';
  export function values(): EPhysicalCondition[] {
    return [
      NONE,
      DISABLED
    ];
  }
}

export { EPhysicalCondition }