/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserData } from '../models/user-data';
import { Authenticate_Param } from '../models/authenticate-_param';
import { AesEncryptedModel } from '../models/aes-encrypted-model';
@Injectable({
  providedIn: 'root',
})
class TokenService extends __BaseService {
  static readonly LoginPath = '/api/Token';
  static readonly LogoutPath = '/api/Token';
  static readonly LoginMobilePath = '/api/Token/loginmobile';
  static readonly LoginByGoogleAccountPath = '/api/Token/google';
  static readonly LoginByGoogleAccountMobilePath = '/api/Token/googlemobile';
  static readonly SubrogatePath = '/api/Token/subrogate';
  static readonly RefreshTokenPath = '/api/Token/refresh';
  static readonly SubrogateLogoutPath = '/api/Token/subrogateLogout';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  LoginResponse(body?: Authenticate_Param): __Observable<__StrictHttpResponse<UserData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserData>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  Login(body?: Authenticate_Param): __Observable<UserData> {
    return this.LoginResponse(body).pipe(
      __map(_r => _r.body as UserData)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  LogoutResponse(body?: UserData): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  Logout(body?: UserData): __Observable<string> {
    return this.LogoutResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  LoginMobileResponse(body?: AesEncryptedModel): __Observable<__StrictHttpResponse<UserData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token/loginmobile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserData>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  LoginMobile(body?: AesEncryptedModel): __Observable<UserData> {
    return this.LoginMobileResponse(body).pipe(
      __map(_r => _r.body as UserData)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  LoginByGoogleAccountResponse(body?: Authenticate_Param): __Observable<__StrictHttpResponse<UserData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token/google`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserData>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  LoginByGoogleAccount(body?: Authenticate_Param): __Observable<UserData> {
    return this.LoginByGoogleAccountResponse(body).pipe(
      __map(_r => _r.body as UserData)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  LoginByGoogleAccountMobileResponse(body?: AesEncryptedModel): __Observable<__StrictHttpResponse<UserData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token/googlemobile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserData>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  LoginByGoogleAccountMobile(body?: AesEncryptedModel): __Observable<UserData> {
    return this.LoginByGoogleAccountMobileResponse(body).pipe(
      __map(_r => _r.body as UserData)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  SubrogateResponse(body?: Authenticate_Param): __Observable<__StrictHttpResponse<UserData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token/subrogate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserData>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  Subrogate(body?: Authenticate_Param): __Observable<UserData> {
    return this.SubrogateResponse(body).pipe(
      __map(_r => _r.body as UserData)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  RefreshTokenResponse(body?: UserData): __Observable<__StrictHttpResponse<UserData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token/refresh`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserData>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  RefreshToken(body?: UserData): __Observable<UserData> {
    return this.RefreshTokenResponse(body).pipe(
      __map(_r => _r.body as UserData)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  SubrogateLogoutResponse(body?: UserData): __Observable<__StrictHttpResponse<UserData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Token/subrogateLogout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserData>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  SubrogateLogout(body?: UserData): __Observable<UserData> {
    return this.SubrogateLogoutResponse(body).pipe(
      __map(_r => _r.body as UserData)
    );
  }
}

module TokenService {
}

export { TokenService }
