<div #blockTarget class="p-fluid">
  <div class="grid" *ngIf="componentMode != 'READ'">
    <div class="col-12">
      <form [formGroup]="form">
        <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
      </form>
    </div>
    <div class="col-12">
      <b>{{'license' | translate}}</b>
    </div>
    <div class="col-12">
      <p-table #dt [value]="permissionList" [autoLayout]="true"  [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [globalFilterFields]="['label']">
        <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="pi pi-search" style="margin: 4px 4px 0 0"></i>
            <input
              type="text"
              pInputText
              size="50"
              placeholder="{{'s_use_details' | translate}}"
              (input)="dt.filterGlobal($any($event.target).value, 'contains')"
              style="width: auto"
            />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>{{'use_details' | translate}}</th>
            <th style="width: 100px">{{'right' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td>{{ rowData.name }}</td>
            <td>
              <div class="grid justify-content-center">
                <div class="col-fixed">
                  <p-inputSwitch [(ngModel)]="rowData.isPermitted"></p-inputSwitch>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="grid" *ngIf="componentMode == 'READ'">
    <div class="col-12">
      <p-table [value]="model?.rolePermissions || []" [autoLayout]="true"  [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0">
        <ng-template pTemplate="header">
          <tr>
            <th>{{'use_details' | translate}}</th>
            <th style="width: 100px">{{'right' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td>{{ rowData.permission }}</td>
            <td>
              <div class="grid justify-content-center">
                <div class="col-fixed">
                  <i class="fas fa-check"></i>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<div class="p-dialog-footer">
  <button
    *ngIf="componentMode != 'READ'"
    type="button"
    pButton
    class="p-button-primary"
    label="{{'record' | translate}}"
    [disabled]="!form.valid"
    (click)="submit()"
  ></button>
  <button
    *ngIf="componentMode != 'READ'"
    type="button"
    pButton
    class="p-button-secondary"
    label="{{'cancel' | translate}}"
    (click)="close()"
  ></button>
  <button
    *ngIf="componentMode == 'READ'"
    type="button"
    pButton
    class="p-button-secondary"
    label="{{'close' | translate}}"
    (click)="close()"
  ></button>
</div>
