import { Component, OnInit, OnDestroy, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject, ReplaySubject, of, Observable } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { map, distinctUntilChanged, debounceTime, finalize, catchError } from 'rxjs/operators';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { UnitModel, PositionModel } from 'app/api/models';
import { HttpErrorResponse } from '@angular/common/http';
import {  toOptionList } from 'utils/formly-utils';
import * as moment from 'moment';
import { BranchService, CostCenterService, UnitLevelService, UnitService } from 'app/api/services';
import { OptionListRepo } from 'app/repo/option-list.repo';
import { MasterDataRepo } from 'app/repo/master-data.repo';

@Component({
  selector: 'app-modal-units',
  templateUrl: './modal-units.component.html',
  styleUrls: ['./modal-units.component.css'],
})
export class ModalUnitsComponent implements OnInit, OnDestroy {
  companyId = null;
  unitId = null;
  effectiveDate = new Date();
  companyIdChange = new ReplaySubject<number>(1);

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    if (this.config.data?.id) {
      this.unitId = this.config.data.id;
    }
    if (this.config.data?.companyId) {
      this.companyId = this.config.data?.companyId;
    }
    if(this.config.data.effectiveDate){
      this.effectiveDate = this.config.data?.effectiveDate;
    }
  }

  async ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  onClose() {
    this.ref.close();
  }
  onSaved(model){
    this.ref.close(model);
  }
}
