import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PipeTransform, Pipe, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
