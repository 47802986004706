/* tslint:disable */
type EEntityStatus =
  'Active' |
  'InActive' |
  'Draft';
module EEntityStatus {
  export const ACTIVE: EEntityStatus = 'Active';
  export const IN_ACTIVE: EEntityStatus = 'InActive';
  export const DRAFT: EEntityStatus = 'Draft';
  export function values(): EEntityStatus[] {
    return [
      ACTIVE,
      IN_ACTIVE,
      DRAFT
    ];
  }
}

export { EEntityStatus }