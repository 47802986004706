import { Component, OnInit } from '@angular/core';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { MasterDataRepo } from 'app/repo/master-data.repo';
import { GlCodeService } from '../../../api/services/gl-code.service';
import { GlCodeModel } from '../../../api/models/gl-code-model';

interface MyFormlyFieldConfig extends FormlyFieldConfig {
  key?: keyof GlCodeModel;
}
@Component({
  selector: 'app-modal-gl-code',
  templateUrl: './modal-gl-code.component.html',
  styleUrls: ['./modal-gl-code.component.css'],
})
export class ModalGlCodeComponent implements OnInit {
  constructor(
    private _notificationService: AppNotificationServiceService,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private glCodeService: GlCodeService,
    private masterDataRepo: MasterDataRepo,
  ) { }

  options: FormlyFormOptions = {};
  data: {
    id: -1
  };

  form = new FormGroup({});
  model: GlCodeModel = {};

  fields: MyFormlyFieldConfig[] = [
    {
      key: 'code',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'code',
        placeholder: '@blank',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-4',
      },
    },
    {
      key: 'name',
      type: 'primeng-input',
      wrappers: ['inline-label'],
      templateOptions: {
        attributes: {
          style: 'width: 100%',
        },
        label: 'ชื่อ GL Code',
        required: true,
        labelClass: 'col-4',
        inputClass: 'col-6',
      },
    },
    {
      key: 'companyId',
      type: 'filter-dropdown',
      wrappers: ['inline-label'],
      templateOptions: {
        translate: true,
        attributes: {
          style: 'width: 100%',
        },
        label: 'company',
        placeholder: '@blank',
        required: false,
        labelClass: 'col-4',
        inputClass: 'col-6',
        options: this.masterDataRepo.GetCompanyList(),
      },
    },
  ];

  ngOnInit(): void {
    this.data = this.config.data;
    this.glCodeService
      .GetGlCodeById(this.data.id)
      .toPromise()
      .then((response: GlCodeModel) => {
        this.model = response;
      });
  }

  submit(model) {
    if (this.config.data.id === 0) {
      this.glCodeService
        .CreateGlCode(this.model)
        .toPromise()
        .then((response: any) => {
          this._notificationService.saveCompleted();
          this.ref.close(this.model);
        });
    } else {
      this.glCodeService
        .UpdateGlCode({
          id: this.config.data.id,
          body: this.model,
        })
        .toPromise()
        .then((response: any) => {
          this._notificationService.saveCompleted();
          this.ref.close(this.model);
        });
    }
  }

  close() {
    this.ref.close();
  }
}
