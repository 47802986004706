import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RepoModule } from "../../repo/repo.module";
import { SharedModule } from "../../shared/shared.module";
import { UnitEditComponent } from "./unit-edit/unit-edit.component";

const sharedComponent: any[] = [
  UnitEditComponent
];

@NgModule({
  declarations: sharedComponent,
  exports: sharedComponent,
  imports: [
    CommonModule,
    SharedModule,
    RepoModule
  ],
})
export class EmployeeBaseComponentsModule { }
