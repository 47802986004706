import { AppTopbarComponent } from './layout/layout-default/app-menu/app-topbar.component';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpRequest } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AgGridModule } from 'ag-grid-angular';
import { NgHttpCachingConfig, NgHttpCachingEntry, NgHttpCachingModule } from 'ng-http-caching';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

// Application Components
import { AppComponent } from './app.component';

// Application services

import { MenuService } from './app.menu.service';
import { SharedModule } from './shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormlyConfig, FormlyModule } from '@ngx-formly/core';
import { LayoutModule } from '../app/layout/layout.module';
import { ApplicationSettingsService } from './services/application-settings.service';
import { AppMenuService } from './layout/layout-default/app-menu/app-menu.service';
import { RetryInterceptor } from './interceptors/retry.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ViewUtilsService } from './services/view-utils.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FormlyTemplateConfig, FormlyTemplateModule } from './formly-template/formly-template.module';
import { ErrorsModule } from './pages/shared/errors/errors.module';
import { AccountModule } from './pages/shared/account/account.module';
import { PrivacyPolicyModule } from 'pages/shared/privacy-policy/privacy-policy.module';
import { IosRedeemedCodeModule } from './pages/shared/ios-redeemed-code/ios-redeemed-code.module';

//Change language
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//import { registerTranslateExtension } from './translate.extension';
import { TranslateService } from '@ngx-translate/core';
import { FORMLY_CONFIG } from '@ngx-formly/core';
//import { ApprovalListComponent } from './../pages/employee/home/main-task/approval-list/approval-list.component';

// import {ApprovalListComponent} from 'app/pages/employee/home/main-take/approval-list/approval-list.component';
// import{ApprovalListComponent} from 'app/pages/admin/approval-center/approval-list/approval-list.component';

//PDF viewer
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { LoadingService } from './services/LoadingService';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { GlobalErrorHandler } from './providers/global-error.handler';
import { RouterModule } from '@angular/router';
import { PrimeNgComponentsModule } from './shared/prime-ng-components.module';
import { JsonDateInterceptor } from './interceptors/json-date.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiModule } from './api/api.module';
import { environment } from '../environments/environment';
import { RouteHelpers } from './helpers/RouteHelpers';
import { PlatformLandingComponent } from './pages/platform/platform-landing/platform-landing.component';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { NewEmployeeComponent } from './pages/shared/new-employee/new-employee.component';
import { EmployeeCreateModule } from './pages/admin/core/employee/employee-create/employee-create.module';
import { MiniProfilerInterceptor, MiniProfilerModule } from 'ng-miniprofiler';
import { TooltipFeature } from 'ag-grid-community/dist/lib/widgets/tooltipFeature';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const ngHttpCachingConfig: NgHttpCachingConfig = {
  allowedMethod: ['GET'],
  lifetime: 1000 * 60 * 5,// cache expire after 5 mins
  isValid: (entry: NgHttpCachingEntry): boolean | undefined => {
    // In this example only response with status code 200 can be stored into the cache
    return entry.response.status === 200;
  },
  isCacheable: (req: HttpRequest<any>): boolean | undefined => {
    // only cache master data
    if (req.urlWithParams.indexOf('/api/MasterData/') !== -1) {
      return undefined;
    }
    return false;
  },
};
@NgModule({
  imports: [
    BrowserModule,
    DynamicDialogModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    SharedModule,
    FontAwesomeModule,
    LayoutModule,
    NgxPermissionsModule.forRoot(),
    FormlyTemplateModule,
    ErrorsModule,
    AccountModule,
    IosRedeemedCodeModule,
    FormlyModule.forRoot(FormlyTemplateConfig),
    AgGridModule,
    PrivacyPolicyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PdfJsViewerModule,
    NgHttpCachingModule.forRoot(ngHttpCachingConfig),
    ApiModule.forRoot({ rootUrl: environment.apiServer }),
    EmployeeCreateModule,
    MiniProfilerModule.forRoot({
      baseUri: 'api',
      colorScheme: 'Auto',
      maxTraces: 15,
      position: 'BottomLeft',
      toggleShortcut: 'Alt+M',
      showControls: true,
      // เปิดใช้เฉพาะ development เท่านั้น
      enabled: environment.production === false,
      enableGlobalMethod: true
    }),
  ],
  declarations: [
    AppComponent,
    NewEmployeeComponent
  ],
  providers: [
    MenuService,
    ApplicationSettingsService,
    AppMenuService,
    ViewUtilsService,
    RouteHelpers,
    CookieService,
    LoadingService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MiniProfilerInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
