import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TimeEventModel } from '../../../api/models';
import { TimeEventService, TimesheetService } from '../../../api/services';
import { AuthenticationService } from '../../../services/authentication.service';
import { AppNotificationServiceService } from '../../../shared/app-notification-service.service';

@Component({
  selector: 'app-modal-show-time-event',
  templateUrl: './modal-show-time-event.component.html',
  styleUrls: ['./modal-show-time-event.component.scss']
})
export class ModalShowTimeEventComponent implements OnInit {

  timeEvents: TimeEventModel[];
  selectedDate: any;
  employeeId: number;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private timeEventService: TimeEventService,
    private timeSheetService: TimesheetService,
    private notification: AppNotificationServiceService,
    private authService: AuthenticationService
  ) {

  }

  async ngOnInit() {
    debugger
    this.employeeId = this.config.data?.employeeId;
    let selectedDate = new Date(this.config.data?.date);
    selectedDate.setHours(0, 0, 0);
    this.selectedDate = selectedDate.toISOString();

    this.timeEvents = await this.timeEventService.GetTimeEventByDate({
      employeeId: this.employeeId,
      date: this.selectedDate
    }).toPromise();
  }

  async calculateTimesheet() {
    await this.timeSheetService.CalculateTimesheetbyDate({
      from: new Date(this.selectedDate).toISOString(),
      to: new Date(this.selectedDate).toISOString(),
      employeeId: this.employeeId
    }).toPromise();
    this.notification.success('ส่งคำสั่งแล้ว', 'กรุณารอสักครู่เพื่อให้ระบบประมวลผล');
  }
  close() {
    this.ref.close();
  }
}
