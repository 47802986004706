<p-dropdown
  [placeholder]="to.placeholder"
  [options]="timeOptions"
  [formControl]="$any(formControl)"
  [formlyAttributes]="field"
  [showClear]="!to.required"
  appendTo="body"
  [filter]="true"
  (onChange)="to.change && to.change(field, $event)"
>
</p-dropdown>
