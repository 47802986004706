<div #blockTarget class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <form [formGroup]="form">
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      </form>
    </div>
  </div>
</div>
<div class="p-dialog-footer">
  <button type="button" pButton class="p-button-primary" label="{{'record' | translate}}" [disabled]="!form.valid"
    (click)="onSubmit()"></button>
  <button type="button" pButton class="p-button-secondary" label="{{'cancel' | translate}}"
    (click)="onClose()"></button>
</div>