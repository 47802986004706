<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <h1>ตารางการทำงาน</h1>
        <hr />
        <div class="card">
          <div class="grid justify-content-between">
            <div class="col-fixed">
              <h1>ตารางการทำงาน</h1>
            </div>
            <!-- <div class="col-fixed">
              <button pButton type="button" label="เพิ่มข้อมูล" (click)="showCreateEmployeeInShiftDialog()"></button>
            </div> -->
          </div>
          <p-table [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0" [value]="workScheduleList" [autoLayout]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>วันที่เริ่มต้นใช้งาน </th>
                <th>ประเภทตารางการทำงาน</th>
                <!-- <th style="width: 100px">Action</th> -->
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{ rowData.effectiveDate | date: 'dd/MM/yyyy' }}</td>
                <td>{{ rowData.shiftType }}</td>
                <!-- <td>
                  <div class="grid justify-content-around icon-action">
                    <div class="col-fixed">
                      <i
                        class="fa fa-pencil-alt"
                        (click)="showUpdateEmployeeInShiftDialog(rowData.employeeInShiftId)"
                      ></i>
                    </div>
                    <div class="col-fixed">
                      <i class="fa fa-trash" (click)="showDeleteEmployeeInShiftDialog(rowData.employeeInShiftId)"></i>
                    </div>
                  </div>
                </td> -->
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="4" class="emptymessage">ไม่พบข้อมูล</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
