<p-calendar
  [formControl]="$any(formControl)"
  view="month"
  dateFormat="mm/yy"
  [yearRange]="to.yearRange || '2000:2030'"
  [yearNavigator]="true"
  [showIcon]="true"
  [readonlyInput]="to.readonlyInput == null ? true : to.readonlyInput"
  (onSelect)="to.onSelect != undefined ? to.onSelect(field, model) : undefined"
  dataType="string" appendTo="body"
></p-calendar>
