/* tslint:disable */
type EPayAllowanceType =
  'Money' |
  'Percent';
module EPayAllowanceType {
  export const MONEY: EPayAllowanceType = 'Money';
  export const PERCENT: EPayAllowanceType = 'Percent';
  export function values(): EPayAllowanceType[] {
    return [
      MONEY,
      PERCENT
    ];
  }
}

export { EPayAllowanceType }