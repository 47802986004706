/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { JobGradeOfSystemModelPaginationResult } from '../models/job-grade-of-system-model-pagination-result';
import { JobGradeOfSystemModel } from '../models/job-grade-of-system-model';
@Injectable({
  providedIn: 'root',
})
class JobGradeOfSystemService extends __BaseService {
  static readonly GetJobGradeOfSystemsListPath = '/api/JobGradeOfSystem';
  static readonly CreateJobGradeOfSystemPath = '/api/JobGradeOfSystem';
  static readonly UpdateJobGradeOfSystemPath = '/api/JobGradeOfSystem';
  static readonly GetJobGradeOfSystemByIdPath = '/api/JobGradeOfSystem/{jobGradeOfSystemId}';
  static readonly DeleteJobGradeOfSystemPath = '/api/JobGradeOfSystem/{jobGradeOfSystemId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `JobGradeOfSystemService.GetJobGradeOfSystemsListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetJobGradeOfSystemsListResponse(params: JobGradeOfSystemService.GetJobGradeOfSystemsListParams): __Observable<__StrictHttpResponse<JobGradeOfSystemModelPaginationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Sorting != null) __params = __params.set('Sorting', params.Sorting.toString());
    if (params.SearchText != null) __params = __params.set('SearchText', params.SearchText.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ItemsToSkip != null) __params = __params.set('ItemsToSkip', params.ItemsToSkip.toString());
    if (params.ItemPerPage != null) __params = __params.set('ItemPerPage', params.ItemPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/JobGradeOfSystem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobGradeOfSystemModelPaginationResult>;
      })
    );
  }
  /**
   * @param params The `JobGradeOfSystemService.GetJobGradeOfSystemsListParams` containing the following parameters:
   *
   * - `Sorting`:
   *
   * - `SearchText`:
   *
   * - `Page`:
   *
   * - `ItemsToSkip`:
   *
   * - `ItemPerPage`:
   *
   * @return Success
   */
  GetJobGradeOfSystemsList(params: JobGradeOfSystemService.GetJobGradeOfSystemsListParams): __Observable<JobGradeOfSystemModelPaginationResult> {
    return this.GetJobGradeOfSystemsListResponse(params).pipe(
      __map(_r => _r.body as JobGradeOfSystemModelPaginationResult)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  CreateJobGradeOfSystemResponse(body?: JobGradeOfSystemModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/JobGradeOfSystem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  CreateJobGradeOfSystem(body?: JobGradeOfSystemModel): __Observable<string> {
    return this.CreateJobGradeOfSystemResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  UpdateJobGradeOfSystemResponse(body?: JobGradeOfSystemModel): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/JobGradeOfSystem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  UpdateJobGradeOfSystem(body?: JobGradeOfSystemModel): __Observable<string> {
    return this.UpdateJobGradeOfSystemResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param jobGradeOfSystemId undefined
   * @return Success
   */
  GetJobGradeOfSystemByIdResponse(jobGradeOfSystemId: number): __Observable<__StrictHttpResponse<JobGradeOfSystemModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/JobGradeOfSystem/${encodeURIComponent(String(jobGradeOfSystemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JobGradeOfSystemModel>;
      })
    );
  }
  /**
   * @param jobGradeOfSystemId undefined
   * @return Success
   */
  GetJobGradeOfSystemById(jobGradeOfSystemId: number): __Observable<JobGradeOfSystemModel> {
    return this.GetJobGradeOfSystemByIdResponse(jobGradeOfSystemId).pipe(
      __map(_r => _r.body as JobGradeOfSystemModel)
    );
  }

  /**
   * @param jobGradeOfSystemId undefined
   * @return Success
   */
  DeleteJobGradeOfSystemResponse(jobGradeOfSystemId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/JobGradeOfSystem/${encodeURIComponent(String(jobGradeOfSystemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param jobGradeOfSystemId undefined
   * @return Success
   */
  DeleteJobGradeOfSystem(jobGradeOfSystemId: number): __Observable<string> {
    return this.DeleteJobGradeOfSystemResponse(jobGradeOfSystemId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module JobGradeOfSystemService {

  /**
   * Parameters for GetJobGradeOfSystemsList
   */
  export interface GetJobGradeOfSystemsListParams {
    Sorting?: string;
    SearchText?: string;
    Page?: number;
    ItemsToSkip?: number;
    ItemPerPage?: number;
  }
}

export { JobGradeOfSystemService }
