<div class="p-fluid">
  <div class="grid">
    <div class="col-12">
      <div class="card card-w-title">
        <div class="grid justify-content-between">
          <div class="col-fixed">
            <h1>{{ componentMode === 'CREATE' ? 'สร้างคำขอรับรองเวลา' : 'แก้ไขคำขอรับรองเวลา' }}</h1>
          </div>
          <div class="col"></div>
          <div class="col-fixed">
            <div class="grid">
              <div class="col-fixed" *ngFor="let action of actionList">
                <button
                  *ngIf="actionList.length == 1"
                  pButton
                  type="button"
                  label="{{ action.name }}"
                  (click)="submitActionForm()"
                  class="p-button-success"
                  [disabled]="form.invalid || !model.attendanceCertifies.length"
                ></button>
              </div>
              <div class="col-fixed">
                <button
                  pButton
                  type="button"
                  label="บันทึกฉบับร่าง"
                  (click)="onSaveBtnClick()"
                  [disabled]="
                    form.invalid ||
                    !model.attendanceCertifies.length ||
                    options.formState.disabled ||
                    !employee ||
                    !form.dirty
                  "
                ></button>
              </div>
            </div>
          </div>
        </div>
        <hr style="margin-top: 5px; margin-bottom: 20px" />
        <div class="grid" [hidden]="actionList.length <= 1">
          <div class="col-8">
            <div class="grid">
              <div class="col-12">
                <form [formGroup]="actionForm">
                  <formly-form [model]="actionModel" [fields]="actionFields" [form]="actionForm"></formly-form>
                </form>
              </div>
              <div class="col-fixed col-offset-3">
                <button
                  pButton
                  type="button"
                  class="p-button-success"
                  label="บันทึกและส่ง"
                  (click)="submitActionForm()"
                  [disabled]="actionForm.invalid || form.invalid || !model.attendanceCertifies.length"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <hr />
          </div>
        </div>
        <app-employee-card
          *ngIf="!(queryData.employeeId && !employee)"
          [employee]="employee"
          [isShowBtn]="componentMode === 'CREATE'"
          [isOnlySubOrdinate]="true"
          (selectedEmployeeIdEvent)="setEmployeeData($event)"
        ></app-employee-card>
        <hr />
        <form [formGroup]="form">
          <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
        </form>
        <!-- <div class="grid" *ngIf="actionList.length > 1">
          <div class="col-8">
            <div class="grid">
              <div class="col-12">
                <form [formGroup]="actionForm">
                  <formly-form [model]="actionModel" [fields]="actionFields" [form]="actionForm"></formly-form>
                </form>
              </div>
              <div class="col-fixed col-offset-3">
                <button
                  pButton
                  type="button"
                  class="p-button-info"
                  label="บันทึกความเห็น"
                  (click)="submitActionForm()"
                  [disabled]="actionForm.invalid"
                ></button>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="grid" *ngIf="actionList.length == 1">
          <div class="col-fixed">
            <label>การดำเนินการ</label>
          </div>
          <div class="col-fixed" *ngFor="let action of actionList">
            <button
              pButton
              type="button"
              label="{{ action.name }}"
              (click)="submitActionForm()"
              class="p-button-info"
            ></button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
