import { DocumentService, EmployeeDocumentService } from 'app/api/services';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmployeeDocumentModel } from '../../../api/models';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { ModalEmployeeAttachmentComponent } from '../../../modals/core/modal-employee-attachment/modal-employee-attachment.component';
import { AppNotificationServiceService } from '../../../shared/app-notification-service.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css'],
  providers: [DialogService],
})
export class FileComponent implements OnInit {
  isEditable = false;
  fileList: EmployeeDocumentModel[];
  ref: DynamicDialogRef;

  constructor(
    private layout: LayoutDefaultComponent,
    private router: Router,
    private notificationService: AppNotificationServiceService,
    private dialogService: DialogService,
    private documentService: DocumentService,
    private employeeDocumentService: EmployeeDocumentService,
  ) {}

  ngOnInit(): void {
    this.setEditablePermission();
    this.setDataTable();
  }

  setEditablePermission() {
    this.isEditable = this.router.url.includes('admin', 1);
  }

  setDataTable(): void {
    this.employeeDocumentService.GetEmployeeDocuments(this.getEmployeeId()).subscribe(result => {
      this.fileList = result;
    });
  }

  showCreateFileDialog() {
    const employeeId = this.getEmployeeId();

    this.showDialog({ employeeId }, '+ เพิ่มข้อมูล', ModalEmployeeAttachmentComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setDataTable();
      }
    });
  }

  downloadFile(id: number, fileName: string){
    this.documentService.Download(id).subscribe(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      if (fileName.endsWith('.pdf')){
        a.target = '_blank';
      }
      else{
        a.download = fileName;
      }

      a.click();
    })
  }

  editFile(id: number) {
    this.showDialog({ id, employeeId: this.getEmployeeId() }, 'แก้ไขข้อมูล', ModalEmployeeAttachmentComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setDataTable();
      }
    });
  }

  removeFile(docId: number) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeeDocumentService.DeleteEmployeeDocument({ docId, employeeId: this.getEmployeeId() }).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.setDataTable();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }
}
