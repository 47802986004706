import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmployeeInShiftService } from 'app/api/services';
import { AppNotificationServiceService } from 'app/shared/app-notification-service.service';
import { LayoutDefaultComponent } from '../../../layout/layout-default/layout-default.component';
import { ModalEmployeeInShiftComponent } from '../../../modals/core/modal-employee-in-shift/modal-employee-in-shift.component';

interface WorkSchedule {
  employeeInShiftId: number;
  effectiveDate: string;
  shiftType: string;
}

@Component({
  selector: 'app-work-schedule',
  templateUrl: './work-schedule.component.html',
  styleUrls: ['./work-schedule.component.css'],
  providers: [DialogService],
})
export class WorkScheduleComponent implements OnInit {
  employeeId: number;
  workScheduleList: WorkSchedule[];
  ref: DynamicDialogRef;

  constructor(
    private layout: LayoutDefaultComponent,
    private dialogService: DialogService,
    private notificationService: AppNotificationServiceService,
    private employeeInShiftService: EmployeeInShiftService,
    private router: Router,
  ) {}

  // ngOnInit(): void {
  //   this.workScheduleList = [
  //     { effectiveDate: '11/02/2018', terminateDate: '', shiftType: 'FLX10001 (08:00-17:00)' }
  //   ];
  // }

  ngOnInit() {
    this.setEmployeeData();
    this.setWorkScheduleList();
  }

  setEmployeeData() {
    this.employeeId = this.getEmployeeId();
  }

  setWorkScheduleList() {
    this.employeeInShiftService
      .GetEmployeeInShiftsListByEmployeeId({
        employeeId: this.employeeId,
      })
      .subscribe(pageResult => {
        this.workScheduleList = pageResult.results.map(empInShift => {
          return {
            employeeInShiftId: empInShift.employeeInShiftId,
            effectiveDate: empInShift.effectiveDate,
            shiftType: empInShift.shiftName,
          };
        });
      });
  }

  private getEmployeeId(): number {
    const urls = this.router.url.split('/');

    return +urls[urls.length - 2];
  }

  showCreateEmployeeInShiftDialog() {
    const employeeId = this.employeeId;

    this.showDialog({ employeeId }, '+ เพิ่มตารางการทำงาน', ModalEmployeeInShiftComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setWorkScheduleList();
      }
    });
  }

  showUpdateEmployeeInShiftDialog(employeeInShiftId: number) {
    this.showDialog({ id: employeeInShiftId }, 'แก้ไขตารางการทำงาน', ModalEmployeeInShiftComponent);

    this.ref.onClose.subscribe(data => {
      if (data) {
        this.setWorkScheduleList();
      }
    });
  }

  showDeleteEmployeeInShiftDialog(employeeInShiftId: number) {
    this.notificationService.confirm('ยืนยันการลบข้อมูล', '').then(result => {
      if (result.value) {
        this.employeeInShiftService.DeleteEmployeeInShift(employeeInShiftId).subscribe(
          () => {
            this.notificationService.deleteCompleted();
            this.setWorkScheduleList();
          },
          err => {
            const errMsg = err.error instanceof Object ? err.error.message : JSON.parse(err.error).message;
            this.notificationService.error('ไม่สามารถลบข้อมูลได้', 'กรุณาตรวจสอบ และทดลองใหม่อีกครั้ง - ' + errMsg);
          },
        );
      }
    });
  }

  private showDialog(data: any, header: string, dialogComponent: any) {
    this.ref = this.dialogService.open(dialogComponent, {
      header,
      width: this.layout.isMobile() ? '100%' : '600px',
      contentStyle: { overflow: 'auto' },
      data,
      dismissableMask: false,
    });
  }
}
