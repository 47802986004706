/* tslint:disable */
type WorkflowStepActorType =
  'Requester' |
  'ChiefNLevelConsecutively' |
  'ChiefTopLevel' |
  'SpecificActor' |
  'GroupNLevelConsecutively' |
  'Custom1' |
  'Custom2' |
  'Custom3' |
  'Custom4' |
  'Custom5' |
  'Custom6' |
  'Custom7' |
  'Custom8' |
  'Custom9';
module WorkflowStepActorType {
  export const REQUESTER: WorkflowStepActorType = 'Requester';
  export const CHIEF_NLEVEL_CONSECUTIVELY: WorkflowStepActorType = 'ChiefNLevelConsecutively';
  export const CHIEF_TOP_LEVEL: WorkflowStepActorType = 'ChiefTopLevel';
  export const SPECIFIC_ACTOR: WorkflowStepActorType = 'SpecificActor';
  export const GROUP_NLEVEL_CONSECUTIVELY: WorkflowStepActorType = 'GroupNLevelConsecutively';
  export const CUSTOM_1: WorkflowStepActorType = 'Custom1';
  export const CUSTOM_2: WorkflowStepActorType = 'Custom2';
  export const CUSTOM_3: WorkflowStepActorType = 'Custom3';
  export const CUSTOM_4: WorkflowStepActorType = 'Custom4';
  export const CUSTOM_5: WorkflowStepActorType = 'Custom5';
  export const CUSTOM_6: WorkflowStepActorType = 'Custom6';
  export const CUSTOM_7: WorkflowStepActorType = 'Custom7';
  export const CUSTOM_8: WorkflowStepActorType = 'Custom8';
  export const CUSTOM_9: WorkflowStepActorType = 'Custom9';
  export function values(): WorkflowStepActorType[] {
    return [
      REQUESTER,
      CHIEF_NLEVEL_CONSECUTIVELY,
      CHIEF_TOP_LEVEL,
      SPECIFIC_ACTOR,
      GROUP_NLEVEL_CONSECUTIVELY,
      CUSTOM_1,
      CUSTOM_2,
      CUSTOM_3,
      CUSTOM_4,
      CUSTOM_5,
      CUSTOM_6,
      CUSTOM_7,
      CUSTOM_8,
      CUSTOM_9
    ];
  }
}

export { WorkflowStepActorType }