<form [formGroup]="form">
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
</form>
<div class="p-dialog-footer">
  <button
    type="button"
    pButton
    class="p-button-primary"
    label="{{ 'search' | translate }}"
    [disabled]="isLoading"
    (click)="search()"
  ></button>
  <button
    type="button"
    pButton
    class="p-button-secondary"
    label="{{ 'cancel' | translate }}"
    (click)="close()"
  ></button>
</div>

<p-table [value]="positionList" [loading]="isLoading" [autoLayout]="true"  [tableStyle]="{'width': '100%', 'min-width': '640px' }" breakpoint="0">
  <ng-template pTemplate="header">
    <tr>
      <th>{{ 'name_position' | translate }}</th>
      <th>{{ 'persist_position' | translate }}</th>
      <th>{{ 'date_beyond_position' | translate }}</th>
      <th>{{ 'agency' | translate }}</th>
      <th>{{ 'company' | translate }}</th>
      <th style="width: 50px">{{ 'action' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>{{ rowData.name }}</td>
      <td>{{ rowData.currentEmployeeInPosition?.employeeName || 'N/A' }}</td>
      <td>{{ (rowData.currentEmployeeInPosition?.terminateDate | date: 'dd/MM/yyyy') || 'N/A' }}</td>
      <td>{{ rowData.unitName || 'N/A' }}</td>
      <td>{{ rowData.companyName || 'N/A' }}</td>
      <td>
        <div class="grid justify-content-around">
          <div class="col-fixed">
            <button pButton icon="fa fa-user-plus" (click)="selectPosition(rowData)"></button>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6" class="emptymessage">{{ 'no_data' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
<p-paginator
  [rows]="paginationParams.rowPerPage"
  [first]="paginationParams.firstRowIdx"
  [totalRecords]="paginationParams.totalRecords"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="paginationParams.rowPerPageSelectList"
  currentPageReportTemplate="{first} - {last} of {totalRecords} Results"
  (onPageChange)="onPageChange($event)"
>
</p-paginator>
