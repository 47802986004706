/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DocumentModel } from '../models/document-model';
import { DocumentOfNewEmployeeModel } from '../models/document-of-new-employee-model';
@Injectable({
  providedIn: 'root',
})
class DocumentService extends __BaseService {
  static readonly GetDoucmentByIdPath = '/api/Document/{docId}';
  static readonly DeleteDocumentPath = '/api/Document/{docId}';
  static readonly CreateDocumentPath = '/api/Document';
  static readonly CreateDocumentsPath = '/api/Document/CreateMultiDocument';
  static readonly DownloadPath = '/api/Document/{docId}/download';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param docId undefined
   * @return Success
   */
  GetDoucmentByIdResponse(docId: number): __Observable<__StrictHttpResponse<DocumentModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Document/${encodeURIComponent(String(docId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentModel>;
      })
    );
  }
  /**
   * @param docId undefined
   * @return Success
   */
  GetDoucmentById(docId: number): __Observable<DocumentModel> {
    return this.GetDoucmentByIdResponse(docId).pipe(
      __map(_r => _r.body as DocumentModel)
    );
  }

  /**
   * @param docId undefined
   * @return Success
   */
  DeleteDocumentResponse(docId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Document/${encodeURIComponent(String(docId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param docId undefined
   * @return Success
   */
  DeleteDocument(docId: number): __Observable<string> {
    return this.DeleteDocumentResponse(docId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `DocumentService.CreateDocumentParams` containing the following parameters:
   *
   * - `file`:
   *
   * - `description`:
   *
   * @return Success
   */
  CreateDocumentResponse(params: DocumentService.CreateDocumentParams): __Observable<__StrictHttpResponse<DocumentOfNewEmployeeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.description != null) __params = __params.set('description', params.description.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Document`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentOfNewEmployeeModel>;
      })
    );
  }
  /**
   * @param params The `DocumentService.CreateDocumentParams` containing the following parameters:
   *
   * - `file`:
   *
   * - `description`:
   *
   * @return Success
   */
  CreateDocument(params: DocumentService.CreateDocumentParams): __Observable<DocumentOfNewEmployeeModel> {
    return this.CreateDocumentResponse(params).pipe(
      __map(_r => _r.body as DocumentOfNewEmployeeModel)
    );
  }

  /**
   * @param params The `DocumentService.CreateDocumentsParams` containing the following parameters:
   *
   * - `file`:
   *
   * - `description`:
   *
   * @return Success
   */
  CreateDocumentsResponse(params: DocumentService.CreateDocumentsParams): __Observable<__StrictHttpResponse<DocumentOfNewEmployeeModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    (params.file || []).forEach(val => {if (val != null) __formData.append('file', val as string | Blob)});
    if (params.description != null) __params = __params.set('description', params.description.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Document/CreateMultiDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentOfNewEmployeeModel>;
      })
    );
  }
  /**
   * @param params The `DocumentService.CreateDocumentsParams` containing the following parameters:
   *
   * - `file`:
   *
   * - `description`:
   *
   * @return Success
   */
  CreateDocuments(params: DocumentService.CreateDocumentsParams): __Observable<DocumentOfNewEmployeeModel> {
    return this.CreateDocumentsResponse(params).pipe(
      __map(_r => _r.body as DocumentOfNewEmployeeModel)
    );
  }

  /**
   * @param docId undefined
   * @return Success
   */
  DownloadResponse(docId: number): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Document/${encodeURIComponent(String(docId))}/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param docId undefined
   * @return Success
   */
  Download(docId: number): __Observable<Blob> {
    return this.DownloadResponse(docId).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module DocumentService {

  /**
   * Parameters for CreateDocument
   */
  export interface CreateDocumentParams {
    file?: Blob;
    description?: string;
  }

  /**
   * Parameters for CreateDocuments
   */
  export interface CreateDocumentsParams {
    file?: Array<string>;
    description?: string;
  }
}

export { DocumentService }
