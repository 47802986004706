/* tslint:disable */
type EPayrollValidationStatus =
  'Unknown' |
  'Ok' |
  'Warning' |
  'Error';
module EPayrollValidationStatus {
  export const UNKNOWN: EPayrollValidationStatus = 'Unknown';
  export const OK: EPayrollValidationStatus = 'Ok';
  export const WARNING: EPayrollValidationStatus = 'Warning';
  export const ERROR: EPayrollValidationStatus = 'Error';
  export function values(): EPayrollValidationStatus[] {
    return [
      UNKNOWN,
      OK,
      WARNING,
      ERROR
    ];
  }
}

export { EPayrollValidationStatus }