import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppNotificationServiceService } from '../shared/app-notification-service.service';
import { Route, Router } from '@angular/router';

interface ExceptionErrorType {
  route: string;
  code: number;
}
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private appNotification: AppNotificationServiceService,
    private router: Router,
  ) {

  }
  async handleGenericError(error: HttpErrorResponse) {
    let errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      errorMsg = `กรุณาลองใหม่อีกครั้ง - ${error.error.message}`;
    } else {
      errorMsg = `เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง - รหัส: ${error.status},  รายละเอียด: ${error.message}`;
    }
    await this.appNotification.error("เกิดข้อผิดพลาด", errorMsg);
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const exceptionErrors: ExceptionErrorType[] = [
      { route: '/api/Token', code: 400 }
    ];
    return next.handle(request)
      .pipe(
        tap({
          next: () => null,
          error: async (error: HttpErrorResponse) => {
            // skip error message for route
            let skipError = false;
            exceptionErrors.forEach(a => {
              if (request.url.includes(a.route) && a.code === error.status) {
                skipError = true;
              }
            })
            if (skipError) {
              return;
            }

            // handle the error
            switch (error.status) {
              case 401:
                // do nothing -> leave it to token interceptor
                break;
              case 412:
                // invalid status
                if (error.error.reason == 'Application not initilized') {
                  this.router.navigate(['system', 'initialize-system'])
                } else {
                  await this.handleGenericError(error);
                }
                break;
              default:
                await this.handleGenericError(error);
                break;
            }
          },
        })
      );
  }
}
